// #region Imports

import { Animate, Component, HorizontalAlignment, Inset, Orientation, Size, State, Variant, animate, repeat, translate, when, type TextBoxElement } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { globals } from '../../Globals';
import { COMPONENTS, type IComponentInfo } from '../../Services/ComponentsService';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

/**
 * The `{@link ComponentsView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-components-view'
})
export class ComponentsView extends ViewBase {

    // #region Fields

    @State()
    private _filter: string;
    @State()
    private _data: Array<{
        key: string;
        items: Array<IComponentInfo>;
    }>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._filter = '';
        this._data = COMPONENTS.getComponentsWithinGroups(this._filter);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-components-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css`
                :host {
                    height: auto;
                }

                .bg {
                                        width: 100%;
                    height: 100%;
                    background-color: var(--theme-transparent-color);
                    background-repeat: repeat;
                    background-image: radial-gradient(var(--theme-highlight-color) 1px, var(--theme-transparent-color) 1px);
                    // background-image: radial-gradient(rgb(68, 76, 247) 1px, rgb(229, 229, 247) 1px);
                    background-size: 12px 12px;
                }
            `
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.components')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md"
                         ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}"
                                           .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/components'}"
                                           .label="${translate('loc.nav.components')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.components')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-pre-content .inset="${Inset.All}">
                    <mosaik-textbox .placeholder="${translate('loc.components.search')}"
                                    .variant="${Variant.Primary}"
                                    .isClearable="${true}"
                                    @input="${(x: InputEvent) => this.onFilter((x.target as TextBoxElement).value)}">
                        <mosaik-icon slot="prefix"
                                     .data="${'M10 2.5a7.5 7.5 0 0 1 5.964 12.048l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083-4.745-4.743A7.5 7.5 0 1 1 10 2.5Zm0 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z'}"
                                     .size="${Size.Small}"></mosaik-icon>
                    </mosaik-textbox>
                </mosaik-page-pre-content>
                <mosaik-page-content .inset="${Inset.Horizontal}">
                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                  .gap="${'16px'}">
                        ${repeat(this._data, (x) => x, (x) => html`
                        <mosaik-text .text="${x.key}"></mosaik-text>
                        <mosaik-wrap part="wrap"
                                     .gap="${'32px'}"
                                     .columns="${this.isMobile ? 1 : 3}">
                            ${repeat(x.items, (i) => i.name, (i) => html`
                            <mosaik-box style="--box-background-color: var(--theme-transparent-color); padding: 0;">
                                <mosaik-router-link .href="${i.path}">
                                    <mosaik-compound-button .label="${i.name}"
                                                            .subLabel="${i.description}"
                                                            .isNavigation="${true}"
                                                            .horizontalContentAlignment="${HorizontalAlignment.Left}">
                                    </mosaik-compound-button>
                                    ${when(i.experimental, () => html`
                                    <mosaik-ribbon .variant="${Variant.Highlight}"
                                                   .icon="${'M9 4.5v6.238c0 .375-.094.744-.273 1.074L7.54 14h8.922l-1.188-2.188A2.25 2.25 0 0 1 15 10.738V4.5h1A.75.75 0 0 0 16 3H8a.75.75 0 0 0 0 1.5h1ZM17.275 15.5H6.725l-1.582 2.915A1.75 1.75 0 0 0 6.68 21h10.638a1.75 1.75 0 0 0 1.538-2.585L17.275 15.5Z'}"
                                                   .iconSize="${Size.Small}"></mosaik-ribbon>
                                    `)}
                                </mosaik-router-link>
                            </mosaik-box>
                            `)}
                        </mosaik-wrap>
                        `)}
                    </mosaik-stack>
                    ${when(this._data.length === 0, () => html`
                    <mosaik-empty-state .header="${translate('The search returns no result.')}"
                                        .content="${translate('Nothing here to show...')}"></mosaik-empty-state>
                    `)}
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    private onFilter(filter: string): void {
        this._filter = filter;
        this._data = COMPONENTS.getComponentsWithinGroups(this._filter);
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-components-view': ComponentsView;
    }
}
