export function getFeedbackTypeSymbol(type: string): string {
    switch (type.toLowerCase()) {
        case 'bug':
            return '🐞';
        case 'featurerequest':
            return '🚀';
        case 'question':
            return '❓';
        case 'integration':
            return '🔌';
        case 'feedback':
            return '📝';
        default:
            return '';
    }
}

export function getFeedbackStatusSymbol(status: string): string {
    switch (status.toLowerCase()) {
        case 'inprogress':
            return '🚧';
        case 'completed':
            return '✅';
        case 'open':
            return '🔓';
        case 'closed':
            return '🔒';
        default:
            return '';
    }
}

export function getFeedbackPrioritySymbol(priority: string): string {
    switch (priority.toLowerCase()) {
        case 'urgent':
            return '🔥';
        case 'high':
            return '🔴';
        case 'medium':
            return '🟠';
        case 'low':
            return '🟢';
        default:
            return '';
    }
}
