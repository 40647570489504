//#region Imports

import { Component, Fit, FlowDirection, HorizontalAlignment, Orientation, StackElement, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { stackElementDocs } from '../../../.Generated/mosaik-stack';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { StackSampleTemplates } from './StackSample.Templates';

//#endregion

/**
 * The `{@link StackSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-stack-sample',
    imports: [
        StackElement
    ]
})
export class StackSampleElement extends SampleBaseElement<StackElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<StackElement> {
        return {
            header: 'Stack',
            description: META.description(StackElement.is),
            graph: META.graph(StackElement.is),
            playground: {
                template: StackSampleTemplates.defaultTemplate(),
                properties: META.properties<StackElement>(StackElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'fit', value: Fit.Both },
                    { key: 'horizontalAlignment', value: HorizontalAlignment.Stretch },
                    { key: 'verticalAlignment', value: VerticalAlignment.Stretch },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'gap', value: '16px' }
                ]),
                events: META.events(StackElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<StackElement>(StackElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'fit', value: Fit.Both },
                    { key: 'horizontalAlignment', value: HorizontalAlignment.Stretch },
                    { key: 'verticalAlignment', value: VerticalAlignment.Stretch },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'gap', value: '16px' }
                ]), [
                    { key: 'gap', template: cssLengtTemplate },
                ] as Array<IPlaygroundProperty<StackElement>>, (a, b) => a.key === b.key), [
                    { key: 'items' },
                ] as Array<IPlaygroundProperty<StackElement>>, (a, b) => a.key === b.key),
            },
            docs: [
                stackElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-stack-sample': StackSampleElement;
    }
}

