// #region Imports

import { Appearance, Component, TitleBarElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { titlebarElementDocs } from '../../.Generated/mosaik-titlebar';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { COMPONENTS } from '../../Services/ComponentsService';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TtitleBarSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-titlebar-sample'
})
export class TtitleBarSampleElement extends SampleBaseElement<TitleBarElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TitleBarElement> {
        return {
            header: 'TtitleBar',
            description: META.description(TitleBarElement.is),
            experimental: COMPONENTS.getComponent(TitleBarElement.is)?.experimental,
            graph: META.graph(TitleBarElement.is),
            playground: {
                template: html`
                    <mosaik-titlebar>
                        <mosaik-button slot="start"
                                       .appearance="${Appearance.Plain}"
                                       .icon="${'M3 17h18a1 1 0 0 1 .117 1.993L21 19H3a1 1 0 0 1-.117-1.993L3 17h18H3Zm0-6 18-.002a1 1 0 0 1 .117 1.993l-.117.007L3 13a1 1 0 0 1-.117-1.993L3 11l18-.002L3 11Zm0-6h18a1 1 0 0 1 .117 1.993L21 7H3a1 1 0 0 1-.117-1.993L3 5h18H3Z'}"></mosaik-button>
                        <mosaik-button slot="end"
                                       .appearance="${Appearance.Plain}"
                                       .icon="${'M9.042 19.003h5.916a3 3 0 0 1-5.916 0Zm2.958-17a7.5 7.5 0 0 1 7.5 7.5v4l1.418 3.16A.95.95 0 0 1 20.052 18h-16.1a.95.95 0 0 1-.867-1.338l1.415-3.16V9.49l.005-.25A7.5 7.5 0 0 1 12 2.004Z'}"></mosaik-button>
                        <mosaik-button slot="end"
                                       .appearance="${Appearance.Plain}"
                                       .icon="${'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'}"></mosaik-button>

                    </mosaik-titlebar>
                `,
                properties: META.properties<TitleBarElement>(TitleBarElement.is, [
                    {
                        key: 'title',
                        value: 'Title'
                    },
                    {
                        key: 'icon',
                        value: 'M12 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7-7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7-7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z'
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<TitleBarElement>(TitleBarElement.is, [
                    {
                        key: 'title',
                        value: 'Title'
                    },
                    {
                        key: 'icon',
                        value: 'M12 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7-7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7-7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM5 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z'
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<TitleBarElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                titlebarElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-titlebar-sample': TtitleBarSampleElement;
    }
}
