//#region Imports

import { Component, TileListElement } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { tileListElementDocs } from '../../../.Generated/mosaik-tile-list';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { TileListSampleTemplates } from './TileListSample.Templates';

//#endregion

/**
 * The `{@link TileListSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tile-list-sample',
    imports: [
        TileListElement
    ]
})
export class TileListSampleElement extends SampleBaseElement<TileListElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TileListElement> {
        return {
            header: 'Tile List',
            description: META.description(TileListElement.is),
            graph: META.graph(TileListElement.is),
            playground: {
                template: TileListSampleTemplates.defaultTemplate(),
                properties: META.properties<TileListElement>(TileListElement.is, [
                    { key: 'cols', value: 3 },
                    { key: 'rowHeight', value: '100px' },
                    { key: 'gutterSize', value: '15px' },
                    { key: 'autoFlow', value: 'column' }
                ]),
                events: META.events(TileListElement.is)
            },
            propertyGrid: {
                properties: META.properties<TileListElement>(TileListElement.is, [
                    { key: 'cols', value: 3 },
                    { key: 'rowHeight', value: '100px' },
                    { key: 'gutterSize', value: '15px' },
                    { key: 'autoFlow', value: 'column' }
                ])
            },
            docs: [
                tileListElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-tile-list-sample': TileListSampleElement;
    }
}

