// #region Imports

import { CSSResultGroup, Component, FlowDirection, WizardElement, html, ref } from '@breadstone/mosaik-elements-foundation';
import { wizardElementDocs } from '../../../.Generated/mosaik-wizard';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link WizardSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-wizard-sample'
})
export class WizardSampleElement extends SampleBaseElement<WizardElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<WizardElement> {
        return {
            header: 'Wizard',
            description: META.description(WizardElement.is),
            graph: META.graph(WizardElement.is),
            playground: {
                template: (_props, set, get) => html`
                    <mosaik-wizard ${ref((el) => set(el as WizardElement))}>
                        <mosaik-wizard-step .isActive="${true}"
                                            .header="${'Wizard step 1'}"
                                            .subHeader="${'The first step'}">
                            <mosaik-button .label="${'Next'}"
                                           @click="${() => get()?.selectNext()}"></mosaik-button>
                        </mosaik-wizard-step>
                        <mosaik-wizard-step .header="${'Wizard step 2'}"
                                            .subHeader="${'the second step'}">
                            <mosaik-button .label="${'Previous'}"
                                           @click="${() => get()?.selectPrevious()}"></mosaik-button>
                            <mosaik-button .label="${'Next'}"
                                           @click="${() => get()?.selectNext()}"></mosaik-button>
                        </mosaik-wizard-step>
                        <mosaik-wizard-step .header="${'Wizard step 3'}"
                                            .subHeader="${'The last step'}">
                            <mosaik-button .label="${'Finish'}"></mosaik-button>
                        </mosaik-wizard-step>
                    </mosaik-wizard>
                `,
                properties: META.properties<WizardElement>(WizardElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<WizardElement>(WizardElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ]),
                exludedProperties: ['selectedItem', 'items']
            },
            docs: [
                wizardElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-wizard-sample': WizardSampleElement;
    }
}
