// #region Imports

import { Appearance, BadgeElement, BadgePosition, Component, FlowDirection, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { badgeElementDocs } from '../../../.Generated/mosaik-badge';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link BadgeSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-badge-sample'
})
export class BadgeSampleElement extends SampleBaseElement<BadgeElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<BadgeElement> {

        return {
            header: 'Badge',
            description: META.description(BadgeElement.is),
            graph: META.graph(BadgeElement.is),
            playground: {
                template: html`
                    <mosaik-badge></mosaik-badge>
                `,
                properties: META.properties<BadgeElement>(BadgeElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'icon',
                        value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'label',
                        value: '100'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'position',
                        value: BadgePosition.TopRight
                    },
                    {
                        key: 'standalone',
                        value: true
                    }
                ]),
                events: META.events<BadgeElement>(BadgeElement.is)
            },
            propertyGrid: {
                properties: META.properties<BadgeElement>(BadgeElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'icon',
                        value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'label',
                        value: '100'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'position',
                        value: BadgePosition.TopRight
                    },
                    {
                        key: 'standalone',
                        value: true
                    }
                ])
            },
            docs: [
                badgeElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-badge-sample': BadgeSampleElement;
    }
}
