
export namespace Icons {

    export const ERROR_CIRCLE = 'M12 2c5.523 0 10 4.478 10 10s-4.477 10-10 10S2 17.522 2 12 6.477 2 12 2Zm.002 13.004a.999.999 0 1 0 0 1.997.999.999 0 0 0 0-1.997ZM12 7a1 1 0 0 0-.993.884L11 8l.002 5.001.007.117a1 1 0 0 0 1.986 0l.007-.117L13 8l-.007-.117A1 1 0 0 0 12 7Z';
    export const PERSON = 'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z';
    export const DELETE = 'M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1Zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648Zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648ZM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5Z';
    export const EDIT = 'M13.94 5 19 10.06 9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L13.938 5Zm7.09-2.03a3.578 3.578 0 0 1 0 5.06l-.97.97L15 3.94l.97-.97a3.578 3.578 0 0 1 5.06 0Z';
    export const WARNING = 'M10.909 2.782a2.25 2.25 0 0 1 2.975.74l.083.138 7.759 14.009a2.25 2.25 0 0 1-1.814 3.334l-.154.006H4.242A2.25 2.25 0 0 1 2.2 17.812l.072-.143L10.03 3.66a2.25 2.25 0 0 1 .879-.878ZM12 16.002a.999.999 0 1 0 0 1.997.999.999 0 0 0 0-1.997Zm-.002-8.004a1 1 0 0 0-.993.884L11 8.998 11 14l.007.117a1 1 0 0 0 1.987 0l.006-.117L13 8.998l-.007-.117a1 1 0 0 0-.994-.883Z';
    export const SYMBOLS = 'M16.918 18a.75.75 0 0 1 .102 1.493l-.102.007h-1.036a2.999 2.999 0 0 0 4.702-.29.75.75 0 1 1 1.232.856A4.495 4.495 0 0 1 18.118 22a4.494 4.494 0 0 1-2.95-1.101v.351a.75.75 0 0 1-1.493.102l-.007-.102v-2.5a.75.75 0 0 1 .649-.743l.101-.007h2.5Zm-10.52-4.993L6.5 13a.75.75 0 0 1 .743.648l.007.102v3h3a.75.75 0 0 1 .743.648L11 17.5a.75.75 0 0 1-.648.743l-.102.007h-3v3a.75.75 0 0 1-.648.743L6.5 22a.75.75 0 0 1-.743-.648l-.007-.102v-3h-3a.75.75 0 0 1-.743-.648L2 17.5a.75.75 0 0 1 .648-.743l.102-.007h3v-3a.75.75 0 0 1 .648-.743L6.5 13l-.102.007ZM17.5 13c1.113 0 2.151.408 2.95 1.101v-.351a.75.75 0 0 1 1.493-.102l.007.102v2.5a.75.75 0 0 1-.648.743L21.2 17h-2.5a.75.75 0 0 1-.102-1.493l.102-.007h1.037a2.999 2.999 0 0 0-4.703.29.75.75 0 1 1-1.231-.856A4.495 4.495 0 0 1 17.5 13ZM6.5 2a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm12.714 0C20.766 2 22 3.39 22 5.079c0 .862-.323 1.671-.852 2.214l-2.502 3.141a1.49 1.49 0 0 1-2.343-.006l-2.676-3.402A3.274 3.274 0 0 1 13 5.079C13 3.39 14.234 2 15.786 2c.564 0 1.1.186 1.55.52l.164.131.165-.131c.449-.334.985-.52 1.55-.52Zm-15.3 2.977a3 3 0 0 0 4.108 4.108L3.916 4.977ZM6.5 3.5c-.556 0-1.076.151-1.523.415l4.108 4.108A3 3 0 0 0 6.5 3.5Z';
    export const ARROW_RIGHT = 'M13.704 4.284a1 1 0 1 0-1.403 1.424L17.67 11H4a1 1 0 1 0 0 2h13.665L12.3 18.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823Z';
    export const ARROW_LEFT = 'M10.295 19.716a1 1 0 0 0 1.404-1.425l-5.37-5.29h13.67a1 1 0 1 0 0-2H6.336L11.7 5.714a1 1 0 0 0-1.404-1.424l-6.924 6.822a1.25 1.25 0 0 0 0 1.78l6.924 6.823Z';
    export const COLOR = 'M3.839 5.858c2.94-3.916 9.03-5.055 13.364-2.36 4.28 2.66 5.854 7.777 4.1 12.577-1.655 4.533-6.016 6.328-9.159 4.048-1.177-.854-1.634-1.925-1.854-3.664l-.106-.987-.045-.398c-.123-.934-.311-1.352-.705-1.572-.535-.298-.892-.305-1.595-.033l-.351.146-.179.078c-1.014.44-1.688.595-2.541.416l-.2-.047-.164-.047c-2.789-.864-3.202-4.647-.565-8.157Zm12.928 4.722a1.25 1.25 0 1 0 2.415-.647 1.25 1.25 0 0 0-2.415.647Zm.495 3.488a1.25 1.25 0 1 0 2.414-.647 1.25 1.25 0 0 0-2.414.647Zm-2.474-6.491a1.25 1.25 0 1 0 2.415-.647 1.25 1.25 0 0 0-2.415.647Zm-.028 8.998a1.25 1.25 0 1 0 2.415-.647 1.25 1.25 0 0 0-2.415.647Zm-3.497-9.97a1.25 1.25 0 1 0 2.415-.646 1.25 1.25 0 0 0-2.415.646Z';
    export const CLOSE = 'M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z';
    export const CHAT = 'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.644-1.142l-4.29 1.117a.85.85 0 0 1-1.037-1.036l1.116-4.289A9.959 9.959 0 0 1 2 12C2 6.477 6.477 2 12 2Zm1.252 11H8.75l-.102.007a.75.75 0 0 0 0 1.486l.102.007h4.502l.101-.007a.75.75 0 0 0 0-1.486L13.252 13Zm1.998-3.5h-6.5l-.102.007a.75.75 0 0 0 0 1.486L8.75 11h6.5l.102-.007a.75.75 0 0 0 0-1.486L15.25 9.5Z';
}
