// #region Imports

import { Animate, Appearance, Fit, HorizontalAlignment, Inset, Orientation, TextAlignment, Variant, VerticalAlignment, animate, html, translate, typography, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import type { NotFoundView } from './NotFoundView';

// #endregion

/**
 * The template of the {@link NotFoundViewElement}.
 *
 * @public
 */
export function notFoundViewTemplate<T extends NotFoundView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${'404'}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-content part="content"
                                 .inset="${[Inset.All]}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .verticalAlignment="${VerticalAlignment.Center}"
                              .horizontalAlignment="${HorizontalAlignment.Center}"
                              .fit="${Fit.Both}">
                    <mosaik-icon part="icon"
                                 ${mouseMovementChild()}
                                 .data="${'M12 2c5.523 0 10 4.478 10 10s-4.477 10-10 10S2 17.522 2 12 6.477 2 12 2Zm0 13.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0-8.75A2.75 2.75 0 0 0 9.25 9.5a.75.75 0 0 0 1.493.102l.007-.102a1.25 1.25 0 1 1 2.5 0c0 .539-.135.805-.645 1.332l-.135.138c-.878.878-1.22 1.447-1.22 2.53a.75.75 0 0 0 1.5 0c0-.539.135-.805.645-1.332l.135-.138c.878-.878 1.22-1.447 1.22-2.53A2.75 2.75 0 0 0 12 6.75Z'}"></mosaik-icon>
                    <mosaik-text ${typography('headline1')}
                                 .text="${translate('loc.notFound.title')}"
                                 .alignment="${TextAlignment.Center}"></mosaik-text>
                    <mosaik-text ${typography('headline5')}
                                 .text="${translate('loc.notFound.description')}"
                                 .wrap="${true}"
                                 .alignment="${TextAlignment.Center}"></mosaik-text>
                    <mosaik-spacer thickness="all"></mosaik-spacer>
                    <mosaik-router-link .href="${'/'}">
                    <mosaik-button .label="${translate('loc.notFound.action')}"
                                   .variant="${Variant.Primary}"
                                   .appearance="${Appearance.Outline}"></mosaik-button>
                    </mosaik-router-link>
                </mosaik-stack>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
