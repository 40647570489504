// #region Imports

import { Appearance, CommentElement, Component, FlowDirection, Size } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { commentElementDocs } from '../../../.Generated/mosaik-comment';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link CommentSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-comment-sample'
})
export class CommentSampleElement extends SampleBaseElement<CommentElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CommentElement> {

        return {
            header: 'Comment',
            description: META.description(CommentElement.is),
            graph: META.graph(CommentElement.is),
            playground: {
                template: html`
                    <mosaik-comment>
                        <mosaik-avatar slot="avatar"
                                       .text="${'John Doe'}"
                                       .size="${Size.Tiny}"
                                       .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-avatar>
                        <mosaik-button slot="actions"
                                       .icon="${'m12.82 5.58-.82.822-.824-.824a5.375 5.375 0 1 0-7.601 7.602l7.895 7.895a.75.75 0 0 0 1.06 0l7.902-7.897a5.376 5.376 0 0 0-.001-7.599 5.38 5.38 0 0 0-7.611 0Z'}"
                                       .appearance="${Appearance.Plain}"
                                       .iconSize="${Size.Small}"></mosaik-button>
                        <mosaik-button slot="actions"
                                       .icon="${'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.644-1.142l-4.29 1.117a.85.85 0 0 1-1.037-1.036l1.116-4.289A9.959 9.959 0 0 1 2 12C2 6.477 6.477 2 12 2Zm1.252 11H8.75l-.102.007a.75.75 0 0 0 0 1.486l.102.007h4.502l.101-.007a.75.75 0 0 0 0-1.486L13.252 13Zm1.998-3.5h-6.5l-.102.007a.75.75 0 0 0 0 1.486L8.75 11h6.5l.102-.007a.75.75 0 0 0 0-1.486L15.25 9.5Z'}"
                                       .appearance="${Appearance.Plain}"
                                       .iconSize="${Size.Small}"></mosaik-button>

                        <mosaik-comment .author="${'Chai Montana'}" .content="${'Awesome 💪'}" .at="${'Now'}">
                            <mosaik-avatar slot="avatar"
                                           .text="${'Chai Montana'}"
                                           .size="${Size.Tiny}"
                                           .src="${'https://images.unsplash.com/photo-1650951650932-1b7c3662c230?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"></mosaik-avatar>
                        </mosaik-comment>
                        <mosaik-comment .author="${'Pin Tan'}" .content="${'สุดยอด 🥰'}" .at="${'Now'}">
                            <mosaik-avatar slot="avatar"
                                           .text="${'Pin Tan'}"
                                           .size="${Size.Tiny}"
                                           .src="${'https://images.unsplash.com/photo-1521566652839-697aa473761a?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"></mosaik-avatar>
                        </mosaik-comment>
                    </mosaik-comment>
                `,
                properties: META.properties<CommentElement>(CommentElement.is, [
                    // { key: 'appearance', value: Appearance.Soft },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'author',
                        value: 'John Doe'
                    },
                    {
                        key: 'at',
                        value: 'Today'
                    },
                    {
                        key: 'content',
                        value: 'This is my very cool first post 🎉'
                    }
                ]),
                events: META.events<CommentElement>(CommentElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<CommentElement>(CommentElement.is, [
                    // { key: 'appearance', value: Appearance.Soft },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'author',
                        value: 'John Doe'
                    },
                    {
                        key: 'at',
                        value: 'Today'
                    },
                    {
                        key: 'content',
                        value: 'This is my very cool first post 🎉'
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<CommentElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                commentElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-comment-sample': CommentSampleElement;
    }
}
