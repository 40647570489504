// #region Imports

import { Attribute, Closeable, Command, Component, CustomElement, DomServiceLocator, Drawer, ICommand, Inject, Property, RouterElement, SearchBoxElement, State, on, type IEventListenerSubscription, type RouteConfig } from '@breadstone/mosaik-elements-foundation';
import { ComponentsService } from '../../Services/ComponentsService';
import { appSideNavStyle } from './AppSideNavStyle';
import { appSideNavElementTemplate } from './AppSideNavTemplate';

// #endregion

interface INavGroup {
    name: string;
    path: string;
    items: Array<INavInfo>;
}

interface INavInfo {
    name: string;
    tags?: Array<string>;
    path: string;
    experimental: boolean;
}

/**
 * The `{@link AppSideNavElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-side-nav',
    template: appSideNavElementTemplate,
    styles: appSideNavStyle,
    providers: [
        {
            provide: ComponentsService,
            useClass: ComponentsService
        }
    ]
})
export class AppSideNavElement
    extends Closeable(CustomElement) {

    // #region Fields

    @State()
    public filter: string;
    // public nav = new Array<INavGroup>(
    //     {
    //         name: 'getStarted',
    //         path: 'getStarted',
    //         items: [
    //             {
    //                 name: 'introduction',
    //                 path: 'introduction',
    //                 experimental: false
    //             },
    //             {
    //                 name: 'installation',
    //                 path: 'installation',
    //                 experimental: false
    //             },
    //             {
    //                 name: 'usage',
    //                 path: 'usage',
    //                 experimental: false
    //             },
    //             {
    //                 name: 'support',
    //                 path: 'support',
    //                 experimental: false
    //             }]
    //     }
    //     // {
    //     //     name: 'Design', path: 'design', items: [
    //     //      ]
    //     // }
    // );
    @Inject(ComponentsService)
    private readonly _componentsService!: ComponentsService;
    private _currentRoute: RouteConfig | null;
    private _routerNavigatedSubscription: IEventListenerSubscription | null;
    private _closeOnNavigate: boolean;
    private _isOpen: boolean;
    private _isMobile: boolean;
    private readonly _filterCommand: Command<string | null>;
    private readonly _focusFilterCommand: Command;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this.closeable = true;
        this._currentRoute = null;
        this._isOpen = false;
        this._routerNavigatedSubscription = null;
        this._closeOnNavigate = false;
        this._isMobile = false;
        this.filter = '';

        this._filterCommand = new Command((x) => this.onExecuteFilterCommand(x));
        this._focusFilterCommand = new Command(() => this.onExecuteFocusFilterCommand());
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-side-nav';
    }

    /**
     * Gets or sets the `currentRoute` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get currentRoute(): RouteConfig | null {
        return this._currentRoute;
    }
    public set currentRoute(value: RouteConfig | null) {
        if (this._currentRoute !== value) {
            this._currentRoute = value;
            this.requestUpdate('currentRoute');
        }
    }

    /**
     * Gets or sets the `closeOnNavigate` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get closeOnNavigate(): boolean {
        return this._closeOnNavigate;
    }
    public set closeOnNavigate(value: boolean) {
        if (this._closeOnNavigate !== value) {
            this._closeOnNavigate = value;
            this.requestUpdate('closeOnNavigate');
        }
    }

    /**
     * Gets or sets the `isOpen` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get isOpen(): boolean {
        return this._isOpen;
    }
    public set isOpen(value: boolean) {
        if (this._isOpen !== value) {
            this._isOpen = value;
            this.requestUpdate('isOpen');
        }
    }

    /**
     * Gets or sets the `isMobile` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get isMobile(): boolean {
        return this._isMobile;
    }
    public set isMobile(value: boolean) {
        if (this._isMobile !== value) {
            this._isMobile = value;
            this.requestUpdate('isMobile');
        }
    }

    /**
     * Returns the `filterCommand` command property.
     *
     * @public
     * @readonly
     */
    public get filterCommand(): ICommand<string | null> {
        return this._filterCommand;
    }

    /**
     * Returns the `focusFilterCommand` command property.
     *
     * @public
     * @readonly
     */
    public get focusFilterCommand(): ICommand {
        return this._focusFilterCommand;
    }

    // #endregion

    // #region Methods

    public override close(): void {
        if (!this._closeOnNavigate) {
            return;
        }

        super.close();
    }

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
    }

    /**
     * @public
     * @override
     */
    public override disconnectedCallback(): void {
        super.disconnectedCallback();

        this._routerNavigatedSubscription?.dispose();
    }

    /**
     * @protected
     * @override
     */
    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        const router = DomServiceLocator.current.findAncestor(this, RouterElement, { strict: true });

        this._routerNavigatedSubscription = on(router, 'navigated', (e) => {
            this.currentRoute = e.detail.route;
        });
    }

    /**
     * Executes the `filterCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteFilterCommand(parameter: string | null): void {
        this.filter = parameter ?? '';
    }

    /**
     * Executes the `focusFilterCommand` command.
     *
     * @private
     */
    private onExecuteFocusFilterCommand(): void {
        if (!this.isOpen) {
            Drawer.open('drawer');
        }

        this.getTemplatePart<SearchBoxElement>('search').focus();
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-side-nav': AppSideNavElement;
    }
}
