// #region Imports

import { Appearance, Component, FlowDirection, InputFormatters, InputParsers, NumberBoxElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { numberboxElementDocs } from '../../../.Generated/mosaik-numberbox';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate, numberTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link NumberBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-numberbox-sample'
})
export class NumberBoxSampleElement extends SampleBaseElement<NumberBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<NumberBoxElement> {
        return {
            header: 'Number Box',
            description: META.description(NumberBoxElement.is),
            graph: META.graph(NumberBoxElement.is),
            playground: {
                template: [
                    ['default', () => html`
                        <mosaik-numberbox></mosaik-numberbox>
                    `],
                    ['currency', () => html`
                        <mosaik-numberbox>
                            <mosaik-text slot="suffix"
                                         .text="${'€'}"></mosaik-text>
                        </mosaik-numberbox>
                    `, [
                        {
                            key: 'label',
                            value: 'Currency'
                        },
                        {
                            key: 'formatter',
                            value: InputFormatters.currency('USD', 'en-US')
                        },
                        {
                            key: 'parser',
                            value: InputParsers.currency('en-US', 'USD')
                        }
                    ]
                    ],
                    ['percent', () => html`
                        <mosaik-numberbox>
                            <mosaik-text slot="suffix"
                                         .text="${'%'}"></mosaik-text>
                        </mosaik-numberbox>
                    `, [
                            {
                                key: 'label',
                                value: 'Percent'
                            },
                            {
                                key: 'formatter',
                                value: InputFormatters.percentage(0)
                            },
                            {
                                key: 'parser',
                                value: InputParsers.percentage()
                            }
                        ]]
                ],
                properties: META.properties<NumberBoxElement>(NumberBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'label',
                        value: 'Number'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'value',
                        value: 0
                    },
                    {
                        key: 'step',
                        value: 1
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'max',
                        value: 100
                    },
                    {
                        key: 'formatter',
                        value: InputFormatters.custom(2, 'en-US')
                    },
                    {
                        key: 'parser',
                        value: InputParsers.custom('en-US')
                    }
                ]),
                events: META.events(NumberBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<NumberBoxElement>(NumberBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'label',
                        value: 'Number'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'value',
                        value: 0
                    },
                    {
                        key: 'step',
                        value: 1
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'max',
                        value: 100
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: functionTemplate
                    },
                    {
                        key: 'parser',
                        template: functionTemplate
                    },
                    {
                        key: 'value',
                        template: numberTemplate
                    }
                ] as Array<IPlaygroundProperty<NumberBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                numberboxElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-numberbox-sample': NumberBoxSampleElement;
    }
}
