
// #region Imports

import { Attribute, Component } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../Abstracts/ViewBase';
import { appLogoStyle } from './AppLogoStyle';
import { appLogoElementTemplate } from './AppLogoTemplate';

// #endregion

/**
 * The `{@link LogoElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-logo',
    template: appLogoElementTemplate,
    styles: appLogoStyle()
})
export class AppLogoElement
    extends ViewBase {

    // #region Fields

    private _type: 'logo' | 'text' | 'both';

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._type = 'both';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-logo';
    }

    /**
     * Gets or sets the `type` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get type(): 'logo' | 'text' | 'both' {
        return this._type;
    }

    public set type(value: 'logo' | 'text' | 'both') {
        if (this._type !== value) {
            this._type = value;
            this.requestUpdate('type');
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-logo': AppLogoElement;
    }
}
