// #region Imports

import { Appearance, ButtonType, Component, FloatingActionButtonElement, FlowDirection, IconPosition, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import type { CSSResultGroup } from 'lit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { fabElementDocs } from '../../../.Generated/mosaik-fab';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { iconSizeTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { FloatingActionButtonSampleTemplates } from './FloatingActionButtonSample.Templates';

// #endregion

/**
 * The `{@link FloatingActionButtonSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-fab-sample'
})
export class FloatingActionButtonSampleElement extends SampleBaseElement<FloatingActionButtonElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<FloatingActionButtonElement> {
        return {
            header: 'Floating Action Button',
            description: META.description(FloatingActionButtonElement.is),
            graph: META.graph(FloatingActionButtonElement.is),
            playground: {
                template: [
                    FloatingActionButtonSampleTemplates.defaultTemplate(),
                    FloatingActionButtonSampleTemplates.withBadgeTemplate()
                ],
                properties: META.properties<FloatingActionButtonElement>(FloatingActionButtonElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'icon',
                        value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]),
                events: META.events(FloatingActionButtonElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<FloatingActionButtonElement>(FloatingActionButtonElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'icon',
                        value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'
                    },
                    {
                        key: 'iconPosition',
                        value: IconPosition.Before
                    },
                    {
                        key: 'iconSize',
                        value: Size.Medium
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'type',
                        value: ButtonType.Button
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<FloatingActionButtonElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                fabElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-fab-sample': FloatingActionButtonSampleElement;
    }
}
