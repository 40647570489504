// #region Imports

import { HtmlCodeGenerator } from './Abstracts/HtmlCodeGenerator';
import type { ICodeModel } from './Interfaces/ICodeGenerator';

// #endregion

/**
 * Generates Vue HTML.
 */
export class VueHtmlGenerator extends HtmlCodeGenerator {

    // #region Methods

    public process(model: ICodeModel): string {
        const attributes = Object.entries(model.properties)
            .map(([key, value]) => {
                if (value === undefined || value === null || value === '') {
                    return '';
                }

                return `:${key}="${this.formatValue(value)}"`;
            })
            .filter((x) => x !== '')
            .join(' ');
        const name = `${model.componentPrefix}-${this.formatName(model.componentName)}`;

        return `<${name} ${attributes}></${name}>`;
    }

    private formatValue(value: any): string {
        if (typeof value === 'string') {
            return `'${value}'`;
        }

        if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
        }

        if (typeof value === 'number') {
            return value.toString();
        }

        return String(value);
    }

    private formatName(name: string): string {
        return name.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-')
            .toLowerCase();
    }

    // #endregion

}
