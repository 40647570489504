// #region Imports

import { Appearance, Inset, Orientation, Size, type TemplateResult, Variant, html, repeat, translate, typography, when } from '@breadstone/mosaik-elements-foundation';
import { ModifierKeys } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/Key';
import { COMPONENTS, type IComponentInfo } from '../../Services/ComponentsService';
import type { AppSideNavElement } from './AppSideNav';

// #endregion

/**
 * The template of the {@link AppSideNavElement}.
 *
 * @public
 */
export function appSideNavElementTemplate<T extends AppSideNavElement>(e: T): TemplateResult {
    const filterComponents = (filter: string, component: IComponentInfo) => {
        if (filter.trim()) {
            // first condition is for the component name, second condition is for the tags
            const hasName = component.name.toLowerCase().includes(e.filter.trim().toLowerCase());
            const hasTag = (component.tags ?? []).some((x) => x.includes(e.filter.trim()));

            return hasName || hasTag;
        }

        return true;
    };

    return html`
        <mosaik-kbd-shortcut .gesture="${{
            key: 's',
            modifiers: ModifierKeys.Shift
        }}"
                             @change="${() => e.focusFilterCommand.execute()}"></mosaik-kbd-shortcut>
        <nav part="nav">
            <mosaik-spacer thickness="all">
            <mosaik-sticky .top="${'24px'}">
                <mosaik-searchbox part="search"
                                  .mode="${'input'}"
                                  .isClearable="${true}"
                                  .placeholder="${translate('loc.global.search')}"
                                  .variant="${Variant.Primary}"
                                  @searched="${(x: CustomEvent<string>) => e.filterCommand.execute(x.detail)}"
                                  @cleared="${(x: CustomEvent<string>) => e.filterCommand.execute(null)}">
                        <mosaik-kbd slot="suffix"
                                    .value="${['Shift', 'S']}"></mosaik-kbd>
                </mosaik-searchbox>
            </mosaik-sticky>
            <!----------------->
            <!-- Get Started -->
            <!----------------->
            <mosaik-stack part="nav-group"
                          .orientation="${Orientation.Vertical}">
                <mosaik-router-link .href="${'/getStarted'}"
                                    @click="${() => e.close()}">
                    <mosaik-text part="nav-group-header"
                                 ${typography('overline')}
                                 .text="${translate('loc.nav.getStarted')}"></mosaik-text>
                </mosaik-router-link>
                <mosaik-menu .inset="${Inset.None}">
                    ${when(e.filter.trim() ? 'Introduction'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/introduction">
                        <mosaik-menu-item .label="${translate('loc.nav.introduction')}"
                                          ?is-active="${e.currentRoute?.name === 'Introduction'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Installation'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/installation">
                        <mosaik-menu-item .label="${translate('loc.nav.installation')}"
                                          ?is-active="${e.currentRoute?.name === 'Installation'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Usage'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/usage">
                        <mosaik-menu-item .label="${translate('loc.nav.usage')}"
                                          ?is-active="${e.currentRoute?.name === 'Usage'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Support'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/support">
                        <mosaik-menu-item .label="${translate('loc.nav.support')}"
                                          ?is-active="${e.currentRoute?.name === 'Support'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                </mosaik-menu>
            </mosaik-stack>
            <!------------>
            <!-- Design -->
            <!------------>
            <mosaik-stack part="nav-group"
                          orientation="${Orientation.Vertical}">
                <mosaik-router-link .href="${'/design'}"
                                    @click="${() => e.close()}">
                    <mosaik-text part="nav-group-header"
                                 .text="${translate('loc.nav.design')}"
                                 ${typography('overline')}></mosaik-text>
                </mosaik-router-link>
                <mosaik-menu .inset="${Inset.None}">
                    ${when(e.filter.trim() ? 'Layout'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/layout">
                        <mosaik-menu-item .label="${translate('loc.nav.layout')}"
                                          ?is-active="${e.currentRoute?.name === 'Layout'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Breakpoint'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/breakpoint">
                        <mosaik-menu-item .label="${translate('loc.nav.breakpoint')}"
                                          ?is-active="${e.currentRoute?.name === 'Breakpoint'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Visibility'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/visibility">
                        <mosaik-menu-item .label="${translate('loc.nav.visibility')}"
                                          ?is-active="${e.currentRoute?.name === 'Visibility'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Scheme'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/scheme">
                        <mosaik-menu-item .label="${translate('loc.nav.scheme')}"
                                          ?is-active="${e.currentRoute?.name === 'Scheme'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Color'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/color">
                        <mosaik-menu-item .label="${translate('loc.nav.color')}"
                                          ?is-active="${e.currentRoute?.name === 'Color'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Typography'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/typography">
                        <mosaik-menu-item .label="${translate('loc.nav.typography')}"
                                          ?is-active="${e.currentRoute?.name === 'Typography'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Elevation'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/elevation">
                        <mosaik-menu-item .label="${translate('loc.nav.elevation')}"
                                          ?is-active="${e.currentRoute?.name === 'Elevation'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Formatting'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/formatting">
                        <mosaik-menu-item .label="${translate('loc.nav.formatting')}"
                                          ?is-active="${e.currentRoute?.name === 'Formatting'}"
                                          @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                </mosaik-menu>
            </mosaik-stack>
            <!---------------->
            <!-- Components -->
            <!---------------->
            <mosaik-stack part="nav-group"
                          .orientation="${Orientation.Vertical}">
                <mosaik-router-link .href="${'/components'}"
                                    @click="${() => e.close()}">
                    <mosaik-text part="nav-group-header"
                                 .text="${translate('loc.nav.components')}"
                                 ${typography('overline')}></mosaik-text>
                </mosaik-router-link>
                ${repeat(COMPONENTS.getComponents(), (x) => x, (x) => html`
                <mosaik-menu inset="${Inset.None}">
                    ${when(filterComponents(e.filter, x), () => html`
                    <mosaik-router-link .href="${x.path}">
                        <mosaik-menu-item .label="${x.name}"
                                          ?is-active="${e.currentRoute?.name === x.name}"
                                          @click="${() => e.close()}">
                            ${when(x.experimental, () => html`
                            <mosaik-chip slot="end"
                                         .size="${Size.Small}"
                                         .variant="${Variant.Highlight}"
                                         .appearance="${Appearance.Soft}"
                                         .label="${translate('loc.global.preview')}"></mosaik-chip>
                            `)}
                        </mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                </mosaik-menu>
                `)}
            </mosaik-stack>
            <!----------->
            <!-- Tools -->
            <!----------->
            <mosaik-stack part="nav-group" orientation="${Orientation.Vertical}">
                <mosaik-router-link .href="${'/tools'}" @click="${() => e.close()}">
                    <mosaik-text part="nav-group-header"
                                    .text="${translate('loc.nav.tools')}"
                                    ${typography('overline')}></mosaik-text>
                </mosaik-router-link>
                <mosaik-menu .inset="${Inset.None}">
                    ${when(e.filter.trim() ? 'Icon Gallery'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/tools/icons">
                        <mosaik-menu-item .label="${translate('loc.nav.iconGallery')}"
                                            ?is-active="${e.currentRoute?.name === 'Icon Gallery'}"
                                            @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Theme Generator'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/tools/themeGenerator">
                        <mosaik-menu-item .label="${translate('loc.nav.themeGenerator')}"
                                            ?is-active="${e.currentRoute?.name === 'Theme Generator'}"
                                            @click="${() => e.close()}"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                    ${when(e.filter.trim() ? 'Grid Generator'.toLowerCase().includes(e.filter.trim().toLowerCase()) : true, () => html`
                    <mosaik-router-link href="/tools/gridGenerator"
                                        ?disabled="${true}">
                        <mosaik-menu-item .label="${translate('loc.nav.gridGenerator')}"
                                            ?is-active="${e.currentRoute?.name === 'Grid Generator'}"
                                            ?disabled="${true}"
                                            @click="${() => e.close()}">
                            <mosaik-chip slot="end"
                                                .size="${Size.Small}"
                                                .variant="${Variant.Tertiary}"
                                                .appearance="${Appearance.Soft}"
                                                .label="${translate('loc.global.soon')}"></mosaik-chip>
                        </mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                </mosaik-menu>
            </mosaik-stack>
            </mosaik-spacer>
        </nav>
        <slot name="end"></slot>
    `;
}
