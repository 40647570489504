// #region Imports

import { Component, CustomElement, Disableable, IDisableableProps, IValueableProps, Property, Valueable } from '@breadstone/mosaik-elements-foundation';
import { Clearable, IClearableEvents, IClearableProps } from '@breadstone/mosaik-elements-foundation/dist/Controls/Behaviors/Clearable';
import { groupBy } from '../../Extensions/ArrayExtensions';
import { playgroundLogElementStyle } from './PlaygroundLogElementStyle';
import { playgroundLogElementTemplate } from './PlaygroundLogElementTemplate';

// #endregion

export interface ILogEntry {
    title: string;
    detail?: any;
    event?: Event;
}

/**
 * Represents the `{@link IPlaygroundLogElementProps}` interface.
 *
 * @public
 */
export interface IPlaygroundLogElementProps
    extends IClearableProps, IValueableProps, IDisableableProps {

    value: Array<ILogEntry>;

}

/**
 * The `{@link PlaygroundLogElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-playground-log',
    template: playgroundLogElementTemplate,
    styles: playgroundLogElementStyle,
    imports: []
})
export class PlaygroundLogElement
    extends Clearable(Valueable(Disableable(CustomElement)))
    implements IPlaygroundLogElementProps, IClearableEvents {

    // #region Fields

    private _value: Array<ILogEntry>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._value = [];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-playground-log';
    }

    /**
     * Gets or sets the `value` property.
     *
     * @public
     */
    @Property({ type: Array<ILogEntry> })
    public override get value(): Array<ILogEntry> {
        return this._value;
    }

    public override set value(value: Array<ILogEntry>) {
        if (this._value !== value) {
            this._value = value;
            this.requestUpdate('value');
        }
    }

    // #endregion

    // #region Methods

    public getGroupedEntries(): Array<{
        key: string | undefined;
        items: Array<ILogEntry>;
    }> {
        const groups = groupBy(this.value, (x) => x.event?.type);
        return groups;
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-playground-log': PlaygroundLogElement;
    }
}
