//#region Imports

import { Appearance, Component, FlowDirection, Placement, SelectElement, Strategy, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { selectElementDocs } from '../../../.Generated/mosaik-select';
import { selectItemElementDocs } from '../../../.Generated/mosaik-select-item';
import { selectItemGroupElementDocs } from '../../../.Generated/mosaik-select-item-group';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link SelectSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-select-sample'
})
export class SelectSampleElement extends SampleBaseElement<SelectElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SelectElement> {
        return {
            header: 'Select',
            description: META.description(SelectElement.is),
            graph: META.graph(SelectElement.is),
            playground: {
                template: [['default', () => html`
                    <mosaik-select>
                        <mosaik-select-item .label="${'None'}"
                                            .isSelected="${true}"
                                            .isActive="${true}"></mosaik-select-item>
                        <mosaik-select-item label="Asia"></mosaik-select-item>
                        <mosaik-select-item label="Africa"></mosaik-select-item>
                        <mosaik-select-item label="North America"></mosaik-select-item>
                        <mosaik-select-item label="South America"></mosaik-select-item>
                        <mosaik-select-item label="Antarctica"></mosaik-select-item>
                        <mosaik-select-item label="Europe"></mosaik-select-item>
                        <mosaik-select-item label="Australia"></mosaik-select-item>
                    </mosaik-select>
                `], ['with groups', () => html`
                    <mosaik-select>
                        <mosaik-select-item-group header="Asia">
                            <mosaik-select-item label="🇮🇳 India"
                                                .isSelected="${true}"
                                                .isActive="${true}"></mosaik-select-item>
                            <mosaik-select-item label="🇨🇳 China"></mosaik-select-item>
                            <mosaik-select-item label="🇯🇵 Japan"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="Africa">
                            <mosaik-select-item label="🇿🇦 South Africa"></mosaik-select-item>
                            <mosaik-select-item label="🇪🇬 Egypt"></mosaik-select-item>
                            <mosaik-select-item label="🇳🇬 Nigeria"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="North America">
                            <mosaik-select-item label="🇺🇸 USA"></mosaik-select-item>
                            <mosaik-select-item label="🇨🇦 Canada"></mosaik-select-item>
                            <mosaik-select-item label="🇲🇽 Mexico"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="South America">
                            <mosaik-select-item label="🇧🇷 Brazil"></mosaik-select-item>
                            <mosaik-select-item label="🇦🇷 Argentina"></mosaik-select-item>
                            <mosaik-select-item label="🇨🇱 Chile"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="Antarctica">
                            <mosaik-select-item label="Antarctica"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="Europe">
                            <mosaik-select-item label="🇬🇧 UK"></mosaik-select-item>
                            <mosaik-select-item label="🇩🇪 Germany"></mosaik-select-item>
                            <mosaik-select-item label="🇫🇷 France"></mosaik-select-item>
                        </mosaik-select-item-group>
                        <mosaik-select-item-group header="Australia">
                            <mosaik-select-item label="🇦🇺 Australia"></mosaik-select-item>
                        </mosaik-select-item-group>
                    </mosaik-select>
                `], ['with prefix', () => html`
                    <mosaik-select>
                        <mosaik-icon inline="${true}" slot="prefix" data="M8.904 16.5h6.192C14.476 19.773 13.234 22 12 22c-1.197 0-2.4-2.094-3.038-5.204l-.058-.294h6.192-6.192Zm-5.838.001H7.37c.365 2.082.983 3.854 1.793 5.093a10.029 10.029 0 0 1-5.952-4.814l-.146-.279Zm13.563 0h4.305a10.028 10.028 0 0 1-6.097 5.093c.755-1.158 1.344-2.778 1.715-4.681l.076-.412h4.306-4.306Zm.302-6.5h4.87a10.055 10.055 0 0 1-.257 5H16.84a28.539 28.539 0 0 0 .13-4.344L16.93 10h4.87-4.87ZM2.198 10h4.87a28.211 28.211 0 0 0 .034 4.42l.057.58H2.456a10.047 10.047 0 0 1-.258-5Zm6.377 0h6.85a25.838 25.838 0 0 1-.037 4.425l-.062.575H8.674a25.979 25.979 0 0 1-.132-4.512L8.575 10h6.85-6.85Zm6.37-7.424-.109-.17A10.027 10.027 0 0 1 21.372 8.5H16.78c-.316-2.416-.956-4.492-1.837-5.923l-.108-.17.108.17Zm-5.903-.133.122-.037C8.283 3.757 7.628 5.736 7.28 8.06l-.061.44H2.628a10.028 10.028 0 0 1 6.414-6.057l.122-.037-.122.037ZM12 2.002c1.319 0 2.646 2.542 3.214 6.183l.047.315H8.739C9.28 4.691 10.643 2.002 12 2.002Z"></mosaik-icon>
                        <mosaik-select-item .label="${'None'}"
                                            .isSelected="${true}"
                                            .isActive="${true}"></mosaik-select-item>
                        <mosaik-select-item label="Asia"></mosaik-select-item>
                        <mosaik-select-item label="Africa"></mosaik-select-item>
                        <mosaik-select-item label="North America"></mosaik-select-item>
                        <mosaik-select-item label="South America"></mosaik-select-item>
                        <mosaik-select-item label="Antarctica"></mosaik-select-item>
                        <mosaik-select-item label="Europe"></mosaik-select-item>
                        <mosaik-select-item label="Australia"></mosaik-select-item>
                    </mosaik-select>
                `], ['with suffix', () => html`
                    <mosaik-select>
                        <mosaik-icon inline="${true}" slot="suffix" data="M8.904 16.5h6.192C14.476 19.773 13.234 22 12 22c-1.197 0-2.4-2.094-3.038-5.204l-.058-.294h6.192-6.192Zm-5.838.001H7.37c.365 2.082.983 3.854 1.793 5.093a10.029 10.029 0 0 1-5.952-4.814l-.146-.279Zm13.563 0h4.305a10.028 10.028 0 0 1-6.097 5.093c.755-1.158 1.344-2.778 1.715-4.681l.076-.412h4.306-4.306Zm.302-6.5h4.87a10.055 10.055 0 0 1-.257 5H16.84a28.539 28.539 0 0 0 .13-4.344L16.93 10h4.87-4.87ZM2.198 10h4.87a28.211 28.211 0 0 0 .034 4.42l.057.58H2.456a10.047 10.047 0 0 1-.258-5Zm6.377 0h6.85a25.838 25.838 0 0 1-.037 4.425l-.062.575H8.674a25.979 25.979 0 0 1-.132-4.512L8.575 10h6.85-6.85Zm6.37-7.424-.109-.17A10.027 10.027 0 0 1 21.372 8.5H16.78c-.316-2.416-.956-4.492-1.837-5.923l-.108-.17.108.17Zm-5.903-.133.122-.037C8.283 3.757 7.628 5.736 7.28 8.06l-.061.44H2.628a10.028 10.028 0 0 1 6.414-6.057l.122-.037-.122.037ZM12 2.002c1.319 0 2.646 2.542 3.214 6.183l.047.315H8.739C9.28 4.691 10.643 2.002 12 2.002Z"></mosaik-icon>
                        <mosaik-select-item .label="${'None'}"
                                            .isSelected="${true}"
                                            .isActive="${true}"></mosaik-select-item>
                        <mosaik-select-item label="Asia"></mosaik-select-item>
                        <mosaik-select-item label="Africa"></mosaik-select-item>
                        <mosaik-select-item label="North America"></mosaik-select-item>
                        <mosaik-select-item label="South America"></mosaik-select-item>
                        <mosaik-select-item label="Antarctica"></mosaik-select-item>
                        <mosaik-select-item label="Europe"></mosaik-select-item>
                        <mosaik-select-item label="Australia"></mosaik-select-item>
                    </mosaik-select>
                `]],
                properties: META.properties<SelectElement>(SelectElement.is, [
                    { key: 'disabled', value: false },
                    { key: 'dropDownStaysOpen', value: false },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dropDownDistance', value: 8 },
                    { key: 'dropDownPlacement', value: Placement.Bottom },
                    { key: 'dropDownSkidding', value: 0 },
                    { key: 'dropDownStrategy', value: Strategy.Absolute },
                    { key: 'isDropDownOpen', value: false },
                    { key: 'isClearable', value: true },
                    { key: 'disabled', value: false },
                    { key: 'dropDownStaysOpen', value: false },
                    { key: 'label', value: 'Continents' },
                ]),
                events: META.events(SelectElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<SelectElement>(SelectElement.is, [
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dropDownDistance', value: 8 },
                    { key: 'dropDownPlacement', value: Placement.Bottom },
                    { key: 'dropDownSkidding', value: 0 },
                    { key: 'dropDownStrategy', value: Strategy.Absolute },
                    { key: 'isDropDownOpen', value: false },
                    { key: 'isClearable', value: true },
                    { key: 'disabled', value: false },
                    { key: 'dropDownStaysOpen', value: HTMLInputElement },
                    { key: 'label', value: 'Continents' },
                ]), [
                    { key: 'maxDropDownHeight', template: cssLengtTemplate },
                    { key: 'value', template: valueTemplate }
                ] as Array<IPlaygroundProperty<SelectElement>>, (a, b) => a.key === b.key), [
                    { key: 'selectedItem' },
                ] as Array<IPlaygroundProperty<SelectElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                selectElementDocs(),
                selectItemElementDocs(),
                selectItemGroupElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-select-sample': SelectSampleElement;
    }
}

