// #region Imports

import { Component } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { notFoundViewStyle } from './NotFoundViewStyle';
import { notFoundViewTemplate } from './NotFoundViewTemplate';

// #endregion

/**
 * The `{@link NotFoundView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-not-found-view',
    template: notFoundViewTemplate,
    styles: [
        viewBaseStyle(),
        notFoundViewStyle()
    ],
    imports: []
})
export class NotFoundView extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-not-found-view';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-not-found-view': NotFoundView;
    }
}
