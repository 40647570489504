// #region Imports

import { Attribute, Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IEventResponse } from '../../Backend/APi/Models/IEventResponse';
import { EventsService } from '../../Backend/Api/Services/EventsService';
import { CouponElement } from '../../Components/Coupon/CouponElement';
import { AppointmentService } from '../../Services/AppointmentService';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { eventsViewStyle } from './EventsViewStyle';
import { eventsViewTemplate } from './EventsViewTemplate';

// #endregion

type EventFilter = 'past' | 'upcoming';
export const EVENT_FILTERS: Array<EventFilter> = ['upcoming', 'past'];

/**
 * The `{@link EventsView}` View.
 *
 * @public
 */
@Component({
    selector: 'app-events-view',
    template: eventsViewTemplate,
    styles: [
        viewBaseStyle(),
        eventsViewStyle()
    ],
    imports: [
        CouponElement
    ],
    providers: [
        {
            provide: AppointmentService,
            useClass: AppointmentService
        },
        {
            provide: EventsService,
            useClass: EventsService
        }
    ]
})
export class EventsView
    extends ViewBase {

    // #region Fields

    @Inject(AppointmentService)
    private readonly _appointment!: AppointmentService;

    @Inject(EventsService)
    private readonly _eventsService!: EventsService;

    private _events: Array<IEventResponse>;
    private _filter: EventFilter;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._events = new Array<IEventResponse>();
        this._filter = 'upcoming';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-events-view';
    }

    /**
     * Gets or sets the `events` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Array })
    public get events(): Array<IEventResponse> {
        return this._events.filter((x) => {
            if (this._filter === 'past') {
                return new Date(x.startDate) < new Date();
            } else if (this._filter === 'upcoming') {
                return new Date(x.startDate) >= new Date();
            }

            return false;
        });
    }

    private set events(value: Array<IEventResponse>) {
        if (this._events !== value) {
            this._events = value;
            this.requestUpdate('events');
        }
    }

    /**
     * Gets or sets the `filter` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get filter(): EventFilter {
        return this._filter;
    }

    public set filter(value: EventFilter) {
        if (this._filter !== value) {
            this._filter = value;
            this.requestUpdate('filter');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.isBusy = true;

        void this._eventsService.getEvents()
            .then((x) => {
                this.events = x;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    /**
     * @private
     * @template
     */
    public onFilter(filter: EventFilter): void {
        this.filter = filter;
    }

    // /**
    //  * @public
    //  */
    // public onSaveAppointment(event: IEventsResponse): void {
    //     if (event.calendar) {
    //         const start = new Date(event.calendar.startDate);
    //         const end = new Date(event.calendar.endDate ?? event.calendar.startDate);
    //         const description = `${Object.keys(event.description)[this.lang as any]}${event.link}`;
    //         this._appointment.create(event.calendar.name, description, start, end);
    //     }
    // }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-events-view': EventsView;
    }
}
