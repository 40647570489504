// #region Imports

import { Attribute, Component, CustomElement, type CSSResultGroup, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { madeWithLoveElementStyle } from './MadeWithLoveElementStyle';
import { madeWithLoveElementTemplate } from './MadeWithLoveElementTemplate';

// #endregion

/**
 * Represents the `{@link IMadeWithLoveElementProps}` interface.
 *
 * @public
 */
export interface IMadeWithLoveElementProps {
    by: string;
    url: string;
}

/**
 * The `{@link MadeWithLoveElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-made-with-love'
})
export class MadeWithLoveElement extends CustomElement implements IMadeWithLoveElementProps {

    // #region Fields

    private _by: string;
    private _url: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._by = '';
        this._url = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-made-with-love';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return madeWithLoveElementStyle();
    }

    /**
     * Gets or sets the `by` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get by(): string {
        return this._by;
    }

    public set by(value: string) {
        if (this._by !== value) {
            this._by = value;
            this.requestUpdate('by');
        }
    }

    /**
     * Gets or sets the `url` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get url(): string {
        return this._url;
    }

    public set url(value: string) {
        if (this._url !== value) {
            this._url = value;
            this.requestUpdate('url');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     */
    protected override render(): TemplateResult {
        return madeWithLoveElementTemplate(this);
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-made-with-love': MadeWithLoveElement;
    }
}
