// #region Imports

import { Appearance, type ListElement, Size, type TemplateResult, Variant, html, repeat } from '@breadstone/mosaik-elements-foundation';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { groupBy } from '../../../Extensions/ArrayExtensions';

// #endregion

export function defaultTemplate(props: { get: <T = unknown>(key: keyof ListElement) => IPlaygroundProperty<ListElement, T> }): TemplateResult {
    const dataSource = [{
        id: 1,
        name: 'Cosme Lucchi',
        avatar: 'https://robohash.org/perspiciatisexdoloribus.jpg?size=48x48&set=set1',
        email: 'clucchi0@liveinternet.ru'
    }, {
        id: 2,
        name: 'Kynthia Winckles',
        avatar: 'https://robohash.org/cumquequiea.jpg?size=48x48&set=set1',
        email: 'kwinckles1@rambler.ru'
    }, {
        id: 3,
        name: 'Leonelle Maskelyne',
        avatar: 'https://robohash.org/porrosintut.jpg?size=48x48&set=set1',
        email: 'lmaskelyne2@fastcompany.com'
    }, {
        id: 4,
        name: 'Arnie Tollemache',
        avatar: 'https://robohash.org/quasisitin.jpg?size=48x48&set=set1',
        email: 'atollemache3@vimeo.com'
    }, {
        id: 5,
        name: 'Ricky Egdal',
        avatar: 'https://robohash.org/quivoluptatemeveniet.jpg?size=48x48&set=set1',
        email: 'regdal4@cnbc.com'
    }, {
        id: 6,
        name: 'Avivah Miller',
        avatar: 'https://robohash.org/dolorvoluptatemearum.jpg?size=48x48&set=set1',
        email: 'amiller5@shop-pro.jp'
    }, {
        id: 7,
        name: 'Rebecka Busher',
        avatar: 'https://robohash.org/ametdelenitieveniet.jpg?size=48x48&set=set1',
        email: 'rbusher6@mashable.com'
    }, {
        id: 8,
        name: 'Kristel Wastall',
        avatar: 'https://robohash.org/quibusdamperferendisdistinctio.jpg?size=48x48&set=set1',
        email: 'kwastall7@disqus.com'
    }, {
        id: 9,
        name: 'Tybalt Polon',
        avatar: 'https://robohash.org/uteosdoloremque.jpg?size=48x48&set=set1',
        email: 'tpolon8@ehow.com'
    }, {
        id: 10,
        name: 'Gawen Von Salzberg',
        avatar: 'https://robohash.org/liberoametalias.jpg?size=48x48&set=set1',
        email: 'gvon9@shinystat.com'
    }, {
        id: 11,
        name: 'Justus Perroni',
        avatar: 'https://robohash.org/facilisautvelit.jpg?size=48x48&set=set1',
        email: 'jperronia@nba.com'
    }, {
        id: 12,
        name: 'Rich Pinson',
        avatar: 'https://robohash.org/doloremsimiliquequo.jpg?size=48x48&set=set1',
        email: 'rpinsonb@sciencedaily.com'
    }, {
        id: 13,
        name: 'Wat Indge',
        avatar: 'https://robohash.org/inciduntnemopraesentium.jpg?size=48x48&set=set1',
        email: 'windgec@netvibes.com'
    }, {
        id: 14,
        name: 'Wash Noke',
        avatar: 'https://robohash.org/doloremetcupiditate.jpg?size=48x48&set=set1',
        email: 'wnoked@cnn.com'
    }, {
        id: 15,
        name: 'Sharla Slatten',
        avatar: 'https://robohash.org/estdoloremrepellendus.jpg?size=48x48&set=set1',
        email: 'sslattene@psu.edu'
    }, {
        id: 16,
        name: 'Adele Gori',
        avatar: 'https://robohash.org/vitaenequeautem.jpg?size=48x48&set=set1',
        email: 'agorif@utexas.edu'
    }, {
        id: 17,
        name: 'Marillin Seine',
        avatar: 'https://robohash.org/sapientealiasplaceat.jpg?size=48x48&set=set1',
        email: 'mseineg@w3.org'
    }, {
        id: 18,
        name: 'Karla Dunkinson',
        avatar: 'https://robohash.org/saepeetsapiente.jpg?size=48x48&set=set1',
        email: 'kdunkinsonh@people.com.cn'
    }, {
        id: 19,
        name: 'Jeramey Eskell',
        avatar: 'https://robohash.org/consequaturlaudantiumomnis.jpg?size=48x48&set=set1',
        email: 'jeskelli@si.edu'
    }, {
        id: 20,
        name: 'Tobit Geaves',
        avatar: 'https://robohash.org/cupiditatedoloremillo.jpg?size=48x48&set=set1',
        email: 'tgeavesj@sina.com.cn'
    }];

    return html`
        <mosaik-list style="height: 300px">
            ${repeat(groupBy(dataSource, (item) => item.name[0].toUpperCase()), (group) => group.key, (group) => html`
                <mosaik-list-item-group .header="${group.key.charAt(0)}"
                                        .isSticky="${true}">
                    ${repeat(group.items, (item) => item.id, (item) => html`
                        <mosaik-list-item>
                            <mosaik-avatar slot="prefix"
                                           .size="${Size.Small}"
                                           .appearance="${Appearance.Soft}"
                                           .variant="${Variant.Primary}"
                                           .text="${item.name}"
                                           .src="${item.avatar}"
                                           .disabled="${props.get('disabled').value}"></mosaik-avatar>
                            <mosaik-text .text="${item.name}"></mosaik-text>
                            <mosaik-text .text="${item.email}"></mosaik-text>
                        </mosaik-list-item>
                    `)}
                </mosaik-list-item-group>
            `)}
        </mosaik-list>
    `;
}
