export namespace ColorExtensions {
    export function hexToRgb(hex: string): [number, number, number] {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }

    export function rgbToHex(r: number, g: number, b: number): string {
        return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)
            .toUpperCase()}`;
    }

    export function adjust(color: number, amount: number): number {
        return Math.min(255, Math.max(0, color + amount));
    }

    export function darken(hex: string, factor: number): string {
        const [r, g, b] = hexToRgb(hex);
        return rgbToHex(adjust(r, -factor), adjust(g, -factor), adjust(b, -factor));
    }

    export function lighten(hex: string, factor: number): string {
        const [r, g, b] = hexToRgb(hex);
        return rgbToHex(adjust(r, factor), adjust(g, factor), adjust(b, factor));
    }

    /**
     * Generate a unique color based on a given string (e.g., user name).
     *
     * @public
     * @param {string} name - The full name or concatenated first name and last name.
     * @returns {string} - The generated HSL color string.
     */
    export function hash(value: string): string {
        let h = 0;

        // Convert the name into a hash value
        for (let i = 0; i < value.length; i++) {
            h = value.charCodeAt(i) + ((h << 5) - h);
        }

        // Use the hash to generate HSL color values
        const hue = Math.abs(h % 360); // hue value between 0 and 360
        const saturation = 60 + (h % 20); // saturation between 60% and 80%
        const lightness = 50 + (h % 10);  // lightness between 50% and 60%

        // Return the HSL color
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

}
