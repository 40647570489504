// #region Imports

import { FlipElement, Inject, Injectable, Signal, Size, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, ViewPresenter } from '@breadstone/mosaik-elements-foundation';
import { IIconResponse } from '../../Backend/Api/Models/IIconResponse';
import { IconsService } from '../../Backend/Api/Services/IconsService';

// #endregion

/**
 * The `IconGalleryViewModel` class.
 *
 * @public
 */
@Injectable()
export class IconGalleryViewPresenter
    extends ViewPresenter {

    // #region Fields

    @Inject(IconsService)
    private readonly _iconsService!: IconsService;
    private readonly _mode: Signal<'filled' | 'outlined'>;
    private readonly _size: Signal<Size>;
    private readonly _data: Signal<Array<IIconResponse>>;
    private readonly _filter: Signal<string>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `IconGalleryViewModel` class.
     *
     * @public
     */
    public constructor() {
        super();

        this._mode = Signal.from<'filled' | 'outlined'>('filled');
        this._size = Signal.from<Size>(Size.Giant);
        this._data = Signal.from<Array<IIconResponse>>([]);
        this._filter = Signal.from('');

        this._filter.subscribe(() => this.initialize());
        this._mode.subscribe(() => this.initialize());
        this._size.subscribe(() => this.initialize());
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `mode` property.
     *
     * @public
     * @readonly
     */
    public get mode(): Signal<'filled' | 'outlined'> {
        return this._mode;
    }

    /**
     * Gets or sets the `size` property.
     *
     * @public
     * @readonly
     */
    public get size(): Signal<Size> {
        return this._size;
    }

    /**
     * Gets or sets the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Signal<Array<IIconResponse>> {
        return this._data;
    }

    /**
     * Gets or sets the `filter` property.
     *
     * @public
     * @readonly
     */
    public get filter(): Signal<string> {
        return this._filter;
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @public
     */
    public override initialize(): void {
        this.loading(true);

        void this._iconsService
            .getIcons({
                filter: this._filter.get(),
                mode: this._mode.get()
            })
            .then((x) => {
                this.data.update(() => x);
            })
            .finally(() => {
                this.loading(false);
            });
    }

    /**
     * @private
     * @template
     */
    public onCopy(event: Event, path: string): void {
        const flip = event.currentTarget as FlipElement;
        flip.toggle();
        flip.disabled = true;

        void navigator.clipboard.writeText(path).then(() => {
            void ToastServiceLocator.current.open({
                variant: Variant.Success,
                content: TranslatorServiceLocator.current.translate('Icon copied to clipboard 🎉.'),
                timeout: Toast2.Timeout.SHORT
            }).then(() => {
                flip.disabled = false;
                flip.toggle();
            });
        });
    }

    // #endregion

}
