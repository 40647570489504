// #region Imports

import { Appearance, html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { DiagrammElement } from './DiagrammElement';

// #endregion

/**
 * The template of the {@link DiagrammElement}.
 *
 * @public
 */
export function diagrammElementTemplate<T extends DiagrammElement>(e: T): TemplateResult {

    return html`
        <div part="root"></div>
        <div part="toolbox">
            <mosaik-button part="zoomIn"
                           aria-label="Zoom in"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M17.5 10c0 1.71-.572 3.287-1.536 4.548l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083-4.745-4.743A7.5 7.5 0 1 1 17.5 10ZM10 5.5a1 1 0 0 0-1 1V9H6.5a1 1 0 1 0 0 2H9v2.5a1 1 0 1 0 2 0V11h2.5a1 1 0 1 0 0-2H11V6.5a1 1 0 0 0-1-1Z'}"
                           @click="${() => e.zoomIn()}"></mosaik-button>
            <mosaik-button part="zoomOut"
                           aria-label="Zoom out"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M17.5 10a7.5 7.5 0 1 0-2.952 5.964l4.745 4.743.094.083a1 1 0 0 0 1.32-1.497l-4.743-4.745A7.468 7.468 0 0 0 17.5 10Zm-4-1a1 1 0 1 1 0 2h-7a1 1 0 1 1 0-2h7Z'}"
                           @click="${() => e.zoomOut()}"></mosaik-button>
            <mosaik-button part="resetZoom"
                           aria-label="Reset view"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M7.207 2.543a1 1 0 0 1 0 1.414L5.414 5.75h7.836a8 8 0 1 1-8 8 1 1 0 1 1 2 0 6 6 0 1 0 6-6H5.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1 0-1.414l3.5-3.5a1 1 0 0 1 1.414 0Z'}"
                           @click="${() => e.resetZoom()}"></mosaik-button>
            <mosaik-button part="up"
                           aria-label="Pan up"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M4.293 15.707a1 1 0 0 0 1.414 0L12 9.414l6.293 6.293a1 1 0 0 0 1.414-1.414l-7-7a1 1 0 0 0-1.414 0l-7 7a1 1 0 0 0 0 1.414Z'}"
                           @click="${() => e.panUp()}"></mosaik-button>
            <mosaik-button part="down"
                           aria-label="Pan down"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M4.293 8.293a1 1 0 0 1 1.414 0L12 14.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414Z'}"
                           @click="${() => e.panDown()}"></mosaik-button>
            <mosaik-button part="left"
                           aria-label="Pan left"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z'}"
                           @click="${() => e.panLeft()}"></mosaik-button>
            <mosaik-button part="right"
                           aria-label="Pan right"
                           .appearance="${Appearance.Solid}"
                           .icon="${'M8.293 4.293a1 1 0 0 0 0 1.414L14.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 0 0-1.414 0Z'}"
                           @click="${() => e.panRight()}"></mosaik-button>
        </div>
    `;

}
