// #region Imports

import { Appearance, CalendarElement, CalendarView, Component, DayOfWeek, Fit, FlowDirection, Orientation, SelectionMode, Size, Variant, type CalendarMarkerHandler } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { calendarElementDocs } from '../../.Generated/mosaik-calendar';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate, dateTemplate, functionTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link CalendarSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-calendar-sample'
})
export class CalendarSampleElement extends SampleBaseElement<CalendarElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CalendarElement> {
        const displayDate = new Date();
        const displayStartDate = new Date(displayDate.getFullYear() - 1, displayDate.getMonth(), displayDate.getDate());
        const displayEndDate = new Date(displayDate.getFullYear() + 1, displayDate.getMonth(), displayDate.getDate());
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const specialDates = [new Date(year, month, 12), new Date(year, month, 24), new Date(year, month, 25), new Date(year, month, 26)];
        const blackoutDates = [new Date(year, month, 11), new Date(year, month, 10)];
        const markerHandler: CalendarMarkerHandler = (value: Date) => value.getDay() % 3 === 0
            ? [{
                variant: Variant.Primary,
                size: Size.Tiny
            }, {
                variant: Variant.Secondary,
                size: Size.Tiny
            }, {
                variant: Variant.Tertiary,
                size: Size.Tiny
            }]
            : [];

        return {
            header: 'Calendar',
            description: META.description(CalendarElement.is),
            graph: META.graph(CalendarElement.is),
            playground: {
                template: html`
                    <mosaik-calendar></mosaik-calendar>
                `,
                properties: META.properties<CalendarElement>(CalendarElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Default
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    // { key: 'value', value: new Date() },
                    {
                        key: 'values',
                        value: []
                    },
                    {
                        key: 'blackoutDates',
                        value: blackoutDates
                    },
                    {
                        key: 'specialDates',
                        value: specialDates
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Single
                    },
                    {
                        key: 'view',
                        value: CalendarView.Day
                    },
                    {
                        key: 'firstDayOfWeek',
                        value: DayOfWeek.Sunday
                    },
                    {
                        key: 'isTodayHighlighted',
                        value: true
                    },
                    {
                        key: 'isWeekendHighlighted',
                        value: true
                    },
                    {
                        key: 'showWeekNumbers',
                        value: false
                    },
                    {
                        key: 'showAdjacent',
                        value: true
                    },
                    {
                        key: 'header',
                        value: 'Select a date'
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Vertical
                    },
                    {
                        key: 'markerHandler',
                        value: markerHandler
                    },
                    {
                        key: 'locale',
                        value: 'en-US'
                    },
                    {
                        key: 'displayDate',
                        value: new Date()
                    },
                    {
                        key: 'displayDateStart',
                        value: displayStartDate
                    },
                    {
                        key: 'displayDateEnd',
                        value: displayEndDate
                    }
                ]),
                events: META.events(CalendarElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<CalendarElement>(CalendarElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Default
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    // { key: 'value', value: new Date() },
                    {
                        key: 'values',
                        value: []
                    },
                    {
                        key: 'blackoutDates',
                        value: blackoutDates
                    },
                    {
                        key: 'specialDates',
                        value: specialDates
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Single
                    },
                    {
                        key: 'view',
                        value: CalendarView.Day
                    },
                    {
                        key: 'firstDayOfWeek',
                        value: DayOfWeek.Sunday
                    },
                    {
                        key: 'isTodayHighlighted',
                        value: true
                    },
                    {
                        key: 'isWeekendHighlighted',
                        value: true
                    },
                    {
                        key: 'showWeekNumbers',
                        value: false
                    },
                    {
                        key: 'showAdjacent',
                        value: true
                    },
                    {
                        key: 'header',
                        value: 'Select a date'
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Vertical
                    },
                    {
                        key: 'markerHandler',
                        value: markerHandler
                    },
                    {
                        key: 'locale',
                        value: 'en-US'
                    },
                    {
                        key: 'displayDate',
                        value: new Date()
                    },
                    {
                        key: 'displayDateStart',
                        value: displayStartDate
                    },
                    {
                        key: 'displayDateEnd',
                        value: displayEndDate
                    }
                ]), [
                    {
                        key: 'value',
                        template: dateTemplate
                    },
                    {
                        key: 'values',
                        template: arrayTemplate
                    },
                    {
                        key: 'minDate',
                        template: dateTemplate
                    },
                    {
                        key: 'maxDate',
                        template: dateTemplate
                    },
                    {
                        key: 'blackoutDates',
                        template: arrayTemplate
                    },
                    {
                        key: 'specialDates',
                        template: arrayTemplate
                    },
                    {
                        key: 'displayDate',
                        template: dateTemplate
                    },
                    {
                        key: 'displayDateStart',
                        template: dateTemplate
                    },
                    {
                        key: 'displayDateEnd',
                        template: dateTemplate
                    },
                    {
                        key: 'markerHandler',
                        template: functionTemplate
                    }
                ] as Array<IPlaygroundProperty<CalendarElement>>, (a, b) => a.key === b.key), [
                    { key: 'intl' }
                ] as Array<IPlaygroundProperty<CalendarElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                calendarElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-calendar-sample': CalendarSampleElement;
    }
}
