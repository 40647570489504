// #region Imports

import { Appearance, Component, FlowDirection, Orientation, Size, SliderElement, TickPlacement, Variant } from '@breadstone/mosaik-elements-foundation';
import { AutoToolTipPlacement } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/AutoToolTipPlacement';
import { html, type CSSResultGroup } from 'lit';
import { sliderElementDocs } from '../../.Generated/mosaik-slider';
import { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { functionTemplate, numberTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link SliderSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-slider-sample',
    imports: [
        SliderElement
    ]
})
export class SliderSampleElement extends SampleBaseElement<SliderElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SliderElement> {

        return {
            header: 'Slider',
            description: META.description(SliderElement.is),
            graph: META.graph(SliderElement.is),
            playground: {
                template: html`
                    <mosaik-slider>
                        <mosaik-icon slot="prefix" .size="${Size.Small}" .data="${'M20.026 17.001c-2.762 4.784-8.879 6.423-13.663 3.661A9.965 9.965 0 0 1 3.13 17.68a.75.75 0 0 1 .365-1.132c3.767-1.348 5.785-2.91 6.956-5.146 1.232-2.353 1.551-4.93.689-8.463a.75.75 0 0 1 .769-.927 9.961 9.961 0 0 1 4.457 1.327c4.784 2.762 6.423 8.879 3.66 13.662Z'}"></mosaik-icon>
                        <mosaik-icon slot="suffix" .size="${Size.Small}" .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                    </mosaik-slider>
                `,
                properties: META.properties<SliderElement>(SliderElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'value',
                        value: 5
                    },
                    {
                        key: 'max',
                        value: 10
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'ticks',
                        value: 1
                    },
                    {
                        key: 'tickPlacement',
                        value: TickPlacement.Both
                    },
                    {
                        key: 'step',
                        value: 1
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'tooltipPlacement',
                        value: AutoToolTipPlacement.None
                    },
                    {
                        key: 'tooltipAccessor',
                        value: (value: number) => `Value - ${value.toString()}`
                    },
                    {
                        key: 'showTickLabels',
                        value: true
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<SliderElement>(SliderElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'value',
                        value: 5
                    },
                    {
                        key: 'max',
                        value: 10
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'step',
                        value: 1
                    },
                    {
                        key: 'ticks',
                        value: 1
                    },
                    {
                        key: 'tickPlacement',
                        value: TickPlacement.Both
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'tooltipPlacement',
                        value: AutoToolTipPlacement.None
                    },
                    {
                        key: 'tooltipAccessor',
                        value: (value: number) => `Value - ${value.toString()}`
                    },
                    {
                        key: 'showTickLabels',
                        value: true
                    }
                ]), [
                    {
                        key: 'min',
                        template: numberTemplate
                    },
                    {
                        key: 'max',
                        template: numberTemplate
                    },
                    {
                        key: 'tooltipAccessor',
                        template: functionTemplate
                    },
                    {
                        key: 'tickLabelAccessor',
                        template: functionTemplate
                    }
                ] as Array<IPlaygroundProperty<SliderElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                sliderElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-slider-sample': SliderSampleElement;
    }
}
