// #region Imports

import type { ICodeGenerator, ICodeModel } from '../Interfaces/ICodeGenerator';

// #endregion

/**
 * Base class for HTML generators.
 *
 * @public
 * @abstract
 */
export abstract class CodeGeneratorBase implements ICodeGenerator {

    // #region Properties

    /**
     * Generates code for the given component properties.
     *
     * @param model - The model to generate code for.
     * @returns The generated code string.
     */
    public abstract process(model: ICodeModel): string;

    /**
     * Converts properties to an code attribute string.
     *
     * @param properties - The properties of the component.
     * @returns The properties as an code attribute string.
     */
    protected convertPropertiesToAttributes(properties: Record<string, any>): string {
        return Object.entries(properties)
            .map(([key, value]) => `${key}="${value}"`)
            .join(' ');
    }

    // #endregion

}
