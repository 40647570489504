// #region Imports

import type { IThemeLayout, IThemeTypography } from '@breadstone/mosaik-elements-foundation';
import * as json from './app.json';

// #endregion

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type G = {
    host: string;
    mode: string;
    name: string;
    version: string;
    description: string;
    author: string;
    authorEmail: string;
    company: string;
    homepage: string;
    buildDate: Date;
    buildId: string;
    license: string;
    keywords: Array<string>;
    // This property prevent the confusing .htaccess web hosting file.
    // TODO: Remove this property when the .htaccess file is chaged.
    // When true, enable the location strategy that uses the URL fragment instead of the history API.
    myWebHostingIsAStupidIdiot: boolean;
    sites: Array<{
        name: string;
        icon?: string;
        url: string;
        external: boolean;
        location: 'header' | 'footer';
    }>;
    languages: Array<{
        name: string;
        key: string;
        default: boolean;
    }>;
    theme: {
        schemes: Array<{
            key: string;
            default: boolean;
        }>;
        presets: Array<{
            key: string;
            default: boolean;
            primary: string;
            secondary: string;
            tertiary: string;
        }>;
        layout: Pick<IThemeLayout, 'radius'>;
        typographies: Array<Required<Pick<IThemeTypography, 'fontFamily'>> & { default: boolean }>;
    };
    assign(value: any): void;
};

/**
 * @internal
 */
// eslint-disable-next-line prefer-object-spread
export const globals: G = Object.assign({
    host: '@@VERCEL_URL',
    mode: '@@MODE',
    name: '@@NAME',
    version: '@@VERSION',
    description: '@@DESCRIPTION',
    author: '@@AUTHOR',
    buildDate: new Date(Number.parseInt('@@BUILDDATE')),
    buildId: '@@VERCEL_GIT_COMMIT_SHA'.substring(0, 7),
    license: '@@LICENSE',
    keywords: [],
    myWebHostingIsAStupidIdiot: false,
    assign(value: any): void {
        Object.assign(this, value);
    }
}, json);

Object.assign(window, {
    globals: globals
});
