//#region Imports

import { Appearance, Component, FlowDirection, ITimeSpan, Placement, Size, Strategy, TimeBoxElement, Variant, type TimeMarkerHandler } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { timeboxElementDocs } from '../../../.Generated/mosaik-timebox';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate, cssLengtTemplate, functionTemplate, timeTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link TimeBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-timebox-sample'
})
export class TimeBoxSampleElement extends SampleBaseElement<TimeBoxElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TimeBoxElement> {
        const blackoutTimes: Array<ITimeSpan> = [
            ITimeSpan.create(11, 0, 'en-US'),
            ITimeSpan.create(12, 0, 'en-US'),
            ITimeSpan.create(13, 0, 'en-US')
        ];
        // eslint-disable-next-line arrow-body-style
        const markerHandler: TimeMarkerHandler = (value: ITimeSpan) => {
            return value.hours % 3 === 0
                ? [
                    { variant: Variant.Primary, size: Size.Tiny },
                    { variant: Variant.Secondary, size: Size.Tiny },
                    { variant: Variant.Tertiary, size: Size.Tiny }]
                : [];
        };

        return {
            header: 'Date Box',
            description: META.description(TimeBoxElement.is),
            graph: META.graph(TimeBoxElement.is),
            playground: {
                template: html`
                    <mosaik-timebox></mosaik-timebox>
                `,
                properties: META.properties<TimeBoxElement>(TimeBoxElement.is, [
                    { key: 'value', value: null },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'disabled', value: false },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dropDownDistance', value: 8 },
                    { key: 'dropDownPlacement', value: Placement.Bottom },
                    { key: 'dropDownSkidding', value: 0 },
                    { key: 'dropDownStrategy', value: Strategy.Absolute },
                    { key: 'isDropDownOpen', value: false },
                    { key: 'isClearable', value: false },
                    { key: 'disabled', value: false },
                    { key: 'dropDownStaysOpen', value: false },
                    { key: 'label', value: 'Your time slot' },
                    { key: 'placeholder', value: 'Type something...' },
                    { key: 'locale', value: 'en-US' },
                    { key: 'blackoutTimes', value: blackoutTimes },
                    { key: 'specialTimes', value: [ITimeSpan.create(11, 0, 'en-US')] },
                    { key: 'markerHandler', value: markerHandler }
                ]),
                events: META.events(TimeBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<TimeBoxElement>(TimeBoxElement.is, [
                    { key: 'value', value: null },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'disabled', value: false },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'maxDropDownHeight', value: '200px' },
                    { key: 'dropDownDistance', value: 8 },
                    { key: 'dropDownPlacement', value: Placement.Bottom },
                    { key: 'dropDownSkidding', value: 0 },
                    { key: 'dropDownStrategy', value: Strategy.Absolute },
                    { key: 'isDropDownOpen', value: false },
                    { key: 'isClearable', value: false },
                    { key: 'disabled', value: false },
                    { key: 'dropDownStaysOpen', value: false },
                    { key: 'label', value: 'Your time slot' },
                    { key: 'placeholder', value: 'Type something...' },
                    { key: 'locale', value: 'en-US' },
                    { key: 'blackoutTimes', value: blackoutTimes },
                    { key: 'specialTimes', value: [ITimeSpan.create(11, 0, 'en-US')] },
                    { key: 'markerHandler', value: markerHandler }
                ]), [
                    { key: 'maxDropDownHeight', template: cssLengtTemplate },
                    { key: 'formatter', template: functionTemplate },
                    { key: 'parser', template: functionTemplate },
                    { key: 'value', template: timeTemplate },
                    { key: 'blackoutTimes', template: arrayTemplate },
                    { key: 'specialTimes', template: arrayTemplate },
                    { key: 'markerHandler', template: functionTemplate },
                    { key: 'maxTime', template: timeTemplate },
                    { key: 'minTime', template: timeTemplate }
                ] as Array<IPlaygroundProperty<TimeBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                timeboxElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-timebox-sample': TimeBoxSampleElement;
    }
}

