//#region Imports

import { Component, FlowDirection, Orientation, RatingElement, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { ratingElementDocs } from '../../../.Generated/mosaik-rating';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate, percentTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { RatingTemplates } from './RatingSample.Templates';

//#endregion

/**
 * The `{@link RatingSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-rating-sample'
})
export class RatingSampleElement extends SampleBaseElement<RatingElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<RatingElement> {
        return {
            header: 'Rating',
            description: META.description(RatingElement.is),
            graph: META.graph(RatingElement.is),
            playground: {
                template: RatingTemplates.defaultTemplate(),
                properties: META.properties<RatingElement>(RatingElement.is, [
                    { key: 'dir', value: FlowDirection.Auto, },
                    { key: 'disabled', value: false, },
                    { key: 'label', value: 'Label', },
                    { key: 'length', value: 5 },
                    { key: 'precision', value: 0.5 },
                    { key: 'readonly', value: false },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'size', value: Size.Medium },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'symbol', value: 'M10.788 3.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L8.43 7.88l2.358-4.777Z' }
                ]),
                events: META.events<RatingElement>(RatingElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<RatingElement>(RatingElement.is, [
                    { key: 'dir', value: FlowDirection.Auto, },
                    { key: 'disabled', value: false, },
                    { key: 'label', value: 'Label', },
                    { key: 'length', value: 5 },
                    { key: 'precision', value: 0.5 },
                    { key: 'readonly', value: false },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'size', value: Size.Medium },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'symbol', value: 'M10.788 3.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L8.43 7.88l2.358-4.777Z' }
                ]), [
                    { key: 'symbolAccessor', template: functionTemplate },
                    { key: 'value', template: valueTemplate },
                    { key: 'precision', template: percentTemplate }
                ] as Array<IPlaygroundProperty<RatingElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                ratingElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-rating-sample': RatingSampleElement;
    }
}

