// #region Imports

import { Appearance, Component, FlowDirection, HorizontalAlignment, Orientation, PersonaElement, PersonaPresence, Size, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { personaElementDocs } from '../../../.Generated/mosaik-persona';
import { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link PersonaSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-persona-sample'
})
export class PersonaSampleElement extends SampleBaseElement<PersonaElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<PersonaElement> {
        return {
            header: 'Persona',
            description: META.description(PersonaElement.is),
            graph: META.graph(PersonaElement.is),
            playground: {
                template: html`
                    <mosaik-persona></mosaik-persona>
                `,
                properties: META.properties<PersonaElement>(PersonaElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'icon',
                        value: 'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'presence',
                        value: PersonaPresence.Online
                    },
                    {
                        key: 'primaryText',
                        value: ''
                    },
                    {
                        key: 'quaternaryText',
                        value: 'Mosaik'
                    },
                    {
                        key: 'secondaryText',
                        value: 'Available'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
                    },
                    {
                        key: 'tertiaryText',
                        value: 'Head of Development'
                    },
                    {
                        key: 'text',
                        value: 'John Doe'
                    },
                    {
                        key: 'verticalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'horizontalContentAlignment',
                        value: HorizontalAlignment.Center
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ]),
                events: META.events<PersonaElement>(PersonaElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<PersonaElement>(PersonaElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'icon',
                        value: 'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'presence',
                        value: PersonaPresence.Online
                    },
                    {
                        key: 'primaryText',
                        value: ''
                    },
                    {
                        key: 'quaternaryText',
                        value: 'Mosaik'
                    },
                    {
                        key: 'secondaryText',
                        value: 'Available'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
                    },
                    {
                        key: 'tertiaryText',
                        value: 'Head of Development'
                    },
                    {
                        key: 'text',
                        value: 'John Doe'
                    },
                    {
                        key: 'verticalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'horizontalContentAlignment',
                        value: HorizontalAlignment.Center
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ]), [
                    {
                        key: 'textAccessor',
                        template: functionTemplate
                    }
                ] as Array<IPlaygroundProperty<PersonaElement>>, (a, b) => a.key === b.key), [
                    { key: 'displayMode' },
                    { key: 'hasBadge' }
                ] as Array<IPlaygroundProperty<PersonaElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                personaElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-persona-sample': PersonaSampleElement;
    }
}
