// #region Imports

import { html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { AppIssueElement } from './AppIssue';

// #endregion

/**
 * The template of the {@link AppIssueElement}.
 *
 * @public
 */
export function appIssueElementTemplate<T extends AppIssueElement>(e: T): TemplateResult {
    // return html`
    //     <mosaik-tooltip for="issue"
    //                     .content="${translate('loc.issue.tooltip')}"></mosaik-tooltip>
    //     <mosaik-fab part="fab"
    //                 id="issue"
    //                 .label="${translate('loc.issue.label')}"
    //                 .icon="${'M21.907 5.622c.062.208.093.424.093.641V17.74a2.25 2.25 0 0 1-2.891 2.156l-5.514-1.64a4.002 4.002 0 0 1-7.59-1.556L6 16.5l-.001-.5-2.39-.711A2.25 2.25 0 0 1 2 13.131V10.87a2.25 2.25 0 0 1 1.61-2.156l15.5-4.606a2.25 2.25 0 0 1 2.797 1.515ZM7.499 16.445l.001.054a2.5 2.5 0 0 0 4.624 1.321l-4.625-1.375Z'}"
    //                 .appearance="${Appearance.Solid}"
    //                 .variant="${Variant.Primary}"
    //                 .size="${Size.Large}"
    //                 @click="${() => DialogServiceLocator.current.open('issueDialog', )}"></mosaik-fab>
    //     <mosaik-portal>
    //         <mosaik-dialog id="issueDialog"
    //                        .isOpen="${false}"
    //                        .hasBackdrop="${true}"
    //                        .clickOutsideToClose="${true}"
    //                        .pressEscapeToClose="${true}"
    //                        .isFullScreen="${e.isMobile}">
    //             <mosaik-dialog-content style="padding: 0;">
    //                 <mosaik-wizard part="wizard"
    //                                .fit="${Fit.Both}"
    //                                @activeStepChanged="${(x: IWizardActiveStepChangedEvent) => e.onWizardStepChanged(x.detail)}">
    //                     <mosaik-button slot="header-start"
    //                                    .icon="${'M10.295 19.716a1 1 0 0 0 1.404-1.425l-5.37-5.29h13.67a1 1 0 1 0 0-2H6.336L11.7 5.714a1 1 0 0 0-1.404-1.424l-6.924 6.822a1.25 1.25 0 0 0 0 1.78l6.924 6.823Z'}"
    //                                    .appearance="${Appearance.Plain}"
    //                                    .disabled="${e.isBusy || e.isDone}"
    //                                    @click="${() => e.onPrevious()}"></mosaik-button>
    //                     <mosaik-button slot="header-end"
    //                                    .icon="${'M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z'}"
    //                                    .appearance="${Appearance.Plain}"
    //                                    @click="${() => e.onClose()}"></mosaik-button>
    //                     <mosaik-wizard-step .header="${translate('loc.issue.create.header')}"
    //                                         .subHeader="${translate('loc.issue.create.description')}"
    //                                         .isActive="${true}">
    //                         <mosaik-stack .orientation="${Orientation.Vertical}"
    //                                       .gap="${'16px'}">
    //                             <mosaik-wrap .columns="${2}"
    //                                          .gap="${'16px'}">
    //                                 <mosaik-summary .header="${translate('loc.issue.create.data.software.name')}"
    //                                                 .content="${e.browser.name}"></mosaik-summary>
    //                                 <mosaik-summary .header="${translate('loc.issue.create.data.software.version')}"
    //                                                 .content="${e.browser.version}"></mosaik-summary>
    //                                 <mosaik-summary .header="${translate('loc.issue.create.data.os.name')}"
    //                                                 .content="${e.browser.osName}"></mosaik-summary>
    //                                 <mosaik-summary .header="${translate('loc.issue.create.data.os.version')}"
    //                                                 .content="${e.browser.osVersion}"></mosaik-summary>
    //                             </mosaik-wrap>
    //                             <mosaik-form part="form"
    //                                         ${flex({
    //     direction: 'column',
    //     gap: '24px',
    //     alignItems: 'stretch'
    // })}>
    //                                 <mosaik-form-field .validators="${[requiredElementValidator()]}"
    //                                                    .accessor="${selectElementValueAccessor}">
    //                                     <mosaik-select .label="${translate('loc.issue.create.data.type.label')}"
    //                                                    .variant="${Variant.Primary}"
    //                                                    ?required="${true}"
    //                                                    ?disabled="${e.isBusy}">
    //                                         ${repeat(e.types, (x) => x, (x) => html`
    //                                         <mosaik-select-item .label="${translate(`loc.issue.create.data.type.options.${x}`)}"
    //                                                             .value="${x}"
    //                                                             @selected="${() => e.type = x}"></mosaik-select-item>
    //                                         `)}
    //                                     </mosaik-select>
    //                                 </mosaik-form-field>
    //                                 <mosaik-form-field .validators="${[requiredElementValidator()]}"
    //                                                    .accessor="${textBoxElementValueAccessor}">
    //                                     <mosaik-textbox .label="${translate('loc.issue.create.data.subject.label')}"
    //                                                     .placeholder="${translate('loc.issue.create.data.subject.placeholder')}"
    //                                                     .value="${e.subject}"
    //                                                     .variant="${Variant.Primary}"
    //                                                     ?required="${true}"
    //                                                     ?disabled="${e.isBusy}"
    //                                                     @input="${(x: Event) => e.subject = (x.target as TextBoxElement).value}"></mosaik-textbox>
    //                                 </mosaik-form-field>
    //                                 <mosaik-form-field ${flex({ fill: true })}
    //                                                    .validators="${[requiredElementValidator()]}"
    //                                                    .accessor="${textBoxElementValueAccessor}">
    //                                     <mosaik-textbox .label="${translate('loc.issue.create.data.description.label')}"
    //                                                     .placeholder="${translate('loc.issue.create.data.description.placeholder')}"
    //                                                     .multiline="${true}"
    //                                                     .multilineRows="${6}"
    //                                                     .value="${e.description}"
    //                                                     .variant="${Variant.Primary}"
    //                                                     ?required="${true}"
    //                                                     ?disabled="${e.isBusy}"
    //                                                     @input="${(x: Event) => e.description = (x.target as TextBoxElement).value}"></mosaik-textbox>
    //                                 </mosaik-form-field>
    //                                 <mosaik-form-field .validators="${[requiredTrueElementValidator()]}"
    //                                                    .accessor="${checkBoxElementValueAccessor}">
    //                                     <mosaik-checkbox .label="${translate('loc.issue.create.data.notice')}"
    //                                                      .variant="${Variant.Primary}"
    //                                                      .appearance="${Appearance.Solid}"
    //                                                      ?required="${true}"></mosaik-checkbox>
    //                                 </mosaik-form-field>
    //                             </mosaik-form>
    //                         </mosaik-stack>
    //                         <mosaik-button slot="footer"
    //                                        .label="${translate('loc.issue.create.data.action')}"
    //                                        .variant="${Variant.Primary}"
    //                                        .appearance="${Appearance.Solid}"
    //                                        .isBusy="${e.isBusy}"
    //                                        @click="${() => e.onNext()}"></mosaik-button>
    //                     </mosaik-wizard-step>
    //                     <mosaik-wizard-step .header="${translate('loc.issue.captcha.header')}"
    //                                         .subHeader="${translate('loc.issue.captcha.description')}" >
    //                         <mosaik-text .text="${translate('loc.issue.captcha.info')}"
    //                                      .wrap="${true}"></mosaik-text>
    //                         <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
    //                                       .horizontalAlignment="${HorizontalAlignment.Center}"
    //                                       .fit="${Fit.Both}">
    //                                 <app-captcha part="captcha"
    //                                              @captchaSolved="${() => e.onNext()}"></app-captcha>
    //                         </mosaik-stack>
    //                     </mosaik-wizard-step>
    //                     <mosaik-wizard-step .header="${translate('loc.issue.done.header')}"
    //                                         .subHeader="${translate('loc.issue.done.description')}" >
    //                         <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
    //                                       .horizontalAlignment="${HorizontalAlignment.Center}"
    //                                       .orientation="${Orientation.Vertical}"
    //                                       .fit="${Fit.Both}">
    //                             ${when(e.isBusy && !e.isDone, () => html`
    //                             <mosaik-progress-ring .radius="${24}"
    //                                                   .thickness="${4}"
    //                                                   .isIndeterminate="${true}"></mosaik-progress-ring>
    //                             <mosaik-text .text="${translate('loc.issue.done.busy')}"></mosaik-text>
    //                             `)}
    //                             ${when(!e.isBusy && e.isDone, () => html`
    //                             `)}
    //                         </mosaik-stack>
    //                         <mosaik-button slot="footer"
    //                                        .label="${translate('Done')}"
    //                                        .variant="${Variant.Primary}"
    //                                        .appearance="${Appearance.Solid}"
    //                                        .isBusy="${e.isBusy}"
    //                                        @click="${() => e.onClose()}"></mosaik-button>
    //                     </mosaik-wizard-step>
    //                 </mosaik-wizard>
    //             </mosaik-dialog-content>
    //         </mosaik-dialog>
    //     </mosaik-portal>
    // `;

    return html``;
}
