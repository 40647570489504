// #region Imports

import { Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IFactsResponse } from '../../Backend/Api/Models/IFactsResponse';
import { IMemberResponse } from '../../Backend/Api/Models/IMemberResponse';
import { FactsService } from '../../Backend/Api/Services/FactsService';
import { MembersService } from '../../Backend/Api/Services/MembersService';
import { ViewBase } from '../Abstracts/ViewBase';
import { aboutViewStyle } from './AboutViewStyle';
import { aboutViewTemplate } from './AboutViewTemplate';

// #endregion

/**
 * The `{@link AboutView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-about-view',
    template: aboutViewTemplate,
    styles: aboutViewStyle,
    imports: [],
    providers: [
        {
            provide: MembersService,
            useClass: MembersService
        },
        {
            provide: FactsService,
            useClass: FactsService
        }
    ]
})
export class AboutView extends ViewBase {

    // #region Fields

    @Inject(MembersService)
    private readonly _membersService!: MembersService;
    @Inject(FactsService)
    private readonly _factsService!: FactsService;
    private _members: Array<IMemberResponse>;
    private _facts: Array<IFactsResponse>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._members = [];
        this._facts = [
            {
                key: 'Gründungsjahr',
                value: '2024'
            },
            {
                key: 'Mitarbeiter',
                value: '2'
            },
            {
                key: 'Kunden',
                value: '0'
            },
            {
                key: 'Projekte',
                value: '0'
            }
        ];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-about-view';
    }

    /**
     * Gets or sets the `members` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get members(): Array<IMemberResponse> {
        return this._members;
    }

    public set members(value: Array<IMemberResponse>) {
        if (this._members !== value) {
            this._members = value;
            this.requestUpdate('members');
        }
    }

    /**
     * Gets or sets the `facts` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get facts(): Array<IFactsResponse> {
        return this._facts;
    }

    public set facts(value: Array<IFactsResponse>) {
        if (this._facts !== value) {
            this._facts = value;
            this.requestUpdate('facts');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.isBusy = true;
        void Promise
            .all([
                this._membersService.getMembers()
                    .then((x) => {
                        this.members = x;
                    }),
                this._factsService.getFacts()
                    .then((x) => {
                        this.facts = x;
                    })
            ])
            .then(() => {
                this.isBusy = false;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-about-view': AboutView;
    }
}
