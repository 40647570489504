// #region Imports

import { animate, Animate, Appearance, Fit, flex, html, Inset, Orientation, repeat, SearchTriggerMode, Size, SkeletonShape, Spacing, TextFormatters, timeAgo, translate, typography, Variant, VerticalAlignment, watch, when, type TemplateResult, type TextBoxElement } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import type { FeedbackView } from './FeedbackView';
import { getFeedbackTypeSymbol } from './Utils';

// #endregion

/**
 * The template of the {@link FeedbackView}.
 *
 * @public
 */
export function feedbackViewTemplate<T extends FeedbackView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.feedback')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/feedback'}"
                                        .label="${translate('loc.nav.feedback')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.feedback.title')}"
                                      .ref="${'feedback'}"></mosaik-router-anchor>
            </mosaik-page-content>
            <mosaik-page-content>
                <mosaik-stack .orientation="${Orientation.Horizontal}"
                              .gap="${'16px'}">
                <mosaik-searchbox ${flex({ fill: true })}
                                  .variant="${Variant.Primary}"
                                  .isClearable="${true}"
                                  .delay="${800}"
                                  .mode="${SearchTriggerMode.Delay}"
                                  @searched="${(x: InputEvent) => e.term = (x.target as TextBoxElement).value}"
                                  @cleared="${(x: CustomEvent<string>) => e.term = ''}"></mosaik-searchbox>
                <mosaik-select ${flex({ fill: true })}
                               .label="${translate('loc.feedback.filter.types.label')}"
                               .variant="${Variant.Primary}"
                               .isClearable="${true}"
                               .maxDropDownHeight="${'auto'}"
                               @selectionChanged="${(x: CustomEvent) => e.type = x.detail.newValue.value}">
                    <mosaik-select-item .label="${translate('loc.feedback.filter.types.all.label')}"
                                        .value="${''}"
                                        .isSelected="${true}"
                                        .isActive="${true}"></mosaik-select-item>
                    ${repeat(e.types, (x) => x.id, (x) => html`
                    <mosaik-select-item .label="${getFeedbackTypeSymbol(x.name)} ${translate(`loc.feedback.filter.types.${x.name.toLowerCase()}.label`)}"
                                        .value="${x.name}">
                        <!-- <mosaik-box slot="start" width="24px" height="24px">
                            <mosaik-dot style="--dot-background-color: #${'eeeeee'}"
                                        .size="${Size.Small}"></mosaik-dot>
                        </mosaik-box> -->
                    </mosaik-select-item>
                    `)}
                </mosaik-select>
                <mosaik-button .label="${translate('loc.feedback.filter.action.create')}"
                               .icon="${'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 5a.75.75 0 0 0-.743.648l-.007.102v3.5h-3.5a.75.75 0 0 0-.102 1.493l.102.007h3.5v3.5a.75.75 0 0 0 1.493.102l.007-.102v-3.5h3.5a.75.75 0 0 0 .102-1.493l-.102-.007h-3.5v-3.5A.75.75 0 0 0 12 7Z'}"
                               .appearance="${Appearance.Solid}"
                               .variant="${Variant.Primary}"
                               @click="${(x: Event) => e.onCreate(x)}"></mosaik-button>
                </mosaik-stack>
            </mosaik-page-content>
            <mosaik-page-content .inset="${Inset.Vertical}">
                ${when(e.items.length > 0 && !e.isBusy, () => html`
                <mosaik-cell-group>
                    ${repeat(e.items, (x) => x.ref.id, (x, i) => html`
                        <mosaik-router-link .href="${'/feedback/detail'}"
                                        .query="${{ id: x.ref.id }}"
                                        style="display: inline;">
                    <mosaik-cell ${animate({ ...Animate.slideInBottom, ...{ options: { delay: 200 * i } } })}>
                        <mosaik-spacer slot="label"
                                       .thickness="${Spacing.Bottom}">
                            <mosaik-stack .gap="${'8px'}">
                            <mosaik-chip .label="${getFeedbackTypeSymbol(x.ref.type.name)} ${x.ref.type.name}"
                                         .appearance="${Appearance.Solid}"></mosaik-chip>
                            ${repeat(x.ref.labels, (y) => y, (y) => html`
                            <mosaik-chip .label="${y.name}"
                                         .appearance="${Appearance.Solid}"></mosaik-chip>
                            `)}
                            </mosaik-stack>
                        </mosaik-spacer>
                        <mosaik-text slot="label"
                                     .text="${x.ref.title}"
                                     .wrap="${true}"
                                     .maxLines="${2}"
                                     .formatter="${TextFormatters.MARKDOWN}"></mosaik-text>
                        <mosaik-text slot="subLabel"
                                     .text="${x.ref.body}"
                                     .wrap="${true}"
                                     .maxLines="${12}"
                                     .formatter="${TextFormatters.MARKDOWN}"></mosaik-text>
                        <mosaik-spacer slot="subLabel" .thickness="${Spacing.Top}">
                            <mosaik-stack .gap="${'16px'}"
                                          .verticalAlignment="${VerticalAlignment.Center}">
                                <div ${flex({ direction: 'row', justify: 'center', alignItems: 'center', gap: '4px' })}>
                                    <mosaik-icon .size="${Size.Tiny}"
                                                 .readonly="${true}"
                                                 .data="${'M15.25 13.5h-4a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0V12h3.25a.75.75 0 0 1 0 1.5ZM12 2C6.478 2 2 6.478 2 12s4.478 10 10 10 10-4.478 10-10S17.522 2 12 2Z'}"></mosaik-icon>
                                    <mosaik-text .text="${timeAgo(x.ref.createdAt)}" .readonly="${true}"></mosaik-text>
                                </div>
                                <div ${flex({ direction: 'row', justify: 'center', alignItems: 'center', gap: '4px' })}>
                                    <mosaik-icon .size="${Size.Tiny}"
                                                 .readonly="${true}"
                                                 .data="${'M8.25 2a3.25 3.25 0 0 0-3.241 3.007c.08-.005.16-.007.241-.007h9.5A4.25 4.25 0 0 1 19 9.25v6.5c0 .08-.002.161-.007.241A3.25 3.25 0 0 0 22 12.75v-6A4.75 4.75 0 0 0 17.25 2h-9Z M17.99 16a3.25 3.25 0 0 1-3.24 3h-4.083L7 21.75c-.824.618-2 .03-2-1v-1.76a3.25 3.25 0 0 1-3-3.24v-6.5A3.25 3.25 0 0 1 5.25 6h9.5A3.25 3.25 0 0 1 18 9.25v6.5c0 .084-.003.168-.01.25Z'}"></mosaik-icon>
                                    <mosaik-text .text="${x.ref.comments.length} comments" .readonly="${true}"></mosaik-text>
                                </div>
                            </mosaik-stack>
                        </mosaik-spacer>
                        <mosaik-divider  slot="end" .orientation="${Orientation.Vertical}"></mosaik-divider>
                        <mosaik-button slot="end"
                                       class="vote"
                                       .icon="${'m7.293 8.293 3.995-4a1 1 0 0 1 1.32-.084l.094.083 4.006 4a1 1 0 0 1-1.32 1.499l-.094-.083-2.293-2.291v11.584a1 1 0 0 1-.883.993L12 20a1 1 0 0 1-.993-.884L11 19.001V7.41L8.707 9.707a1 1 0 0 1-1.32.084l-.094-.084a1 1 0 0 1-.084-1.32l.084-.094 3.995-4-3.995 4Z'}"
                                       .orientation="${Orientation.Vertical}"
                                       .label="${watch(x.votes, (y) => y.toString())} ${'Votes'}"
                                       .variant="${Variant.Success}"
                                       .appearance="${Appearance.Plain}"
                                       .isBusy="${watch(x.isBusy)}"
                                       .fit="${Fit.Both}"
                                       @click="${(y: Event) => e.onVote(y, x)}"></mosaik-button>
                    </mosaik-cell>
                    </mosaik-router-link>
                    <mosaik-divider></mosaik-divider>
                    `)}
                </mosaik-cell-group>
                `)}
                ${when(e.isBusy, () => html`
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'16px'}">
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .width="${'100%'}"
                                     .height="${'166px'}"
                                     .shimmer="${true}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .width="${'100%'}"
                                     .height="${'166px'}"
                                     .shimmer="${true}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .width="${'100%'}"
                                     .height="${'166px'}"
                                     .shimmer="${true}"></mosaik-skeleton>
                </mosaik-stack>
                `)}

                ${when(e.items.length === 0 && !e.isBusy, () => html`
                <mosaik-empty-state .content="${translate('loc.feedback.empty')}"
                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-empty-state>
                `)}
            </mosaik-page-content>
        </mosaik-page>
    `;
}
