export function merge<T>(arr1: Array<T>, arr2: Array<T>, comparison: (a: T, b: T) => boolean): Array<T> {
    const mergedArray: Array<T> = [...arr1];

    for (const item of arr2) {
        const existingItem = mergedArray.find((existing) => comparison(existing, item)); // Assuming objects have an "id" property for comparison
        if (existingItem) {
            // If an item with the same "id" already exists, extend it
            Object.assign(existingItem, item);
        } else {
            // If not, push the item to the merged array
            mergedArray.push(item);
        }
    }

    return mergedArray;
}

export function exclude<T>(arr1: Array<T>, arr2: Array<T>, comparison: (a: T, b: T) => boolean): Array<T> {
    return arr1.filter((a1) => !arr2.some((a2) => comparison(a1, a2)));
}

/**
 * Splits an array into chunks of a specified size.
 *
 * @param array - The array to split into chunks.
 * @param size - The size of each chunk.
 * @returns An array of arrays, where each inner array is a chunk of the specified size.
 */
export function chunk<T>(array: Array<T>, size: number): Array<Array<T>> {
    if (size <= 0) {
        throw new Error('Size must be a positive integer');
    }

    const result: Array<Array<T>> = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }

    return result;
}

export function groupBy<T, TK>(arr: Array<T>, keyFn: (item: T) => TK): Array<{
    key: TK;
    items: Array<T>;
}> {
    const groupsMap = new Map<TK, Array<T>>();

    // Group elements by key
    for (const item of arr) {
        const key = keyFn(item);
        const group = groupsMap.get(key);
        if (group) {
            group.push(item);
        } else {
            groupsMap.set(key, [item]);
        }
    }

    // Convert map to array of group objects
    const groups: Array<{
        key: TK;
        items: Array<T>;
    }> = [];
    for (const [key, items] of groupsMap.entries()) {
        groups.push({
            key,
            items
        });
    }

    return groups;
}

/**
 * @public
 */
export function each<T>(self: Array<T> | ReadonlyArray<T>, fn: (value: T, index: number) => void): Array<T> | ReadonlyArray<T> {
    self.forEach((x, i) => {
        fn(x, i);
    });
    return self;
}

/**
 * @public
 */
export function clear<T>(self: Array<T>, fn?: (value: T, index: number) => void): Array<T> {
    const splicedItems = self.splice(0, self.length);
    if (fn) {
        each(splicedItems, fn);
    }
    return self;
}

/**
 * @public
 */
export function reset<T>(self: Array<T>, values: Array<T>): Array<T> {
    clear(self).push(...values);
    return self;
}
