//#region Imports

import { TemplateResult, html } from '@breadstone/mosaik-elements-foundation';

//#endregion

export namespace RatingTemplates {

    export function defaultTemplate(): TemplateResult {
        return html`
            <mosaik-rating></mosaik-rating>
        `;
    }

}