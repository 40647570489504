
// #region Imports

import { Animate, Appearance, BreakpointAlias, CSSResultGroup, DomServiceLocator, HorizontalAlignment, Inject, Inset, Orientation, Size, Spacing, State, TabStripPlacement, TemplateResult, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, VerticalAlignment, animate, classMap, flex, html, ifDefined, repeat, translate, when, type IBreakpointMatchedEvent, type IBreakpointMatchedEventDetail, type IPortalAttachedEvent, type IPortalAttachedEventDetail, type PortalProjectionElement } from '@breadstone/mosaik-elements-foundation';
import { CodeEditorLanguages } from '../../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../../Components/CodeEditor/CodeEditorTheme';
import type { HighlightElement } from '../../../Components/Highlight/HighlightElement';
import type { IPlaygroundThrowEvent } from '../../../Components/Playground/Events/IPlaygroundThrowEvent';
import { IPlaygroundElementProps, ITemplateDescriptor, TemplateResultFn } from '../../../Components/Playground/IPlaygroundElementProps';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { PlaygroundElement } from '../../../Components/Playground/PlaygroundElement';
import type { IPlaygroundPropertyGridElementProps } from '../../../Components/Playground/PlaygroundPropertyGridElement';
import type { IViewPortChangedEvent, IViewPortChangedEventDetail } from '../../../Components/ViewPort/Events/IViewPortChangedEvent';
import type { IViewPort } from '../../../Components/ViewPort/IViewPort';
import { VIEWPORTS } from '../../../Components/ViewPort/ViewPorts';
import { merge } from '../../../Extensions/ArrayExtensions';
import { ObjectExtensions } from '../../../Extensions/ObjectExtensions';
import { globals } from '../../../Globals';
import { CodeGenerator } from '../../../Services/Code/CodeGenerator';
import { ICodeModel } from '../../../Services/Code/Generators/Interfaces/ICodeGenerator';
import { FeatureManager } from '../../../Services/FeatureManager';
import { GraphService } from '../../../Services/GraphService';
import { ViewBase } from '../../Abstracts/ViewBase';
import { sampleBaseStyle } from './SampleBaseStyle';

// #endregion

export interface ISampleDescription<TElement extends HTMLElement = HTMLElement> {

    /**
     * The header of the sample.
     */
    header?: string;

    /**
     * The description of the sample.
     */
    description?: string;

    /**
     * The graph of the sample.
     */
    graph?: {
        mixinClasses: Array<string>;
        baseClass: string;
        class: string;
    };

    /**
     * The sample is experimental will show a badge with `preview` label.
     */
    experimental?: boolean;

    /**
     * The tags are useful (and only) for filtering and searching.
     */
    tags?: Array<string>;

    /**
     * The playground properties
     */
    playground: IPlaygroundElementProps<TElement>;

    /**
     * The property grid properties
     */
    propertyGrid: IPlaygroundPropertyGridElementProps<TElement>;

    /**
     * The docs
     */
    docs?: Array<TemplateResult>;
}

interface IEventTrigger {
    title: string;
    detail: string;
    event: Event;
}

/**
 * The `{@link SampleBaseElement}` element.
 *
 * @public
 * @abstract
 */
export abstract class SampleBaseElement<TElement extends HTMLElement = HTMLElement> extends ViewBase {

    // #region Fields

    @Inject(CodeGenerator)
    private readonly _codeGenerator!: CodeGenerator;
    @Inject(GraphService)
    private readonly _graphService!: GraphService;
    @State()
    private _isLessThanMedium: boolean = false;
    @State()
    private _isMaximized: boolean = false;
    @State()
    private _isResizeable: boolean = false;
    @State()
    private _isSizeViewerVisible: boolean = false;
    @State()
    private _selectedTemplate: ITemplateDescriptor | null = null;
    @State()
    private _selectedTemplateResult: TemplateResult | TemplateResultFn<TElement> | null = null;
    @State()
    private _viewPort: {
        zoom: number;
        viewPort: IViewPort;
        visualize: boolean;
    } = {
            zoom: 100,
            viewPort: VIEWPORTS[0],
            visualize: false
        };
    @State()
    private _logs: Array<IEventTrigger> = new Array<IEventTrigger>();
    private _bottomSheetProjection: PortalProjectionElement | null = null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @override
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return [
            ViewBase.styles,
            sampleBaseStyle()
        ];
    }

    /**
     * Returns the `ofElement` property.
     *
     * @protected
     * @readonly
     */
    @State()
    protected get ofElement(): TElement | null {
        const playground = this.shadowRoot
            ? DomServiceLocator.current.findDescendant(this.shadowRoot, PlaygroundElement<TElement>)
            : null;

        return playground?.ofElement ?? null;
    }

    /**
     * Returns the `playgroundElement` property.
     *
     * @protected
     * @readonly
     */
    protected get playgroundElement(): PlaygroundElement<TElement> | null {
        return this.shadowRoot
            ? DomServiceLocator.current.findDescendant(this.shadowRoot, PlaygroundElement<TElement>)
            : null;
    }

    /**
     * Returns the `description` property.
     *
     * @protected
     * @abstract
     * @readonly
     */
    protected abstract get description(): ISampleDescription<TElement>;

    // #endregion

    // #region Methods

    public override connectedCallback(): void {
        super.connectedCallback();

        this._selectedTemplate = this.getTemplateDescriptors()[0];
        this._selectedTemplateResult = this.tryGetTemplateResult();
    }

    public override disconnectedCallback(): void {
        this._bottomSheetProjection?.remove();
        super.disconnectedCallback();
    }

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${this.description.header ?? ''}"></title>
                <meta name="description" content="${ifDefined(this.description.description)}"/>
                <meta name="keywords" content="${ifDefined(this.description.tags?.join(', '))}"/>
                <meta name="og:title" content="${ifDefined(this.description.header)}">
                <meta name="og:description" content="${ifDefined(this.description.description)}"/>
                <meta name="twitter:title" content="${ifDefined(this.description.header)}"/>
                <meta name="twitter:description" content="${ifDefined(this.description.description)}"/>
            </mosaik-helmet>
            <mosaik-breakpoint .query="${BreakpointAlias.LessThanMedium}"
                               @matched="${(x: IBreakpointMatchedEvent) => this.onBreakpointMatched(x.detail)}"></mosaik-breakpoint>
            <mosaik-page breakpoint="md"
                         ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-stack>
                        <mosaik-stack>
                            <mosaik-breadcrumb ?wrap="${true}">
                                <mosaik-breadcrumb-item>
                                    <mosaik-button .href="${'/'}"
                                                .label="${translate('loc.nav.home')}"></mosaik-button>
                                </mosaik-breadcrumb-item>
                                <mosaik-breadcrumb-item>
                                    <mosaik-button .href="${'/components'}"
                                                .label="${translate('loc.nav.components')}"></mosaik-button>
                                </mosaik-breadcrumb-item>
                                <mosaik-breadcrumb-item .isActive="${true}">
                                    <mosaik-button .href="${'/components/'}${this.description.header}"
                                                .label="${this.description.header ?? '?'}"></mosaik-button>
                                </mosaik-breadcrumb-item>
                            </mosaik-breadcrumb>
                        </mosaik-stack>
                        <mosaik-button .iconSize="${Size.Small}"
                                       .appearance="${Appearance.Plain}"
                                       .icon="${'M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z'}"
                                       @click="${() => this.emit('requestPreviousSample' as any)}"></mosaik-button>
                        <mosaik-button .iconSize="${Size.Small}"
                                       .appearance="${Appearance.Plain}"
                                       .icon="${'M8.293 4.293a1 1 0 0 0 0 1.414L14.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 0 0-1.414 0Z'}"
                                       @click="${() => this.emit('requestNextSample' as any)}"></mosaik-button>
                    </mosaik-stack>
                </mosaik-page-pre-header>
                <mosaik-page-content .inset="${Inset.Horizontal}">
                    <mosaik-stack orientation="${Orientation.Vertical}"
                                  gap="32px">
                        ${when(this.getTemplateDescriptors().length > 0, () => html`
                        <mosaik-select>
                            ${repeat(this.getTemplateDescriptors(), (x) => x.name, (x) => html`
                            <mosaik-select-item .label="${x.name} - ${x.description ?? ''}"
                                                .isActive="${this._selectedTemplate === x}"
                                                .isSelected="${this._selectedTemplate === x}"
                                                @click="${() => this.onToggleTemplate(x)}"></mosaik-select-item>
                            `)}
                        </mosaik-select>
                        `)}
                        <mosaik-card part="main"
                                     class="${classMap({
                                         'fullscreen': this._isMaximized,
                                         'lt-md': this._isLessThanMedium
                                     })}"
                                     .appearance="${Appearance.Outline}"
                                     .inset="${Inset.None}">
                            <mosaik-card-header .text="${this.description.header ?? ''}"
                                                .subText="${this.description.description ?? ''}">
                                <mosaik-card-title>
                                    <mosaik-router-anchor .ref="${'preview'}"
                                                          .label="${this.description.header ?? ''}"></mosaik-router-anchor>
                                </mosaik-card-title>
                                ${when(this.description.experimental ?? false, () => html`
                                <mosaik-spacer .thickness="${Spacing.Top}"
                                               .size="${Size.Tiny}">
                                    <mosaik-chip .label="${translate('loc.global.experimental')}"
                                                 .icon="${'M9 4.5v6.238c0 .375-.094.744-.273 1.074L7.54 14h8.922l-1.188-2.188A2.25 2.25 0 0 1 15 10.738V4.5h1A.75.75 0 0 0 16 3H8a.75.75 0 0 0 0 1.5h1ZM17.275 15.5H6.725l-1.582 2.915A1.75 1.75 0 0 0 6.68 21h10.638a1.75 1.75 0 0 0 1.538-2.585L17.275 15.5Z'}"
                                                 .variant="${Variant.Highlight}"
                                                 .appearance="${Appearance.Soft}"></mosaik-chip>
                                </mosaik-spacer>

                                `)}
                            </mosaik-card-header>
                            <mosaik-card-content>
                            ${when(!this._isLessThanMedium, () => html`
                            <mosaik-split part="split"
                                          style='--split-max: 70%; --split-min: 10%; overflow: hidden; flex: 1;'
                                          .orientation="${Orientation.Horizontal}"
                                          .position="${60}"
                                          .snaps="${['50%', '70%']}"
                                          .disabled="${true}">
                                <mosaik-stack slot="start"
                                              part="splitPanel"
                                              ${flex({ fill: true })}
                                              .horizontalAlignment="${HorizontalAlignment.Stretch}"
                                              .verticalAlignment="${VerticalAlignment.Stretch}"
                                              .orientation="${Orientation.Horizontal}"
                                              style="min-height: 280px;">
                                    <app-playground .template="${this._selectedTemplateResult}"
                                                    .properties="${this.description.playground.properties}"
                                                    .events="${this.description.playground.events ?? []}"
                                                    .viewPort="${this._viewPort}"
                                                    .resizeable="${this._isResizeable}"
                                                    @eventTriggered="${(e: IPlaygroundThrowEvent) => this.onEventTriggered(e)}"
                                                    @connected="${() => this.requestUpdate()}">
                                        ${when(FeatureManager.isActive('playgroundTools'), () => html`
                                        <app-view-port .viewPort="${this._viewPort.viewPort}"
                                                       .zoom="${this._viewPort.zoom}"
                                                       .visualize="${this._viewPort.visualize}"
                                                       .appearance="${Appearance.Soft}"
                                                       @viewPortChanged="${(e: IViewPortChangedEvent) => this.onViewPortChanged(e.detail)}"
                                                       @maximizedChanged="${(e: CustomEvent<boolean>) => this._isMaximized = e.detail}"
                                                       @sizeTrackerChanged="${(e: CustomEvent<boolean>) => this.onToggleSizeViewer(e.detail)}"
                                                       @resizeableChanged="${(e: CustomEvent<boolean>) => this._isResizeable = e.detail}"></app-view-port>
                                        `)}
                                    </app-playground>
                                </mosaik-stack>
                                <mosaik-stack slot="end"
                                              part="splitPanel"
                                              .verticalAlignment="${VerticalAlignment.Stretch}"
                                              .horizontalAlignment="${HorizontalAlignment.Stretch}">
                                    <!-- <mosaik-card part="properties"
                                                .appearance="${Appearance.Plain}">
                                        <mosaik-card-content> -->
                                            <mosaik-tab ${flex({ fill: true })}
                                                        style="--tab-border-radius: 0; --tab-background-color: var(--theme-background-color);"
                                                        .appearance="${Appearance.Plain}"
                                                        .variant="${Variant.Primary}"
                                                        .placement="${TabStripPlacement.Right}"
                                                        .hasIndicator="${false}">
                                                <mosaik-tab-item .label="${translate('loc.sample.properties')}">
                                                    <app-playground-property-grid id="playgroundPropertyGrid"
                                                                                  part="playgroundPropertyGrid"
                                                                                  .properties="${this.description.propertyGrid.properties}"
                                                                                  .exludedProperties="${this.description.propertyGrid.exludedProperties ?? null}"
                                                                                  .ofElement="${this.ofElement}"
                                                                                  @playgroundPropertyGridChange="${(e: any) => this.onPlaygroundPropertyGridChange(e)}"></app-playground-property-grid>
                                                </mosaik-tab-item>
                                                ${when(FeatureManager.isActive('playgroundEvents'), () => html`
                                                <mosaik-tab-item .label="${translate('loc.sample.log')}">
                                                    <app-playground-log .value="${this._logs}"
                                                                        .isClearable="${true}"
                                                                        @cleared="${() => this.clearLogs()}"></app-playground-log>
                                                </mosaik-tab-item>
                                                `)}
                                            </mosaik-tab>

                                        <!-- </mosaik-card-content>
                                        <mosaik-card-footer>
                                            <mosaik-hint .text="${translate('loc.sample.propertiesCount', this.description.propertyGrid.properties?.length ?? 0)}"
                                                         ?readonly="${true}"></mosaik-hint>
                                        </mosaik-card-footer>
                                    </mosaik-card> -->
                                </mosaik-stack>
                            </mosaik-split>
                            `, () => html`
                            <app-playground .template="${this._selectedTemplateResult}"
                                            .properties="${this.description.playground.properties}"
                                            .events="${this.description.playground.events ?? []}"
                                            @eventTriggered="${(e: IPlaygroundThrowEvent) => this.onEventTriggered(e)}"
                                            @connected="${() => this.requestUpdate()}"></app-playground>
                            `)}
                            </mosaik-card-content>
                        </mosaik-card>

                        <mosaik-card part="code"
                                     .appearance="${Appearance.Soft}">
                            <mosaik-card-header>
                                <mosaik-card-title>
                                    <mosaik-router-anchor .ref="${'code'}"
                                                          .label="${translate('loc.sample.code.header')}"></mosaik-router-anchor>
                                </mosaik-card-title>
                            </mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-tab .appearance="${Appearance.Soft}"
                                            .variant="${Variant.Primary}">
                                    ${repeat(this._codeGenerator.generators, (x) => x, (x) => html`
                                    <mosaik-tab-item .label="${x}">
                                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                                      .gap="${'16px'}">
                                            <app-code-editor part="highlight"
                                                            .text="${this._codeGenerator.process(this.toCodeModel(), x)}"
                                                            .language="${CodeEditorLanguages.getLanguage(x)}"
                                                            .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                                            <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                                          .horizontalAlignment="${HorizontalAlignment.Right}">
                                                <mosaik-button .label="${translate('loc.sample.code.copy.label')}"
                                                               .appearance="${Appearance.Solid}"
                                                               .variant="${Variant.Primary}"
                                                               @click="${() => this.onCopyCode(this._codeGenerator.process(this.toCodeModel(), x))}"></mosaik-button>
                                            </mosaik-stack>
                                        </mosaik-stack>
                                    </mosaik-tab-item>
                                    `)}
                                </mosaik-tab>
                            </mosaik-card-content>
                        </mosaik-card>

                        ${repeat(this.description.docs ?? [], (x) => x, (x) => html`
                        <mosaik-card part="docs"
                                     appearance="${Appearance.Outline}">
                            <mosaik-card-header>
                                <mosaik-card-title>
                                    <mosaik-router-anchor .ref="${'docs'}"
                                                          .label="${translate('loc.sample.docs.header')}"></mosaik-router-anchor>
                                </mosaik-card-title>
                            </mosaik-card-header>
                            <mosaik-card-content>
                                <div part="docs">
                                    ${x}
                                </div>
                            </mosaik-card-content>
                        </mosaik-card>
                        `)}

                        <mosaik-card part="diagramm" .appearance="${Appearance.Soft}">
                            <mosaik-card-header>
                                <mosaik-card-title >
                                    <mosaik-router-anchor .ref="${'diagramm'}"
                                                          .label="${translate('loc.sample.diagramm.header')}"></mosaik-router-anchor>
                                </mosaik-card-title>
                            </mosaik-card-header>
                            <mosaik-card-content>
                                ${when(this.description.graph, () => html`
                                <app-diagramm .text="${this.description.graph ? this._graphService.renderGraph(this.description.graph) : ''}"></app-diagramm>
                                `)}
                            </mosaik-card-content>
                        </mosaik-card>
                    </mosaik-stack>
                </mosaik-page-content>
            </mosaik-page>

            <!-- the protal for mobile properties -->
            ${when(this._isLessThanMedium, () => html`
            <mosaik-portal @portalAttached="${(x: IPortalAttachedEvent) => this.onPortalAttached(x.detail)}">
                <mosaik-bottom-sheet part="sheet"
                                     .header="${translate('loc.sample.properties')}"
                                     .isOpen="${true}">
                    <app-playground-property-grid id="playgroundPropertyGrid"
                                                  part="playgroundPropertyGrid"
                                                  .properties="${this.description.propertyGrid.properties}"
                                                  .exludedProperties="${this.description.propertyGrid.exludedProperties ?? null}"
                                                  .ofElement="${this.ofElement}"
                                                  @playgroundPropertyGridChange="${(e: any) => this.onPlaygroundPropertyGridChange(e)}"></app-playground-property-grid>
                </mosaik-bottom-sheet>
            </mosaik-portal>
            `)}

            <!-- <app-toc class="sticky">
                <ul slot="toc">
                    <li>
                        <a href="#beschreibung" class="toc-link">Characteristics</a>
                        <ul>
                            <li>
                                <a href="#lebensweise" class="toc-link">Natural habitat</a>
                            </li>
                            <li>
                                <a href="#nahrung" class="toc-link">Diet</a>
                            </li>
                            <li>
                                <a href="#fortpflanzung" class="toc-link">Reproduction and life cycle</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#opossumarten-und-verbreitung" class="toc-link">Possum celebrities</a>
                    </li>
                </ul>
            </app-toc> -->
        `;
    }

    /**
     * @private
     */
    private onToggleSizeViewer(detail: boolean): void {
        this._isSizeViewerVisible = detail;
    }

    /**
     * @private
     */
    private onToggleTemplate(descriptor: ITemplateDescriptor): void {
        this._selectedTemplate = descriptor;
        this._selectedTemplateResult = this.tryGetTemplateResult(descriptor);

        // Override properties if template has properties
        const properties = this.tryGetTemplateProperties(descriptor);

        if (properties) {
            // Sync properties in playground
            if (this.description.playground.properties) {
                this.description.playground.properties = merge(this.description.playground.properties, properties, (x, y) => x.key === y.key);
            }

            // Sync properties in property grid
            if (this.description.propertyGrid.properties) {
                this.description.propertyGrid.properties = merge(this.description.propertyGrid.properties, properties, (x, y) => x.key === y.key);
                // This.getTemplatePart<PlaygroundPropertyGridElement>('playgroundPropertyGrid').properties = this.description.propertyGrid.properties as any;
            }
        } else {
            // If no properties are defined, reset the defaults
            if (this.description.playground.properties) {
                this.description.playground.properties = [];
            }
        }
    }

    /**
     * @private
     */
    private onPlaygroundPropertyGridChange(e: CustomEvent<{
        property: keyof TElement;
        value: TElement[keyof TElement];
    }>): void {
        if (this.description.playground.properties) {
            const index = this.description.playground.properties.findIndex((x) => x.key === e.detail.property);
            if (index !== -1) {
                this.description.playground.properties = merge(this.description.playground.properties, [
                    {
                        key: e.detail.property,
                        value: e.detail.value
                    }
                ], (x, y) => x.key === y.key);
            }
        }

        if (this.ofElement) {
            if (ObjectExtensions.set<TElement>(this.ofElement, e.detail.property, e.detail.value)) {
                setTimeout(() => {
                    this.getTemplatePart<HighlightElement>('highlight').text = this.ofElement?.outerHTML ?? '';
                }, 100);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                console.warn(`### MOSAIK: Could not set property '${String(e.detail.property)}' to value '${String(e.detail.value)}'.`);
            }
        }

        // Format to base64 and append it to the url
        const { properties } = this.toCodeModel();
        const model = btoa(JSON.stringify(properties));
        const params = new URLSearchParams(window.location.search);
        params.set('model', model);
        console.log(params.toString());
        // Window.history.replaceState({}, '', params.toString());
        // TODO: handle here the url update with the new base64 encoded properties.
        // Append them to the url.
    }

    /**
     * @private
     */
    private onCopyCode(code: string): void {
        void navigator.clipboard.writeText(code).then(() => {
            void ToastServiceLocator.current.open({
                variant: Variant.Success,
                content: TranslatorServiceLocator.current.translate('loc.sample.code.copy.message'),
                timeout: Toast2.Timeout.SHORT
            });
        });
    }

    /**
     * @private
     */
    private onPortalAttached(e: IPortalAttachedEventDetail): void {
        this._bottomSheetProjection = e.projection;
    }

    /**
     * @private
     */
    private onViewPortChanged(e: IViewPortChangedEventDetail): void {
        this._viewPort = e;
    }

    /**
     * @private
     */
    private onBreakpointMatched(e: IBreakpointMatchedEventDetail): void {
        this._isLessThanMedium = e.match;

        if (!e.match) {
            this._bottomSheetProjection?.remove();
        }
    }

    /**
     * @private
     */
    private onEventTriggered(event: IPlaygroundThrowEvent): void {
        const e = event.detail.originalEvent;
        const target = e.target as HTMLElement;

        if (e instanceof CustomEvent) {
            const copy = [...this._logs];
            const msg = {
                title: `${new Date().toLocaleTimeString()}: [${target.tagName.toLowerCase()}] ${e.type}`,
                detail: e.detail,
                event: e
            };

            copy.push(msg);
            this._logs = copy;
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            console.warn(`### MOSAIK: Could not log event '${e.type}'.`);
        }

        // Console.debug(msg);
    }

    /**
     * @private
     */
    private tryGetTemplateResult(descriptor?: ITemplateDescriptor): TemplateResult | TemplateResultFn<TElement> | null {
        let tpl: TemplateResult | TemplateResultFn<TElement> | null = null;

        if (Array.isArray(this.description.playground.template)) {
            if (descriptor) {
                tpl = this.description.playground.template.find((x) => {
                    // This is the fallback logic (legacy support) when template names are only strings
                    if (typeof x[0] === 'string') {
                        return x[0] === descriptor.name;
                    }

                    return x[0] === descriptor;
                })?.[1] ?? null;
            } else {
                tpl = this.description.playground.template[0][1];
            }
        } else {
            tpl = this.description.playground.template;
        }

        return tpl;
    }

    /**
     * @private
     */
    private tryGetTemplateProperties(descriptor?: ITemplateDescriptor): Array<IPlaygroundProperty<TElement>> | null {
        if (Array.isArray(this.description.playground.template)) {
            if (descriptor) {
                return this.description.playground.template.find((x) => {
                    // This is the fallback logic (legacy support) when template names are only strings
                    if (typeof x[0] === 'string') {
                        return x[0] === descriptor.name;
                    }

                    return x[0] === descriptor;
                })?.[2] ?? [];
            }

            return null;
        }

        return null;
    }

    /**
     * @private
     */
    private getTemplateDescriptors(): Array<ITemplateDescriptor> {
        if (Array.isArray(this.description.playground.template)) {
            return this.description.playground.template.map((x) => {
                if (typeof x[0] === 'string') {
                    return {
                        name: x[0],
                        description: 'Legacy'
                    };
                }

                return x[0];
            });
        }

        return [];
    }

    private clearLogs(): void {
        this._logs = [];
    }

    /**
     * @private
     */
    private toCodeModel(): ICodeModel {
        const componentName = this.description.header ?? '';
        const properties = this.description.propertyGrid.properties?.reduce((acc, x) => {
            acc[x.key as string] = x.value;
            return acc;
        }, {} as Record<string, any>) ?? {};
        return {
            componentPrefix: 'mosaik',
            componentName: componentName,
            properties: properties
        };
    }

    // #endregion

}
