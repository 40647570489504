// #region Imports

import { Appearance, ChatElement, Component, FlowDirection, Orientation, State, TextFormatters, Variant, interpolate, repeat } from '@breadstone/mosaik-elements-foundation';
import type { IChatSubmitedEventDetail } from '@breadstone/mosaik-elements-foundation/dist/Controls/Components/Media/Chat/Events/IChatSubmitedEventDetail';
import { html, type CSSResultGroup } from 'lit';
import { chatElementDocs } from '../../../.Generated/mosaik-chat';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, jsonTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { globals } from '../../../Globals';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ChatSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-chat-sample'
})
export class ChatSampleElement extends SampleBaseElement<ChatElement> {

    // #region Fields

    @State()
    private _customMessages: Array<string> = [];

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChatElement> {

        return {
            header: 'Chat',
            description: META.description(ChatElement.is),
            graph: META.graph(ChatElement.is),
            playground: {
                template: html`
                    <mosaik-chat @submited="${(e: CustomEvent<IChatSubmitedEventDetail>) => this.onSubmited(e)}">
                        <mosaik-chat-header .title="${'Conversation'}"
                                            .subTitle="${'between *John* and *AI*'}"
                                            .formatter="${TextFormatters.RICHTEXT}"></mosaik-chat-header>
                        <mosaik-chat-marker .text="${'Messages you send to this chat and calls are now secured with end-to-end encryption. Tap for more info.'}"
                                            .variant="${Variant.Warning}"
                                            .orientation="${Orientation.Vertical}"
                                            .icon="${'M12 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 20 10.25v9.5A2.25 2.25 0 0 1 17.75 22H6.25A2.25 2.25 0 0 1 4 19.75v-9.5A2.25 2.25 0 0 1 6.25 8H8V6a4 4 0 0 1 4-4Zm5.75 7.5H6.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h11.5a.75.75 0 0 0 .75-.75v-9.5a.75.75 0 0 0-.75-.75Zm-5.75 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-10A2.5 2.5 0 0 0 9.5 6v2h5V6A2.5 2.5 0 0 0 12 3.5Z'}"
                                            ></mosaik-chat-marker>
                        <mosaik-chat-message-divider .text="${'Today'}"
                                                     .isSticky="${true}"></mosaik-chat-message-divider>
                        <mosaik-chat-message .text="${'Hey 👋'}">
                            <mosaik-chat-message-avatar .text="${'John Doe'}"
                                                        .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        <mosaik-chat-message .text="${'Hey'}"
                                             .reply="${true}">
                            <mosaik-chat-message-avatar .text="${'AI'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        <mosaik-chat-message .text="${'How are u?'}">
                            <mosaik-chat-message-avatar .text="${'John Doe'}"
                                                        .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        <mosaik-chat-message .text="${'Good!'}"
                                             .reply="${true}">
                            <mosaik-chat-message-avatar .text="${'AI'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        <mosaik-chat-message .text="${'How are you?'}"
                                             .reply="${true}">
                            <mosaik-chat-message-avatar .text="${'AI'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        <mosaik-chat-message .text="${interpolate("I'm grindin' on this dope component framework, ya know? Ever heard of it?\n http://mosaik.breadstone.de", globals.name)}"
                                             .formatter="${TextFormatters.RICHTEXT}">
                            <mosaik-chat-message-avatar .text="${'John Doe'}"
                                                        .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        ${repeat(this._customMessages, (x) => x, (x) => html`
                        <mosaik-chat-message .text="${x}">
                            <mosaik-chat-message-avatar .text="${'John Doe'}"
                                                        .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-chat-message-avatar>
                        </mosaik-chat-message>
                        `)}
                    </mosaik-chat>
                `,
                properties: META.properties<ChatElement>(ChatElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'height',
                        value: '500px'
                    },
                    {
                        key: 'width',
                        value: '400px'
                    }
                    // { key: 'icon', value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z' },
                    // { key: 'size', value: Size.Medium },
                    // { key: 'label', value: '100' },
                    // { key: 'variant', value: Variant.Primary },
                    // { key: 'position', value: ChatPosition.TopRight },
                ]),
                events: META.events<ChatElement>(ChatElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ChatElement>(ChatElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'height',
                        value: '500px'
                    },
                    {
                        key: 'width',
                        value: '400px'
                    }
                    // { key: 'icon', value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z' },
                    // Cha{ key: 'size', value: Size.Medium },
                    // { key: 'label', value: '100' },
                    // { key: 'variant', value: Variant.Primary },
                    // { key: 'position', value: ChatPosition.TopRight },
                ]), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'reactions',
                        template: jsonTemplate
                    }
                ] as Array<IPlaygroundProperty<ChatElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                chatElementDocs()
            ]
        };

    }

    private onSubmited(e: CustomEvent<IChatSubmitedEventDetail>): void {

        const copy = this._customMessages;
        copy.push(String(e.detail.message));
        this._customMessages = copy;

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-chat-sample': ChatSampleElement;
    }
}
