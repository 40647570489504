// #region Imports

import { Attachable, Attribute, Component, CustomElement, Emit, HTMLElementEventEmitter, IEventEmitter, RendererServiceLocator, on, type IAttachable, type IEventDetail } from '@breadstone/mosaik-elements-foundation';
import { backTopElementStyle } from './BackTopElementStyle';
import { backTopElementTemplate } from './BackTopElementTemplate';

// #endregion

/**
 * Represents the `{@link IBackTopElementProps}` interface.
 *
 * @public
 */
export interface IBackTopEvents {
    restored: IEventEmitter<IEventDetail<BackTopElement>>;
}

/**
 * Represents the `{@link IBackTopElementProps}` interface.
 *
 * @public
 */
export interface IBackTopElementProps {

    offset: number;

}

/**
 * The `{@link BackTopElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-back-top',
    template: backTopElementTemplate,
    styles: backTopElementStyle,
    imports: []
})
export class BackTopElement extends Attachable(CustomElement) implements IBackTopElementProps, IAttachable, IBackTopEvents {

    // #region Fields

    private readonly _restored: IEventEmitter<IEventDetail<this>>;
    private _offset: number;
    private _visible: boolean;
    private _position: number;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._offset = 120;
        this._visible = false;
        this._position = 0;
        this._restored = new HTMLElementEventEmitter(this, 'restored');
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-backtop';
    }

    /**
     * Gets or sets the `offset` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get offset(): number {
        return this._offset;
    }
    public set offset(value: number) {
        if (this._offset !== value) {
            this._offset = value;
            this.requestUpdate('offset');
        }
    }

    /**
     * Returns the `position` property.
     *
     * @public
     * @readonly
     */
    public get position(): number {
        return this._position;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'restored' })
    public get restored(): IEventEmitter<IEventDetail<this>> {
        return this._restored;
    }

    /**
     * Emitts the {@link restored} event.
     *
     * @protected
     */
    protected onRestored(args: any): void {
        this._restored.emit(args);
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        if (this.control) {
            on(this.control, 'scroll', () => {
                this._position = this.getPosition(this.control as any);

                const offset = this.measureScrollOffset(this.control!, 'top') ?? 0;
                const isVisible = offset > this._offset;

                if (isVisible !== this._visible) {
                    this._visible = isVisible;

                    if (isVisible) {
                        RendererServiceLocator.current.addClass(this, 'active');
                    } else {
                        RendererServiceLocator.current.removeClass(this, 'active');
                    }
                }
            });
        }
    }

    /**
     * @public
     */
    public onClick(): void {
        if (this.control) {
            this.control.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            this.restored.emit({
                sender: this,
                restored: true
            });
        }
    }

    /**
     * @private
     */
    private getPosition(el?: HTMLElement | null): number {
        return el ? Math.round((100 / (el.scrollHeight - el.offsetHeight)) * el.scrollTop) ?? 0 : 0;
    }

    /**
     * Measures the scroll offset relative to the specified edge of the viewport. This method can be
     * used instead of directly checking scrollLeft or scrollTop, since browsers are not consistent
     * about what scrollLeft means in RTL. The values returned by this method are normalized such that
     * left and right always refer to the left and right side of the scrolling container irrespective
     * of the layout direction. start and end refer to left and right in an LTR context and vice-versa
     * in an RTL context.
     *
     * @private
     * @param from The edge to measure from.
     */
    private measureScrollOffset(el: HTMLElement, from: 'top' | 'left' | 'right' | 'bottom' | 'start' | 'end'): number {
        const LEFT = 'left';
        const RIGHT = 'right';

        if (from === 'top') {
            return el.scrollTop;
        }

        if (from === 'bottom') {
            return el.scrollHeight - el.clientHeight - el.scrollTop;
        }

        // Rewrite start & end as left or right offsets.
        const isRtl = this.dir && this.dir === 'rtl';
        if (from === 'start') {
            from = isRtl ? RIGHT : LEFT;
        } else if (from === 'end') {
            from = isRtl ? LEFT : RIGHT;
        }

        return from === LEFT
            ? el.scrollLeft
            : el.scrollWidth - el.clientWidth - el.scrollLeft;
    }

    // #endregion

}

declare global {

    interface HTMLElementTagNameMap {

        'app-back-top': BackTopElement;
    }

    interface HTMLElementEventMap {
        restored: CustomEvent<IEventDetail>;
    }
}
