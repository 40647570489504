// #region Imports

import { Appearance, BreakpointAlias, Orientation, Size, TextFormatters, Variant, html, repeat, when, type IBreakpointMatchedEvent, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { ApiDocsLayoutElement } from './ApiDocsLayoutElement';

// #endregion

/**
 * The template of the {@link ApidocslayoutElement}.
 *
 * @public
 */
export function apiDocsLayoutElementTemplate<T extends ApiDocsLayoutElement>(e: T): TemplateResult {
    return html`
        <mosaik-breakpoint .query="${BreakpointAlias.LessThanMedium}"
                           @matched="${(x: IBreakpointMatchedEvent) => e.onBreakpointMatched(x.detail.match)}"></mosaik-breakpoint>

        <mosaik-stack .orientation="${Orientation.Vertical}"
                      .gap="${'32px'}">
            ${when(e.properties.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '30% 20% 50%'}"
                          .caption="${'Properties'}"
                          .appearance="${Appearance.Plain}">
                <mosaik-table-body>
                    ${repeat(e.properties.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-stack .gap="${'8px'}"
                                          .verticalAlignment="${'center'}">
                            <mosaik-text .text="${x.name ?? ''}" ?wrap="${false}"></mosaik-text>
                            ${when(e.attrs.map((x) => x.name).includes(x.name), () => html`
                            <mosaik-tooltip .content="${'This property is available as dom attribute.'}">
                                <mosaik-chip .variant="${Variant.Success}"
                                                .appearance="${Appearance.Soft}"
                                                .size="${Size.Small}"
                                                .label="${'Attribute'}"></mosaik-chip>
                                                </mosaik-tooltip>
                            `)}
                            </mosaik-stack>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-code .text="${x.type?.toString() ?? ''}"></mosaik-code>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
            ${when(e.methods.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '30% 70%'}" caption="Methods" appearance="plain">
                <mosaik-table-body>
                    ${repeat(e.methods.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${x.name}"></mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
            ${when(e.events.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '30% 70%'}" caption="Events" appearance="plain">
                <mosaik-table-body>
                    ${repeat(e.events.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${x.name}"></mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
            ${when(e.slots.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '30% 70%'}" caption="Slots" appearance="plain">
                <mosaik-table-body>
                    ${repeat(e.slots.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${x.name}"></mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
            ${when(e.cssParts.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '30% 70%'}" caption="CSS Parts" appearance="plain">
                <mosaik-table-body>
                    ${repeat(e.cssParts.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${x.name}"></mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
            ${when(e.cssProperties.length > 0, () => html`
            <mosaik-table .columns="${e.isMobile ? '100%' : '40% 20% 40%'}" caption="CSS Propertiests" appearance="plain">
                <mosaik-table-body>
                    ${repeat(e.cssProperties.toSorted((a, b) => a.name.localeCompare(b.name)), (x) => x.name, (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${x.name}"></mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-code .text="${(x as any).type ?? ''}"></mosaik-code>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.description ?? ''}"
                                         .formatter="${TextFormatters.MARKDOWN}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
            </mosaik-table>
            `)}
        </mosaik-stack>
    `;
}
