//#region Imports

import { Attribute, Component, CustomElement, IconElement, Property, TextElement } from '@breadstone/mosaik-elements-foundation';
import { type IPricingTableRowElementProps } from './IPricingTableRowElementProps';
import { pricingTableRowElementStyle } from './PricingTableRowElementStyle';
import { pricingTableRowElementTemplate } from './PricingTableRowElementTemplate';

//#endregion

/**
 * The `{@link PricingTableRowElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-pricing-table-row',
    template: pricingTableRowElementTemplate,
    styles: pricingTableRowElementStyle,
    host: {
        role: 'listitem'
    },
    imports: [
        TextElement,
        IconElement
    ]
})
export class PricingTableRowElement extends CustomElement implements IPricingTableRowElementProps {

    //#region Fields

    private _text: string;
    private _available: boolean | null | undefined;

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._text = '';
        this._available = null;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-pricingtablerow';
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    /**
     * Gets or sets the `available` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get available(): boolean | null | undefined {
        return this._available;
    }
    public set available(value: boolean | null | undefined) {
        if (this._available !== value) {
            this._available = value;
            this.requestUpdate('available');
        }
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-pricing-table-row': PricingTableRowElement;
    }
}

