// #region Imports

import { CSSResultGroup, Component, DataListElement, FlowDirection, html, type IDataListItemDefinition } from '@breadstone/mosaik-elements-foundation';
import { dataListElementDocs } from '../../../.Generated/mosaik-data-list';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { jsonTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DataListSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-data-list-sample'
})
export class DataListSampleElement extends SampleBaseElement<DataListElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DataListElement> {

        return {
            header: 'Data List',
            description: META.description(DataListElement.is),
            graph: META.graph(DataListElement.is),
            playground: {
                template: html`
                    <mosaik-data-list style="height: 300px"></mosaik-data-list>
                `,
                properties: META.properties<DataListElement>(DataListElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    // { key: 'label', value: 'Label', type: String },
                    // { key: 'subLabel', value: 'Sub label', type: String },
                    // { key: 'icon', value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z', type: String },
                    // { key: 'disabled', value: false, type: Boolean },
                    // { key: 'appearance', value: Appearance.Soft, type: Appearance },
                    {
                        key: 'source',
                        value: [{
                            id: 1,
                            name: 'Cosme Lucchi',
                            avatar: 'https://robohash.org/perspiciatisexdoloribus.jpg?size=48x48&set=set1',
                            email: 'clucchi0@liveinternet.ru'
                        }, {
                            id: 2,
                            name: 'Kynthia Winckles',
                            avatar: 'https://robohash.org/cumquequiea.jpg?size=48x48&set=set1',
                            email: 'kwinckles1@rambler.ru'
                        }, {
                            id: 3,
                            name: 'Leonelle Maskelyne',
                            avatar: 'https://robohash.org/porrosintut.jpg?size=48x48&set=set1',
                            email: 'lmaskelyne2@fastcompany.com'
                        }, {
                            id: 4,
                            name: 'Arnie Tollemache',
                            avatar: 'https://robohash.org/quasisitin.jpg?size=48x48&set=set1',
                            email: 'atollemache3@vimeo.com'
                        }, {
                            id: 5,
                            name: 'Ricky Egdal',
                            avatar: 'https://robohash.org/quivoluptatemeveniet.jpg?size=48x48&set=set1',
                            email: 'regdal4@cnbc.com'
                        }, {
                            id: 6,
                            name: 'Avivah Miller',
                            avatar: 'https://robohash.org/dolorvoluptatemearum.jpg?size=48x48&set=set1',
                            email: 'amiller5@shop-pro.jp'
                        }, {
                            id: 7,
                            name: 'Rebecka Busher',
                            avatar: 'https://robohash.org/ametdelenitieveniet.jpg?size=48x48&set=set1',
                            email: 'rbusher6@mashable.com'
                        }, {
                            id: 8,
                            name: 'Kristel Wastall',
                            avatar: 'https://robohash.org/quibusdamperferendisdistinctio.jpg?size=48x48&set=set1',
                            email: 'kwastall7@disqus.com'
                        }, {
                            id: 9,
                            name: 'Tybalt Polon',
                            avatar: 'https://robohash.org/uteosdoloremque.jpg?size=48x48&set=set1',
                            email: 'tpolon8@ehow.com'
                        }, {
                            id: 10,
                            name: 'Gawen Von Salzberg',
                            avatar: 'https://robohash.org/liberoametalias.jpg?size=48x48&set=set1',
                            email: 'gvon9@shinystat.com'
                        }, {
                            id: 11,
                            name: 'Justus Perroni',
                            avatar: 'https://robohash.org/facilisautvelit.jpg?size=48x48&set=set1',
                            email: 'jperronia@nba.com'
                        }, {
                            id: 12,
                            name: 'Rich Pinson',
                            avatar: 'https://robohash.org/doloremsimiliquequo.jpg?size=48x48&set=set1',
                            email: 'rpinsonb@sciencedaily.com'
                        }, {
                            id: 13,
                            name: 'Wat Indge',
                            avatar: 'https://robohash.org/inciduntnemopraesentium.jpg?size=48x48&set=set1',
                            email: 'windgec@netvibes.com'
                        }, {
                            id: 14,
                            name: 'Wash Noke',
                            avatar: 'https://robohash.org/doloremetcupiditate.jpg?size=48x48&set=set1',
                            email: 'wnoked@cnn.com'
                        }, {
                            id: 15,
                            name: 'Sharla Slatten',
                            avatar: 'https://robohash.org/estdoloremrepellendus.jpg?size=48x48&set=set1',
                            email: 'sslattene@psu.edu'
                        }, {
                            id: 16,
                            name: 'Adele Gori',
                            avatar: 'https://robohash.org/vitaenequeautem.jpg?size=48x48&set=set1',
                            email: 'agorif@utexas.edu'
                        }, {
                            id: 17,
                            name: 'Marillin Seine',
                            avatar: 'https://robohash.org/sapientealiasplaceat.jpg?size=48x48&set=set1',
                            email: 'mseineg@w3.org'
                        }, {
                            id: 18,
                            name: 'Karla Dunkinson',
                            avatar: 'https://robohash.org/saepeetsapiente.jpg?size=48x48&set=set1',
                            email: 'kdunkinsonh@people.com.cn'
                        }, {
                            id: 19,
                            name: 'Jeramey Eskell',
                            avatar: 'https://robohash.org/consequaturlaudantiumomnis.jpg?size=48x48&set=set1',
                            email: 'jeskelli@si.edu'
                        }, {
                            id: 20,
                            name: 'Tobit Geaves',
                            avatar: 'https://robohash.org/cupiditatedoloremillo.jpg?size=48x48&set=set1',
                            email: 'tgeavesj@sina.com.cn'
                        }]
                    },
                    {
                        key: 'itemDefinitions',
                        value: [
                            {
                                key: 'name',
                                path: 'name'
                            },
                            {
                                key: 'email',
                                path: 'email'
                            }
                        ] as Array<IDataListItemDefinition>
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<DataListElement>(DataListElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'source',
                        value: [{
                            id: 1,
                            name: 'Cosme Lucchi',
                            avatar: 'https://robohash.org/perspiciatisexdoloribus.jpg?size=48x48&set=set1',
                            email: 'clucchi0@liveinternet.ru'
                        }, {
                            id: 2,
                            name: 'Kynthia Winckles',
                            avatar: 'https://robohash.org/cumquequiea.jpg?size=48x48&set=set1',
                            email: 'kwinckles1@rambler.ru'
                        }, {
                            id: 3,
                            name: 'Leonelle Maskelyne',
                            avatar: 'https://robohash.org/porrosintut.jpg?size=48x48&set=set1',
                            email: 'lmaskelyne2@fastcompany.com'
                        }, {
                            id: 4,
                            name: 'Arnie Tollemache',
                            avatar: 'https://robohash.org/quasisitin.jpg?size=48x48&set=set1',
                            email: 'atollemache3@vimeo.com'
                        }, {
                            id: 5,
                            name: 'Ricky Egdal',
                            avatar: 'https://robohash.org/quivoluptatemeveniet.jpg?size=48x48&set=set1',
                            email: 'regdal4@cnbc.com'
                        }, {
                            id: 6,
                            name: 'Avivah Miller',
                            avatar: 'https://robohash.org/dolorvoluptatemearum.jpg?size=48x48&set=set1',
                            email: 'amiller5@shop-pro.jp'
                        }, {
                            id: 7,
                            name: 'Rebecka Busher',
                            avatar: 'https://robohash.org/ametdelenitieveniet.jpg?size=48x48&set=set1',
                            email: 'rbusher6@mashable.com'
                        }, {
                            id: 8,
                            name: 'Kristel Wastall',
                            avatar: 'https://robohash.org/quibusdamperferendisdistinctio.jpg?size=48x48&set=set1',
                            email: 'kwastall7@disqus.com'
                        }, {
                            id: 9,
                            name: 'Tybalt Polon',
                            avatar: 'https://robohash.org/uteosdoloremque.jpg?size=48x48&set=set1',
                            email: 'tpolon8@ehow.com'
                        }, {
                            id: 10,
                            name: 'Gawen Von Salzberg',
                            avatar: 'https://robohash.org/liberoametalias.jpg?size=48x48&set=set1',
                            email: 'gvon9@shinystat.com'
                        }, {
                            id: 11,
                            name: 'Justus Perroni',
                            avatar: 'https://robohash.org/facilisautvelit.jpg?size=48x48&set=set1',
                            email: 'jperronia@nba.com'
                        }, {
                            id: 12,
                            name: 'Rich Pinson',
                            avatar: 'https://robohash.org/doloremsimiliquequo.jpg?size=48x48&set=set1',
                            email: 'rpinsonb@sciencedaily.com'
                        }, {
                            id: 13,
                            name: 'Wat Indge',
                            avatar: 'https://robohash.org/inciduntnemopraesentium.jpg?size=48x48&set=set1',
                            email: 'windgec@netvibes.com'
                        }, {
                            id: 14,
                            name: 'Wash Noke',
                            avatar: 'https://robohash.org/doloremetcupiditate.jpg?size=48x48&set=set1',
                            email: 'wnoked@cnn.com'
                        }, {
                            id: 15,
                            name: 'Sharla Slatten',
                            avatar: 'https://robohash.org/estdoloremrepellendus.jpg?size=48x48&set=set1',
                            email: 'sslattene@psu.edu'
                        }, {
                            id: 16,
                            name: 'Adele Gori',
                            avatar: 'https://robohash.org/vitaenequeautem.jpg?size=48x48&set=set1',
                            email: 'agorif@utexas.edu'
                        }, {
                            id: 17,
                            name: 'Marillin Seine',
                            avatar: 'https://robohash.org/sapientealiasplaceat.jpg?size=48x48&set=set1',
                            email: 'mseineg@w3.org'
                        }, {
                            id: 18,
                            name: 'Karla Dunkinson',
                            avatar: 'https://robohash.org/saepeetsapiente.jpg?size=48x48&set=set1',
                            email: 'kdunkinsonh@people.com.cn'
                        }, {
                            id: 19,
                            name: 'Jeramey Eskell',
                            avatar: 'https://robohash.org/consequaturlaudantiumomnis.jpg?size=48x48&set=set1',
                            email: 'jeskelli@si.edu'
                        }, {
                            id: 20,
                            name: 'Tobit Geaves',
                            avatar: 'https://robohash.org/cupiditatedoloremillo.jpg?size=48x48&set=set1',
                            email: 'tgeavesj@sina.com.cn'
                        }]
                    },
                    {
                        key: 'itemDefinitions',
                        value: [
                            {
                                key: 'name',
                                path: 'name',
                                title: 'Name'
                            },
                            {
                                key: 'email',
                                path: 'email',
                                title: 'Email'
                            }
                        ] as Array<IDataListItemDefinition>
                    }

                    // { key: 'label', value: 'Label' },
                    // { key: 'subLabel', value: 'Sub label' },
                    // { key: 'icon', value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z' },
                    // { key: 'variant', value: variant.Primary, },
                    // { key: 'appearance', value: Appearance.Soft },
                    // { key: 'orientation', value: Orientation.Horizontal },
                    // { key: 'size', value: Size.Medium },
                    // { key: 'iconPosition', value: IconPosition.Before },
                    // { key: 'type', value: 'button' },
                    // { key: 'verticalContentAlignment', value: VerticalAlignment.Center },
                    // { key: 'horizontalContentAlignment', value: HorizontalAlignment.Center },
                    // { key: 'fit', value: Fit.None }
                ]), [
                    {
                        key: 'itemDefinitions',
                        template: jsonTemplate
                    },
                    {
                        key: 'source',
                        template: jsonTemplate
                    }
                ] as Array<IPlaygroundProperty<DataListElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                dataListElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-data-list-sample': DataListSampleElement;
    }
}
