// #region Imports

import { Animate, Inset, Orientation, SkeletonShape, type TemplateResult, animate, html, ifDefined, translate, when } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import type { BlogPostView } from './BlogPostView';

// #endregion

/**
 * The template of the {@link BlogPostView}.
 *
 * @public
 */
export function blogPostViewTemplate<T extends BlogPostView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${ifDefined(e.post?.description[e.lang] ?? undefined)}"></title>
            <meta name="description" content="${ifDefined(e.post?.description[e.lang] ?? undefined)}"/>
        </mosaik-helmet>
        <mosaik-page breakpoint="md"
                     ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb .wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                       .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/blog'}"
                                       .label="${translate('loc.nav.blog')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${`/blog/post?id=${e.id}`}"
                                       .label="${e.post?.title[e.lang] ?? e.post?.id ?? '#'}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            ${when(e.isBusy && !e.content, () => html`
            <mosaik-page-content .inset="${[Inset.All]}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'16px'}">
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'50%'}"
                                     .height="${'38px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'50%'}"
                                     .height="${'16px'}"></mosaik-skeleton>
                </mosaik-stack>
            </mosaik-page-content>
            `, () => html`
            <mosaik-page-content .inset="${[Inset.All]}"
                                 ${animate({ ...Animate.slideInBottom })}>
                <app-markdown .text="${e.content}"
                              .breaks="${true}"></app-markdown>
            </mosaik-page-content>
            `)}
        </mosaik-page>
    `;
}
