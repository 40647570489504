//#region Imports

import { globals } from '../../Globals';

//#endregion

export const htmlCode = `
<!-- HTML code for a breakpoint element -->
<!-- The matched event will be called when the breakpoint is matched -->
<mosaik-breakpoint query="xs" matched="this.onMatched"></mosaik-breakpoint>

<script>
    function onMatched(event: IBreakpointMatchedEvent) {
        if(event.matched){
            /* The breakpoint is matched */
        } else{
            /* The breakpoint is not matched */
        }
    }
</script>
`;

export const cssCode = `
/* Styles for screens up to 600px */
@media screen and (max-width: 600px) {
  /* ${globals.name}-like layout for small screens */
  .my-element {
    /* CSS rules for smaller pieces */
  }
}

/* Styles for screens between 601px and 1200px */
@media screen and (min-width: 601px) and (max-width: 1200px) {
    /* ${globals.name}-like layout for medium screens */
    .my-element {
        /* CSS rules for medium-sized pieces */
    }
}

/* Styles for screens above 1200px */
@media screen and (min-width: 1201px) {
    /* ${globals.name}-like layout for large screens */
    .my-element {
        /* CSS rules for larger pieces */
    }
}
`;

export const tsCode = `
const controller = new BreakpointController(this, {
    query: 'xs',
    callback: (match, query) => {
      if (match) {
          /* The breakpoint is matched */
      } else {
          /* The breakpoint is not matched */
      }
  }
});
`;
