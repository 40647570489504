// #region Imports

import { Appearance, Attribute, Component, CustomElement, Emit, Fit, HTMLElementEventEmitter, Orientation, Property, Spacing, TextFormatters, Variant, cache, html, repeat, translate, type IEventEmitter, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { IPlaygroundProperty } from './IPlaygroundProperty';
import { playgroundPropertyGridElementStyle } from './PlaygroundPropertyGridElementStyle';

// #endregion

/**
 * Represents the `{@link IPlaygroundPropertyGridElementEvents}` interface.
 *
 * @public
 */
export interface IPlaygroundPropertyGridElementEvents {

    change: IEventEmitter<{
        key: string;
        value: any;
    }>;

}

/**
 * Represents the `{@link IPlaygroundPropertyGridElementProps}` interface.
 *
 * @public
 */
export interface IPlaygroundPropertyGridElementProps<TElement extends HTMLElement> {

    properties: Array<IPlaygroundProperty<TElement>> | null;

    exludedProperties?: Array<keyof TElement> | null;

}

/**
 * Represents the `PlaygroundPropertyGridElement` class.
 *
 * @fires playgroundPropertyGridChange - Called when any property changes.
 *
 * @public
 */
@Component({
    selector: 'app-playground-property-grid',
    styles: playgroundPropertyGridElementStyle()
})
export class PlaygroundPropertyGridElement<TElement extends HTMLElement = HTMLElement>
    extends CustomElement
    implements IPlaygroundPropertyGridElementProps<TElement> {

    // #region Fields

    private readonly _change: IEventEmitter<{
        property: keyof TElement;
        value: TElement[keyof TElement];
    }>;
    private _errors: Array<{ key: string }>;
    private _filter: string;
    private _properties: Array<IPlaygroundProperty<TElement>> | null;
    private _exludedProperties: Array<keyof TElement> | null;
    private _ofElement: TElement | null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._properties = null;
        this._exludedProperties = null;
        this._filter = '';
        this._errors = [];
        this._ofElement = null;
        this._change = new HTMLElementEventEmitter(this, 'playgroundPropertyGridChange');
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-playground-property-table';
    }

    /**
     * Gets or sets the `ofElement` property.
     *
     * @public
     */
    @Property({ type: Element })
    public get ofElement(): TElement | null {
        return this._ofElement;
    }
    public set ofElement(value: TElement | null) {
        if (this._ofElement !== value) {
            this._ofElement = value;
            this.requestUpdate('ofElement');
        }
    }

    /**
     * Gets or sets the `filter` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get filter(): string {
        return this._filter;
    }
    private set filter(value: string) {
        if (this._filter !== value) {
            this._filter = value;
            this.requestUpdate('filter');
        }
    }

    /**
     * Gets or sets the `property` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get properties(): Array<IPlaygroundProperty<TElement>> | null {
        return this._properties;
    }
    public set properties(value: Array<IPlaygroundProperty<TElement>> | null) {
        if (this._properties !== value) {
            this._properties = value;
            this.requestUpdate('properties');
        }
    }

    /**
     * Gets or sets the `exludedProperties` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get exludedProperties(): Array<keyof TElement> | null {
        return this._exludedProperties;
    }
    public set exludedProperties(value: Array<keyof TElement> | null) {
        if (this._exludedProperties !== value) {
            this._exludedProperties = value;
            this.requestUpdate('exludedProperties');
        }
    }

    /**
     * Gets or sets the `errors` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get errors(): Array<{ key: string }> {
        return this._errors;
    }
    private set errors(value: Array<{ key: string }>) {
        if (this._errors !== value) {
            this._errors = value;
            this.requestUpdate('errors');
        }
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `any` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit()
    public get change(): IEventEmitter<{
        property: keyof TElement;
        value: TElement[keyof TElement];
    }> {
        return this._change;
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-sticky>
                <mosaik-searchbox .mode="${'input'}"
                                  .isClearable="${true}"
                                  .placeholder="${translate('loc.components.properties.search')}"
                                  .variant="${Variant.Primary}"
                                  @searched="${(x: CustomEvent<string>) => this.onFilter(x.detail)}"
                                  @cleared="${(x: CustomEvent<string>) => this.onFilter(null)}"></mosaik-searchbox>
            </mosaik-sticky>
            <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
            <mosaik-stack .orientation="${Orientation.Vertical}"
                          .gap="${'16px'}"
                          .fit="${Fit.Both}">
                ${repeat(this.getProperties(), (x) => html`
                    ${cache(html`
                        ${this.renderCore(x)}
                    `)}
                `)}
            </mosaik-stack>
        `;
    }

    /**
     * @private
     */
    private renderCore(property: IPlaygroundProperty<TElement>): TemplateResult {
        switch (property.type) {
            case Array:
                return html`<p>Array ${property.key}: ${property.type}</p>`;
            case 'object':
            case Object:
                return html`<p>Object ${property.key}: ${property.type}</p>`;
            case 'string':
            case String:
                return property.template
                    ? this.renderCustomField(property)
                    : this.renderStringField(property);
            case 'number':
            case Number:
                return property.template
                    ? this.renderCustomField(property)
                    : this.renderNumberField(property);
            case 'boolean':
            case Boolean:
                return property.template
                    ? this.renderCustomField(property)
                    : this.renderBooleanField(property);
            case 'date':
            case 'Date':
            case Date:
                return property.template
                    ? this.renderCustomField(property)
                    : this.renderDateField(property);
            default:
                return property.template
                    ? this.renderCustomField(property)
                    : this.renderFallbackField(property);
        }
    }

    /**
     * @private
     */
    private renderBooleanField(property: IPlaygroundProperty<TElement>): TemplateResult {
        return html`
            <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                               .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                <mosaik-toggle-switch .isChecked="${property.value}"
                                      .value="${property.value}"
                                      .variant="${Variant.Primary}"
                                      @checked="${() => this.onFieldChange(property.key, true as any)}"
                                      @unchecked="${() => this.onFieldChange(property.key, false as any)}"></mosaik-toggle-switch>
            </mosaik-form-field>
        `;
    }

    /**
     * @private
     */
    private renderStringField(property: IPlaygroundProperty<TElement>): TemplateResult {
        if (property.valuePossibilities) {
            return html`
                <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                                   .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                    <mosaik-select .value="${property.value}"
                                   .variant="${Variant.Primary}"
                                   .isClearable="${true}"
                                   @selectionChanged="${(e: any) => this.onFieldChange(property.key as any, e.target.value)}">
                        ${repeat(property.valuePossibilities.sort((a, b) => this.order(a, b, (x) => x, false)), (_, index) => index, (x) => html`
                        <mosaik-select-item .label="${x.toString()}"
                                            .value="${x}"></mosaik-select-item>
                        `)}
                    </mosaik-select>
                </mosaik-form-field>
            `;
        }

        return html`
            <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                               .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                <mosaik-textbox .value="${property.value}"
                                .variant="${Variant.Primary}"
                                .isClearable="${true}"
                                @input="${(e: any) => this.onFieldChange(property.key as any, e.target.value)}"></mosaik-textbox>
            </mosaik-form-field>
        `;
    }

    /**
     * @private
     */
    private renderNumberField(property: IPlaygroundProperty<TElement>): TemplateResult {
        return html`
            <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                               .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                <mosaik-numberbox .value="${property.value}"
                                  .variant="${Variant.Primary}"
                                  .isClearable="${true}"
                                  @input="${(e: any) => this.onFieldChange(property.key as any, e.target.value)}"></mosaik-numberbox>
            </mosaik-form-field>
        `;
    }

    /**
     * @private
     */
    private renderDateField(property: IPlaygroundProperty<TElement>): TemplateResult {
        return html`
            <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                               .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                <mosaik-datebox .value="${property.value}"
                                .variant="${Variant.Primary}"
                                .isClearable="${true}"
                                @input="${(e: any) => this.onFieldChange(property.key as any, e.target.value)}"></mosaik-datebox>
            </mosaik-form-field>
        `;
    }

    /**
     * @private
     */
    private renderCustomField(property: IPlaygroundProperty<TElement>): TemplateResult {
        if (property.template) {
            return html`
                <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                                   .info="${TextFormatters.RICHTEXT(property.description ?? '')}">
                    ${property.template({
                        element: this.ofElement,
                        value: property.value,
                        apply: (value: any) => this.onFieldChange(property.key, value)
                    })}
                </mosaik-form-field>
            `;
        }

        return html.nothing;
    }

    /**
     * @private
     */
    private renderFallbackField(property: IPlaygroundProperty<TElement>): TemplateResult {
        // this._errors.push({ key: property.key.toString() });
        // this.requestUpdate('errors');

        return html`
            <!-- <mosaik-form-field .label="${this.humanize(property.label ?? property.key.toString())}"
                               .info="${TextFormatters.RICHTEXT(property.description ?? '')}"> -->
            <mosaik-banner .appearance="${Appearance.Outline}"
                           .variant="${Variant.Danger}"
                           .header="${'Something went wrong while parsing this property.'}"
                           .subHeader="${`${property.key.toString()}: ${this.extractType(property.type)}`}"
                           .icon="${'M12 2c5.523 0 10 4.478 10 10s-4.477 10-10 10S2 17.522 2 12 6.477 2 12 2Zm.002 13.004a.999.999 0 1 0 0 1.997.999.999 0 0 0 0-1.997ZM12 7a1 1 0 0 0-.993.884L11 8l.002 5.001.007.117a1 1 0 0 0 1.986 0l.007-.117L13 8l-.007-.117A1 1 0 0 0 12 7Z'}"></mosaik-banner>
            <!-- </mosaik-form-field> -->
        `;
    }

    /**
     * @private
     */
    private onFilter(filter: string | null): void {
        this.filter = filter ?? '';
    }

    /**
     * @private
     */
    private getProperties(): Array<IPlaygroundProperty<TElement>> {
        // filter out excluded properties
        return (this.properties ?? [])
            .filter((x) => !this.exludedProperties?.includes(x.key))
            .filter((x) => this.humanize(x.label ?? x.key.toString()).toLowerCase()
                .includes(this._filter.toLowerCase()))
            .sort((a, b) => this.order(a, b, (x) => x.key, false));
    }

    /**
     * @private
     */
    private onFieldChange(property: keyof TElement, value: TElement[keyof TElement]): void {
        this._change.emit({
            property,
            value
        });
    }

    /**
     * @private
     */
    private order<T>(a: T, b: T, keySelector: (key: T) => any, descending?: boolean): number {
        const sortKeyA = keySelector(a);
        const sortKeyB = keySelector(b);
        if (sortKeyA > sortKeyB) {
            if (!descending) {
                return 1;
            }
            return -1;
        } else if (sortKeyA < sortKeyB) {
            if (!descending) {
                return -1;
            }
            return 1;
        }
        return 0;
    }

    /**
     * @private
     */
    private humanize(value: string): string {
        if (value === '') {
            return value;
        }

        value = value.replace(/([^A-Z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][^A-Z])/g, '$1 $2');
        value = value[0].toUpperCase() + value.slice(1);
        return value;
    }

    /**
     * @private
     */
    private extractType(str: unknown): Array<string> | string {
        if (typeof str !== 'string') {
            return 'Type could not be parsed.';
        }

        const typeRegex = /("|')(.+?)\1/g;
        const matches = str.match(typeRegex);

        if (!matches) {
            return str.trim();
        }

        return Array.from(matches);
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-playground-property-grid': PlaygroundPropertyGridElement;
    }
}
