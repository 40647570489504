// #region Imports

import { Attribute, Component, CustomElement, html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { Package } from 'custom-elements-manifest/schema';
import { apiDocsElementStyle } from './ApiDocsElementStyle';
import { apiDocsElementTemplate } from './ApiDocsElementTemplate';
import { ApiDocsLayoutElement } from './ApiDocsLayoutElement';

// #endregion

/**
 * Represents the `{@link IApiDocsElementProps}` interface.
 *
 * @public
 */
export interface IApiDocsElementProps {

    data: Promise<Package | null>;

}

/**
 * The `{@link ApiDocsElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-api-docs',
    template: apiDocsElementTemplate,
    styles: apiDocsElementStyle,
    imports: [
        ApiDocsLayoutElement
    ]
})
export class ApiDocsElement extends CustomElement implements IApiDocsElementProps {

    // #region Fields

    private _data: Promise<Package | null>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
        this._data = Promise.resolve(null);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-api-docs';
    }

    /**
     * Gets or sets the `data` property.
     *
     * @public
     */
    @Attribute({
        type: Promise<Package>,
        reflect: false
    })
    public get data(): Promise<Package | null> {
        return this._data;
    }

    public set data(value: Promise<Package | null>) {
        if (this._data !== value) {
            this._data = value;
            this.requestUpdate('data');
        }
    }

    // #endregion

}

/**
 * @public
 */
export function createApiDocsElement(data: Promise<Package>): TemplateResult {
    return html`
        <app-api-docs .data="${data}"></app-api-docs>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'app-api-docs': ApiDocsElement;
    }
}
