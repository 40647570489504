// #region Imports

import { Appearance, Component, FlowDirection, PasswordBoxElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { passwordboxElementDocs } from '../../../.Generated/mosaik-passwordbox';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link PasswordBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-passwordbox-sample'
})
export class PasswordBoxSampleElement extends SampleBaseElement<PasswordBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<PasswordBoxElement> {
        return {
            header: 'Password Box',
            description: META.description(PasswordBoxElement.is),
            graph: META.graph(PasswordBoxElement.is),
            playground: {
                template: html`
                    <mosaik-passwordbox></mosaik-passwordbox>
                `,
                properties: META.properties<PasswordBoxElement>(PasswordBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'value',
                        value: 'Value'
                    },
                    {
                        key: 'label',
                        value: 'Password'
                    },
                    {
                        key: 'placeholder',
                        value: 'Enter a strong password'
                    },
                    {
                        key: 'isReveal',
                        value: false
                    }
                ]),
                events: META.events(PasswordBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<PasswordBoxElement>(PasswordBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'value',
                        value: 'Value'
                    },
                    {
                        key: 'label',
                        value: 'Password'
                    },
                    {
                        key: 'placeholder',
                        value: 'Enter a strong password'
                    },
                    {
                        key: 'isReveal',
                        value: false
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: functionTemplate
                    },
                    {
                        key: 'parser',
                        template: functionTemplate
                    }
                ] as unknown as Array<IPlaygroundProperty<PasswordBoxElement>>, (a, b) => a.key === b.key),
                exludedProperties: ['cleared']
            },
            docs: [
                passwordboxElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-passwordbox-sample': PasswordBoxSampleElement;
    }
}
