// #region Imports

import { HtmlCodeGenerator } from './Abstracts/HtmlCodeGenerator';
import type { ICodeModel } from './Interfaces/ICodeGenerator';

// #endregion

/**
 * Generates native HTML.
 */
export class NativeHtmlGenerator extends HtmlCodeGenerator {

    // #region Methods

    public process(model: ICodeModel): string {
        const attributes = this.convertPropertiesToAttributes(model.properties);
        return `<${model.componentPrefix}-${model.componentName} ${attributes}></${model.componentPrefix}-${model.componentName}>`;
    }

    // #endregion

}
