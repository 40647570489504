// #region Imports

import { Component, FlowDirection, TextElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { textElementDocs } from '../../.Generated/mosaik-text';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate, multilineTextTemplate, numberTemplate, valueTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TextSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-text-sample'
})
export class TextSampleElement extends SampleBaseElement<TextElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TextElement> {
        return {
            header: 'Text',
            description: META.description(TextElement.is),
            graph: META.graph(TextElement.is),
            playground: {
                template: html`
                    <mosaik-text></mosaik-text>
                `,
                properties: META.properties2<TextElement>(TextElement.is, {
                    text: 'A *default* Text.',
                    maxLength: 100,
                    maxLines: 2,
                    alignment: 'left',
                    formatter: 'default',
                    marker: null
                })
            },
            propertyGrid: {
                properties: merge(META.properties2<TextElement>(TextElement.is, {
                    text: 'A *default* Text.',
                    dir: FlowDirection.Auto,
                    maxLength: 100,
                    maxLines: 2,
                    alignment: 'left',
                    formatter: 'default',
                    marker: null
                }), [
                    {
                        key: 'text',
                        template: multilineTextTemplate
                    },
                    {
                        key: 'maxLength',
                        template: numberTemplate
                    },
                    {
                        key: 'maxLines',
                        template: numberTemplate
                    },
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    },
                    {
                        key: 'marker',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<TextElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                textElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-text-sample': TextSampleElement;
    }
}
