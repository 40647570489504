
// #region Imports

import { Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IPostResponse } from '../../Backend/Api/Models/IPostResponse';
import { BlogService } from '../../Backend/Api/Services/BlogService';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { blogPostViewStyle } from './BlogPostViewStyle';
import { blogPostViewTemplate } from './BlogPostViewTemplate';

// #endregion

/**
 * The `{@link BlogPostView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-blog-post-view',
    template: blogPostViewTemplate,
    styles: blogPostViewStyle,
    imports: []
})
export class BlogPostView extends ViewBase {

    // #region Fields

    @Inject(BlogService)
    private readonly _blogService!: BlogService;
    private _post: IPostResponse | null = null;
    private _content: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._post = null;
        this._content = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-blog-post-view';
    }

    /**
     * Gets or sets the `post` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get post(): IPostResponse | null {
        return this._post;
    }

    public set post(value: IPostResponse | null) {
        if (this._post !== value) {
            this._post = value;
            this.requestUpdate('post');
        }
    }

    /**
     * Gets or sets the `content` property.
     *
     * @public
     */
    @Property({ type: String })
    public get content(): string {
        return this._content;
    }

    public set content(value: string) {
        if (this._content !== value) {
            this._content = value;
            this.requestUpdate('content');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();
    }

    /**
     * @private
     */
    private initialize(): void {
        const id = this.extractId();

        this.isBusy = true;

        void this._blogService.getPost({ id: id })
            .then((x) => {
                this.post = x;

                return void this._blogService
                    .getPostDetails({
                        id: id,
                        lang: this.lang as Parameters<BlogService['getPostDetails']>[0]['lang']
                    })
                    .then((y) => {
                        this.content = y.content;
                    });
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    /**
     * @private
     */
    private extractId(): string {
        let id: string | null = null;

        // get router query
        if (!globals.myWebHostingIsAStupidIdiot) {
            // IMPORTANT: this only works if the router is configured without hash strategy.
            const params = new URLSearchParams(window.location.search);
            id = params.get('id');
        } else {
            // IMPORTANT: this only works if the router is configured to use the hash strategy.
            const search = window.location.hash.split('?')[1];
            const params = new URLSearchParams(search);
            id = params.get('id');
        }

        return id ?? '';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-blog-post-view': BlogPostView;
    }
}
