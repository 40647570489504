// #region Imports

import { Appearance, CSSResultGroup, Component, FlowDirection, TabElement, TabPanelAlignment, TabStripPlacement, Variant } from '@breadstone/mosaik-elements-foundation';
import { tabElementDocs } from '../../../.Generated/mosaik-tab';
import { tabItemElementDocs } from '../../../.Generated/mosaik-tab-item';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { TabSampleTemplates } from './TabSample.Templates';

// #endregion

/**
 * The `{@link TabSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tab-sample'
})
export class TabSampleElement extends SampleBaseElement<TabElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TabElement> {

        return {
            header: 'Tab',
            description: META.description(TabElement.is),
            graph: META.graph(TabElement.is),
            playground: {
                template: [[{
                    name: 'Default',
                    description: 'A template with text only'
                }, TabSampleTemplates.defaultTemplate()], [{
                    name: 'Single',
                    description: 'A template with a single tab'
                }, TabSampleTemplates.singleTabTemplate()], [{
                    name: 'Icon',
                    description: 'A template with icon only'
                }, TabSampleTemplates.iconTemplate()], [{
                    name: 'Icon and text',
                    description: 'A template with icon and text'
                }, TabSampleTemplates.iconAndTextTemplate()],
                [{
                    name: 'Code',
                    description: 'A manual template that uses code'
                }, TabSampleTemplates.manuelTemplate()]],
                properties: [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'placement',
                        value: TabStripPlacement.Top
                    },
                    {
                        key: 'hasIndicator',
                        value: true
                    },
                    {
                        key: 'alignment',
                        value: TabPanelAlignment.Stretch
                    }
                ]
            },
            propertyGrid: {
                properties: META.properties<TabElement>(TabElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'placement',
                        value: TabStripPlacement.Top
                    },
                    {
                        key: 'hasIndicator',
                        value: true
                    },
                    {
                        key: 'alignment',
                        value: TabPanelAlignment.Stretch
                    }
                ]),
                exludedProperties: ['items']
            },
            docs: [
                tabElementDocs(),
                tabItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-tab-sample': TabSampleElement;
    }
}
