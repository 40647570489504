// #region Imports

import type { ICodeModel } from '../Interfaces/ICodeGenerator';
import type { IHtmlCodeGenerator, IHtmlCodeGeneratorConfig } from '../Interfaces/IHtmlCodeGenerator';
import { CodeGeneratorBase } from './CodeGeneratorBase';

// #endregion

/**
 * The `HtmlCodeGenerator` class.
 *
 * @public
 */
export abstract class HtmlCodeGenerator extends CodeGeneratorBase implements IHtmlCodeGenerator {

    // #region Fields

    private readonly _config: Required<IHtmlCodeGeneratorConfig>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `HtmlCodeGenerator` class.
     *
     * @param config - The optional generator configuration.
     * @public
     */
    public constructor(config?: IHtmlCodeGeneratorConfig) {
        super();

        this._config = {
            indentSize: 4,
            ...config
        };
    }

    // #endregion

    // #region Methods

    /**
     * Generates code for the given component properties.
     *
     * @param model - The model to generate code for.
     * @returns The generated code string.
     */
    public abstract override process(model: ICodeModel): string;

    /**
     * Formats an input HTML string to adhere to specific formatting guidelines, with customizable indent size.
     * This mimics VSCode's `html.format.wrapAttributes=force-aligned` and `html.format.wrapLineLength=1`,
     * which forces attributes to be on new lines and aligns them, allowing for a custom indentation size.
     *
     * @protected
     * @param {string} htmlString The HTML string to be formatted.
     * @param {number} indentSize The number of spaces to use for indentation.
     * @returns {string} The formatted HTML string.
     */
    protected format(htmlString: string): string {
        const tagRegex = /<[^>]+>/g;
        const attributeRegex = /(\w+(-\w+)*)(="[^"]*")?/g;

        return htmlString.replace(tagRegex, (tag) => {
            const tagNameMatch = (/^<\/?[\w-]+/).exec(tag);
            if (!tagNameMatch) {
                return tag;
            } // Return tag if not matching the expected pattern

            const tagName = tagNameMatch[0];
            const attributesMatch = tag.match(attributeRegex);

            if (!attributesMatch || attributesMatch.length <= 1) {
                return tag;
            } // Return original tag if no attributes to format

            const attributesFormatted = attributesMatch.map((attr, index) => {
                if (index === 0) {
                    return `${tagName}\n${' '.repeat(this._config.indentSize)}${attr}`;
                }
                return `${' '.repeat(this._config.indentSize)}${attr}`;
            });

            const closingBracket = tag.endsWith('/>') ? '/>' : '>';
            return `${attributesFormatted.join('\n')}\n${closingBracket}`;
        });
    }

    // #endregion

}
