
//#region Imports

import { Appearance, CheckBoxGroupElement, Component, FlowDirection, Orientation, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { checkboxGroupElementDocs } from '../../../.Generated/mosaik-checkbox-group';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link CheckBoxGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-checkbox-group-sample'
})
export class CheckBoxGroupSampleElement extends SampleBaseElement<CheckBoxGroupElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CheckBoxGroupElement> {
        return {
            header: 'CheckBox Group',
            description: META.description(CheckBoxGroupElement.is),
            graph: META.graph(CheckBoxGroupElement.is),
            playground: {
                template: html`
                    <mosaik-checkbox-group>
                        <mosaik-checkbox .label="${'Dog'}"
                                         .value="${'dog'}"></mosaik-checkbox>
                        <mosaik-checkbox .label="${'Cat'}"
                                         .value="${'cat'}"></mosaik-checkbox>
                        <mosaik-checkbox .label="${'Rabbit'}"
                                         .value="${'rabbit'}"></mosaik-checkbox>
                        <mosaik-checkbox .label="${'Turtle'}"
                                         .value="${'turtle'}"></mosaik-checkbox>
                    </mosaik-checkbox-group>
                `,
                properties: META.properties<CheckBoxGroupElement>(CheckBoxGroupElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'orientation', value: Orientation.Vertical },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'value', value: ['dog', 'turtle'] }
                ]),
                events: META.events(CheckBoxGroupElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<CheckBoxGroupElement>(CheckBoxGroupElement.is, [
                    { key: 'dir', value: FlowDirection.Auto, },
                    { key: 'orientation', value: Orientation.Vertical },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'value', value: ['dog', 'turtle'] }
                ]), [
                    { key: 'value', template: arrayTemplate }
                ] as Array<IPlaygroundProperty<CheckBoxGroupElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                checkboxGroupElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-checkbox-group-sample': CheckBoxGroupSampleElement;
    }
}

