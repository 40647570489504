// #region Imports

import { Appearance, BannerElement, Component, ElevatioWeight, Fit, FlowDirection, Orientation, Size, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import type { CSSResultGroup } from 'lit';
import { bannerElementDocs } from '../../.Generated/mosaik-banner';
import { bannerHeaderElementDocs } from '../../.Generated/mosaik-banner-header';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate, iconSizeTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';
import { BannerSampleTemplates } from './BannerSample.Templates';

// #endregion

/**
 * The `{@link BannerSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-banner-sample'
})
export class BannerSampleElement extends SampleBaseElement<BannerElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<BannerElement> {

        return {
            header: 'Banner',
            description: META.description(BannerElement.is),
            graph: META.graph(BannerElement.is),
            playground: {
                template: [
                    ['default', (props) => BannerSampleTemplates.defaultTemplate(props)],
                    ['with action', (props) => BannerSampleTemplates.withButtonTemplate(props)],
                    ['with custom content', (props) => BannerSampleTemplates.withCustomContentTemplate(props)]
                ],
                properties: META.properties<BannerElement>(BannerElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Info
                    },
                    {
                        key: 'header',
                        value: 'Network connection'
                    },
                    {
                        key: 'subHeader',
                        value: 'We detected that you are currently offline. Do you want to turn the Wi-Fi connection on?'
                    },
                    {
                        key: 'icon',
                        value: 'm12.858 14.273 7.434 7.434a1 1 0 0 0 1.414-1.414l-17.999-18a1 1 0 1 0-1.414 1.414L5.39 6.804c-.643.429-1.254.927-1.821 1.495a12.382 12.382 0 0 0-1.39 1.683 1 1 0 0 0 1.644 1.14c.363-.524.761-1.01 1.16-1.41a9.94 9.94 0 0 1 1.855-1.46L7.99 9.405a8.14 8.14 0 0 0-3.203 3.377 1 1 0 0 0 1.784.903 6.08 6.08 0 0 1 1.133-1.563 6.116 6.116 0 0 1 1.77-1.234l1.407 1.407A5.208 5.208 0 0 0 8.336 13.7a5.25 5.25 0 0 0-1.09 1.612 1 1 0 0 0 1.832.802c.167-.381.394-.722.672-1a3.23 3.23 0 0 1 3.108-.841Zm-1.332-5.93 2.228 2.229a6.1 6.1 0 0 1 2.616 1.55c.444.444.837.995 1.137 1.582a1 1 0 1 0 1.78-.911 8.353 8.353 0 0 0-1.503-2.085 8.108 8.108 0 0 0-6.258-2.365ZM8.51 5.327l1.651 1.651a9.904 9.904 0 0 1 10.016 4.148 1 1 0 1 0 1.646-1.136A11.912 11.912 0 0 0 8.51 5.327Zm4.552 11.114a1.501 1.501 0 1 1-2.123 2.123 1.501 1.501 0 0 1 2.123-2.123Z'
                    },
                    {
                        key: 'elevation',
                        value: ElevatioWeight.None
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'verticalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'horizontalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'iconSize',
                        value: Size.Medium
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<BannerElement>(BannerElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Info
                    },
                    {
                        key: 'header',
                        value: 'Network connection'
                    },
                    {
                        key: 'subHeader',
                        value: 'We detected that you are currently offline. Do you want to turn the Wi-Fi connection on?'
                    },
                    {
                        key: 'icon',
                        value: 'm12.858 14.273 7.434 7.434a1 1 0 0 0 1.414-1.414l-17.999-18a1 1 0 1 0-1.414 1.414L5.39 6.804c-.643.429-1.254.927-1.821 1.495a12.382 12.382 0 0 0-1.39 1.683 1 1 0 0 0 1.644 1.14c.363-.524.761-1.01 1.16-1.41a9.94 9.94 0 0 1 1.855-1.46L7.99 9.405a8.14 8.14 0 0 0-3.203 3.377 1 1 0 0 0 1.784.903 6.08 6.08 0 0 1 1.133-1.563 6.116 6.116 0 0 1 1.77-1.234l1.407 1.407A5.208 5.208 0 0 0 8.336 13.7a5.25 5.25 0 0 0-1.09 1.612 1 1 0 0 0 1.832.802c.167-.381.394-.722.672-1a3.23 3.23 0 0 1 3.108-.841Zm-1.332-5.93 2.228 2.229a6.1 6.1 0 0 1 2.616 1.55c.444.444.837.995 1.137 1.582a1 1 0 1 0 1.78-.911 8.353 8.353 0 0 0-1.503-2.085 8.108 8.108 0 0 0-6.258-2.365ZM8.51 5.327l1.651 1.651a9.904 9.904 0 0 1 10.016 4.148 1 1 0 1 0 1.646-1.136A11.912 11.912 0 0 0 8.51 5.327Zm4.552 11.114a1.501 1.501 0 1 1-2.123 2.123 1.501 1.501 0 0 1 2.123-2.123Z'
                    },
                    {
                        key: 'elevation',
                        value: ElevatioWeight.None
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'verticalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'horizontalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'iconSize',
                        value: Size.Medium
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    }
                ]), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    },
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<BannerElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                bannerElementDocs(),
                bannerHeaderElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-banner-sample': BannerSampleElement;
    }
}
