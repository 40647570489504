// #region Imports

import { Appearance, ButtonType, Component, Fit, FlowDirection, HorizontalAlignment, IconPosition, Orientation, RepeatButtonElement, Size, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { repeatButtonElementDocs } from '../../../.Generated/mosaik-repeat-button';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { iconSizeTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { RepeatButtonSampleTemplates } from './RepeatButtonSample.Templates';

// #endregion

/**
 * The `{@link RepeatButtonSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-repeat-button-sample'
})
export class RepeatButtonSampleElement extends SampleBaseElement<RepeatButtonElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<RepeatButtonElement> {
        return {
            header: 'Repeat Button',
            description: META.description(RepeatButtonElement.is),
            graph: META.graph(RepeatButtonElement.is),
            playground: {
                template: [
                    RepeatButtonSampleTemplates.defaultTemplate(),
                    RepeatButtonSampleTemplates.withBadgeTemplate()
                ],
                properties: META.properties<RepeatButtonElement>(RepeatButtonElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'icon',
                        value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'delay',
                        value: 500
                    },
                    {
                        key: 'interval',
                        value: 330
                    }
                ]),
                events: META.events(RepeatButtonElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<RepeatButtonElement>(RepeatButtonElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    {
                        key: 'horizontalContentAlignment',
                        value: HorizontalAlignment.Center
                    },
                    {
                        key: 'icon',
                        value: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'
                    },
                    {
                        key: 'iconPosition',
                        value: IconPosition.Before
                    },
                    {
                        key: 'iconSize',
                        value: Size.Medium
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'type',
                        value: ButtonType.Button
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'verticalContentAlignment',
                        value: VerticalAlignment.Center
                    },
                    {
                        key: 'delay',
                        value: 500
                    },
                    {
                        key: 'interval',
                        value: 330
                    }
                ]), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<RepeatButtonElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                repeatButtonElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-repeat-button-sample': RepeatButtonSampleElement;
    }
}
