// #region Imports

import { Animate, Appearance, HorizontalAlignment, Inset, Orientation, Placement, Size, Spacing, Variant, animate, colorBoxElementValueAccessor, html, live, patternElementValidator, repeat, requiredElementValidator, translate, when, type ColorBoxElement, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { ThemeGeneratorView } from './ThemeGeneratorView';

// #endregion

/**
 * The template of the {@link ThemeGeneratorElement}.
 *
 * @public
 */
export function themeGeneratorViewTemplate<T extends ThemeGeneratorView>(e: T): TemplateResult {
    return html`
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                       .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/tools'}"
                                       .label="${translate('loc.nav.tools')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/themeGenerator'}"
                                       .label="${translate('loc.nav.themeGenerator')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-header .inset="${Inset.Horizontal}"
                                .text="${translate('loc.nav.themeGenerator')}"></mosaik-page-header>
            <mosaik-page-pre-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-form-field .validators="${[requiredElementValidator(), patternElementValidator()]}"
                                   .accessor="${colorBoxElementValueAccessor}">
                    <mosaik-colorbox .value="${live(e.presenter.color.get())}"
                                     .required="${true}"
                                     .placeholder="${translate('Enter a hex color...')}"
                                     @input="${(x: InputEvent) => e.presenter.changeColorCommand.execute((x.target as ColorBoxElement).value)}"></mosaik-colorbox>
                </mosaik-form-field>
            </mosaik-page-pre-content>
            <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-tab .appearance="${Appearance.Plain}"
                            .variant="${Variant.Primary}">
                    <mosaik-tab-item .label="${translate('loc.global.colors')}">
                        <mosaik-spacer thickness="${Spacing.All}" size="${Size.Large}"></mosaik-spacer>
                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                      .gap="${'32px'}">
                        <mosaik-cell-group .header="${translate('loc.global.scheme')}">
                            ${repeat(e.presenter.schemeKeys.get(), (key) => key, (key, i) => html`
                            <mosaik-cell .label="${key}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${e.presenter.schemeValues.get()[i]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${e.presenter.schemeValues.get()[i]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(e.presenter.schemeValues.get()[i])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < e.presenter.schemeKeys.get().length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        <mosaik-cell-group .header="${translate('loc.global.functional')}">
                            ${repeat(e.presenter.functionalKeys.get(), (key) => key, (key, i) => html`
                            <mosaik-cell .label="${key}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${e.presenter.functionalValues.get()[i]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${e.presenter.functionalValues.get()[i]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(e.presenter.functionalValues.get()[i])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < e.presenter.functionalKeys.get().length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        <mosaik-cell-group .header="${translate('loc.global.accent')}">
                            ${repeat(e.presenter.accentKeys.get(), (key) => key, (key, i) => html`
                            <mosaik-cell .label="${key}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${e.presenter.accentValues.get()[i]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${e.presenter.accentValues.get()[i]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(e.presenter.accentValues.get()[i])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < e.presenter.accentKeys.get().length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        </mosaik-stack>
                        <mosaik-spacer thickness="${Spacing.All}" size="${Size.Tiny}">
                        <mosaik-stack .orientation="${Orientation.Horizontal}"
                                      .horizontalAlignment="${HorizontalAlignment.Right}">
                            <mosaik-button .label="${translate('loc.global.generate')}"
                                           .icon="${'M8.664 15.735c.245.173.537.265.836.264v-.004a1.442 1.442 0 0 0 1.327-.872l.613-1.864a2.872 2.872 0 0 1 1.817-1.812l1.778-.578a1.443 1.443 0 0 0-.052-2.74l-1.755-.57a2.876 2.876 0 0 1-1.822-1.823l-.578-1.777a1.446 1.446 0 0 0-2.732.022l-.583 1.792a2.877 2.877 0 0 1-1.77 1.786l-1.777.571a1.444 1.444 0 0 0 .017 2.734l1.754.569a2.887 2.887 0 0 1 1.822 1.826l.578 1.775c.099.283.283.528.527.7Zm7.667 5.047a1.123 1.123 0 0 1-.41-.549l-.328-1.007a1.293 1.293 0 0 0-.821-.823l-.991-.323A1.148 1.148 0 0 1 13 16.997a1.143 1.143 0 0 1 .771-1.08l1.006-.326a1.3 1.3 0 0 0 .8-.819l.324-.992a1.143 1.143 0 0 1 2.157-.021l.329 1.014a1.3 1.3 0 0 0 .82.816l.992.323a1.141 1.141 0 0 1 .039 2.165l-1.014.329a1.3 1.3 0 0 0-.818.822l-.322.989c-.078.23-.226.43-.425.57a1.14 1.14 0 0 1-1.328-.005Z'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Primary}"
                                           @click="${() => e.presenter.generateThemeCommand.execute()}"></mosaik-button>
                        </mosaik-stack>
                        </mosaik-spacer>
                    </mosaik-tab-item>
                    <mosaik-tab-item .label="${translate('loc.global.variations')}">
                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                      .gap="${'32px'}">
                        <mosaik-spacer thickness="${Spacing.Top}" size="${Size.Tiny}"></mosaik-spacer>
                        <mosaik-cell-group .header="${translate('loc.global.complementary')}">
                            ${repeat(Object.entries(e.presenter.complementaryPalette.get()), (kv) => kv[0], (kv, i) => html`
                            <mosaik-cell .label="${kv[0]}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${kv[1]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${kv[1]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(kv[1])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < Object.entries(e.presenter.complementaryPalette.get()).length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        <mosaik-cell-group .header="${translate('loc.global.analogous')}">
                            ${repeat(Object.entries(e.presenter.analogous1Palette.get()), (kv) => kv[0], (kv, i) => html`
                            <mosaik-cell .label="${kv[0]}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${kv[1]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${kv[1]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(kv[1])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                    <mosaik-tooltip .content="${Object.entries(e.presenter.analogous2Palette.get())[i][1]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${Object.entries(e.presenter.analogous2Palette.get())[i][1]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(Object.entries(e.presenter.analogous2Palette.get())[i][1])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < Object.entries(e.presenter.analogous1Palette.get()).length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        <mosaik-cell-group .header="${translate('loc.global.triadic')}">
                            ${repeat(Object.entries(e.presenter.triadic1Palette.get()), (kv) => kv[0], (kv, i) => html`
                            <mosaik-cell .label="${kv[0]}">
                                <mosaik-stack slot="end"
                                              .orientation="${Orientation.Horizontal}">
                                    <mosaik-tooltip .content="${kv[1]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${kv[1]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(kv[1])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                    <mosaik-tooltip .content="${Object.entries(e.presenter.triadic2Palette.get())[i][1]}"
                                                    .placement="${Placement.Left}">
                                        <mosaik-color-swatch .hasBackground="${true}"
                                                             .value="${Object.entries(e.presenter.triadic2Palette.get())[i][1]}"
                                                             @click="${() => e.presenter.copyColorCommand.execute(Object.entries(e.presenter.triadic2Palette.get())[i][1])}"></mosaik-color-swatch>
                                    </mosaik-tooltip>
                                </mosaik-stack>
                            </mosaik-cell>
                            ${when(i < Object.entries(e.presenter.triadic1Palette.get()).length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                        </mosaik-stack>
                    </mosaik-tab-item>
                </mosaik-tab>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
