// #region Imports

import { Appearance, CSSResultGroup, Component, FlowDirection, TabStripElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { TabStripPlacement } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/TabStripPlacement';
import { tabStripElementDocs } from '../../../.Generated/mosaik-tab-strip';
import { tabStripItemElementDocs } from '../../../.Generated/mosaik-tab-strip-item';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { TabStripSampleTemplates } from './TabStripSample.Templates';

// #endregion

/**
 * The `{@link TabStripSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tab-strip-sample'
})
export class TabStripSampleElement extends SampleBaseElement<TabStripElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TabStripElement> {

        return {
            header: 'TabStrip',
            description: META.description(TabStripElement.is),
            graph: META.graph(TabStripElement.is),
            playground: {
                template: [
                    [{
                        name: 'Default',
                        description: 'A template with text only'
                    }, TabStripSampleTemplates.defaultTemplate()
                    ],
                    [{
                        name: 'Icon',
                        description: 'A template with icon only'
                    }, TabStripSampleTemplates.iconTemplate()
                    ],
                    [{
                        name: 'Icon and text',
                        description: 'A template with icon and text'
                    }, TabStripSampleTemplates.iconAndTextTemplate()
                    ],
                    [{
                        name: 'Code',
                        description: 'A manual template that uses code'
                    }, TabStripSampleTemplates.manuelTemplate()]
                ],
                properties: [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'placement',
                        value: TabStripPlacement.Top
                    },
                    {
                        key: 'hasIndicator',
                        value: true
                    }
                ]
            },
            propertyGrid: {
                properties: META.properties<TabStripElement>(TabStripElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'placement',
                        value: TabStripPlacement.Top
                    },
                    {
                        key: 'hasIndicator',
                        value: true
                    }
                ])
            },
            docs: [
                tabStripElementDocs(),
                tabStripItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-tab-strip-sample': TabStripSampleElement;
    }
}
