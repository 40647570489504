// #region Imports

import { Animate, Component, Inset, Orientation, TextFormatters, animate, css, html, translate, type CSSResultGroup, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

/**
 * The `{@link IntroductionView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-introduction-view'
})
export class IntroductionView extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-introduction-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.introduction')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/introduction'}" .label="${translate('loc.nav.introduction')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-header .text="${translate('loc.nav.introduction')}"
                                .inset="${Inset.Horizontal}"></mosaik-page-header>
            <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-stack orientation="${Orientation.Vertical}"
                            gap="32px">
                    <mosaik-text .formatter="${TextFormatters.RICHTEXT}"
                                .text="${translate('loc.introduction.1.text', globals.name)}"
                                ?wrap="${true}"></mosaik-text>
                    <mosaik-text .text="${translate('loc.introduction.2.text', globals.name)}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                ?wrap="${true}"></mosaik-text>
                    <mosaik-text .text="${translate('loc.introduction.3.text', globals.name)}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                ?wrap="${true}"></mosaik-text>
                    <mosaik-text .text="${translate('loc.introduction.4.text', globals.name)}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                ?wrap="${true}"></mosaik-text>
                    <mosaik-text .text="${translate('loc.introduction.5.text', globals.name)}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                ?wrap="${true}"></mosaik-text>
                </mosaik-stack>
            </mosaik-page-content>
        </mosaik-page>
        `;
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-introduction-view': IntroductionView;
    }
}
