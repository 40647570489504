// #region Imports

import { Appearance, Component, FlowDirection, InputState, RichTextBoxElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { richtextboxElementDocs } from '../../../.Generated/mosaik-richtextbox';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate, numberTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { COMPONENTS } from '../../../Services/ComponentsService';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link √TextBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-richtextbox-sample'
})
export class RichTextBoxSampleElement extends SampleBaseElement<RichTextBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<RichTextBoxElement> {
        return {
            header: 'Rich Text Box',
            description: META.description(RichTextBoxElement.is),
            experimental: COMPONENTS.getComponent(RichTextBoxElement.is)?.experimental,
            graph: META.graph(RichTextBoxElement.is),
            tags: ['input'],
            playground: {
                template: html`
                    <mosaik-richtextbox intl></mosaik-richtextbox>
                `,
                properties: META.properties<RichTextBoxElement>(RichTextBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'value',
                        value: 'Value'
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'state',
                        value: InputState.Initial
                    },
                    {
                        key: 'multilineRows',
                        value: 3
                    },
                    {
                        key: 'showToolbar',
                        value: true
                    }
                ]),
                events: META.events(RichTextBoxElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<RichTextBoxElement>(RichTextBoxElement.is, [
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'value',
                        value: 'Value'
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'state',
                        value: InputState.Initial
                    },
                    {
                        key: 'multilineRows',
                        value: 3
                    },
                    {
                        key: 'showToolbar',
                        value: true
                    }
                ]), [
                    {
                        key: 'maxlength',
                        template: numberTemplate
                    },
                    {
                        key: 'minlength',
                        template: numberTemplate
                    },
                    {
                        key: 'formatter',
                        template: functionTemplate
                    },
                    {
                        key: 'parser',
                        template: functionTemplate
                    }
                ] as Array<IPlaygroundProperty<RichTextBoxElement>>, (a, b) => a.key === b.key), [
                    { key: 'intl' }
                ] as Array<IPlaygroundProperty<RichTextBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                richtextboxElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-richtextbox-sample': RichTextBoxSampleElement;
    }
}
