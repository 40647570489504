// #region Imports

import { Appearance, AutoFlow, BadgePosition, ButtonType, CalendarView, CameraCaptureFormat, CameraRecorderFormat, CameraType, ChartDisplayType, ChartLegendPosition, ChartStackType, ChatBegin, DayOfWeek, DrawerMode, DrawerPosition, ElevatioWeight, ExpandDirection, ExpansionMode, FabGroupDirection, FileUploadView, Fit, FlowDirection, HorizontalAlignment, HyperlinkUnderline, IconPosition, ImageFit, ImageLegendPosition, Injectable, InputState, LabelPosition, MessageBoxButtons, MeterRange, NumberKeyboardMode, Orientation, PersonaPresence, PinMode, Placement, QRErrorCorrection, RangeSelectionMode, ResizeMode, ScaleMode, SearchTriggerMode, SelectionMode, Size, SkeletonShape, SpinDirection, SplitLock, Strategy, TabPanelAlignment, TabStripPlacement, TextAlignment, TextBoxType, TextKeyboardMode, TickLabelPosition, TickPlacement, ToastPosition, Trigger, UpDownSpinPosition, Variant, VerticalAlignment, VideoFit, VideoLegendPosition } from '@breadstone/mosaik-elements-foundation';
import { AutoToolTipPlacement } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/AutoToolTipPlacement';
import { CaretPosition } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/CaretPosition';
import type { IPlaygroundProperty } from '../Components/Playground/IPlaygroundProperty';
import * as meta from '../Resources/Data/Meta.g.json';

// #endregion

interface IMeta {
    modules: Array<{
        exports: Array<{
            name: string;
            kind: string;
            description: string;
            preview: boolean;
            tagName: string;
            members: Array<{
                kind: string;
                privacy: string;
                name: string;
                type: string;
                description: string;
            }>;
            mixins: Array<{
                name: string;
            }>;
            superclass: {
                name: string;
            };
            events: Array<{
                name: string;
                description: string;
            }>;
        }>;
    }>;
}

/**
 * The `MetaService` class.
 *
 * @public
 */
@Injectable()
export class MetaService {

    // #region Fields

    private readonly _enums: Map<string, unknown>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `MetaService` class.
     *
     * @public
     */
    public constructor() {
        this._enums = new Map();

        this._enums.set('orientation', [Orientation.Horizontal, Orientation.Vertical]);
        this._enums.set('size', [Size.Tiny, Size.Small, Size.Medium, Size.Large, Size.Giant]);
        this._enums.set('variant', [Variant.Default, Variant.Primary, Variant.Secondary, Variant.Tertiary, Variant.Danger, Variant.Warning, Variant.Success, Variant.Info, Variant.Gray, Variant.Highlight]);
        this._enums.set('appearance', [Appearance.Default, Appearance.Outline, Appearance.Plain, Appearance.Soft, Appearance.Solid]);
        this._enums.set('expanddirection', [ExpandDirection.Down, ExpandDirection.Left, ExpandDirection.Right, ExpandDirection.Up]);
        this._enums.set('inset', ['top', 'right', 'bottom', 'left', 'vertical', 'horizontal', 'all', 'none']);
        this._enums.set('textalignment', [TextAlignment.Left, TextAlignment.Center, TextAlignment.Justify, TextAlignment.Right]);
        this._enums.set('iconposition', [IconPosition.Before, IconPosition.After]);
        this._enums.set('labelposition', [LabelPosition.Before, LabelPosition.After]);
        this._enums.set('type', ['button', 'submit', 'reset']);
        this._enums.set('placement', [Placement.Bottom, Placement.BottomEnd, Placement.BottomStart, Placement.Left, Placement.LeftEnd, Placement.LeftStart, Placement.Right, Placement.RightEnd, Placement.RightStart, Placement.Top, Placement.TopEnd, Placement.TopStart]);
        this._enums.set('verticalalignment', [VerticalAlignment.Top, VerticalAlignment.Center, VerticalAlignment.Bottom, VerticalAlignment.Stretch]);
        this._enums.set('verticalcontentalignment', [VerticalAlignment.Top, VerticalAlignment.Center, VerticalAlignment.Bottom, VerticalAlignment.Stretch]);
        this._enums.set('horizontalalignment', [HorizontalAlignment.Left, HorizontalAlignment.Center, HorizontalAlignment.Right, HorizontalAlignment.Stretch]);
        this._enums.set('horizontalcontentalignment', [HorizontalAlignment.Left, HorizontalAlignment.Center, HorizontalAlignment.Right, HorizontalAlignment.Stretch]);
        this._enums.set('fit', [Fit.None, Fit.Width, Fit.Height, Fit.Both]);
        this._enums.set('drawerposition', [DrawerPosition.Left, DrawerPosition.Top, DrawerPosition.Right, DrawerPosition.Bottom]);
        this._enums.set('toastposition', [ToastPosition.Bottom, ToastPosition.Middle, ToastPosition.Top]);
        this._enums.set('drawermode', [DrawerMode.Over, DrawerMode.Push, DrawerMode.Side]);
        this._enums.set('videolegendposition', [VideoLegendPosition.None, VideoLegendPosition.Bottom, VideoLegendPosition.Top]);
        this._enums.set('imagelegendposition', [ImageLegendPosition.None, ImageLegendPosition.Bottom, ImageLegendPosition.Top]);
        this._enums.set('flowdirection', [FlowDirection.Auto, FlowDirection.LeftToRight, FlowDirection.RightToLeft]);
        this._enums.set('buttontype', [ButtonType.Button, ButtonType.Reset, ButtonType.Submit]);
        this._enums.set('personapresence', [PersonaPresence.Away, PersonaPresence.Blocked, PersonaPresence.Busy, PersonaPresence.None, PersonaPresence.Offline, PersonaPresence.Online]);
        this._enums.set('keyboard', [TextKeyboardMode.Email, TextKeyboardMode.None, TextKeyboardMode.Tel, TextKeyboardMode.Text, TextKeyboardMode.Url]);
        this._enums.set('textboxtype', [TextBoxType.Email, TextBoxType.Telephone, TextBoxType.Text, TextBoxType.Url]);
        this._enums.set('selectionmode', [SelectionMode.None, SelectionMode.Single, SelectionMode.Multiple]);
        this._enums.set('rangeselectionmode', [RangeSelectionMode.Range, RangeSelectionMode.None, RangeSelectionMode.Single, RangeSelectionMode.Multiple]);
        this._enums.set('updownspinposition', [UpDownSpinPosition.After, UpDownSpinPosition.Around, UpDownSpinPosition.Before]);
        this._enums.set('spindirection', [SpinDirection.Decrease, SpinDirection.Increase]);
        this._enums.set('calendarview', [CalendarView.Day, CalendarView.Month, CalendarView.Year]);
        this._enums.set('dayofweek', [DayOfWeek.Friday, DayOfWeek.Monday, DayOfWeek.Saturday, DayOfWeek.Sunday, DayOfWeek.Thursday, DayOfWeek.Tuesday, DayOfWeek.Wednesday]);
        this._enums.set('expansionmode', [ExpansionMode.Multiple, ExpansionMode.Single]);
        this._enums.set('strategy', [Strategy.Absolute, Strategy.Fixed]);
        this._enums.set('inputstate', [InputState.Initial, InputState.Invalid, InputState.Valid]);
        this._enums.set('trigger', [Trigger.Click, Trigger.Hover, Trigger.Focus, Trigger.Manual]);
        this._enums.set('pinmode', [PinMode.Password, PinMode.Text]);
        this._enums.set('chartlegendposition', [ChartLegendPosition.Top, ChartLegendPosition.Right, ChartLegendPosition.Bottom, ChartLegendPosition.Left]);
        this._enums.set('chartdisplaytype', [ChartDisplayType.Area, ChartDisplayType.Bar, ChartDisplayType.BoxPlot, ChartDisplayType.Bubble, ChartDisplayType.Candlestick, ChartDisplayType.Donut, ChartDisplayType.HeatMap, ChartDisplayType.Line, ChartDisplayType.Pie, ChartDisplayType.PolarArea, ChartDisplayType.Radar, ChartDisplayType.RadialBar, ChartDisplayType.RangeArea, ChartDisplayType.RangeBar, ChartDisplayType.Scatter, ChartDisplayType.TreeMap]);
        this._enums.set('chartstacktype', [ChartStackType.Normal, ChartStackType.Fill]);
        this._enums.set('elevatioweight', [ElevatioWeight.None, ElevatioWeight.Light, ElevatioWeight.Regular, ElevatioWeight.SemiLight, ElevatioWeight.Bold, ElevatioWeight.SemiBold, ElevatioWeight.ExtraBold]);
        this._enums.set('textkeyboardmode', [TextKeyboardMode.Email, TextKeyboardMode.None, TextKeyboardMode.Tel, TextKeyboardMode.Text, TextKeyboardMode.Url]);
        this._enums.set('numberkeyboardmode', [NumberKeyboardMode.Decimal, NumberKeyboardMode.Numeric, NumberKeyboardMode.Tel]);
        this._enums.set('searchtriggermode', [SearchTriggerMode.Input, SearchTriggerMode.Delay, SearchTriggerMode.Enter]);
        this._enums.set('fileuploadview', [FileUploadView.List, FileUploadView.Card, FileUploadView.Table, FileUploadView.Chip]);
        this._enums.set('splitlock', [SplitLock.None, SplitLock.Start, SplitLock.End]);
        this._enums.set('videofit', [VideoFit.Contain, VideoFit.Cover, VideoFit.None, VideoFit.Fill, VideoFit.ScaleDown]);
        this._enums.set('imagefit', [ImageFit.Contain, ImageFit.Cover, ImageFit.None, ImageFit.Fill, ImageFit.ScaleDown]);
        this._enums.set('fabgroupdirection', [FabGroupDirection.Top, FabGroupDirection.Bottom, FabGroupDirection.Left, FabGroupDirection.Right]);
        this._enums.set('badgeposition', [BadgePosition.None, BadgePosition.TopLeft, BadgePosition.TopRight, BadgePosition.BottomLeft, BadgePosition.BottomRight]);
        this._enums.set('hyperlinkunderline', [HyperlinkUnderline.Never, HyperlinkUnderline.Always, HyperlinkUnderline.Hover]);
        this._enums.set('skeletonshape', [SkeletonShape.circle, SkeletonShape.rect]);
        this._enums.set('resizemode', [ResizeMode.Both, ResizeMode.Horizontal, ResizeMode.Vertical, ResizeMode.None]);
        this._enums.set('tabpanelalignment', [TabPanelAlignment.Center, TabPanelAlignment.Start, TabPanelAlignment.End, TabPanelAlignment.Stretch]);
        this._enums.set('tabstripplacement', [TabStripPlacement.Bottom, TabStripPlacement.Left, TabStripPlacement.Right, TabStripPlacement.Top]);
        this._enums.set('chatbegin', [ChatBegin.Start, ChatBegin.End]);
        this._enums.set('meterrange', [MeterRange.High, MeterRange.Low, MeterRange.Normal, MeterRange.Optimum]);
        this._enums.set('messageboxbuttons', [MessageBoxButtons.OK, MessageBoxButtons.OKCancel, MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel]);
        this._enums.set('autoflow', [AutoFlow.Column, AutoFlow.ColumnDense, AutoFlow.Row, AutoFlow.RowDense, AutoFlow.None]);
        this._enums.set('tickplacement', [TickPlacement.After, TickPlacement.Before, TickPlacement.Both, TickPlacement.None]);
        this._enums.set('autotooltipplacement', [AutoToolTipPlacement.After, AutoToolTipPlacement.Before, AutoToolTipPlacement.None]);
        this._enums.set('ticklabelposition', [TickLabelPosition.After, TickLabelPosition.Before]);
        this._enums.set('qrerrorcorrection', [QRErrorCorrection.H, QRErrorCorrection.L, QRErrorCorrection.M, QRErrorCorrection.Q]);
        this._enums.set('caretposition', [CaretPosition.After, CaretPosition.Before]);
        this._enums.set('scalemode', [ScaleMode.Grow, ScaleMode.Shrink]);
        this._enums.set('cameracaptureformat', [CameraCaptureFormat.Jpeg, CameraCaptureFormat.Png]);
        this._enums.set('camerarecorderformat', [CameraRecorderFormat.Mp4, CameraRecorderFormat.Webm]);
        this._enums.set('cameratype', [CameraType.Front, CameraType.Back]);
    }

    // #endregion

    // #region Methods

    /**
     * Returns the description of the elements tag name.
     *
     * @param name The name (tag name e.g 'mosaik-button') of the component.
     */
    public description(name: string): string {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        return (root as any).description;
    }

    /**
     * Returns the preview flag of the elements tag name.
     *
     * @param name The name (tag name e.g 'mosaik-button') of the component.
     */
    public preview(name: string): boolean {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        return (root as any).preview;
    }

    /**
     * Returns the properties of the elements tag name.
     *
     * @public
     * @deprecated Use `properties2` instead.
     * @param name The name (tag name e.g 'mosaik-button') of the component.
     */
    public properties<TElement extends HTMLElement>(name: string, overrides?: Array<{
        key: keyof TElement;
        value: TElement[keyof TElement];
    }>): Array<IPlaygroundProperty<TElement>> {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        const properties = (root as any).members?.filter((m: any) => m.kind === 'field' && m.privacy === 'public' && !(root as any).events.map((x: any) => x.name).includes(m.name)) ?? [];

        const props = properties.map((x: any) => x).map((x: any) => {
            if (x.type && this.isPrimitive(x.type)) {
                return {
                    key: x.name ?? '',
                    type: x.type ?? '',
                    value: overrides?.find((y) => y.key === x.name)?.value ?? this.primitiveDefaultValue(x.type),
                    description: x.description
                };
            }

            if (x.type && this.isPossible(x.type)) {
                const valuePossibilities = this._enums.get(x.type.toLowerCase());
                const isValuePossibilities = Array.isArray(valuePossibilities);

                return {
                    key: x.name ?? '',
                    type: isValuePossibilities ? 'string' : valuePossibilities,
                    value: overrides?.find((y) => y.key === x.name)?.value,
                    valuePossibilities: isValuePossibilities ? valuePossibilities : undefined,
                    description: x.description
                };
            }

            return {
                key: x.name,
                type: x.type,
                value: overrides?.find((y) => y.key === x.name)?.value
            };
        }) as unknown as Array<IPlaygroundProperty<TElement>>;

        return props;
    }

    public properties2<TElement extends HTMLElement>(name: string, overrides?: Partial<Record<keyof TElement, TElement[keyof TElement]>>): Array<IPlaygroundProperty<TElement>> {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        const properties = (root as any).members?.filter((m: any) => m.kind === 'field' && m.privacy === 'public' && !(root as any).events.map((x: any) => x.name).includes(m.name)) ?? [];

        const props = properties.map((x: any) => x).map((x: any) => {
            if (x.type && this.isPrimitive(x.type)) {
                return {
                    key: x.name ?? '',
                    type: x.type ?? '',
                    value: overrides[x.name] ?? this.primitiveDefaultValue(x.type),
                    description: x.description
                };
            }

            if (x.type && this.isPossible(x.type)) {
                const valuePossibilities = this._enums.get(x.type.toLowerCase());
                const isValuePossibilities = Array.isArray(valuePossibilities);

                return {
                    key: x.name ?? '',
                    type: isValuePossibilities ? 'string' : valuePossibilities,
                    value: overrides[x.name],
                    valuePossibilities: isValuePossibilities ? valuePossibilities : undefined,
                    description: x.description
                };
            }

            return {
                key: x.name,
                type: x.type,
                value: overrides[x.name]
            };
        }) as unknown as Array<IPlaygroundProperty<TElement>>;

        return props;
    }

    /**
     * Returns the events of the elements tag name.
     *
     * @param name The name (tag name e.g 'mosaik-button') of the component.
     */
    public events<TElement extends HTMLElement>(name: string): Array<{
        name: string;
        description: string;
    }> {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        const events = (root as any).events ?? [];
        return events;
    }

    /**
     * Returns the mixins and superclass of the elements tag name.
     *
     * @param name The name (tag name e.g 'mosaik-button') of the component.
     */
    public graph<TElement extends HTMLElement>(name: string): {
        mixinClasses: Array<string>;
        baseClass: string;
        class: string;
    } {
        const root = (meta as IMeta).modules.find((x) => x.exports.find((d: any) => d.kind === 'class' && d.tagName === name))?.exports.find((d: any) => d.kind === 'class');
        const mixins = (root as any).mixins ?? [];
        const base = (root as any).superclass ?? '';

        return {
            class: root?.name ?? '',
            baseClass: base.name,
            mixinClasses: mixins.map((x) => x.name)
        };
    }

    /**
     * Returns the count of the components.
     */
    public count(): number {
        const num = (meta as IMeta).modules.length;
        return Math.floor(num / 10) * 10;
    }

    /**
     * Checks if the given type is a primitive.
     *
     * @private
     */
    private isPrimitive(type: string): boolean {
        return type === 'string' || type === 'number' || type === 'boolean';
    }

    /**
     * Checks if the given type is possible.
     * True in this case means that the type has a list of options and this options will be resolves from the defaults list.
     *
     * @private
     */
    private isPossible(type: string): boolean {
        const has = this._enums.has(type.toLowerCase());

        if (!has) {
            console.info(`### MOSAIK: type ${type.toLowerCase()} has no option list. Values in this list are`, this._enums.keys());
        }

        return has;
    }

    /**
     * Returns the default value for the given type.
     *
     * @private
     */
    private primitiveDefaultValue(type: string): string | number | boolean | undefined {
        if (type === 'string') {
            return '';
        }

        if (type === 'number') {
            return 0;
        }

        if (type === 'boolean') {
            return false;
        }

        return undefined;
    }

    // #endregion

}

export const META = new MetaService();
