// #region Imports

import { Injectable } from '@breadstone/mosaik-elements-foundation';

// #endregion

/**
 * Service to handle downloading of calendar appointments.
 */
@Injectable()
export class AppointmentService {

    /**
     * Triggers a download of an iCalendar file with the given event details, supporting whole-day events.
     * @param title The title of the event.
     * @param description The description of the event.
     * @param startTime The start time of the event as a Date object, if available.
     * @param endTime The end time of the event as a Date object, if available.
     */
    public create(title: string, description: string, startTime?: Date, endTime?: Date): void {
        const icsData = this.generateICalendarData(title, description, startTime, endTime);
        const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
        this.save(blob, `${title}.ics`, 'text/calendar;charset=utf-8');
    }

    /**
     * Generates an iCalendar data string for the given event details, handling whole-day events.
     * @param title The title of the event.
     * @param description The description of the event.
     * @param startTime The start time of the event as a Date object, if available.
     * @param endTime The end time of the event as a Date object, if available.
     * @returns A string representing the iCalendar data.
     */
    private generateICalendarData(title: string, description: string, startTime?: Date, endTime?: Date): string {
        const isWholeDay = startTime && endTime ? this.isWholeDayEvent(startTime) && this.isWholeDayEvent(endTime) : false;
        const components: Array<string> = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `SUMMARY:${title}`,
            `DESCRIPTION:${description}`
        ];

        if (startTime) {
            components.push(`DTSTART:${this.formatICalendarDate(startTime, isWholeDay)}`);
        }
        if (endTime) {
            components.push(`DTEND:${this.formatICalendarDate(endTime, isWholeDay)}`);
        }

        components.push('END:VEVENT', 'END:VCALENDAR');
        return components.join('\r\n');
    }

    /**
     * Checks if the provided date is at midnight, which is used to determine whole-day events.
     * @param date The date to check.
     * @returns A boolean indicating if the date is at midnight.
     */
    private isWholeDayEvent(date: Date): boolean {
        return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0;
    }

    /**
     * Formats a Date object to the iCalendar date or datetime format depending on if it's a whole-day event.
     * @param date The date object to format.
     * @param wholeDay Indicates whether the event is a whole-day event.
     * @returns A string representing the formatted date or datetime.
     */
    private formatICalendarDate(date: Date, wholeDay: boolean): string {
        return wholeDay
            ? date.toISOString().substring(0, 10)
                .replace(/-/g, '')
            : date.toISOString().replace(/[-:]/g, '')
                .substring(0, 15);
    }

    /**
     * @private
     */
    private save(data: BufferSource | Blob | string, name: string, mime: string): void {
        const blob = new Blob([data], { type: mime });
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 0);
    }

}
