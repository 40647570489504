// #region Imports

import { html, Inset, Orientation, repeat, translate, typography, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import { chunk } from '../../../Extensions/ArrayExtensions';
import { globals } from '../../../Globals';
import { COMPONENTS } from '../../../Services/ComponentsService';
import type { ComponentsView } from './ComponentsView';

// #endregion

/**
 * The template of the {@link ComponentsView}.
 *
 * @public
 */
export function componentsViewTemplate<T extends ComponentsView>(e: T): TemplateResult {
    return html`
        <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                ${typography('headline4')}
                                .label="${translate('loc.landing.components.title', globals.name)}"
                                .ref="${'components'}"></mosaik-router-anchor>
        <mosaik-masonry .gap="${'32px'}"
                        .columns="${e.isMobile ? 2 : 4}">
            ${repeat(COMPONENTS.getComponentsWithinGroups(), (x) => x.key, (x) => html`
            <mosaik-menu .inset="${Inset.None}">
                <mosaik-menu-item-group .header="${x.key}"
                                        .orientation="${Orientation.Vertical}">
                    ${repeat(x.items, (y) => y.name, (y) => html`
                    <mosaik-router-link href="${y.path}">
                        <mosaik-menu-item .label="${y.name}"
                                            style="--menu-item-background-color: var(--theme-transparent-color)"></mosaik-menu-item>
                    </mosaik-router-link>
                    `)}
                </mosaik-menu-item-group>
            </mosaik-menu>
            `)}
        </mosaik-masonry>
        <!-- <mosaik-absolute>
            <mosaik-absolute-item> -->
            <div part="bg">
                ${repeat(chunk(COMPONENTS.getComponents(), COMPONENTS.getComponents().length / 2), (x, i) => html`
                <mosaik-marquee .reverse="${!!(i % 2)}"
                                .speed="${i % 2 ? '180000ms' : '120000ms'}">
                    ${repeat(x, (y) => y.name, (y) => html`
                    <mosaik-box part="item">
                        <mosaik-text ${typography('headline2')}
                                     style="--text-foreground-color: var(--theme-semi-transparent-color)"
                                     .text="${y.name}"></mosaik-text>
                    </mosaik-box>
                    `)}
                </mosaik-marquee>
                `)}
                </div>
            <!-- </mosaik-absolute-item>
        </mosaik-absolute> -->

    `;
}
