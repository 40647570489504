// #region Imports

import { Component, inject, View } from '@breadstone/mosaik-elements-foundation';
import { IconGalleryViewPresenter } from './IconGalleryViewPresenter';
import { iconGalleryViewStyle } from './IconGalleryViewStyle';
import { iconGalleryViewTemplate } from './IconGalleryViewTemplate';

// #endregion

/**
 * The `{@link IconGalleryViewElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-icon-gallery-view',
    template: iconGalleryViewTemplate,
    styles: iconGalleryViewStyle
})
export class IconGalleryView
    extends View<IconGalleryViewPresenter> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super(inject(IconGalleryViewPresenter));
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-icon-gallery-view';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-icon-gallery-view': IconGalleryView;
    }
}
