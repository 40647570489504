//#region Imports

import { Appearance, Placement, UpDownSpinPosition, flex, html, repeat, type NumberBoxElement, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type ViewPortElement } from './ViewPortElement';
import { VIEWPORTS } from './ViewPorts';

//#endregion

/**
 * The template of the {@link ViewPortElement}.
 *
 * @public
 */
export function viewPortElementTemplate<T extends ViewPortElement>(e: T): TemplateResult {
    return html`
        <mosaik-stack .gap="${'8px'}">
            <mosaik-numberbox style="--numberbox-height: 40px; padding: 0;"
                              .min="${20}"
                              .max="${200}"
                              .step="${10}"
                              .appearance="${Appearance.Soft}"
                              .spinPosition="${UpDownSpinPosition.Around}"
                              .value="${e.zoom}"
                              @input="${(x: InputEvent) => e.zoom = (x.target as NumberBoxElement).value ?? 100}"></mosaik-numberbox>

            <mosaik-dropdown-button .appearance="${e.appearance}"
                                    .label="${e.viewPort.name}"
                                    .maxDropDownHeight="${'300px'}"
                                    .dropDownPlacement="${Placement.Top}">
                <mosaik-menu>
                    ${repeat(VIEWPORTS, v => v.name, v => html`
                    <mosaik-menu-item force-close="true"
                                      .label="${v.name}"
                                      .subLabel="${`${v.width} x ${v.height}`}"
                                      .isChecked="${e.viewPort === v}"
                                      @click=${() => e.viewPort = v}></mosaik-menu-item>
                    `)}
                </mosaik-menu>
            </mosaik-dropdown-button>

            <mosaik-tooltip .content="${'Toogle device portrait/landscape mode'}">
                <mosaik-toggle-button .appearance="${Appearance.Soft}"
                                    .icon="${'M12 4.75a7.25 7.25 0 1 0 7.201 6.406c-.068-.588.358-1.156.95-1.156.515 0 .968.358 1.03.87a9.25 9.25 0 1 1-3.432-6.116V4.25a1 1 0 1 1 2.001 0v2.698l.034.052h-.034v.25a1 1 0 0 1-1 1h-3a1 1 0 1 1 0-2h.666A7.219 7.219 0 0 0 12 4.75Z'}"
                                    .variant="${e.swap ? 'primary' : 'default'}"
                                    ?is-active="${e.swap}"
                                    @click="${() => e.swap = !e.swap}"></mosaik-toggle-button>
            </mosaik-tooltip>

            <mosaik-tooltip .content="${'Toggle device grid lines'}">
                <mosaik-toggle-button .appearance="${Appearance.Soft}"
                                    .icon="${'M14 4a1 1 0 0 1-1 1h-2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1ZM5 11a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2ZM19 11a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2ZM13 21a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2h2ZM7 4a1 1 0 0 0-1-1 3 3 0 0 0-3 3 1 1 0 0 0 2 0 1 1 0 0 1 1-1 1 1 0 0 0 1-1ZM18 3a1 1 0 1 0 0 2 1 1 0 0 1 1 1 1 1 0 1 0 2 0 3 3 0 0 0-3-3ZM7 20a1 1 0 0 1-1 1 3 3 0 0 1-3-3 1 1 0 1 1 2 0 1 1 0 0 0 1 1 1 1 0 0 1 1 1ZM18 21a1 1 0 1 1 0-2 1 1 0 0 0 1-1 1 1 0 1 1 2 0 3 3 0 0 1-3 3Z'}"
                                    .variant="${e.visualize ? 'primary' : 'default'}"
                                    ?is-active="${e.visualize}"
                                    @click="${() => e.visualize = !e.visualize}"></mosaik-toggle-button>
            </mosaik-tooltip>
            <span ${flex({ fill: true })}></span>
            <mosaik-tooltip .content="${'Toggle maximize/minimize'}">
                <mosaik-toggle-button .appearance="${Appearance.Soft}"
                                    .icon="${e.maximized ? 'M21.778 2.223a.75.75 0 0 1 .072.976l-.072.084-6.223 6.224h5.693a.75.75 0 0 1 .743.65l.007.1a.75.75 0 0 1-.649.744l-.101.007-7.55-.002-.016-.002a.727.727 0 0 1-.195-.042l-.098-.046a.747.747 0 0 1-.386-.553l-.007-.105V2.754a.75.75 0 0 1 1.493-.102l.007.102v5.69l6.222-6.221a.749.749 0 0 1 1.06 0ZM11.003 13.755v7.504a.75.75 0 0 1-1.494.102l-.007-.102v-5.695L3.28 21.78a.75.75 0 0 1-1.133-.977l.073-.084 6.22-6.214H2.751a.75.75 0 0 1-.743-.648L2 13.755a.75.75 0 0 1 .75-.75l7.554.002.074.009.097.023.053.019.086.04.089.058a.761.761 0 0 1 .148.148l.066.106.041.094.022.07.01.055.007.058v-.008l.005.076Z' : 'M12.748 3.001h7.554l.1.014.099.028.061.026a.72.72 0 0 1 .218.15l.04.044.061.082.037.065.039.09.02.064.013.064.009.093v7.534a.75.75 0 0 1-1.493.102l-.006-.102-.001-5.696-13.94 13.945h5.69a.75.75 0 0 1 .744.65l.007.1a.75.75 0 0 1-.649.744l-.101.007H3.714L3.684 21a.705.705 0 0 1-.187-.04l-.09-.038-.018-.01a.746.746 0 0 1-.384-.553l-.007-.105V12.75a.75.75 0 0 1 1.493-.102l.007.102v5.692L18.438 4.5l-5.69.001a.75.75 0 0 1-.743-.648l-.007-.102a.75.75 0 0 1 .648-.743L12.748 3Z'}"
                                    ?is-active="${e.maximized}"
                                    @click="${() => e.maximized = !e.maximized}"></mosaik-toggle-button>
            </mosaik-tooltip>
            <mosaik-tooltip .content="${'Toggle element size tracker'}">
                <mosaik-toggle-button .appearance="${Appearance.Soft}"
                                    .icon="${'M16.78 7.22c.142.14.22.331.22.53v2.5a.75.75 0 0 1-1.5 0v-.69l-1.72 1.72a.75.75 0 1 1-1.06-1.061L14.44 8.5h-.69a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 .53.22ZM7 16.25a.75.75 0 0 0 .75.75h2.501a.75.75 0 0 0 0-1.5h-.69l1.72-1.72a.75.75 0 0 0-1.061-1.06L8.5 14.438v-.69a.75.75 0 0 0-1.5 0v2.5Z M2 6.75A2.75 2.75 0 0 1 4.75 4h14.5A2.75 2.75 0 0 1 22 6.75v10.5A2.75 2.75 0 0 1 19.25 20H4.75A2.75 2.75 0 0 1 2 17.25V6.75ZM4.75 5.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h14.5c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25H4.75Z'}"
                                    ?is-active="${e.sizeTracker}"
                                    @click="${() => e.sizeTracker = !e.sizeTracker}"></mosaik-toggle-button>
            </mosaik-tooltip>
            <mosaik-tooltip .content="${'Toggle element size tracker'}">
                <mosaik-toggle-button .appearance="${Appearance.Soft}"
                                    .icon="${'M6.25 4.5A1.75 1.75 0 0 0 4.5 6.25v1.5a.75.75 0 0 1-1.5 0v-1.5A3.25 3.25 0 0 1 6.25 3h1.5a.75.75 0 0 1 0 1.5h-1.5ZM10.2 8a2.25 2.25 0 0 0-2.25 2.25v3.5A2.25 2.25 0 0 0 10.2 16h3.5a2.25 2.25 0 0 0 2.25-2.25v-3.5A2.25 2.25 0 0 0 13.7 8h-3.5ZM17.75 4.5c.966 0 1.75.784 1.75 1.75v1.5a.75.75 0 0 0 1.5 0v-1.5A3.25 3.25 0 0 0 17.75 3h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.75 19.5a1.75 1.75 0 0 0 1.75-1.75v-1.5a.75.75 0 0 1 1.5 0v1.5A3.25 3.25 0 0 1 17.75 21h-1.5a.75.75 0 0 1 0-1.5h1.5ZM6.25 19.5a1.75 1.75 0 0 1-1.75-1.75v-1.5a.75.75 0 0 0-1.5 0v1.5A3.25 3.25 0 0 0 6.25 21h1.5a.75.75 0 0 0 0-1.5h-1.5Z'}"
                                    ?is-active="${e.resizeable}"
                                    @click="${() => e.resizeable = !e.resizeable}"></mosaik-toggle-button>
            </mosaik-tooltip>
        </mosaik-stack>
    `;
}
