// #region Imports

import { CSSResultGroup, Component, FlowDirection, MenuElement, Orientation, html } from '@breadstone/mosaik-elements-foundation';
import { menuElementDocs } from '../../../.Generated/mosaik-menu';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link MenuSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-menu-sample'
})
export class MenuSampleElement extends SampleBaseElement<MenuElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<MenuElement> {

        return {
            header: 'Menu',
            description: META.description(MenuElement.is),
            graph: META.graph(MenuElement.is),
            playground: {
                template: [['default', html`
                    <mosaik-menu>
                        <mosaik-menu-item label="Menu item 1">
                            <mosaik-menu-item label="Menu item 1.1">
                                <mosaik-menu-item label="Menu item 1.1.1"></mosaik-menu-item>
                                <mosaik-menu-item label="Menu item 1.1.2"></mosaik-menu-item>
                            </mosaik-menu-item>
                            <mosaik-menu-item label="Menu item 1.2">
                                <mosaik-menu-item label="Menu item 1.2.1"></mosaik-menu-item>
                                <mosaik-menu-item label="Menu item 1.2.2"></mosaik-menu-item>
                            </mosaik-menu-item>
                        </mosaik-menu-item>
                        <mosaik-menu-item label="Menu item 2"></mosaik-menu-item>
                        <mosaik-menu-item label="Menu item 3" ?disabled="${true}"></mosaik-menu-item>
                    </mosaik-menu>
                `], ['with groups', html`
                    <mosaik-menu>
                        <mosaik-menu-item-group header="Group 1" .orientation="${Orientation.Vertical}">
                            <mosaik-menu-item label="Menu item 1">
                                <mosaik-menu-item label="Menu item 1.1">
                                    <mosaik-menu-item label="Menu item 1.1.1"></mosaik-menu-item>
                                    <mosaik-menu-item label="Menu item 1.1.2"></mosaik-menu-item>
                                </mosaik-menu-item>
                                <mosaik-menu-item label="Menu item 1.2">
                                    <mosaik-menu-item label="Menu item 1.2.1"></mosaik-menu-item>
                                    <mosaik-menu-item label="Menu item 1.2.2"></mosaik-menu-item>
                                </mosaik-menu-item>
                            </mosaik-menu-item>
                            <mosaik-menu-item label="Menu item 2"></mosaik-menu-item>
                        </mosaik-menu-item-group>
                        <mosaik-menu-item-group header="Group 2" .orientation="${Orientation.Vertical}">
                            <mosaik-menu-item label="Menu item 3"></mosaik-menu-item>
                            <mosaik-menu-item label="Menu item 4"></mosaik-menu-item>
                        </mosaik-menu-item-group>
                    </mosaik-menu>
                `]],
                properties: META.properties<MenuElement>(MenuElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<MenuElement>(MenuElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            docs: [
                menuElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-menu-sample': MenuSampleElement;
    }
}
