// #region Imports

import { Component, DrawerElement, DrawerMode, DrawerPosition } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { drawerElementDocs } from '../../../.Generated/mosaik-drawer';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DrawerSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-drawer-sample'
})
export class DrawerSampleElement extends SampleBaseElement<DrawerElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DrawerElement> {

        return {
            header: 'Drawer',
            description: META.description(DrawerElement.is),
            graph: META.graph(DrawerElement.is),
            playground: {
                template: html`
                    <mosaik-drawer slot="drawer"
                                   id="drawer"
                                   .position="${DrawerPosition.Right}"
                                   .mode="${DrawerMode.Side}">
                        <mosaik-stack>
                        </mosaik-stack>
                    </mosaik-drawer>

                `,
                properties: META.properties<DrawerElement>(DrawerElement.is, [
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'position',
                        value: DrawerPosition.Right
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub header'
                    },
                    {
                        key: 'mode',
                        value: DrawerMode.Push
                    }
                ]),
                events: META.events(DrawerElement.is)
            },
            propertyGrid: {
                properties: META.properties<DrawerElement>(DrawerElement.is, [
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'position',
                        value: DrawerPosition.Right
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub header'
                    },
                    {
                        key: 'mode',
                        value: DrawerMode.Push
                    }
                ])
            },
            docs: [
                drawerElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-drawer-sample': DrawerSampleElement;
    }
}
