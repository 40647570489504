// #region Imports

import { Appearance, Component, ElevatioWeight, FlipElement, FlowDirection, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { flipElementDocs } from '../../../.Generated/mosaik-flip';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link FlipSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-flip-sample'
})
export class FlipSampleElement extends SampleBaseElement<FlipElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<FlipElement> {
        return {
            header: 'Flip',
            description: META.description(FlipElement.is),
            graph: META.graph(FlipElement.is),
            playground: {
                template: (_props, set, get) => html`
                    <mosaik-flip ${ref((el) => set(el as FlipElement))}>
                        <mosaik-card slot="front"
                                     .appearance="${Appearance.Plain}"
                                     .elevation="${ElevatioWeight.Bold}"
                                     @click="${() => get()?.toggle()}">
                            <mosaik-card-header .text="${'Front'}"></mosaik-card-header>
                        </mosaik-card>
                        <mosaik-card slot="back"
                                     .appearance="${Appearance.Plain}"
                                     .elevation="${ElevatioWeight.Bold}"
                                     @click="${() => get()?.toggle()}">
                            <mosaik-card-header .text="${'Back'}"></mosaik-card-header>
                        </mosaik-card>
                    </mosaik-flip>
                `,
                properties: META.properties<FlipElement>(FlipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isFlipped',
                        value: false
                    }
                ]),
                events: META.events(FlipElement.is)
            },
            propertyGrid: {
                properties: META.properties<FlipElement>(FlipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isFlipped',
                        value: false
                    }
                ])
            },
            docs: [
                flipElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-flip-sample': FlipSampleElement;
    }
}
