// #region Imports

import { ColorPickerElement, Component } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { colorPickerElementDocs } from '../../../.Generated/mosaik-color-picker';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ColorPickerSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-color-picker-sample'
})
export class ColorPickerSampleElement extends SampleBaseElement<ColorPickerElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ColorPickerElement> {
        return {
            header: 'Color Picker',
            description: META.description(ColorPickerElement.is),
            graph: META.graph(ColorPickerElement.is),
            playground: {
                template: html`
                    <mosaik-color-picker></mosaik-color-picker>
                `,
                properties: [

                ],
                events: META.events<ColorPickerElement>(ColorPickerElement.is)
            },
            propertyGrid: {
                properties: META.properties<ColorPickerElement>(ColorPickerElement.is, [

                ])
            },
            docs: [
                colorPickerElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-color-picker-sample': ColorPickerSampleElement;
    }
}
