// #region Imports

import { Appearance, Component, FlowDirection, SwipeElement, Variant, query, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { swipeElementDocs } from '../../../.Generated/mosaik-swipe';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link SwipeSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-swipe-sample'
})
export class SwipeSampleElement extends SampleBaseElement<SwipeElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SwipeElement> {
        return {
            header: 'Swipe',
            description: META.description(SwipeElement.is),
            graph: META.graph(SwipeElement.is),
            playground: {
                template: (_props, set, get) => html`
                    <mosaik-list ${ref((e) => set(e as any))}>
                         <mosaik-swipe .panLeftDisabled="${false}" .panRightDisabled="${true}">
                            <mosaik-button slot="before"
                                           .label="${'Read'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Info}"></mosaik-button>
                            <mosaik-button slot="before"
                                           .label="${'Remind me'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Highlight}"></mosaik-button>
                            <mosaik-list-item>
                                <mosaik-text .text="${'Only left panning'}"></mosaik-text>
                            </mosaik-list-item>
                        </mosaik-swipe>
                        <mosaik-swipe .panLeftDisabled="${true}" .panRightDisabled="${false}">
                            <mosaik-list-item>
                                <mosaik-text .text="${'Only right panning'}"></mosaik-text>
                            </mosaik-list-item>
                            <mosaik-button slot="after"
                                           .label="${'Delete'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Danger}"></mosaik-button>
                        </mosaik-swipe>
                        <mosaik-swipe
                                      .panLeftDisabled="${true}"
                                      .panRightDisabled="${true}">
                            <mosaik-button slot="before"
                                           .label="${'Read'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Info}"></mosaik-button>
                            <mosaik-button slot="before"
                                           .label="${'Remind me'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Highlight}"></mosaik-button>
                            <mosaik-list-item>
                                <mosaik-text .text="${'Both panning'}"></mosaik-text>
                            </mosaik-list-item>
                            <mosaik-button slot="after"
                                           .label="${'Delete'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Danger}"></mosaik-button>
                        </mosaik-swipe>
                        <mosaik-list-item>
                            <mosaik-text .text="${'Nothig'}"></mosaik-text>
                        </mosaik-list-item>
                        <mosaik-swipe id="swipe">
                            <mosaik-list-item>
                                <mosaik-text .text="${'manual'}"></mosaik-text>
                                <mosaik-stack>
                                    <mosaik-button @click="${() => query<SwipeElement>(get()!, '#swipe', {
                    shadow: false,
                    strict: true
                }).panLeft()}">Pan left</mosaik-button>
                                    <mosaik-button @click="${() => query<SwipeElement>(get()!, '#swipe', {
                    shadow: false,
                    strict: true
                }).panRight()}">Pan right</mosaik-button>
                                </mosaik-stack>
                            </mosaik-list-item>
                        </mosaik-swipe>
                    </mosaik-list>
                `,
                properties: META.properties<SwipeElement>(SwipeElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }]),
                events: META.events(SwipeElement.is)
            },
            propertyGrid: {
                properties: META.properties<SwipeElement>(SwipeElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            docs: [
                swipeElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-swipe-sample': SwipeSampleElement;
    }
}
