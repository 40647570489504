// #region Imports

import { Component, CustomElement, Listen, PanController, Property, QueryChild, Watch } from '@breadstone/mosaik-elements-foundation';
import mermaid from 'mermaid';
import { diagrammElementStyle } from './DiagrammElementStyle';
import { diagrammElementTemplate } from './DiagrammElementTemplate';
import { IDiagrammElementProps } from './IDiagrammElementProps';

// #endregion

/**
 * The `{@link DiagrammElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-diagramm',
    template: diagrammElementTemplate,
    styles: diagrammElementStyle,
    imports: []
})
export class DiagrammElement
    extends CustomElement
    implements IDiagrammElementProps {

    // #region Fields

    @QueryChild('[part="root"]')
    private readonly _root!: HTMLElement;

    private readonly _panController: PanController;
    private readonly _minZoom: number;
    private readonly _maxZoom: number;
    private readonly _zoomFactor: number;
    private readonly _panFactor: number;

    private _text: string;
    private _zoom: number;
    private _panX: number;
    private _panY: number;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._minZoom = 0.2;
        this._maxZoom = 6;
        this._zoomFactor = 0.1;
        this._panFactor = 100;
        this._text = '';
        this._zoom = 1;
        this._panX = 0;
        this._panY = 0;

        this._panController = new PanController(this);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-diagramm';
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    // #endregion

    // #region Methods

    /**
     * Zooms in the diagram.
     *
     * @public
     */
    public zoomIn(): void {
        if (this._zoom >= this._maxZoom) {
            return;
        }

        this._zoom += this._zoomFactor;
        this.updateTransform();
    }

    /**
     * Zooms out the diagram.
     *
     * @public
     */
    public zoomOut(): void {
        if (this._zoom <= this._minZoom) {
            return;
        }

        this._zoom -= this._zoomFactor;
        this.updateTransform();
    }

    /**
     * Resets the zoom level of the diagram.
     *
     * @public
     */
    public resetZoom(): void {
        this._zoom = 1;
        this._panX = 0;
        this._panY = 0;
        this.updateTransform();
    }

    /**
     * Pans up the diagram.
     *
     * @public
     */
    public panUp(): void {
        const panFactorByZoom = this._panFactor / this._zoom;

        this._panY += panFactorByZoom;
        this.updateTransform();
    }

    /**
     * Pans down the diagram.
     *
     * @public
     */
    public panDown(): void {
        const panFactorByZoom = this._panFactor / this._zoom;

        this._panY -= panFactorByZoom;
        this.updateTransform();
    }

    /**
     * Pans left the diagram.
     *
     * @public
     */
    public panLeft(): void {
        const panFactorByZoom = this._panFactor / this._zoom;

        this._panX += panFactorByZoom;
        this.updateTransform();
    }

    /**
     * Pans right the diagram.
     *
     * @public
     */
    public panRight(): void {
        const panFactorByZoom = this._panFactor / this._zoom;

        this._panX -= panFactorByZoom;
        this.updateTransform();
    }

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        mermaid.initialize({
            theme: 'dark',
            darkMode: true,
            securityLevel: 'loose'
        });

        void mermaid.run();
    }

    /**
     * @protected
     * @override
     */
    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        void this.updateComplete.then(async () => {
            try {
                const result = await mermaid.render('diagramm', this.text);
                result.bindFunctions?.(this._root);
                this._root.innerHTML = result.svg;
            } catch (error) {
                this._root.innerHTML = String(error);
            }
        });
    }

    /**
     * @protected
     */
    @Watch('text')
    protected onTextPropertyChanged(prev?: string, next?: string): void {
        // if (next) {
        //     void this.updateComplete.then(() => {
        //         void mermaid.render('graphDiv', next, this._root);
        //     });
        // } else {
        //     this._root.innerHTML = '';
        // }
    }

    /**
     * @protected
     */
    @Listen('panmove', 'self')
    protected onPanMove(event: CustomEvent): void {
        event.stopPropagation();

        const deltaX = event.detail.deltaX;
        const deltaY = event.detail.deltaY;
        this._root.style.transform = `translate(${this._panX + deltaX}px, ${this._panY + deltaY}px)`;
    }

    /**
     * Returns the svg element what will be rendered by mermaid
     *
     * @private
     */
    private getInnerSvg(): SVGElement {
        return this._root.querySelector('svg') as SVGElement;
    }

    /**
     * Updates the transform of the inner svg element
     *
     * @private
     */
    private updateTransform(): void {
        this.getInnerSvg().style.transform = `scale(${this._zoom}) translate(${this._panX}px, ${this._panY}px)`;
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-diagramm': DiagrammElement;
    }
}
