import { Appearance, type CardElement, HorizontalAlignment, Orientation, Size, type TemplateResult, TextFormatters, Variant, VerticalAlignment, html, typography } from '@breadstone/mosaik-elements-foundation';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';

export namespace CardSampleTemplates {

    export function defaultTemplate(props: { get: <T = unknown>(key: keyof CardElement) => IPlaygroundProperty<CardElement, T> }): TemplateResult {

        return html`
            <mosaik-card style="width: 316px;">
                <mosaik-card-actions>
                    <mosaik-button .label="${'Action 1'}"></mosaik-button>
                    <mosaik-button .label="${'Action 2'}"></mosaik-button>
                </mosaik-card-actions>
            </mosaik-card>
        `;

    }

    export function instagramTemplate(props: { get: <T = unknown>(key: keyof CardElement) => IPlaygroundProperty<CardElement, T> }): TemplateResult {

        return html`
            <mosaik-card style="width: 316px;">
                <mosaik-card-header .text="${props.get<string>('header').value ?? ''}">
                    <mosaik-avatar slot="prefix"
                                    .text="${'JD'}"
                                    .size="${Size.Small}"
                                    .variant="${Variant.Primary}"
                                    .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"
                                    .appearance="${props.get<Appearance>('appearance').value ?? Appearance.Default}"></mosaik-avatar>
                </mosaik-card-header>
                <mosaik-image slot="media"
                                .height="${'200px'}"
                                .src="${'https://images.unsplash.com/photo-1517948812699-8c02585b30ba?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"></mosaik-image>
                <mosaik-card-content>
                    <mosaik-text .text="${'liked from *You* and *135* others'}"
                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>
                    <mosaik-avatar-group .size="${Size.Tiny}"
                                            .appearance="${Appearance.Soft}"
                                            .variant="${Variant.Primary}">
                        <mosaik-avatar .text="${'JD'}"
                                        .appearance="${Appearance.Soft}"
                                        .variant="${Variant.Primary}"></mosaik-avatar>
                        <mosaik-avatar .text="${'PG'}"
                                        .appearance="${Appearance.Soft}"
                                        .variant="${Variant.Primary}"></mosaik-avatar>
                        <mosaik-avatar .text="${'NW'}"
                                        .appearance="${Appearance.Soft}"
                                        .variant="${Variant.Primary}"></mosaik-avatar>
                    </mosaik-avatar-group>
                </mosaik-card-content>
                <mosaik-card-actions>
                    <mosaik-stack slot="prefix" .gap="${'16px'}">
                        <mosaik-button .icon="${'m12.82 5.58-.82.822-.824-.824a5.375 5.375 0 1 0-7.601 7.602l7.895 7.895a.75.75 0 0 0 1.06 0l7.902-7.897a5.376 5.376 0 0 0-.001-7.599 5.38 5.38 0 0 0-7.611 0Zm6.548 6.54L12 19.485 4.635 12.12a3.875 3.875 0 1 1 5.48-5.48l1.358 1.357a.75.75 0 0 0 1.073-.012L13.88 6.64a3.88 3.88 0 0 1 5.487 5.48Z'}"
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Default}"></mosaik-button>
                        <mosaik-button .icon="${'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.587-1.112l-3.826 1.067a1.25 1.25 0 0 1-1.54-1.54l1.068-3.823A9.96 9.96 0 0 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5A8.5 8.5 0 0 0 3.5 12c0 1.47.373 2.883 1.073 4.137l.15.27-1.112 3.984 3.987-1.112.27.15A8.5 8.5 0 1 0 12 3.5ZM8.75 13h4.498a.75.75 0 0 1 .102 1.493l-.102.007H8.75a.75.75 0 0 1-.102-1.493L8.75 13h4.498H8.75Zm0-3.5h6.505a.75.75 0 0 1 .101 1.493l-.101.007H8.75a.75.75 0 0 1-.102-1.493L8.75 9.5h6.505H8.75Z'}"
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Default}"></mosaik-button>
                        <mosaik-button .icon="${'M5.694 12 2.299 3.272c-.236-.607.356-1.188.942-.982l.093.04 18 9a.75.75 0 0 1 .097 1.283l-.097.058-18 9c-.583.291-1.217-.244-1.065-.847l.03-.096L5.694 12 2.299 3.272 5.694 12ZM4.402 4.54l2.61 6.71h6.627a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.649.743l-.101.007H7.01l-2.609 6.71L19.322 12 4.401 4.54Z'}"
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Default}"></mosaik-button>
                    </mosaik-stack>
                    <mosaik-stack .gap="${'16px'}"
                                    .horizontalAlignment="${HorizontalAlignment.Right}">
                        <mosaik-button .icon="${'M6.19 21.854a.75.75 0 0 1-1.188-.61V6.25a3.25 3.25 0 0 1 3.25-3.25h7.499A3.25 3.25 0 0 1 19 6.249v14.996a.75.75 0 0 1-1.188.609l-5.811-4.181-5.812 4.18ZM17.5 6.249a1.75 1.75 0 0 0-1.75-1.75H8.253a1.75 1.75 0 0 0-1.75 1.75v13.532l5.062-3.64a.75.75 0 0 1 .876 0l5.06 3.64V6.25Z'}"
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Default}"></mosaik-button>
                    </mosaik-stack>
                </mosaik-card-actions>
                <mosaik-card-footer>
                    <mosaik-textbox .placeholder="${'Send a reply...'}"
                                    .appearance="${Appearance.Plain}">
                        <mosaik-button slot="suffix"
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Primary}"
                                        .label="${'Send'}"></mosaik-button>
                    </mosaik-textbox>
                </mosaik-card-footer>
            </mosaik-card>
        `;

    }

    export function bookingTemplate(props: { get: <T = unknown>(key: keyof CardElement) => IPlaygroundProperty<CardElement, T> }): TemplateResult {

        return html`
            <mosaik-card style="width: 316px;">
                <mosaik-carousel slot="media"
                                    .showNavigation="${true}"
                                    .showIndicator="${true}"
                                    .appearance="${Appearance.Soft}"
                                    .loop="${true}"
                                    .variant="${Variant.Primary}">
                    <mosaik-carousel-item>
                        <mosaik-image
                                .src="${'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                .height="${'200px'}"
                                .width="${'316px'}"></mosaik-image>
                    </mosaik-carousel-item>
                    <mosaik-carousel-item>
                        <mosaik-image
                                .src="${'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                .height="${'200px'}"
                                .width="${'316px'}"></mosaik-image>
                    </mosaik-carousel-item>
                    <mosaik-carousel-item>
                        <mosaik-image
                                .src="${'https://images.unsplash.com/photo-1477314099024-9f209cdc507a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                .height="${'200px'}"
                                .width="${'316px'}"></mosaik-image>
                    </mosaik-carousel-item>
                </mosaik-carousel>
                <mosaik-card-header>
                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                    .gap="${'4px'}"
                                    .verticalAlignment="${VerticalAlignment.Center}">
                        <mosaik-rating .size="${Size.Small}"
                                        .value="${4.9}"
                                        .symbol="${'M10.788 3.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L8.43 7.88l2.358-4.777Z'}"></mosaik-rating>
                        <!-- <div>
                            <mosaik-text ${typography('body1')} .text="${'4.9'}"></mosaik-text>
                            <mosaik-text ${typography('body2')} .text="${'/5'}" .readonly="${true}"></mosaik-text>
                        </div> -->
                        <mosaik-anchor .label="${'(27 reviews)'}"
                                        .variant="${Variant.Primary}"></mosaik-anchor>
                    </mosaik-stack>
                </mosaik-card-header>
                <mosaik-divider slot="content" .orientation="${Orientation.Horizontal}"></mosaik-divider>
                <mosaik-card-content>
                        <mosaik-stack .orientation="${Orientation.Horizontal}"
                                        .gap="${'16px'}">
                            <mosaik-stack .orientation="${Orientation.Vertical}"
                                            .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                <mosaik-icon .data="${'M10.55 2.532a2.25 2.25 0 0 1 2.9 0l6.75 5.692c.507.428.8 1.057.8 1.72v9.803a1.75 1.75 0 0 1-1.75 1.75h-3.5a1.75 1.75 0 0 1-1.75-1.75v-5.5a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25v5.5a1.75 1.75 0 0 1-1.75 1.75h-3.5A1.75 1.75 0 0 1 3 19.747V9.944c0-.663.293-1.292.8-1.72l6.75-5.692Zm1.933 1.147a.75.75 0 0 0-.966 0L4.767 9.37a.75.75 0 0 0-.267.573v9.803c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25v-5.5c0-.967.784-1.75 1.75-1.75h3.5c.966 0 1.75.783 1.75 1.75v5.5c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25V9.944a.75.75 0 0 0-.267-.573l-6.75-5.692Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')} .text="${'House'}"
                                            .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack .orientation="${Orientation.Vertical}"
                                            .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                <mosaik-icon .data="${'M6.75 4h10.5a2.75 2.75 0 0 1 2.745 2.582L20 6.75v3.354a2.752 2.752 0 0 1 1.994 2.459l.006.187v7.5a.75.75 0 0 1-1.493.102l-.007-.102V18h-17v2.25a.75.75 0 0 1-.648.743L2.75 21a.75.75 0 0 1-.743-.648L2 20.25v-7.5c0-1.259.846-2.32 2-2.647V6.75a2.75 2.75 0 0 1 2.582-2.745L6.75 4Zm12.5 7.5H4.75a1.25 1.25 0 0 0-1.244 1.122l-.006.128v3.75h17v-3.75a1.25 1.25 0 0 0-1.122-1.243l-.128-.007Zm-2-6H6.75a1.25 1.25 0 0 0-1.244 1.122L5.5 6.75V10H7a1 1 0 0 1 1-1h2a1 1 0 0 1 .993.883L11 10h2a1 1 0 0 1 1-1h2a1 1 0 0 1 .993.883L17 10h1.5V6.75a1.25 1.25 0 0 0-1.122-1.244L17.25 5.5Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')} .text="${'2 Beds'}"
                                            .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack .orientation="${Orientation.Vertical}"
                                            .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                <mosaik-icon .data="${''}"></mosaik-icon>
                                <mosaik-text ${typography('body2')} .text="${'1 Bath'}"
                                            .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack .orientation="${Orientation.Vertical}"
                                            .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                <mosaik-icon .data="${'M15.8 3a3.25 3.25 0 0 1 3.152 2.46L19.337 7h.913a.75.75 0 0 1 .743.648L21 7.75a.75.75 0 0 1-.648.743l-.102.007h-.536l.208.826a2.249 2.249 0 0 1 1.082 1.924v8.497a1.75 1.75 0 0 1-1.75 1.75H17.75a1.75 1.75 0 0 1-1.75-1.75l-.001-1.247H8.004v1.247a1.75 1.75 0 0 1-1.75 1.75H4.75A1.75 1.75 0 0 1 3 19.747V11.25c0-.815.433-1.529 1.082-1.923l.207-.827H3.75a.75.75 0 0 1-.743-.648L3 7.75a.75.75 0 0 1 .648-.743L3.75 7h.913l.386-1.538A3.25 3.25 0 0 1 8.202 3h7.597ZM6.503 18.5H4.499l.001 1.247c0 .138.112.25.25.25h1.504a.25.25 0 0 0 .25-.25V18.5Zm13 0h-2.005l.001 1.247c0 .138.112.25.25.25h1.504a.25.25 0 0 0 .25-.25V18.5Zm-.75-8H5.25a.75.75 0 0 0-.75.75V17h15.004v-5.75a.75.75 0 0 0-.75-.75ZM10.25 14h3.502a.75.75 0 0 1 .101 1.493l-.101.007h-3.502a.75.75 0 0 1-.102-1.493L10.25 14h3.502-3.502ZM17 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM7 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm8.8-7.5H8.201a1.75 1.75 0 0 0-1.698 1.326L5.711 9h12.582l-.796-3.176A1.75 1.75 0 0 0 15.799 4.5Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')} .text="${'1 Garage'}"
                                            .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                        </mosaik-stack>
                </mosaik-card-content>
                <mosaik-card-content>
                    <mosaik-text .text="${'Experience the wonders of nature as you hike through the snow-capped mountains and fish in the nearby river. This newly built cabin is perched high in the mountains with beautiful'}"
                                        .wrap="${true}"
                                        .readonly="${true}"
                                        .maxLines="${3}"></mosaik-text>
                </mosaik-card-content>
                <mosaik-divider slot="content" .orientation="${Orientation.Horizontal}"></mosaik-divider>
                <mosaik-card-actions>
                    <mosaik-text slot="prefix" ${typography('body1')} .text="${'$299.00'}"></mosaik-text>
                    <mosaik-text slot="prefix" ${typography('body2')} .text="${'/night'}" .readonly="${true}"></mosaik-text>
                    <mosaik-button .label="${'Book now'}" .appearance="${Appearance.Soft}" .variant="${Variant.Primary}"></mosaik-button>
                </mosaik-card-actions>
            </mosaik-card>
        `;

    }

}
