// #region Imports

import { animate, Animate, Appearance, html, repeat, Size, Spacing, translate, UpDownSpinPosition, Variant, when, type IChangedEventDetail, type NumberBoxElement, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { ISelectorSelectionChangedEvent } from '@breadstone/mosaik-elements-foundation/dist/Controls/Components/Selectors/Abstracts/Selector/Events/ISelectorSelectionChangedEventDetail';
import { globals } from '../../../Globals';
import { Icons } from '../../../Resources/Icons/Icons';
import { FeatureManager } from '../../../Services/FeatureManager';
import type { AppSettingsView } from '../../../Views/App/Dialogs/AppSettingsView';

// #endregion

/**
 * The template of the {@link AppSettingsView}.
 *
 * @public
 */
export function appSettingsViewTemplate<T extends AppSettingsView>(e: T): TemplateResult {
    return html`
        <mosaik-dialog id="themeDialog"
                       style="--dialog-divider-height: 0px"
                       .isOpen="${false}"
                       .hasBackdrop="${true}"
                       .clickOutsideToClose="${true}"
                       .pressEscapeToClose="${true}"
                       .isFullScreen="${e.isMobile}">
            <mosaik-dialog-header>
                <mosaik-dialog-header-text .text="${translate('loc.settings.header')}"></mosaik-dialog-header-text>
                <mosaik-dialog-header-sub-text .text="${translate('loc.settings.subHeader')}"></mosaik-dialog-header-sub-text>
            </mosaik-dialog-header>
            <mosaik-dialog-content style="padding: 0;">
                <mosaik-tab style="--tab-divider-height: 1px"
                            .appearance="${Appearance.Plain}"
                            .variant="${Variant.Primary}">
                    <mosaik-tab-item .label="${translate('loc.settings.common.label')}">
                        <mosaik-cell-group>
                        ${when(FeatureManager.isActive('multiLanguage'), () => html`
                        <mosaik-cell .label="${translate('loc.global.language.tooltip')}">
                            <mosaik-select slot="end"
                                           @selectionChanged="${(x: CustomEvent<IChangedEventDetail>) => e.language.set((x.detail.newValue as any).value)}">
                                ${repeat(e.languages, (x) => x, (x) => html`
                                <mosaik-select-item .label="${translate('loc.global.language.'.concat(x.key))}"
                                                    .value="${x.key}"
                                                    .isSelected="${e.language.get() === x.key}"></mosaik-select-item>
                                `)}
                            </mosaik-select>
                        </mosaik-cell>

                        `)}
                        </mosaik-cell-group>
                        <mosaik-cell-group .header="${translate('loc.settings.theme.header')}">
                        <mosaik-cell .label="${translate('loc.global.theme.scheme')}">
                            <mosaik-select slot="end"
                                           @selectionChanged="${(x: ISelectorSelectionChangedEvent) => e.scheme.set((x.detail.newValue as any).value as string)}">
                                ${repeat(globals.theme.schemes, (x) => x, (x) => html`
                                <mosaik-select-item .label="${x.key}"
                                                    .value="${x.key}"
                                                    .isSelected="${e.scheme.get() === x.key}">
                                    <mosaik-theme slot="end"
                                                  .scheme="${x.key}"
                                                  .isInline="${true}">
                                        <mosaik-color-swatch .value="${x.key}"></mosaik-color-swatch>
                                    </mosaik-theme>
                                </mosaik-select-item>
                                `)}
                            </mosaik-select>
                        </mosaik-cell>
                        <mosaik-divider></mosaik-divider>
                        <mosaik-cell .label="${translate('loc.global.theme.preset')}">
                            <mosaik-select slot="end"
                                           @selectionChanged="${(x: ISelectorSelectionChangedEvent) => e.preset.set((x.detail.newValue as any).value)}">
                                ${repeat(globals.theme.presets, (x) => x, (x) => html`
                                <mosaik-select-item .label="${x.key}"
                                                    .value="${x}"
                                                    .isSelected="${e.preset.get().key === x.key}">
                                    <mosaik-color-swatch slot="end"
                                                         .value="${x.primary}"></mosaik-color-swatch>
                                    <mosaik-color-swatch slot="end"
                                                         .value="${x.secondary}"></mosaik-color-swatch>
                                    <mosaik-color-swatch slot="end"
                                                         .value="${x.tertiary}"></mosaik-color-swatch>
                                </mosaik-select-item>
                                `)}
                            </mosaik-select>
                        </mosaik-cell>
                        <mosaik-divider></mosaik-divider>
                        <mosaik-cell .label="${translate('loc.global.theme.layoutRounding')}"
                                     .subLabel="${'px'}">
                            <mosaik-numberbox slot="end"
                                              style="width: 152px;"
                                              .min="${0}"
                                              .max="${16}"
                                              .value="${12}"
                                              .spinPosition="${UpDownSpinPosition.After}"
                                              @input="${(x: Event) => e.layout.set({ radius: (x.target as NumberBoxElement).value ?? 0 })}"></mosaik-numberbox>
                        </mosaik-cell>
                        <mosaik-divider></mosaik-divider>
                        <mosaik-cell .label="${translate('loc.global.theme.font')}">
                            <mosaik-select slot="end"
                                           @selectionChanged="${(x: CustomEvent<IChangedEventDetail>) => e.font.set((x.detail.newValue as any).value)}">
                                ${repeat(e.fonts, (x) => x, (x) => html`
                                <mosaik-select-item .isSelected="${e.font.get().fontFamily === x}"
                                                    .label="${x}"
                                                    .value="${x}">
                                    <span slot="end"
                                          style="font-family: ${x}">abc</span>
                                </mosaik-select-item>
                                `)}
                            </mosaik-select>
                        </mosaik-cell>
                        </mosaik-cell-group>
                    </mosaik-tab-item>
                    ${when(e.features.length > 0, () => html`
                    <mosaik-tab-item .label="${translate('loc.settings.experimental.label')}">
                        ${when(e.hasFeatureChanges, () => html`
                        <mosaik-spacer .thickness="${Spacing.All}" .size="${Size.Small}">
                            <mosaik-banner ${animate({ ...Animate.slideInTop })} .appearance="${Appearance.Soft}"
                                           .variant="${Variant.Warning}"
                                           .header="${translate('Feature changes detected!')}"
                                           .subHeader="${translate('After an experimental feature has been changed, it is recommended to reload the application. Do you want to reload now?')}"
                                           .icon="${Icons.WARNING}">
                                <mosaik-button slot="actions"
                                               .label="${translate('Yes, reload!')}"
                                               .variant="${Variant.Warning}"
                                               .appearance="${Appearance.Solid}"
                                               @click="${() => window.location.reload()}"></mosaik-button>
                                <mosaik-button slot="actions"
                                               .label="${translate('Ignore')}"
                                               .variant="${Variant.Warning}"
                                               .appearance="${Appearance.Soft}"
                                               @click="${() => e.hasFeatureChanges.set(false)}"></mosaik-button>
                            </mosaik-banner>
                        </mosaik-spacer>
                        `)}
                        <mosaik-cell-group>
                            ${repeat(e.features, (x) => x, (x, i) => html`
                            <mosaik-cell .label="${x.key}">
                                <mosaik-text slot="subLabel"
                                             .text="${x.description}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-toggle-switch slot="end"
                                                      .variant="${Variant.Primary}"
                                                      .isChecked="${x.active}"
                                                      @checked="${() => e.changeFeatureFlagCommand.execute({ flag: x, state: true })}"
                                                      @unchecked="${() => e.changeFeatureFlagCommand.execute({ flag: x, state: false })}"></mosaik-toggle-switch>
                            </mosaik-cell>
                            ${when(i < e.features.length - 1, () => html`
                            <mosaik-divider></mosaik-divider>
                            `)}
                            `)}
                        </mosaik-cell-group>
                    </mosaik-tab-item>
                    `)}
                </mosaik-tab>
            </mosaik-dialog-content>
            <mosaik-dialog-actions>
                <mosaik-button slot="prefix"
                               .label="${translate('loc.global.theme.reset')}"
                               .appearance="${Appearance.Plain}"
                               .variant="${Variant.Danger}"
                               @click="${() => e.resetCommand.execute()}"></mosaik-button>
                <mosaik-button .label="${translate('loc.global.theme.close')}"
                               .variant="${Variant.Primary}"
                               .appearance="${Appearance.Soft}"
                               @click="${() => e.closeCommand.execute()}"></mosaik-button>
            </mosaik-dialog-actions>
        </mosaik-dialog>
    `;
}
