// #region Imports

import { Component, CustomElement, Emit, HTMLElementEventEmitter, Property, RendererServiceLocator, State, Watch, on, type IConnectedCallback, type IEventEmitter, type IEventListenerSubscription, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { CssLength } from '@breadstone/mosaik-elements-foundation/dist/Theming/Types';
import { ObjectExtensions } from '../../Extensions/ObjectExtensions';
import type { IViewPort } from '../ViewPort/IViewPort';
import type { IPlaygroundThrowEventDetail } from './Events/IPlaygroundThrowEvent';
import { IPlaygroundElementProps, TemplateResultFn } from './IPlaygroundElementProps';
import type { IPlaygroundEvent, IPlaygroundProperty } from './IPlaygroundProperty';
import { playgroundElementStyle } from './PlaygroundElementStyle';
import { playgroundElementTemplate } from './PlaygroundElementTemplate';

// #endregion

/**
 * The `{@link PlaygroundElement}` element.
 *
 * @fires eventTriggered - Fired when an event is triggered.
 *
 * @public
 */
@Component({
    selector: 'app-playground',
    template: playgroundElementTemplate,
    styles: playgroundElementStyle()
})
export class PlaygroundElement<TElement extends HTMLElement = HTMLElement>
    extends CustomElement
    implements IConnectedCallback, IPlaygroundElementProps<TElement> {

    // #region Fields

    private readonly _eventTriggered: IEventEmitter<IPlaygroundThrowEventDetail>;
    @State()
    private _ofElement: TElement | null;
    private _template: TemplateResult | TemplateResultFn<TElement> | null;
    private _properties: Array<IPlaygroundProperty<TElement>> | null;
    private _events: Array<IPlaygroundEvent> | null;
    private _viewPort: {
        viewPort: IViewPort;
        zoom: number;
        visualize: boolean;
    } | null;
    private _ofElementEventSubscriptions: Array<IEventListenerSubscription>;
    private _resizeable: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._ofElement = null;
        this._template = null;
        this._properties = null;
        this._events = null;
        this._viewPort = null;
        this._resizeable = false;
        this._ofElementEventSubscriptions = new Array<IEventListenerSubscription>();
        this._eventTriggered = new HTMLElementEventEmitter(this, 'eventTriggered');
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-playground';
    }

    /**
     * Gets or sets the `of` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get template(): TemplateResult | TemplateResultFn<TElement> | null {
        return this._template;
    }
    public set template(value: TemplateResult | TemplateResultFn<TElement> | null) {
        if (this._template !== value) {
            this._template = value;
            this.requestUpdate('template');
        }
    }

    /**
     * Gets or sets the `property` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get properties(): Array<IPlaygroundProperty<TElement>> | null {
        return this._properties;
    }
    public set properties(value: Array<IPlaygroundProperty<TElement>> | null) {
        if (this._properties !== value) {
            this._properties = value;
            this.requestUpdate('properties');
        }
    }

    /**
     * Gets or sets the `events` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get events(): Array<IPlaygroundEvent> | null {
        return this._events;
    }
    public set events(value: Array<IPlaygroundEvent> | null) {
        if (this._events !== value) {
            this._events = value;
            this.requestUpdate('events');
        }
    }

    /**
     * Gets or sets the `viewPort` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get viewPort(): {
        viewPort: IViewPort;
        zoom: number;
        visualize: boolean;
    } | null {
        return this._viewPort;
    }
    public set viewPort(value: {
        viewPort: IViewPort;
        zoom: number;
        visualize: boolean;
    } | null) {
        if (this._viewPort !== value) {
            this._viewPort = value;
            this.requestUpdate('viewPort');
        }
    }

    /**
     * Gets or sets the `resizeable` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get resizeable(): boolean {
        return this._resizeable;
    }
    public set resizeable(value: boolean) {
        if (this._resizeable !== value) {
            this._resizeable = value;
            this.requestUpdate('resizeable');
        }
    }

    /**
     * Returns the `ofElement` property.
     *
     * @public
     * @readonly
     */
    public get ofElement(): TElement | null {
        return this._ofElement;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `{@link IPlaygroundThrowEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'eventTriggered' })
    public get eventTriggered(): IEventEmitter<IPlaygroundThrowEventDetail> {
        return this._eventTriggered;
    }

    /**
     * Emitts the {@link eventTriggered} event.
     *
     * @protected
     */
    protected onEventTriggered(args: IPlaygroundThrowEventDetail): void {
        this._eventTriggered.emit(args);
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.invalidate();
    }

    /**
     * @protected
     */
    @Watch('template', { waitUntilFirstUpdate: true })
    protected onTemplatePropertyChanged(prev?: TemplateResult | TemplateResultFn<TElement> | null, next?: TemplateResult | TemplateResultFn<TElement> | null): void {
        if (prev !== next) {
            this.invalidate();
        }
    }

    /**
     * @protected
     */
    @Watch('viewPort')
    protected onViewPortPropertyChanged(prev?: {
        viewPort: IViewPort;
        zoom: number;
    } | null, next?: {
        viewPort: IViewPort;
        zoom: number;
    } | null): void {
        if (prev !== next) {
            const adjustedZoom = this.tryAdjustZoom();
        }
    }

    /**
     * @private
     */
    private invalidate(): void {
        this._ofElementEventSubscriptions.forEach((x) => x.dispose());
        this._ofElementEventSubscriptions = [];

        if (this._template) {
            let getSetElement: TElement | null = null;
            const set = (el: TElement): void => {
                // TODO: chech why here is undefined possible
                if (!el) {
                    return;
                }

                getSetElement = el;
            };
            const get = (): TElement | null => getSetElement;
            const props = {
                get: (key: IPlaygroundProperty<TElement>['key']) => {
                    const p = (this._properties ?? []).find((x) => x.key === key);
                    if (!p) {
                        throw new Error(`Property with key ${key.toString()} not found.`);
                    }

                    return p;
                }
            };

            const tpl = typeof this._template === 'function' ? this._template(props, set, get) : this._template;
            const parent = RendererServiceLocator.current.appendChild(this.renderRoot, tpl) as ShadowRoot;
            const templateRootElement = parent.children.item(0) as TElement;
            const ofElement = (typeof this._template === 'function' ? getSetElement : templateRootElement) ?? templateRootElement;

            if (this._properties && this._properties.length > 0 && ofElement) {
                this._properties.forEach((x) => {
                    if (x.value !== undefined && x.key !== undefined && x.key !== 'style' && x.key !== 'class') {
                        ObjectExtensions.set(ofElement, x.key, x.value);
                    }
                });
            }

            // subscribe to events
            if (this._events && this._events.length > 0) {
                this._events.forEach((x) => {
                    this._ofElementEventSubscriptions.push(on(ofElement, x.name as any, (e) => {
                        // only trigger events when the sender directly is the ofElement.
                        if (e.target === ofElement) {
                            this.onEventTriggered({ originalEvent: e });
                        }
                    }));
                });
            }

            this._ofElement = ofElement;
            this.emit('connected');
        }
    }

    /**
     * Adjust the view port zoom percentage related to the current size of the element.
     * It will only adjust the zoom if the view port is set and the view port is a valid css length; otherwise it will return 100.
     *
     * @private
     */
    private tryAdjustZoom(): number {
        if (this._viewPort && CssLength.isLength(this._viewPort.viewPort.width) && CssLength.isLength(this._viewPort.viewPort.height)) {
            const { width, height } = this.getBoundingClientRect();
            const { viewPort } = this._viewPort;
            const cssWidth = CssLength.isLength(viewPort.width) ? CssLength.extractValue(viewPort.width) : 0;
            const cssHeight = CssLength.isLength(viewPort.height) ? CssLength.extractValue(viewPort.height) : 0;

            const zoom = Math.min(width / cssWidth, height / cssHeight) * 100;
            return zoom;
        }

        return 100;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-playground': PlaygroundElement;
    }
}
