
// #region Imports

import { AnimationPresets, Appearance, Component, DialogElement, FlowDirection, TextFormatters, Variant, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { dialogElementDocs } from '../../../.Generated/mosaik-dialog';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { animationTemplate, cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DialogSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-dialog-sample'
})
export class DialogSampleElement extends SampleBaseElement<DialogElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DialogElement> {
        return {
            header: 'Dialog',
            description: META.description(DialogElement.is),
            graph: META.graph(DialogElement.is),
            playground: {
                template: (_props, set, get) => html`
                    <mosaik-dialog ${ref((el) => set(el as DialogElement))}>
                        <mosaik-dialog-header>
                            <mosaik-dialog-header-text .text="${'Network Connection'}"
                                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-dialog-header-text>
                            <mosaik-dialog-header-sub-text .text="${'We detected that you are currently *offline*. Do you want to turn the Wi-Fi connection on?'}"
                                                        .formatter="${TextFormatters.RICHTEXT}"></mosaik-dialog-header-sub-text>
                        </mosaik-dialog-header>
                        <mosaik-dialog-content>
                            <mosaik-text .text="${'Enabling Wi-Fi offers numerous advantages: Access a wide range of online resources, stay connected with messaging and video calls, enjoy streaming and social media, facilitate remote work and learning, receive real-time updates and notifications, improve navigation with maps and location services, control smart home devices, and save on mobile data usage. Connect now to enjoy these benefits.'}"
                                        .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>
                        </mosaik-dialog-content>
                        <mosaik-dialog-actions>
                            <mosaik-button .label="${'Cancel'}"
                                            .variant="${Variant.Primary}"
                                            .appearance="${Appearance.Plain}"
                                            @click="${() => get()?.close()}"></mosaik-button>
                            <mosaik-button .label="${'OK'}"
                                            .variant="${Variant.Primary}"
                                            .appearance="${Appearance.Soft}"
                                            @click="${() => get()?.close()}"></mosaik-button>
                        </mosaik-dialog-actions>
                    </mosaik-dialog>
                `,
                properties: META.properties<DialogElement>(DialogElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'enter',
                        value: AnimationPresets.FadeSlideIn
                    },
                    {
                        key: 'exit',
                        value: AnimationPresets.FadeOut
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'height',
                        value: '220px'
                    },
                    {
                        key: 'width',
                        value: '380px'
                    },
                    {
                        key: 'pressEscapeToClose',
                        value: true
                    },
                    {
                        key: 'clickOutsideToClose',
                        value: true
                    },
                    {
                        key: 'isDraggable',
                        value: true
                    }
                ]),
                events: META.events(DialogElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<DialogElement>(DialogElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'enter',
                        value: AnimationPresets.FadeSlideIn
                    },
                    {
                        key: 'exit',
                        value: AnimationPresets.FadeOut
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'height',
                        value: '220px'
                    },
                    {
                        key: 'width',
                        value: '380px'
                    },
                    {
                        key: 'pressEscapeToClose',
                        value: true
                    },
                    {
                        key: 'clickOutsideToClose',
                        value: true
                    },
                    {
                        key: 'isDraggable',
                        value: true
                    }
                ]), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'enter',
                        template: animationTemplate
                    },
                    {
                        key: 'exit',
                        template: animationTemplate
                    }
                ] as Array<IPlaygroundProperty<DialogElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                dialogElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-dialog-sample': DialogSampleElement;
    }
}
