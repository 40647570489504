// #region Imports

import { CSSResultGroup, Component, FlowDirection, RippleElement, Variant, html } from '@breadstone/mosaik-elements-foundation';
import { rippleElementDocs } from '../../../.Generated/mosaik-ripple';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { timeTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link RippleSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-ripple-sample'
})
export class RippleSampleElement extends SampleBaseElement<RippleElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<RippleElement> {
        return {
            header: 'Ripple',
            description: META.description(RippleElement.is),
            graph: META.graph(RippleElement.is),
            playground: {
                template: (props, set, _get) => html`
                    <div id="my-id"
                         style="background: black; width: 320px; height: 120px; position: relative">
                        <mosaik-ripple .for="${'my-id'}"
                                       .centered="${props.get('centered').value}"
                                       .disabled="${props.get('disabled').value}"
                                       .duration="${props.get('duration').value}"
                                       .variant="${props.get('variant').value}"></mosaik-ripple>
                        <mosaik-text .text="${'Click me'}"></mosaik-text>
                    </div>
                    <div id="lalalla"></div>
                `,
                properties: META.properties<RippleElement>(RippleElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'variant',
                        value: Variant.Primary
                    }, {
                        key: 'centered',
                        value: false
                    }, {
                        key: 'duration',
                        value: 600
                    }
                ]),
                events: META.events(RippleElement.is)
            },
            propertyGrid: {
                exludedProperties: ['control', 'for'],
                properties: merge(META.properties<RippleElement>(RippleElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'variant',
                        value: Variant.Primary
                    }, {
                        key: 'centered',
                        value: false
                    }, {
                        key: 'duration',
                        value: 600
                    }
                ]), [{
                    key: 'duration',
                    template: timeTemplate
                }] as Array<IPlaygroundProperty<RippleElement>>, (a, b) => a.key === b.key)
            },
            docs: [rippleElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-ripple-sample': RippleSampleElement;
    }
}
