//#region Imports

import { Appearance, Variant, html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type CaptchaElement } from './CaptchaElement';

//#endregion

/**
 * The template of the {@link CaptchaElement}.
 *
 * @public
 */
export function captchaElementTemplate<T extends CaptchaElement>(e: T): TemplateResult {
    return html`
        <mosaik-text part="question"
                     .text="${e.question}"></mosaik-text>
        <mosaik-stack .orientation="${'horizontal'}"
                      .gap="${'16px'}">
            ${e.options.map(option => html`
            <mosaik-button part="button"
                        .label="${option.toString()}"
                        .value="${option}"
                        .appearance="${Appearance.Solid}"
                        .variant="${Variant.Primary}"
                        @click="${() => e.validate(option)}"></mosaik-button>
            `)}
        </mosaik-stack>
    `;
}
