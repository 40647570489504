// #region Imports

import { Animate, Appearance, Component, HorizontalAlignment, Inset, Orientation, Size, TextFormatters, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, VerticalAlignment, animate, flex, repeat, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { CodeEditorLanguages } from '../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../Components/CodeEditor/CodeEditorTheme';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { colorCode } from './ColorView.Snippets';

// #endregion

/**
 * The `{@link ColorView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-color-view'
})
export class ColorView extends ViewBase {

    // #region Fields

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly COLORS: Array<string> = ['primary', 'secondary', 'tertiary', 'danger', 'warning', 'success', 'info', 'highlight', 'gray'];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly VARIANTS: Array<string> = ['0', '50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-color-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css`
                :host mosaik-dot {
                    cursor: pointer;
                }

                :host [part=colors] [part=color] ~ [part=color] {
                    margin-left: -8px;
                }

                :host [part=colors].lt-md [part=color] ~ [part=color] {
                    margin-left: -16px;
                }
            `
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.color')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                 <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/color'}" .label="${translate('loc.nav.color')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.color')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'CSS color palettes and their shades play a crucial role in defining the visual identity and design consistency of a website or application. They help maintain a cohesive and harmonious look across different elements and user interfaces. Let us explore the general concept of CSS color palettes and their shades using the example you provided.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'A color palette is a predefined set of colors that are carefully chosen to work well together. Each color in the palette serves a specific purpose or represents a particular style or meaning. In your case, the color palettes you mentioned are *primary*, *secondary*, *tertiary*, *info*, *warning*, *danger*, *success*, *highlight*, and *gray*.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-banner .variant="${Variant.Info}"
                                   .appearance="${Appearance.Soft}"
                                   .icon="${'M12 1.999c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999Zm-.004 8.25a1 1 0 0 0-.992.885l-.007.116.003 5.502.007.117a1 1 0 0 0 1.987-.002L13 16.75l-.003-5.501-.007-.117a1 1 0 0 0-.994-.882ZM12 6.5a1.251 1.251 0 1 0 0 2.503A1.251 1.251 0 0 0 12 6.5Z'}"
                                   .header="${'Pro tip'}"
                                   .subHeader="${'The colored dots are clickable 🎉. Clicking on a dot automatically copies its color for pasting elsewhere.'}"></mosaik-banner>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-stack orientation="${Orientation.Vertical}">
                        ${repeat(this.COLORS, (x) => x, (x) => html`
                        <mosaik-stack .orientation="${this.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                      .gap="${'16px'}">
                            <mosaik-stack ${flex({ fill: true })}
                                          .orientation="${Orientation.Vertical}">
                                <mosaik-text .text="${x}"></mosaik-text>
                                <mosaik-text .text="\`\`\`--theme-${x}-{n}-color\`\`\`"
                                             .formatter="${TextFormatters.RICHTEXT}"
                                             ${typography('body2')}></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack part="colors"
                                          class="${this.isMobile ? 'lt-md' : ''}"
                                          .orientation="${Orientation.Horizontal}">
                                ${repeat(this.VARIANTS, (y) => y, (y) => html`
                                <mosaik-stack part="color"
                                              .orientation="${Orientation.Vertical}"
                                              .verticalAlignment="${VerticalAlignment.Center}"
                                              .horizontalAlignment="${HorizontalAlignment.Center}">
                                    <mosaik-dot size="${Size.Giant}"
                                            style="background: var(--theme-${x}-${y}-color);"
                                            @click="${() => this.onCopy(x, y)}"></mosaik-dot>
                                    <mosaik-spacer thickness="top" size="${Size.Tiny}"></mosaik-spacer>
                                    <mosaik-text .text="${y}"
                                                 ${typography('caption')}></mosaik-text>
                                </mosaik-stack>
                                `)}
                            </mosaik-stack>
                        </mosaik-stack>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        `)}
                    </mosaik-stack>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'The *primary* color palette typically consists of the dominant colors that represent the main brand identity or theme of a website or application. These colors often appear in prominent elements such as headers, buttons, and important call-to-action elements.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'The *secondary* and *tertiary* color palettes are complementary to the *primary* palette and provide additional options for enhancing the visual hierarchy and differentiation of different UI elements. They can be used for backgrounds, borders, and other supporting visual elements.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'The *info*, *warning*, *danger*, and *success* color palettes are often used to convey different types of messages or states to the users. For example, the info color may be used to indicate informational content, while the warning color can signify potential issues or warnings.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'The *highlight* color palette is useful for drawing attention to specific elements or for emphasizing interactive or clickable elements such as links or buttons.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'Lastly, the *gray* color palette provides a range of shades of gray, which are commonly used for backgrounds, borders, and text colors. gray shades can help create visual contrast and add depth to the overall design.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'Now let us discuss the color shade variations you mentioned: *50*, *100*, *200*, *300*, *400*, *500*, *600*, *700*, *800*, and *900*. These numbers represent different levels of intensity or darkness within each color palette. Typically, lower numbers(e.g., *50* or *100*) indicate lighter shades, while higher numbers(e.g., *800* or *900*) represent darker shades. The middle value(*500*) is often the default or base color of the palette.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text ="${'By using different shade variations within a color palette, you can create subtle variations and establish a consistent hierarchy within your design. Lighter shades are commonly used for backgrounds or less prominent elements, while darker shades can be applied to add contrast or emphasis to important components.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text ="${'In CSS, you can refer to these colors and their shade variations using their respective names and numeric values. For example, you can set the *primary* color to ```primary-500-color``` or the secondary color to ```secondary-200-color``` to achieve a specific shade within the defined color palettes.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text ="${'Overall, CSS color palettes and their shade variations allow designers and developers to maintain visual consistency, create hierarchy, and convey meaning throughout the user interface, resulting in a polished and cohesive design.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <app-code-editor .language="${CodeEditorLanguages.CSS}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                     .text="${colorCode}"
                                     .readonly="${true}"></app-code-editor>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    /**
     * @private
     */
    private onCopy(color: string, tone: string): void {
        void navigator.clipboard.writeText(`--theme-${color}-${tone}-color`).then(() => {
            void ToastServiceLocator.current.open({
                variant: Variant.Success,
                content: TranslatorServiceLocator.current.translate('Color copied to clipboard 🎉.'),
                timeout: Toast2.Timeout.SHORT
            });
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-color-view': ColorView;
    }
}
