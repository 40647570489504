// #region Imports

import { Attribute, Component, CustomElement, Property, css, html, type CSSResultGroup, type IConnectedCallback, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import hljs from 'highlight.js';
import { highlightElementStyle } from './HighlightElementStyle';

// #endregion

/**
 * Represents the `IHighlightElementProps` interface.
 *
 * @public
 */
export interface IHighlightElementProps {

    // #region Properties

    text: string;

    language: string;

    editable: boolean;

    // #endregion

}

/**
 * The `{@link HighlightElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-highlight'
})
export class HighlightElement extends CustomElement implements IConnectedCallback {

    // #region Fields

    private _text: string;
    private _editable: boolean;
    private _language: string;
    private _indentSize: number;

    // #endregion

    // #region Ctor

    public constructor() {
        super();

        this._text = '';
        this._editable = false;
        this._language = '';
        this._indentSize = 4;
    }

    // #endregion

    // #region Properites

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-highlight';
    }

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return css`
            :host {
                display: block;
                overflow: hidden;
            }

            :host pre {
                margin: 0;
                white-space: normal;
            }

            ${highlightElementStyle()}
        `;
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    /**
     * Gets or sets the `language` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get language(): string {
        return this._language;
    }

    public set language(value: string) {
        if (this._language !== value) {
            this._language = value;
            this.requestUpdate('language');
        }
    }

    /**
     * Gets or sets the `editable` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get editable(): boolean {
        return this._editable;
    }

    public set editable(value: boolean) {
        if (this._editable !== value) {
            this._editable = value;
            this.requestUpdate('editable');
        }
    }

    /**
     * Gets or sets the `indentSize` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get indentSize(): number {
        return this._indentSize;
    }

    public set indentSize(value: number) {
        if (this._indentSize !== value) {
            this._indentSize = value;
            this.requestUpdate('indentSize');
        }
    }

    // #endregion

    // #region Methods

    public override connectedCallback(): void {
        super.connectedCallback();

        hljs.configure({
            classPrefix: '',
            cssSelector: ''
        });

        if (hljs.listLanguages().length < 1) {
            throw new Error('[HighlightJS]: No languages were registered!');
        }
    }

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-text part="language" .text="${this.language}"></mosaik-text>
            <pre>
                <code class="editable"
                      spellcheck="false"
                      .innerHTML="${this.highlight()}"
                      ?contentEditable="${this.editable}"
                      @input="${() => this.onCodeChanged()}"></code>
            </pre>
        `;
    }

    private highlight(): string {
        const preformattedCode = this.format(this.text);
        const result = hljs.highlight(preformattedCode, { language: this._language });
        return result.value;
    }

    private onCodeChanged(): void {
        this.requestUpdate();
    }

    private format(code: string): string {
        let result = '';
        // result = code;
        // variant 1
        // result = code.replace(/^(.*)$/gm, (match, p1) => ' '.repeat(this._indentSize) + p1.trim());
        // variant 2
        // result = formatHtmlString(code, this._indentSize);

        // default variant
        result = code;

        return result;
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-highlight': HighlightElement;
    }
}
