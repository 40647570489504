// #region Imports

import { Injectable } from '@breadstone/mosaik-elements-foundation';

// #endregion

/**
 * The `GraphService` class.
 *
 * @public
 */
@Injectable()
export class GraphService {

    // #region Ctor

    /**
     * Constructs a new instance of the `GraphService` class.
     *
     * @public
     */
    public constructor() {

    }

    // #endregion

    // #region Methods

    public renderGraph(def: {
        mixinClasses: Array<string>;
        baseClass: string;
        class: string;
    }): string {
        const graph = new Graph();

        const baseNode = new Node('base', def.baseClass);
        const elementNode = new Node('element', def.class);
        const mixinNodes = def.mixinClasses.map((m, i) => new Node(String.fromCharCode(97 + i), m));

        graph.addNode(baseNode);
        graph.addNode(elementNode);
        mixinNodes.forEach((node) => {
            graph.addNode(node);
        });

        graph.addEdge(new Edge(baseNode, elementNode));
        mixinNodes.forEach((node) => {
            graph.addEdge(new Edge(node, elementNode));
        });

        return graph.toString();
    }

    // #endregion

}

/**
 * Interface representing a graph node
 */
interface INode {
    id: string;
    label: string;
    toString(): string;
}

/**
 * Interface representing a graph edge
 */
interface IEdge {
    source: INode;
    target: INode;
    label?: string;
    toString(): string;
}

/**
 * Interface representing a graph
 */
interface IGraph {
    addNode(node: INode): void;
    removeNode(nodeId: string): void;
    addEdge(edge: IEdge): void;
    removeEdge(edge: IEdge): void;
    findNode(nodeId: string): INode | undefined;
    findEdge(sourceId: string, targetId: string): IEdge | undefined;
    getNodes(): Array<INode>;
    getEdges(): Array<IEdge>;
    toString(): string;
}

/**
 * Class representing a graph node
 */
class Node implements INode {

    public constructor(id: string, label: string) {
        this.id = id;
        this.label = label;
    }

    public id: string;
    public label: string;

    /**
     * Returns the Mermaid representation of the node
     * @returns Mermaid node definition
     */
    public toString(): string {
        return `${this.id}(${this.label})`;
    }

}

/**
 * Class representing a graph edge
 */
class Edge implements IEdge {

    public constructor(source: INode, target: INode, label?: string) {
        this.source = source;
        this.target = target;
        this.label = label;
    }

    public source: INode;
    public target: INode;
    public label?: string;

    /**
     * Returns the Mermaid representation of the edge
     * @returns Mermaid edge definition
     */
    public toString(): string {
        return this.label ? `${this.source.id} -->|${this.label}| ${this.target.id}` : `${this.source.id} --> ${this.target.id}`;
    }

}

/**
 * Class representing a graph
 */
class Graph implements IGraph {

    private readonly _nodes: Map<string, INode>;
    private readonly _edges: Set<IEdge>;

    // #region Ctor

    /**
     * Constructs a new instance of the `GraphService` class.
     *
     * @public
     */
    public constructor() {
        this._nodes = new Map<string, INode>();
        this._edges = new Set<IEdge>();
    }

    // #endregion

    /**
     * Adds a node to the graph
     * @param node Node to be added
     */
    public addNode(node: INode): void {
        this._nodes.set(node.id, node);
    }

    /**
     * Removes a node from the graph
     * @param nodeId ID of the node to be removed
     */
    public removeNode(nodeId: string): void {
        const node = this._nodes.get(nodeId);
        if (node) {
            this._edges.forEach((edge) => {
                if (edge.source.id === nodeId || edge.target.id === nodeId) {
                    this._edges.delete(edge);
                }
            });
            this._nodes.delete(nodeId);
        }
    }

    /**
     * Adds an edge to the graph
     * @param edge Edge to be added
     */
    public addEdge(edge: IEdge): void {
        if (this._nodes.has(edge.source.id) && this._nodes.has(edge.target.id)) {
            this._edges.add(edge);
        } else {
            throw new Error('Both nodes must be part of the graph');
        }
    }

    /**
     * Removes an edge from the graph
     * @param edge Edge to be removed
     */
    public removeEdge(edge: IEdge): void {
        this._edges.delete(edge);
    }

    /**
     * Finds a node by its ID
     * @param nodeId ID of the node to be found
     * @returns The node if found, undefined otherwise
     */
    public findNode(nodeId: string): INode | undefined {
        return this._nodes.get(nodeId);
    }

    /**
     * Finds an edge by its source and target node IDs
     * @param sourceId Source node ID
     * @param targetId Target node ID
     * @returns The edge if found, undefined otherwise
     */
    public findEdge(sourceId: string, targetId: string): IEdge | undefined {
        return Array.from(this._edges).find((edge) => edge.source.id === sourceId && edge.target.id === targetId);
    }

    /**
     * Gets all nodes in the graph
     * @returns Array of nodes
     */
    public getNodes(): Array<INode> {
        return Array.from(this._nodes.values());
    }

    /**
     * Gets all edges in the graph
     * @returns Array of edges
     */
    public getEdges(): Array<IEdge> {
        return Array.from(this._edges);
    }

    /**
     * Generates the Mermaid diagram representation of the graph
     * @returns Mermaid diagram definition
     */
    public toString(): string {
        let diagram = 'graph TD\n';
        this._nodes.forEach((node) => {
            diagram += `    ${node.toString()}\n`;
        });
        this._edges.forEach((edge) => {
            diagram += `    ${edge.toString()}\n`;
        });
        return diagram;
    }

}
