// #region Imports

import { Component, IntersectionController } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../../Abstracts/ViewBase';
import { argumentViewStyle } from './ArgumentViewStyle';
import { argumentViewTemplate } from './ArgumentViewTemplate';

// #endregion

interface IArgInfo {
    emoji: string;
    title: string;
    content: string;
}

/**
 * The `{@link ArgumentView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-argument-view',
    template: argumentViewTemplate,
    styles: argumentViewStyle
})
export class ArgumentView extends ViewBase {

    // #region Fields

    private readonly _intersectionController: IntersectionController;
    private readonly _args: Array<IArgInfo>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._args = new Array<IArgInfo>(
            {
                emoji: '1F3A8',
                title: 'loc.landing.arguments.1.title',
                content: 'loc.landing.arguments.1.content'
            },
            {
                emoji: '1F310',
                title: 'loc.landing.arguments.2.title',
                content: 'loc.landing.arguments.2.content'
            },
            {
                emoji: '1F559',
                title: 'loc.landing.arguments.3.title',
                content: 'loc.landing.arguments.3.content'
            },
            {
                emoji: '1F6E0',
                title: 'loc.landing.arguments.4.title',
                content: 'loc.landing.arguments.4.content'
            },
            // {
            //     emoji: '1F680',
            //     title: 'loc.landing.arguments.5.title',
            //     content: 'loc.landing.arguments.5.content'
            // },
            {
                emoji: '1FAE7',
                title: 'loc.landing.arguments.6.title',
                content: 'loc.landing.arguments.6.content'
            }
        );

        this._intersectionController = new IntersectionController(this, {
            config: {
                threshold: 1
            },
            callback: (entry) => {
                entry.forEach((e) => {
                    if (e.isIntersecting) {
                        e.target.classList.add('visible');
                    } else {
                        e.target.classList.remove('visible');
                    }
                });
            }
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-argument-view';
    }

    /**
     * Returns the `args` property.
     *
     * @public
     * @readonly
     */
    public get args(): Array<IArgInfo> {
        return this._args;
    }

    // #endregion

    // #region Methods

    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        this.getTemplateParts('arg').forEach((e) => {
            this._intersectionController.observe(e);
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-landing-argument-view': ArgumentView;
    }
}
