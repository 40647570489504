// #region Imports

import { Component, FlowDirection, PaginatorElement, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { paginatorElementDocs } from '../../../.Generated/mosaik-paginator';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link PaginatorSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-paginator-sample'
})
export class PaginatorSampleElement extends SampleBaseElement<PaginatorElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<PaginatorElement> {
        return {
            header: 'Paginator',
            description: META.description(PaginatorElement.is),
            graph: META.graph(PaginatorElement.is),
            playground: {
                template: (_props, set, get) => html`
                    <mosaik-paginator ${ref((el) => set(el as PaginatorElement))}></mosaik-paginator>
                `,
                properties: META.properties<PaginatorElement>(PaginatorElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'size',
                        value: 20
                    }, {
                        key: 'total',
                        value: 100
                    }
                ]),
                events: META.events(PaginatorElement.is)
            },
            propertyGrid: {
                exludedProperties: ['intl'],
                properties: META.properties<PaginatorElement>(PaginatorElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'size',
                        value: 20
                    }, {
                        key: 'total',
                        value: 100
                    }
                ])
            },
            docs: [
                paginatorElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-paginator-sample': PaginatorSampleElement;
    }
}
