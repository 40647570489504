//#region Imports

import { Appearance, Component, FlowDirection, SearchBoxElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { searchboxElementDocs } from '../../../.Generated/mosaik-searchbox';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link SearchBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-searchbox-sample'
})
export class SearchBoxSampleElement extends SampleBaseElement<SearchBoxElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SearchBoxElement> {
        return {
            header: 'Search Box',
            description: META.description(SearchBoxElement.is),
            graph: META.graph(SearchBoxElement.is),
            playground: {
                template: html`
                    <mosaik-searchbox></mosaik-searchbox>
                `,
                properties: META.properties<SearchBoxElement>(SearchBoxElement.is, [
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'value', value: 'Value' },
                    { key: 'mode', value: 'input' },
                ]),
                events: META.events(SearchBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<SearchBoxElement>(SearchBoxElement.is, [
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'value', value: 'Value' },
                    { key: 'mode', value: 'input' },
                ]), [
                    { key: 'maxDropDownHeight', template: cssLengtTemplate },
                    { key: 'formatter', template: functionTemplate },
                    { key: 'parser', template: functionTemplate },
                ] as Array<IPlaygroundProperty<SearchBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                searchboxElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-searchbox-sample': SearchBoxSampleElement;
    }
}

