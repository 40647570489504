// #region Imports

import { Component, Fit, FlowDirection, HorizontalAlignment, ImageElement, ImageFit, ImageLegendPosition, Size, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { imageElementDocs } from '../../../.Generated/mosaik-image';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ImageSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-image-sample'
})
export class ImageSampleElement extends SampleBaseElement<ImageElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ImageElement> {

        return {
            header: 'Image',
            description: META.description(ImageElement.is),
            graph: META.graph(ImageElement.is),
            playground: {
                template: [[{
                    name: 'Default',
                    description: 'The default template'
                }, html`
                    <mosaik-image></mosaik-image>
                `], [{
                    name: 'With custom empty state',
                    description: 'The image with a legend'
                }, html`
                    <mosaik-image>
                        <mosaik-stack slot="empty"
                                        .fit="${Fit.Both}"
                                        .verticalAlignment="${VerticalAlignment.Center}"
                                        .horizontalAlignment="${HorizontalAlignment.Center}"
                                        style="${'background: var(--theme-semi-transparent-color);'}">
                            <mosaik-icon .data="${'M0 12v-8.646l10-1.355v10.001h-10zm11 0h13v-12l-13 1.807v10.193zm-1 1h-10v7.646l10 1.355v-9.001zm1 0v9.194l13 1.806v-11h-13z'}"
                                            .size="${Size.Large}"></mosaik-icon>
                        </mosaik-stack>
                    </mosaik-image>
                `]],
                properties: META.properties<ImageElement>(ImageElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    },
                    {
                        key: 'width',
                        value: '200px'
                    },
                    {
                        key: 'height',
                        value: '300px'
                    },
                    {
                        key: 'fit',
                        value: ImageFit.Cover
                    },
                    {
                        key: 'legend',
                        value: 'The legend of the image'
                    },
                    {
                        key: 'legendPosition',
                        value: ImageLegendPosition.Bottom
                    },
                    {
                        key: 'showPending',
                        value: true
                    },
                    {
                        key: 'showEmpty',
                        value: true
                    },
                    {
                        key: 'showFailed',
                        value: true
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'ratio',
                        value: 'auto'
                    },
                    {
                        key: 'alt',
                        value: 'some alt text...'
                    }
                ]),
                events: META.events<ImageElement>(ImageElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<ImageElement>(ImageElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    },
                    {
                        key: 'width',
                        value: '200px'
                    },
                    {
                        key: 'height',
                        value: '300px'
                    },
                    {
                        key: 'fit',
                        value: ImageFit.Cover
                    },
                    {
                        key: 'legend',
                        value: 'The legend of the image'
                    },
                    {
                        key: 'legendPosition',
                        value: ImageLegendPosition.Bottom
                    },
                    {
                        key: 'showPending',
                        value: true
                    },
                    {
                        key: 'showEmpty',
                        value: true
                    },
                    {
                        key: 'showFailed',
                        value: true
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'ratio',
                        value: 'auto'
                    },
                    {
                        key: 'alt',
                        value: 'some alt text...'
                    }
                ]), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'ratio',
                        template: cssLengtTemplate
                    }
                ] as Array<IPlaygroundProperty<ImageElement>>, (a, b) => a.key === b.key), [
                    { key: 'state' }
                ] as Array<IPlaygroundProperty<ImageElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                imageElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-image-sample': ImageSampleElement;
    }
}
