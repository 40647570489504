// #region Imports

import { Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { ContentsService } from '../../Backend/Api/Services/ContentsService';
import { CouponElement } from '../../Components/Coupon/CouponElement';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { storyViewStyle } from './StoryViewStyle';
import { storyViewTemplate } from './StoryViewTemplate';

// #endregion

/**
 * The `{@link StoryView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-story-view',
    template: storyViewTemplate,
    styles: [
        viewBaseStyle(),
        storyViewStyle()
    ],
    imports: [
        CouponElement
    ],
    providers: [
        {
            provide: ContentsService,
            useClass: ContentsService
        }
    ]
})
export class StoryView
    extends ViewBase {

    // #region Fields

    @Inject(ContentsService)
    private readonly _contentsService!: ContentsService;

    private _content: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._content = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-story-view';
    }

    /**
     * Gets or sets the `content` property.
     *
     * @public
     */
    @Property({ type: String })
    public get content(): string {
        return this._content;
    }

    public set content(value: string) {
        if (this._content !== value) {
            this._content = value;
            this.requestUpdate('content');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();
    }

    /**
     * @private
     */
    private initialize(): void {
        this.isBusy = true;

        void this._contentsService
            .getContents({
                kind: 'whatItIs',
                lang: this.lang as Parameters<ContentsService['getContents']>[0]['lang']
            })
            .then((x) => {
                this.content = x.content;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-story-view': StoryView;
    }
}
