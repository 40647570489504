//#region Imports

import { Appearance, Component, FlowDirection, LabelPosition, ToggleSwitchElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { toggleSwitchElementDocs } from '../../../.Generated/mosaik-toggle-switch';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { booleanTemplate, nullableBooleanTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link ToggleSwitchSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-toggle-switch-sample'
})
export class ToggleSwitchSampleElement extends SampleBaseElement<ToggleSwitchElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ToggleSwitchElement> {
        return {
            header: 'Toggle Switch',
            description: META.description(ToggleSwitchElement.is),
            graph: META.graph(ToggleSwitchElement.is),
            playground: {
                template: html`
                    <mosaik-toggle-switch></mosaik-toggle-switch>
                `,
                properties: META.properties<ToggleSwitchElement>(ToggleSwitchElement.is, [
                    { key: 'label', value: 'Label', },
                    { key: 'checked', value: true },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'labelPosition', value: LabelPosition.After, }
                ]),
                events: META.events(ToggleSwitchElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ToggleSwitchElement>(ToggleSwitchElement.is, [
                    { key: 'dir', value: FlowDirection.Auto, },
                    { key: 'label', value: 'Label', },
                    { key: 'labelPosition', value: LabelPosition.After, },
                    { key: 'checked', value: true },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary }
                ]), [
                    { key: 'isChecked', template: nullableBooleanTemplate },
                    { key: 'value', template: booleanTemplate }
                ] as Array<IPlaygroundProperty<ToggleSwitchElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                toggleSwitchElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-toggle-switch-sample': ToggleSwitchSampleElement;
    }
}

