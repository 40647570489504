//#region Imports

import { Orientation, html, typography, until, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type Package } from 'custom-elements-manifest/schema';
import { getCustomElements, getElementData, getPublicFields, getPublicMethods, hasCustomElements } from './ApiDocs';
import { type ApiDocsElement } from './ApiDocsElement';

//#endregion

/**
 * The template of the {@link ApiDocsElement}.
 *
 * @public
 */
export function apiDocsElementTemplate<T extends ApiDocsElement>(e: T): TemplateResult {
    return html`
        ${until(renderDocs(e.data))}
    `;
}

/**
 * @private
 */
async function renderDocs(x: Promise<Package | null>): Promise<TemplateResult> {
    const manifest = await x;

    if (!hasCustomElements(manifest)) {
        return html`<div>There are no custom elements in this package.</div>`;
    }

    const elements = getCustomElements(manifest);
    const data = getElementData(manifest, elements);

    if (!data) {
        return html`<div>There are no custom elements in this package.</div>`;
    }

    const props = getPublicFields(data.members);
    const methods = getPublicMethods(data.members);

    return html`
        <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'16px'}">
            <mosaik-text ${typography('headline4')}
                         .text="${data.name}"
                         ?wrap="${true}"></mosaik-text>
            <mosaik-text ${typography('headline6')}
                         .text="${data.description ?? ''}"
                         ?wrap="${true}"></mosaik-text>
            <app-api-docs-layout part="docs-container"
                                .name="${data.name}"
                                .properties="${props}"
                                .attrs="${data.attributes ?? []}"
                                .methods="${methods}"
                                .events="${data.events ?? []}"
                                .slots="${data.slots ?? []}"
                                .cssParts="${data.cssParts ?? []}"
                                .cssProperties="${data.cssProperties ?? []}"></app-api-docs-layout>
        </mosaik-stack>
    `;
}
