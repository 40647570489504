// #region Imports

import { Animate, Appearance, Component, Inset, TextFormatters, Variant, animate, interpolate, translate } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { CodeEditorLanguages } from '../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../Components/CodeEditor/CodeEditorTheme';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { cssCode, htmlCode, tsCode } from './BreakpointView.Snippets';

// #endregion

/**
 * The `{@link BreakpointView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-breakpoint-view'
})
export class BreakpointView extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-breakpoint-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.breakpoint')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md"
                         ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}"
                                           .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/breakpoint'}"
                                           .label="${translate('loc.nav.breakpoint')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.breakpoint')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <div>
                        <mosaik-text ?wrap="${true}"
                                    .formatter="${TextFormatters.RICHTEXT}"
                                    .text="${'In CSS, breakpoints refer to specific screen widths or ranges where the layout of a web page changes to accommodate different screen sizes. These breakpoints are defined using media queries and allow developers to create responsive designs that adapt to various devices.'}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                    .formatter="${TextFormatters.RICHTEXT}"
                                    .text="${interpolate('When it comes to *{0}* breakpoints, we can think of them as the points at which the layout of a web page adjusts to display a *{0}*-like design. Just like a *{0}* artwork consists of small, distinct pieces coming together to form a larger image, a *{0}* breakpoint in CSS involves breaking down the design into smaller components that rearrange themselves based on the available screen space.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                    .formatter="${TextFormatters.RICHTEXT}"
                                    .text="${interpolate('By utilizing *{0}* breakpoints, web designers can create visually appealing and user-friendly layouts that seamlessly adapt to different devices. For example, at smaller screen sizes, elements may stack vertically or shift position to ensure readability and accessibility. As the screen size increases, the *{0}*-like pieces come together to form a cohesive design that maximizes the use of available space.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                    .formatter="${TextFormatters.RICHTEXT}"
                                    .text="${interpolate('To implement *{0}* breakpoints in CSS, developers typically use media queries. Media queries allow them to specify different styles and layout rules based on the screen width or other device characteristics. By targeting specific screen ranges, developers can apply different CSS rules to create the desired *{0}* effect.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                            <mosaik-tab-item .label="${'CSS'}"
                                             .isSelected="${true}">
                                <app-code-editor .language="${CodeEditorLanguages.CSS}"
                                                 .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${cssCode}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'HTML'}">
                                <app-code-editor .language="${CodeEditorLanguages.HTML}"
                                                 .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${htmlCode}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'TS'}">
                                <app-code-editor .language="${CodeEditorLanguages.TYPESCRIPT}"
                                                 .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${tsCode}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                        </mosaik-tab>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     .text="${interpolate('In this example, the CSS code defines different styles for three ranges of screen widths: up to 600px, between 601px and 1200px, and above 1200px. Within each range, the *{0}* elements can have their own specific styles and layout properties.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     .text="${interpolate('By incorporating *{0}* breakpoints in CSS, developers can create responsive designs that adapt to various screen sizes, providing an optimal user experience across different devices.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text ?wrap="${true}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     .text="${interpolate('By incorporating *{0}* breakpoints in CSS, developers can create responsive designs that adapt to various screen sizes, providing an optimal user experience across different devices.', globals.name)}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <a href="./images/layout_adaptive_breakpoints.png"
                           target="_blank">
                            <img src="./images/layout_adaptive_breakpoints.png"
                                alt="Breakpoints as specified in the Material Design Spec"
                                style="max-width:100%;">
                        </a>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-text .text="${'The Breakpoints are strictly oriented by Material Design'}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        <mosaik-table columns="1fr 1fr">
                            <mosaik-table-header>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="Breakpoint key"></mosaik-table-cell>
                                    <mosaik-table-cell text="Media query (pixel range)"></mosaik-table-cell>
                                </mosaik-table-row>
                            </mosaik-table-header>
                            <mosaik-table-body>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="xs"></mosaik-table-cell>
                                    <mosaik-table-cell text="(max-width: <b>599</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="gt-xs"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>600</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="sm"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>600</b>px) and (max-width: <b>959</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="gt-sm"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>960</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="md"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>960</b>px) and (max-width: <b>1279</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="gt-md"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>1280</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="lg"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>1280</b>px) and (max-width: <b>1919</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="gt-lg"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>1920</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                                <mosaik-table-row>
                                    <mosaik-table-cell text="xl"></mosaik-table-cell>
                                    <mosaik-table-cell text="(min-width: <b>1920</b>px)"></mosaik-table-cell>
                                </mosaik-table-row>
                            </mosaik-table-body>
                        </mosaik-table>
                    </div>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-breakpoint-view': BreakpointView;
    }
}
