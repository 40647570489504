// #region Imports

import { Animate, HorizontalAlignment, Inset, Orientation, Size, Spacing, type TemplateResult, VerticalAlignment, animate, html, repeat, translate, typography, when } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import type { AboutMemberView } from './AboutMemberView';

// #endregion

/**
 * The template of the {@link AboutMemberView}.
 *
 * @public
 */
export function aboutMemberViewTemplate<T extends AboutMemberView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.about')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md"
                     ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/about'}"
                                        .label="${translate('loc.nav.about')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/about'}"
                                       .label="${e.member?.name ?? ''}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content part="content"
                                 .verticalAlignment="${VerticalAlignment.Center}"
                                 .inset="${Inset.All}">
                ${when(!e.isBusy, () => html`
                <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                              .verticalAlignment="${VerticalAlignment.Center}"
                              ?reverse="${!!e.isMobile}"
                              .gap="${'32px'}">
                    <mosaik-stack .orientation="${'vertical'}"
                                  .verticalAlignment="${VerticalAlignment.Center}"
                                  .horizontalAlignment="${HorizontalAlignment.Left}">
                        <mosaik-text ${typography('overline')}
                                     .text="${e.member?.role ?? ''}"></mosaik-text>
                        <mosaik-text ${typography('headline4')}
                                     .text="${e.member?.name ?? ''}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-spacer .size="${Size.Small}"
                                       .thickness="${Spacing.Top}"></mosaik-spacer>
                        <mosaik-text .text="${(e.member?.description as any)[e.lang] ?? ''}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-spacer .size="${Size.Small}"
                                       .thickness="${Spacing.Top}"></mosaik-spacer>
                        <mosaik-text .text="${translate('loc.about.team.member.hashTag')}"></mosaik-text>
                        <mosaik-text .text="${e.member?.hashtag ?? ''}"></mosaik-text>
                        ${when(e.member?.social, (x) => html`
                        <mosaik-spacer .size="${Size.Small}"
                                       .thickness="${Spacing.Top}"></mosaik-spacer>
                        <mosaik-stack .orientation="${Orientation.Horizontal}"
                                      .gap="${'16px'}">
                            ${repeat(Object.entries(x), (y) => html`
                            ${when(y[1], () => html`
                            <app-social-link .type="${y[0]}" .href="${y[1]}"></app-social-link>
                            `)}
                            `)}
                        </mosaik-stack>
                        `)}
                    </mosaik-stack>
                    <mosaik-stack .orientation="${'vertical'}">
                        <mosaik-image .src="${e.member?.image ?? ''}"></mosaik-image>
                    </mosaik-stack>
                </mosaik-stack>
                `, () => html`
                <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                              .horizontalAlignment="${HorizontalAlignment.Center}"
                              .orientation="${Orientation.Vertical}">
                    <mosaik-progress-ring .radius="${24}"
                                          .thickness="${4}"
                                          .isIndeterminate="${true}"></mosaik-progress-ring>
                    <mosaik-text .text="${'Loading...'}"></mosaik-text>
                </mosaik-stack>
                `)}
            </mosaik-page-content>
        </mosaik-page>
    `;
}
