// #region Imports

import { emit, on } from '@breadstone/mosaik-elements-foundation';
import { DirectiveBase, type DirectiveArgs } from '@breadstone/mosaik-elements-foundation/dist/Controls/Directives/Abstracts/DirectiveBase';
import { nothing } from 'lit';
import { PartType, directive } from 'lit/directive.js';

// #endregion

/**
 * The `MouseMovementObserverDirective` class.
 *
 * @public
 */
export class MouseMovementObserverDirective extends DirectiveBase {

    // #region Fields
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `MouseMovementObserverDirective` class.
     *
     * @public
     */
    public constructor(args: DirectiveArgs) {
        super(args);

        if (args.type !== PartType.ELEMENT) {
            throw new Error(
                'The `mouseMovementObserver` directive must be used in attribute position.'
            );
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public render(options?: { delay?: number }): unknown {
        on(this.internals.element, 'mousemove', (event) => setTimeout(() => this.onMouseMove(event), options?.delay));
        on(this.internals.element, 'mouseleave', () => setTimeout(() => this.onMouseLeave(), options?.delay));

        return nothing;
    }

    /**
     * @private
     */
    private onMouseMove(event: MouseEvent): void {
        // IMPORTANT: we need the window object here.
        emit(window, 'mousemovementchange', {
            x: event.clientX,
            y: event.clientY
        });
    }

    /**
     * @private
     */
    private onMouseLeave(): void {
        // IMPORTANT: we need the window object here.
        emit(window, 'mousemovementchange', {
            x: 0,
            y: 0
        });
    }

    // #endregion

}

export const mouseMovementObserver = directive(MouseMovementObserverDirective as any) as (...values: Parameters<MouseMovementObserverDirective['render']>) => any;

declare global {

    interface HTMLElementEventMap {

        mousemovementchange: CustomEvent<{
            x: number;
            y: number;
        }>;
    }
}
