// #region Imports

import { Attribute, Component, CustomElement, Property, TextAlignment, TextFormattable, Variantable, Watch } from '@breadstone/mosaik-elements-foundation';
import { typewriterElementStyle } from './TypewriterElementStyle';
import { typewriterElementTemplate } from './TypewriterElementTemplate';

// #endregion

@Component({
    selector: 'app-typewriter',
    template: typewriterElementTemplate,
    styles: typewriterElementStyle
})
export class TypewriterElement extends Variantable(TextFormattable(CustomElement)) {

    // #region Fields

    private _text: string;
    private _loopCount: number;
    private _isDeleting: boolean;
    private _alignment: TextAlignment;
    private _toRotate: Array<string>;
    private _period: number;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `TypewriterElement` class.
     *
     * @public
     */
    public constructor() {

        super();

        this._text = '';
        this._loopCount = 0;
        this._isDeleting = false;
        this._alignment = TextAlignment.Left;
        this._toRotate = [];
        this._period = 2000;

    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `period` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get period(): number {

        return this._period;

    }

    public set period(value: number) {

        if (this._period !== value) {

            this._period = value;
            this.requestUpdate('period');

        }

    }

    /**
     * Gets or sets the `toRotate` property.
     *
     * @public
     */
    @Property({ type: Array<string> })
    public get toRotate(): Array<string> {

        return this._toRotate;

    }

    public set toRotate(value: Array<string>) {

        if (this._toRotate !== value) {

            this._toRotate = value;
            this.requestUpdate('toRotate');

        }

    }

    /**
     * Gets or sets the `alignment` property.
     *
     * Posible values are:
     * * `center`
     * * `justify`
     * * `left`
     * * `right`
     *
     * @public
     */
    @Attribute({ type: TextAlignment })
    public get alignment(): TextAlignment {

        return this._alignment;

    }

    public set alignment(value: TextAlignment) {

        if (this._alignment !== value) {

            this._alignment = value;
            this.requestUpdate('alignment');

        }

    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {

        return this._text;

    }

    private set text(value: string) {

        if (this._text !== value) {

            this._text = value;
            this.requestUpdate('text');

        }

    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {

        super.connectedCallback();
        this.tick();

    }

    /**
     * @private
     */
    private tick(): void {

        const index = this._loopCount % this.toRotate.length;
        const fullTxt = this.toRotate[index];

        this.text = this._isDeleting
            ? fullTxt.substring(0, this._text.length - 1)
            : fullTxt.substring(0, this._text.length + 1);

        this.requestUpdate();

        let delta = 200 - Math.random() * 100;
        if (this._isDeleting) {

            delta /= 2;

        }

        if (!this._isDeleting && this._text === fullTxt) {

            delta = this.period;
            this._isDeleting = true;

        } else if (this._isDeleting && this._text === '') {

            this._isDeleting = false;
            this._loopCount++;
            delta = 500;

        }

        setTimeout(() => this.tick(), delta);

    }

    @Watch('toRotate')
    protected onToRotatePropertyChanged(prev?: Array<string>, next?: Array<string>): void {

        if (next) {

            this.toRotate = shuffle(next);

        }

    }

    // #endregion

}

export function shuffle<T>(self: Array<T>): Array<T> {

    for (let i = self.length; i > 1; i--) {

        const j = Math.floor(Math.random() * i);
        const tmp = self[j];
        self[j] = self[i - 1];
        self[i - 1] = tmp;

    }
    return self;

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-typewriter': TypewriterElement;
    }
}
