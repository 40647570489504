import { Extension } from '@codemirror/state';
import { EditorView } from 'codemirror';

export function lineNumber(visible: boolean): Extension {
    return EditorView.theme({
        // Custom theme to ensure no line number styling
        '&.cm-editor': {
            lineNumbers: visible ? '' : 'none'
        }
    });
}