// #region Imports

import { Component } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../../Abstracts/ViewBase';
import { componentsViewStyle } from './ComponentsViewStyle';
import { componentsViewTemplate } from './ComponentsViewTemplate';

// #endregion

/**
 * The `{@link ComponentsView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-components-view',
    template: componentsViewTemplate,
    styles: componentsViewStyle
})
export class ComponentsView
    extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-components-view';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-landing-components-view': ComponentsView;
    }
}
