// #region Imports

import { Appearance, DialogServiceLocator, Orientation, type TemplateResult, TextFormatters, Variant, equalsToElementValidator, html, query, requiredElementValidator, textBoxElementValueAccessor, translate } from '@breadstone/mosaik-elements-foundation';
import { DeleteProfileView } from './DeleteProfileView';

// #endregion

/**
 * The template of the {@link DeleteProfileView}.
 *
 * @public
 */
export function deleteProfileViewTemplate<T extends DeleteProfileView>(e: T): TemplateResult {
    return html`
        <mosaik-dialog>
            <mosaik-dialog-header .text="${translate('loc.profile.dialogs.delete.header')}"></mosaik-dialog-header>
            <mosaik-dialog-content>
                <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'32px'}">
                    <mosaik-text .text="${translate('loc.profile.dialogs.delete.description', e.user?.userName)}"
                                 .wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>

                    <mosaik-form name="delete">
                        <mosaik-form-field .name="${'word'}"
                                           .accessor="${textBoxElementValueAccessor}"
                                           .hidden="${true}">
                            <mosaik-textbox part="word"
                                            .value="${e.user?.userName ?? ''}"
                                            .readonly="${true}"></mosaik-textbox>
                        </mosaik-form-field>
                        <mosaik-form-field name="${'confirmWord'}"
                                           .accessor="${textBoxElementValueAccessor}"
                                           .validators="${[requiredElementValidator(), equalsToElementValidator(() => query(e, '[part="word"]', { shadow: true, strict: true }))]}">
                            <mosaik-textbox .placeholder="${translate('loc.profile.dialogs.delete.form.word.placeholder', e.user?.userName ?? '')}" .variant="${Variant.Primary}"
                                            ?required="${true}"
                                            ?autofocus="${true}"></mosaik-textbox>
                        </mosaik-form-field>
                    </mosaik-form>
                </mosaik-stack>
            </mosaik-dialog-content>
            <mosaik-dialog-actions>
                <mosaik-button .label="${translate('loc.profile.dialogs.delete.actions.cancel')}"
                               .appearance="${Appearance.Plain}"
                               .variant="${Variant.Default}"
                               @click="${() => DialogServiceLocator.current.close(DeleteProfileView.is)}"></mosaik-button>
                <mosaik-button .label="${translate('loc.profile.dialogs.delete.actions.delete')}"
                               .appearance="${Appearance.Solid}"
                               .variant="${Variant.Danger}"
                               .isBusy="${e.isBusy}"
                               @click="${() => e.onDelete()}"></mosaik-button>
            </mosaik-dialog-actions>
        </mosaik-dialog>
    `;
}
