// #region Imports

import { Component, FlowDirection, Placement, ToggleTipElement, Trigger } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { toggletipElementDocs } from '../../../.Generated/mosaik-toggletip';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ToggletipSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-toggletip-sample'
})
export class ToggletipSampleElement extends SampleBaseElement<ToggleTipElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ToggleTipElement> {

        return {
            header: 'Toggletip',
            description: META.description(ToggleTipElement.is),
            graph: META.graph(ToggleTipElement.is),
            playground: {
                template: html`
                <mosaik-toggletip></mosaik-toggletip>
            `,
                properties: META.properties<ToggleTipElement>(ToggleTipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'hideDelay',
                        value: 100
                    },
                    {
                        key: 'showDelay',
                        value: 300
                    },
                    {
                        key: 'placement',
                        value: Placement.Top
                    },
                    {
                        key: 'trigger',
                        value: Trigger.Hover
                    },
                    {
                        key: 'horizontalOffset',
                        value: 8
                    },
                    {
                        key: 'content',
                        value: 'Im a toggletip.'
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<ToggleTipElement>(ToggleTipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'hideDelay',
                        value: 100
                    },
                    {
                        key: 'showDelay',
                        value: 300
                    },
                    {
                        key: 'placement',
                        value: Placement.Top
                    },
                    {
                        key: 'trigger',
                        value: Trigger.Hover
                    },
                    {
                        key: 'horizontalOffset',
                        value: 8
                    },
                    {
                        key: 'content',
                        value: 'Im a toggletip.'
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<ToggleTipElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                toggletipElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-toggletip-sample': ToggletipSampleElement;
    }
}
