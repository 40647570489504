// #region Imports

import { Component, TextFormatElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { textFormatElementDocs } from '../../.Generated/mosaik-text-format';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TextFormatSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-text-format-sample'
})
export class TextFormatSampleElement extends SampleBaseElement<TextFormatElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TextFormatElement> {

        return {
            header: 'Text Format',
            description: META.description(TextFormatElement.is),
            graph: META.graph(TextFormatElement.is),
            playground: {
                template: html`
                    <mosaik-text-format>
                        <template><sub>Format</sub></template>
                        <sub>Format</sub>
                        <sub>Format</sub>
                    </mosaik-text-format>
                `,
                properties: [
                    {
                        key: 'text',
                        value: 'A default Text with {0}.',
                        type: String
                    }
                ]
            },
            propertyGrid: {
                properties: META.properties<TextFormatElement>(TextFormatElement.is, [
                    {
                        key: 'text',
                        value: 'Text'
                    }
                ])
            },
            docs: [
                textFormatElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-text-format-sample': TextFormatSampleElement;
    }
}
