// #region Imports

import { Animate, Component, Fit, Inset, Orientation, TextFormatters, animate, repeat, translate, typography, when } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

interface IFormattersDescriptor {
    type: string;
    examples: Array<{
        format: string;
        value: string;
    }>;
}

/**
 * The `{@link FormattingView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-formatting-view'
})
export class FormattingView extends ViewBase {

    // #region Fields

    private readonly _formatters: Array<IFormattersDescriptor>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._formatters = [
            {
                type: 'richtext',
                examples: [
                    {
                        format: 'italic',
                        value: '_Value_'
                    },
                    {
                        format: 'bold',
                        value: '*Value*'
                    },
                    {
                        format: 'strikethrough',
                        value: '~Value~'
                    },
                    {
                        format: 'code',
                        value: '`Value`'
                    },
                    {
                        format: 'link',
                        value: 'http://example.com\nhttps://example.com\nftp://example.com'
                    },
                    {
                        format: 'phone',
                        value: '+1-234-567-8900'
                    },
                    {
                        format: 'email',
                        value: 'user@example.com'
                    },
                    {
                        format: 'linebreak',
                        value: 'New\nline'
                    }
                ]
            },
            {
                type: 'markdown',
                examples: [
                    {
                        format: 'italic',
                        value: '_Value_'
                    },
                    {
                        format: 'bold',
                        value: '**Value**'
                    },
                    {
                        format: 'strikethrough',
                        value: '~~Value~~'
                    },
                    {
                        format: 'code',
                        value: '`Value`'
                    },
                    {
                        format: 'link',
                        value: '[example](http://example.com)'
                    },
                    {
                        format: 'image',
                        value: '![alt text](https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D =120x200)'
                    },
                    {
                        format: 'email',
                        value: '<user@example.com>'
                    },
                    {
                        format: 'list',
                        value: '* Item 1\n* Item 2\n* Item 3'
                    },
                    {
                        format: 'list',
                        value: '1. Item 1\n1. Item 2\n1. Item 3'
                    },
                    {
                        format: 'blockquote',
                        value: '> Quote'
                    },
                    {
                        format: 'header',
                        value: '# Header 1'
                    },
                    {
                        format: 'header',
                        value: '## Header 2'
                    },
                    {
                        format: 'header',
                        value: '### Header 3'
                    },
                    {
                        format: 'header',
                        value: '#### Header 4'
                    },
                    {
                        format: 'header',
                        value: '##### Header 5'
                    },
                    {
                        format: 'header',
                        value: '###### Header 6'
                    },
                    {
                        format: 'linebreak',
                        value: 'New\nline'
                    }
                ]
            },
            {
                type: 'bbcode',
                examples: [
                    {
                        format: 'italic',
                        value: '[i]Value[/i]'
                    },
                    {
                        format: 'bold',
                        value: '[b]Value[/b]'
                    },
                    {
                        format: 'underline',
                        value: '[u]Value[/u]'
                    },
                    {
                        format: 'strikethrough',
                        value: '[s]Value[/s]'
                    },
                    {
                        format: 'code',
                        value: '[code]Value[/code]'
                    },
                    {
                        format: 'link',
                        value: '[url=http://example.com]example[/url]'
                    },
                    {
                        format: 'image',
                        value: '[img width=120 height=200]https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D[/img]'
                    },
                    {
                        format: 'email',
                        value: '[email]user@example.com[/email]'
                    },
                    {
                        format: 'color',
                        value: '[color=red]value[/color]'
                    },
                    {
                        format: 'size',
                        value: '[size=2]value[/size]'
                    },
                    {
                        format: 'list',
                        value: '[list][*]item[*]item[/list]'
                    },
                    {
                        format: 'blockquote',
                        value: '[quote]value[/quote]'
                    },
                    {
                        format: 'linebreak',
                        value: ''
                    }
                ]
            }
        ];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-formatting-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.formatting')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/formatting'}" .label="${translate('loc.nav.formatting')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.formatting')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate('loc.formatting.intro')}"></mosaik-text>
                    ${repeat(this._formatters, (x) => x.type, (x) => html`
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate(`loc.formatting.${x.type}.name`)}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate(`loc.formatting.${x.type}.description`)}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-table .columns="${this.isMobile ? '100%' : '20% 40% 40%'}">
                        ${when(!this.isMobile, () => html`
                        <mosaik-table-header>
                            <mosaik-table-row>
                                <mosaik-table-cell .text="${translate('loc.formatting.table.feature')}"></mosaik-table-cell>
                                <mosaik-table-cell .text="${translate('loc.formatting.table.format')}"></mosaik-table-cell>
                                <mosaik-table-cell .text="${translate('loc.formatting.table.result')}"></mosaik-table-cell>
                            </mosaik-table-row>
                        </mosaik-table-header>
                        `)}
                        <mosaik-table-body>
                            ${repeat(x.examples, (y) => y.format, (y) => html`
                            <mosaik-table-row>
                                <mosaik-table-cell>
                                    ${when(this.isMobile, () => html`
                                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                                  .fit="${Fit.Both}">
                                        <mosaik-text .text="${translate(`loc.formatting.table.${y.format}`)}"
                                                     ${typography('overline')}></mosaik-text>
                                        <mosaik-spacer thickness="top"></mosaik-spacer>
                                        <mosaik-stack .orientation="${Orientation.Horizontal}">
                                            <mosaik-stack>
                                                <mosaik-text .text="${y.value}" ?truncate="${true}"></mosaik-text>
                                            </mosaik-stack>
                                            <mosaik-stack>
                                                ${when(x.type === 'richtext', () => html`
                                                <mosaik-text .text="${y.value}"
                                                             .formatter="${TextFormatters.RICHTEXT}"
                                                             ?truncate="${true}"></mosaik-text>
                                                `)}
                                                ${when(x.type === 'markdown', () => html`
                                                <mosaik-text .text="${y.value}"
                                                             .formatter="${TextFormatters.MARKDOWN}"
                                                             ?truncate="${true}"></mosaik-text>
                                                `)}
                                                ${when(x.type === 'bbcode', () => html`
                                                <mosaik-text .text="${y.value}"
                                                             .formatter="${TextFormatters.BBCODE}"
                                                             ?truncate="${true}"></mosaik-text>
                                                `)}
                                            </mosaik-stack>
                                        </mosaik-stack>
                                    </mosaik-stack>
                                    `, () => html`
                                    <mosaik-text .text="${translate(`loc.formatting.table.${y.format}`)}"></mosaik-text>
                                    `)}
                                </mosaik-table-cell>
                                ${when(!this.isMobile, () => html`
                                <mosaik-table-cell>
                                    <mosaik-code .text="${y.value}"></mosaik-code>
                                </mosaik-table-cell>
                                <mosaik-table-cell>
                                    ${when(x.type === 'richtext', () => html`
                                    <mosaik-text .text="${y.value}"
                                                 .formatter="${TextFormatters.RICHTEXT}"
                                                 ?truncate="${true}"></mosaik-text>
                                    `)}
                                    ${when(x.type === 'markdown', () => html`
                                    <mosaik-text .text="${y.value}"
                                                 .formatter="${TextFormatters.MARKDOWN}"
                                                 ?truncate="${true}"></mosaik-text>
                                    `)}
                                    ${when(x.type === 'bbcode', () => html`
                                    <mosaik-text .text="${y.value}"
                                                 .formatter="${TextFormatters.BBCODE}"
                                                 ?truncate="${true}"></mosaik-text>
                                    `)}
                                </mosaik-table-cell>
                                `)}
                            </mosaik-table-row>
                            `)}
                        </mosaik-table-body>
                    </mosaik-table>
                    `)}
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    /**
     * @private
     */
    private onBreakpointMatched(match: boolean): void {
        this.isMobile = match;
        match ? this.classList.add('lt-sm') : this.classList.remove('lt-sm');
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-formatting-view': FormattingView;
    }
}
