// #region Imports

import { animate, Animate, Appearance, flex, html, ifDefined, Inset, Orientation, Placement, repeat, requiredElementValidator, Size, SkeletonShape, textBoxElementValueAccessor, timeAgo, translate, Variant, when, type IFormSubmitEvent, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { ColorExtensions } from '../../Extensions/ColorExtensions';
import { globals } from '../../Globals';
import type { FeedbackDetailView } from './FeedbackDetailView';
import { getFeedbackPrioritySymbol, getFeedbackStatusSymbol, getFeedbackTypeSymbol } from './Utils';

// #endregion

/**
 * The template of the {@link FeedbackDetailView}.
 *
 * @public
 */
export function feedbackDetailViewTemplate<T extends FeedbackDetailView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${ifDefined(e.feedback?.title ?? undefined)}"></title>
            <meta name="description" content="${ifDefined(e.feedback?.body ?? undefined)}"/>
        </mosaik-helmet>
        <mosaik-page breakpoint="md"
                     ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb .wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                       .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/feedback'}"
                                       .label="${translate('loc.nav.feedback')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${`/feedback/detail?id=${e.id}`}"
                                       .label="${e.feedback?.title ?? '#'}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            ${when(e.isBusy && !e.feedback, () => html`
            <mosaik-page-content .inset="${[Inset.All]}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'16px'}">
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'38px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'38px'}"></mosaik-skeleton>
                    <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                     .shimmer="${true}"
                                     .width="${'100%'}"
                                     .height="${'38px'}"></mosaik-skeleton>
                </mosaik-stack>
            </mosaik-page-content>
            `, () => html`
            <mosaik-page-content .inset="${[Inset.All]}"
                                 ${animate({ ...Animate.slideInBottom })}>
                <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                              .gap="${'32px'}">
                    <mosaik-stack ${flex({ fill: 3 })}
                                  .orientation="${Orientation.Vertical}"
                                  .gap="${'16px'}">
                        <mosaik-text .text="${translate('Description')}"></mosaik-text>
                        <mosaik-stack .orientation="${Orientation.Horizontal}"
                                      .gap="${'16px'}">
                            <mosaik-button class="vote"
                                           .icon="${'m7.293 8.293 3.995-4a1 1 0 0 1 1.32-.084l.094.083 4.006 4a1 1 0 0 1-1.32 1.499l-.094-.083-2.293-2.291v11.584a1 1 0 0 1-.883.993L12 20a1 1 0 0 1-.993-.884L11 19.001V7.41L8.707 9.707a1 1 0 0 1-1.32.084l-.094-.084a1 1 0 0 1-.084-1.32l.084-.094 3.995-4-3.995 4Z'}"
                                           .orientation="${Orientation.Vertical}"
                                           .label="${e.feedback?.votesCount.toString() ?? '0'}"
                                           .variant="${Variant.Success}"
                                           .appearance="${Appearance.Outline}"
                                           .isBusy="${e.isBusy}"
                                           .size="${Size.Small}"
                                           @click="${(y: Event) => e.onVote()}"></mosaik-button>
                            <mosaik-stack .orientation="${Orientation.Vertical}">
                                <app-markdown .text="${e.feedback?.title ?? ''}"
                                              .breaks="${true}"></app-markdown>
                                <app-markdown .text="${e.feedback?.body ?? ''}"
                                              .breaks="${true}"></app-markdown>
                            </mosaik-stack>
                        </mosaik-stack>
                        <mosaik-divider></mosaik-divider>
                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                      .gap="${'16px'}">
                            <mosaik-text .text="${translate('Comments')} (${e.feedback?.comments.length})"></mosaik-text>
                            ${when(e.feedback?.comments && e.feedback.comments.length > 0, () => html`
                            ${repeat(e.feedback?.comments ?? [], (comment) => comment.id, (comment) => html`
                            <mosaik-comment .content="${comment.body}"
                                            .author="${comment.user.profile.fullName}"
                                            .at="${timeAgo(comment.createdAt)}">
                                <mosaik-avatar slot="avatar"
                                               style="--avatar-background-color: ${ColorExtensions.hash(comment.user.profile.fullName)}"
                                               .text="${comment.user.profile.fullName}"
                                               .size="${Size.Small}"></mosaik-avatar>
                                ${when(e.user?.roles.includes('admin'), () => html`
                                <mosaik-button slot="actions"
                                               .label="${translate('Delete')}"
                                               .icon="${'M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1Zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648Zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648ZM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5Z'}"
                                               .size="${Size.Small}"
                                               .appearance="${Appearance.Plain}"
                                               .variant="${Variant.Danger}"
                                               @click="${() => e.deleteComment(e.feedback?.id, comment.id)}"></mosaik-button>
                                `)}
                            </mosaik-comment>
                            `)}
                            `, () => html`
                            <mosaik-empty-state .content="${translate('No comments')}"></mosaik-empty-state>
                            `)}
                        </mosaik-stack>
                        <mosaik-divider></mosaik-divider>

                        <mosaik-form part="form"
                                     ${flex({ direction: 'row', gap: '16px', alignItems: 'flex-start' })}
                                     @submited="${(x: IFormSubmitEvent) => e.onSubmit(x)}">
                            <mosaik-form-field ${flex({ fill: true })}
                                               name="comment"
                                               .validators="${[requiredElementValidator()]}"
                                               .accessor="${textBoxElementValueAccessor}">
                                <mosaik-textbox .label="${'Comment'}"
                                                .required="${true}"
                                                .placeholder="${'Write something serious...'}"
                                                .multiline="${true}"
                                                .multilineRows="${2}"
                                                .multilineMaxRows="${22}"></mosaik-textbox>
                            </mosaik-form-field>
                            <mosaik-button ${flex({ fill: false })} .icon="${'m12.815 12.197-7.532 1.256a.5.5 0 0 0-.386.318L2.3 20.728c-.248.64.421 1.25 1.035.943l18-9a.75.75 0 0 0 0-1.342l-18-9c-.614-.307-1.283.304-1.035.943l2.598 6.957a.5.5 0 0 0 .386.319l7.532 1.255a.2.2 0 0 1 0 .394Z'}"
                                           .appearance="${Appearance.Solid}"
                                           .variant="${Variant.Primary}"
                                           .isBusy="${e.isBusy}"
                                           @click="${() => e.addComment()}"></mosaik-button>
                        </mosaik-form>
                    </mosaik-stack>
                    <mosaik-divider .orientation="${Orientation.Vertical}"></mosaik-divider>
                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                  .gap="${'16px'}">
                        <mosaik-text .text="${translate('Details')}"></mosaik-text>
                        ${when(e.feedback, (feedback) => html`
                        <mosaik-summary .header="${translate('Author')}">
                            <mosaik-tooltip slot="text"
                                            .content="${feedback.user.profile.fullName}"
                                            .placement="${Placement.Bottom}">
                                <mosaik-avatar style="--avatar-background-color: ${ColorExtensions.hash(feedback.user.profile.fullName)}"
                                                .size="${Size.Tiny}"
                                                .src="${feedback.user.profile.avatar ?? ''}"
                                                .text="${feedback.user.profile.fullName}"></mosaik-avatar>
                            </mosaik-tooltip>
                        </mosaik-summary>
                        <mosaik-summary .header="${translate('Voters')}">
                            ${when(feedback.votes.length > 0, () => html`
                            <mosaik-avatar-group .size="${Size.Tiny}">
                                ${repeat(feedback.votes, (x, i) => x.id, (x) => html`
                                <mosaik-avatar id="avatar_${x.id}"
                                               style="--avatar-background-color: ${ColorExtensions.hash(x.user.profile.fullName)}"
                                               .src="${x.user.profile.avatar ?? ''}"
                                               .icon="${'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'}"
                                               .text="${x.user.profile.fullName}"></mosaik-avatar>

                                `)}
                            </mosaik-avatar-group>
                            ${repeat(feedback.votes.map((x) => x.user), (x, i) => x.id, (x) => html`
                                 <mosaik-tooltip .for="avatar_${x.id}"></mosaik-tooltip>
                            `)}

                            `, () => html`
                            <mosaik-text .text="${translate('No voters')}" .readonly="${true}"></mosaik-text>
                            `)}
                        </mosaik-summary>
                        <mosaik-summary .header="${translate('Status')}"
                                        .content="${getFeedbackStatusSymbol(feedback.status.name)} ${feedback.status.name}"></mosaik-summary>
                        <mosaik-summary .header="${translate('Priority')}"
                                        .content="${getFeedbackPrioritySymbol(feedback.priority.name)} ${feedback.priority.name}"></mosaik-summary>
                        <mosaik-summary .header="${translate('Type')}"
                                        .content="${getFeedbackTypeSymbol(feedback.type.name)} ${feedback.type.name}"></mosaik-summary>
                        <mosaik-summary .header="${translate('Labels')}">
                            ${when(feedback.labels.length > 0, () => html`
                            <div ${flex({ wrap: 'wrap' })}>
                                ${repeat(feedback.labels, (label) => label.id, (label) => html`
                                <mosaik-chip .label="${label.name}"></mosaik-chip>
                                `)}
                            </div>
                            `, () => html`
                            <mosaik-text .text="${translate('No labels')}" .readonly="${true}"></mosaik-text>
                            `)}
                        </mosaik-summary>
                        <mosaik-summary .header="${translate('Created')}"
                                        .content="${timeAgo(feedback.createdAt)}"></mosaik-summary>
                        `)}
                    </mosaik-stack>
                </mosaik-stack>
            </mosaik-page-content>
            `)}
        </mosaik-page>
    `;
}
