// #region Imports

import { Injectable, html, inject, unsafeHTML, type RouteConfig } from '@breadstone/mosaik-elements-foundation';
import { ComponentsService } from './ComponentsService';

// #endregion

interface IRouteData {
    hasHeader: boolean;
    hasNavigation: boolean;
    hasMenu: boolean;
    hasFooter: boolean;
    hasFeedback: boolean;
    isDrawerOpen: boolean;
}

/**
 * The `RoutesService` class.
 *
 * @public
 */
@Injectable()
export class RoutesService {

    // #region Fields

    private readonly _componentsService: ComponentsService;
    private readonly _componentRoutes: Array<RouteConfig>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RoutesService` class.
     *
     * @public
     */
    public constructor() {
        this._componentsService = inject(ComponentsService);
        this._componentRoutes = this._componentsService.getComponents().map((component) => ({
            name: component.name,
            path: component.path,
            render: () => {
                const dynamicTagName = `${component.internalName.replace('mosaik-', 'app-').toLowerCase()}-sample`;
                const tag = unsafeHTML(`<${dynamicTagName}></${dynamicTagName}>`);
                return html`${tag}`;
            }
        }));
    }

    // #endregion

    // #region Properties

    public get routes(): Array<RouteConfig> {
        return [
            {
                name: 'Auth',
                path: '/auth',
                render: () => html`<app-auth-view></app-auth-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasFooter: false,
                    hasFeedback: false,
                    isDrawerOpen: false,
                    hasMenu: false
                } satisfies IRouteData
            },
            {
                name: 'Profile',
                path: '/profile',
                render: () => html`<app-profile-view></app-profile-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false,
                    hasMenu: true
                } satisfies IRouteData
            },
            {
                name: 'Landing',
                path: '/',
                render: () => html`<app-landing-view></app-landing-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: false,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'GetStarted',
                path: '/getstarted',
                render: () => html`<app-getstarted-view></app-getstarted-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Introduction',
                path: '/introduction',
                render: () => html`<app-introduction-view></app-introduction-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Installation',
                path: '/installation',
                render: () => html`<app-installation-view></app-installation-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Usage',
                path: '/usage',
                render: () => html`<app-usage-view></app-usage-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Support',
                path: '/support',
                render: () => html`<app-support-view></app-support-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Desgin',
                path: '/design',
                render: () => html`<app-design-view></app-design-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Layout',
                path: '/layout',
                render: () => html`<app-layout-view></app-layout-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Breakpoint',
                path: '/breakpoint',
                render: () => html`<app-breakpoint-view></app-breakpoint-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Visibility',
                path: '/visibility',
                render: () => html`<app-visibility-view></app-visibility-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Scheme',
                path: '/scheme',
                render: () => html`<app-scheme-view></app-scheme-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Color',
                path: '/color',
                render: () => html`<app-color-view></app-color-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Typography',
                path: '/typography',
                render: () => html`<app-typography-view></app-typography-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Elevation',
                path: '/elevation',
                render: () => html`<app-elevation-view></app-elevation-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Formatting',
                path: '/formatting',
                render: () => html`<app-formatting-view></app-formatting-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },

            {
                name: 'Components',
                path: '/components',
                render: () => html`<app-components-view></app-components-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            ...this._componentRoutes,
            {
                name: 'Tools',
                path: '/tools',
                render: () => html`<app-tools-view></app-tools-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: false,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Icon Gallery',
                path: '/tools/icons',
                render: () => html`<app-icon-gallery-view></app-icon-gallery-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Theme Generator',
                path: '/tools/themegenerator',
                render: () => html`<app-theme-generator-view></app-theme-generator-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Grid Generator',
                path: '/tools/gridgenerator',
                render: () => html`<app-grid-generator-view></app-grid-generator-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: true,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: true
                } satisfies IRouteData
            },
            {
                name: 'Imprint',
                path: '/legal/imprint',
                render: () => html`<app-termsofuse-view></app-termsofuse-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Privacy Policy',
                path: '/legal/privacypolicy',
                render: () => html`<app-privacypolicy-view></app-privacypolicy-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Cookie Policy',
                path: '/legal/cookiepolicy',
                render: () => html`<app-cookiepolicy-view></app-cookiepolicy-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'About',
                path: '/about',
                render: () => html`<app-about-view></app-about-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'About Member',
                path: '/about/member',
                render: () => html`<app-about-member-view></app-about-member-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Events',
                path: '/events',
                render: () => html`<app-events-view></app-events-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Feedback',
                path: '/feedback',
                render: () => html`<app-feedback-view></app-feedback-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: false,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Feedback',
                path: '/feedback/detail',
                render: () => html`<app-feedback-detail-view></app-feedback-detail-view>`,
                data: {
                    hasHeader: true,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: false,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Blog',
                path: '/blog',
                render: () => html`<app-blog-view></app-blog-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Blog post',
                path: '/blog/post',
                render: () => html`<app-blog-post-view></app-blog-post-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Story',
                path: '/story',
                render: () => html`<app-story-view></app-story-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Contact',
                path: '/contact',
                render: () => html`<app-contact-view></app-contact-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            },
            {
                name: 'Not Found',
                path: '/:pathMatch(.*)*',
                render: () => html`<app-not-found-view></app-not-found-view>`,
                data: {
                    hasHeader: false,
                    hasNavigation: false,
                    hasMenu: true,
                    hasFooter: true,
                    hasFeedback: true,
                    isDrawerOpen: false
                } satisfies IRouteData
            }
        ];
    }

    // #endregion

    // #region Methods
    // #endregion

}

export const ROUTES = new RoutesService().routes;
