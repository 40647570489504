//#region Imports

import { Appearance, Component, FlowDirection, InputState, TextBoxElement, TextBoxType, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { textboxElementDocs } from '../../../.Generated/mosaik-textbox';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate, numberTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link TextBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-textbox-sample'
})
export class TextBoxSampleElement extends SampleBaseElement<TextBoxElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TextBoxElement> {
        return {
            header: 'Text Box',
            description: META.description(TextBoxElement.is),
            graph: META.graph(TextBoxElement.is),
            tags: ['input'],
            playground: {
                template: [
                    ['default', html`
                        <mosaik-textbox></mosaik-textbox>
                    `], ['with prefix', html`
                        <mosaik-textbox>
                            <mosaik-icon slot="prefix"
                                         .inline="${true}"
                                         .data="${'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'}"></mosaik-icon>
                        </mosaik-textbox>
                    `], ['with suffix', html`
                        <mosaik-textbox>
                            <mosaik-icon slot="suffix"
                                         .inline="${true}"
                                         .data="${'M2 6a3 3 0 0 1 3-3h1.5a1 1 0 0 1 0 2H5a1 1 0 0 0-1 1v1.5a1 1 0 0 1-2 0V6Zm14.5-2a1 1 0 0 1 1-1H19a3 3 0 0 1 3 3v1.5a1 1 0 1 1-2 0V6a1 1 0 0 0-1-1h-1.5a1 1 0 0 1-1-1ZM3 15.5a1 1 0 0 1 1 1V18a1 1 0 0 0 1 1h1.5a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3v-1.5a1 1 0 0 1 1-1Zm18 0a1 1 0 0 1 1 1V18a3 3 0 0 1-3 3h-1.5a1 1 0 1 1 0-2H19a1 1 0 0 0 1-1v-1.5a1 1 0 0 1 1-1ZM6 6.75a1 1 0 0 1 1 1v8.5a1 1 0 1 1-2 0v-8.5a1 1 0 0 1 1-1Zm5 1a1 1 0 1 0-2 0v8.5a1 1 0 1 0 2 0v-8.5Zm3-1a1 1 0 0 1 1 1v8.5a1 1 0 1 1-2 0v-8.5a1 1 0 0 1 1-1Zm5 1a1 1 0 1 0-2 0v8.5a1 1 0 1 0 2 0v-8.5Z'}"></mosaik-icon>
                        </mosaik-textbox>
                    `]
                ],
                properties: META.properties<TextBoxElement>(TextBoxElement.is, [
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'value', value: 'Value' },
                    { key: 'label', value: 'Label' },
                    { key: 'placeholder', value: 'Type something...' },
                    { key: 'state', value: InputState.Initial },
                    { key: 'type', value: TextBoxType.Text },
                ]),
                events: META.events(TextBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<TextBoxElement>(TextBoxElement.is, [
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'value', value: 'Value' },
                    { key: 'label', value: 'Label' },
                    { key: 'placeholder', value: 'Type something...' },
                    { key: 'state', value: InputState.Initial },
                    { key: 'type', value: TextBoxType.Text },
                ]), [
                    { key: 'maxlength', template: numberTemplate },
                    { key: 'minlength', template: numberTemplate },
                    { key: 'formatter', template: functionTemplate },
                    { key: 'parser', template: functionTemplate }
                ] as Array<IPlaygroundProperty<TextBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                textboxElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-textbox-sample': TextBoxSampleElement;
    }
}

