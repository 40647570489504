// #region Imports

import { IUserResponse } from '@backend/Index';
import { Inject, Injectable } from '@breadstone/mosaik-elements-foundation';
import { AuthService } from '../Backend/Api/Services/AuthService';

// #endregion

/**
 * The `SessionManager` class.
 *
 * @public
 */
@Injectable()
export class SessionManager {

    // #region Fields

    private readonly _authService: AuthService;
    private readonly _me: IUserResponse | null;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SessionManager` class.
     *
     * @public
     */
    public constructor(@Inject(AuthService) sessionService: AuthService) {
        this._authService = sessionService;
        this._me = null;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `session` property.
     *
     * @public
     * @readonly
     */
    public get session(): string {
        return SessionManager.getStoredSession();
    }

    /**
     * Returns the `me` property.
     *
     * @public
     * @readonly
     */
    public get me(): IUserResponse | null {
        return SessionManager.getStoredUser();
    }

    // #endregion

    // #region Methods

    public static async handshake(): Promise<void> {
        if (SessionManager.getStoredSession()) {
            const response = await new AuthService().refresh({
                authorization: SessionManager.getStoredSession()
            });

            SessionManager.setStoredSession(response.accessToken);
        } else {
            const response = await new AuthService().login({
                body: {
                    login: 'Anonymous',
                    password: ''
                }
            });

            const user = await new AuthService().me({
                authorization: response.accessToken
            });

            SessionManager.setStoredSession(response.accessToken);
            SessionManager.setStoredUser(user);
        }
    }

    public static clear(): void {
        SessionManager.clearStoredSession();
        SessionManager.clearStoredUser();
    }

    /**
     * Handshake with the server to establish a session.
     * The access token should only be provided if the session is already established.
     *
     * @public
     * @param accessToken - The access token.
     * @param me - The user information.
     */
    public handshake(accessToken: string, me: IUserResponse): void {
        SessionManager.setStoredSession(accessToken);
        SessionManager.setStoredUser(me);
    }

    /**
     * Extracts the payload from the session.
     *
     * @public
     */
    public extract(): Record<string, unknown> | null {
        try {
            const [, payload] = this.session.split('.');
            const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));

            // Parse the JSON string into an object
            return JSON.parse(decodedPayload);
        } catch (error) {
            console.error('Invalid JWT token', error);
            return null;
        }
    }

    public clear(): void {
        SessionManager.clearStoredSession();
        SessionManager.clearStoredUser();
    }

    /**
     * @private
     */
    private static getStoredSession(): string {
        return sessionStorage.getItem('session') ?? '';
    }

    /**
     * @private
     */
    private static getStoredUser(): IUserResponse | null {
        const user = sessionStorage.getItem('user');

        if (user) {
            return JSON.parse(user);
        }

        return null;
    }

    /**
     * @private
     */
    private static setStoredSession(session?: string): void {
        sessionStorage.setItem('session', session ?? '');
    }

    /**
     * @private
     */
    private static setStoredUser(user: IUserResponse): void {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * @private
     */
    private static clearStoredSession(): void {
        sessionStorage.removeItem('session');
    }

    /**
     * @private
     */
    private static clearStoredUser(): void {
        sessionStorage.removeItem('user');
    }

    // #endregion

}
