// #region Imports

import { Animate, Appearance, Fit, HorizontalAlignment, IconPosition, Inset, Orientation, Size, Spacing, type TemplateResult, TextAlignment, TextFormatters, Variant, animate, html, translate, typography, when } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { COMPONENTS } from '../../Services/ComponentsService';
import { FeatureManager } from '../../Services/FeatureManager';
import type { LandingView } from './LandingView';

// #endregion

/**
 * The template of the {@link LandingView}.
 *
 * @public
 */
export function landingViewTemplate<T extends LandingView>(e: T): TemplateResult {
    const list = COMPONENTS.getComponents().map((x) => 'like '.concat(`*${x.name}*.`));

    return html`
        ${when(FeatureManager.isActive('itsXmasTime'), () => html`
        <mosaik-light-chain part="lightChain"
                            .state="${'auto'}"
                            .count="${e.isMobile ? 16 : 32}"></mosaik-light-chain>
        `)}
        <mosaik-page breakpoint="md">
            <mosaik-page-content class="intro">
                <div class="main">
                    <app-logo ${mouseMovementChild()}
                              ${animate({ ...Animate.slideInBottom })}
                              part="logo"
                              .type="${e.isMobile ? 'logo' : 'both'}"></app-logo>
                    <mosaik-spacer .thickness="${Spacing.All}"></mosaik-spacer>
                    <mosaik-stack ${mouseMovementChild()}
                                  .orientation="${Orientation.Vertical}">
                        <mosaik-text ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 } } })}
                                     ${typography('headline5')}
                                     .text="${translate('loc.landing.description')}"
                                     .alignment="${TextAlignment.Center}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <app-typewriter ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 } } })}
                                        ${typography('headline5')}
                                        .toRotate="${list}"
                                        .period="${3000}"
                                        .variant="${Variant.Primary}"
                                        .formatter="${TextFormatters.RICHTEXT}"
                                        .alignment="${TextAlignment.Center}"></app-typewriter>
                    </mosaik-stack>
                    <mosaik-spacer thickness="all"></mosaik-spacer>
                    <mosaik-text ${animate({ ...Animate.slideInTop, ...{ options: { delay: 400 } } })}
                                 ${typography('headline6')}
                                 ${mouseMovementChild()}
                                 .text="${translate('loc.landing.count', e.count)}"
                                 .alignment="${TextAlignment.Center}"
                                 .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>
                    <mosaik-spacer thickness="all"></mosaik-spacer>
                    <mosaik-stack .gap="${'16px'}"
                                  .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}">
                        <mosaik-router-link .href="${'/introduction'}">
                            <mosaik-button ${animate({ ...Animate.slideInTop, ...{ options: { delay: 600 } } })}
                                        .appearance="${Appearance.Plain}"
                                        .variant="${Variant.Secondary}"
                                        .label="${translate('loc.landing.actions.docs.label')}"
                                        .size="${Size.Giant}"></mosaik-button>
                        </mosaik-router-link>
                        <mosaik-router-link .href="${'/components'}">
                            <mosaik-button ${animate({ ...Animate.slideInTop, ...{ options: { delay: 600 } } })}
                                        .appearance="${Appearance.Solid}"
                                        .variant="${Variant.Primary}"
                                        .label="${translate('loc.landing.actions.explore.label')}"
                                        .size="${Size.Giant}"></mosaik-button>
                        </mosaik-router-link>
                    </mosaik-stack>
                </div>
            </mosaik-page-content>
            <mosaik-page-content class="argument"
                                    .inset="${Inset.All}"
                                    .fullWidth="${true}">
                <mosaik-spacer .thickness="${Spacing.All}"
                                .size="${Size.Large}">
                    <app-landing-argument-view></app-landing-argument-view>
                    <mosaik-spacer .thickness="${Spacing.Top}"
                                    .size="${Size.Small}">
                        <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                            <mosaik-router-link .href="${'/introduction'}">
                                <mosaik-button .appearance="${Appearance.Plain}"
                                                .variant="${Variant.Primary}"
                                                .iconSize="${Size.Medium}"
                                                .iconPosition="${IconPosition.After}"
                                                .label="${translate('loc.landing.actions.introduction')}"
                                                .fit="${Fit.Width}"
                                                .icon="${'M13.704 4.284a1 1 0 1 0-1.403 1.424L17.67 11H4a1 1 0 1 0 0 2h13.665L12.3 18.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823Z'}"></mosaik-button>
                            </mosaik-router-link>
                        </mosaik-stack>
                    </mosaik-spacer>
                </mosaik-spacer>
            </mosaik-page-content>
            <mosaik-page-content class="impression"
                                    .inset="${Inset.All}">
                <app-landing-impression-view></app-landing-impression-view>
                <mosaik-spacer .thickness="${Spacing.Top}"
                                .size="${Size.Small}">
                    <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                        <mosaik-router-link .href="${'/components'}">
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .variant="${Variant.Primary}"
                                            .iconSize="${Size.Medium}"
                                            .iconPosition="${IconPosition.After}"
                                            .label="${translate('loc.landing.actions.components.label')}"
                                            .fit="${Fit.Width}"
                                            .icon="${'M13.704 4.284a1 1 0 1 0-1.403 1.424L17.67 11H4a1 1 0 1 0 0 2h13.665L12.3 18.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823Z'}"></mosaik-button>
                        </mosaik-router-link>
                    </mosaik-stack>
                </mosaik-spacer>
            </mosaik-page-content>
            <mosaik-page-content class="components"
                                    .inset="${Inset.All}">
                <app-landing-components-view></app-landing-components-view>
            </mosaik-page-content>
            <mosaik-page-content class="newsletter"
                                    .inset="${Inset.All}"
                                    .fullWidth="${true}">
                <mosaik-spacer .thickness="${Spacing.All}"
                                .size="${Size.Large}">
                    <app-landing-newsletter-view .kind="${'landing'}"></app-landing-newsletter-view>
                </mosaik-spacer>
            </mosaik-page-content>
            ${when(FeatureManager.isActive('pricingTable'), () => html`
            <mosaik-page-content class="pricing"
                                    .inset="${Inset.All}">
                <app-landing-pricing-view></app-landing-pricing-view>
            </mosaik-page-content>
            `)}
        </mosaik-page>
    `;
}
