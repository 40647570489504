// #region Imports

import { Animate, Appearance, Fit, HorizontalAlignment, HyperlinkUnderline, type IPasswordConcealedEvent, type IPasswordRevealedEvent, type IPropertyChangedEvent, IconPosition, Orientation, PinMode, Spacing, TabPanelAlignment, type TemplateResult, TextAlignment, TextFormatters, Variant, VerticalAlignment, animate, checkBoxElementValueAccessor, emailElementValidator, equalsToElementValidator, flex, html, passwordBoxElementValueAccessor, query, repeat, requiredElementValidator, requiredTrueElementValidator, textBoxElementValueAccessor, translate, typography, when } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import { Icons } from '../../Resources/Icons/Icons';
import { COMPONENTS } from '../../Services/ComponentsService';
import type { AuthView } from './AuthView';

// #endregion

/**
 * The template of the {@link AuthView}.
 *
 * @public
 */
export function authViewTemplate<T extends AuthView>(e: T): TemplateResult {
    const list = COMPONENTS.getComponents().map((x) => 'like '.concat(`*${x.name}*.`));

    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.auth')}"></title>
        </mosaik-helmet>
        <mosaik-page part="page">
            <mosaik-page-content ${flex({ direction: 'row' })}>
                ${when(!e.isMobile, () => html`
                <mosaik-stack ${flex({ fill: 4 })}
                              class="bg"
                              .verticalAlignment="${VerticalAlignment.Center}"
                              .horizontalAlignment="${HorizontalAlignment.Center}"
                              .orientation="${Orientation.Vertical}">
                    <app-logo ${mouseMovementChild()}
                              ${animate({ ...Animate.slideInBottom })}
                              part="logo"
                              .type="${'both'}"></app-logo>
                    <mosaik-spacer thickness="all"></mosaik-spacer>
                        <mosaik-text ${mouseMovementChild()} ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 } } })}
                                     ${typography('headline5')}
                                     .text="${translate('loc.landing.description')}"
                                     .alignment="${TextAlignment.Center}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <app-typewriter ${mouseMovementChild()} ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 } } })}
                                        ${typography('headline5')}
                                        .toRotate="${list}"
                                        .period="${3000}"
                                        .variant="${Variant.Primary}"
                                        .formatter="${TextFormatters.RICHTEXT}"
                                        .alignment="${TextAlignment.Center}"></app-typewriter>
                </mosaik-stack>
                `)}
                <mosaik-stack ${flex({ fill: 2 })}
                              .fit="${Fit.Both}">
                    <mosaik-box style="--box-background-color: var(--theme-background-color);"
                                .fit="${Fit.Both}" >
                        <mosaik-stack .fit="${Fit.Both}"
                                      .orientation="${Orientation.Vertical}">
                            <mosaik-button .label="${translate('Back')}" .icon="${Icons.ARROW_LEFT}"
                                           .iconPosition="${IconPosition.Before}"
                                           .href="${'/'}"
                                           .appearance="${Appearance.Plain}"></mosaik-button>
                            ${when(e.isMobile, () => html`
                            <mosaik-spacer .thickness="${Spacing.All}">
                            <app-logo ${mouseMovementChild()}
                                      ${animate({ ...Animate.slideInBottom })}
                                      part="logo"
                                      .type="${'both'}"></app-logo>
                            </mosaik-spacer>
                            `)}
                            <mosaik-stack .verticalAlignment="${!e.isMobile ? VerticalAlignment.Center : VerticalAlignment.Top}"
                                          .horizontalAlignment="${!e.isMobile ? HorizontalAlignment.Center : HorizontalAlignment.Stretch}"
                                          .orientation="${Orientation.Vertical}">
                                ${when(!e.isUnverified, () => html`
                                <mosaik-tab part="tab"
                                            .appearance="${Appearance.Plain}"
                                            .variant="${Variant.Primary}"
                                            .alignment="${TabPanelAlignment.Center}"
                                            ${animate({ ...Animate.slideInTop })}>
                                    <mosaik-tab-item .label="${translate('loc.auth.login.header')}">
                                        <mosaik-spacer .thickness="${Spacing.All}">
                                            <mosaik-form ${flex({ direction: 'column', gap: '16px' })}
                                                         name="login">
                                                <mosaik-form-field name="login"
                                                                   .accessor="${textBoxElementValueAccessor}"
                                                                   .validators="${[requiredElementValidator()]}">
                                                    <mosaik-textbox .label="${translate('loc.auth.login.login.label')}"
                                                                    .variant="${Variant.Primary}"
                                                                    ?required="${true}"></mosaik-textbox>
                                                </mosaik-form-field>
                                                <mosaik-form-field name="password"
                                                                   .accessor="${passwordBoxElementValueAccessor}"
                                                                   .validators="${[requiredElementValidator()]}">
                                                    <mosaik-passwordbox .label="${translate('loc.auth.login.password.label')}"
                                                                        .revealable="${true}"
                                                                        .variant="${Variant.Primary}"
                                                                        ?required="${true}"></mosaik-passwordbox>
                                                </mosaik-form-field>
                                                <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                                    <mosaik-anchor .label="${translate('loc.auth.login.actions.forgot.label')}"
                                                                  .variant="${Variant.Primary}"
                                                                  @click="${() => e.passwordForgotCommand.execute()}"></mosaik-anchor>
                                                </mosaik-stack>
                                                <mosaik-button .appearance="${Appearance.Solid}"
                                                            .label="${translate('loc.auth.login.actions.login.label')}"
                                                            .variant="${Variant.Primary}"
                                                            .isBusy="${e.isBusy}"
                                                            @click="${() => e.submitCommand.execute('login')}"></mosaik-button>
                                            </mosaik-form>
                                            <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
                                            <mosaik-divider></mosaik-divider>
                                            <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
                                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                          .gap="${'8px'}">
                                                <mosaik-tooltip .content="${translate('loc.auth.login.actions.github.label')}">
                                                    <mosaik-button ${flex({ fill: true })}
                                                                   .icon="${'M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z'}"
                                                                   .appearance="${Appearance.Solid}"
                                                                   ?disabled="${true}"></mosaik-button>
                                                </mosaik-tooltip>
                                                <mosaik-tooltip .content="${translate('loc.auth.login.actions.google.label')}">
                                                    <mosaik-button ${flex({ fill: true })}
                                                                   .icon="${'M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z'}"
                                                                   .appearance="${Appearance.Solid}"
                                                                   ?disabled="${true}"></mosaik-button>
                                                </mosaik-tooltip>
                                                <mosaik-tooltip .content="${translate('loc.auth.login.actions.microsoft.label')}">
                                                    <mosaik-button ${flex({ fill: true })}
                                                                   .icon="${'M11 11H2V2h9zm11-9h-9v9h9zM11 13H2v9h9zm11 0h-9v9h9z'}"
                                                                   .appearance="${Appearance.Solid}"
                                                                   ?disabled="${true}"></mosaik-button>
                                                </mosaik-tooltip>
                                                <mosaik-tooltip .content="${translate('loc.auth.login.actions.apple.label')}">
                                                    <mosaik-button ${flex({ fill: true })}
                                                                   .icon="${'M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701'}"
                                                                   .appearance="${Appearance.Solid}"
                                                                   ?disabled="${true}"></mosaik-button>
                                                </mosaik-tooltip>
                                            </mosaik-stack>

                                        </mosaik-spacer>
                                    </mosaik-tab-item>
                                    <mosaik-tab-item .label="${translate('loc.auth.register.label')}">
                                        <mosaik-spacer .thickness="${Spacing.All}">
                                                    <mosaik-form name="register"
                                                                 ${flex({ direction: 'column', gap: '16px' })}>
                                                        <mosaik-form-field name="firstName"
                                                                           .accessor="${textBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator()]}">
                                                            <mosaik-textbox .label="${translate('loc.auth.register.firstName.label')}"
                                                                            .variant="${Variant.Primary}"
                                                                            ?required="${true}"></mosaik-textbox>
                                                        </mosaik-form-field>
                                                        <mosaik-form-field name="lastName"
                                                                           .accessor="${textBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator()]}">
                                                            <mosaik-textbox .label="${translate('loc.auth.register.lastName.label')}"
                                                                            .variant="${Variant.Primary}"
                                                                            ?required="${true}"></mosaik-textbox>
                                                        </mosaik-form-field>
                                                        <mosaik-form-field name="email"
                                                                           .accessor="${textBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator(), emailElementValidator()]}">
                                                            <mosaik-textbox .label="${translate('loc.auth.register.email.label')}"
                                                                            .variant="${Variant.Primary}"
                                                                            ?required="${true}"></mosaik-textbox>
                                                        </mosaik-form-field>
                                                        <mosaik-form-field name="userName"
                                                                           .accessor="${textBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator()]}">
                                                            <mosaik-textbox .label="${translate('loc.auth.register.username.label')}"
                                                                            .variant="${Variant.Primary}"
                                                                            ?required="${true}"></mosaik-textbox>
                                                        </mosaik-form-field>
                                                        <mosaik-form-field name="password"
                                                                           .accessor="${passwordBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator()]}">
                                                            <mosaik-passwordbox part="password"
                                                                                .label="${translate('loc.auth.register.password.label')}"
                                                                                .variant="${Variant.Primary}"
                                                                                ?required="${true}"
                                                                                ?revealable="${true}"
                                                                                .isReveal="${e.isPasswordShown}"
                                                                                @revealed="${(_x: IPasswordRevealedEvent) => e.passwordRevealCommand.execute()}"
                                                                                @concealed="${(_x: IPasswordConcealedEvent) => e.passwordConcealCommand.execute()}">></mosaik-passwordbox>
                                                        </mosaik-form-field>
                                                        <mosaik-form-field name="passwordConfirm"
                                                                           .accessor="${passwordBoxElementValueAccessor}"
                                                                           .validators="${[requiredElementValidator(), equalsToElementValidator(() => query(e, '[part="password"]', { shadow: true, strict: true }))]}">
                                                            <mosaik-passwordbox .label="${translate('loc.auth.register.confirmPassword.label')}"
                                                                                .variant="${Variant.Primary}"
                                                                                ?required="${true}"
                                                                                ?revealable="${true}"
                                                                                .isReveal="${e.isPasswordShown}"
                                                                                @revealed="${(_x: IPasswordRevealedEvent) => e.passwordRevealCommand.execute()}"
                                                                                @concealed="${(_x: IPasswordConcealedEvent) => e.passwordConcealCommand.execute()}"></mosaik-passwordbox>
                                                        </mosaik-form-field>
                                                        <!-- <mosaik-form-field name="terms"
                                                                           .accessor="${checkBoxElementValueAccessor}"
                                                                           .validators="${[requiredTrueElementValidator()]}">
                                                            <mosaik-checkbox .label="${translate('loc.auth.register.terms.label')}"
                                                                             .variant="${Variant.Primary}"
                                                                             .appearance="${Appearance.Outline}"
                                                                             ?required="${true}"></mosaik-checkbox>
                                                        </mosaik-form-field> -->
                                                        <mosaik-button .appearance="${Appearance.Solid}"
                                                                       .label="${translate('loc.auth.register.actions.register.label')}"
                                                                       .variant="${Variant.Primary}"
                                                                       .isBusy="${e.isBusy}"
                                                                       @click="${() => e.submitCommand.execute('register')}"></mosaik-button>
                                                    </mosaik-form>
                                        </mosaik-spacer>
                                    </mosaik-tab-item>
                                </mosaik-tab>
                                `)}
                                ${when(e.isUnverified, () => html`
                                <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                              .horizontalAlignment="${HorizontalAlignment.Center}"
                                              .orientation="${Orientation.Vertical}"
                                              ${animate({ ...Animate.slideInTop })}>
                                    <mosaik-success-state .header="${translate('loc.auth.verify.header')}"
                                                          .content="${translate('loc.auth.verify.content')}">
                                        <mosaik-spacer .thickness="${Spacing.Vertical}">
                                            <mosaik-pinbox .mode="${PinMode.Text}"
                                                        .variant="${Variant.Primary}"
                                                        .length="${6}"
                                                        .disabled="${e.isBusy}"
                                                        @changed="${(x: IPropertyChangedEvent) => e.pinChangedCommand.execute(x.detail)}"></mosaik-pinbox>
                                            ${when(e.countdown > 0, () => html`
                                            <mosaik-hint .text="${'Tthe token expires in '}${e.countdown} seconds"></mosaik-hint>
                                            `, () => html`
                                            <mosaik-error .text="${'The token has expired'}"></mosaik-error>
                                            `)}
                                        </mosaik-spacer>
                                        <mosaik-text .text="${translate('loc.auth.verify.hint')}"
                                                    .readonly="${true}"></mosaik-text>
                                        <mosaik-anchor .label="${translate('loc.auth.verify.actions.verify')}"
                                                    .variant="${Variant.Primary}"
                                                    @click="${() => e.pinResetCommand.execute()}"></mosaik-anchor>
                                    </mosaik-success-state>
                                </mosaik-stack>
                                `)}
                            </mosaik-stack>
                            <mosaik-footer part="footer"
                                           .breakpoint="${'md'}"
                                           .brand="${globals.company}">
                                <div ${flex({ fill: 1, alignItems: !e.isMobile ? 'center' : 'flex-start' })}
                                     slot="start">
                                    <!-- <mosaik-footer-item-group>
                                        ${repeat(globals.sites.filter((x) => !x.external), (x) => x, (x) => html`
                                        <mosaik-footer-item>
                                            <mosaik-router-link .href="${x.url}">
                                                <mosaik-anchor .label="${translate(`loc.nav.${x.name}`)}"
                                                               .underline="${HyperlinkUnderline.Never}"></mosaik-anchor>
                                            </mosaik-router-link>
                                        </mosaik-footer-item>
                                        `)}
                                    </mosaik-footer-item-group> -->
                                </div>
                            </mosaik-footer>
                        </mosaik-stack>
                    </mosaik-box>
                </mosaik-stack>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
