//#region Imports

import { html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type BackTopElement } from './BackTopElement';

//#endregion

/**
 * The template of the {@link BackTopElement}.
 *
 * @public
 */
export function backTopElementTemplate<T extends BackTopElement>(e: T): TemplateResult {
    return html`
        <div part="root">
            <mosaik-fab part="button"
                        .icon="${'M5.25 3.495h13.498a.75.75 0 0 0 .101-1.493l-.101-.007H5.25a.75.75 0 0 0-.102 1.493l.102.007Zm6.633 18.498L12 22a1 1 0 0 0 .993-.884L13 21V8.41l3.294 3.292a1 1 0 0 0 1.32.083l.094-.083a1 1 0 0 0 .083-1.32l-.083-.094-4.997-4.997a1 1 0 0 0-1.32-.083l-.094.083-5.004 4.996a1 1 0 0 0 1.32 1.499l.094-.083L11 8.415V21a1 1 0 0 0 .883.993Z'}"
                        @click="${() => e.onClick()}"></mosaik-fab>
        </div>
    `;
}

