//#region Imports

import { html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type CountdownElement } from './CountdownElement';

//#endregion

/**
 * The template of the {@link CountdownElement}.
 *
 * @public
 */
export function countdownElementTemplate<T extends CountdownElement>(e: T): TemplateResult {
    return html`
        <div part="countdown">
            <div part="column">
                <div part="header">${e.intl.days}</div>
                <div>
                    <div part="text">${e.format(e.days)}</div>
                </div>
            </div>
            <div part="column">
                <div part="header">${e.intl.hours}</div>
                <div>
                    <div part="text">${e.format(e.hours)}</div>
                </div>
            </div>
            <div part="column">
                <div part="header">${e.intl.minutes}</div>
                <div>
                    <div part="text">${e.format(e.minutes)}</div>
                </div>
            </div>
            <div part="column">
                <div part="header">${e.intl.seconds}</div>
                <div>
                    <div part="text">${e.format(e.seconds)}</div>
                </div>
            </div>
        </div>
    `;
}
