//#region Imports

import { html, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type MadeWithLoveElement } from './MadeWithLoveElement';

//#endregion

/**
 * The template of the {@link MadeWithLoveElement}.
 *
 * @public
 */
export function madeWithLoveElementTemplate<T extends MadeWithLoveElement>(e: T): TemplateResult {
    return html`
        <span>
            Made with <span class="symbol pulse">🧻</span>
            ${when(e.by, () => html`
            <span> by</span>
            `)}
            ${when(e.url, () => html`
            <a target="_blank" href="${e.url}">{{ this.by }}</a>
            `, () => html`
            <span>${e.by}</span>
            `)}
            <slot></slot>
        </span>
    `;
}
