// #region Imports

import { Attribute, Component, CustomElement, Property, Watch } from '@breadstone/mosaik-elements-foundation';
import { ResizeMode } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/Resize';
import { LanguageSupport } from '@codemirror/language';
import { EditorState, Extension, StateEffect } from '@codemirror/state';
import { EditorView, ViewUpdate } from '@codemirror/view';
import { codeEditorElementStyle } from './CodeEditorElementStyle';
import { codeEditorElementTemplate } from './CodeEditorElementTemplate';
import { CodeFormatter } from './CodeEditorFormatter';
import { lineNumber } from './Extension/LineNumbers';
import { ICodeEditorElementProps } from './ICodeEditorElementProps';

// #endregion

/**
 * The `{@link CodeEditorElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-code-editor',
    template: codeEditorElementTemplate,
    styles: codeEditorElementStyle,
    imports: []
})
export class CodeEditorElement extends CustomElement implements ICodeEditorElementProps {

    // #region Fields

    private _text: string;
    private _readonly: boolean;
    private _lineNumbers: boolean;
    private _lineWrapping: boolean;
    private _view: EditorView | null;
    private _language: LanguageSupport | null;
    private _theme: Extension | null;
    private _resize: ResizeMode;
    private _formatter: CodeFormatter;
    private _isFormatted: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._text = '';
        this._readonly = false;
        this._lineNumbers = false;
        this._lineWrapping = false;
        this._view = null;
        this._language = null;
        this._theme = null;
        this._resize = ResizeMode.None;
        this._formatter = new CodeFormatter();
        this._isFormatted = false;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-code-editor';
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    /**
     * Gets or sets the `languange` property.
     *
     * @public
     */
    @Property({ type: LanguageSupport })
    public get language(): LanguageSupport | null {
        return this._language;
    }

    public set language(value: LanguageSupport | null) {
        if (this._language !== value) {
            this._language = value;
            this.requestUpdate('language');
        }
    }

    /**
     * Gets or sets the `readonly` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get readonly(): boolean {
        return this._readonly;
    }

    public set readonly(value: boolean) {
        if (this._readonly !== value) {
            this._readonly = value;
            this.requestUpdate('readonly');
        }
    }

    /**
     * Gets or sets the `lineNumbers` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get lineNumbers(): boolean {
        return this._lineNumbers;
    }

    public set lineNumbers(value: boolean) {
        if (this._lineNumbers !== value) {
            this._lineNumbers = value;
            this.requestUpdate('lineNumbers');
        }
    }

    /**
     * Gets or sets the `lineWrapping` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get lineWrapping(): boolean {
        return this._lineWrapping;
    }

    public set lineWrapping(value: boolean) {
        if (this._lineWrapping !== value) {
            this._lineWrapping = value;
            this.requestUpdate('lineWrapping');
        }
    }

    /**
     * Gets or sets the `theme` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get theme(): Extension | null {
        return this._theme;
    }

    public set theme(value: Extension | null) {
        if (this._theme !== value) {
            this._theme = value;
            this.requestUpdate('theme');
        }
    }

    /**
     * Gets or sets the `resize` property.
     *
     * @public
     */
    @Attribute({ type: ResizeMode })
    public get resize(): ResizeMode {
        return this._resize;
    }

    public set resize(value: ResizeMode) {
        if (this._resize !== value) {
            this._resize = value;
            this.requestUpdate('resize');
        }
    }

    /**
     * Gets or sets the `formatter` property.
     *
     * @public
     */
    @Property({ type: CodeFormatter })
    public get formatter(): CodeFormatter {
        return this._formatter;
    }

    public set formatter(value: CodeFormatter) {
        if (this._formatter !== value) {
            this._formatter = value;
            this.requestUpdate('formatter');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override disconnectedCallback(): void {
        super.disconnectedCallback();

        if (this._view) {
            this._view.destroy();
        }
    }

    /**
     * @protected
     * @override
     */
    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        const state = EditorState.create({
            doc: this.text,
            extensions: [
                // basicSetup()
                EditorState.readOnly.of(this.readonly),
                EditorView.editable.of(false),
                EditorView.lineWrapping,
                EditorView.updateListener.of((x) => this.onEditorUpdate(x)),
                lineNumber(false),
                this.language ?? [],
                this._theme ?? []
            ]
        });

        this._view = new EditorView({
            state,
            parent: this.getTemplatePart('editor')
        });
    }

    protected onEditorUpdate(update: ViewUpdate): void {
        if (update.docChanged && !this._isFormatted) {
            this._isFormatted = false;
            this._text = update.state.doc.toString();
            void this.autoFormat().then(() => {
                this.dispatchEvent(new CustomEvent('input', { detail: this._text }));
            });
        }
    }

    /**
     * @protected
     */
    @Watch('text')
    protected onTextPropertyChanged(_prev?: string, next?: string): void {
        if (this._view) {
            this._view.dispatch({
                changes: {
                    from: 0,
                    to: this._view.state.doc.length,
                    insert: next
                }
            });
        }
    }

    /**
     * @protected
     */
    @Watch('readonly')
    protected onReadonlyPropertyChanged(_prev?: boolean, next?: boolean): void {
        if (this._view) {
            const readonlyEffect = StateEffect.reconfigure.of(EditorState.readOnly.of(next ?? false));
            this._view.dispatch({ effects: [readonlyEffect] });
        }
    }

    /**
     * @protected
     */
    @Watch('lineNumbers')
    protected onLineNumbersPropertyChanged(_prev?: boolean, next?: boolean): void {
        if (this._view && next) {
            const lineNumbersEffect = StateEffect.reconfigure.of(lineNumber(next));
            this._view.dispatch({ effects: [lineNumbersEffect] });
        }
    }

    /**
     * @protected
     */
    @Watch('lineWrapping')
    protected onLineWrappingPropertyChanged(_prev?: boolean, next?: boolean): void {
        if (this._view && next) {
            const lineNumbersEffect = StateEffect.reconfigure.of(EditorView.lineWrapping);
            this._view.dispatch({ effects: [lineNumbersEffect] });
        }
    }

    /**
     * @protected
     */
    @Watch('language')
    protected onLanguagePropertyChanged(_prev?: LanguageSupport, next?: LanguageSupport): void {
        if (this._view && next) {
            const languageEffect = StateEffect.reconfigure.of(next);
            this._view.dispatch({ effects: [languageEffect] });
        }
    }

    /**
     * @protected
     */
    @Watch('theme')
    protected onThemePropertyChanged(_prev?: Extension, next?: Extension): void {
        if (this._view && next) {
            const themeEffect = StateEffect.reconfigure.of(next);
            this._view.dispatch({ effects: [themeEffect] });
        }
    }

    /**
     * @private
     */
    private async autoFormat(): Promise<void> {
        if (!this._view) {
            return;
        }

        const languageName = this.language?.language.name ?? '';

        try {
            const formattedText = await this.formatter.format(this.text, languageName);
            this._isFormatted = true;
            this._view.dispatch({
                changes: {
                    from: 0,
                    to: this._view.state.doc.length,
                    insert: formattedText
                }
            });
        } catch (error) {
            console.error('Formatting failed:', error);
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-code-editor': CodeEditorElement;
    }
}
