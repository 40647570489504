export class ApiError extends Error {

    // #region Fields

    private readonly _statusCode: number;
    private readonly _statusTest: unknown;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor(statusCode: number, statusTest: string, message: string) {
        super(message);

        this._statusCode = statusCode;
        this._statusTest = statusTest;
        super.message = message;
    }

    // #endregion

    // #region Properties

    /**
     * Gets the `statusCode` property.
     *
     * @public
     */
    public get statusCode(): number {
        return this._statusCode;
    }

    /**
     * Gets the `statusTest` property.
     *
     * @public
     */
    public get statusTest(): unknown {
        return this._statusTest;
    }

    // #endregion

}
