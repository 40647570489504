//#region Imports

import { html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type TocElement } from './TocElement';

//#endregion

/**
 * The template of the {@link TocElement}.
 *
 * @public
 */
export function tocElementTemplate<T extends TocElement>(e: T): TemplateResult {
    return html`
        <slot name="toc"></slot>
        <!-- <nav part="nav">
            <h3 part="header">On this page</h3>
            <ol part="list">
                <li part="listItem">
                    <a part="listItemLink"
                       href="#default"
                       target="_self">Default</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#shape"
                       target="_self">Shape</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#appearance"
                       target="_self">Appearance</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#icon"
                       target="_self">Icon</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#size"
                       target="_self">Size</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#disabled"
                       target="_self">Disabled</a>
                </li>
                <li part="listItem">
                    <a part="listItemLink"
                       href="#with-long-text"
                       target="_self">With Long Text</a>
                </li>
            </ol>
        </nav> -->
    `;
}
