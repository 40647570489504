// #region Imports

import { html, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { PlaygroundElement } from './PlaygroundElement';

// #endregion

/**
 * The template of the {@link PlaygroundElement}.
 *
 * @public
 */
export function playgroundElementTemplate<T extends PlaygroundElement>(e: T): TemplateResult {
    return html`
        <style>
            :host {
                --playground-zoom: ${e.viewPort?.zoom ? e.viewPort.zoom / 100 : 'unset'};
                --playground-viewport-height : ${e.viewPort?.viewPort.height ? `${e.viewPort.viewPort.height}` : 'unset'};
                --playground-viewport-width: ${e.viewPort?.viewPort.width ? `${e.viewPort.viewPort.width}` : 'unset'};
            }
        </style>
        <div part="container" class="${e.viewPort?.visualize ? 'visualize' : ''}">
            ${when(e.resizeable, () => html`
            <mosaik-resize-adorner .disabled="${!e.resizeable}">
                ${when(e.ofElement instanceof HTMLElement, () => e.ofElement, () => html`${e.ofElement}`)}
            </mosaik-resize-adorner>
            `, () => html`
            ${when(e.ofElement instanceof HTMLElement, () => e.ofElement, () => html`${e.ofElement}`)}
            `)}
        </div>
        <slot></slot>
    `;
}
