// #region Imports

import { ColorSwatchElement, Component } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { colorSwatchElementDocs } from '../../../.Generated/mosaik-color-swatch';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ColorSwatchSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-color-swatch-sample'
})
export class ColorSwatchSampleElement extends SampleBaseElement<ColorSwatchElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ColorSwatchElement> {
        return {
            header: 'Color Swatch',
            description: META.description(ColorSwatchElement.is),
            graph: META.graph(ColorSwatchElement.is),
            playground: {
                template: html`
                    <mosaik-color-swatch></mosaik-color-swatch>
                `,
                properties: [

                ],
                events: META.events<ColorSwatchElement>(ColorSwatchElement.is)
            },
            propertyGrid: {
                properties: META.properties<ColorSwatchElement>(ColorSwatchElement.is, [

                ])
            },
            docs: [
                colorSwatchElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-color-swatch-sample': ColorSwatchSampleElement;
    }
}
