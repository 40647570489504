// #region Imports

import { CSSResultGroup, Component, FlowDirection, PerspectiveElement, html } from '@breadstone/mosaik-elements-foundation';
import { perspectiveElementDocs } from '../../../.Generated/mosaik-perspective';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { timeTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link PerspectiveSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-perspective-sample'
})
export class PerspectiveSampleElement extends SampleBaseElement<PerspectiveElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<PerspectiveElement> {
        return {
            header: 'Perspective',
            description: META.description(PerspectiveElement.is),
            graph: META.graph(PerspectiveElement.is),
            playground: {
                template: (props, set, _get) => html`
                    <div id="my-id"
                         style="background: black; width: 320px; height: 120px; position: relative">
                        <mosaik-perspective .for="${'my-id'}"
                                            .duration="${props.get('duration').value}"
                                            .disabled="${props.get('disabled').value}"
                                            .factor="${10}"></mosaik-perspective>
                        <mosaik-text .text="${'Click me'}"></mosaik-text>
                    </div>
                `,
                properties: META.properties<PerspectiveElement>(PerspectiveElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'duration',
                        value: 200
                    }
                ]),
                events: META.events(PerspectiveElement.is)
            },
            propertyGrid: {
                exludedProperties: ['control', 'for'],
                properties: merge(META.properties<PerspectiveElement>(PerspectiveElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'duration',
                        value: 0
                    }
                ]), [{
                    key: 'duration',
                    template: timeTemplate
                }] as Array<IPlaygroundProperty<PerspectiveElement>>, (a, b) => a.key === b.key)
            },
            docs: [perspectiveElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-perspective-sample': PerspectiveSampleElement;
    }
}
