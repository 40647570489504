// #region Imports

import { Command, Component, DialogServiceLocator, ICommand, Inject, IThemeLayout, IThemeTypography, IWritableSignal, Property, Signal, TrackableSignal } from '@breadstone/mosaik-elements-foundation';
import { IFeatureResponse } from '../../../Backend/Api/Models/IFeatureResponse';
import { globals } from '../../../Globals';
import { FeatureManager, IFeatureFlagInfo } from '../../../Services/FeatureManager';
import { ViewBase } from '../../../Views/Abstracts/ViewBase';
import { appSettingsViewTemplate } from '../../../Views/App/Dialogs/AppSettingsViewTemplate';
import { appSettingsViewStyle } from './AppSettingsViewStyle';

// #endregion

interface IAppSettingsViewData {
    scheme: string;
    preset: IPresetInfo;
    layout: Pick<IThemeLayout, 'radius'>;
    font: Pick<IThemeTypography, 'fontFamily'>;
    language: string;
}

interface IPresetInfo {
    key: string;
    default: boolean;
    primary: string;
    secondary: string;
    tertiary: string;
}

/**
 * The `{@link AppSettingsView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-settings-view',
    template: appSettingsViewTemplate,
    styles: appSettingsViewStyle,
    imports: []
})
export class AppSettingsView
    extends ViewBase {

    // #region Fields

    @Inject(FeatureManager)
    private readonly _featureManager!: FeatureManager;
    private readonly _fonts: Array<string>;
    private readonly _languages: typeof globals.languages;
    private readonly _scheme: TrackableSignal<string>;
    private readonly _preset: TrackableSignal<IPresetInfo>;
    private readonly _layout: TrackableSignal<Pick<IThemeLayout, 'radius'>>;
    private readonly _font: TrackableSignal<Pick<IThemeTypography, 'fontFamily'>>;
    private readonly _language: TrackableSignal<string>;
    private readonly _hasFeatureChanges: Signal<boolean>;
    private _features: Array<IFeatureResponse>;
    private readonly _changeFeatureFlagCommand: Command<{
        flag: IFeatureFlagInfo;
        state: boolean;
    }>;
    private readonly _resetCommand: Command;
    private readonly _closeCommand: Command;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor(data: IAppSettingsViewData) {
        super();

        this._features = new Array<IFeatureResponse>();
        this._fonts = globals.theme.typographies.map((x) => x.fontFamily);
        this._languages = globals.languages;
        this._scheme = TrackableSignal.from(data.scheme);
        this._preset = TrackableSignal.from(data.preset);
        this._layout = TrackableSignal.from(data.layout);
        this._font = TrackableSignal.from(data.font);
        this._language = TrackableSignal.from(data.language);
        this._hasFeatureChanges = Signal.from(false);
        this._changeFeatureFlagCommand = new Command((x) => this.onExecuteChangeFeatureFlagCommand(x));
        this._resetCommand = new Command(() => this.onExecuteResetCommand());
        this._closeCommand = new Command(() => this.onExecuteCloseCommand());
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-settings-view';
    }

    /**
     * Gets or sets the `scheme` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: String })
    public get scheme(): IWritableSignal<string> {
        return this._scheme;
    }

    /**
     * Gets or sets the `preset` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    public get preset(): IWritableSignal<IPresetInfo> {
        return this._preset;
    }

    /**
     * Gets or sets the `layout` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    public get layout(): IWritableSignal<Pick<IThemeLayout, 'radius'>> {
        return this._layout;
    }

    /**
     * Gets or sets the `layout` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    public get font(): IWritableSignal<Pick<IThemeTypography, 'fontFamily'>> {
        return this._font;
    }

    /**
     * Gets or sets the `language` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: String })
    public get language(): IWritableSignal<string> {
        return this._language;
    }

    /**
     * Gets or sets the `features` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get features(): Array<IFeatureResponse> {
        return this._features;
    }
    public set features(value: Array<IFeatureResponse>) {
        if (this._features !== value) {
            this._features = value;
            this.requestUpdate('features');
        }
    }

    /**
     * Gets or sets the `hasFeatureChanges` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Boolean })
    public get hasFeatureChanges(): IWritableSignal<boolean> {
        return this._hasFeatureChanges;
    }

    /**
     * Returns the `fonts` property.
     *
     * @public
     * @readonly
     */
    public get fonts(): Array<string> {
        return this._fonts;
    }

    /**
     * Returns the `languages` property.
     *
     * @public
     * @readonly
     */
    public get languages(): typeof globals.languages {
        return this._languages;
    }

    /**
     * Returns the `changeFeatureFlagCommand` command property.
     *
     * @public
     * @readonly
     */
    public get changeFeatureFlagCommand(): ICommand<{
        flag: IFeatureFlagInfo;
        state: boolean;
    }> {
        return this._changeFeatureFlagCommand;
    }

    /**
     * Returns the `resetCommand` command property.
     *
     * @public
     * @readonly
     */
    public get resetCommand(): ICommand {
        return this._resetCommand;
    }

    /**
     * Returns the `closeCommand` command property.
     *
     * @public
     * @readonly
     */
    public get closeCommand(): ICommand { return this._closeCommand; }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.initialize();
    }

    /**
     * @private
     */
    private initialize(): void {
        this._features = this._featureManager.features;
        this._featureManager.changes.subscribe(() => {
            this.hasFeatureChanges.set(true);
        });
    }

    /**
     * Executes the `changeFeatureFlagCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteChangeFeatureFlagCommand(parameter: {
        flag: IFeatureFlagInfo;
        state: boolean;
    }): void {
        this._featureManager.changeFeature(parameter.flag.key, parameter.state);
    }

    /**
     * Executes the `resetCommand` command.
     *
     * @private
     */
    private onExecuteResetCommand(): void {
        const scheme = globals.theme.schemes.find((x) => x.default)?.key;
        if (scheme) {
            this.scheme.set(scheme);
        }

        const preset = globals.theme.presets.find((x) => x.default);
        if (preset) {
            this.preset.set(preset);
        }

        this.layout.set({ radius: globals.theme.layout.radius });
        this.font.set({ fontFamily: globals.theme.typographies.find((x) => x.default)?.fontFamily ?? '' });
    }

    /**
     * Executes the `closeCommand` command.
     *
     * @private
     */
    private onExecuteCloseCommand(): void {
        void DialogServiceLocator.current.close(AppSettingsView.is, {
            scheme: this._scheme.get(),
            preset: this._preset.get(),
            layout: this._layout.get(),
            language: this._language.get(),
            font: this._font.get()
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-settings-view': AppSettingsView;
    }
}
