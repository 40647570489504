//#region Imports

import { Animate, Appearance, Component, HorizontalAlignment, Inset, Orientation, Size, TextFormatters, Variant, VerticalAlignment, animate, css, html, repeat, translate, when, type CSSResultGroup, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

//#endregion

/**
 * The `{@link SupportView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-support-view'
})
export class SupportView extends ViewBase {

    //#region Fields

    private readonly _browsers: Array<{ icon: string; key: string; name: string; support: number }> = [
        {
            icon: 'M12,20L15.46,14H15.45C15.79,13.4 16,12.73 16,12C16,10.8 15.46,9.73 14.62,9H19.41C19.79,9.93 20,10.94 20,12A8,8 0 0,1 12,20M4,12C4,10.54 4.39,9.18 5.07,8L8.54,14H8.55C9.24,15.19 10.5,16 12,16C12.45,16 12.88,15.91 13.29,15.77L10.89,19.91C7,19.37 4,16.04 4,12M15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12M12,4C14.96,4 17.54,5.61 18.92,8H12C10.06,8 8.45,9.38 8.08,11.21L5.7,7.08C7.16,5.21 9.44,4 12,4M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
            key: 'chrome',
            name: 'Chrome',
            support: 100
        }, {
            icon: 'M22 10.25C22 13.06 19.91 15.33 17.33 15.33C15.5 15.33 14.43 15 13.97 14.63C13.5 14.26 13.61 14 13.81 13.78C14.24 13.33 14.5 12.72 14.5 12.04C14.5 10.66 13.39 9.53 12 9.53C10.61 9.53 9.5 10.66 9.5 12.04C9.5 14.76 12.25 17.8 16.4 17.8C18.46 17.8 19.37 17.18 20.15 16.86C20.44 16.82 20.56 17.23 20.44 17.41C18.65 20.15 15.55 21.96 12 21.96C6.5 21.96 2 17.5 2 12C2 6.5 6.5 2.04 12 2.04C18.88 2.04 22 7.23 22 10.25Z',
            key: 'edge',
            name: 'Edge',
            support: 100
        }, {
            icon: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,14.09 4.8,16 6.11,17.41L9.88,9.88L17.41,6.11C16,4.8 14.09,4 12,4M12,20A8,8 0 0,0 20,12C20,9.91 19.2,8 17.89,6.59L14.12,14.12L6.59,17.89C8,19.2 9.91,20 12,20M12,12L11.23,11.23L9.7,14.3L12.77,12.77L12,12M12,17.5H13V19H12V17.5M15.88,15.89L16.59,15.18L17.65,16.24L16.94,16.95L15.88,15.89M17.5,12V11H19V12H17.5M12,6.5H11V5H12V6.5M8.12,8.11L7.41,8.82L6.35,7.76L7.06,7.05L8.12,8.11M6.5,12V13H5V12H6.5Z',
            key: 'safari',
            name: 'Safari',
            support: 50
        }, {
            icon: 'M9.27 7.94C9.27 7.94 9.27 7.94 9.27 7.94M6.85 6.74C6.86 6.74 6.86 6.74 6.85 6.74M21.28 8.6C20.85 7.55 19.96 6.42 19.27 6.06C19.83 7.17 20.16 8.28 20.29 9.1L20.29 9.12C19.16 6.3 17.24 5.16 15.67 2.68C15.59 2.56 15.5 2.43 15.43 2.3C15.39 2.23 15.36 2.16 15.32 2.09C15.26 1.96 15.2 1.83 15.17 1.69C15.17 1.68 15.16 1.67 15.15 1.67H15.13L15.12 1.67L15.12 1.67L15.12 1.67C12.9 2.97 11.97 5.26 11.74 6.71C11.05 6.75 10.37 6.92 9.75 7.22C9.63 7.27 9.58 7.41 9.62 7.53C9.67 7.67 9.83 7.74 9.96 7.68C10.5 7.42 11.1 7.27 11.7 7.23L11.75 7.23C11.83 7.22 11.92 7.22 12 7.22C12.5 7.21 12.97 7.28 13.44 7.42L13.5 7.44C13.6 7.46 13.67 7.5 13.75 7.5C13.8 7.54 13.86 7.56 13.91 7.58L14.05 7.64C14.12 7.67 14.19 7.7 14.25 7.73C14.28 7.75 14.31 7.76 14.34 7.78C14.41 7.82 14.5 7.85 14.54 7.89C14.58 7.91 14.62 7.94 14.66 7.96C15.39 8.41 16 9.03 16.41 9.77C15.88 9.4 14.92 9.03 14 9.19C17.6 11 16.63 17.19 11.64 16.95C11.2 16.94 10.76 16.85 10.34 16.7C10.24 16.67 10.14 16.63 10.05 16.58C10 16.56 9.93 16.53 9.88 16.5C8.65 15.87 7.64 14.68 7.5 13.23C7.5 13.23 8 11.5 10.83 11.5C11.14 11.5 12 10.64 12.03 10.4C12.03 10.31 10.29 9.62 9.61 8.95C9.24 8.59 9.07 8.42 8.92 8.29C8.84 8.22 8.75 8.16 8.66 8.1C8.43 7.3 8.42 6.45 8.63 5.65C7.6 6.12 6.8 6.86 6.22 7.5H6.22C5.82 7 5.85 5.35 5.87 5C5.86 5 5.57 5.16 5.54 5.18C5.19 5.43 4.86 5.71 4.56 6C4.21 6.37 3.9 6.74 3.62 7.14C3 8.05 2.5 9.09 2.28 10.18C2.28 10.19 2.18 10.59 2.11 11.1L2.08 11.33C2.06 11.5 2.04 11.65 2 11.91L2 11.94L2 12.27L2 12.32C2 17.85 6.5 22.33 12 22.33C16.97 22.33 21.08 18.74 21.88 14C21.9 13.89 21.91 13.76 21.93 13.63C22.13 11.91 21.91 10.11 21.28 8.6Z',
            key: 'firefox',
            name: 'Firefox',
            support: 50
        }, {
            icon: 'M9.04,17.07C8.04,15.9 7.4,14.16 7.35,12.21V11.79C7.4,9.84 8.04,8.1 9.04,6.93C9.86,5.95 10.93,5.37 12.1,5.37C14.72,5.37 16.84,8.34 16.84,12C16.84,15.66 14.72,18.63 12.1,18.63C10.93,18.63 10.33,18.5 9.04,17.07M12.03,3H12A9,9 0 0,0 3,12C3,16.83 6.8,20.77 11.57,21H12C14.3,21 16.4,20.13 18,18.71C19.84,17.06 21,14.67 21,12C21,9.33 19.84,6.94 18,5.29C16.41,3.87 14.32,3 12.03,3Z',
            key: 'opera',
            name: 'Opera',
            support: 50
        }, {
            icon: 'M13,3L14,3.06C16.8,1.79 19.23,1.64 20.5,2.92C21.5,3.93 21.58,5.67 20.92,7.72C21.61,9 22,10.45 22,12L21.95,13H9.08C9.45,15.28 11.06,17 13,17C14.31,17 15.47,16.21 16.2,15H21.5C20.25,18.5 16.92,21 13,21C11.72,21 10.5,20.73 9.41,20.25C6.5,21.68 3.89,21.9 2.57,20.56C1,18.96 1.68,15.57 4,12C4.93,10.54 6.14,9.06 7.57,7.65L8.38,6.88C7.21,7.57 5.71,8.62 4.19,10.17C5.03,6.08 8.66,3 13,3M13,7C11.21,7 9.69,8.47 9.18,10.5H16.82C16.31,8.47 14.79,7 13,7M20.06,4.06C19.4,3.39 18.22,3.35 16.74,3.81C18.22,4.5 19.5,5.56 20.41,6.89C20.73,5.65 20.64,4.65 20.06,4.06M3.89,20C4.72,20.84 6.4,20.69 8.44,19.76C6.59,18.67 5.17,16.94 4.47,14.88C3.27,17.15 3,19.07 3.89,20Z',
            key: 'ie',
            name: 'Internet Explorer',
            support: 0
        }
    ];

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-support-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    //#endregion

    //#region Methods

    /**
     * @protected
     */
    protected render(): TemplateResult {
        return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.support')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                    .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/support'}"
                                    .label="${translate('loc.nav.support')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-header .text="${translate('loc.nav.support')}"
                                .inset="${Inset.Horizontal}"></mosaik-page-header>
            <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-text ?wrap="${true}"
                             .formatter="${TextFormatters.RICHTEXT}"
                             .text="${translate('loc.support.1.text', globals.name)}"></mosaik-text>
                <mosaik-spacer thickness="top"></mosaik-spacer>
                <mosaik-text ?wrap="${true}"
                             .formatter="${TextFormatters.RICHTEXT}"
                             .text="${translate('loc.support.2.text', globals.name)}"></mosaik-text>
                <mosaik-spacer thickness="top"></mosaik-spacer>
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'32px'}">
                    <mosaik-masonry .gap="${'32px'}"
                                    .columns="${this.isMobile ? 1 : 2}">
                        ${repeat(this._browsers, x => x.name, x => html`
                        <mosaik-card .appearance="${Appearance.Outline}">
                            <mosaik-card-content>
                                <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                              .horizontalAlignment="${HorizontalAlignment.Center}"
                                              .orientation="${Orientation.Vertical}"
                                              .gap="${'8px'}">
                                    <mosaik-icon .size="${Size.Giant}"
                                                .data="${x.icon}"></mosaik-icon>
                                    <mosaik-text text="${x.name}"></mosaik-text>
                                        ${when(x.support === 100, () => html`<mosaik-chip .variant="${Variant.Success}" .appearance="${Appearance.Solid}" .label="${x.support.toString().concat('%')}" ></mosaik-chip>`)}
                                        ${when(x.support === 0, () => html`<mosaik-chip .variant="${Variant.Danger}" .appearance="${Appearance.Solid}" .label="${x.support.toString().concat('%')}" ></mosaik-chip>`)}
                                        ${when(x.support < 100 && x.support > 0, () => html`<mosaik-chip .variant="${Variant.Warning}" .appearance="${Appearance.Solid}" .label="${x.support.toString().concat('%')}"></mosaik-chip>`)}
                                </mosaik-stack>
                            </mosaik-card-content>
                            <mosaik-card-footer>
                                <mosaik-text .text="${translate('loc.support.browsers.'.concat(x.key), globals.name)}"
                                             .formatter="${TextFormatters.RICHTEXT}"
                                             ?wrap="${true}"></mosaik-text>
                            </mosaik-card-footer>
                        </mosaik-card>
                        `)}
                    </mosaik-masonry>
                </mosaik-stack>
            </mosaik-page-content>
        </mosaik-page>
        `;
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-support-view': SupportView;
    }
}

