//#region Imports

import { TemplateResult, html } from '@breadstone/mosaik-elements-foundation';

//#endregion

export namespace SpacerSampleTemplates {

    export function defaultTemplate(): TemplateResult {
        return html`
            <mosaik-box .highlighted="${true}" .cornerd="${true}">
                <mosaik-spacer>
                    <mosaik-box .cornerd="${true}">
                        <mosaik-text .text="${'Box 1'}"></mosaik-text>
                    </mosaik-box>
                    <mosaik-box .cornerd="${true}">
                        <mosaik-text .text="${'Box 2'}"></mosaik-text>
                    </mosaik-box>
                    <mosaik-box .cornerd="${true}">
                        <mosaik-text .text="${'Box 3'}"></mosaik-text>
                    </mosaik-box>
                </mosaik-spacer>
            </mosaik-box>
        `;
    }

}