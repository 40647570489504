// #region Imports

import { Component, ExpandDirection, ExpandableElement, FlowDirection, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { expandableElementDocs } from '../../../.Generated/mosaik-expandable';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ExpandableSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-expandable-sample'
})
export class ExpandableSampleElement extends SampleBaseElement<ExpandableElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ExpandableElement> {
        return {
            header: 'Expandable',
            description: META.description(ExpandableElement.is),
            graph: META.graph(ExpandableElement.is),
            playground: {
                template: (_props, set) => html`
                    <mosaik-expandable ${ref((el) => set(el as ExpandableElement))}>
                        <mosaik-text .wrap="${true}" .text="${'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}"></mosaik-text>
                    </mosaik-expandable>
                `,
                properties: META.properties<ExpandableElement>(ExpandableElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isExpanded',
                        value: true
                    },
                    {
                        key: 'direction',
                        value: ExpandDirection.Down
                    }
                ]),
                events: META.events(ExpandableElement.is)
            },
            propertyGrid: {
                properties: META.properties<ExpandableElement>(ExpandableElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isExpanded',
                        value: true
                    },
                    {
                        key: 'direction',
                        value: ExpandDirection.Down
                    }
                ])
            },
            docs: [
                expandableElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-expandable-sample': ExpandableSampleElement;
    }
}
