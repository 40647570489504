// #region Imports

import { type TemplateResult, html } from '@breadstone/mosaik-elements-foundation';
import type { CodeEditorElement } from './CodeEditorElement';

// #endregion

/**
 * The template of the {@link CodeEditorElement}.
 *
 * @public
 */
export function codeEditorElementTemplate<T extends CodeEditorElement>(e: T): TemplateResult {

    return html`
        <div part="editor"></div>
    `;

}
