// #region Imports

import { Animate, Appearance, ButtonType, type FormFieldElement, HorizontalAlignment, type IFilePickEvent, Inset, Orientation, Size, type TemplateResult, type TextBoxElement, Variant, VerticalAlignment, animate, avatarElementValueAccessor, flex, html, maxlengthElementValidator, notEqualsToElementValidator, query, requiredElementValidator, textBoxElementValueAccessor, toggleSwitchElementValueAccessor, translate, when } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import { Icons } from '../../Resources/Icons/Icons';
import type { ProfileView } from './ProfileView';

// #endregion

/**
 * The template of the {@link ProfileView}.
 *
 * @public
 */
export function profileViewTemplate<T extends ProfileView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.about')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md"
                     ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/profile'}"
                                        .label="${translate('loc.nav.profile')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/profile'}"
                                       .label="${e.user?.profile.fullName ?? ''}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content part="content"
                                 .verticalAlignment="${VerticalAlignment.Stretch}"
                                 .inset="${Inset.All}">
                ${when(!e.isBusy, () => html`
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'32px'}"
                              ${animate({ ...Animate.slideInBottom })}>
                    <mosaik-card .appearance="${Appearance.Outline}">
                        <mosaik-card-header .text="${translate('loc.profile.general.header')}"></mosaik-card-header>
                        <mosaik-card-content ${flex({ direction: 'column', gap: '32px' })}>
                             <mosaik-stack .orientation="${Orientation.Horizontal}" .gap="${'8px'}">
                                <mosaik-file-picker .accept="${['image/*']}"
                                                    @filePicked="${(x: IFilePickEvent) => e.addAvatarCommand.execute(x.detail)}">
                                    <mosaik-button .appearance="${Appearance.Plain}"
                                                    .size="${Size.Small}"
                                                    .label="${translate('loc.profile.general.avatar.actions.addAvatar')}"
                                                    .icon="${Icons.EDIT}"></mosaik-button>
                                </mosaik-file-picker>
                                <mosaik-button .appearance="${Appearance.Plain}"
                                                .size="${Size.Small}"
                                                .label="${translate('loc.profile.general.avatar.actions.removeAvatar')}"
                                                .icon="${Icons.DELETE}"
                                                .variant="${Variant.Danger}"
                                                ?disabled="${!e.hasAvatar}"
                                                @click="${() => e.removeAvatarCommand.execute()}"></mosaik-button>
                            </mosaik-stack>
                            <mosaik-form ${flex({ direction: 'column', gap: '32px' })}
                                         name="general">
                                <mosaik-form-field name="avatar"
                                                   .label="${translate('loc.profile.general.avatar.label')}"
                                                   .accessor="${avatarElementValueAccessor}">
                                    <mosaik-avatar part="avatar"
                                                   .src="${e.user?.profile.avatar ?? ''}"
                                                   .size="${Size.Large}"
                                                   .appearance="${Appearance.Soft}"
                                                   .icon="${Icons.PERSON}"></mosaik-avatar>
                                </mosaik-form-field>
                                <mosaik-form-field name="firstName"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[requiredElementValidator()]}"
                                                   ?required="${true}">
                                    <mosaik-textbox .label="${translate('loc.profile.general.firstName.label')}"
                                                    .value="${e.user?.profile.firstName ?? ''}"
                                                    .variant="${Variant.Primary}"
                                                    .maxlength="${50}"
                                                    ?required="${true}"></mosaik-textbox>
                                </mosaik-form-field>
                                <mosaik-form-field name="lastName"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[requiredElementValidator()]}"
                                                   ?required="${true}">
                                    <mosaik-textbox .label="${translate('loc.profile.general.lastName.label')}"
                                                    .value="${e.user?.profile.lastName ?? ''}"
                                                    .variant="${Variant.Primary}"
                                                    ?required="${true}"></mosaik-textbox>
                                </mosaik-form-field>
                                <mosaik-form-field name="bio"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[maxlengthElementValidator()]}"
                                                   .hint="${e.user?.profile.bio?.length}${'/255'}">
                                    <mosaik-textbox .label="${translate('loc.profile.general.bio.label')}"
                                                    .value="${e.user?.profile.bio ?? ''}"
                                                    .multiline="${true}"
                                                    .multilineMaxRows="${8}"
                                                    .multilineRows="${3}"
                                                    .maxlength="${255}"
                                                    .variant="${Variant.Primary}"
                                                    @input="${(x: InputEvent) => query<FormFieldElement>(e, '[name="bio"]', { strict: true, shadow: true }).hint = `${(x.target as TextBoxElement).value.length}/255`}"></mosaik-textbox>
                                </mosaik-form-field>
                            </mosaik-form>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.profile.general.actions.save')}"
                                               .variant="${Variant.Primary}"
                                               .appearance="${Appearance.Solid}"
                                               .isBusy="${e.isBusy}"
                                               .type="${ButtonType.Submit}"
                                               @click="${() => e.saveCommand.execute('general')}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-card-content>
                    </mosaik-card>

                    <mosaik-card>
                        <mosaik-card-header .text="${translate('loc.profile.changeEmail.header')}"></mosaik-card-header>
                        <mosaik-card-content ${flex({ direction: 'column', gap: '32px' })}>
                            <mosaik-form name="changeEmail"
                                         ${flex({ direction: e.isMobile ? 'column' : 'row', gap: '32px' })}>
                                <mosaik-form-field ${flex({ fill: true })}
                                                   name="currentEmail"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[requiredElementValidator()]}"
                                                   ?required="${true}">
                                    <mosaik-textbox part="currentEmail"
                                                    .label="${translate('loc.profile.changeEmail.currentEmail.label')}"
                                                    .value="${e.user?.email ?? ''}"
                                                    .variant="${Variant.Primary}"
                                                    ?readonly="${true}"
                                                    ?required="${true}"></mosaik-textbox>
                                </mosaik-form-field>
                                 <mosaik-form-field ${flex({ fill: true })}
                                                    name="newEmail"
                                                    .accessor="${textBoxElementValueAccessor}"
                                                    .validators="${[requiredElementValidator(), notEqualsToElementValidator(() => query(e, '[part="currentEmail"]', { strict: true, shadow: true }))]}"
                                                    ?required="${true}">
                                    <mosaik-textbox .label="${translate('loc.profile.changeEmail.newEmail.label')}"
                                                    .variant="${Variant.Primary}"
                                                    ?required="${true}"></mosaik-textbox>
                                </mosaik-form-field>
                            </mosaik-form>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.profile.changeEmail.actions.save')}"
                                               .variant="${Variant.Primary}"
                                               .appearance="${Appearance.Solid}"
                                               .isBusy="${e.isBusy}"
                                               .type="${ButtonType.Submit}"
                                               @click="${() => e.saveCommand.execute('changeEmail')}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-card-content>
                    </mosaik-card>

                    <mosaik-card>
                        <mosaik-card-header .text="${translate('loc.profile.changePassword.header')}"></mosaik-card-header>
                        <mosaik-card-content ${flex({ direction: 'column', gap: '32px' })}>
                            <mosaik-form name="changePassword"
                                         ${flex({ direction: e.isMobile ? 'column' : 'row', gap: '32px' })}>
                                <mosaik-form-field ${flex({ fill: true })}
                                                   name="currentPassword"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[requiredElementValidator()]}"
                                                   ?required="${true}">
                                    <mosaik-passwordbox part="currentPassword"
                                                        .label="${translate('loc.profile.changePassword.currentPassword.label')}"
                                                        .variant="${Variant.Primary}"
                                                        ?required="${true}"
                                                        ?revealable="${true}"></mosaik-passwordbox>
                                </mosaik-form-field>
                                <mosaik-form-field ${flex({ fill: true })}
                                                   name="newPassword"
                                                   .accessor="${textBoxElementValueAccessor}"
                                                   .validators="${[requiredElementValidator(), notEqualsToElementValidator(() => query(e, '[part="currentPassword"]', { strict: true, shadow: true }))]}"
                                                   ?required="${true}">
                                    <mosaik-passwordbox ${flex({ fill: true })}
                                                        .label="${translate('loc.profile.changePassword.newPassword.label')}"
                                                        .variant="${Variant.Primary}"
                                                        ?required="${true}"
                                                        ?revealable="${true}"></mosaik-passwordbox>
                                </mosaik-form-field>
                            </mosaik-form>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.profile.changePassword.actions.save')}"
                                               .variant="${Variant.Primary}"
                                               .appearance="${Appearance.Solid}"
                                               .isBusy="${e.isBusy}"
                                               .type="${ButtonType.Submit}"
                                               @click="${() => e.saveCommand.execute('changePassword')}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-card-content>
                    </mosaik-card>

                    <mosaik-card>
                        <mosaik-card-header .text="${translate('loc.profile.visibility.header')}"></mosaik-card-header>
                        <mosaik-card-content ${flex({ direction: 'column', gap: '32px' })}>
                            <mosaik-text .text="${translate('loc.profile.visibility.description')}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-banner .variant="${Variant.Warning}"
                                           .appearance="${Appearance.Soft}"
                                           .icon="${Icons.WARNING}"
                                           .header="${translate('loc.profile.visibility.banner.header')}"
                                           .subHeader="${translate('loc.profile.visibility.banner.description')}">
                            </mosaik-banner>
                            <mosaik-form name="visibility">
                                <mosaik-form-field .label="${translate('loc.profile.visibility.label')}"
                                                   .accessor="${toggleSwitchElementValueAccessor}">
                                    <mosaik-toggle-switch .isChecked="${e.user?.profile.isHidden ?? false}"
                                                          .variant="${Variant.Primary}"></mosaik-toggle-switch>
                                </mosaik-form-field>
                            </mosaik-form>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.profile.visibility.actions.save')}"
                                        .variant="${Variant.Primary}"
                                        .appearance="${Appearance.Solid}"
                                        .isBusy="${e.isBusy}"
                                        .type="${ButtonType.Submit}"
                                        @click="${() => e.saveCommand.execute('visibility')}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-card-content>
                    </mosaik-card>

                    <mosaik-card style="--card-border-color: var(--theme-danger-500-color)"
                                 .appearance="${Appearance.Outline}">
                        <mosaik-card-header .text="${translate('loc.profile.delete.header')}"></mosaik-card-header>
                        <mosaik-card-content>
                            <mosaik-text .text="${translate('loc.profile.delete.description')}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.profile.delete.actions.delete')}"
                                               .appearance="${Appearance.Solid}"
                                               .variant="${Variant.Danger}"
                                               @click="${() => { e.deleteCommand.execute(); }}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-card-content>
                    </mosaik-card>
                </mosaik-stack>
                `, () => html`
                <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                              .horizontalAlignment="${HorizontalAlignment.Center}"
                              .orientation="${Orientation.Vertical}">
                    <mosaik-progress-ring .radius="${24}"
                                          .thickness="${4}"
                                          .isIndeterminate="${true}"></mosaik-progress-ring>
                    <mosaik-text .text="${translate('Loading...')}"></mosaik-text>
                </mosaik-stack>
                `)}
            </mosaik-page-content>
        </mosaik-page>
    `;
}
