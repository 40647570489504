// #region Imports

import { Appearance, BadgeElement, ButtonElement, ButtonType, CSSResultGroup, Component, Fit, FlowDirection, HorizontalAlignment, IconPosition, Orientation, Size, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { buttonElementDocs } from '../../../.Generated/mosaik-button';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { iconSizeTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { ButtonSampleTemplates } from './ButtonSample.Templates';

// #endregion

/**
 * The `{@link ButtonSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-button-sample',
    imports: [
        ButtonElement,
        BadgeElement
    ]
})
export class ButtonSampleElement extends SampleBaseElement<ButtonElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ButtonElement> {
        return {
            header: 'Button',
            description: META.description(ButtonElement.is),
            graph: META.graph(ButtonElement.is),
            playground: {
                template: [
                    ButtonSampleTemplates.defaultTemplate(),
                    ButtonSampleTemplates.withBadgeTemplate(),
                    ButtonSampleTemplates.styledTemplate()
                ],
                properties: META.properties2<ButtonElement>(ButtonElement.is, {
                    dir: FlowDirection.Auto,
                    appearance: Appearance.Default,
                    icon: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z',
                    label: 'Label',
                    variant: Variant.Default
                }),
                events: META.events(ButtonElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<ButtonElement>(ButtonElement.is, {
                    appearance: Appearance.Default,
                    dir: FlowDirection.Auto,
                    fit: Fit.None,
                    horizontalContentAlignment: HorizontalAlignment.Center,
                    icon: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z',
                    iconPosition: IconPosition.Before,
                    iconSize: Size.Medium,
                    label: 'Label',
                    orientation: Orientation.Horizontal,
                    size: Size.Medium,
                    type: ButtonType.Button,
                    variant: Variant.Default,
                    verticalContentAlignment: VerticalAlignment.Center
                }), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    }
                    // { key: 'icon', template: iconTemplate, }
                ] as Array<IPlaygroundProperty<ButtonElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                buttonElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-button-sample': ButtonSampleElement;
    }
}
