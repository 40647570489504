// #region Imports

import { CSSResultGroup, Component, FlowDirection, TreeElement, html } from '@breadstone/mosaik-elements-foundation';
import { treeElementDocs } from '../../../.Generated/mosaik-tree';
import { treeItemElementDocs } from '../../../.Generated/mosaik-tree-item';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TreeSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tree-sample'
})
export class TreeSampleElement extends SampleBaseElement<TreeElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TreeElement> {

        return {
            header: 'Tree',
            description: META.description(TreeElement.is),
            graph: META.graph(TreeElement.is),
            playground: {
                template: html`
                    <mosaik-tree>
                        <mosaik-tree-item .text="${'Item 1'}">
                            <mosaik-tree-item .text="${'Item 1.1'}">
                                <mosaik-tree-item .text="${'Item 1.1.1'}"></mosaik-tree-item>
                                <mosaik-tree-item .text="${'Item 1.1.2'}"></mosaik-tree-item>
                                <mosaik-tree-item .text="${'Item 1.1.3'}"></mosaik-tree-item>
                            </mosaik-tree-item>
                            <mosaik-tree-item .text="${'Item 1.2'}"></mosaik-tree-item>
                            <mosaik-tree-item .text="${'Item 1.3'}" .isBusy="${true}"></mosaik-tree-item>
                        </mosaik-tree-item>
                    </mosaik-tree>
                `,
                properties: META.properties<TreeElement>(TreeElement.is, [
                    // add dir
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<TreeElement>(TreeElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }
                ])
            },
            docs: [
                treeElementDocs(),
                treeItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-tree-sample': TreeSampleElement;
    }
}
