// #region Imports

import { Component, FlowDirection, Orientation, SkeletonElement, SkeletonShape } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { skeletonElementDocs } from '../../../.Generated/mosaik-skeleton';
import { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link SkeletonSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-skeleton-sample'
})
export class SkeletonSampleElement extends SampleBaseElement<SkeletonElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SkeletonElement> {

        return {
            header: 'Skeleton',
            description: META.description(SkeletonElement.is),
            graph: META.graph(SkeletonElement.is),
            playground: {
                template: [
                    ['default', html`
                        <mosaik-skeleton></mosaik-skeleton>
                    `], ['with multiple', (props) => html`
                        <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'8px'}">
                            <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'200px'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                            <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'200px'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                            <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'100px'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                        </mosaik-stack>
                    `],
                    ['with card', (props) => html`
                        <mosaik-card>
                            <mosaik-card-header>
                                <mosaik-skeleton slot="prefix" .shape="${SkeletonShape.circle}" .width="${'48px'}" .height="${'48px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                <mosaik-card-title>
                                    <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'200px'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                </mosaik-card-title>
                                <mosaik-card-sub-title>
                                    <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'8px'}">
                                        <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'160px'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                    </mosaik-stack>
                                </mosaik-card-sub-title>
                            </mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'8px'}">
                                    <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'100%'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                    <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'100%'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                    <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'100%'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                    <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'80%'}" .height="${'16px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                                </mosaik-stack>
                            </mosaik-card-content>
                            <mosaik-card-footer>
                                <mosaik-skeleton .shape="${SkeletonShape.rect}" .width="${'100px'}" .height="${'40px'}" .shimmer="${props.get('shimmer').value}"></mosaik-skeleton>
                            </mosaik-card-footer>
                        </mosaik-card>
                    `]
                ],
                properties: META.properties<SkeletonElement>(SkeletonElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'shape',
                        value: SkeletonShape.rect
                    },
                    {
                        key: 'shimmer',
                        value: false
                    },
                    {
                        key: 'width',
                        value: '200px'
                    },
                    {
                        key: 'height',
                        value: '16px'
                    }
                ]),
                events: META.events(SkeletonElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<SkeletonElement>(SkeletonElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'shape',
                        value: SkeletonShape.rect
                    },
                    {
                        key: 'shimmer',
                        value: false
                    },
                    {
                        key: 'width',
                        value: '200px'
                    },
                    {
                        key: 'height',
                        value: '16px'
                    }
                ]), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    }
                ] as Array<IPlaygroundProperty<SkeletonElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                skeletonElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-skeleton-sample': SkeletonSampleElement;
    }
}
