// #region Imports

import { Orientation, Size, Spacing, TextFormatters, html, repeat, translate, typography, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../../Globals';
import type { ArgumentView } from './ArgumentView';

// #endregion

/**
 * The template of the {@link ArgumentView}.
 *
 * @public
 */
export function argumentViewTemplate<T extends ArgumentView>(e: T): TemplateResult {
    return html`
        <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                              ${typography('headline4')}
                              .label="${translate('loc.landing.arguments.title', globals.name)}"
                              .ref="${'arguments'}"></mosaik-router-anchor>
        <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
        <mosaik-stack .orientation="${Orientation.Vertical}"
                      .gap="${'64px'}">
            ${repeat(e.args, (x, i) => html`
            <mosaik-stack part="arg"
                          class="arg ${i % 2 ? 'reverse' : ''} ${e.isMobile ? 'mobile' : ''}"
                          .orientation="${Orientation.Horizontal}"
                          .gap="${e.isMobile ? '32px' : '64px'}"
                          .reverse="${!!(i % 2)}">
                <mosaik-emoji .unicode="${x.emoji}"
                              .size="${Size.Giant}"></mosaik-emoji>
                <mosaik-stack .orientation="${Orientation.Vertical}">
                    <mosaik-text ${typography('headline6')}
                                 .text="${translate(x.title, globals.name)}"
                                 .wrap="${true}"></mosaik-text>
                    <mosaik-text ${typography('subtitle1')}
                                 .text="${translate(x.content, globals.name)}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .wrap="${true}"
                                 .readonly="${true}"></mosaik-text>
                </mosaik-stack>
            </mosaik-stack>
            `)}
        </mosaik-stack>
    `;
}
