//#region Imports

import { TemplateResult, html } from '@breadstone/mosaik-elements-foundation';

//#endregion

export namespace SplitSampleTemplates {

    export function defaultTemplate(): TemplateResult {
        return html`
            <mosaik-split>
                <mosaik-box slot="start" .highlighted="${true}" .cornerd="${true}">
                    <mosaik-text .text="${'Box 1'}"></mosaik-text>
                </mosaik-box>
                <mosaik-box slot="end" .highlighted="${true}" .cornerd="${true}">
                    <mosaik-text .text="${'Box 1'}"></mosaik-text>
                </mosaik-box>
            </mosaik-split>
        `;
    }

}