//#region Imports

import { TemplateResult, html } from '@breadstone/mosaik-elements-foundation';

//#endregion

export namespace MasonrySampleTemplates {

    export function defaultTemplate(): TemplateResult {
        return html`
            <mosaik-masonry>
                <mosaik-box .highlighted="${true}" .cornerd="${true}">
                    <mosaik-text .text="${'Box 1'}"></mosaik-text>
                </mosaik-box>
                <mosaik-box .highlighted="${true}" .cornerd="${true}">
                    <mosaik-text .text="${'Box 2'}"></mosaik-text>
                </mosaik-box>
                <mosaik-box .highlighted="${true}" .cornerd="${true}">
                    <mosaik-text .text="${'Box 3'}"></mosaik-text>
                </mosaik-box>
            </mosaik-masonry>
        `;
    }

}