//#region Imports

import { ElementIntlBase } from '@breadstone/mosaik-elements-foundation';

//#endregion

/**
 * The `CountdownElementIntl` class.
 *
 * @public
 */
export class CountdownElementIntl extends ElementIntlBase {

    //#region Fields

    private _days: string;
    private _hours: string;
    private _minutes: string;
    private _seconds: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `CountdownElementIntl` class.
     *
     * @public
     */
    public constructor(locale?: string) {
        super(locale);

        this._days = 'Days';
        this._hours = 'Hours';
        this._minutes = 'Minutes';
        this._seconds = 'Seconds';
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `days` property.
     *
     * @public
     */
    public get days(): string {
        return this._days;
    }
    public set days(value: string) {
        this._days = value;
    }

    /**
     * Gets or sets the `hours` property.
     *
     * @public
     */
    public get hours(): string {
        return this._hours;
    }
    public set hours(value: string) {
        this._hours = value;
    }

    /**
     * Gets or sets the `minutes` property.
     *
     * @public
     */
    public get minutes(): string {
        return this._minutes;
    }
    public set minutes(value: string) {
        this._minutes = value;
    }

    /**
     * Gets or sets the `seconds` property.
     *
     * @public
     */
    public get seconds(): string {
        return this._seconds;
    }
    public set seconds(value: string) {
        this._seconds = value;
    }

    //#endregion

}
