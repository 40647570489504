// #region Imports

import { Animate, Component, ElevatioWeight, HorizontalAlignment, Inset, Orientation, Size, TextFormatters, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, VerticalAlignment, animate, flex, interpolate, repeat, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

/**
 * The `{@link ElevationView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-elevation-view'
})
export class ElevationView extends ViewBase {

    // #region Fields

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly ELEVATIONS: Array<ElevatioWeight> = [ElevatioWeight.None, ElevatioWeight.Light, ElevatioWeight.SemiLight, ElevatioWeight.Regular, ElevatioWeight.SemiBold, ElevatioWeight.Bold, ElevatioWeight.ExtraBold];

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-elevation-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.elevation')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/elevation'}" .label="${translate('loc.nav.elevation')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.elevation')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'CSS Elevation refers to the concept of visually elevating or giving depth to elements on a webpage using shadow effects.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${interpolate('In *{0}*, the elevation system provides a range of shadow styles, numbered from *0* to *24*, to create a sense of depth and hierarchy within the user interface. Each elevation level corresponds to a specific shadow style, with increasing values representing higher elevations and stronger shadows.', globals.name)}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${'Elevation levels *0* to *24* offer a progression of shadows, where higher levels indicate elements that are visually elevated above others. Lower elevation values, such as *0*, create a flatter appearance without any shadows, while higher values, such as *24*, generate more pronounced and darker shadows.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'By applying elevation values to elements using CSS classes or styles, you can create a visual hierarchy and convey depth within your webpage. This helps users understand the relationship between different elements and provides a more immersive user experience.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                .formatter="${TextFormatters.RICHTEXT}"
                                .text="${'Using the CSS elevation system, you can achieve consistent and intuitive design patterns by leveraging the predefined shadow styles associated with each elevation level. This allows for a cohesive and visually appealing interface across different components and layouts within your web design.'}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-stack orientation="${this.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                  gap="16px">
                        <mosaik-stack ${flex({ fill: true })} orientation="${Orientation.Vertical}" >
                            <mosaik-text .text="${'elevation'}"></mosaik-text>
                            <mosaik-text .text="${'```--theme-elevation-{n}```'}"
                                         .formatter="${TextFormatters.RICHTEXT}"
                                         ${typography('body2')}></mosaik-text>
                        </mosaik-stack>
                        <mosaik-stack ${flex({ fill: true })}>
                            <mosaik-wrap orientation="${Orientation.Horizontal}" .columns="${5}" .gap="${'16px'}">
                                ${repeat(this.ELEVATIONS, (x) => x, (x) => html`
                                <mosaik-stack .orientation="${Orientation.Vertical}"
                                              .verticalAlignment="${VerticalAlignment.Center}"
                                              .horizontalAlignment="${HorizontalAlignment.Center}">
                                    <mosaik-dot .size="${Size.Giant}"
                                                style="background-color: var(--theme-transparent-color); box-shadow: var(--theme-elevation-${x});"
                                                @click="${() => this.onCopy(x)}"></mosaik-dot>
                                    <mosaik-spacer thickness="top"
                                                   .size="${Size.Tiny}"></mosaik-spacer>
                                    <mosaik-text .text="${x.toString()}"></mosaik-text>
                                </mosaik-stack>
                                `)}
                            </mosaik-wrap>
                        </mosaik-stack>
                    </mosaik-stack>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    /**
     * @private
     */
    private onCopy(weigth: string): void {
        void navigator.clipboard.writeText(`--theme-elevation-${weigth}`).then(() => {
            void ToastServiceLocator.current.open({
                variant: Variant.Success,
                content: TranslatorServiceLocator.current.translate('Shadow copied to clipboard 🎉.'),
                timeout: Toast2.Timeout.SHORT
            });
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-elevation-view': ElevationView;
    }
}
