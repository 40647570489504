// #region Imports

import { Appearance, CSSResultGroup, Component, FabGroupDirection, FloatingActionButtonGroupElement, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import { fabGroupElementDocs } from '../../../.Generated/mosaik-fab-group';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { iconSizeTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { FloatingActionButtonGroupSampleTemplates } from './FloatingActionButtonGroupSample.Templates';

// #endregion

/**
 * The `{@link FloatingActionButtonGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-fab-group-sample'
})
export class FloatingActionButtonGroupSampleElement extends SampleBaseElement<FloatingActionButtonGroupElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<FloatingActionButtonGroupElement> {
        return {
            header: 'Floating Action Button Group',
            description: META.description(FloatingActionButtonGroupElement.is),
            graph: META.graph(FloatingActionButtonGroupElement.is),
            playground: {
                template: [
                    FloatingActionButtonGroupSampleTemplates.defaultTemplate(),
                    FloatingActionButtonGroupSampleTemplates.withBadgeTemplate()
                ],
                properties: META.properties2<FloatingActionButtonGroupElement>(FloatingActionButtonGroupElement.is, {
                    appearance: Appearance.Soft,
                    variant: Variant.Primary,
                    label: '',
                    icon: 'M11.883 3.007 12 3a1 1 0 0 1 .993.883L13 4v7h7a1 1 0 0 1 .993.883L21 12a1 1 0 0 1-.883.993L20 13h-7v7a1 1 0 0 1-.883.993L12 21a1 1 0 0 1-.993-.883L11 20v-7H4a1 1 0 0 1-.993-.883L3 12a1 1 0 0 1 .883-.993L4 11h7V4a1 1 0 0 1 .883-.993L12 3l-.117.007Z',
                    direction: FabGroupDirection.Top,
                    iconSize: Size.Medium
                }),
                events: META.events(FloatingActionButtonGroupElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<FloatingActionButtonGroupElement>(FloatingActionButtonGroupElement.is, {
                    appearance: Appearance.Soft,
                    variant: Variant.Primary,
                    label: '',
                    icon: 'M11.883 3.007 12 3a1 1 0 0 1 .993.883L13 4v7h7a1 1 0 0 1 .993.883L21 12a1 1 0 0 1-.883.993L20 13h-7v7a1 1 0 0 1-.883.993L12 21a1 1 0 0 1-.993-.883L11 20v-7H4a1 1 0 0 1-.993-.883L3 12a1 1 0 0 1 .883-.993L4 11h7V4a1 1 0 0 1 .883-.993L12 3l-.117.007Z',
                    direction: FabGroupDirection.Top,
                    iconSize: Size.Medium
                }), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    }
                ] as Array<IPlaygroundProperty<FloatingActionButtonGroupElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                fabGroupElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-fab-group-sample': FloatingActionButtonGroupSampleElement;
    }
}
