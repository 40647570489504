// #region Imports

import { Appearance, CSSResultGroup, ComboElement, Component, FlowDirection, Placement, SelectionMode, Strategy, Variant, html } from '@breadstone/mosaik-elements-foundation';
import { comboElementDocs } from '../../../.Generated/mosaik-combo';
import { comboItemElementDocs } from '../../../.Generated/mosaik-combo-item';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ComboSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-combo-sample'
})
export class ComboSampleElement extends SampleBaseElement<ComboElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
 *    <!-- <mosaik-combo-trigger .template="${(x: ComboElement) => html`
                            ${x.selectedItem.label}
                            ${when(x.selectedItems.length > 1, () => html`
                            <span class="example-additional-selection">
                                ${`(+${x.selectedItems.length - 1} ${x.selectedItems.length}` === 2 ? 'other' : 'others' + ')'}
                            </span>
                            `)}
                        `}"></mosaik-combo-trigger>-->
 */

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ComboElement> {

        return {
            header: 'Combo',
            description: META.description(ComboElement.is),
            graph: META.graph(ComboElement.is),
            playground: {
                template: html`
                    <mosaik-combo>
                        <mosaik-combo-item .label="${'None'}"
                                           .isSelected="${true}"
                                           .isChecked="${true}"></mosaik-combo-item>
                        <mosaik-combo-item label="Asia"></mosaik-combo-item>
                        <mosaik-combo-item label="Africa"></mosaik-combo-item>
                        <mosaik-combo-item label="North America"></mosaik-combo-item>
                        <mosaik-combo-item label="South America"></mosaik-combo-item>
                        <mosaik-combo-item label="Antarctica"></mosaik-combo-item>
                        <mosaik-combo-item label="Europe"></mosaik-combo-item>
                        <mosaik-combo-item label="Australia"></mosaik-combo-item>
                    </mosaik-combo>
                `,
                properties: META.properties<ComboElement>(ComboElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Multiple
                    },
                    {
                        key: 'maxDropDownHeight',
                        value: '200px'
                    },
                    {
                        key: 'dropDownDistance',
                        value: 8
                    },
                    {
                        key: 'dropDownPlacement',
                        value: Placement.Bottom
                    },
                    {
                        key: 'dropDownSkidding',
                        value: 0
                    },
                    {
                        key: 'dropDownStrategy',
                        value: Strategy.Absolute
                    },
                    {
                        key: 'isDropDownOpen',
                        value: false
                    },
                    {
                        key: 'isClearable',
                        value: false
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'dropDownStaysOpen',
                        value: false
                    },
                    {
                        key: 'label',
                        value: 'Continents'
                    },
                    {
                        key: 'placeholder',
                        value: 'Select something...'
                    }
                ])
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<ComboElement>(ComboElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Multiple
                    },
                    {
                        key: 'maxDropDownHeight',
                        value: '200px'
                    },
                    {
                        key: 'dropDownDistance',
                        value: 8
                    },
                    {
                        key: 'dropDownPlacement',
                        value: Placement.Bottom
                    },
                    {
                        key: 'dropDownSkidding',
                        value: 0
                    },
                    {
                        key: 'dropDownStrategy',
                        value: Strategy.Absolute
                    },
                    {
                        key: 'isDropDownOpen',
                        value: false
                    },
                    {
                        key: 'isClearable',
                        value: false
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'dropDownStaysOpen',
                        value: false
                    },
                    {
                        key: 'label',
                        value: 'Continents'
                    },
                    {
                        key: 'placeholder',
                        value: 'Select something...'
                    }
                ]), [
                    {
                        key: 'maxDropDownHeight',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<ComboElement>>, (a, b) => a.key === b.key), [
                    { key: 'selectedItem' },
                    { key: 'selectedItems' }
                ] as Array<IPlaygroundProperty<ComboElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                comboElementDocs(),
                comboItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-combo-sample': ComboSampleElement;
    }
}
