// #region Imports

import { CSSResultGroup, Component, FlowDirection, PatternElement, Variant, html } from '@breadstone/mosaik-elements-foundation';
import { patternElementDocs } from '../../../.Generated/mosaik-pattern';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link PatternSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-pattern-sample'
})
export class PatternSampleElement extends SampleBaseElement<PatternElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<PatternElement> {
        return {
            header: 'Pattern',
            description: META.description(PatternElement.is),
            graph: META.graph(PatternElement.is),
            playground: {
                template: (_props, _set, _get) => html`
                    <div id="my-id"
                         style="background: black; width: 320px; height: 120px; position: relative">
                        <mosaik-pattern .for="${'my-id'}"></mosaik-pattern>
                        <mosaik-text .text="${'Click me'}"></mosaik-text>
                    </div>
                    <div id="lalalla"></div>
                `,
                properties: META.properties<PatternElement>(PatternElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]),
                events: META.events(PatternElement.is)
            },
            propertyGrid: {
                exludedProperties: ['control', 'for'],
                properties: merge(META.properties<PatternElement>(PatternElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]), [] as Array<IPlaygroundProperty<PatternElement>>, (a, b) => a.key === b.key)
            },
            docs: [patternElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-pattern-sample': PatternSampleElement;
    }
}
