// #region Imports

// import prettier, { BuiltInParserName } from 'prettier';
import type { BuiltInParserName, Plugin } from 'prettier';
import * as angularPlugin from 'prettier/plugins/angular';
import * as babelPlugin from 'prettier/plugins/babel';
import * as htmlPlugin from 'prettier/plugins/html';
import * as postcssPlugin from 'prettier/plugins/postcss';
import * as typescriptPlugin from 'prettier/plugins/typescript';
import * as yamlPlugin from 'prettier/plugins/yaml';
import * as prettier from 'prettier/standalone';
// #endregion

export class CodeFormatter {

    public static ANGULAR: BuiltInParserName = 'angular';
    public static VUE: BuiltInParserName = 'vue';
    public static HTML: BuiltInParserName = 'html';
    public static TYPESCRIPT: BuiltInParserName = 'typescript';
    public static TSX: BuiltInParserName = 'typescript';
    public static JAVASCRIPT: BuiltInParserName = 'babel';
    public static JSX: BuiltInParserName = 'babel';
    public static SCSS: BuiltInParserName = 'scss';
    public static SASS: BuiltInParserName = 'scss';
    public static CSS: BuiltInParserName = 'css';
    public static YAML: BuiltInParserName = 'yaml';
    public static JSON: BuiltInParserName = 'json';
    // public static XML: BuiltInParserName = 'xml';
    private readonly parsers: Record<string, {
        name: BuiltInParserName;
        plugin: Plugin;
    }> = {
            [CodeFormatter.ANGULAR]: {
                name: CodeFormatter.ANGULAR,
                plugin: angularPlugin
            },
            [CodeFormatter.VUE]: {
                name: CodeFormatter.VUE,
                plugin: htmlPlugin
            },
            [CodeFormatter.HTML]: {
                name: CodeFormatter.HTML,
                plugin: htmlPlugin
            },
            [CodeFormatter.TYPESCRIPT]: {
                name: CodeFormatter.TYPESCRIPT,
                plugin: typescriptPlugin
            },
            [CodeFormatter.TSX]: {
                name: CodeFormatter.TSX,
                plugin: typescriptPlugin
            },
            [CodeFormatter.JAVASCRIPT]: {
                name: CodeFormatter.JAVASCRIPT,
                plugin: babelPlugin
            },
            [CodeFormatter.JSX]: {
                name: CodeFormatter.JSX,
                plugin: babelPlugin
            },
            [CodeFormatter.SCSS]: {
                name: CodeFormatter.SCSS,
                plugin: postcssPlugin
            },
            [CodeFormatter.SASS]: {
                name: CodeFormatter.SASS,
                plugin: postcssPlugin
            },
            [CodeFormatter.CSS]: {
                name: CodeFormatter.CSS,
                plugin: postcssPlugin
            },
            [CodeFormatter.YAML]: {
                name: CodeFormatter.YAML,
                plugin: yamlPlugin
            },
            [CodeFormatter.JSON]: {
                name: CodeFormatter.JSON,
                plugin: babelPlugin
            }
            // [CodeFormatter.XML]: CodeFormatter.XML,

        };

    public format(code: string, language: string): Promise<string> {
        const info = this.parsers[language];
        if (!info) {
            throw new Error(`No parser found for language: ${language}`);
        }

        // return prettier.format(code, { parser, plugins: [{ parsers: parser }] });

        return prettier.format(code, {
            parser: info.name,
            plugins: [info.plugin]
        });
    }

}
