// #region Imports

import { Component, FlowDirection, Orientation, TickBarElement, TickLabelPosition } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { tickBarElementDocs } from '../../../.Generated/mosaik-tick-bar';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TickBarSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tick-bar-sample'
})
export class TickBarSampleElement extends SampleBaseElement<TickBarElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TickBarElement> {
        return {
            header: 'TickBar',
            description: META.description(TickBarElement.is),
            graph: META.graph(TickBarElement.is),
            playground: {
                template: html`
                    <mosaik-tick-bar></mosaik-tick-bar>
                `,
                properties: META.properties<TickBarElement>(TickBarElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'max',
                        value: 100
                    },
                    {
                        key: 'interval',
                        value: 10
                    },
                    {
                        key: 'showLabels',
                        value: true
                    },
                    {
                        key: 'labelPosition',
                        value: TickLabelPosition.After
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    }
                ]),
                events: META.events(TickBarElement.is)
            },
            propertyGrid: {
                properties: META.properties<TickBarElement>(TickBarElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'max',
                        value: 100
                    },
                    {
                        key: 'interval',
                        value: 10
                    },
                    {
                        key: 'showLabels',
                        value: true
                    },
                    {
                        key: 'labelPosition',
                        value: TickLabelPosition.After
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    }
                ]),
                exludedProperties: ['ticks']
            },
            docs: [
                tickBarElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-tick-bar-sample': TickBarSampleElement;
    }
}
