// #region Imports

import { AnimationPresets, Component, FlowDirection, ToastElement, ToastPosition, Variant, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { toastElementDocs } from '../../../.Generated/mosaik-toast';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { animationTemplate, formatterTemplate, multilineTextTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ToastSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-toast-sample'
})
export class ToastSampleElement extends SampleBaseElement<ToastElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ToastElement> {
        return {
            header: 'Toast',
            description: META.description(ToastElement.is),
            graph: META.graph(ToastElement.is),
            playground: {
                template: (_props, set, _get) => html`
                    <mosaik-toast ${ref((el) => set(el as ToastElement))}></mosaik-toast>
                `,
                properties: META.properties<ToastElement>(ToastElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'enter',
                        value: AnimationPresets.FadeSlideIn
                    },
                    {
                        key: 'exit',
                        value: AnimationPresets.FadeOut
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'position',
                        value: ToastPosition.Bottom
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'content',
                        value: 'Content'
                    },
                    {
                        key: 'timeout',
                        value: 8000
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    }
                ]),
                events: META.events<ToastElement>(ToastElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ToastElement>(ToastElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'enter',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'exit',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'position',
                        value: ToastPosition.Bottom
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'content',
                        value: 'Content'
                    },
                    {
                        key: 'timeout',
                        value: 8000
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    }
                ]), [
                    {
                        key: 'header',
                        template: multilineTextTemplate
                    },
                    {
                        key: 'content',
                        template: multilineTextTemplate
                    },
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    },
                    {
                        key: 'enter',
                        template: animationTemplate
                    },
                    {
                        key: 'exit',
                        template: animationTemplate
                    }
                ] as Array<IPlaygroundProperty<ToastElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                toastElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-toast-sample': ToastSampleElement;
    }
}
