// #region Imports

import { Component, CustomElement, ToastServiceLocator, css, html } from '@breadstone/mosaik-elements-foundation';

// #endregion

interface IPerson {
    firstName: string;
    lastName: string;
}

/**
 * The `{@link testElement}` element.
 *
 * @public
 */

@Component({
    selector: 'app-test',
    styles: () => css`
    `,
    template: (e: TestElement) => html`
    <mosaik-theme>
        <mosaik-app .isNavOpen="${true}">
            <!-- <mosaik-app-header slot="header"></mosaik-app-header> -->
            <p slot="header">header</p>
            <p slot="content">
                <button @click="${() => e.onClick()}">open toast</button>
            </p>
            <p slot="nav">nav</p>
            <mosaik-footer slot="footer"></mosaik-footer>
        </mosaik-app>
    </mosaik-theme>
    `
})
export class TestElement extends CustomElement {

    public constructor() {
        super();
    }

    public onClick(): void {
        void ToastServiceLocator.current.open({
            content: 'Hello World!'
        });
    }

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-test': TestElement;
    }
}
