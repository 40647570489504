//#region Imports

import { Attribute, Component, CustomElement, TextFormattable, type ITextFormattableProps } from '@breadstone/mosaik-elements-foundation';
import { couponElementStyle } from './CouponElementStyle';
import { couponElementTemplate } from './CouponElementTemplate';

//#endregion

/**
 * Represents the `{@link ICouponElementProps}` interface.
 *
 * @public
 */
export interface ICouponElementProps extends ITextFormattableProps {
    code: string;
    discount: number;
    text: string;
}

/**
 * The `{@link CouponElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-coupon',
    template: couponElementTemplate,
    styles: couponElementStyle,
    imports: []
})
export class CouponElement extends TextFormattable(CustomElement) implements ICouponElementProps {

    //#region Fields

    private _code: string;
    private _discount: number;
    private _text: string;

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._code = '';
        this._discount = 0;
        this._text = '';
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-coupon';
    }

    /**
     * Gets or sets the `code` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get code(): string {
        return this._code;
    }
    public set code(value: string) {
        if (this._code !== value) {
            this._code = value;
            this.requestUpdate('code');
        }
    }

    /**
     * Gets or sets the `discount` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get discount(): number {
        return this._discount;
    }
    public set discount(value: number) {
        if (this._discount !== value) {
            this._discount = value;
            this.requestUpdate('discount');
        }
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-coupon': CouponElement;
    }
}

