//#region Imports

import { HighlightStyle, TagStyle, syntaxHighlighting, } from '@codemirror/language';
import { Extension } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { tags } from '@lezer/highlight';

//#endregion

interface ICodeEditorThemeOptions {
    /**
     * Theme variant. Determines which styles CodeMirror will apply by default.
     */
    variant: 'light' | 'dark';

    /**
     * Settings to customize the look of the editor, like background, gutter, selection and others.
     */
    settings: ICodeEditorThemeSettings;

    /**
     * Syntax highlighting styles.
     */
    styles: TagStyle[];
}

interface ICodeEditorThemeSettings {
    /**
     * Editor background.
     */
    background: string;

    /**
     * Default text color.
     */
    foreground: string;

    /**
     * Caret color.
     */
    caret: string;

    /**
     * Selection background.
     */
    selection: string;

    /**
     * Background of highlighted lines.
     */
    lineHighlight: string;

    /**
     * Gutter background.
     */
    gutterBackground: string;

    /**
     * Text color inside gutter.
     */
    gutterForeground: string;
}

export namespace CodeEditorTheme {

    export function create(options: ICodeEditorThemeOptions): Extension {
        const theme = EditorView.theme(
            {
                '&': {
                    backgroundColor: options.settings.background,
                    color: options.settings.foreground,
                },
                '.cm-content': {
                    caretColor: options.settings.caret
                },
                '.cm-cursor, .cm-dropCursor': {
                    borderLeftColor: options.settings.caret
                },
                '&.cm-focused .cm-selectionBackgroundm .cm-selectionBackground, .cm-content ::selection':
                {
                    backgroundColor: options.settings.selection
                },
                '.cm-activeLine': {
                    backgroundColor: options.settings.lineHighlight
                },
                '.cm-gutters': {
                    backgroundColor: options.settings.gutterBackground,
                    color: options.settings.gutterForeground
                },
                '.cm-activeLineGutter': {
                    backgroundColor: options.settings.lineHighlight
                }
            },
            {
                dark: options.variant === 'dark',
            },
        );

        const highlightStyle = HighlightStyle.define(options.styles);
        const extension = [theme, syntaxHighlighting(highlightStyle)];

        return extension;
    };

    export const BOYSANDGIRLS = create({
        variant: 'dark',
        settings: {
            background: '#000205',
            foreground: '#FFFFFF',
            caret: '#E60065',
            selection: '#E60C6559',
            gutterBackground: '#000205',
            gutterForeground: '#ffffff90',
            lineHighlight: '#4DD7FC1A',
        },
        styles: [
            {
                tag: tags.comment,
                color: '#404040',
            },
            {
                tag: [tags.string, tags.special(tags.brace), tags.regexp],
                color: '#00D8FF',
            },
            {
                tag: tags.number,
                color: '#E62286',
            },
            {
                tag: [tags.variableName, tags.attributeName, tags.self],
                color: '#E62286',
                fontWeight: 'bold',
            },
            {
                tag: tags.function(tags.variableName),
                color: '#fff',
                fontWeight: 'bold',
            },
        ],
    });

}