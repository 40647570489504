// #region Imports

import { Animate, Appearance, Fit, HorizontalAlignment, Inset, Orientation, Size, SkeletonShape, Spacing, TextFormatters, VerticalAlignment, animate, html, interpolate, repeat, translate, typography, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import type { BlogView } from './BlogView';

// #endregion

/**
 * The template of the {@link BlogView}.
 *
 * @public
 */
export function blogViewTemplate<T extends BlogView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.blog')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/blog'}"
                                        .label="${translate('loc.nav.blog')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.blog.title')}"
                                      .ref="${'blog'}"></mosaik-router-anchor>
            </mosaik-page-content>
            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'64px'}">
                    ${repeat(e.posts, (x) => x.title, (x, i) => html`
                    <mosaik-router-link .href="${'/blog/post'}"
                                        .query="${{ id: x.id }}"
                                        style="display: inline;">
                    <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                  .gap="${'32px'}"
                                  ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 * i } }
    })}>
                        <mosaik-image .src="${x.image ?? ''}"
                                      .alt="${(x.title as any)[e.lang]}"
                                      .width="${'152px'}"
                                      .height="${'152px'}"
                                      .showEmpty="${true}">
                            <mosaik-stack slot="empty"
                                          .fit="${Fit.Both}"
                                          .verticalAlignment="${VerticalAlignment.Center}"
                                          .horizontalAlignment="${HorizontalAlignment.Center}"
                                          style="${'background: var(--theme-semi-transparent-color);'}">
                                <mosaik-icon .data="${'M0 12v-8.646l10-1.355v10.001h-10zm11 0h13v-12l-13 1.807v10.193zm-1 1h-10v7.646l10 1.355v-9.001zm1 0v9.194l13 1.806v-11h-13z'}"
                                             .size="${Size.Large}"></mosaik-icon>
                            </mosaik-stack>
                        </mosaik-image>
                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                      .gap="${'8px'}">
                            <mosaik-text ${typography('subtitle1')}
                                         .text="${(x.title as any)[e.lang]}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-text ${typography('body1')}
                                         .text="${(x.description as any)[e.lang]}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                          .verticalAlignment="${VerticalAlignment.Center}"
                                          .gap="${'4px'}">
                                <mosaik-icon .size="${Size.Tiny}"
                                             .readonly="${true}"
                                             .data="${'M21 8.5v9.25A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V8.5h18ZM17.75 3A3.25 3.25 0 0 1 21 6.25V7H3v-.75A3.25 3.25 0 0 1 6.25 3h11.5Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')}
                                             .text="${new Date(x.createdAt).toLocaleDateString()}"
                                             .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                          .gap="${'4px'}"
                                          style="flex-wrap: wrap;">
                                ${repeat(x.tags, (y) => y, (y, j) => html`
                                <mosaik-chip .label="${interpolate('#{0}', y)}"
                                             .appearance="${Appearance.Solid}"></mosaik-chip>
                                `)}
                            </mosaik-stack>
                        </mosaik-stack>
                    </mosaik-stack>
                    </mosaik-router-link>
                    ${when(i < e.posts.length - 1, () => html`
                    <mosaik-divider ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 * i } }
    })}></mosaik-divider>
                    `)}
                    `)}
                    ${when(e.posts.length === 0 && !e.isBusy, () => html`
                    <mosaik-empty-state .content="${translate('loc.blog.empty')}"
                                        .formatter="${TextFormatters.RICHTEXT}"></mosaik-empty-state>
                    `)}
                    ${when(e.isBusy, () => html`
                    <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                  .horizontalAlignment="${HorizontalAlignment.Center}"
                                  .orientation="${Orientation.Vertical}"
                                  .gap="${'16px'}"
                                  ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 } }
    })}>
                        <div style="width: 100%">
                            <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                          .gap="${'32px'}">
                                <mosaik-skeleton .width="${'152px'}"
                                                 .height="${'152px'}"
                                                 .shimmer="${true}"></mosaik-skeleton>
                                <mosaik-stack .orientation="${Orientation.Vertical}"
                                              .gap="${'8px'}">
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .width="${'50%'}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'40px'}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .shape="${SkeletonShape.rect}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .shape="${SkeletonShape.rect}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                </mosaik-stack>
                            </mosaik-stack>
                        </div>
                    </mosaik-stack>
                    `)}
                </mosaik-stack>
            </mosaik-page-content>

            <mosaik-page-content part="newsletterContent"
                                 .inset="${[Inset.All]}"
                                 .fullWidth="${true}">
                <mosaik-spacer .thickness="${Spacing.All}"
                               .size="${Size.Large}">
                    <app-landing-newsletter-view .kind="${'blog'}"></app-landing-newsletter-view>
                </mosaik-spacer>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
