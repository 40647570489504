// #region Imports

import { AvatarElement, Command, Component, DialogServiceLocator, FormController, ICommand, IFilePickEventDetail, Inject, Property, ToastServiceLocator, TranslatorServiceLocator, Variant } from '@breadstone/mosaik-elements-foundation';
import { IUserResponse } from '../../Backend/Api/Models/IUserResponse';
import { AuthService } from '../../Backend/Api/Services/AuthService';
import { FileExtensions } from '../../Extensions/FileExtensions';
import { SessionManager } from '../../Services/SessionManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { DeleteProfileView, IDeleteProfileViewData } from './Dialogs/DeleteProfileView';
import { profileViewStyle } from './ProfileViewStyle';
import { profileViewTemplate } from './ProfileViewTemplate';

// #endregion

type FromNames = 'general' | 'changeEmail' | 'changePassword' | 'visibility';

/**
 * The `{@link ProfileView}` view.
 *
 * @public
 */
@Component({
    selector: 'app-profile-view',
    template: profileViewTemplate,
    styles: profileViewStyle,
    imports: []
})
export class ProfileView
    extends ViewBase {

    // #region Fields

    @Inject(AuthService)
    private readonly _authService!: AuthService;
    @Inject(SessionManager)
    private readonly _sessionManager!: SessionManager;
    private readonly _saveCommand: ICommand<FromNames>;
    private readonly _deleteCommand: ICommand;
    private readonly _addAvatarCommand: ICommand;
    private readonly _removeAvatarCommand: ICommand;
    private readonly _formController: FormController;
    private _user: IUserResponse | null;
    private _hasAvatar: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._user = null;
        this._hasAvatar = false;
        this._formController = new FormController(this, {
            queries: {
                general: '[name="general"]',
                changeEmail: '[name="changeEmail"]',
                changePassword: '[name="changePassword"]',
                visibility: '[name="visibility"]'
            }
        });
        this._saveCommand = new Command<FromNames>((x) => this.onExecuteSaveCommand(x));
        this._deleteCommand = new Command(() => this.onExecuteDeleteCommand());
        this._addAvatarCommand = new Command<IFilePickEventDetail>((x) => this.onExecuteAddAvatarCommand(x));
        this._removeAvatarCommand = new Command(() => this.onExecuteRemoveAvatarCommand());
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `user` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get user(): IUserResponse | null {
        return this._user;
    }

    public set user(value: IUserResponse | null) {
        if (this._user !== value) {
            this._user = value;
            this.requestUpdate('user');
        }
    }

    /**
     * Gets or sets the `hasAvatar` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Boolean })
    public get hasAvatar(): boolean {
        return this._hasAvatar;
    }
    private set hasAvatar(value: boolean) {
        if (this._hasAvatar !== value) {
            this._hasAvatar = value;
            this.requestUpdate('hasAvatar');
        }
    }

    /**
     * Returns the `saveCommand` command property.
     *
     * @public
     * @readonly
     */
    public get saveCommand(): ICommand<FromNames> {
        return this._saveCommand;
    }

    /**
     * Returns the `deleteCommand` command property.
     *
     * @public
     * @readonly
     */
    public get deleteCommand(): ICommand {
        return this._deleteCommand;
    }

    /**
     * Returns the `addAvatarCommand` command property.
     *
     * @public
     * @readonly
     */
    public get addAvatarCommand(): ICommand {
        return this._addAvatarCommand;
    }

    /**
     * Returns the `removeAvatarCommand` command property.
     *
     * @public
     * @readonly
     */
    public get removeAvatarCommand(): ICommand {
        return this._removeAvatarCommand;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.initialize();
    }

    /**
     * @private
     */
    private initialize(): void {
        this.isBusy = true;

        void this._authService
            .me({
                authorization: this._sessionManager.session
            })
            .then((x) => {
                this.user = x;
            })
            .then(() => {
                this.isBusy = false;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    /**
     * Executes the `saveCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteSaveCommand(parameter: FromNames): void {
        const formFacade = this._formController.get(parameter);

        if (formFacade?.reportValidity()) {
            switch (parameter) {
                case 'general':
                    void this._authService
                        .update({
                            authorization: this._sessionManager.session,
                            body: {
                                avatar: formFacade.get('avatar'),
                                firstName: formFacade.get('firstName'),
                                lastName: formFacade.get('lastName'),
                                bio: formFacade.get('bio')
                            }
                        })
                        .then(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.profile.general.messages.updated'),
                            variant: Variant.Success
                        }))
                        .catch(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.global.error'),
                            variant: Variant.Danger
                        }))
                        .finally(() => { });
                    break;
                case 'changeEmail':
                    void this._authService
                        .changeEmail({
                            authorization: this._sessionManager.session,
                            body: {
                                email: formFacade.get('email')
                            }
                        }).then(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.profile.changeEmail.messages.updated'),
                            variant: Variant.Success
                        }))
                        .catch(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.global.error'),
                            variant: Variant.Danger
                        }))
                        .finally(() => { });
                    break;
                case 'changePassword':
                    void this._authService
                        .changePassword({
                            authorization: this._sessionManager.session,
                            body: {
                                password: formFacade.get('password')
                            }
                        })
                        .then(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.profile.changePassword.messages.updated'),
                            variant: Variant.Success
                        }))
                        .catch(() => ToastServiceLocator.current.open({
                            content: TranslatorServiceLocator.current.translate('loc.global.error'),
                            variant: Variant.Danger
                        }))
                        .finally(() => { });
                    break;
                case 'visibility':
                    console.log('visibility');
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Executes the `deleteCommand` command.
     *
     * @private
     */
    private onExecuteDeleteCommand(): void {
        void DialogServiceLocator.current
            .open<DeleteProfileView, IDeleteProfileViewData, unknown>(DeleteProfileView.is, DeleteProfileView, {}, {
                user: this.user
            }).then((x) => {
                console.log(x);
            });

        // const result = await MessageBoxServiceLocator.current.open('delete', {
        //     header: TranslatorServiceLocator.current.translate('Delete profile'),
        //     message: TranslatorServiceLocator.current.translate('Are you sure you want to delete your profile?'),
        //     icon: Icons.errorCircle,
        //     buttons: MessageBoxButtons.OKCancel,
        //     labels: {
        //         ok: TranslatorServiceLocator.current.translate('Delete'),
        //         cancel: TranslatorServiceLocator.current.translate('Cancel')
        //     },
        //     hasBackdrop: true

        // });

        // if (result === MessageBoxResult.OK) {
        //     this.isBusy = true;

        //     void this._authService
        //         .delete({
        //             authorization: this._sessionManager.session
        //         })
        //         .then(() => {
        //             this._sessionManager.clear();
        //             window.location.href = '/';
        //         })
        //         .finally(() => {
        //             this.isBusy = false;
        //         });
        // }
    }

    /**
     * Executes the `addAvatarCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteAddAvatarCommand(parameter: IFilePickEventDetail): void {
        if (parameter.files.length) {
            const file = parameter.files.item(0);

            // check file size smaller than 1MB
            if (file && file.size > 1024 * 1024) {
                void ToastServiceLocator.current.open({
                    content: TranslatorServiceLocator.current.translate('loc.profile.general.messages.avatarToLarge'),
                    variant: Variant.Danger
                });
            } else if (file) {
                void FileExtensions.read(file, 'dataUrl').then((x) => {
                    const avatar = this.getTemplatePart<AvatarElement>('avatar');
                    avatar.src = String(x);
                    this.hasAvatar = true;
                });
            }
        }
    }

    /**
     * Executes the `removeAvatarCommand` command.
     *
     * @private
     */
    private onExecuteRemoveAvatarCommand(): void {
        const avatar = this.getTemplatePart<AvatarElement>('avatar');
        avatar.src = '';
        this.hasAvatar = false;
    }

    // #endregion

}
