// #region Imports

import { html, Orientation, ref, Size, type TabStripElement, type TabStripItemElement, type TemplateResult, Variant } from '@breadstone/mosaik-elements-foundation';

// #endregion

export namespace TabStripSampleTemplates {

    export function defaultTemplate(): TemplateResult {

        return html`
            <mosaik-tab-strip>
                <mosaik-tab-strip-item .isSelected="${true}"
                                       .orientation="${Orientation.Vertical}"
                                       label="Explore"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       label="Workout"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       .disabled="${true}"
                                       label="Food"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       label="You"></mosaik-tab-strip-item>
            </mosaik-tab-strip>
        `;

    }

    export function iconTemplate(): TemplateResult {

        return html`
            <mosaik-tab-strip>
                <mosaik-tab-strip-item .isSelected="${true}"
                                       .orientation="${Orientation.Vertical}"
                                       icon="M8.707 7.084c-1.02-.407-2.03.604-1.624 1.624l1.606 4.028a4.75 4.75 0 0 0 2.541 2.607l4.325 1.854c1.038.445 2.086-.604 1.642-1.641l-1.854-4.326a4.75 4.75 0 0 0-2.607-2.541L8.707 7.084Zm1.375 5.096-1.39-3.488 3.488 1.39a3.25 3.25 0 0 1 1.784 1.74l1.608 3.75-3.751-1.607a3.25 3.25 0 0 1-1.739-1.785Z M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-7.75 9h-.692A8.504 8.504 0 0 1 11 3.558v.692a.75.75 0 0 0 1.5 0v-.736A8.502 8.502 0 0 1 20.442 11h-.692a.75.75 0 0 0 0 1.5h.735a8.501 8.501 0 0 1-7.985 7.985v-.735a.75.75 0 0 0-1.5 0v.692A8.502 8.502 0 0 1 3.514 12.5h.736a.75.75 0 0 0 0-1.5Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       icon="M17.767 7.803a.75.75 0 0 1-1.06 1.06l-1.57-1.57a.75.75 0 0 1 1.06-1.06l1.57 1.57Zm-8.372 9.433a.75.75 0 0 0 0-1.061l-1.57-1.57a.75.75 0 1 0-1.06 1.06l1.57 1.57a.75.75 0 0 0 1.06 0ZM20.938 3.084a1.941 1.941 0 0 0-2.745 0l-.671.671-.207-.207a2.413 2.413 0 0 0-3.662.291 2.936 2.936 0 0 0-2.613 4.963l.718.718-2.24 2.239-.717-.718a2.936 2.936 0 0 0-4.963 2.613 2.413 2.413 0 0 0-.291 3.661l.207.208-.692.692a1.941 1.941 0 1 0 2.746 2.745l.692-.692.207.207a2.413 2.413 0 0 0 3.661-.29 2.936 2.936 0 0 0 2.613-4.963l-.717-.718 2.24-2.24.716.718a2.936 2.936 0 0 0 4.963-2.613 2.413 2.413 0 0 0 .291-3.661l-.207-.207.671-.671a1.941 1.941 0 0 0 0-2.746ZM19.207 5.44l-.624-.624.67-.67a.441.441 0 0 1 .625.623l-.671.671Zm-5.764 5.764-2.24 2.24-.624-.625 2.24-2.239.623.624Zm-8.004 8.003-.692.693a.441.441 0 1 1-.624-.624l.692-.692.624.623ZM14.963 4.61a.913.913 0 0 1 1.291 0l3.16 3.16a.913.913 0 0 1-.362 1.513.75.75 0 0 0-.45 1.025 1.437 1.437 0 0 1-2.321 1.614l-4.18-4.18a1.436 1.436 0 0 1 1.614-2.321.75.75 0 0 0 1.025-.45.906.906 0 0 1 .223-.361ZM5.71 12.102c.56-.56 1.47-.56 2.03 0l4.18 4.18a1.436 1.436 0 0 1-1.613 2.322.75.75 0 0 0-1.026.45.913.913 0 0 1-1.514.361l-3.16-3.16a.913.913 0 0 1 .362-1.514.75.75 0 0 0 .45-1.025 1.437 1.437 0 0 1 .29-1.614Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       .disabled="${true}"
                                       icon="M18.25 3.25a.75.75 0 0 1 .743.648L19 4v16a.75.75 0 0 1-1.493.102L17.5 20v-5h-2.25a.75.75 0 0 1-.743-.648l-.007-.102V7a3.75 3.75 0 0 1 3.75-3.75Zm-6 0a.75.75 0 0 1 .743.648L13 4v4c0 1.953-1.4 3.578-3.25 3.93V20a.75.75 0 0 1-1.493.102L8.25 20v-8.07a4.002 4.002 0 0 1-3.245-3.722L5 8V4a.75.75 0 0 1 1.493-.102L6.5 4v4c0 1.12.736 2.067 1.75 2.386V4a.75.75 0 0 1 1.493-.102L9.75 4v6.385a2.502 2.502 0 0 0 1.743-2.2L11.5 8V4a.75.75 0 0 1 .75-.75ZM17.5 13.5V4.878a2.252 2.252 0 0 0-1.494 1.95L16 7v6.5h1.5V4.878 13.5Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       icon="M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.575c0 .894-.32 1.76-.902 2.438-1.57 1.834-3.957 2.739-7.102 2.739-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.435v-.577a2.249 2.249 0 0 1 2.249-2.25h11.501Zm0 1.5H6.253a.749.749 0 0 0-.75.749v.577c0 .536.192 1.054.54 1.461 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 0 0 .541-1.463v-.575a.749.749 0 0 0-.749-.75ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm0 1.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z">
                    <mosaik-avatar slot="icon"
                                   style="--avatar-diameter: 24px;"
                                   .size="${Size.Tiny}"
                                   .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-avatar>
                </mosaik-tab-strip-item>
            </mosaik-tab-strip>
        `;

    }

    export function iconAndTextTemplate(): TemplateResult {

        return html`
            <mosaik-tab-strip>
                <mosaik-tab-strip-item .isSelected="${true}"
                                       .orientation="${Orientation.Vertical}"
                                       label="Explore"
                                       icon="M8.707 7.084c-1.02-.407-2.03.604-1.624 1.624l1.606 4.028a4.75 4.75 0 0 0 2.541 2.607l4.325 1.854c1.038.445 2.086-.604 1.642-1.641l-1.854-4.326a4.75 4.75 0 0 0-2.607-2.541L8.707 7.084Zm1.375 5.096-1.39-3.488 3.488 1.39a3.25 3.25 0 0 1 1.784 1.74l1.608 3.75-3.751-1.607a3.25 3.25 0 0 1-1.739-1.785Z M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-7.75 9h-.692A8.504 8.504 0 0 1 11 3.558v.692a.75.75 0 0 0 1.5 0v-.736A8.502 8.502 0 0 1 20.442 11h-.692a.75.75 0 0 0 0 1.5h.735a8.501 8.501 0 0 1-7.985 7.985v-.735a.75.75 0 0 0-1.5 0v.692A8.502 8.502 0 0 1 3.514 12.5h.736a.75.75 0 0 0 0-1.5Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       label="Workout"
                                       icon="M17.767 7.803a.75.75 0 0 1-1.06 1.06l-1.57-1.57a.75.75 0 0 1 1.06-1.06l1.57 1.57Zm-8.372 9.433a.75.75 0 0 0 0-1.061l-1.57-1.57a.75.75 0 1 0-1.06 1.06l1.57 1.57a.75.75 0 0 0 1.06 0ZM20.938 3.084a1.941 1.941 0 0 0-2.745 0l-.671.671-.207-.207a2.413 2.413 0 0 0-3.662.291 2.936 2.936 0 0 0-2.613 4.963l.718.718-2.24 2.239-.717-.718a2.936 2.936 0 0 0-4.963 2.613 2.413 2.413 0 0 0-.291 3.661l.207.208-.692.692a1.941 1.941 0 1 0 2.746 2.745l.692-.692.207.207a2.413 2.413 0 0 0 3.661-.29 2.936 2.936 0 0 0 2.613-4.963l-.717-.718 2.24-2.24.716.718a2.936 2.936 0 0 0 4.963-2.613 2.413 2.413 0 0 0 .291-3.661l-.207-.207.671-.671a1.941 1.941 0 0 0 0-2.746ZM19.207 5.44l-.624-.624.67-.67a.441.441 0 0 1 .625.623l-.671.671Zm-5.764 5.764-2.24 2.24-.624-.625 2.24-2.239.623.624Zm-8.004 8.003-.692.693a.441.441 0 1 1-.624-.624l.692-.692.624.623ZM14.963 4.61a.913.913 0 0 1 1.291 0l3.16 3.16a.913.913 0 0 1-.362 1.513.75.75 0 0 0-.45 1.025 1.437 1.437 0 0 1-2.321 1.614l-4.18-4.18a1.436 1.436 0 0 1 1.614-2.321.75.75 0 0 0 1.025-.45.906.906 0 0 1 .223-.361ZM5.71 12.102c.56-.56 1.47-.56 2.03 0l4.18 4.18a1.436 1.436 0 0 1-1.613 2.322.75.75 0 0 0-1.026.45.913.913 0 0 1-1.514.361l-3.16-3.16a.913.913 0 0 1 .362-1.514.75.75 0 0 0 .45-1.025 1.437 1.437 0 0 1 .29-1.614Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       .disabled="${true}"
                                       label="Food"
                                       icon="M18.25 3.25a.75.75 0 0 1 .743.648L19 4v16a.75.75 0 0 1-1.493.102L17.5 20v-5h-2.25a.75.75 0 0 1-.743-.648l-.007-.102V7a3.75 3.75 0 0 1 3.75-3.75Zm-6 0a.75.75 0 0 1 .743.648L13 4v4c0 1.953-1.4 3.578-3.25 3.93V20a.75.75 0 0 1-1.493.102L8.25 20v-8.07a4.002 4.002 0 0 1-3.245-3.722L5 8V4a.75.75 0 0 1 1.493-.102L6.5 4v4c0 1.12.736 2.067 1.75 2.386V4a.75.75 0 0 1 1.493-.102L9.75 4v6.385a2.502 2.502 0 0 0 1.743-2.2L11.5 8V4a.75.75 0 0 1 .75-.75ZM17.5 13.5V4.878a2.252 2.252 0 0 0-1.494 1.95L16 7v6.5h1.5V4.878 13.5Z"></mosaik-tab-strip-item>
                <mosaik-tab-strip-item .orientation="${Orientation.Vertical}"
                                       label="You"
                                       icon="M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.575c0 .894-.32 1.76-.902 2.438-1.57 1.834-3.957 2.739-7.102 2.739-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.435v-.577a2.249 2.249 0 0 1 2.249-2.25h11.501Zm0 1.5H6.253a.749.749 0 0 0-.75.749v.577c0 .536.192 1.054.54 1.461 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 0 0 .541-1.463v-.575a.749.749 0 0 0-.749-.75ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm0 1.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z">
                    <mosaik-avatar slot="icon"
                                   style="--avatar-diameter: 24px;"
                                   .size="${Size.Tiny}"
                                   .src="${'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'}"></mosaik-avatar>
                </mosaik-tab-strip-item>
            </mosaik-tab-strip>
        `;

    }

    export function manuelTemplate(): TemplateResult {

        let strip: TabStripElement | null = null;
        let stripItem: TabStripItemElement | null = null;

        const connectStrip = (element: TabStripElement) => {

            if (element) {

                strip = element;

            }

        };

        const connectStripItem = (element: TabStripItemElement) => {

            if (element) {

                stripItem = element;

            }

        };

        const selectByIndex = () => {

            strip?.selectByIndex(1);

        };

        const selectByValue = () => {

            strip?.selectByValue('c');

        };

        const select = () => {

            stripItem?.select();

        };

        return html`
            <div>
                <mosaik-tab-strip ${ref((x) => connectStrip(x as TabStripElement))} .variant="${Variant.Primary}">
                    <mosaik-tab-strip-item ${ref((x) => connectStripItem(x as TabStripItemElement))} .isSelected="${true}" label="a"></mosaik-tab-strip-item>
                    <mosaik-tab-strip-item label="b"></mosaik-tab-strip-item>
                    <mosaik-tab-strip-item label="c" .value="${'c'}"></mosaik-tab-strip-item>
                </mosaik-tab-strip>

                <mosaik-stack .orientation="${Orientation.Horizontal}">
                    <mosaik-button .label="${'Select a'}" @click="${() => select()}"></mosaik-button>
                    <mosaik-button .label="${'Select b'}" @click="${() => selectByIndex()}"></mosaik-button>
                    <mosaik-button .label="${'Select c'}" @click="${() => selectByValue()}"></mosaik-button>
                </mosaik-stack>
            </div>
        `;

    }

}
