export const usageNativeHtmlCode = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Mosaik Elemente Beispiel</title>
    <script type="module">
        import '@breadstone/mosaik-elements-foundation';

        document.addEventListener('DOMContentLoaded', () => {
            const myElement = document.createElement('mosaik-element');
            document.body.appendChild(myElement);
        });
    </script>
</head>
<body>
</body>
</html>
`;

export const usageAngularTsCode = `
// app.module.ts
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonComponent } from '@breadstone/mosaik-elements-angular';

@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
`;

export const usageAngularHtmlCode = `
<!-- app.component.html -->
<mosaik-button label="Hello Mosaik"></mosaik-button>
`;

export const usageReactTsxCode = `
// App.tsx
import React from 'react';
import { Button } from '@breadstone/mosaik-elements-react';

const App: React.FC = () => {
  return (
    <div className="App">
      <Button label="Hello Mosaik"></Button>
    </div>
  );
}

export default App;
`;

export const usageVueTsCode = `
// main.ts
import Vue from 'vue';
import App from './App.vue';
import { Button } from '@breadstone/mosaik-elements-vue';

Vue.config.productionTip = false;

Vue.component('Button', Button);

new Vue({
  render: h => h(App),
}).$mount('#app');
`;

export const usageVueHtmlCode = `
<!-- App.vue -->
<template>
  <div id="app">
    <Button label="Hello Mosaik"></Button>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class App extends Vue {}
</script>
`;