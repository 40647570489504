//#region Imports

import { Component, FlowDirection, GridElement, HorizontalAlignment, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { gridElementDocs } from '../../../.Generated/mosaik-grid';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, jsonTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { GridSampleTemplates } from './GridSample.Templates';

//#endregion

/**
 * The `{@link GridSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-grid-sample',
    imports: [
        GridElement
    ]
})
export class GridSampleElement extends SampleBaseElement<GridElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<GridElement> {
        return {
            header: 'Grid',
            description: META.description(GridElement.is),
            graph: META.graph(GridElement.is),
            playground: {
                template: GridSampleTemplates.defaultTemplate(),
                properties: META.properties<GridElement>(GridElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'columnDefinitions', value: ['*'] },
                    { key: 'rowDefinitions', value: ['*'] },
                    { key: 'horizontalAlignment', value: HorizontalAlignment.Stretch },
                    { key: 'verticalAlignment', value: VerticalAlignment.Stretch },
                    { key: 'gap', value: '16px' }
                ]),
                events: META.events(GridElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<GridElement>(GridElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'columnDefinitions', value: ['*'] },
                    { key: 'rowDefinitions', value: ['*'] },
                    { key: 'horizontalAlignment', value: HorizontalAlignment.Stretch },
                    { key: 'verticalAlignment', value: VerticalAlignment.Stretch },
                    { key: 'gap', value: '16px' }
                ]), [
                    { key: 'gap', template: cssLengtTemplate },
                    { key: 'columnDefinitions', template: jsonTemplate },
                    { key: 'rowDefinitions', template: jsonTemplate }
                ] as Array<IPlaygroundProperty<GridElement>>, (a, b) => a.key === b.key), [
                    { key: 'items' },
                ] as Array<IPlaygroundProperty<GridElement>>, (a, b) => a.key === b.key),
            },
            docs: [
                gridElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-grid-sample': GridSampleElement;
    }
}

