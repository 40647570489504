// #region Imports

import { Appearance, html, Size, Spacing, translate, typography, Variant, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import type { ContactView } from './ContactView';

// #endregion

/**
 * The template of the {@link ContactView}.
 *
 * @public
 */
export function contactViewTemplate<T extends ContactView>(e: T): TemplateResult {
    return html`
        <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                              ${typography('headline4')}
                              .label="${translate('loc.landing.contact.title')}"
                              .ref="${'contact'}"></mosaik-router-anchor>
        <mosaik-text .text="${translate('loc.landing.contact.description')}"
                     .wrap="${true}"></mosaik-text>
        <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
        <mosaik-router-link .href="${'/contact'}">
            <mosaik-button .label="${translate('loc.landing.contact.action.label')}"
                           .appearance="${Appearance.Outline}"
                           .variant="${Variant.Primary}"
                           .size="${Size.Giant}"></mosaik-button>
        </mosaik-router-link>
    `;
}
