//#region Imports

import { globals } from '../../Globals';

//#endregion

export const colorCode = `
/* ${globals.name}-like css class */
.my-element {
    /* Sets the custom background color. */
    background-color: var(--theme-primary-500-color);
}
`;
