// #region Imports

import { ButtonElement, Component, CustomElement, Emit, HTMLElementEventEmitter, IntlController, Property, TextBoxElement, TextElement, type IEventEmitter } from '@breadstone/mosaik-elements-foundation';
import { captchaElementStyle } from './CaptchaElementStyle';
import { captchaElementTemplate } from './CaptchaElementTemplate';
import type { ICaptchaChangedEvent, ICaptchaChangedEventDetail } from './Events/ICaptchaChangedEventDetail';
import type { ICaptchaElementProps } from './ICaptchaElementProps';
import { CaptchaElementIntl } from './Intl/CaptchaElementIntl';

// #endregion

/**
 * The `{@link CaptchaElement}` element.
 *
 * @fires captchaSolved - Fired when the value of the element changes.
 *
 * @public
 */
@Component({
    selector: 'app-captcha',
    template: captchaElementTemplate,
    styles: captchaElementStyle,
    imports: [
        TextElement,
        TextBoxElement,
        ButtonElement
    ]
})
export class CaptchaElement
    extends CustomElement
    implements ICaptchaElementProps {

    // #region Fields

    private readonly _intl: IntlController<CaptchaElementIntl>;
    private readonly _captchaSolved: IEventEmitter<ICaptchaChangedEventDetail>;
    private _question: string;
    private _answer: number | null;
    private _options: Array<number>;
    private _valid: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._question = '';
        this._answer = null;
        this._options = [];
        this._valid = false;
        this._captchaSolved = new HTMLElementEventEmitter<ICaptchaChangedEventDetail>(this, 'captchaSolved');
        this._intl = new IntlController<CaptchaElementIntl>(this, {
            factory: () => new CaptchaElementIntl()
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-captcha';
    }

    /**
     * Gets or sets the `question` property.
     *
     * @public
     */
    @Property({ type: String })
    public get question(): string {
        return this._question;
    }

    public set question(value: string) {
        if (this._question !== value) {
            this._question = value;
            this.requestUpdate('question');
        }
    }

    /**
     * Gets or sets the `answer` property.
     *
     * @public
     */
    @Property({ type: Number })
    public get answer(): number | null {
        return this._answer;
    }

    public set answer(value: number | null) {
        if (this._answer !== value) {
            this._answer = value;
            this.requestUpdate('answer');
        }
    }

    /**
     * Gets or sets the `value` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get options(): Array<number> {
        return this._options;
    }

    public set options(value: Array<number>) {
        if (this._options !== value) {
            this._options = value;
            this.requestUpdate('options');
        }
    }

    /**
     * Gets or sets the `valid` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get valid(): boolean {
        return this._valid;
    }

    private set valid(value: boolean) {
        if (this._valid !== value) {
            this._valid = value;
            this.requestUpdate('valid');
        }
    }

    /**
     * Called when the `Captcha` is changed.
     * Provides reference to {@link ICaptchaChangedEventDetail} as event argument.
     *
     * @public
     * @eventProperty
     * @readonly
     */
    @Emit({ eventName: 'captchaSolved' })
    public get slotChanged(): IEventEmitter<ICaptchaChangedEventDetail> {
        return this.slotChanged;
    }

    /**
     * Returns the `intl` property.
     *
     * @public
     * @readonly
     */
    public get intl(): CaptchaElementIntl {
        return this._intl.intl;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.generateCaptcha();
    }

    /**
     * @public
     */
    public validate(answer: number): void {
        this.valid = answer === this.answer;
        this._captchaSolved.emit({
            answer: answer,
            valid: this.valid
        });

        if (answer !== this.answer) {
            this.generateCaptcha();
        }
    }

    /**
     * @private
     */
    private generateCaptcha(): void {
        const a = Math.floor(Math.random() * 10);
        const b = Math.floor(Math.random() * 10);
        // TODO: use string formatter instead of replace.
        this.question = this._intl.intl.question.replace('{0}', a.toString()).replace('{1}', b.toString());
        this.answer = a + b;

        // randomize options
        this.options = this.generateOptions(this.answer);
    }

    /**
     * @private
     */
    private generateOptions(correctAnswer: number): Array<number> {
        const options = new Set<number>();
        options.add(correctAnswer);

        // generate 2 wrong answers
        while (options.size < 3) {
            const wrongAnswer = correctAnswer + Math.floor(Math.random() * 3) - 1;
            options.add(wrongAnswer);
        }

        return Array.from(options).sort(() => Math.random() - 0.5); // shuffle
    }

    // #endregion

}

declare global {

    interface HTMLElementTagNameMap {

        'app-captcha': CaptchaElement;
    }

    interface HTMLElementEventMap {
        captchaSolved: ICaptchaChangedEvent;
    }
}
