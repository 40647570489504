// #region Imports

import { Component, Inject, Property, css, type CSSResultGroup } from '@breadstone/mosaik-elements-foundation';
import { MetaService } from '../../Services/MetaService';
import { ViewBase } from '../Abstracts/ViewBase';
import { landingViewTemplate } from './LandingViewTemplate';

// #endregion

/**
 * The `{@link LandingView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-view',
    template: landingViewTemplate
})
export class LandingView extends ViewBase {

    // #region Fields

    @Inject(MetaService)
    private readonly _metaService!: MetaService;
    private _count: number;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._count = this._metaService.count();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return css`
        :host {
            position: relative;
            display: flex;
            // height: 100%;
            width: 100%;
        }

        :host .main {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 600px;
            margin-top: -80px;
        }

        :host .intro{
            height: 100vh;
            flex: inherit;
        }

        :host .argument{
            background-color: var(--theme-semi-transparent-color);
        }

        :host .newsletter {
            background: linear-gradient(45deg, var(--theme-secondary-500-color), var(--theme-primary-500-color), var(--theme-tertiary-500-color));
        }

        :host [part='logo'] {
            zoom: 2.4;
        }

        :host [part='logo'][type='logo'] {
            zoom: 4.4;
        }

        :host [part='lightChain'] {
            position: sticky;
            top: 64px;
            width: initial;
            height: initial;
            z-index: 2;
            overflow-y: initial;
            overflow-x: clip;

            &::part(root) {
                margin: -4px;
            }
        }
        `;
    }

    /**
     * Gets or sets the `count` property.
     *
     * @public
     */
    @Property({ type: Number })
    public get count(): number { return this._count; }

    private set count(value: number) {
        if (this._count !== value) {
            this._count = value;
            this.requestUpdate('count');
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-landing-view': LandingView;
    }
}
