// #region Imports

import { Appearance, Fit, HorizontalAlignment, Orientation, Size, Spacing, TextBoxType, Variant, VerticalAlignment, choose, emailElementValidator, flex, html, query, requiredElementValidator, textBoxElementValueAccessor, translate, typography, type TemplateResult, type TextBoxElement } from '@breadstone/mosaik-elements-foundation';
import type { ICaptchaChangedEvent } from '../../../Components/Captcha/Events/ICaptchaChangedEventDetail';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import type { NewsletterView } from './NewsletterView';

// #endregion

/**
 * The template of the {@link NewsletterView}.
 *
 * @public
 */
export function newsletterViewTemplate<T extends NewsletterView>(e: T): TemplateResult {
    return html`
        <!-- <app-duck class="duck" .width="${'32px'}" .height="${'48px'}"></app-duck> -->
        <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                              ${typography('headline4')}
                              .label="${translate('loc.newsletter.title')}"
                              .ref="${'newsletter'}"></mosaik-router-anchor>
        <mosaik-text .text="${translate('loc.newsletter.description')}"
                     .wrap="${true}"></mosaik-text>
        <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
        <mosaik-form part="form"
                     ${flex({ direction: 'row', gap: '16px', alignItems: 'stretch', fill: true })}>
            ${choose(e.state, [['subscribe', () => html`
            <mosaik-form-field style="width: 100%"
                               .accessor="${textBoxElementValueAccessor}"
                               .validators="${[requiredElementValidator() as any, emailElementValidator() as any]}"
                               .required="${true}">
                <mosaik-textbox id="newsletter"
                                style="--textbox-border-radius: 26px;"
                                .type="${TextBoxType.Email}"
                                .label="${translate('loc.newsletter.email.label')}"
                                .required="${true}">
                    <mosaik-button slot="suffix"
                                   style="--button-border-radius: 20px;"
                                   .label="${translate('loc.newsletter.email.action')}"
                                   .appearance="${Appearance.Solid}"
                                   .variant="${Variant.Primary}"
                                   .type="${'button'}"
                                   @click="${() => e.onSubscribe(query<TextBoxElement>(e, '#newsletter', { shadow: true, strict: false })?.value ?? '')}"></mosaik-button>
                </mosaik-textbox>
            </mosaik-form-field>
            `], ['validate', () => html`
            <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                            .horizontalAlignment="${HorizontalAlignment.Center}"
                            .orientation="${Orientation.Vertical}"
                            .fit="${Fit.Both}">
                <mosaik-text .text="${translate('loc.newsletter.captcha.label')}"
                             .wrap="${true}"></mosaik-text>
                <app-captcha @captchaSolved="${(x: ICaptchaChangedEvent) => e.onCaptchaChanged(x.detail)}"></app-captcha>
            </mosaik-stack>
            `], ['done', () => html`
            <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                          .horizontalAlignment="${HorizontalAlignment.Center}"
                          .orientation="${Orientation.Vertical}"
                          .fit="${Fit.Both}">
                <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                             .size="${Size.Large}"></mosaik-icon>
                <mosaik-text .text="${translate('loc.newsletter.done.label')}"></mosaik-text>
            </mosaik-stack>
            `], ['busy', () => html`
            <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                          .horizontalAlignment="${HorizontalAlignment.Center}"
                          .orientation="${Orientation.Vertical}"
                          .fit="${Fit.Both}">
                <mosaik-progress-ring .radius="${24}"
                                      .thickness="${4}"
                                      .isIndeterminate="${true}"></mosaik-progress-ring>
                <mosaik-text .text="${translate('loc.newsletter.busy.label')}"></mosaik-text>
            </mosaik-stack>
            `]])}
        </mosaik-form>
    `;
}
