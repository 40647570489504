// #region Imports

import { Appearance, Orientation, Placement, type TemplateResult, Variant, html, repeat, translate, when } from '@breadstone/mosaik-elements-foundation';
import { globals } from '../../Globals';
import type { AppBar } from './AppBar';

// #endregion

/**
 * The template of the {@link AppBar}.
 *
 * @public
 */
export function appBarTemplate<T extends AppBar>(e: T): TemplateResult {
    return html`
        ${when(e.hasMenu, () => html`
        <mosaik-toolbar part="toolbar">
            <mosaik-tooltip slot="start"
                            .content="${translate('loc.global.menu.tooltip')}"
                            .placement="${Placement.Bottom}">
                <mosaik-button .appearance="${Appearance.Plain}"
                               .icon="${'M3 17h18a1 1 0 0 1 .117 1.993L21 19H3a1 1 0 0 1-.117-1.993L3 17h18H3Zm0-6 18-.002a1 1 0 0 1 .117 1.993l-.117.007L3 13a1 1 0 0 1-.117-1.993L3 11l18-.002L3 11Zm0-6h18a1 1 0 0 1 .117 1.993L21 7H3a1 1 0 0 1-.117-1.993L3 5h18H3Z'}"
                               @click="${() => e.drawerOpened.emit()}"></mosaik-button>
            </mosaik-tooltip>
            <mosaik-router-link slot="title">
                <app-logo style="height: 32px" .type="${'text'}"></app-logo>
            </mosaik-router-link>
            <!-- <span slot="end" class="clear-fix"> -->
            <mosaik-stack slot="end"
                          .orientation="${Orientation.Horizontal}"
                          .gap="${'8px'}">
                ${repeat(globals.sites.filter((x) => x.external && x.location === 'header'), (x) => x, (x) => html`
                <mosaik-button .appearance="${Appearance.Plain}"
                               .icon="${x.icon ?? ''}"
                               @click="${() => e.openLinkCommand.execute(x.url)}"></mosaik-button>
                `)}
            </mosaik-stack>
            <mosaik-stack slot="end">
                ${when(!e.user || e.user.isAnonymous, () => html`
                <mosaik-router-link .href="${'/auth'}">
                    <mosaik-button part="login"
                                   .appearance="${Appearance.Outline}"
                                   .variant="${Variant.Primary}"
                                   .label="${translate('loc.global.login')}"></mosaik-button>
                </mosaik-router-link>
                `, () => html`
                <mosaik-dropdown-button .dropDownPlacement="${Placement.Bottom}"
                                        .appearance="${Appearance.Plain}"
                                        .dropDownStrategy="${'absolute'}">
                    <mosaik-avatar slot="icon" .size="${'tiny'}"
                                   .text="${e.user?.profile.fullName ?? ''}"
                                   .src="${e.user?.profile.avatar ?? ''}"></mosaik-avatar>
                    <mosaik-menu>
                        <mosaik-router-link .href="${'/profile'}">
                            <mosaik-menu-item .label="${translate('loc.nav.profile')}"></mosaik-menu-item>
                        </mosaik-router-link>
                        <mosaik-menu-item .label="${translate('loc.nav.logout')}"
                                          @click="${() => e.logoutCommand.execute()}"></mosaik-menu-item>
                    </mosaik-menu>
                </mosaik-dropdown-button>
                `)}
            </mosaik-stack>
        </mosaik-toolbar>
        `, () => html`
        <mosaik-pagemenu part="toolbar"
                         .breakpoint="${'md'}">
            ${when(e.isMobile, () => html`
            <mosaik-dropdown-button slot="start"
                                    .caretVisible="${false}"
                                    .icon="${'M3 17h18a1 1 0 0 1 .117 1.993L21 19H3a1 1 0 0 1-.117-1.993L3 17h18H3Zm0-6 18-.002a1 1 0 0 1 .117 1.993l-.117.007L3 13a1 1 0 0 1-.117-1.993L3 11l18-.002L3 11Zm0-6h18a1 1 0 0 1 .117 1.993L21 7H3a1 1 0 0 1-.117-1.993L3 5h18H3Z'}"
                                    .appearance="${Appearance.Plain}"
                                    .dropDownPlacement="${Placement.Bottom}">
                <mosaik-menu>
                    <mosaik-router-link .href="${'/getStarted'}">
                        <mosaik-menu-item .label="${translate('loc.nav.getStarted')}"></mosaik-menu-item>
                    </mosaik-router-link>
                    <mosaik-router-link .href="${'/design'}">
                        <mosaik-menu-item .label="${translate('loc.nav.design')}"></mosaik-menu-item>
                    </mosaik-router-link>
                    <mosaik-router-link .href="${'/components'}">
                        <mosaik-menu-item .label="${translate('loc.nav.components')}"></mosaik-menu-item>
                    </mosaik-router-link>
                    <mosaik-router-link .href="${'/tools'}">
                        <mosaik-menu-item .label="${translate('loc.nav.tools')}"></mosaik-menu-item>
                    </mosaik-router-link>
                </mosaik-menu>
            </mosaik-dropdown-button>
            `, () => html`
            <mosaik-router-link slot="start"
                                .href="${'/getStarted'}">
                <mosaik-button .label="${translate('loc.nav.getStarted')}"
                               .appearance="${Appearance.Plain}"></mosaik-button>
            </mosaik-router-link>
            <mosaik-router-link slot="start"
                                .href="${'/design'}">
                <mosaik-button .label="${translate('loc.nav.design')}"
                               .appearance="${Appearance.Plain}"></mosaik-button>
            </mosaik-router-link>
            <mosaik-router-link slot="start"
                                .href="${'/components'}">
                <mosaik-button .label="${translate('loc.nav.components')}"
                               .appearance="${Appearance.Plain}"></mosaik-button>
            </mosaik-router-link>
            <mosaik-router-link slot="start"
                                .href="${'/tools'}">
                <mosaik-button .label="${translate('loc.nav.tools')}"
                               .appearance="${Appearance.Plain}"></mosaik-button>
            </mosaik-router-link>
            `)}

            <mosaik-stack slot="end"
                          .orientation="${Orientation.Horizontal}"
                          .gap="${'8px'}">
                ${repeat(globals.sites.filter((x) => x.external && x.location === 'header'), (x) => x, (x) => html`
                <mosaik-button .appearance="${Appearance.Plain}"
                               .icon="${x.icon ?? ''}"
                               @click="${() => e.openLinkCommand.execute(x.url)}"></mosaik-button>
                `)}
            </mosaik-stack>
            <mosaik-stack slot="end">
                ${when(!e.user || e.user.isAnonymous, () => html`
                <mosaik-router-link .href="${'/auth'}">
                    <mosaik-button part="login"
                                   .appearance="${Appearance.Outline}"
                                   .variant="${Variant.Primary}"
                                   .label="${translate('loc.global.login')}"></mosaik-button>
                </mosaik-router-link>
                `, () => html`
                <mosaik-dropdown-button .dropDownPlacement="${Placement.Bottom}"
                                        .appearance="${Appearance.Plain}"
                                        .dropDownStrategy="${'absolute'}">
                    <mosaik-avatar slot="icon" .size="${'tiny'}"
                                   .text="${e.user?.profile.fullName ?? ''}"
                                   .src="${e.user?.profile.avatar ?? ''}"></mosaik-avatar>
                    <mosaik-menu>
                        <mosaik-router-link .href="${'/profile'}">
                            <mosaik-menu-item .label="${translate('loc.nav.profile')}"></mosaik-menu-item>
                        </mosaik-router-link>
                        <mosaik-menu-item .label="${translate('loc.nav.logout')}"
                                          @click="${() => e.logoutCommand.execute()}"></mosaik-menu-item>
                    </mosaik-menu>
                </mosaik-dropdown-button>
                `)}
            </mosaik-stack>
        </mosaik-pagemenu>
        `)}
    `;
}
