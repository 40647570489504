// #region Imports

import { CSSResultGroup, Component, FlowDirection, ListElement, SelectionMode } from '@breadstone/mosaik-elements-foundation';
import { listElementDocs } from '../../../.Generated/mosaik-list';
import { listItemElementDocs } from '../../../.Generated/mosaik-list-item';
import { listItemGroupElementDocs } from '../../../.Generated/mosaik-list-item-group';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { defaultTemplate } from './ListSample.Templates';

// #endregion

/**
 * The `{@link ListSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-list-sample'
})
export class ListSampleElement extends SampleBaseElement<ListElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ListElement> {

        return {
            header: 'List',
            description: META.description(ListElement.is),
            graph: META.graph(ListElement.is),
            playground: {
                template: (props) => defaultTemplate(props),
                properties: META.properties<ListElement>(ListElement.is, [
                    // add dir
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Multiple
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<ListElement>(ListElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Multiple
                    }
                ]),
                exludedProperties: ['items', 'selectedItem']
            },
            docs: [
                listElementDocs(),
                listItemElementDocs(),
                listItemGroupElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-list-sample': ListSampleElement;
    }
}
