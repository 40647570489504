// #region Imports

import { Animate, Appearance, ButtonType, Inset, Orientation, type TemplateResult, type TextBoxElement, TextFormatters, Variant, animate, flex, html, requiredElementValidator, textBoxElementValueAccessor, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import type { ContactView } from './ContactView';

// #endregion

/**
 * The template of the {@link ContactView}.
 *
 * @public
 */
export function contactViewTemplate<T extends ContactView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.contact')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/contact'}"
                                        .label="${translate('loc.nav.contact')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.contact.title')}"
                                      .ref="${'contact'}"></mosaik-router-anchor>
                <mosaik-text .text="${translate('loc.contact.description', globals.authorEmail)}"
                             .wrap="${true}"
                             .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>
            </mosaik-page-content>
            <mosaik-page-content .inset="${Inset.All}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'24px'}">
                    <mosaik-form part="form" name="contact"
                                 ${flex({ direction: 'column', gap: '24px', alignItems: 'stretch' })}>
                        <div ${flex({ direction: 'row', gap: '24px', alignItems: 'stretch' })}>
                            <mosaik-form-field ${flex({ fill: true })}
                                               .accessor="${textBoxElementValueAccessor}"
                                               .validators="${[requiredElementValidator()]}">
                                <mosaik-textbox .label="${translate('loc.contact.firstName.label')}"
                                                .placeholder="${translate('loc.contact.firstName.placeholder')}"
                                                .value="${e.firstName}"
                                                .variant="${Variant.Primary}"
                                                ?required="${true}"
                                                ?disabled="${e.isBusy}"
                                                @input="${(x: Event) => e.firstName = (x.target as TextBoxElement).value}"></mosaik-textbox>
                            </mosaik-form-field>
                            <mosaik-form-field ${flex({ fill: true })}
                                               .accessor="${textBoxElementValueAccessor}"
                                               .validators="${[requiredElementValidator()]}">
                                <mosaik-textbox .label="${translate('loc.contact.lastName.label')}"
                                                .placeholder="${translate('loc.contact.lastName.placeholder')}"
                                                .value="${e.lastName}"
                                                .variant="${Variant.Primary}"
                                                ?required="${true}"
                                                ?disabled="${e.isBusy}"
                                                @input="${(x: Event) => e.lastName = (x.target as TextBoxElement).value}"></mosaik-textbox>
                            </mosaik-form-field>
                        </div>
                        <mosaik-form-field .accessor="${textBoxElementValueAccessor}"
                                           .validators="${[requiredElementValidator()]}">
                            <mosaik-textbox .label="${translate('loc.contact.email.label')}"
                                            .placeholder="${translate('loc.contact.email.placeholder')}"
                                            .value="${e.email}"
                                            .variant="${Variant.Primary}"
                                            ?required="${true}"
                                            ?disabled="${e.isBusy}"
                                            @input="${(x: Event) => e.email = (x.target as TextBoxElement).value}"></mosaik-textbox>
                        </mosaik-form-field>
                        <mosaik-form-field .accessor="${textBoxElementValueAccessor}"
                                           .validators="${[requiredElementValidator()]}">
                            <mosaik-textbox .label="${translate('loc.contact.message.label')}"
                                            .placeholder="${translate('loc.contact.message.placeholder')}"
                                            .value="${e.message}"
                                            .variant="${Variant.Primary}"
                                            .required="${true}"
                                            .multiline="${true}"
                                            .multilineRows="${6}"
                                            ?disabled="${e.isBusy}"
                                            @input="${(x: Event) => e.message = (x.target as TextBoxElement).value}"></mosaik-textbox>
                        </mosaik-form-field>
                    </mosaik-form>
                    <mosaik-button .label="${translate('loc.contact.submit.label')}"
                                   .variant="${Variant.Primary}"
                                   .appearance="${Appearance.Solid}"
                                   .isBusy="${e.isBusy}"
                                   .type="${ButtonType.Submit}"
                                   @click="${() => e.onSubmit()}"></mosaik-button>
                </mosaik-stack>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
