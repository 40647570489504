//#region Imports

import { Animate, Component, Inset, animate, translate } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

//#endregion

/**
 * The `{@link VisibilityView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-visibility-view'
})
export class VisibilityView extends ViewBase {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-visibility-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    //#endregion

    //#region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.visibility')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/visibility'}" .label="${translate('loc.nav.visibility')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header slot="header"
                                    .text="${translate('loc.nav.visibility')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-empty-state .header="${translate('loc.global.availableSoon.title')}"
                                    .content="${translate('loc.global.availableSoon.message')}"></mosaik-empty-state>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-visibility-view': VisibilityView;
    }
}

