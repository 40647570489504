// #region Imports

import { animate, Animate, Appearance, DialogServiceLocator, flex, html, repeat, requiredElementValidator, selectElementValueAccessor, Size, Spacing, textBoxElementValueAccessor, translate, Variant, VerticalAlignment, when, type TemplateResult, type TextBoxElement } from '@breadstone/mosaik-elements-foundation';
import { Icons } from '../../../Resources/Icons/Icons';
import { getFeedbackTypeSymbol } from '../Utils';
import { CreateFeedbackView } from './CreateFeedbackView';

// #endregion

/**
 * The template of the {@link CreateFeedbackViewEleme}.
 *
 * @public
 */
export function createFeedbackViewTemplate<T extends CreateFeedbackView>(e: T): TemplateResult {
    return html`
    <mosaik-dialog>
        <mosaik-dialog-header>
            <mosaik-dialog-header-text .text="${translate('loc.feedback.create.header')}"></mosaik-dialog-header-text>
            <mosaik-dialog-header-sub-text .text="${translate('loc.feedback.create.subHeader')}"></mosaik-dialog-header-sub-text>
        </mosaik-dialog-header>
        <mosaik-dialog-content>
            <mosaik-spacer .thickness="${Spacing.Bottom}" .size="${Size.Small}">
                ${when(!e.user?.isAnonymous, () => html`
                <mosaik-banner ${animate({ ...Animate.slideInTop })} .appearance="${Appearance.Soft}"
                               .variant="${Variant.Info}"
                               .header="${translate('You are currently not logged in!')}"
                               .subHeader="${translate('If you want be notified about the progress of your feedback, please log in. If you want to proceed without logging in, you can do so.')}"
                               .icon="${Icons.WARNING}">
                    <mosaik-router-link slot="actions" .href="${'/auth'}">
                        <mosaik-button .label="${translate('Log me in')}"
                                       .variant="${Variant.Info}"
                                       .appearance="${Appearance.Solid}"></mosaik-button>
                    </mosaik-router-link>
                </mosaik-banner>
                `)}
                <mosaik-summary .header="${translate('Author')}">
                    <mosaik-stack .gap="${'8px'}"
                                  .verticalAlignment="${VerticalAlignment.Center}">
                        <mosaik-avatar .size="${Size.Small}"
                                       .text="${e.user?.profile.fullName ?? ''}"
                                       .src="${e.user?.profile.avatar ?? ''}"></mosaik-avatar>
                        <mosaik-text .text="${e.user?.profile.fullName}"></mosaik-text>
                    </mosaik-stack>
                </mosaik-summary>
            </mosaik-spacer>
            <mosaik-form part="form" ${flex({ direction: 'column', gap: '24px', alignItems: 'stretch' })}>
                <mosaik-form-field .validators="${[requiredElementValidator()]}"
                                   .accessor="${selectElementValueAccessor}">
                    <mosaik-select .label="${translate('loc.feedback.create.data.type.label')}"
                                   .variant="${Variant.Primary}"
                                   .maxDropDownHeight="${'auto'}"
                                   ?disabled="${e.isBusy}">
                        ${repeat(e.feedbackTypes, (x) => x.id, (x) => html`
                        <mosaik-select-item .label="${getFeedbackTypeSymbol(x.name)} ${translate(x.name)}"
                                            .value="${x.id}"
                                            @selected="${() => e.feedbackType = x}"></mosaik-select-item>
                        `)}
                    </mosaik-select>
                </mosaik-form-field>
                <mosaik-form-field .validators="${[requiredElementValidator()]}"
                                   .accessor="${textBoxElementValueAccessor}">
                    <mosaik-textbox .label="${translate('loc.feedback.create.data.subject.label')}"
                                    .placeholder="${translate('loc.feedback.create.data.subject.placeholder')}"
                                    .value="${e.subject}"
                                    .variant="${Variant.Primary}"
                                    ?required="${true}"
                                    ?disabled="${e.isBusy}"
                                    @input="${(x: Event) => e.subject = (x.target as TextBoxElement).value}"></mosaik-textbox>
                </mosaik-form-field>
                <mosaik-form-field ${flex({ fill: true })}
                                   .validators="${[requiredElementValidator()]}"
                                   .accessor="${textBoxElementValueAccessor}">
                    <mosaik-textbox .label="${translate('loc.feedback.create.data.description.label')}"
                                    .placeholder="${translate('loc.feedback.create.data.description.placeholder')}"
                                    .multiline="${true}"
                                    .multilineRows="${6}"
                                    .value="${e.description}"
                                    .variant="${Variant.Primary}"
                                    ?required="${true}"
                                    ?disabled="${e.isBusy}"
                                    @input="${(x: Event) => e.description = (x.target as TextBoxElement).value}"></mosaik-textbox>
                </mosaik-form-field>
            </mosaik-form>
        </mosaik-dialog-content>
        <mosaik-dialog-actions>
            <mosaik-button .label="${translate('loc.feedback.create.actions.cancel.label')}"
                           .appearance="${Appearance.Plain}"
                           .variant="${Variant.Default}"
                           .disabled="${e.isBusy}"
                           @click="${() => DialogServiceLocator.current.close(CreateFeedbackView.is)}"></mosaik-button>
            <mosaik-button .label="${translate('loc.feedback.create.actions.create.label')}"
                           .appearance="${Appearance.Solid}"
                           .variant="${Variant.Primary}"
                           .isBusy="${e.isBusy}"
                           @click="${() => e.onCreateFeedback()}"></mosaik-button>
        </mosaik-dialog-actions>
    </mosaik-dialog>
    `;
}
