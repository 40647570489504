// #region Imports

import { Appearance, CameraCaptureFormat, CameraElement, CameraRecorderFormat, CameraType, Component, Fit, FlowDirection, HorizontalAlignment, ICameraCaptureEvent, ref, Size, ToastServiceLocator, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { cameraElementDocs } from '../../../.Generated/mosaik-camera';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link CameraSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-camera-sample'
})
export class CameraSampleElement extends SampleBaseElement<CameraElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CameraElement> {
        return {
            header: 'Camera',
            description: META.description(CameraElement.is),
            graph: META.graph(CameraElement.is),
            playground: {
                template: (props, set, get) => html`
                    <mosaik-camera ${ref((el) => set(el as CameraElement))}
                                   @error="${(e: CustomEvent) => ToastServiceLocator.current.open({ content: e.detail.error })}"
                                   @captured="${(e: ICameraCaptureEvent) => ToastServiceLocator.current.open({ content: 'cheees 🧀' })}">
                        <mosaik-stack slot="overlay" .fit="${Fit.Both}" .verticalAlignment="${VerticalAlignment.Bottom}">
                        <mosaik-stack  .gap="${'16px'}"
                                      .verticalAlignment="${VerticalAlignment.Center}"
                                      .horizontalAlignment="${HorizontalAlignment.Center}">
                            <mosaik-fab .icon="${'M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z'}"
                                        .size="${Size.Large}"
                                        .appearance="${Appearance.Solid}"
                                        @click="${() => get()?.record()}"></mosaik-fab>
                            <mosaik-fab .icon="${'M13.925 2.503a2.25 2.25 0 0 1 1.94 1.11L16.679 5h2.071A3.25 3.25 0 0 1 22 8.25v9.5A3.25 3.25 0 0 1 18.75 21H5.25A3.25 3.25 0 0 1 2 17.75v-9.5A3.25 3.25 0 0 1 5.25 5h2.08l.875-1.424a2.25 2.25 0 0 1 1.917-1.073h3.803ZM12 8a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 1.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z'}"
                                        .size="${Size.Medium}"
                                        .appearance="${Appearance.Solid}"
                                        @click="${() => get()?.capture()}"></mosaik-fab>
                        </mosaik-stack>
                        </mosaik-stack>
                    </mosaik-camera>
                `,
                properties: META.properties2<CameraElement>(CameraElement.is, {
                    dir: FlowDirection.Auto,
                    width: '200px',
                    height: '300px',
                    autoPlay: true,
                    poster: '',
                    autoFullScreen: false,
                    captureFormat: CameraCaptureFormat.Jpeg,
                    type: CameraType.Front,
                    recorderFormat: CameraRecorderFormat.Mp4
                }),
                events: META.events<CameraElement>(CameraElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<CameraElement>(CameraElement.is, {
                    dir: FlowDirection.Auto,
                    width: '200px',
                    height: '300px',
                    autoPlay: true,
                    poster: '',
                    autoFullScreen: false,
                    captureFormat: CameraCaptureFormat.Jpeg,
                    type: CameraType.Front,
                    recorderFormat: CameraRecorderFormat.Mp4
                }), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'src',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<CameraElement>>, (a, b) => a.key === b.key),
                exludedProperties: ['isRecorderSupported', 'isSupported']
            },
            docs: [
                cameraElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-camera-sample': CameraSampleElement;
    }
}
