// #region Imports

import { Component, FormController, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IUserResponse } from '../../../Backend/Api/Models/IUserResponse';
import { AuthService } from '../../../Backend/Api/Services/AuthService';
import { SessionManager } from '../../../Services/SessionManager';
import { ViewBase } from '../../../Views/Abstracts/ViewBase';
import { deleteProfileViewStyle } from './DeleteProfileViewStyle';
import { deleteProfileViewTemplate } from './DeleteProfileViewTemplate';

// #endregion

/**
 * Represents the `IDeleteProfileViewData` interface.
 *
 * @public
 */
export interface IDeleteProfileViewData {

    // #region Properties

    user: IUserResponse | null;

    // #endregion

}

/**
 * The `{@link DeleteProfileView}` view.
 *
 * @public
 */
@Component({
    selector: 'app-delete-profile-view',
    template: deleteProfileViewTemplate,
    styles: deleteProfileViewStyle,
    imports: []
})
export class DeleteProfileView
    extends ViewBase {

    // #region Fields

    @Inject(AuthService)
    private readonly _authService!: AuthService;
    @Inject(SessionManager)
    private readonly _sessionManager!: SessionManager;
    private readonly _formController: FormController;
    private _user: IUserResponse | null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor(data?: IDeleteProfileViewData) {
        super();

        this._user = data?.user ?? null;
        this._formController = new FormController(this, {
            queries: {
                delete: '[name="delete"]'
            }
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-delete-profile-view';
    }

    /**
     * Gets or sets the `user` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    public get user(): IUserResponse | null {
        return this._user;
    }
    private set user(value: IUserResponse) {
        if (this._user !== value) {
            this._user = value;
            this.requestUpdate('user');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     * @template
     */
    public onDelete(): void {
        const formFacade = this._formController.get('delete');

        if (formFacade?.reportValidity()) {
            this.isBusy = true;

            void this._authService
                .delete({
                    authorization: this._sessionManager.session
                })
                .then(() => {
                    this._sessionManager.clear();
                    window.location.href = '/';
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }

    // #endregion

}
