// #region Imports

import { Component, Inject, Property, ToastServiceLocator, Variant } from '@breadstone/mosaik-elements-foundation';
import { ApiError } from '../../../Backend/Api/ApiError';
import { IPricesResponse } from '../../../Backend/Api/Index';
import { PaymentService } from '../../../Backend/Api/Services/PaymentService';
import { ViewBase } from '../../Abstracts/ViewBase';
import { pricingViewStyle } from './PricingViewStyle';
import { pricingViewTemplate } from './PricingViewTemplate';

// #endregion

/**
 * The `{@link PricingView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-pricing-view',
    template: pricingViewTemplate,
    styles: pricingViewStyle,
    providers: [
        {
            provide: PaymentService,
            useClass: PaymentService
        }
    ]
})
export class PricingView
    extends ViewBase {

    // #region Fields

    @Inject(PaymentService)
    private readonly _paymentService!: PaymentService;
    private _isDetailed: boolean;
    private _prices: Array<IPricesResponse>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._isDetailed = false;
        this._prices = [];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-pricing-view';
    }

    /**
     * Gets or sets the `isDetailed` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isDetailed(): boolean {
        return this._isDetailed;
    }

    public set isDetailed(value: boolean) {
        if (this._isDetailed !== value) {
            this._isDetailed = value;
            this.requestUpdate('isDetailed');
        }
    }

    /**
     * Gets or sets the `prices` property.
     *
     * @public
     */
    @Property({ type: Array<IPricesResponse> })
    public get prices(): Array<IPricesResponse> {
        return this._prices;
    }

    public set prices(value: Array<IPricesResponse>) {
        if (this._prices !== value) {
            this._prices = value;
            this.requestUpdate('prices');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();
    }

    /**
     * @protected
     * @template
     */
    public onToggleDetails(): void {
        this._isDetailed = !this._isDetailed;
    }

    /**
     * @protected
     * @template
     */
    public onSelect(priceId: string): void {
        this._prices.find((x) => x.id === priceId);

        void this._paymentService
            .checkout({
                body: {
                    priceId: priceId,
                    successUrl: `${window.location.origin}/success`,
                    cancelUrl: `${window.location.origin}/cancel`
                }
            })
            .then((x) => {
                console.log(x);
            })
            .catch((error: ApiError) => {
                void ToastServiceLocator.current.open({
                    header: error.message,
                    variant: Variant.Danger
                });
            });
    }

    private initialize(): void {
        void this._paymentService.getPrices().then((x) => {
            this.prices = x;
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-landing-pricing-view': PricingView;
    }
}
