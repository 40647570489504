// #region Imports

import { on, type IEventListenerSubscription } from '@breadstone/mosaik-elements-foundation';
import { AsyncDirectiveBase, type AsyncDirectiveArgs } from '@breadstone/mosaik-elements-foundation/dist/Controls/Directives/Abstracts/AsyncDirectiveBase';
import { noChange } from 'lit';
import { PartType, directive, type ElementPart } from 'lit/directive.js';

// #endregion

/**
 * The `MouseMovementChildDirective` class.
 *
 * @public
 */
export class MouseMovementChildDirective extends AsyncDirectiveBase {

    // #region Fields

    private readonly _itemPosition: {
        x: number;
        y: number;
    };

    private _offsetX: number;
    private _offsetY: number;
    private _mouseMovementChangeSubscription: IEventListenerSubscription | null;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `MouseMovementChildDirective` class.
     *
     * @public
     */
    public constructor(args: AsyncDirectiveArgs) {
        super(args);

        if (args.type !== PartType.ELEMENT) {
            throw new Error('The `mouseMovementChild` directive must be used in attribute position.');
        }

        this._itemPosition = {
            x: 0,
            y: 0
        };
        this._offsetX = 0.003;
        this._offsetY = 0.003;
        this._mouseMovementChangeSubscription = null;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public render(offsetX?: number, offsetY?: number): unknown {
        if (offsetX) {
            this._offsetX = offsetX;
        }

        if (offsetY) {
            this._offsetY = offsetY;
        }

        return noChange;
    }

    /**
     * @public
     */
    public override update(part: ElementPart, [options]: Parameters<this['render']>): unknown {
        // IMPORTANT: we need the window object here.
        this._mouseMovementChangeSubscription = on(window, 'mousemovementchange', (event) => this.onMouseMovementChange(event));

        return this.render(options);
    }

    /**
     * @protected
     * @override
     */
    protected override disconnected(): void {
        this._mouseMovementChangeSubscription?.dispose();
        super.disconnected();
    }

    /**
     * @private
     */
    private onMouseMovementChange(event: CustomEvent): void {
        if (this._itemPosition.x && this._itemPosition.y) {
            const pos = this._itemPosition as {
                x: number;
                y: number;
            };

            pos.x = event.detail.x * this._offsetX;
            pos.y = event.detail.y * this._offsetY;

            this.internals.element.style.transform = `translate(${pos.x}%, ${pos.y}%)`;
        } else {
            const newPoint = {
                x: event.detail.x * this._offsetX,
                y: event.detail.y * this._offsetY
            };
            this._itemPosition.x = newPoint.x;
            this._itemPosition.y = newPoint.y;

            this.internals.element.style.transform = `translate(${newPoint.x}%, ${newPoint.y}%)`;
        }
    }

    // #endregion

}

export const mouseMovementChild = directive(MouseMovementChildDirective as any) as (...values: Parameters<MouseMovementChildDirective['render']>) => any;
