// #region Imports

import { Appearanceable, Attribute, Component, CustomElement, Emit, HTMLElementEventEmitter, OverflowController, Property, Watch, type IAppearanceableProps, type IEventEmitter } from '@breadstone/mosaik-elements-foundation';
import type { IViewPortChangedEvent, IViewPortChangedEventDetail } from './Events/IViewPortChangedEvent';
import type { IViewPort } from './IViewPort';
import { viewPortElementStyle } from './ViewPortElementStyle';
import { viewPortElementTemplate } from './ViewPortElementTemplate';
import { VIEWPORTS } from './ViewPorts';

// #endregion

/**
 * Represents the `{@link IView-portElementProps}` interface.
 *
 * @public
 */
export interface IViewPortElementProps extends IAppearanceableProps {

    zoom: number;

    viewPort: IViewPort;

    swap: boolean;

    visualize: boolean;

    maximized: boolean;

    sizeTracker: boolean;

    resizeable: boolean;

}

/**
 * The `{@link ViewPortElement}` element.
 *
 * @Fires zoomChanged - Called when the zoom property changes.
 * @fires viewPortChanged - Called when any related view port property changes.
 * @fires swapChanged - Called when the swap property changes.
 * @fires visualizeChanged - Called when the visualize property changes.
 * @fires maximizedChanged - Called when the maximized property changes.
 * @fires sizeTrackerChanged - Called when the sizeTracker property changes.
 *
 * @public
 */
@Component({
    selector: 'app-view-port',
    template: viewPortElementTemplate,
    styles: viewPortElementStyle
})
export class ViewPortElement extends Appearanceable(CustomElement) implements IViewPortElementProps {

    // #region Fields

    private readonly _overflowController: OverflowController;
    private readonly _viewPortChanged: IEventEmitter<IViewPortChangedEventDetail>;
    private readonly _zoomChanged: IEventEmitter<number>;
    private readonly _swapChanged: IEventEmitter<boolean>;
    private readonly _visualizeChanged: IEventEmitter<boolean>;
    private readonly _maximizedChanged: IEventEmitter<boolean>;
    private readonly _sizeTrackerChanged: IEventEmitter<boolean>;
    private readonly _resizeableChanged: IEventEmitter<boolean>;
    private _zoom: number;
    private _viewPort: IViewPort;
    private _swap: boolean;
    private _visualize: boolean;
    private _maximized: boolean;
    private _sizeTracker: boolean;
    private _hasOverflow: boolean;
    private _resizeable: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._zoom = 1;
        this._viewPort = VIEWPORTS[0];
        this._swap = false;
        this._visualize = false;
        this._maximized = false;
        this._sizeTracker = false;
        this._hasOverflow = false;
        this._resizeable = false;
        this._viewPortChanged = new HTMLElementEventEmitter<IViewPortChangedEventDetail>(this, 'viewPortChanged');
        this._zoomChanged = new HTMLElementEventEmitter<number>(this, 'zoomChanged');
        this._swapChanged = new HTMLElementEventEmitter<boolean>(this, 'swapChanged');
        this._visualizeChanged = new HTMLElementEventEmitter<boolean>(this, 'visualizeChanged');
        this._maximizedChanged = new HTMLElementEventEmitter<boolean>(this, 'maximizedChanged');
        this._sizeTrackerChanged = new HTMLElementEventEmitter<boolean>(this, 'sizeTrackerChanged');
        this._resizeableChanged = new HTMLElementEventEmitter<boolean>(this, 'resizeableChanged');
        this._overflowController = new OverflowController(this, {
            target: 'viewPort',
            callback: (overflow: boolean) => this.hasOverflow = overflow
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-view-port';
    }

    /**
     * Gets or sets the `zoom` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get zoom(): number {
        return this._zoom;
    }
    public set zoom(value: number) {
        if (this._zoom !== value) {
            this._zoom = value;
            this.requestUpdate('zoom');
        }
    }

    /**
     * Gets or sets the `viewPort` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get viewPort(): IViewPort {
        return this._viewPort;
    }
    public set viewPort(value: IViewPort) {
        if (this._viewPort !== value) {
            this._viewPort = value;
            this.requestUpdate('viewPort');
        }
    }

    /**
     * Gets or sets the `swap` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get swap(): boolean {
        return this._swap;
    }
    public set swap(value: boolean) {
        if (this._swap !== value) {
            this._swap = value;
            this.requestUpdate('swap');
        }
    }

    /**
     * Gets or sets the `visualize` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get visualize(): boolean {
        return this._visualize;
    }
    public set visualize(value: boolean) {
        if (this._visualize !== value) {
            this._visualize = value;
            this.requestUpdate('visualize');
        }
    }

    /**
     * Gets or sets the `maximized` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get maximized(): boolean {
        return this._maximized;
    }
    public set maximized(value: boolean) {
        if (this._maximized !== value) {
            this._maximized = value;
            this.requestUpdate('maximized');
        }
    }

    /**
     * Gets or sets the `sizeTracker` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get sizeTracker(): boolean {
        return this._sizeTracker;
    }
    public set sizeTracker(value: boolean) {
        if (this._sizeTracker !== value) {
            this._sizeTracker = value;
            this.requestUpdate('sizeTracker');
        }
    }

    /**
     * Gets or sets the `resizeable` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get resizeable(): boolean {
        return this._resizeable;
    }
    public set resizeable(value: boolean) {
        if (this._resizeable !== value) {
            this._resizeable = value;
            this.requestUpdate('resizeable');
        }
    }

    /**
     * Gets or sets the `hasOverflow` property.
     *
     * @public
     * @readonly
     * @attr
     */
    @Attribute({ type: Boolean })
    public get hasOverflow(): boolean {
        return this._hasOverflow;
    }
    private set hasOverflow(value: boolean) {
        if (this._hasOverflow !== value) {
            this._hasOverflow = value;
            this.requestUpdate('hasOverflow');
        }
    }

    /**
     * Called when any related view port property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'viewPortChanged' })
    public get viewPortChanged(): IEventEmitter<IViewPortChangedEventDetail> {
        return this._viewPortChanged;
    }

    /**
     * Called when the zoom property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'zoomChanged' })
    public get zoomChanged(): IEventEmitter<number> {
        return this._zoomChanged;
    }

    /**
     * Called when the swap property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'swapChanged' })
    public get swapChanged(): IEventEmitter<boolean> {
        return this._swapChanged;
    }

    /**
     * Called when the visualize property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'visualizeChanged' })
    public get visualizeChanged(): IEventEmitter<boolean> {
        return this._visualizeChanged;
    }

    /**
     * Called when the maximized property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'maximizedChanged' })
    public get maximizedChanged(): IEventEmitter<boolean> {
        return this._maximizedChanged;
    }

    /**
     * Called when the sizeTracker property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'sizeTrackerChanged' })
    public get sizeTrackerChanged(): IEventEmitter<boolean> {
        return this._sizeTrackerChanged;
    }

    /**
     * Called when the resizeable property changes.
     * Provides reference to `{@link IEventDetail}` as event detail.
     *
     * @public
     * @readonly
     * @eventProperty
     */
    @Emit({ eventName: 'resizeableChanged' })
    public get resizeableChanged(): IEventEmitter<boolean> {
        return this._resizeableChanged;
    }

    // #endregion

    // #region Methods

    /**
     * Emitts the {@link viewPortChanged} event.
     *
     * @protected
     */
    protected onViewPortChanged(args: IViewPortChangedEventDetail): void {
        this._viewPortChanged.emit(args);
    }

    /**
     * Emitts the {@link zoomChanged} event.
     *
     * @protected
     */
    protected onZoomChanged(value: number): void {
        this._zoomChanged.emit(value);
    }

    /**
     * Emitts the {@link swapChanged} event.
     *
     * @protected
     */
    protected onSwapChanged(value: boolean): void {
        this._swapChanged.emit(value);
    }

    /**
     * Emitts the {@link visualizeChanged} event.
     *
     * @protected
     */
    protected onVisualizeChanged(value: boolean): void {
        this._visualizeChanged.emit(value);
    }

    /**
     * Emitts the {@link maximizedChanged} event.
     *
     * @protected
     */
    protected onMaximizedChanged(value: boolean): void {
        this._maximizedChanged.emit(value);
    }

    /**
     * Emitts the {@link sizeTrackerChanged} event.
     *
     * @protected
     */
    protected onSizeTrackerChanged(value: boolean): void {
        this._sizeTrackerChanged.emit(value);
    }

    /**
     * Emitts the {@link resizeableChanged} event.
     *
     * @protected
     */
    protected onResizeableChanged(value: boolean): void {
        this._resizeableChanged.emit(value);
    }

    /**
     * @protected
     */
    @Watch('viewPort')
    protected onViewPortPropertyChanged(prev?: IViewPort, next?: IViewPort): void {
        if (prev !== next && next) {
            this.onViewPortChanged({
                viewPort: next,
                zoom: this.zoom,
                visualize: this.visualize,
                maximized: this.maximized,
                sizeTracker: this.sizeTracker,
                swap: this.swap
            });
        }
    }

    /**
     * @protected
     */
    @Watch('zoom')
    protected onZoomPropertyChanged(prev?: number, next?: number): void {
        if (prev !== next && next !== undefined) {
            this.onZoomChanged(next);
        }
    }

    /**
     * @protected
     */
    @Watch('swap')
    protected onSwapPropertyChanged(prev?: boolean, next?: boolean): void {
        if (prev !== next) {
            this.onSwapChanged(next ?? false);
        }
    }

    /**
     * @protected
     */
    @Watch('visualize')
    protected onVisualizePropertyChanged(prev?: boolean, next?: boolean): void {
        if (prev !== next) {
            this.onVisualizeChanged(next ?? false);
        }
    }

    /**
     * @protected
     */
    @Watch('maximized')
    protected onMaximizedPropertyChanged(prev?: boolean, next?: boolean): void {
        if (prev !== next) {
            this.onMaximizedChanged(next ?? false);
        }
    }

    /**
     * @protected
     */
    @Watch('sizeTracker')
    protected onSizeTrackerPropertyChanged(prev?: boolean, next?: boolean): void {
        if (prev !== next) {
            this.onSizeTrackerChanged(next ?? false);
        }
    }

    /**
     * @protected
     */
    @Watch('resizeable')
    protected onResizeablePropertyChanged(prev?: boolean, next?: boolean): void {
        if (prev !== next) {
            this.onResizeableChanged(next ?? false);
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-view-port': ViewPortElement;
    }

    interface HTMLElementEventMap {

        viewPortChanged: IViewPortChangedEvent;

        zoomChanged: CustomEvent<number>;

        swapChanged: CustomEvent<boolean>;

        visualizeChanged: CustomEvent<boolean>;

        maximizedChanged: CustomEvent<boolean>;

        sizeTrackerChanged: CustomEvent<boolean>;

        resizeableChanged: CustomEvent<boolean>;
    }
}
