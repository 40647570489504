// #region Imports

import { Injectable } from '@breadstone/mosaik-elements-foundation';
import { AngularHtmlGenerator } from './Generators/AngularHtmlCodeGenerator';
import { ICodeGenerator, ICodeModel } from './Generators/Interfaces/ICodeGenerator';
import { NativeHtmlGenerator } from './Generators/NativeHtmlCodeGenerator';
import { ReactHtmlGenerator } from './Generators/ReactHtmlCodeGenerator';
import { VueHtmlGenerator } from './Generators/VueHtmlCodeGenerator';

// #endregion

/**
 * The `CodeGenerator` class.
 *
 * @public
 */
@Injectable()
export class CodeGenerator {

    // #region Fields

    private readonly _generators: Map<string, ICodeGenerator>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CodeGenerator` class.
     *
     * @public
     */
    public constructor() {
        this._generators = new Map<string, ICodeGenerator>();
        this._generators.set('html', new NativeHtmlGenerator());
        this._generators.set('angular', new AngularHtmlGenerator());
        this._generators.set('react', new ReactHtmlGenerator());
        this._generators.set('vue', new VueHtmlGenerator());
        // this._generators.set('yaml', new YamlGenerator());
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `generators` property.
     *
     * @public
     * @readonly
     */
    public get generators(): Array<string> {
        return Array.from(this._generators.keys());
    }

    // #endregion

    // #region Methods

    /**
     * Generates code for the given component properties.
     *
     * @param componentName - The name of the component.
     * @param properties - The properties of the component.
     * @param format - The format of the serialization.
     * @returns The serialized value.
     *
     * @public
     */
    public process(model: ICodeModel, format: string): string {
        const generator = this._generators.get(format);

        if (generator) {
            try {
                return generator.process(model);
            } catch (error) {
                return '';
            }
        }

        throw new Error(`The specified format '${format}' is not supported.`);
    }

    // #endregion

}
