// #region Imports

import { Command, ICommand, inject, Injectable, IThemeScheme, Signal, ThemeServiceLocator, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, ViewPresenter } from '@breadstone/mosaik-elements-foundation';
import { generateAnalogousPalette } from '@breadstone/mosaik-elements-foundation/src/Theming/Utils.Analogous';
import { generateComplementaryPalette } from '@breadstone/mosaik-elements-foundation/src/Theming/Utils.Complementary';
import { generateMaterialPalette } from '@breadstone/mosaik-elements-foundation/src/Theming/Utils.Material.2014.2';
import { generateTriadicPalette } from '@breadstone/mosaik-elements-foundation/src/Theming/Utils.Triadic';
import { ThemeManager } from '../../Services/ThemeManager';

// #endregion

/**
 * The `ThemeGeneratorViewPresenter` class.
 *
 * @public
 */
@Injectable()
export class ThemeGeneratorViewPresenter
    extends ViewPresenter {

    // #region Fields

    private readonly _themeManager: ThemeManager;
    private readonly _baseColor: Signal<string>;
    private readonly _baseScheme: Signal<string>;
    private readonly _schemeKeys: Signal<Array<keyof IThemeScheme>>;
    private readonly _schemeValues: Signal<Array<string>>;
    private readonly _accentKeys: Signal<Array<string>>;
    private readonly _accentValues: Signal<Array<string>>;
    private readonly _functionalKeys: Signal<Array<string>>;
    private readonly _functionalValues: Signal<Array<string>>;
    private readonly _complementaryPalette: Signal<Record<string, string>>;
    private readonly _analogous1Palette: Signal<Record<string, string>>;
    private readonly _analogous2Palette: Signal<Record<string, string>>;
    private readonly _triadic1Palette: Signal<Record<string, string>>;
    private readonly _triadic2Palette: Signal<Record<string, string>>;
    private readonly _changeColorCommand: Command<string>;
    private readonly _generateThemeCommand: Command;
    private readonly _copyColorCommand: Command<string>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ThemeGeneratorViewPresenter` class.
     *
     * @public
     */
    public constructor() {
        super();
        this._themeManager = inject(ThemeManager);

        const scheme = ThemeServiceLocator.current.getScheme();
        const primary = ThemeServiceLocator.current.getPalette('primary');
        const secondary = ThemeServiceLocator.current.getPalette('secondary');
        const tertiary = ThemeServiceLocator.current.getPalette('tertiary');
        const info = ThemeServiceLocator.current.getPalette('info');
        const success = ThemeServiceLocator.current.getPalette('success');
        const warning = ThemeServiceLocator.current.getPalette('warning');
        const danger = ThemeServiceLocator.current.getPalette('danger');
        const highlight = ThemeServiceLocator.current.getPalette('highlight');
        const gray = ThemeServiceLocator.current.getPalette('gray');

        this._baseColor = Signal.from(primary ? primary[500] : '');
        this._baseScheme = Signal.from(scheme ? scheme.backgroundColor : '');
        this._schemeKeys = Signal.from(['backgroundColor', 'foregroundColor', 'highlightColor', 'middlelightColor', 'lowlightColor', 'selectionColor', 'disabledColor', 'semiTransparentColor', 'transparentColor']);
        this._schemeValues = scheme ? Signal.from([scheme.backgroundColor, scheme.foregroundColor, scheme.highlightColor, scheme.middlelightColor, scheme.lowlightColor, scheme.selectionColor, scheme.disabledColor, scheme.semiTransparentColor, scheme.transparentColor]) : Signal.from([]);
        this._accentKeys = Signal.from(['primary', 'secondary', 'tertiary']);
        this._accentValues = primary && secondary && tertiary ? Signal.from([primary[500], secondary[500], tertiary[500]]) : Signal.from([]);
        this._functionalKeys = Signal.from(['info', 'success', 'warning', 'danger', 'highlight', 'gray']);
        this._functionalValues = info && success && warning && danger && highlight && gray ? Signal.from([info[500], success[500], warning[500], danger[500], highlight[500], gray[500]]) : Signal.from([]);
        this._complementaryPalette = Signal.from(generateComplementaryPalette(this._baseColor.get()));
        this._analogous1Palette = Signal.from(generateAnalogousPalette(this._baseColor.get(), 1 / 9));
        this._analogous2Palette = Signal.from(generateAnalogousPalette(this._baseColor.get(), -1 / 9));
        this._triadic1Palette = Signal.from(generateTriadicPalette(this._baseColor.get(), 1 / 3));
        this._triadic2Palette = Signal.from(generateTriadicPalette(this._baseColor.get(), -1 / 3));
        this._changeColorCommand = new Command((x: string) => this.onExecuteChangeColorCommand(x));
        this._generateThemeCommand = new Command(() => this.onExecuteGenerateThemeCommand());
        this._copyColorCommand = new Command((x: string) => this.onExecuteCopyColorCommand(x));
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `color` property.
     *
     * @public
     * @readonly
     */
    public get color(): Signal<string> {
        return this._baseColor;
    }

    /**
     * Returns the `scheme` property.
     *
     * @public
     * @readonly
     */
    public get scheme(): Signal<string> {
        return this._baseScheme;
    }

    /**
     * Returns the `schemeKeys` property.
     *
     * @public
     * @readonly
     */
    public get schemeKeys(): Signal<Array<keyof IThemeScheme>> {
        return this._schemeKeys;
    }

    /**
     * Returns the `schemeValues` property.
     *
     * @public
     * @readonly
     */
    public get schemeValues(): Signal<Array<string>> {
        return this._schemeValues;
    }

    /**
     * Returns the `accentKeys` property.
     *
     * @public
     * @readonly
     */
    public get accentKeys(): Signal<Array<string>> {
        return this._accentKeys;
    }

    /**
     * Returns the `accentValues` property.
     *
     * @public
     * @readonly
     */
    public get accentValues(): Signal<Array<string>> {
        return this._accentValues;
    }

    /**
     * Returns the `functionalKeys` property.
     *
     * @public
     * @readonly
     */
    public get functionalKeys(): Signal<Array<string>> {
        return this._functionalKeys;
    }

    /**
     * Returns the `functionalValues` property.
     *
     * @public
     * @readonly
     */
    public get functionalValues(): Signal<Array<string>> {
        return this._functionalValues;
    }

    /**
     * Returns the `complementaryPalette` property.
     *
     * @public
     * @readonly
     */
    public get complementaryPalette(): Signal<Record<string, string>> {
        return this._complementaryPalette;
    }

    /**
     * Returns the `analogous1Palette` property.
     *
     * @public
     * @readonly
     */
    public get analogous1Palette(): Signal<Record<string, string>> {
        return this._analogous1Palette;
    }

    /**
     * Returns the `analogous2Palette` property.
     *
     * @public
     * @readonly
     */
    public get analogous2Palette(): Signal<Record<string, string>> {
        return this._analogous2Palette;
    }

    /**
     * Returns the `triadic1Palette` property.
     *
     * @public
     * @readonly
     */
    public get triadic1Palette(): Signal<Record<string, string>> {
        return this._triadic1Palette;
    }

    /**
     * Returns the `triadic2Palette` property.
     *
     * @public
     * @readonly
     */
    public get triadic2Palette(): Signal<Record<string, string>> {
        return this._triadic2Palette;
    }

    /**
     * Returns the `changeColorCommand` command property.
     *
     * @public
     * @readonly
     */
    public get changeColorCommand(): ICommand {
        return this._changeColorCommand;
    }

    /**
     * Returns the `generateThemeCommand` command property.
     *
     * @public
     * @readonly
     */
    public get generateThemeCommand(): ICommand {
        return this._generateThemeCommand;
    }

    /**
     * Returns the `copyColorCommand` command property.
     *
     * @public
     * @readonly
     */
    public get copyColorCommand(): ICommand {
        return this._copyColorCommand;
    }

    // #endregion

    // #region Methods

    /**
     * Executes the `changeColorCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteChangeColorCommand(parameter: string): void {
        this._baseColor.set(parameter);

        const materialPalette = generateMaterialPalette(parameter);
        const complementaryPalette = generateComplementaryPalette(parameter);
        const analogous1Palette = generateAnalogousPalette(parameter, 1 / 9);
        const analogous2Palette = generateAnalogousPalette(parameter, -1 / 9);
        const triadic1Palette = generateTriadicPalette(parameter, 1 / 3);
        const triadic2Palette = generateTriadicPalette(parameter, -1 / 3);

        this._complementaryPalette.set(complementaryPalette);
        this._analogous1Palette.set(analogous1Palette);
        this._analogous2Palette.set(analogous2Palette);
        this._triadic1Palette.set(triadic1Palette);
        this._triadic2Palette.set(triadic2Palette);

        // this.requestUpdate();
    }

    /**
     * Executes the `generateThemeCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteGenerateThemeCommand(): void {
        const theme = this._themeManager.generate();

        this._schemeValues.update(() => Object.values(theme.scheme));
        this._accentValues.update(() => [theme.primary, theme.secondary, theme.tertiary]);

        ThemeServiceLocator.current.applyScheme(theme.scheme);
        ThemeServiceLocator.current.applyPalette(theme.primary, 'primary', '500');
        ThemeServiceLocator.current.applyPalette(theme.secondary, 'secondary', '500');
        ThemeServiceLocator.current.applyPalette(theme.tertiary, 'tertiary', '500');
    }

    /**
     * Executes the `copyColorCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteCopyColorCommand(parameter: string): void {
        void ToastServiceLocator.current.open({
            content: TranslatorServiceLocator.current.translate('Color copied to clipboard 🎉.'),
            variant: Variant.Success,
            timeout: Toast2.Timeout.SHORT
        });
    }

    // #endregion

}
