//#region Imports

import { TemplateResult, html } from '@breadstone/mosaik-elements-foundation';

//#endregion

export namespace TileListSampleTemplates {

    export function defaultTemplate(): TemplateResult {
        return html`
            <mosaik-tile-list>
                <mosaik-tile-list-item .col="${0}"
                                       .colSpan="${2}"
                                       .row="${0}"
                                       .rowSpan="${2}"
                                       .title="${'Item 1'}"></mosaik-tile-list-item>
                <mosaik-tile-list-item .title="${'Item 2'}"></mosaik-tile-list-item>
                <mosaik-tile-list-item .title="${'Item 3'}"></mosaik-tile-list-item>
            </mosaik-tile-list>
        `;
    }

}