// #region Imports

import { Attribute, BreakpointAlias, BreakpointController, CSSResultGroup, IViewPresenter, View, ViewPresenter } from '@breadstone/mosaik-elements-foundation';
import { ISubscription, SubscriptionController } from '../../Controllers/SubscriptionController';
import { viewBaseStyle } from './ViewBaseStyle';

// #endregion

class ViewPresenterBase extends ViewPresenter {

    public constructor() {
        super();
    }

}

/**
 * A abstract view.
 *
 * @public
 * @static
 */
export abstract class ViewBase<TPresenter extends IViewPresenter = any>
    extends View<TPresenter> {

    // #region Fields

    private readonly _subscriptionController: SubscriptionController;
    private _isBusy: boolean;
    private _isMobile: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor(presenter?: TPresenter) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        super((presenter as IViewPresenter as TPresenter) ?? new ViewPresenterBase());

        this._isBusy = false;
        this._isMobile = false;

        // eslint-disable-next-line no-new
        new BreakpointController(this, {
            query: BreakpointAlias.LessThanMedium,
            callback: (match) => {
                this.isMobile = match;
            }
        });

        this._subscriptionController = new SubscriptionController(this);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @override
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return viewBaseStyle();
    }

    /**
     * Gets or sets the `isBusy` property.
     *
     * @public
     * @readonly
     * @attr
     */
    @Attribute({ type: Boolean })
    public get isBusy(): boolean {
        return this._isBusy;
    }

    protected set isBusy(value: boolean) {
        if (this._isBusy !== value) {
            this._isBusy = value;
            this.requestUpdate('isBusy');
        }
    }

    /**
     * Gets or sets the `isMobile` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get isMobile(): boolean {
        return this._isMobile;
    }

    public set isMobile(value: boolean) {
        if (this._isMobile !== value) {
            this._isMobile = value;
            this.requestUpdate('isMobile');
        }
    }

    // #endregion

    // #region Methods

    public addSubscription(sub: ISubscription): void {
        this._subscriptionController.addSubscription(sub);
    }

    // #endregion

}
