// #region Imports

import { Appearance, type BannerElement, type TemplateResult, Variant, html } from '@breadstone/mosaik-elements-foundation';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';

// #endregion

export namespace BannerSampleTemplates {

    export function defaultTemplate(props: { get: <T = unknown>(key: keyof BannerElement) => IPlaygroundProperty<BannerElement, T> }): TemplateResult {

        return html`
            <mosaik-banner></mosaik-banner>
        `;

    }

    export function withButtonTemplate(props: { get: <T = unknown>(key: keyof BannerElement) => IPlaygroundProperty<BannerElement, T> }): TemplateResult {

        return html`
            <mosaik-banner>
                <mosaik-button slot="actions"
                               .label="${'Yes, turn on'}"
                               .appearance="${Appearance.Soft}"
                               .variant="${Variant.Info}"></mosaik-button>
            </mosaik-banner>
        `;

    }

    export function withCustomContentTemplate(props: { get: <T = unknown>(key: keyof BannerElement) => IPlaygroundProperty<BannerElement, T> }): TemplateResult {

        return html`
            <mosaik-banner>
                <mosaik-banner-header .text="${props.get('header').value?.toString() ?? ''}"></mosaik-banner-header>
                <mosaik-banner-sub-header .text="${props.get('subHeader').value?.toString() ?? ''}"></mosaik-banner-sub-header>
            </mosaik-banner>
        `;

    }

}
