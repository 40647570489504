// #region Imports

import { Attribute, Component, Inject, Property, Query, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, type FormElement } from '@breadstone/mosaik-elements-foundation';
import { NewsletterService } from '../../../Backend/Api/Services/NewsletterService';
import type { ICaptchaChangedEventDetail } from '../../../Components/Captcha/Events/ICaptchaChangedEventDetail';
import { ViewBase } from '../../Abstracts/ViewBase';
import { newsletterViewStyle } from './NewsletterViewStyle';
import { newsletterViewTemplate } from './NewsletterViewTemplate';

// #endregion

/**
 * The `{@link NewsletterView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-newsletter-view',
    template: newsletterViewTemplate,
    styles: newsletterViewStyle,
    providers: [
        {
            provide: NewsletterService,
            useClass: NewsletterService
        }
    ]
})
export class NewsletterView extends ViewBase {

    // #region Fields

    @Inject(NewsletterService)
    private readonly _newsletterService!: NewsletterService;
    @Query('[part="form"]')
    private readonly _formElement!: FormElement;
    private _state: 'subscribe' | 'validate' | 'busy' | 'done';
    private _email: string;
    private _kind: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._state = 'subscribe';
        this._email = '';
        this._kind = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-newsletter-view';
    }

    /**
     * Gets or sets the `state` property.
     *
     * @public
     * @readonly
     * @attr
     */
    @Attribute({ type: String })
    public get state(): 'subscribe' | 'validate' | 'busy' | 'done' {
        return this._state;
    }

    private set state(value: 'subscribe' | 'validate' | 'busy' | 'done') {
        if (this._state !== value) {
            this._state = value;
            this.requestUpdate('state');
        }
    }

    /**
     * Gets or sets the `kind` property.
     *
     * @public
     */
    @Property({ type: String })
    public get kind(): string {
        return this._kind;
    }

    public set kind(value: string) {
        if (this._kind !== value) {
            this._kind = value;
            this.requestUpdate('kind');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @template
     */
    public onSubscribe(value: string): void {
        const isValid = this._formElement.reportValidity();

        if (isValid) {
            this._email = value;
            this.state = 'validate';
        }
    }

    /**
     * @protected
     * @template
     */
    public async onCaptchaChanged(detail: ICaptchaChangedEventDetail): Promise<void> {
        if (detail.valid) {
            this.state = 'busy';
            const response = await this._newsletterService.subscribe({
                email: this._email
            });

            if (response.success) {
                this.state = 'done';
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.landing.newsletter.success'),
                    variant: Variant.Success,
                    timeout: Toast2.Timeout.LONG
                });
            } else {
                this.state = 'subscribe';
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.landing.newsletter.error'),
                    content: response.error,
                    variant: Variant.Danger,
                    timeout: Toast2.Timeout.LONG
                });
            }
        } else {
            void ToastServiceLocator.current.open({
                header: TranslatorServiceLocator.current.translate('loc.global.error.captcha'),
                variant: Variant.Danger
            });
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-landing-newsletter-view': NewsletterView;
    }
}
