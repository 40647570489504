// #region Imports

import { AnimationPresets, Appearance, Inset, Placement, Portal, SelectionMode, Size, Spacing, TemplateServiceLocator, TextFormatters, Variant, html, ifDefined, type TemplateResult, type TextFormatter } from '@breadstone/mosaik-elements-foundation';
import { CodeEditorLanguages } from '../CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../CodeEditor/CodeEditorTheme';
import type { IPlaygroundPropertyTemplateContext } from './IPlaygroundProperty';

// #endregion

export function iconSizeTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-select .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @selectionChanged="${(e: any) => c.apply(e.target.value)}">
            <mosaik-select-item .value="${Size.Tiny}" .label="${'Tiny'}"></mosaik-select-item>
            <mosaik-select-item .value="${Size.Small}" .label="${'Small'}"></mosaik-select-item>
            <mosaik-select-item .value="${Size.Medium}" .label="${'Medium'}"></mosaik-select-item>
            <mosaik-select-item .value="${Size.Large}" .label="${'Large'}"></mosaik-select-item>
            <mosaik-select-item .value="${Size.Giant}" .label="${'Giant'}"></mosaik-select-item>
        </mosaik-select>
    `;
}

export function formatterTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    const getFormatter = (v: string): TextFormatter => {
        switch (v) {
            case 'default':
                return TextFormatters.DEFAULT;
            case 'bbcode':
                return TextFormatters.BBCODE;
            case 'richtext':
                return TextFormatters.RICHTEXT;
            case 'markdown':
                return TextFormatters.MARKDOWN;
            default:
                return TextFormatters.DEFAULT;
        }
    };

    return html`
        <mosaik-select .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @selectionChanged="${(e: any) => c.apply(getFormatter(e.target.value))}">
            <mosaik-select-item .value="${'default'}" .label="${'Default'}" .isSelected="${true}"></mosaik-select-item>
            <mosaik-select-item .value="${'bbcode'}" .label="${'BBcode'}"></mosaik-select-item>
            <mosaik-select-item .value="${'richtext'}" .label="${'Richtext'}"></mosaik-select-item>
            <mosaik-select-item .value="${'markdown'}" .label="${'Markdown'}"></mosaik-select-item>
        </mosaik-select>
    `;
}

export function valueTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-textbox .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @input="${(e: any) => c.apply(e.target.value)}"></mosaik-textbox>
    `;
}

export function multilineTextTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-textbox .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .multiline="${true}"
                        .isClearable="${true}"
                        @input="${(e: any) => c.apply(e.target.value)}"></mosaik-textbox>
    `;
}

export function numberTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-numberbox .value="${c.value}"
                          .variant="${Variant.Primary}"
                          .isClearable="${true}"
                          @input="${(e: any) => c.apply(e.target.value)}"></mosaik-numberbox>
    `;
}

export function percentTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-numberbox .value="${c.value}"
                          .variant="${Variant.Primary}"
                          .isClearable="${true}"
                          .min="${0}"
                          .max="${1}"
                          .step="${0.1}"
                          .decimalPlaces="${1}"
                          @input="${(e: any) => c.apply(e.target.value)}"></mosaik-numberbox>
    `;
}

export function colorTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-colorbox .value="${c.value}"
                          .variant="${Variant.Primary}"
                          .isClearable="${true}"
                          @input="${(e: any) => c.apply(e.target.value)}"></mosaik-colorbox>
    `;
}

export function cssLengtTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-textbox .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @input="${(e: any) => c.apply(e.target.value)}"></mosaik-textbox>
    `;
}

export function insetTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-combo .value="${c.value}"
                      .variant="${Variant.Primary}"
                      .selectionMode="${SelectionMode.Multiple}"
                      .isClearable="${true}"
                      @selectionChanged="${(e: any) => c.apply(e.target.value)}">
            <mosaik-combo-item .value="${Inset.None}"
                               .label="${'None'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.All}"
                               .label="${'All'}"
                               .isSelected="${true}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Bottom}"
                               .label="${'Bottom'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Horizontal}"
                               .label="${'Horizontal'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Left}"
                               .label="${'Left'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Right}"
                               .label="${'Right'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Top}"
                               .label="${'Top'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Inset.Vertical}"
                               .label="${'Vertical'}"></mosaik-combo-item>
        </mosaik-combo>
    `;
}

export function spacingTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-combo .value="${c.value}"
                      .variant="${Variant.Primary}"
                      .selectionMode="${SelectionMode.Multiple}"
                      .isClearable="${true}"
                      @selectionChanged="${(e: any) => c.apply(e.target.value)}">
            <mosaik-combo-item .value="${Spacing.All}"
                               .label="${'All'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.None}"
                               .label="${'None'}"
                               .isSelected="${true}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Bottom}"
                               .label="${'Bottom'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Horizontal}"
                               .label="${'Horizontal'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Left}"
                               .label="${'Left'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Right}"
                               .label="${'Right'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Top}"
                               .label="${'Top'}"></mosaik-combo-item>
            <mosaik-combo-item .value="${Spacing.Vertical}"
                               .label="${'Vertical'}"></mosaik-combo-item>
        </mosaik-combo>
    `;
}

export function nullTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-textbox .readonly="${true}"
                        .value="${'none'}"
                        .variant="${Variant.Primary}"></mosaik-textbox>
    `;
}

export function arrayTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    // format dates
    c.value = c.value?.map((x: unknown) => {
        // check if the value is a date
        if (x instanceof Date) {
            return x.toLocaleDateString();
        }

        return x;
    });

    return html`
        <mosaik-chipbox .chips="${c.value}"
                        .isEditable="${true}"
                        .placeholder="${'Add...'}"
                        @chipAdded="${(e: any) => c.apply(e.target.chips)}"
                        @chipRemoved="${(e: any) => c.apply(e.target.chips)}"></mosaik-chipbox>
    `;
}

export function jsonTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    // create a random id for the floating trigger
    const id = Math.random().toString(36)
        .substring(7);

    return html`
        <mosaik-floating-trigger>
            <mosaik-button id="${id}"
                           .variant="${Variant.Primary}"
                           .icon="${'M12 10c4.418 0 8-1.79 8-4s-3.582-4-8-4-8 1.79-8 4 3.582 4 8 4Zm6.328.17A7.61 7.61 0 0 0 20 9.053V18c0 2.21-3.582 4-8 4s-8-1.79-8-4V9.053a7.61 7.61 0 0 0 1.672 1.117C7.37 11.018 9.608 11.5 12 11.5c2.392 0 4.63-.482 6.328-1.33Z'}"></mosaik-button>
            <mosaik-floating slot="floating"
                             style="display: block; z-index: 1;"
                             .anchor="${id}"
                             .strategy="${'fixed'}"
                             .sync="${'width'}"
                             .distance="${8}"
                             .placement="${Placement.Bottom}"
                             .flipFallbackPlacements="${[Placement.Top, Placement.Bottom]}">
                <app-code-editor .text="${JSON.stringify(c.value, null, 4)}"
                                 .language="${CodeEditorLanguages.JSON}"
                                 .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                 .resize="${'vertical'}"
                                 @input="${(e: any) => c.apply(JSON.parse(e.target.text))}"></app-code-editor>
            </mosaik-floating>
        </mosaik-floating-trigger>
    `;
}

export function functionTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    // create a random id for the floating trigger
    const id = Math.random().toString(36)
        .substring(7);

    return html`
        <mosaik-floating-trigger>
            <mosaik-button id="${id}"
                           .variant="${Variant.Primary}"
                           .icon="${'M12.187 4.14c-.964-.723-2.345-.07-2.398 1.134L9.712 7H12a1 1 0 1 1 0 2H9.623l-.393 8.85c-.13 2.931-3.593 4.41-5.801 2.479l-.087-.076a1 1 0 0 1 1.317-1.506l.087.076c.946.829 2.43.194 2.486-1.062L7.622 9H6a1 1 0 0 1 0-2h1.71l.08-1.815c.126-2.81 3.347-4.332 5.597-2.645l.213.16a1 1 0 1 1-1.2 1.6l-.213-.16Z M13.082 13.046a.5.5 0 0 1 .693.225l.813 1.727-3.295 3.295a1 1 0 0 0 1.414 1.414l2.786-2.786.78 1.657a2.5 2.5 0 0 0 3.853.864l.51-.42a1 1 0 1 0-1.272-1.543l-.51.42a.5.5 0 0 1-.771-.172l-1.087-2.309 2.711-2.711a1 1 0 1 0-1.414-1.414l-2.202 2.202-.506-1.075a2.5 2.5 0 0 0-3.467-1.126l-.6.33a1 1 0 0 0 .964 1.752l.6-.33Z'}"></mosaik-button>
            <mosaik-floating slot="floating"
                             style="display: block; z-index: 1;"
                             .anchor="${id}"
                             .strategy="${'fixed'}"
                             .sync="${'width'}"
                             .distance="${8}"
                             .placement="${Placement.Bottom}"
                             .flipFallbackPlacements="${[Placement.Top, Placement.Bottom]}">
                <mosaik-textbox .value="${JSON.stringify(c.value, null, 4)}"
                                .variant="${Variant.Primary}"
                                .multiline="${true}"
                                .resize="${'vertical'}"
                                .isClearable="${true}"
                                .readonly="${true}"></mosaik-textbox>
               <mosaik-chip .label="${'readonly'}"
                            .appearance="${Appearance.Solid}"
                            style="position: absolute; bottom: 4px;right: 4px;"></mosaik-chip>
            </mosaik-floating>
        </mosaik-floating-trigger>
    `;
}

export function dateTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-datebox .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @input="${(e: any) => c.apply(e.target.value)}"></mosaik-datebox>
    `;
}

export function timeTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    const locale = c.element && 'locale' in c.element ? c.element.locale : undefined;

    return html`
        <mosaik-timebox .locale="${ifDefined(locale)}"
                        .value="${c.value}"
                        .variant="${Variant.Primary}"
                        .isClearable="${true}"
                        @input="${(e: any) => c.apply(e.target.value)}"></mosaik-timebox>
    `;
}

export function nullableBooleanTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-segment>
            <mosaik-segment-item .variant="${Variant.Primary}"
                                 .label="${'True'}"
                                 .isChecked="${c.value === true}"
                                 @click="${() => c.apply(true)}"></mosaik-segment-item>
            <mosaik-segment-item .variant="${Variant.Primary}"
                                 .label="${'False'}"
                                 .isChecked="${c.value === false}"
                                 @click="${() => c.apply(false)}"></mosaik-segment-item>
            <mosaik-segment-item .variant="${Variant.Primary}"
                                 .label="${'Null'}"
                                 .isChecked="${c.value === null || c.value === undefined}"
                                 @click="${() => c.apply(null)}"></mosaik-segment-item>
        </mosaik-segment>
    `;
}

export function booleanTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-toggle-switch .isChecked="${c.value}"
                              .value="${c.value}"
                              .variant="${Variant.Primary}"
                              @checked="${() => c.apply(true)}"
                              @unchecked="${() => c.apply(false)}"></mosaik-toggle-switch>
    `;
}

export function animationTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    return html`
        <mosaik-select .value="${c.value}"
                       .variant="${Variant.Primary}"
                       .isClearable="${true}"
                       @selectionChanged="${(e: any) => c.apply(e.target.value)}">
            <mosaik-select-item-group .header="${'Fade'}">
                <mosaik-select-item .value="${AnimationPresets.FadeIn}" .label="${'Fade In'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.FadeOut}" .label="${'Fade Out'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.FadeSlideIn}" .label="${'Fade Slide In'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.FadeSlideOut}" .label="${'Fade Slide Out'}"></mosaik-select-item>
            </mosaik-select-item-group>
            <mosaik-select-item-group .header="${'Grow'}">
                <mosaik-select-item .value="${AnimationPresets.GrowHorIn}" .label="${'Grow Horizontal In'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.GrowHorOut}" .label="${'Grow Horizontal Out'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.GrowVerIn}" .label="${'Grow Vertical In'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.GrowVerOut}" .label="${'Grow Vertical Out'}"></mosaik-select-item>
            </mosaik-select-item-group>
            <mosaik-select-item-group .header="${'Shake'}">
                <mosaik-select-item .value="${AnimationPresets.ShakeHorizontal}" .label="${'Shake Horizontal'}"></mosaik-select-item>
                <mosaik-select-item .value="${AnimationPresets.ShakeVertical}" .label="${'Shake Vertical'}"></mosaik-select-item>
            </mosaik-select-item-group>
        </mosaik-select>
    `;
}

export function iconTemplate(c: IPlaygroundPropertyTemplateContext): TemplateResult {
    // create a random id for the floating trigger
    const id = Math.random().toString(36)
        .substring(7);
    const tpl = TemplateServiceLocator.current.create(html`
        <template id="iconDialogTemplate">
            <mosaik-dialog id="iconDialog"
                           hasBackdrop="${true}"
                           clickOutsideToClose="true"
                           pressEscapeToClose="true">
                <mosaik-dialog-content>
                    <p>icon list goes here</p>
                </mosaik-dialog-content>
            </mosaik-dialog>
        </template>
    `);

    return html`
        <mosaik-textbox id="${id}"
                        .variant="${Variant.Primary}"
                        .value="${c.value}">
            <mosaik-icon slot="suffix"
                         .data="${'M8 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM18 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'}"
                         @click="${() => Portal.open(tpl)}"></mosaik-icon>
        </mosaik-textbox>
    `;
}
