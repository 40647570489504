// #region Imports

import { Component, inject, View } from '@breadstone/mosaik-elements-foundation';
import { ThemeGeneratorViewPresenter } from './ThemeGeneratorViewPresenter';
import { themeGeneratorViewStyle } from './ThemeGeneratorViewStyle';
import { themeGeneratorViewTemplate } from './ThemeGeneratorViewTemplate';

// #endregion

/**
 * The `{@link ThemeGeneratorViewElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-theme-generator-view',
    template: themeGeneratorViewTemplate,
    styles: themeGeneratorViewStyle
})
export class ThemeGeneratorView
    extends View<ThemeGeneratorViewPresenter> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super(inject(ThemeGeneratorViewPresenter));

        this.presenter.schemeKeys.subscribe(() => this.requestUpdate());
        this.presenter.schemeValues.subscribe(() => this.requestUpdate());
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-theme-generator-view';
    }

    // #region Properties

}
