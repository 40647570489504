//#region Imports

import { html, unsafeHTML, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type MarkdownElement } from './MarkdownElement';

//#endregion

/**
 * The template of the {@link MarkdownElement}.
 *
 * @public
 */
export function markdownElementTemplate<T extends MarkdownElement>(e: T): TemplateResult {
    return html`
        ${unsafeHTML(e.renderMarkdown())}
    `;
}
