// #region Imports

import { Appearance, Fit, HorizontalAlignment, Orientation, Size, VerticalAlignment, html, when, type ElevationElement, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { PricingTableElement } from './PricingTableElement';

// #endregion

/**
 * The template of the {@link PricingTableElement}.
 *
 * @public
 */
export function pricingTableElementTemplate<T extends PricingTableElement>(e: T): TemplateResult {
    return html`
        ${when(e.disabled, () => html`
        <mosaik-elevation part="elevation"
                          .weigh="${e.elevation}"
                          @connected="${(evt: Event) => (evt.target as ElevationElement).attach(e)}"></mosaik-elevation>
        `)}
        ${when(e.ribbon.trim(), () => html`
        <mosaik-ribbon part="ribbon"
                       .variant="${e.variant}"
                       .label="${e.ribbon.trim()}"
                       ></mosaik-ribbon>
        `)}
        <div part="heading">
            <mosaik-text part="header"
                         .text="${e.header}"></mosaik-text>
            <mosaik-text part="subHeader"
                         .text="${e.subHeader}"></mosaik-text>
        </div>
        <div part="pricing">
            <mosaik-text part="price"
                         .text="${e.price}"
                         .variant="${e.variant}">
                <p slot="text">
                    ${e.price}
                    <sub part="priceInterval">/${e.priceInterval}</sub>
                </p>
            </mosaik-text>
            ${when(e.priceInterval.trim(), () => html`

            `)}
        </div>
        <div part="feature">
            <mosaik-stack .fit="${Fit.Both}"
                          .orientation="${Orientation.Vertical}">
                <slot></slot>
            </mosaik-stack>
        </div>

        <mosaik-stack part="action"
                      .verticalAlignment="${VerticalAlignment.Center}"
                      .horizontalAlignment="${HorizontalAlignment.Center}">
            <mosaik-button .label="${'Select'}"
                           .size="${Size.Large}"
                           .variant="${e.variant}"
                           .appearance="${Appearance.Solid}"
                           .fit="${Fit.Width}"></mosaik-button>
        </mosaik-stack>
    `;
}
