export const typographyCssCode = `
/* {0}-like css class */
.my-element {
    /* Sets the font. */
    font: var(--theme-body1-typography);
    /* Sets the letter spacing. */
    letter-spacing: var(--theme-body1-typography-letter-spacing);
}
`;

export const typographyHtmlCode = `
<my-element style="font: var(--theme-body1-typography); letter-spacing: var(--theme-body1-typography-letter-spacing);"></my-element>
`;
