/**
 * A decorator that handle a method memorization.
 *
 * Decoration usage as `MethodDecorator`.
 *
 * @public
 * @param callback - The optional callback method, which is triggered when the property was changed.
 */
export function Memoize(hash?: boolean | ((...args: Array<any>) => any)): MethodDecorator {
    return (target: object, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
        if (descriptor.value) {
            descriptor.value = functionFactory(descriptor.value, hash);
        } else if (descriptor.get) {
            descriptor.get = functionFactory(descriptor.get, hash);
        } else {
            throw new Error('Only put a Memoize() decorator on a method or get accessor.');
        }
    };
}

let counter = 0;
function functionFactory(originalMethod: () => void, autoHashOrHashFn?: boolean | ((...args: Array<any>) => any), duration: number = 0): () => any {
    const identifier = ++counter;

    // The function returned here gets called instead of originalMethod.
    return function(...args: Array<any>): any {
        const propValName = `__memoized_value_${identifier}`;
        const propMapName = `__memoized_map_${identifier}`;

        let returnedValue: any;

        if (autoHashOrHashFn ?? args.length > 0 ?? duration > 0) {
            // Get or create map
            // eslint-disable-next-line no-prototype-builtins
            if (!this.hasOwnProperty(propMapName)) {
                Object.defineProperty(this, propMapName, {
                    configurable: false,
                    enumerable: false,
                    writable: false,
                    value: new Map<any, any>()
                });
            }
            const myMap: Map<any, any> = this[propMapName];

            let hashKey: any;

            // If true is passed as first parameter, will automatically use every argument, passed to string
            if (autoHashOrHashFn === true) {
                hashKey = args.map((a) => a.toString()).join('!');
            } else if (autoHashOrHashFn) {
                hashKey = autoHashOrHashFn.apply(this, args);
            } else {
                hashKey = args[0];
            }

            const timestampKey = `${hashKey}__timestamp`;
            let isExpired: boolean = false;
            if (duration > 0) {
                if (!myMap.has(timestampKey)) {
                    // "Expired" since it was never called before
                    isExpired = true;
                } else {
                    const timestamp = myMap.get(timestampKey);
                    isExpired = (Date.now() - timestamp) > duration;
                }
            }

            if (myMap.has(hashKey) && !isExpired) {
                returnedValue = myMap.get(hashKey);
            } else {
                returnedValue = originalMethod.apply(this, args as any);
                myMap.set(hashKey, returnedValue);
                if (duration > 0) {
                    myMap.set(timestampKey, Date.now());
                }
            }
        } else {
            // eslint-disable-next-line no-prototype-builtins
            if (this.hasOwnProperty(propValName)) {
                returnedValue = this[propValName];
            } else {
                returnedValue = originalMethod.apply(this, args as any);
                Object.defineProperty(this, propValName, {
                    configurable: false,
                    enumerable: false,
                    writable: false,
                    value: returnedValue
                });
            }
        }

        return returnedValue;
    };
}
