import { type TemplateResult, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace ButtonGroupSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-button-group>
                <mosaik-button .label=${'First'}></mosaik-button>
                <mosaik-button .label=${'Second'}></mosaik-button>
                <mosaik-button .label=${'Third'}></mosaik-button>
            </mosaik-button-group>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withTogglesTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-button-group>
                <mosaik-toggle-button .label=${'First'}></mosaik-toggle-button>
                <mosaik-toggle-button .label=${'Second'}></mosaik-toggle-button>
                <mosaik-toggle-button .label=${'Third'}></mosaik-toggle-button>
            </mosaik-button-group>
        `;

        return [
            {
                name: 'With Toggles',
                description: 'The template with toggle buttons'
            }, template
        ];
    }

}
