// #region Imports

import { Attribute, Component, CustomElement } from '@breadstone/mosaik-elements-foundation';
import { ISocialLinkElementProps } from './ISocialLinkElementProps';
import { socialLinkElementStyle } from './SocialLinkElementStyle';
import { socialLinkElementTemplate } from './SocialLinkElementTemplate';

// #endregion

/**
 * The `{@link SocialLinkElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-social-link',
    template: socialLinkElementTemplate,
    styles: socialLinkElementStyle,
    imports: []
})
export class SocialLinkElement
    extends CustomElement
    implements ISocialLinkElementProps {

    // #region Fields

    private _type: string;
    private _href: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._type = '';
        this._href = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-social-link';
    }

    /**
     * Gets or sets the `type` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        if (this._type !== value) {
            this._type = value;
            this.requestUpdate('type');
        }
    }

    /**
     * Gets or sets the `href` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get href(): string {
        return this._href;
    }

    public set href(value: string) {
        if (this._href !== value) {
            this._href = value;
            this.requestUpdate('href');
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-social-link': SocialLinkElement;
    }
}
