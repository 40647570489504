//#region Imports

import { Appearance, Component, FlowDirection, SignaturePadElement, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { signaturepadElementDocs } from '../../../.Generated/mosaik-signaturepad';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { colorTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link SignaturePadSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-signaturepad-sample',
})
export class SignaturePadSampleElement extends SampleBaseElement<SignaturePadElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
      * Returns the `styles` property.
      *
      * @public
      * @static
      * @readonly
      */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SignaturePadElement> {
        return {
            header: 'Signature Pad',
            description: META.description(SignaturePadElement.is),
            graph: META.graph(SignaturePadElement.is),
            playground: {
                template: html`
                    <mosaik-signaturepad></mosaik-signaturepad>
                `,
                properties: META.properties<SignaturePadElement>(SignaturePadElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'width', value: 400 },
                    { key: 'height', value: 200 },
                    { key: 'placeholder', value: 'Sign here' },
                    { key: 'minDistance', value: 5 },
                    { key: 'throttle', value: 16 },
                    { key: 'dotSize', value: 0 },
                    { key: 'minWidth', value: 0.5 },
                    { key: 'maxWidth', value: 2.5 },
                    { key: 'velocityFilterWeight', value: 0.7 },
                    { key: 'backgroundColor', value: 'rgb(255,255,255)' },
                    { key: 'penColor', value: 'rgb(0,0,0)' },
                    { key: 'isClearable', value: true },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                ]),
                events: META.events<SignaturePadElement>(SignaturePadElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<SignaturePadElement>(SignaturePadElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'width', value: 400 },
                    { key: 'height', value: 200 },
                    { key: 'placeholder', value: 'Sign here' },
                    { key: 'minDistance', value: 5 },
                    { key: 'throttle', value: 16 },
                    { key: 'dotSize', value: 0 },
                    { key: 'minWidth', value: 0.5 },
                    { key: 'maxWidth', value: 2.5 },
                    { key: 'velocityFilterWeight', value: 0.7 },
                    { key: 'backgroundColor', value: 'rgb(255,255,255)' },
                    { key: 'penColor', value: 'rgb(0,0,0)' },
                    { key: 'isClearable', value: true },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'appearance', value: Appearance.Soft },
                ]), [
                    { key: 'backgroundColor', template: colorTemplate },
                    { key: 'penColor', template: colorTemplate },
                    { key: 'value', template: valueTemplate },
                ] as Array<IPlaygroundProperty<SignaturePadElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                signaturepadElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-signaturepad-sample': SignaturePadSampleElement;
    }
}

