// #region Imports

import { Injectable, IThemeScheme } from '@breadstone/mosaik-elements-foundation';
import { CssColor, CssHEXColor } from '@breadstone/mosaik-elements-foundation/dist/Theming/Types';
import { generateAnalogousPalette } from '@breadstone/mosaik-elements-foundation/src/Theming/Utils.Analogous';

// #endregion

/**
 * The `FeatureManager` class.
 *
 * @public
 */
@Injectable()
export class ThemeManager {

    // #region Ctor

    /**
     * Constructs a new instance of the `ThemeManager` class.
     *
     * @public
     */
    public constructor() {

    }

    // #endregion

    // #region Methods

    public generate(): {
        scheme: IThemeScheme;
        primary: CssColor;
        secondary: CssColor;
        tertiary: CssColor;
    } {
        // scheme
        const types = ['dark', 'light'];
        const type = types[this.getRandomNumber(0, 1)] as 'dark' | 'light';
        const backgroundColor = this.generateRandomBaseColor(type);
        const theme = this.generateRandomTheme(backgroundColor, type);

        // primary, secondary, tertiary
        const primary = this.generateRandomAccentColor();
        const secondary = generateAnalogousPalette(primary, 1 / 9)[500] as CssColor;
        const tertiary = generateAnalogousPalette(primary, -1 / 9)[500] as CssColor;

        return {
            scheme: theme,
            primary: primary,
            secondary: secondary,
            tertiary: tertiary
        };
    }

    /**
     * @private
     */
    private generateRandomBaseColor(type: 'dark' | 'light'): CssColor {
        const step = this.getRandomNumber(0, 40);

        const min = type === 'dark' ? 0 : 245 - step * 4;
        const max = type === 'dark' ? 20 : 255 - step * 4;

        const r: number = this.getRandomNumber(min, max);
        const g: number = this.getRandomNumber(min, max);
        const b: number = this.getRandomNumber(min, max);

        const base1 = this.rgbToHex(`${r + step * 1}, ${g + step * 1}, ${b + step * 1}`);
        const base2 = this.rgbToHex(`${r + step * 2}, ${g + step * 2}, ${b + step * 2}`);
        const base3 = this.rgbToHex(`${r + step * 3}, ${g + step * 3}, ${b + step * 3}`);
        const base4 = this.rgbToHex(`${r + step * 4}, ${g + step * 4}, ${b + step * 4}`);

        return type === 'dark' ? base1 : base4;
    }

    /**
     * @private
     */
    private generateRandomTheme(backgroundColor: CssColor, type: 'dark' | 'light'): IThemeScheme {
        // Utility function to lighten or darken a color
        const adjustColor = (color: CssColor, amount: number): CssColor => `#${color.replace(/^#/, '')
            .replace(/../g, (c) => `0${Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)}`.slice(-2))}`;

        return {
            backgroundColor: backgroundColor,
            foregroundColor: adjustColor(backgroundColor, type === 'dark' ? 190 : -190),
            highlightColor: adjustColor(backgroundColor, type === 'dark' ? 30 : -30),
            middlelightColor: adjustColor(backgroundColor, -50),
            lowlightColor: adjustColor(backgroundColor, -80),
            transparentColor: `${backgroundColor}00` as CssColor,
            semiTransparentColor: `${backgroundColor}aa` as CssColor,
            disabledColor: adjustColor(backgroundColor, -40),
            selectionColor: adjustColor(backgroundColor, -60)
        };
    }

    /**
     * @private
     */
    private generateRandomAccentColor(): CssColor {
        const r: number = this.getRandomNumber(0, 245);
        const g: number = this.getRandomNumber(0, 245);
        const b: number = this.getRandomNumber(0, 245);

        const accent = this.rgbToHex(`${r}, ${g}, ${b}`);
        const accentB = this.rgbToHex(`${r + 10}, ${g + 10}, ${b + 10}`);

        return accent;
    }

    /**
     * @private
     */
    private getRandomNumber(min: number = 0, max: number = 255): number {
        return min + Math.floor(Math.random() * (max - min + 1));
    }

    /**
     * @private
     */
    private rgbToHex(rgb: string): CssHEXColor {
        const rgbArray = rgb.replace(' ', '').split(',');
        const [r, g, b] = [
            parseInt(rgbArray[0]),
            parseInt(rgbArray[1]),
            parseInt(rgbArray[2])
        ];
        return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
    }

    /**
     * @private
     */
    private componentToHex(c: number): string | number {
        const hex = c.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }

    // #endregion

}
