// #region Imports

import { Animate, Appearance, Fit, HorizontalAlignment, Inset, Orientation, SearchTriggerMode, Size, SkeletonShape, Variant, VerticalAlignment, VirtualizeLayout, animate, flex, html, translate, typography, watch, when, type TemplateResult, type TextBoxElement } from '@breadstone/mosaik-elements-foundation';
import type { GridBaseLayoutConfig } from '@breadstone/mosaik-elements-foundation/dist/Controls/Components/Layouts/Virtualize/VirtualizeLayout';
import type { IIconResponse } from '../../Models/Interfaces/IIconResponse';
import type { IconGalleryView } from './IconGalleryView';

// #endregion

/**
 * The template of the {@link IconGalleryElement}.
 *
 * @public
 */
export function iconGalleryViewTemplate<T extends IconGalleryView>(e: T): TemplateResult {
    const config: GridBaseLayoutConfig = {
        justify: 'space-between',
        flex: true,
        itemSize: '174px',
        gap: '16px'
    };

    return html`
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}" .label="${translate('Home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/tools'}" .label="${translate('Tools')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/iconGallery'}" .label="${translate('Icon Gallery')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-header .inset="${Inset.Horizontal}"
                                .text="${translate('Icon Gallery')}"></mosaik-page-header>
            <mosaik-page-pre-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-sticky>
                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                  .gap="${'8px'}">
                        <mosaik-stack .orientation="${Orientation.Horizontal}"
                                  .gap="${'8px'}">
                            <mosaik-searchbox ${flex({ fill: true })}
                                              .placeholder="${translate('Search for icons...')}"
                                              .variant="${Variant.Primary}"
                                              .isClearable="${true}"
                                              .delay="${300}"
                                              .autofocus="${true}"
                                              .mode="${SearchTriggerMode.Delay}"
                                              .isBusy="${watch(e.presenter.isLoading)}"
                                              @searched="${(x: InputEvent) => e.presenter.filter.set((x.target as TextBoxElement).value)}"
                                              @cleared="${(x: CustomEvent<string>) => e.presenter.filter.set('')}"></mosaik-searchbox>
                            <mosaik-select ${flex({ fill: false })}
                                           style="min-width: 100px;"
                                           .variant="${Variant.Primary}"
                                           .value="${watch(e.presenter.size)}">
                                <mosaik-select-item .isSelected="${e.presenter.size.get() === Size.Tiny}"
                                                    .isActive="${e.presenter.size.get() === Size.Tiny}"
                                                    .label="${'16'}"
                                                    .value="${16}"
                                                    @click="${() => e.presenter.size.set(Size.Tiny)}"></mosaik-select-item>
                                <mosaik-select-item .isSelected="${e.presenter.size.get() === Size.Small}"
                                                    .isActive="${e.presenter.size.get() === Size.Small}"
                                                    .label="${'24'}"
                                                    .value="${24}"
                                                    @click="${() => e.presenter.size.set(Size.Small)}"></mosaik-select-item>
                                <mosaik-select-item .isSelected="${e.presenter.size.get() === Size.Medium}"
                                                    .isActive="${e.presenter.size.get() === Size.Medium}"
                                                    .label="${'32'}"
                                                    .value="${32}"
                                                    @click="${() => e.presenter.size.set(Size.Medium)}"></mosaik-select-item>
                                <mosaik-select-item .isSelected="${e.presenter.size.get() === Size.Large}"
                                                    .isActive="${e.presenter.size.get() === Size.Large}"
                                                    .label="${'48'}"
                                                    .value="${48}"
                                                    @click="${() => e.presenter.size.set(Size.Large)}"></mosaik-select-item>
                                <mosaik-select-item .isSelected="${e.presenter.size.get() === Size.Giant}"
                                                    .isActive="${e.presenter.size.get() === Size.Giant}"
                                                    .label="${'52'}"
                                                    .value="${52}"
                                                    @click="${() => e.presenter.size.set(Size.Giant)}"></mosaik-select-item>
                            </mosaik-select>
                        </mosaik-stack>
                        <mosaik-stack .gap="${'8px'}">
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Center}">
                                <mosaik-segment>
                                    <mosaik-segment-item .isChecked="${e.presenter.mode.get() === 'filled'}"
                                                         .label="${'Filled'}"
                                                         @click="${() => e.presenter.mode.set('filled')}"></mosaik-segment-item>
                                    <mosaik-segment-item .isChecked="${e.presenter.mode.get() === 'outlined'}"
                                                         .label="${'Outlined'}"
                                                         @click="${() => e.presenter.mode.set('outlined')}"></mosaik-segment-item>
                                </mosaik-segment>
                            </mosaik-stack>
                        </mosaik-stack>
                    </mosaik-stack>
                </mosaik-sticky>
            </mosaik-page-pre-content>
            <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                <mosaik-stack .orientation="${Orientation.Vertical}" .gap="${'16px'}">
                    <mosaik-spacer .size="${Size.Medium}"
                                   .invert="${true}">
                        <mosaik-virtualize .layout="${VirtualizeLayout.grid(config)}"
                                           .items="${e.presenter.data.get()}"
                                           .renderItem="${(y: IIconResponse) => html`
                            <mosaik-flip @click="${(event: Event) => e.presenter.onCopy(event, y.data)}">
                                <mosaik-card slot="front"
                                             .appearance="${Appearance.Outline}"
                                             .fit="${Fit.Both}"
                                             .inset="${Inset.None}">
                                    <mosaik-card-content>
                                        <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                                        .horizontalAlignment="${HorizontalAlignment.Center}"
                                                        .fit="${Fit.Both}">
                                            <mosaik-icon .data="${y.data}"
                                                            .size="${watch(e.presenter.size)}"></mosaik-icon>
                                        </mosaik-stack>
                                    </mosaik-card-content>
                                    <mosaik-card-footer>
                                        <mosaik-text ${typography('caption')}
                                                        .text="${y.name}"
                                                        .truncate="${true}"></mosaik-text>
                                    </mosaik-card-footer>
                                </mosaik-card>
                                <mosaik-card slot="back"
                                             .appearance="${Appearance.Solid}"
                                             .fit="${Fit.Both}"
                                             .inset="${Inset.None}">
                                    <mosaik-card-content>
                                        <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                                        .horizontalAlignment="${HorizontalAlignment.Center}"
                                                        .fit="${Fit.Both}">
                                            <mosaik-text .text="${translate('Copied')}"
                                                            .truncate="${true}"></mosaik-text>
                                        </mosaik-stack>
                                    </mosaik-card-content>
                                </mosaik-card>
                            </mosaik-flip>
                        `}"></mosaik-virtualize>
                    </mosaik-spacer>
                </mosaik-stack>

                ${when(e.presenter.isLoading.get(), () => html`
                    <mosaik-wrap .orientation="${Orientation.Horizontal}"
                                 .gap="${'16px'}">
                        <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                        .width="${'160px'}"
                                        .height="${'189px'}"
                                        .shimmer="${true}"></mosaik-skeleton>
                        <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                        .width="${'160px'}"
                                        .height="${'189px'}"
                                        .shimmer="${true}"></mosaik-skeleton>
                        <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                        .width="${'160px'}"
                                        .height="${'189px'}"
                                        .shimmer="${true}"></mosaik-skeleton>
                        <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                        .width="${'160px'}"
                                        .height="${'189px'}"
                                        .shimmer="${true}"></mosaik-skeleton>
                        <mosaik-skeleton .shape="${SkeletonShape.rect}"
                                        .width="${'160px'}"
                                        .height="${'189px'}"
                                        .shimmer="${true}"></mosaik-skeleton>
                    </mosaik-wrap>
                `)}

                ${when(e.presenter.data.get().length === 0 && !e.presenter.isLoading.get(), () => html`
                    <mosaik-empty-state .content="${translate('No icons found.')}"></mosaik-empty-state>
                `)}
            </mosaik-page-content>
        </mosaik-page>
    `;
}
