// #region Imports

import { Component, FlowDirection, Placement, TooltipElement, Trigger } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { tooltipElementDocs } from '../../.Generated/mosaik-tooltip';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TooltipSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tooltip-sample'
})
export class TooltipSampleElement extends SampleBaseElement<TooltipElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TooltipElement> {
        return {
            header: 'Tooltip',
            description: META.description(TooltipElement.is),
            graph: META.graph(TooltipElement.is),
            playground: {
                template: html`
                    <mosaik-tooltip>
                        <mosaik-button label="Hover me"></mosaik-button>
                    </mosaik-tooltip>
                `,
                properties: META.properties<TooltipElement>(TooltipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'hideDelay',
                        value: 100
                    },
                    {
                        key: 'showDelay',
                        value: 300
                    },
                    {
                        key: 'placement',
                        value: Placement.Top
                    },
                    {
                        key: 'trigger',
                        value: Trigger.Hover
                    },
                    {
                        key: 'horizontalOffset',
                        value: 8
                    },
                    {
                        key: 'content',
                        value: 'Im a tooltip.'
                    }
                ])
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<TooltipElement>(TooltipElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'hideDelay',
                        value: 100
                    },
                    {
                        key: 'showDelay',
                        value: 300
                    },
                    {
                        key: 'placement',
                        value: Placement.Top
                    },
                    {
                        key: 'trigger',
                        value: Trigger.Hover
                    },
                    {
                        key: 'horizontalOffset',
                        value: 8
                    },
                    {
                        key: 'content',
                        value: 'Im a tooltip.'
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<TooltipElement>>, (a, b) => a.key === b.key), [
                    { key: 'for' },
                    { key: 'control' }
                ] as Array<IPlaygroundProperty<TooltipElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                tooltipElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-tooltip-sample': TooltipSampleElement;
    }
}
