// #region Imports

import { Animate, Appearance, DrawerMode, ElevatioWeight, Fit, FlowDirection, HorizontalAlignment, Inset, Orientation, Size, Spacing, Variant, VerticalAlignment, animate, classMap, flex, html, query, repeat, translate, when, type DrawerElement, type IPortalAttachedEventDetail, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { HyperlinkUnderline } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/HyperlinkUnderline';
import { mouseMovementObserver } from '../../Components/MouseMovement/MouseMovementObserverDirective';
import { chunk } from '../../Extensions/ArrayExtensions';
import { globals } from '../../Globals';
import { FeatureManager } from '../../Services/FeatureManager';
import { ROUTES } from '../../Services/RoutesService';
import type { AppElement } from './App';

// #endregion

/**
 * @public
 */
export function appElementTemplate<T extends AppElement>(e: T): TemplateResult {
    return html`
        ${bg()}
        <mosaik-theme part="theme"
                      .scheme="${e.scheme}"
                      .palette="${{ primary: e.preset.primary, secondary: e.preset.secondary, tertiary: e.preset.tertiary }}"
                      .layout="${e.layout}"
                      .typography="${e.typography}">
            <mosaik-router id="router"
                           .routes="${ROUTES}"
                           .useHash="${globals.myWebHostingIsAStupidIdiot}"
                           .scrollRestoration="${'manual'}"
                           .scrollTargetFn="${() => query(e, '#drawerContent', { shadow: true, strict: false })}">
                <mosaik-drawer-container ${mouseMovementObserver()}
                                         .fit="${Fit.Both}"
                                         .hasBackdrop="${e.isMobile}">
                    <mosaik-drawer slot="drawer"
                                   part="drawer"
                                   id="drawer"
                                   .isOpen="${e.isDrawerOpen}"
                                   .mode="${e.isMobile ? DrawerMode.Push : DrawerMode.Side}"
                                   .hasBackdrop="${e.isMobile}"
                                   .hasShadow="${e.isMobile}">
                        ${when(e.hasHeader, () => html`
                        <app-side-nav .closeOnNavigate="${e.isMobile}"
                                      .isOpen="${e.isDrawerOpen}"
                                      .isMobile="${e.isMobile}"
                                      @closed="${() => { query<DrawerElement>(e, '#drawer', { shadow: true, strict: false })?.close(); }}">
                            <mosaik-divider slot="end"></mosaik-divider>
                            <mosaik-box slot="end">
                                <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                    <mosaik-button @click="${() => e.settingsCommand.execute()}">
                                        <mosaik-icon ${animate({ ...Animate.rotate, ...{ options: { iterations: 10000, duration: 2000 } } })}
                                                     slot="icon"
                                                    .data="${'M12.012 2.25c.734.008 1.465.093 2.182.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.927 1.116l1.401-.615a.75.75 0 0 1 .85.174 9.792 9.792 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.381 1.381 0 0 0 0 2.226l1.243.915a.75.75 0 0 1 .272.826 9.797 9.797 0 0 1-2.204 3.792.75.75 0 0 1-.848.175l-1.407-.617a1.38 1.38 0 0 0-1.926 1.114l-.169 1.526a.75.75 0 0 1-.572.647 9.518 9.518 0 0 1-4.406 0 .75.75 0 0 1-.572-.647l-.168-1.524a1.382 1.382 0 0 0-1.926-1.11l-1.406.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.204-3.796.75.75 0 0 1 .272-.826l1.243-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.271-.826 9.793 9.793 0 0 1 2.204-3.792.75.75 0 0 1 .85-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.717-.159 1.45-.243 2.201-.252ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z'}"></mosaik-icon>
                                    </mosaik-button>
                                </mosaik-stack>
                            </mosaik-box>
                        </app-side-nav>
                        `)}
                    </mosaik-drawer>
                    <mosaik-drawer-content slot="content"
                                           part="drawerContent"
                                           id="drawerContent">
                        ${when(e.hasHeader, () => html`
                        <app-bar .hasMenu="${e.hasMenu}"
                                 .text="${e.name}"
                                 .scrollTargetFn="${() => query(e, '#drawerContent', { shadow: true, strict: false })}"
                                 @drawerOpened="${() => { query<DrawerElement>(e, '#drawer', { shadow: true, strict: false })?.toggle(); e.isDrawerOpen = query<DrawerElement>(e, '#drawer', { shadow: true, strict: false })?.isOpen ?? false; }}"></app-bar>
                        `)}
                        <mosaik-stack .orientation="${Orientation.Vertical}">
                            <mosaik-stack part="main"
                                          class="${classMap({ headerless: !e.hasHeader })}"
                                          .orientation="${Orientation.Vertical}">
                                <mosaik-router-outlet></mosaik-router-outlet>
                                ${when(e.hasNavigation, () => html`
                                <mosaik-page .breakpoint="${'md'}">
                                    <mosaik-page-content>
                                        <mosaik-spacer .thickness="${Spacing.Vertical}"
                                                       .size="${Size.Large}">
                                        <mosaik-spacer .thickness="${Spacing.Horizontal}"
                                                       .size="${Size.Small}">
                                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                        .horizontalAlignment="${HorizontalAlignment.Stretch}"
                                                        .gap="${'16px'}">
                                                <mosaik-box .fit="${Fit.Width}"
                                                            .inset="${Inset.None}"
                                                            style="--box-background-color: var(--theme-transparent-color);">
                                                    <mosaik-router-link ${flex({ fill: true })} .href="${e.previousRoutePath}">
                                                        <mosaik-compound-button .label="${translate('loc.global.previous')}"
                                                                                .subLabel="${e.previousRouteName}"
                                                                                .variant="${Variant.Primary}"
                                                                                .appearance="${Appearance.Outline}"
                                                                                .fit="${Fit.Width}"
                                                                                .horizontalContentAlignment="${HorizontalAlignment.Right}"
                                                                                .isNavigation="${true}"
                                                                                .dir="${FlowDirection.RightToLeft}"></mosaik-compound-button>
                                                    </mosaik-router-link>
                                                </mosaik-box>
                                                <mosaik-box .fit="${Fit.Width}"
                                                            .inset="${Inset.None}"
                                                            style="--box-background-color: var(--theme-transparent-color);">
                                                    <mosaik-router-link ${flex({ fill: true })} .href="${e.nextRoutePath}">
                                                        <mosaik-compound-button .label="${translate('loc.global.next')}"
                                                                                .subLabel="${e.nextRouteName}"
                                                                                .variant="${Variant.Primary}"
                                                                                .appearance="${Appearance.Outline}"
                                                                                .fit="${Fit.Width}"
                                                                                .horizontalContentAlignment="${HorizontalAlignment.Right}"
                                                                                .isNavigation="${true}"></mosaik-compound-button>
                                                    </mosaik-router-link>
                                                </mosaik-box>
                                            </mosaik-stack>
                                        </mosaik-spacer>
                                        </mosaik-spacer>
                                    </mosaik-page-content>
                                </mosaik-page>
                                `)}
                            </mosaik-stack>
                            ${when(e.hasFooter, () => html`
                            <mosaik-footer .breakpoint="${'md'}"
                                           .brand="${globals.company}">
                                <div slot="start">
                                    ${repeat(chunk(globals.sites.filter((x) => !x.external), 5), (x) => x, (sites) => html`
                                    <mosaik-footer-item-group>
                                        ${repeat(sites, (x) => x, (x) => html`
                                        <mosaik-footer-item>
                                            <mosaik-router-link .href="${x.url}">
                                                <mosaik-anchor .label="${translate(`loc.nav.${x.name}`)}"
                                                               .underline="${HyperlinkUnderline.Hover}"></mosaik-anchor>
                                            </mosaik-router-link>
                                        </mosaik-footer-item>
                                        `)}
                                    </mosaik-footer-item-group>
                                    `)}
                                </div>
                                <div slot="end">
                                    <mosaik-footer-item-group>
                                        ${repeat(globals.sites.filter((x) => x.external && x.location === 'footer'), (x) => x, (x: any) => html`
                                        <mosaik-footer-item>
                                            <mosaik-button .href="${x?.url}"
                                                           .target="${x?.external ? '_blank' : '_self'}"
                                                           .icon="${x.icon ?? ''}"
                                                           .iconSize="${Size.Small}"></mosaik-button>
                                        </mosaik-footer-item>
                                        `)}
                                    </mosaik-footer-item-group>
                                </div>
                                <mosaik-router-link slot="brand" .href="${'/'}">
                                    <app-logo part="logo" .type="${'logo'}"></app-logo>
                                </mosaik-router-link>
                                <mosaik-stack slot="bottom"
                                              .orientation="${Orientation.Vertical}">
                                    <app-made-with-love .by="${globals.name}"></app-made-with-love>
                                    <mosaik-text .text="${e.deploymentDate.toLocaleDateString()}"
                                                 .readonly="${true}"></mosaik-text>
                                </mosaik-stack>
                            </mosaik-footer>
                            `)}
                        </mosaik-stack>
                        <app-back-top></app-back-top>
                    </mosaik-drawer-content>
                </mosaik-drawer-container>
            </mosaik-router>
        </mosaik-theme>
        <mosaik-portal>
            <mosaik-theme .scheme="${e.scheme}"
                          .palette="${{ primary: e.preset.primary }}"
                          .layout="${e.layout}"
                          .isInline="${true}">
                <mosaik-absolute style="pointer-events: none;">
                    <mosaik-absolute-item style="pointer-events: all;"
                                          .bottom="${'50%'}"
                                          .right="${'0'}">
                        ${when(e.hasFeedback && FeatureManager.isActive('issueTracking'), () => html`
                        <app-issue ${animate({ ...Animate.slideInRight, ...{ delay: 800 } })}></app-issue>
                        `)}
                    </mosaik-absolute-item>
                    <mosaik-absolute-item style="pointer-events: all; padding-bottom: env(safe-area-inset-bottom);"
                                          .bottom="${'16px'}"
                                          .right="${'16px'}">
                        ${when(FeatureManager.isActive('aiChat'), () => html`
                        <app-chat ${animate({ ...Animate.slideInBottom, ...{ delay: 800 } })}></app-chat>
                        `)}
                    </mosaik-absolute-item>
                </mosaik-absolute>
            </mosaik-theme>
        </mosaik-portal>
        ${when(!e.hasCookies, () => html`
        <mosaik-portal @portalAttached="${(x: CustomEvent<IPortalAttachedEventDetail>) => e.attachPortalCommand.execute(x.detail)}">
            <mosaik-theme .scheme="${e.scheme}"
                          .palette="${{ primary: e.preset.primary }}"
                          .layout="${e.layout}"
                          .isInline="${true}">
                <mosaik-cookies-consent part="consent"
                                        .elevation="${ElevatioWeight.Regular}"
                                        .acceptable="${true}"
                                        @accepted="${() => e.acceptCookiesCommand.execute()}">
                    <mosaik-router-link slot="actions"
                                        .href="${'/legal/cookiePolicy'}">
                        <mosaik-button .label="${translate('loc.cookiesConsent.more')}"
                                       .appearance="${Appearance.Plain}"></mosaik-button>
                    </mosaik-router-link>
                </mosaik-cookies-consent>
            </mosaik-theme>
        </mosaik-portal>
        `)}
    `;
}

function bg(): TemplateResult {
    return html`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1720 751" fill="none" style="
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100%">
<mask id="mask0_1117_18716" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1720" height="751">
<rect width="1720" height="751" fill="url(#paint0_radial_1117_18716)"></rect>
<rect width="1720" height="751" fill="url(#paint1_radial_1117_18716)"></rect>
</mask>
<g mask="url(#mask0_1117_18716)">
<rect width="1720" height="751" fill="#EFECF0" style="
    fill: transparent;
"></rect>
<g filter="url(#filter0_f_1117_18716)">
<ellipse cx="644" cy="822" rx="411" ry="297" fill="url(#paint2_radial_1117_18716)"></ellipse>
</g>
<g filter="url(#filter1_f_1117_18716)">
<ellipse cx="1265.34" cy="656.882" rx="411" ry="297" transform="rotate(-68.2103 1265.34 656.882)" fill="url(#paint3_linear_1117_18716)"></ellipse>
</g>
</g>
<defs>
<filter id="filter0_f_1117_18716" x="-367" y="-75" width="2022" height="1794" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
<feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1117_18716"></feGaussianBlur>
</filter>
<filter id="filter1_f_1117_18716" x="350.119" y="-340.461" width="1830.45" height="1994.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
<feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1117_18716"></feGaussianBlur>
</filter>
<radialGradient id="paint0_radial_1117_18716" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(860 751) rotate(90) scale(769 860)">
<stop></stop>
<stop offset="1" stop-color="#d9d9d929" stop-opacity="0"></stop>
<stop offset="1" stop-opacity="0"></stop>
</radialGradient>
<radialGradient id="paint1_radial_1117_18716" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(860 751) rotate(90) scale(769 860)">
<stop></stop>
<stop offset="1" stop-color="#d9d9d925" stop-opacity="0"></stop>
<stop offset="1" stop-opacity="0"></stop>
</radialGradient>
<radialGradient id="paint2_radial_1117_18716" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(692 822) rotate(98.858) scale(311.718 415.961)">
<stop offset="0.50025" stop-color="#d100ab1a"></stop>
<stop offset="1" stop-color="#d100ab0b"></stop>
</radialGradient>
<linearGradient id="paint3_linear_1117_18716" x1="1448.75" y1="679.578" x2="996.171" y2="891.735" gradientUnits="userSpaceOnUse">
<stop stop-color="#ff4f271a"></stop>
<stop offset="1" stop-color="#ff4d270b"></stop>
</linearGradient>
</defs>
</svg>
`;
}
