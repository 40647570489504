
//#region Imports

import { Appearance, ChoiceGroupElement, Component, FlowDirection, Orientation, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { choiceGroupElementDocs } from '../../../.Generated/mosaik-choice-group';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link ChoiceGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-choice-group-sample'
})
export class ChoiceGroupSampleElement extends SampleBaseElement<ChoiceGroupElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChoiceGroupElement> {
        return {
            header: 'Choice Group',
            description: META.description(ChoiceGroupElement.is),
            graph: META.graph(ChoiceGroupElement.is),
            playground: {
                template: html`
                    <mosaik-choice-group>
                        <mosaik-choice-group-header>
                            <mosaik-text .text="${'Choose your favorite pet or pets'}"></mosaik-text>
                        </mosaik-choice-group-header>
                        <mosaik-choice .label="${'Dog'}"
                                       .value="${'dog'}"
                                       .icon="${'M10.876 8.661c.387.216.848.339 1.37.339.847 0 1.555-.307 2.047-.844.481-.526.703-1.216.703-1.906a.75.75 0 0 0-1.5 0c0 .377-.12.686-.31.894-.18.196-.472.356-.94.356-.449 0-.73-.162-.914-.37A1.46 1.46 0 0 1 11 6.29V4.25A2.25 2.25 0 0 1 13.25 2h3.185c.509 0 1.007.141 1.44.408l2.768 1.703A.75.75 0 0 1 21 4.75v1.5a2.25 2.25 0 0 1-2.25 2.25h-.25v9.533c.92.088 1.618.366 2.047.964.257.359.36.76.408 1.129.045.352.045.736.045 1.09v.034a.75.75 0 0 1-.75.75h-3.252v-.774c0-.572.002-2.208-1.5-3.169v-2.804a.75.75 0 0 0-1.5 0v2.266a4.622 4.622 0 0 0-.697-.022c-.026 0-.049.002-.064.003h-.025l-.003.001a.75.75 0 1 0 .082 1.498h.003l.013-.001.05-.002c.041-.002.093-.003.141-.003.432 0 .715.073.901.17l.033.017c1.039.484 1.066 1.439 1.066 2.07V22H5.833a3.833 3.833 0 0 1-1.97-7.123.75.75 0 0 1 .772 1.287A2.333 2.333 0 0 0 5.833 20.5c.319 0 .517-.083.649-.176.139-.098.248-.238.331-.407a1.928 1.928 0 0 0 .187-.69v-.035l.004-.143a14.198 14.198 0 0 1 .26-2.187c.26-1.291.797-2.968 1.952-4.139.848-.86 1.309-2.119 1.547-3.364.046-.238.083-.472.113-.698Z'}"></mosaik-choice>
                        <mosaik-choice .label="${'Cat'}"
                                       .value="${'cat'}"
                                       .icon="${'M7.247 22H5.796a3.797 3.797 0 0 1-2.775-6.39l1.135-1.217a3.059 3.059 0 0 0-.073-4.248L2.969 9.03a.75.75 0 0 1 1.06-1.06l1.114 1.114a4.558 4.558 0 0 1 .11 6.332l-1.136 1.217a2.3 2.3 0 0 0 1.68 3.867h.677c-.003-.285-.001-.634.012-1.033.036-1.083.157-2.542.5-4.012.34-1.462.915-2.996 1.899-4.177.872-1.047 2.055-1.801 3.61-1.985V5.15A3.15 3.15 0 0 1 15.641 2c.746 0 1.35.604 1.35 1.35V4h1.636c.95 0 1.834.492 2.335 1.3l.62 1c1.092 1.763-.084 4.02-2.093 4.19v9.26A2.25 2.25 0 0 1 17.242 22h-.75v-2.25A3.749 3.749 0 0 0 12.744 16h-1.25a.75.75 0 0 0 0 1.5h1.25a2.25 2.25 0 0 1 2.249 2.25V22H7.247Z'}"></mosaik-choice>
                        <mosaik-choice .label="${'Rabbit'}"
                                       .value="${'rabbit'}"
                                       .icon="${'m15.53 5.437 5.426 5.437a3.576 3.576 0 0 1 0 5.049 3.556 3.556 0 0 1-3.342.95v.124a2.001 2.001 0 0 1-2 2.003h-2.11l.001-.748-.005-.168a2.752 2.752 0 0 0-2.57-2.58l-.174-.006h-1l-.101.007a.751.751 0 0 0 0 1.488l.102.007h1l.127.007c.588.06 1.056.528 1.115 1.117l.007.128-.001.748h-5c-1.103 0-1.998-.897-1.998-2.003v-2.504c0-.19.012-.378.034-.562A2.502 2.502 0 0 1 2 11.488a2.502 2.502 0 0 1 2.499-2.504 2.5 2.5 0 0 1 2.407 1.83 4.462 4.462 0 0 1 2.599-.829h3.611c.603 0 1.179.12 1.705.335a4.19 4.19 0 0 1 .597-.779l-1.992-1.995a1.494 1.494 0 0 1 0-2.11 1.486 1.486 0 0 1 2.105 0Z'}"></mosaik-choice>
                        <mosaik-choice .label="${'Turtle'}"
                                       .value="${'turtle'}"
                                       .icon="${'M10.997 5.998a6.14 6.14 0 0 1 5.8 4.125l.075.233.044.145h2.33a2.749 2.749 0 0 1 2.744 2.581l.005.168v1a1.75 1.75 0 0 1-1.606 1.742l-.143.006H18.62l.241.584a1.75 1.75 0 0 1-.813 2.22l-.137.064a1.752 1.752 0 0 1-.496.123l-.171.009h-1.787a1.75 1.75 0 0 1-1.51-.867l-.072-.138-.539-1.142.054-.007c-1.4.186-2.817.208-4.221.066l-.497-.057-.535 1.136a1.75 1.75 0 0 1-1.583 1.005H4.75a1.749 1.749 0 0 1-1.618-2.415l.433-1.05a3.242 3.242 0 0 1-1.57-2.78.75.75 0 0 1 .648-.743L2.745 12h1.88l.497-1.642a6.137 6.137 0 0 1 5.875-4.36Zm6.777 9.693c-.771.31-1.559.565-2.356.765l-.549.129.362.77a.25.25 0 0 0 .117.118l.053.019.056.006h1.787a.25.25 0 0 0 .248-.28l-.017-.065-.478-1.155h-.043l.411-.148.409-.159Zm-13.552 0 .39.152.388.141-.482 1.165a.25.25 0 0 0 .232.345h1.804l.057-.006a.25.25 0 0 0 .17-.137l.359-.763.044.01a18.159 18.159 0 0 1-2.962-.907Zm-.202-1.695.151-.499-.507.001.025.053c.086.166.198.316.33.445ZM17.37 12l.756 2.498 2.12.001a.25.25 0 0 0 .243-.193l.007-.057v-1a1.25 1.25 0 0 0-1.122-1.242l-.128-.006L17.37 12Z'}"></mosaik-choice>
                    </mosaik-choice-group>
                `,
                properties: META.properties<ChoiceGroupElement>(ChoiceGroupElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'orientation', value: Orientation.Vertical },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'value', value: 'turtle' }
                ]),
                events: META.events(ChoiceGroupElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ChoiceGroupElement>(ChoiceGroupElement.is, [
                    { key: 'dir', value: FlowDirection.Auto, },
                    { key: 'orientation', value: Orientation.Vertical },
                    { key: 'appearance', value: Appearance.Soft },
                    { key: 'variant', value: Variant.Primary },
                    { key: 'value', value: 'turtle' }
                ]), [
                    { key: 'value', template: valueTemplate }
                ] as Array<IPlaygroundProperty<ChoiceGroupElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                choiceGroupElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-choice-group-sample': ChoiceGroupSampleElement;
    }
}

