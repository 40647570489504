//#region Imports

import { Appearance, CheckType, html, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type PricingTableRowElement } from './PricingTableRowElement';

//#endregion

/**
 * The template of the {@link PricingTableRowElement}.
 *
 * @public
 */
export function pricingTableRowElementTemplate<T extends PricingTableRowElement>(e: T): TemplateResult {
    return html`
        ${when(e.available !== null || e.available !== null, () => html`
        <mosaik-checkmark part="checkmark"
                          .type="${CheckType.Hook}"
                          .appearance="${Appearance.Solid}"
                          ?checked="${e.available}"></mosaik-checkmark>
        `)}
        <slot>
            ${when(e.text.trim(), () => html`
            <mosaik-text .text="${e.text}"
                         ?wrap="${true}"></mosaik-text>
            `)}
        </slot>
    `;
}
