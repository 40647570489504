// #region Imports

import { Appearance, CalendarView, Component, DateBoxElement, DayOfWeek, FlowDirection, Placement, SelectionMode, Size, Strategy, Variant, type CalendarMarkerHandler } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { dateboxElementDocs } from '../../../.Generated/mosaik-datebox';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate, cssLengtTemplate, dateTemplate, functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DateBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-datebox-sample'
})
export class DateBoxSampleElement extends SampleBaseElement<DateBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DateBoxElement> {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const specialDates = [new Date(year, month, 12), new Date(year, month, 24), new Date(year, month, 25), new Date(year, month, 26)];
        const blackoutDates = [new Date(year, month, 11), new Date(year, month, 10)];

        const markerHandler: CalendarMarkerHandler = (value: Date) => value.getDay() % 3 === 0
            ? [{
                variant: Variant.Primary,
                size: Size.Tiny
            }, {
                variant: Variant.Secondary,
                size: Size.Tiny
            }, {
                variant: Variant.Tertiary,
                size: Size.Tiny
            }]
            : [];

        return {
            header: 'Date Box',
            description: META.description(DateBoxElement.is),
            graph: META.graph(DateBoxElement.is),
            playground: {
                template: html`
                    <mosaik-datebox></mosaik-datebox>
                `,
                properties: META.properties<DateBoxElement>(DateBoxElement.is, [
                    {
                        key: 'value',
                        value: new Date()
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'maxDropDownHeight',
                        value: 'auto'
                    },
                    {
                        key: 'dropDownDistance',
                        value: 8
                    },
                    {
                        key: 'dropDownPlacement',
                        value: Placement.Bottom
                    },
                    {
                        key: 'dropDownSkidding',
                        value: 0
                    },
                    {
                        key: 'dropDownStrategy',
                        value: Strategy.Absolute
                    },
                    {
                        key: 'isDropDownOpen',
                        value: false
                    },
                    {
                        key: 'isClearable',
                        value: false
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'dropDownStaysOpen',
                        value: false
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'values',
                        value: []
                    },
                    {
                        key: 'blackoutDates',
                        value: blackoutDates
                    },
                    {
                        key: 'specialDates',
                        value: specialDates
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Single
                    },
                    {
                        key: 'view',
                        value: CalendarView.Day
                    },
                    {
                        key: 'firstDayOfWeek',
                        value: DayOfWeek.Sunday
                    },
                    {
                        key: 'isTodayHighlighted',
                        value: true
                    },
                    {
                        key: 'isWeekendHighlighted',
                        value: true
                    },
                    {
                        key: 'showWeekNumbers',
                        value: false
                    },
                    {
                        key: 'showAdjacent',
                        value: true
                    },
                    {
                        key: 'markerHandler',
                        value: markerHandler
                    },
                    {
                        key: 'locale',
                        value: 'en-US'
                    },
                    {
                        key: 'format',
                        value: 'dd.MM.yyyy'
                    }
                ]),
                events: META.events<DateBoxElement>(DateBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<DateBoxElement>(DateBoxElement.is, [
                    {
                        key: 'value',
                        value: new Date()
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'maxDropDownHeight',
                        value: 'auto'
                    },
                    {
                        key: 'dropDownDistance',
                        value: 8
                    },
                    {
                        key: 'dropDownPlacement',
                        value: Placement.Bottom
                    },
                    {
                        key: 'dropDownSkidding',
                        value: 0
                    },
                    {
                        key: 'dropDownStrategy',
                        value: Strategy.Absolute
                    },
                    {
                        key: 'isDropDownOpen',
                        value: false
                    },
                    {
                        key: 'isClearable',
                        value: false
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'dropDownStaysOpen',
                        value: false
                    },
                    {
                        key: 'label',
                        value: 'Label'
                    },
                    {
                        key: 'placeholder',
                        value: 'Type something...'
                    },
                    {
                        key: 'values',
                        value: []
                    },
                    {
                        key: 'blackoutDates',
                        value: blackoutDates
                    },
                    {
                        key: 'specialDates',
                        value: specialDates
                    },
                    {
                        key: 'selectionMode',
                        value: SelectionMode.Single
                    },
                    {
                        key: 'view',
                        value: CalendarView.Day
                    },
                    {
                        key: 'firstDayOfWeek',
                        value: DayOfWeek.Sunday
                    },
                    {
                        key: 'isTodayHighlighted',
                        value: true
                    },
                    {
                        key: 'isWeekendHighlighted',
                        value: true
                    },
                    {
                        key: 'showWeekNumbers',
                        value: false
                    },
                    {
                        key: 'showAdjacent',
                        value: true
                    },
                    {
                        key: 'markerHandler',
                        value: markerHandler
                    },
                    {
                        key: 'locale',
                        value: 'en-US'
                    },
                    {
                        key: 'format',
                        value: 'dd.MM.yyyy'
                    }
                ]), [
                    {
                        key: 'maxDropDownHeight',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'formatter',
                        template: functionTemplate
                    },
                    {
                        key: 'parser',
                        template: functionTemplate
                    },
                    {
                        key: 'value',
                        template: dateTemplate
                    },
                    {
                        key: 'blackoutDates',
                        template: arrayTemplate
                    },
                    {
                        key: 'displayDate',
                        template: dateTemplate
                    },
                    {
                        key: 'displayDateEnd',
                        template: dateTemplate
                    },
                    {
                        key: 'displayDateStart',
                        template: dateTemplate
                    },
                    {
                        key: 'markerHandler',
                        template: functionTemplate
                    },
                    {
                        key: 'maxDate',
                        template: dateTemplate
                    },
                    {
                        key: 'minDate',
                        template: dateTemplate
                    },
                    {
                        key: 'specialDates',
                        template: arrayTemplate
                    },
                    {
                        key: 'values',
                        template: arrayTemplate
                    }
                ] as Array<IPlaygroundProperty<DateBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                dateboxElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-datebox-sample': DateBoxSampleElement;
    }
}
