//#region Imports

import { Animate, Appearance, Component, Inset, Orientation, QueryAll, TabElement, TabItemElement, TextFormatters, Variant, animate, css, html, translate, typography, type CSSResultGroup, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { CodeEditorElement } from '../../Components/CodeEditor/CodeEditorElement';
import { CodeEditorLanguages } from '../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../Components/CodeEditor/CodeEditorTheme';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

//#endregion

/**
 * The `{@link InstallationView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-installation-view',
    imports: [
        CodeEditorElement
    ]
})
export class InstallationView extends ViewBase {

    //#region Fields

    @QueryAll('mosaik-tab')
    private _tabs!: TabElement[];

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-installation-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    //#endregion

    //#region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.installation')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/installation'}"
                                        .label="${translate('loc.nav.installation')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.installation')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                  .gap="${'32px'}">
                        <mosaik-text .text="${translate('loc.installation.1.text')}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-text .text="${translate('loc.installation.2.text')}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-router-anchor ${typography('headline5')}
                                              .label="${translate('loc.installation.native.title')}"
                                              .ref="${'native'}"></mosaik-router-anchor>
                        <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                            <mosaik-tab-item .label="${'Npm'}"
                                             .value="${'npm'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'npm install @breadstone/mosaik-elements-foundation'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'Yarn'}"
                                             .value="${'yarn'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'yarn add @breadstone/mosaik-elements-foundation'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                        </mosaik-tab>

                        <mosaik-router-anchor ${typography('headline5')}
                                              .label="${translate('loc.installation.angular.title')}"
                                              .ref="${'angular'}"></mosaik-router-anchor>
                        <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                            <mosaik-tab-item .label="${'Npm'}"
                                             .value="${'npm'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'npm install @breadstone/mosaik-elements-angular'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'Yarn'}"
                                             .value="${'yarn'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'yarn add @breadstone/mosaik-elements-angular'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                        </mosaik-tab>
                        <mosaik-router-anchor ${typography('headline5')}
                                              .label="${translate('loc.installation.react.title')}"
                                              .ref="${'react'}"></mosaik-router-anchor>
                        <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                            <mosaik-tab-item .label="${'Npm'}"
                                             .value="${'npm'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'npm install @breadstone/mosaik-elements-react'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'Yarn'}"
                                             .value="${'yarn'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'yarn add @breadstone/mosaik-elements-react'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                        </mosaik-tab>
                        <mosaik-router-anchor ${typography('headline5')}
                                              .label="${translate('loc.installation.vue.title')}"
                                              .ref="${'vue'}"></mosaik-router-anchor>
                        <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                            <mosaik-tab-item .label="${'Npm'}"
                                             .value="${'npm'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'npm install @breadstone/mosaik-elements-vue'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                            <mosaik-tab-item .label="${'Yarn'}"
                                             .value="${'yarn'}"
                                             @selectionChanged="${(e: CustomEvent) => this.onTabSelectionChanged(e)}">
                                <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                                 .text="${'yarn add @breadstone/mosaik-elements-vue'}"
                                                 .readonly="${true}"></app-code-editor>
                            </mosaik-tab-item>
                        </mosaik-tab>
                        <mosaik-text .text="${translate('loc.installation.3.text')}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <app-code-editor .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                         .language="${CodeEditorLanguages.TYPESCRIPT}"
                                         .text="${"import * from '@breadstone/mosaik-elements-foundation';"}"
                                         .readonly="${true}"></app-code-editor>
                        <mosaik-text .text="${translate('loc.installation.4.text')}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                        <mosaik-text .text="${translate('loc.installation.5.text')}"
                                     .formatter="${TextFormatters.RICHTEXT}"
                                     ?wrap="${true}"></mosaik-text>
                    </mosaik-stack>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    private onTabSelectionChanged(event: CustomEvent): void {
        const tab = event.target as TabItemElement;

        if (tab.isSelected) {
            this._tabs.forEach(x => {
                x.selectByValue(tab.value);
            });
        }
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-installation-view': InstallationView;
    }
}

