import { Animate, Appearance, Fit, HorizontalAlignment, IconPosition, Inset, Orientation, Size, SkeletonShape, Spacing, TextFormatters, Variant, VerticalAlignment, animate, flex, html, interpolate, repeat, translate, typography, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import { EVENT_FILTERS, type EventsView } from './EventsView';

/**
 * The template of the {@link EventsView}.
 *
 * @public
 */
export function eventsViewTemplate<T extends EventsView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.events')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/events'}"
                                        .label="${translate('loc.nav.events')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>
            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.events.title')}"
                                      .ref="${'events'}"></mosaik-router-anchor>
            </mosaik-page-content>
            <mosaik-page-content>
                <mosaik-spacer .thickness="${Spacing.All}"
                               .size="${Size.Giant}">
                    <mosaik-stack .orientation="${Orientation.Vertical}"
                                  .verticalAlignment="${VerticalAlignment.Center}"
                                  .horizontalAlignment="${HorizontalAlignment.Center}">
                        <mosaik-box style="--box-background-color: var(--theme-semi-transparent-color);"
                                    ${flex({ justify: 'center' })}>
                            <mosaik-button-group .appearance="${Appearance.Solid}"
                                                .variant="${Variant.Primary}">
                                ${repeat(EVENT_FILTERS, (x) => x, (x) => html`
                                <mosaik-toggle-button .label="${translate(`loc.events.filter.${x}`)}"
                                                    ?is-active="${e.filter === x}"
                                                    @click="${() => e.onFilter(x)}"></mosaik-toggle-button>
                                `)}
                                </mosaik-button-group>
                        </mosaik-box>
                    </mosaik-stack>
                </mosaik-spacer>
            </mosaik-page-content>
            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-stack .orientation="${Orientation.Vertical}"
                              .gap="${'64px'}">
                    ${repeat(e.events, (x) => x.title, (x, i) => html`
                    <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                  .gap="${'32px'}"
                                  ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 * i } }
    })}>
                        <mosaik-image .src="${x.image ?? ''}"
                                      .alt="${(x.title as any)[e.lang]}"
                                      .width="${'152px'}"
                                      .height="${'152px'}"
                                      .showEmpty="${true}">
                            <mosaik-stack slot="empty"
                                          .fit="${Fit.Both}"
                                          .verticalAlignment="${VerticalAlignment.Center}"
                                          .horizontalAlignment="${HorizontalAlignment.Center}"
                                          style="${'background: var(--theme-semi-transparent-color);'}">
                                <mosaik-icon .data="${'M0 12v-8.646l10-1.355v10.001h-10zm11 0h13v-12l-13 1.807v10.193zm-1 1h-10v7.646l10 1.355v-9.001zm1 0v9.194l13 1.806v-11h-13z'}"
                                             .size="${Size.Large}"></mosaik-icon>
                            </mosaik-stack>
                        </mosaik-image>
                        <mosaik-stack .orientation="${Orientation.Vertical}"
                                      .gap="${'8px'}">
                            <mosaik-text ${typography('subtitle1')}
                                         .text="${(x.title as any)[e.lang]}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-text ${typography('body1')}
                                         .text="${(x.description as any)[e.lang]}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                          .verticalAlignment="${VerticalAlignment.Center}"
                                          .gap="${'4px'}">
                                <mosaik-icon .size="${Size.Tiny}"
                                             .readonly="${true}"
                                             .data="${'M21 8.5v9.25A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V8.5h18ZM17.75 3A3.25 3.25 0 0 1 21 6.25V7H3v-.75A3.25 3.25 0 0 1 6.25 3h11.5Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')}
                                             .text="${interpolate('{0} - {1}', new Date(x.startDate).toLocaleDateString(), new Date(x.endDate).toLocaleDateString())}"
                                             .readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack .orientation="${Orientation.Horizontal}"
                                          .verticalAlignment="${VerticalAlignment.Center}"
                                          .gap="${'4px'}">
                                <mosaik-icon .size="${Size.Tiny}"
                                             .readonly="${true}"
                                             .data="${'m18.157 16.882-1.187 1.174c-.875.858-2.01 1.962-3.406 3.312a2.25 2.25 0 0 1-3.128 0l-3.491-3.396c-.439-.431-.806-.794-1.102-1.09a8.707 8.707 0 1 1 12.314 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z'}"></mosaik-icon>
                                <mosaik-text ${typography('body2')}
                                             .text="${x.calendar.location ?? ''}"
                                             readonly="${true}"></mosaik-text>
                            </mosaik-stack>
                            ${when(x.link.trim(), () => html`
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .href="${x.link ?? ''}" .label="${translate('loc.events.event.more')}" .variant="${Variant.Primary}"
                                           .icon="${'M6.25 4.75a1.5 1.5 0 0 0-1.5 1.5v11.5a1.5 1.5 0 0 0 1.5 1.5h11.5a1.5 1.5 0 0 0 1.5-1.5v-4a1 1 0 1 1 2 0v4a3.5 3.5 0 0 1-3.5 3.5H6.25a3.5 3.5 0 0 1-3.5-3.5V6.25a3.5 3.5 0 0 1 3.5-3.5h4a1 1 0 1 1 0 2h-4Zm6.5-1a1 1 0 0 1 1-1h6.5a1 1 0 0 1 1 1v6.5a1 1 0 1 1-2 0V6.164l-4.793 4.793a1 1 0 1 1-1.414-1.414l4.793-4.793H13.75a1 1 0 0 1-1-1Z'}"
                                           .iconPosition="${IconPosition.After}"
                                           .iconSize="${Size.Small}"></mosaik-button>
                            </mosaik-stack>
                            `)}
                        </mosaik-stack>
                    </mosaik-stack>
                    ${when(i < e.events.length - 1, () => html`
                    <mosaik-divider ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 * i } }
    })}></mosaik-divider>
                    `)}
                    `)}
                    ${when(e.events.length === 0 && !e.isBusy, () => html`
                    <mosaik-empty-state .content="${translate('loc.events.empty', e.filter)}"
                                        .formatter="${TextFormatters.RICHTEXT}"></mosaik-empty-state>
                    `)}
                    ${when(e.isBusy, () => html`
                    <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                  .horizontalAlignment="${HorizontalAlignment.Center}"
                                  .orientation="${Orientation.Vertical}"
                                  .gap="${'16px'}"
                                  ${animate({
        ...Animate.slideInBottom,
        ...{ options: { delay: 200 } }
    })}>
                        <div style="width: 100%">
                            <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                          .gap="${'32px'}">
                                <mosaik-skeleton .width="${'152px'}"
                                                 .height="${'152px'}"
                                                 .shimmer="${true}"></mosaik-skeleton>
                                <mosaik-stack .orientation="${Orientation.Vertical}"
                                              .gap="${'8px'}">
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .width="${'50%'}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'40px'}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .shape="${SkeletonShape.rect}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                    <mosaik-skeleton .height="${'20px'}"
                                                     .shape="${SkeletonShape.rect}"
                                                     .shimmer="${true}"></mosaik-skeleton>
                                </mosaik-stack>
                            </mosaik-stack>
                        </div>
                    </mosaik-stack>
                    `)}
                </mosaik-stack>
            </mosaik-page-content>

            <mosaik-page-content part="newsletterContent"
                                 .inset="${[Inset.All]}"
                                 .fullWidth="${true}">
                <mosaik-spacer .thickness="${Spacing.All}"
                               .size="${Size.Large}">
                    <app-landing-newsletter-view .kind="${'events'}"></app-landing-newsletter-view>
                </mosaik-spacer>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
