//#region Imports

import { type IViewPort } from './IViewPort';

//#endregion

export const VIEWPORTS: Array<IViewPort> = [
    { name: 'None', width: '100%', height: '100%' },
    // mobile sizes (only iPhones)
    { name: 'iPhone 5/SE', width: '320px', height: '568px' },
    { name: 'iPhone 6/7/8', width: '375px', height: '667px' },
    { name: 'iPhone X', width: '375px', height: '812px' },
    { name: 'iPhone 13/14/15 Pro', width: '390px', height: '844px' },
    { name: 'iPhone 13/14/15 Pro Max', width: '428px', height: '926px' },
    // tablet sizes
    { name: 'iPad', width: '768px', height: '1024px' },
    { name: 'iPad Pro', width: '1024px', height: '1366px' },
];
