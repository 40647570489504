// #region Imports

import { Animate, Appearance, ElevatioWeight, Fit, HorizontalAlignment, Inset, Orientation, Size, Spacing, type TemplateResult, TextAlignment, VerticalAlignment, animate, html, perspective, repeat, ripple, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../Globals';
import type { AboutView } from './AboutView';

// #endregion

/**
 * The template of the {@link AboutElement}.
 *
 * @public
 */
export function aboutViewTemplate<T extends AboutView>(e: T): TemplateResult {
    return html`
        <mosaik-helmet>
            <title .text="${globals.name} - ${translate('loc.nav.about')}"></title>
        </mosaik-helmet>
        <mosaik-page breakpoint="md"
                     ${animate({ ...Animate.slideInBottom })}>
            <mosaik-page-pre-header .inset="${Inset.All}">
                <mosaik-breadcrumb ?wrap="${true}">
                    <mosaik-breadcrumb-item>
                        <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                    <mosaik-breadcrumb-item .isActive="${true}">
                        <mosaik-button .href="${'/about'}"
                                        .label="${translate('loc.nav.about')}"></mosaik-button>
                    </mosaik-breadcrumb-item>
                </mosaik-breadcrumb>
            </mosaik-page-pre-header>

            <mosaik-page-content part="intro"
                                 .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.about.title')}"
                                      .ref="${'about'}"></mosaik-router-anchor>
                <mosaik-text .text="${translate('loc.about.description', globals.name)}"
                             ?wrap="${true}"
                             ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200, duration: 200 } } })}></mosaik-text>
            </mosaik-page-content>

            <!-- <mosaik-page-content part="facts"
                                 .inset="${Inset.All}"
                                 .fullWidth="${true}">
                <mosaik-spacer .thickness="${Spacing.All}"
                               .size="${Size.Large}">
                    <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                          ${typography('headline4')}
                                          ${animate({ ...Animate.slideInBottom })}
                                          .label="${translate('loc.about.facts.title')}"
                                          .ref="${'facts'}"></mosaik-router-anchor>
                    <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
                    <mosaik-wrap .orientation="${Orientation.Horizontal}"
                                 .columns="${e.isMobile ? 2 : 4}"
                                 .gap="${'32px'}">
                        ${repeat(e.facts, (x) => x.key, (x, i) => html`
                        <mosaik-card .appearance="${Appearance.Soft}"
                                     .elevation="${ElevatioWeight.Regular}">
                            <mosaik-card-header>
                                <mosaik-card-title>
                                    <mosaik-number-counter
                                                        ${typography('headline2')}
                                                        .from="${0}"
                                                        .to="${Number(x.value)}"
                                                        .duration="${'4s'}"
                                                        .alignment="${TextAlignment.Center}"></mosaik-number-counter>
                                </mosaik-card-title>
                                <mosaik-card-sub-title>
                                    <mosaik-text .text="${x.key}"
                                                .alignment="${TextAlignment.Center}"></mosaik-text>
                                </mosaik-card-sub-title>
                            </mosaik-card-header>
                        </mosaik-card>
                        `)}
                    </mosaik-wrap>
                </mosaik-spacer>
            </mosaik-page-content> -->

            <mosaik-page-content part="members"
                                 .inset="${Inset.All}">
                <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                      ${typography('headline4')}
                                      ${animate({ ...Animate.slideInBottom })}
                                      .label="${translate('loc.about.team.title')}"
                                      .ref="${'team'}"></mosaik-router-anchor>
                <mosaik-text .text="${translate('loc.about.team.description', globals.name)}"
                             .wrap="${true}"></mosaik-text>
                <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
                <mosaik-wrap .orientation="${Orientation.Horizontal}"
                             .columns="${e.isMobile ? 1 : 3}"
                             .gap="${'32px'}">
                    ${repeat(e.members, (x) => x.name, (x, i) => html`
                    <mosaik-router-link .href="${'/about/member'}"
                                        .query="${{ id: x.id }}"
                                        style="display: inline;">
                        <mosaik-card .header="${x.name}"
                                        .subHeader="${x.role}"
                                        .appearance="${Appearance.Soft}"
                                        .elevation="${ElevatioWeight.Regular}"
                                        ${animate({ ...Animate.slideInBottom, ...{ options: { delay: 200 * i } } })}
                                        ${perspective({ duration: 200 })}
                                        ${ripple({})}>
                            <mosaik-image slot="media"
                                          .src="${x.image}"
                                          .height="${'280px'}"
                                          ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 * i, duration: 200 } } })}></mosaik-image>
                        </mosaik-card>
                    </mosaik-router-link>
                    `)}
                    <mosaik-card .header="${translate('loc.about.team.newMember.name')}"
                                 .subHeader="${translate('loc.about.team.newMember.role')}"
                                 .appearance="${Appearance.Soft}"
                                 .elevation="${ElevatioWeight.Regular}"
                                 ${animate({ ...Animate.slideInBottom, ...{ options: { delay: 200 * e.members.length } } })}
                                 ${perspective({ duration: 200 })}>
                        <mosaik-box slot="media"
                                    part="unknown"
                                    .fit="${Fit.Both}"
                                    .height="${'280px'}"
                                    .cornerd="${true}">
                            <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                          .horizontalAlignment="${HorizontalAlignment.Center}"
                                          .fit="${Fit.Both}">
                                <mosaik-icon .size="${Size.Giant}"
                                             .data="${'M12 4C9.238 4 7 6.238 7 9a1 1 0 0 0 2 0c0-1.658 1.342-3 3-3s3 1.342 3 3c0 .816-.199 1.294-.438 1.629-.262.365-.625.638-1.128.985l-.116.078c-.447.306-1.023.699-1.469 1.247-.527.648-.849 1.467-.849 2.561v.5a1 1 0 1 0 2 0v-.5c0-.656.178-1.024.4-1.299.257-.314.603-.552 1.114-.903l.053-.037c.496-.34 1.133-.786 1.62-1.468C16.7 11.081 17 10.183 17 9c0-2.762-2.238-5-5-5ZM12 21.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z'}"
                                             ${animate({ ...Animate.slideInTop, ...{ options: { delay: 200 * e.members.length, duration: 200 } } })}>
                                </mosaik-icon>
                            </mosaik-stack>
                        </mosaik-box>
                    </mosaik-card>
                </mosaik-wrap>
            </mosaik-page-content>
            <mosaik-page-content>
                <mosaik-spacer .thickness="${Spacing.All}"
                                .size="${Size.Large}">
                    <app-landing-contact-view></app-landing-contact-view>
                </mosaik-spacer>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
