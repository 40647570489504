//#region Imports

import { ChartDisplayType, ChartElement, ChartLegendPosition, Component, Fit, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { chartElementDocs } from '../../../.Generated/mosaik-chart';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link RadialBarChartSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-radial-bar-chart-sample'
})
export class RadialBarChartSampleElement extends SampleBaseElement<ChartElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChartElement> {
        return {
            header: 'RadialBar Chart',
            description: META.description(ChartElement.is),
            graph: META.graph(ChartElement.is),
            playground: {
                template: html`
                    <mosaik-chart></mosaik-chart>
                `,
                properties: META.properties<ChartElement>(ChartElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'variant', value: Variant.Default },
                    { key: 'display', value: ChartDisplayType.RadialBar },
                    { key: 'fit', value: Fit.None },
                    { key: 'width', value: '400px' },
                    { key: 'height', value: '300px' },
                    { key: 'isLegendVisible', value: true },
                    { key: 'legendPosition', value: ChartLegendPosition.Bottom },
                    { key: 'isXAxisVisible', value: true },
                    { key: 'isYAxisVisible', value: true },
                    { key: 'annotations', value: null },
                    { key: 'colors', value: null },
                    { key: 'chart', value: null },
                    { key: 'dataLabels', value: null },
                    { key: 'series', value: [76, 67, 61, 90] },
                    { key: 'stroke', value: null },
                    { key: 'labels', value: null },
                    {
                        key: 'legend', value: {
                            show: true,
                            floating: true,
                            fontSize: '16px',
                            position: 'left',
                            offsetX: 160,
                            offsetY: 15,
                            labels: {
                                useSeriesColors: true,
                            },
                            // markers: {
                            //     size: 0
                            // },
                            formatter: (seriesName: string, opts: any): string => `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`,
                            itemMargin: {
                                vertical: 3
                            }
                        }
                    },
                    { key: 'markers', value: null },
                    { key: 'noData', value: null },
                    { key: 'fill', value: null },
                    { key: 'tooltip', value: null },
                    {
                        key: 'plotOptions', value: {
                            radialBar: {
                                offsetY: 0,
                                startAngle: 0,
                                endAngle: 270,
                                hollow: {
                                    margin: 5,
                                    size: '30%',
                                    background: 'transparent',
                                    image: undefined,
                                },
                                dataLabels: {
                                    name: {
                                        show: false,
                                    },
                                    value: {
                                        show: false,
                                    }
                                }
                            }
                        }
                    },
                    { key: 'responsive', value: null },
                    {
                        key: 'xaxis', value: null
                    },
                    {
                        key: 'yaxis', value: null
                    },
                    { key: 'grid', value: null },
                    { key: 'states', value: null },
                    { key: 'header', value: 'Header' },
                    { key: 'subHeader', value: 'Subheader' },
                    { key: 'theme', value: null },
                ]),
                events: META.events(ChartElement.is),
            },
            propertyGrid: {
                properties: merge(META.properties<ChartElement>(ChartElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'variant', value: Variant.Default },
                    { key: 'display', value: ChartDisplayType.RadialBar },
                    { key: 'fit', value: Fit.None },
                    { key: 'header', value: 'Header' },
                    { key: 'subHeader', value: 'Subheader' },
                    { key: 'width', value: '400px' },
                    { key: 'height', value: '300px' },
                    { key: 'isLegendVisible', value: true },
                    { key: 'legendPosition', value: ChartLegendPosition.Bottom },
                    { key: 'isXAxisVisible', value: true },
                    { key: 'isYAxisVisible', value: true },
                ]), [
                    { key: 'width', template: cssLengtTemplate },
                    { key: 'height', template: cssLengtTemplate },
                ] as Array<IPlaygroundProperty<ChartElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                chartElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-radial-bar-chart-sample': RadialBarChartSampleElement;
    }
}

