// #region Imports

import { Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IPostResponse } from '../../Backend/Api/Models/IPostResponse';
import { BlogService } from '../../Backend/Api/Services/BlogService';
import { CouponElement } from '../../Components/Coupon/CouponElement';
import { AppointmentService } from '../../Services/AppointmentService';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { blogViewStyle } from './BlogViewStyle';
import { blogViewTemplate } from './BlogViewTemplate';

// #endregion

/**
 * The `{@link BlogView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-blog-view',
    template: blogViewTemplate,
    styles: [
        viewBaseStyle(),
        blogViewStyle()
    ],
    imports: [
        CouponElement
    ],
    providers: [
        {
            provide: AppointmentService,
            useClass: AppointmentService
        },
        {
            provide: BlogService,
            useClass: BlogService
        }
    ]
})
export class BlogView extends ViewBase {

    // #region Fields

    @Inject(BlogService)
    private readonly _blogService!: BlogService;

    private _posts: Array<IPostResponse>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._posts = new Array<IPostResponse>();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-blog-view';
    }

    /**
     * Gets or sets the `blog` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Array })
    public get posts(): Array<IPostResponse> {
        return this._posts;
    }

    private set posts(value: Array<IPostResponse>) {
        if (this._posts !== value) {
            this._posts = value;
            this.requestUpdate('posts');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        this.isBusy = true;

        void this._blogService.getPosts()
            .then((x) => {
                this.posts = x;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-blog-view': BlogView;
    }

}
