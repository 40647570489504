// #region Imports

import { ElementIntlBase } from '@breadstone/mosaik-elements-foundation';

// #endregion

/**
 * The `CaptchaElementIntl` class.
 *
 * @public
 */
export class CaptchaElementIntl extends ElementIntlBase {

    // #region Fields

    private _question: string;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CaptchaElementIntl` class.
     *
     * @public
     */
    public constructor(locale?: string) {
        super(locale);

        this._question = 'What is {0} + {1}?';
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `question` property.
     *
     * @public
     */
    public get question(): string {
        return this._question;
    }

    public set question(value: string) {
        this._question = value;
    }

    // #endregion

}
