// #region Imports

import { Component, FlowDirection, Orientation, Slider2Element, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { sliderElementDocs } from '../../.Generated/mosaik-slider';
import { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { numberTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link Slider2SampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-slider2-sample',
    imports: [
        Slider2Element
    ]
})
export class Slider2SampleElement extends SampleBaseElement<Slider2Element> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<Slider2Element> {

        return {
            header: 'Slider',
            description: META.description(Slider2Element.is),
            graph: META.graph(Slider2Element.is),
            playground: {
                template: html`
                    <mosaik-slider2>
                        <mosaik-slider2-thumb .value="${0}"></mosaik-slider2-thumb>
                        <mosaik-slider2-thumb .value="${50}"></mosaik-slider2-thumb>
                    </mosaik-slider2>
                `,
                properties: META.properties<Slider2Element>(Slider2Element.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'value',
                        value: 50
                    },
                    {
                        key: 'max',
                        value: 100
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<Slider2Element>(Slider2Element.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'value',
                        value: 50
                    },
                    {
                        key: 'max',
                        value: 100
                    },
                    {
                        key: 'min',
                        value: 0
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    }
                ]), [
                    {
                        key: 'min',
                        template: numberTemplate
                    },
                    {
                        key: 'max',
                        template: numberTemplate
                    }
                ] as Array<IPlaygroundProperty<Slider2Element>>, (a, b) => a.key === b.key)
            },
            docs: [
                sliderElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-slider2-sample': Slider2SampleElement;
    }
}
