// #region Imports

import { Animate, Component, Inset, TextFormatters, Variant, animate, interpolate, translate } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

/**
 * The `{@link LayoutView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-layout-view'
})
export class LayoutView extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-layout-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${translate('loc.nav.layout')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}"
                                           .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/layout'}"
                                           .label="${translate('loc.nav.layout')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.layout')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${interpolate('The *{0}* Component Library represents a groundbreaking approach to web development, distinctively different from traditional CSS frameworks. While conventional frameworks rely on an array of CSS classes, *{0}* harnesses modular and semantically clear Lit-Element components. This methodology offers numerous advantages, appealing to both beginners and seasoned developers alike.', globals.name)}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${interpolate('Why *{0}* Outshines Traditional CSS Frameworks:', globals.name)}"></mosaik-text>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${'*Intuitive Semantics*: Instead of memorizing numerous CSS classes, Mosaik allows for clear, understandable markup, keeping code neat and maintainable.'}"></mosaik-text>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${'*Modularity*: Each component is a self-contained entity, easily reusable and deployable in various contexts.'}"></mosaik-text>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${'*Streamlined Design*: Mosaik cuts down on boilerplate code, allowing developers to focus on what truly matters: creating awe-inspiring websites'}"></mosaik-text>
                    <mosaik-text ?wrap="${true}"
                                 .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${''}"></mosaik-text>
                </mosaik-page-content>
                <mosaik-page-content .inset="${Inset.All}">
                    <mosaik-wrap .columns="${2}"
                                         .gap="${'32px'}">
                        <mosaik-card>
                            <mosaik-card-header .text="${'Absolute'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Absolute positioning allows you to place an element exactly where you want it to be. This is useful for creating overlays, tooltips, or modals.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/absolute'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'Grid'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Grid layout is a two-dimensional layout system that allows you to create complex layouts with rows and columns. This is useful for creating responsive designs with multiple sections.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/grid'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'Masonry'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Masonry layout is a layout that arranges elements in a grid with variable column sizes. This is useful for creating a Pinterest-style grid or a photo gallery.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/masonry'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'Split'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Split layout is a layout that divides the screen into two or more sections. This is useful for creating a sidebar, a footer, or a multi-column layout.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/split'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'Stack'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Stack layout is a layout that stacks elements vertically or horizontally. This is useful for creating a list, a menu, or a form.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/stack'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'TileList'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'TileList layout is a layout that displays items in a grid with equal spacing between them. This is useful for creating a gallery or a product list.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/tilelist'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                        <mosaik-card>
                            <mosaik-card-header .text="${'Wrap'}"></mosaik-card-header>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Wrap layout is a layout that wraps elements to the next line when there is not enough space. This is useful for creating a tag list, a menu, or a form.'}"
                                             .wrap="${true}"></mosaik-text>
                                <mosaik-router-link .href="${'/components/wrap'}">
                                    <mosaik-anchor .label="${'Learn more'}"
                                                   .variant="${Variant.Primary}"></mosaik-anchor>
                                </mosaik-router-link>
                            </mosaik-card-content>
                        </mosaik-card>
                    </mosaik-wrap>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-layout-view': LayoutView;
    }
}
