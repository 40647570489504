// #region Imports

import { Attribute, Component, CustomElement, IntlController, Property } from '@breadstone/mosaik-elements-foundation';
import type { CssCustomProperty, CssPart, Attribute as MetaAttribute, ClassField as MetaField, ClassMethod as MetaMethod, Slot as MetaSlot, Event as MetasEvent } from 'custom-elements-manifest/schema';
import { apiDocsLayoutElementStyle } from './ApiDocsLayoutElementStyle';
import { apiDocsLayoutElementTemplate } from './ApiDocsLayoutElementTemplate';
import { ApiDocsLayoutElementIntl } from './Intl/ApiDocsLayoutElementIntl';

// #endregion

/**
 * Represents the `{@link IApiDocsLayoutElementProps}` interface.
 *
 * @public
 */
export interface IApiDocsLayoutElementProps {

    name: string;
    properties: Array<MetaField>;
    attrs: Array<MetaAttribute>;
    methods: Array<MetaMethod>;
    events: Array<MetasEvent>;
    slots: Array<MetaSlot>;
    cssParts: Array<CssPart>;
    cssProperties: Array<CssCustomProperty>;

}

/**
 * The `{@link ApiDocsLayoutElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-api-docs-layout',
    template: apiDocsLayoutElementTemplate,
    styles: apiDocsLayoutElementStyle,
    imports: []
})
export class ApiDocsLayoutElement extends CustomElement implements IApiDocsLayoutElementProps {

    // #region Fields

    private readonly _intl: IntlController<ApiDocsLayoutElementIntl>;
    private _name: string;
    private _properties: Array<MetaField>;
    private _attrs: Array<MetaAttribute>;
    private _methods: Array<MetaMethod>;
    private _events: Array<MetasEvent>;
    private _slots: Array<MetaSlot>;
    private _cssParts: Array<CssPart>;
    private _cssProperties: Array<CssCustomProperty>;
    private _isMobile: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._name = '';
        this._properties = [];
        this._attrs = [];
        this._methods = [];
        this._events = [];
        this._slots = [];
        this._cssParts = [];
        this._cssProperties = [];
        this._isMobile = false;
        this._intl = new IntlController<ApiDocsLayoutElementIntl>(this, {
            factory: () => new ApiDocsLayoutElementIntl()
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-api-docs-layout';
    }

    /**
     * Gets or sets the `name` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        if (this._name !== value) {
            this._name = value;
            this.requestUpdate('name');
        }
    }

    /**
     * Gets or sets the `properties` property.
     *
     * @public
     */
    @Property({ type: Array<MetaField> })
    public get properties(): Array<MetaField> {
        return this._properties;
    }

    public set properties(value: Array<MetaField>) {
        if (this._properties !== value) {
            this._properties = value;
            this.requestUpdate('properties');
        }
    }

    /**
     * Gets or sets the `attributes` property.
     *
     * @public
     */
    @Property({ type: Array<MetaAttribute> })
    public get attrs(): Array<MetaAttribute> {
        return this._attrs;
    }

    public set attrs(value: Array<MetaAttribute>) {
        if (this._attrs !== value) {
            this._attrs = value;
            this.requestUpdate('attributes');
        }
    }

    /**
     * Gets or sets the `methods` property.
     *
     * @public
     */
    @Property({ type: Array<MetaMethod> })
    public get methods(): Array<MetaMethod> {
        return this._methods;
    }

    public set methods(value: Array<MetaMethod>) {
        if (this._methods !== value) {
            this._methods = value;
            this.requestUpdate('methods');
        }
    }

    /**
     * Gets or sets the `events` property.
     *
     * @public
     */
    @Property({ type: Array<MetasEvent> })
    public get events(): Array<MetasEvent> {
        return this._events;
    }

    public set events(value: Array<MetasEvent>) {
        if (this._events !== value) {
            this._events = value;
            this.requestUpdate('events');
        }
    }

    /**
     * Gets or sets the `slots` property.
     *
     * @public
     */
    @Property({ type: Array<MetaSlot> })
    public get slots(): Array<MetaSlot> {
        return this._slots;
    }

    public set slots(value: Array<MetaSlot>) {
        if (this._slots !== value) {
            this._slots = value;
            this.requestUpdate('slots');
        }
    }

    /**
     * Gets or sets the `cssParts` property.
     *
     * @public
     */
    @Property({ type: Array<CssPart> })
    public get cssParts(): Array<CssPart> {
        return this._cssParts;
    }

    public set cssParts(value: Array<CssPart>) {
        if (this._cssParts !== value) {
            this._cssParts = value;
            this.requestUpdate('cssParts');
        }
    }

    /**
     * Gets or sets the `cssProperties` property.
     *
     * @public
     */
    @Property({ type: Array<CssCustomProperty> })
    public get cssProperties(): Array<CssCustomProperty> {
        return this._cssProperties;
    }

    public set cssProperties(value: Array<CssCustomProperty>) {
        if (this._cssProperties !== value) {
            this._cssProperties = value;
            this.requestUpdate('cssProperties');
        }
    }

    /**
     * Gets or sets the `isMobile` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isMobile(): boolean {
        return this._isMobile;
    }

    public set isMobile(value: boolean) {
        if (this._isMobile !== value) {
            this._isMobile = value;
            this.requestUpdate('isMobile');
        }
    }

    /**
     * Returns the `intl` property.
     *
     * @public
     * @readonly
     */
    public get intl(): ApiDocsLayoutElementIntl {
        return this._intl.intl;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public onBreakpointMatched(match: boolean): void {
        this.isMobile = match;
        match ? this.classList.add('lt-sm') : this.classList.remove('lt-sm');
    }

    // #endregion

}

declare global {
    interface HTMLElementTagNameMap {
        'app-api-docs-layout': ApiDocsLayoutElement;
    }
}
