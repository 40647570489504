//#region Imports

import { Component, Fit, FlowDirection, Orientation, SplitElement } from '@breadstone/mosaik-elements-foundation';
import { SplitLock } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/SplitLock';
import { type CSSResultGroup } from 'lit';
import { splitElementDocs } from '../../../.Generated/mosaik-split';
import { arrayTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { SplitSampleTemplates } from './SplitSample.Templates';

//#endregion

/**
 * The `{@link SplitSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-split-sample',
    imports: [
        SplitElement
    ]
})
export class SplitSampleElement extends SampleBaseElement<SplitElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SplitElement> {
        return {
            header: 'Split',
            description: META.description(SplitElement.is),
            graph: META.graph(SplitElement.is),
            playground: {
                template: SplitSampleTemplates.defaultTemplate(),
                properties: META.properties<SplitElement>(SplitElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'fit', value: Fit.Both },
                    { key: 'position', value: 50 },
                    // { key: 'positionInPixels', value: 0 },
                    { key: 'lock', value: SplitLock.None },
                    { key: 'snaps', value: ['30%', '50%'] },
                    { key: 'snapThreshold', value: 12 },
                    { key: 'thickness', value: 2 }
                ]),
                events: META.events(SplitElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<SplitElement>(SplitElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'fit', value: Fit.Both },
                    { key: 'position', value: 50 },
                    // { key: 'positionInPixels', value: 0 },
                    { key: 'lock', value: SplitLock.None },
                    { key: 'snaps', value: ['30%', '50%'] },
                    { key: 'snapThreshold', value: 12 },
                    { key: 'thickness', value: 2 }
                ]), [
                    { key: 'snaps', value: ['30%', '50%'], template: arrayTemplate },
                ], (a, b) => a.key === b.key)
            },
            docs: [
                splitElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-split-sample': SplitSampleElement;
    }
}

