// #region Imports

import { Appearance, AvatarGroupElement, Component, FlowDirection, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { avatarGroupElementDocs } from '../../../.Generated/mosaik-avatar-group';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link AvatarGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-avatar-group-sample'
})
export class AvatarGroupSampleElement extends SampleBaseElement<AvatarGroupElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<AvatarGroupElement> {

        return {
            header: 'Avatar Group',
            description: META.description(AvatarGroupElement.is),
            graph: META.graph(AvatarGroupElement.is),
            playground: {
                template: html`
                    <mosaik-avatar-group>
                        <mosaik-avatar .text="${'RK'}"></mosaik-avatar>
                        <mosaik-avatar .text="${'AW'}"></mosaik-avatar>
                        <mosaik-avatar .text="${'CS'}"></mosaik-avatar>
                        <mosaik-avatar .text="${'CK'}"></mosaik-avatar>
                        <mosaik-avatar .text="${'NK'}"></mosaik-avatar>
                        <mosaik-avatar .text="${'SK'}"></mosaik-avatar>
                    </mosaik-avatar-group>
                `,
                properties: META.properties<AvatarGroupElement>(AvatarGroupElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'maxLength',
                        value: 3
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]),
                events: META.events(AvatarGroupElement.is)
            },
            propertyGrid: {
                properties: META.properties<AvatarGroupElement>(AvatarGroupElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'maxLength',
                        value: 3
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ])
            },
            docs: [
                avatarGroupElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-avatar-group-sample': AvatarGroupSampleElement;
    }
}
