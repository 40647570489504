// #region Imports

import { Appearance, html, Orientation, repeat, Size, Spacing, TextFormatters, typography, Variant, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import { COMPONENTS } from '../../../Services/ComponentsService';
import type { PricingView } from './PricingView';

// #endregion

/**
 * The template of the {@link PricingView}.
 *
 * @public
 */
export function pricingViewTemplate<T extends PricingView>(e: T): TemplateResult {
    return html`
            <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                                  ${typography('headline4')}
                                  .label="${'Pricing'}"
                                  .ref="${'pricing'}"></mosaik-router-anchor>
            <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
            <mosaik-stack class="pricing-tables"
                          .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}">
                          <!-- 899€ -->
                <app-pricing-table class="standard ${e.isMobile ? 'mobile' : ''}"
                                   .header="${'Standard'}"
                                   .variant="${Variant.Gray}"
                                   .price="${'N/A'}"
                                   .priceInterval="${'Year'}"
                                   @click="${() => e.onSelect('price_1Q8ECkP26IL1ACP7g6Q0GIRh')}">
                    <app-pricing-table-row .text="${'Basic display components for text, icons, avatars and more.'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'E-mail support'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Regular updates'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Standard customizations'}"
                                           .available="${true}"></app-pricing-table-row>
                </app-pricing-table>
                <!-- 1499€ -->
                <app-pricing-table class="premium ${e.isMobile ? 'mobile' : ''}"
                                   .header="${'Premium'}"
                                   .variant="${Variant.Primary}"
                                   .price="${'N/A'}"
                                   .priceInterval="${'Year'}"
                                   .ribbon="${'most popular'}"
                                   @click="${() => e.onSelect('premium')}">
                    <app-pricing-table-row .text="${'Standard components plus editable components such as text fields, selection menus and forms.'}"
                                           ?available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Prioritized e-mail support'}"
                                           ?available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Regular updates + beta access'}"
                                           ?available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Advanced customizations'}"
                                           ?available="${true}"></app-pricing-table-row>
                </app-pricing-table>
                <!-- 2499€ -->
                <app-pricing-table class="ultimate ${e.isMobile ? 'mobile' : ''}"
                                   .header="${'Ultimate'}"
                                   .variant="${Variant.Gray}"
                                   .price="${'N/A'}"
                                   .priceInterval="${'Year'}"
                                   @click="${() => e.onSelect('ultimate')}">
                    <app-pricing-table-row .text="${'Premium components plus extended chart components for data visualization.'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Prioritized telephone and e-mail support'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Regular updates + beta access'}"
                                           .available="${true}"></app-pricing-table-row>
                    <app-pricing-table-row .text="${'Complete customizations'}"
                                           .available="${true}"></app-pricing-table-row>
                </app-pricing-table>
            </mosaik-stack>

            ${when(e.isDetailed, () => html`
            <mosaik-spacer .thickness="${Spacing.Top}"
                           .size="${Size.Large}"></mosaik-spacer>
            <mosaik-table>
                <mosaik-table-header>
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${'Included'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Standard'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Premium'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Ultimate'}"></mosaik-table-cell>
                    </mosaik-table-row>
                </mosaik-table-header>
                <mosaik-table-body>
                    ${repeat(COMPONENTS.getComponents(), (x) => html`
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${x.name}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            ${when(x.edition?.includes('standard'), () => html`
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                            `)}
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            ${when(x.edition?.includes('premium'), () => html`
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                            `)}
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            ${when(x.edition?.includes('ultimate'), () => html`
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                            `)}
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    `)}
                </mosaik-table-body>
                <mosaik-table-header>
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${'SUPPORT SERVICES'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Lite Support'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Priority Support'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Ultimate Support'}"></mosaik-table-cell>
                    </mosaik-table-row>
                </mosaik-table-header>
                <mosaik-table-body>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Guaranteed response time'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'*72* hours'}"
                                         .formatter="${TextFormatters.RICHTEXT}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'*24* hours'}"
                                         .formatter="${TextFormatters.RICHTEXT}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'*24* hours'}"
                                         .formatter="${TextFormatters.RICHTEXT}"
                                         ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Incidents'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'10 incidents'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Unlimited'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Unlimited'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Access to online support system'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Access to community forums'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Access to industry-leading online knowledge'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Bug reports and feature requests'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Unlimited'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Unlimited'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Unlimited'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Phone support'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Remote web assistance'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Ticket pre-screening'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                    <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Issue escalation'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row>
                </mosaik-table-body>
                <mosaik-table-header>
                    <mosaik-table-row>
                        <mosaik-table-cell .text="${'PRODUCT TRAINING'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Lite Support'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Priority Support'}"></mosaik-table-cell>
                        <mosaik-table-cell .text="${'Ultimate Support'}"></mosaik-table-cell>
                    </mosaik-table-row>
                </mosaik-table-header>
                <mosaik-table-body>
                    <!-- <mosaik-table-row>
                        <mosaik-table-cell>
                            <mosaik-text .text="${'Issue escalation'}" ?wrap="${true}"></mosaik-text>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                        <mosaik-table-cell>
                            <mosaik-icon .data="${'m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z'}"
                                         .size="${Size.Small}"
                                         .appearance="${Appearance.Plain}"
                                         .variant="${Variant.Primary}"></mosaik-icon>
                        </mosaik-table-cell>
                    </mosaik-table-row> -->
                </mosaik-table-body>
            </mosaik-table>
            `)}
            <mosaik-spacer .thickness="${Spacing.Top}"
                           .size="${Size.Large}"></mosaik-spacer>
            <mosaik-toggle-button .label="${!e.isDetailed ? 'See Detailed Comperison' : 'Hide Detailed Comperison'}"
                                  @click="${() => e.onToggleDetails()}"></mosaik-toggle-button>
        `;
}
