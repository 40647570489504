// #region Imports

import { Component, CustomElement, Property, css, html, type CSSResultGroup, type IConnectedCallback, type TemplateResult } from '@breadstone/mosaik-elements-foundation';

// #endregion

/**
 * Represents the `IJsonRendererElementProps` interface.
 *
 * @public
 */
export interface IJsonRendererElementProps {
    data: object;
}

/**
 * The `{@link JsonRendererElement}` element.
 *
 * @description
 * The `JsonRendererElement` is a custom web component designed to display JSON data in a readable, hierarchical format.
 *
 * @name Json Renderer
 * @element app-json-renderer
 * @category Display
 */
@Component({
    selector: 'app-json-renderer'
})
export class JsonRendererElement extends CustomElement implements IConnectedCallback {

    // #region Fields

    private _data: object;

    // #endregion

    // #region Ctor

    /**
     * Initializes a new instance of the `JsonRendererElement` class.
     *
     * @public
     */
    public constructor() {
        super();
        this._data = {};
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents the natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-json-renderer';
    }

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return css`
        /* :host {
            display: block;
            font-family: monospace;
            white-space: pre-wrap;
            background: #f9f9f9;
            border: 1px solid #ccc;
            padding: 1rem;
            border-radius: 0.5rem;
            overflow: auto;
        } */

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
        }
        li {
            padding: 0px;
            margin: 0px 0px 0px 16px;
        }
        `;
    }

    /**
     * Gets or sets the `data` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get data(): object {
        return this._data;
    }

    public set data(value: object) {
        if (this._data !== value) {
            this._data = value;
            this.requestUpdate('data');
        }
    }

    // #endregion

    // #region Methods

    /**
     * Called when the element is connected to the DOM.
     *
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
    }

    /**
     * Renders the component template.
     *
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
        <div>
            ${this.renderJson(this.data)}
        </div>
        `;
    }

    private renderJson(data: any): TemplateResult {
        if (typeof data === 'object' && data !== null) {
            return html`
        <ul>
          ${Object.entries(data).map(
                ([key, value]) => html`
                <li>
                  <strong>${key}:</strong>
                  ${this.renderJson(value)}
                </li>
              `
            )}
        </ul>
      `;
        }
        return html`<span>${data}</span>`;
    }

    /**
     * Formats JSON data as a readable string with indentation.
     *
     * @private
     * @param data - The JSON object to format.
     * @returns A formatted JSON string.
     */
    private formatJson(data: object): string {
        try {
            return JSON.stringify(data, null, 2);
        } catch (e) {
            console.error('Invalid JSON:', e);
            return 'Invalid JSON data';
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-json-renderer': JsonRendererElement;
    }
}
