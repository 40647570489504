// #region Imports

import { Injectable } from '@breadstone/mosaik-elements-foundation';
import data from '../Resources/Data/Components.json';

// #endregion

// #region Imports

// #endregion

/**
 * Represents the `IComponentInfo` interface.
 *
 * @public
 */
export interface IComponentInfo {
    name: string;
    description: string;
    tags?: Array<string>;
    path: string;
    category: string;
    internalName: string;
    experimental: boolean;
    edition?: Array<'standard' | 'premium' | 'ultimate'>;
}

/**
 * The `ComponentsService` class.
 *
 * @public
 */
@Injectable()
export class ComponentsService {

    // #region Ctor

    /**
     * Constructs a new instance of the `ComponentsService` class.
     *
     * @public
     */
    public constructor() {

    }

    // #endregion

    // #region Methods

    public getComponent(name: string): IComponentInfo | undefined {
        const result = data.find((x) => x.internalName.toLowerCase().includes(name.toLowerCase())) as IComponentInfo;
        return result;
    }

    public getComponents(filter?: string): Array<IComponentInfo> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return data.filter((x) => x.name.toLowerCase().includes((filter ?? '').toLowerCase()) || x.description.toLowerCase().includes((filter ?? '').toLowerCase()));
    }

    public getComponentsWithinGroups(filter?: string): Array<{
        key: string;
        items: Array<IComponentInfo>;
    }> {
        return groupBy(this.getComponents(filter), (x) => x.category).filter((x) => x.items.length > 0);
    }

    // #endregion

}

function groupBy<T, TK>(arr: Array<T>, keyFn: (item: T) => TK): Array<{
    key: TK;
    items: Array<T>;
}> {
    const groupsMap = new Map<TK, Array<T>>();

    // Group elements by key
    for (const item of arr) {
        const key = keyFn(item);
        const group = groupsMap.get(key);
        if (group) {
            group.push(item);
        } else {
            groupsMap.set(key, [item]);
        }
    }

    // Convert map to array of group objects
    const groups: Array<{
        key: TK;
        items: Array<T>;
    }> = [];
    for (const [key, items] of groupsMap.entries()) {
        groups.push({
            key,
            items
        });
    }

    return groups;
}

export const COMPONENTS = new ComponentsService();
