//#region Imports

import { Component, Fit, FlowDirection, Orientation, WrapElement } from '@breadstone/mosaik-elements-foundation';
import { type CSSResultGroup } from 'lit';
import { wrapElementDocs } from '../../../.Generated/mosaik-wrap';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { WrapSampleTemplates } from './WrapSample.Templates';

//#endregion

/**
 * The `{@link WrapSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-wrap-sample',
    imports: [
        WrapElement
    ]
})
export class WrapSampleElement extends SampleBaseElement<WrapElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<WrapElement> {
        return {
            header: 'Wrap',
            description: META.description(WrapElement.is),
            graph: META.graph(WrapElement.is),
            playground: {
                template: WrapSampleTemplates.defaultTemplate(),
                properties: META.properties<WrapElement>(WrapElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'fit', value: Fit.Both },
                    { key: 'columns', value: 2 },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'gap', value: '16px' },
                ]),
                events: META.events(WrapElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<WrapElement>(WrapElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'fit', value: Fit.Both },
                    { key: 'columns', value: 2 },
                    { key: 'orientation', value: Orientation.Horizontal },
                    { key: 'gap', value: '16px' },
                ]), [
                    { key: 'gap', template: cssLengtTemplate },
                ] as Array<IPlaygroundProperty<WrapElement>>, (a, b) => a.key === b.key), [
                    { key: 'items' },
                ] as Array<IPlaygroundProperty<WrapElement>>, (a, b) => a.key === b.key),
            },
            docs: [
                wrapElementDocs()
            ]
        };
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-wrap-sample': WrapSampleElement;
    }
}

