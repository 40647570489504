// #region Imports

import { Component, DividerElement, FlowDirection, Orientation } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { dividerElementDocs } from '../../.Generated/mosaik-divider';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DividerSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-divider-sample'
})
export class DividerSampleElement extends SampleBaseElement<DividerElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DividerElement> {
        return {
            header: 'Divider',
            description: META.description(DividerElement.is),
            graph: META.graph(DividerElement.is),
            playground: {
                template: (props) => html`
                    <mosaik-divider style="${props.get<Orientation>('orientation').value === Orientation.Horizontal ? 'width: 200px;' : 'height: 200px'}"></mosaik-divider>
                `,
                properties: META.properties<DividerElement>(DividerElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'thickness',
                        value: 1
                    }
                ]),
                events: META.events(DividerElement.is)
            },
            propertyGrid: {
                properties: META.properties<DividerElement>(DividerElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'thickness',
                        value: 1
                    }
                ])
            },
            docs: [
                dividerElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-divider-sample': DividerSampleElement;
    }
}
