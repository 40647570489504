import { Appearance, BadgePosition, type TemplateResult, Variant, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace FloatingActionButtonGroupSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-fab-group>
                <mosaik-fab .label="${'First'}"></mosaik-fab>
                <mosaik-fab .label="${'Second'}"></mosaik-fab>
                <mosaik-fab .label="${'Third'}"></mosaik-fab>
            </mosaik-fab-group>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withBadgeTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-fab-group>
                <mosaik-badge slot="overlay"
                            .appearance="${Appearance.Soft}"
                            .position="${BadgePosition.TopRight}"
                            .variant="${Variant.Danger}"
                            .label="${'1'}"></mosaik-badge>
                <mosaik-fab .label="${'First'}"></mosaik-fab>
                <mosaik-fab .label="${'Second'}"></mosaik-fab>
                <mosaik-fab .label="${'Third'}"></mosaik-fab>
            </mosaik-fab-group>
        `;

        return [
            {
                name: 'With Badge',
                description: 'The template with a badge'
            }, template
        ];
    }

}
