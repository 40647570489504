//#region Imports

import { angular } from '@codemirror/lang-angular';
import { css } from '@codemirror/lang-css';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { sass } from '@codemirror/lang-sass';
import { vue } from '@codemirror/lang-vue';
import { xml } from '@codemirror/lang-xml';
import { yaml } from '@codemirror/lang-yaml';

//#endregion

export class CodeEditorLanguages {

    public static ANGULAR = angular();

    public static VUE = vue();

    public static HTML = html();

    public static TYPESCRIPT = javascript({ typescript: true });

    public static TSX = javascript({ jsx: true, typescript: true });

    public static JAVASCRIPT = javascript();

    public static JSX = javascript({ jsx: true });

    public static SCSS = sass({ indented: true });

    public static SASS = sass();

    public static CSS = css();

    public static YAML = yaml();

    public static JSON = json();

    public static XML = xml();

}

export type LanguageName = Lowercase<keyof typeof CodeEditorLanguages>;
export namespace CodeEditorLanguages {

    export function getLanguage(name: Omit<LanguageName, 'prototype' | 'getlanguage'>): any {
        switch (name) {
            case 'angular':
                return CodeEditorLanguages.ANGULAR;
            case 'vue':
                return CodeEditorLanguages.VUE;
            case 'html':
                return CodeEditorLanguages.HTML;
            case 'typescript':
                return CodeEditorLanguages.TYPESCRIPT;
            case 'tsx':
            case 'react':
                return CodeEditorLanguages.TSX;
            case 'javascript':
                return CodeEditorLanguages.JAVASCRIPT;
            case 'jsx':
                return CodeEditorLanguages.JSX;
            case 'scss':
                return CodeEditorLanguages.SCSS;
            case 'sass':
                return CodeEditorLanguages.SASS;
            case 'css':
                return CodeEditorLanguages.CSS;
            case 'yaml':
                return CodeEditorLanguages.YAML;
            case 'json':
                return CodeEditorLanguages.JSON;
            case 'xml':
                return CodeEditorLanguages.XML;
            default:
                return null;
        }
    }

}