// #region Imports

import { AnchorElement, CSSResultGroup, Component, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { anchorElementDocs } from '../../../.Generated/mosaik-anchor';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { AnchorSampleTemplates } from './AnchorSample.Templates';

// #endregion

/**
 * The `{@link AnchorSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-anchor-sample'
})
export class AnchorSampleElement extends SampleBaseElement<AnchorElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<AnchorElement> {
        return {
            header: 'Anchor',
            description: META.description(AnchorElement.is),
            graph: META.graph(AnchorElement.is),
            playground: {
                template: [
                    AnchorSampleTemplates.defaultTemplate()
                ],
                properties: META.properties2<AnchorElement>(AnchorElement.is, {
                    dir: FlowDirection.Auto,
                    variant: Variant.Primary,
                    label: 'Anchor'
                }),
                events: META.events(AnchorElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<AnchorElement>(AnchorElement.is, {
                    dir: FlowDirection.Auto,
                    variant: Variant.Primary,
                    label: 'Anchor'
                }), [
                ] as Array<IPlaygroundProperty<AnchorElement>>, (a, b) => a.key === b.key)
            },
            docs: [anchorElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-anchor-sample': AnchorSampleElement;
    }
}
