
// #region Imports

import { Component, DialogServiceLocator, Inject, Property, Signal, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, Watch } from '@breadstone/mosaik-elements-foundation';
import { IFeedbackResponse } from '../../Backend/Api/Models/IFeedbackResponse';
import { IFeedbackTypeResponse } from '../../Backend/Api/Models/IFeedbackTypeResponse';
import { FeedbackService } from '../../Backend/Api/Services/FeedbackService';
import { SessionManager } from '../../Services/SessionManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { CreateFeedbackView } from './Dialogs/CreateFeedbackView';
import { feedbackViewStyle } from './FeedbackViewStyle';
import { feedbackViewTemplate } from './FeedbackViewTemplate';

// #endregion

interface IFeedbackVotingInfo {
    ref: IFeedbackResponse;
    isBusy: Signal<boolean>;
    votes: Signal<number>;
}

/**
 * The `{@link FeedbackView}` View.
 *
 * @public
 */
@Component({
    selector: 'app-feedback-view',
    template: feedbackViewTemplate,
    styles: [
        viewBaseStyle(),
        feedbackViewStyle()
    ],
    imports: [
        CreateFeedbackView
    ]
})
export class FeedbackView
    extends ViewBase {

    // #region Fields

    @Inject(FeedbackService) private readonly _feedbackService!: FeedbackService;
    @Inject(SessionManager) private readonly _sessionManager!: SessionManager;
    private _term: string;
    private _type: string;
    private _items: Array<IFeedbackVotingInfo>;
    private _types: Array<IFeedbackTypeResponse>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `FeedbackView` class.
     *
     * @public
     */
    public constructor() {
        super();

        this._term = '';
        this._type = '';
        this._items = [];
        this._types = [];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-feedback-view';
    }

    /**
     * Gets or sets the `term` property.
     *
     * @public
     */
    @Property({ type: String })
    public get term(): string {
        return this._term;
    }

    public set term(value: string) {
        if (this._term !== value) {
            this._term = value;
            this.requestUpdate('term');
        }
    }

    /**
     * Gets or sets the `type` property.
     *
     * @public
     */
    @Property({ type: String })
    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        if (this._type !== value) {
            this._type = value;
            this.requestUpdate('type');
        }
    }

    /**
     * Gets or sets the `items` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get items(): Array<IFeedbackVotingInfo> {
        return this._items;
    }

    private set items(value: Array<IFeedbackVotingInfo>) {
        if (this._items !== value) {
            this._items = value;
            this.requestUpdate('items');
        }
    }

    /**
     * Gets or sets the `types` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get types(): Array<IFeedbackTypeResponse> {
        return this._types;
    }

    private set types(value: Array<IFeedbackTypeResponse>) {
        if (this._types !== value) {
            this._types = value;
            this.requestUpdate('types');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();
    }

    /**
     * @public
     */
    public onCreate(e: Event): void {
        e.preventDefault();

        void DialogServiceLocator.current.open(CreateFeedbackView.is, CreateFeedbackView, {
            width: '400px',
            navigateToClose: true
        }).finally(() => {
            void this.initializeFeedback();

            void ToastServiceLocator.current.open({
                header: TranslatorServiceLocator.current.translate('Thank you!'),
                variant: Variant.Success,
                timeout: Toast2.Timeout.MEDIUM
            });
        });
    }

    /**
     * @public
     */
    public onVote(e: Event, vote: IFeedbackVotingInfo): void {
        e.preventDefault();

        void this._feedbackService.vote({
            authorization: this._sessionManager.session,
            feedbackId: vote.ref.id
        }).then((x) => {
            vote.votes.set(x.count);

            void ToastServiceLocator.current.open({
                header: TranslatorServiceLocator.current.translate('Thank you!'),
                variant: Variant.Success,
                timeout: Toast2.Timeout.MEDIUM
            });
        });
    }

    /**
     * @private
     */
    private initialize(): void {
        void this.initializeFeedback();
        void this.initalizeFeedbackTypes();
        this.initalizeFeedbackTracking();
    }

    /**
     * @private
     */
    private async initializeFeedback(): Promise<void> {
        this.isBusy = true;

        const params: {
            term?: string;
            type?: string;
        } = {};
        if (this._term) {
            params.term = this._term;
        }
        if (this._type) {
            params.type = this._type;
        }

        await this._feedbackService
            .list(params)
            .then((x) => {
                const items = x.map<IFeedbackVotingInfo>((y) => ({
                    ref: y,
                    isBusy: Signal.from(false),
                    votes: Signal.from(y.votesCount)
                }));

                this.items = items;
            })
            .finally(() => this.isBusy = false);
    }

    /**
     * @private
     */
    private async initalizeFeedbackTypes(): Promise<void> {
        this.isBusy = true;

        await this._feedbackService
            .types()
            .then((x) => this.types = x)
            .finally(() => this.isBusy = false);
    }

    private initalizeFeedbackTracking(): void {
        // this.addSubscription(this._feedbackService.track().subscribe((x) => {
        //     console.log('TRAKING FROM VIEW', x);
        // }));
    }

    /**
     * @private
     */
    @Watch('term')
    private onTermPropertyChanged(prev: string, next: string): void {
        if (prev !== next) {
            void this.initializeFeedback();
        }
    }

    /**
     * @private
     */
    @Watch('type')
    private onTypePropertyChanged(prev: string, next: string): void {
        if (prev !== next) {
            void this.initializeFeedback();
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-feedback-view': FeedbackView;
    }
}
