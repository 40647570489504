// #region Imports

import { ControllerBase, type ControllerHost } from '@breadstone/mosaik-elements-foundation/dist/Controls/Controllers/Abstracts/ControllerBase';

// #endregion

export interface ISubscription {
    unsubscribe: () => void;
}

/**
 * The registry controller registers and unregisters elements.
 * It is used to handle static elements like Drawer, Dialog, etc. on a global level.
 *
 * @public
 */
export class SubscriptionController
    extends ControllerBase {

    // #region Fields

    private readonly _subscriptions: Array<ISubscription>;

    // #endregion

    // #region Ctor

    public constructor(host: ControllerHost) {
        super(host);

        this._subscriptions = new Array<ISubscription>();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override hostDisconnected(): void {
        this._subscriptions.forEach((x) => {
            x.unsubscribe();
        });
    }

    public addSubscription(subscription: ISubscription): void {
        this._subscriptions.push(subscription);
    }

    public removeSubscription(subscription: ISubscription): void {
        const index = this._subscriptions.indexOf(subscription);

        if (index !== -1) {
            const sub = this._subscriptions.splice(index, 1).at(0);
            sub?.unsubscribe();
        }
    }

    // #endregion

}
