// #region Imports

import { Command, Component, FloatingElement, IChatSubmitEventDetail, ICommand, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { AiService } from '../../Backend/Api/Services/AiService';
import { FeatureManager } from '../../Services/FeatureManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { appChatStyle } from './AppChatStyle';
import { appChatElementTemplate } from './AppChatTemplate';

// #endregion

interface IChatMessage {
    message: string;
    attachments?: Array<File>;
    from: string;
    date: Date;
    reply?: boolean;
    isBusy: boolean;
}

/**
 * The `{@link ChatElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-chat',
    template: appChatElementTemplate,
    styles: appChatStyle,
    providers: [
        {
            provide: FeatureManager,
            useClass: FeatureManager
        }
    ]
})
export class AppChatElement
    extends ViewBase {

    // #region Fields

    @Inject(FeatureManager)
    private readonly _featureManager!: FeatureManager;
    @Inject(AiService)
    private readonly _aiService!: AiService;
    private readonly _closeCommand: Command;
    private readonly _submitCommand: Command<IChatSubmitEventDetail>;
    private _messages: Array<IChatMessage>;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._messages = [
            {
                message: `Hello, Nice to meet you! My name is *${this.name}* and I am a chatbot. I am here to help you with your questions about the Mosaik Design System. How can I help you?`,
                from: this.name,
                date: new Date(),
                reply: true,
                isBusy: false
            }
        ];
        this._closeCommand = new Command(() => this.onExecuteCloseCommand());
        this._submitCommand = new Command((x) => this.onExecuteSubmitCommand(x));
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-chat';
    }

    /**
     * Gets or sets the `messages` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get messages(): Array<IChatMessage> {
        return this._messages;
    }

    private set messages(value: Array<IChatMessage>) {
        if (this._messages !== value) {
            this._messages = value;
            this.requestUpdate('messages');
        }
    }

    /**
     * Returns the `name` property.
     *
     * @public
     * @readonly
     */
    public get name(): string {
        return this._featureManager.getFeatureOptions('aiChat')?.name ?? '';
    }

    /**
     * Returns the `closeCommand` command property.
     *
     * @public
     * @readonly
     */
    public get closeCommand(): ICommand {
        return this._closeCommand;
    }

    /**
     * Returns the `submitCommand` command property.
     *
     * @public
     * @readonly
     */
    public get submitCommand(): ICommand<IChatSubmitEventDetail> {
        return this._submitCommand;
    }

    // #endregion

    // #region Methods

    /**
     * Executes the `closeCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteCloseCommand(): void {
        const element = this.getTemplatePart<FloatingElement>('floating');
        element.close();
    }

    /**
     * Executes the `submitCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteSubmitCommand(parameter: IChatSubmitEventDetail): void {
        this._messages.push({
            message: parameter.message.text,
            attachments: parameter.message.files,
            from: 'You',
            date: new Date(),
            isBusy: false
        });

        const botMessage = {
            message: 'I am thinking...',
            from: this.name,
            date: new Date(),
            reply: true,
            isBusy: true
        };

        this._messages.push(botMessage);

        void this._aiService.chat({ body: { message: parameter.message.text } })
            .then((response) => {
                botMessage.isBusy = false;
                botMessage.message = response.message;
                this.requestUpdate('messages');
            })
            .catch((_error: unknown) => {
                botMessage.isBusy = false;
                botMessage.message = 'Sorry, I am currently unavailable. Please try again later.';
                this.requestUpdate('messages');
            });

        this.requestUpdate('messages');
    }

    // #endregion

}

declare global {
    interface HTMLElementTagNameMap {
        'app-chat': AppChatElement;
    }
}
