// #region Imports

import { Appearance, ButtonType, CSSResultGroup, Component, DropDownButtonElement, Fit, FlowDirection, HorizontalAlignment, IconPosition, Orientation, Placement, Size, Strategy, Variant, VerticalAlignment } from '@breadstone/mosaik-elements-foundation';
import { dropdownButtonElementDocs } from '../../../.Generated/mosaik-dropdown-button';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, iconSizeTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { DropDownButtonSampleTemplates } from './DropDownButtonSample.Templates';

// #endregion

/**
 * The `{@link DropDownButtonSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-dropdown-button-sample'
})
export class DropDownButtonSampleElement extends SampleBaseElement<DropDownButtonElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<DropDownButtonElement> {
        return {
            header: 'Drop Down Button',
            description: META.description(DropDownButtonElement.is),
            graph: META.graph(DropDownButtonElement.is),
            playground: {
                template: [
                    DropDownButtonSampleTemplates.defaultTemplate(),
                    DropDownButtonSampleTemplates.withBadgeTemplate(),
                    DropDownButtonSampleTemplates.withCustomMenuTemplate()
                ],
                properties: META.properties2<DropDownButtonElement>(DropDownButtonElement.is, {
                    appearance: Appearance.Soft,
                    icon: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z',
                    label: 'Label',
                    variant: Variant.Primary,
                    dropDownDistance: 8
                }),
                events: META.events(DropDownButtonElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<DropDownButtonElement>(DropDownButtonElement.is, {
                    appearance: Appearance.Soft,
                    dir: FlowDirection.Auto,
                    dropDownDistance: 8,
                    dropDownPlacement: Placement.Bottom,
                    dropDownSkidding: 0,
                    dropDownStrategy: Strategy.Fixed,
                    fit: Fit.None,
                    horizontalContentAlignment: HorizontalAlignment.Center,
                    icon: 'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z',
                    iconPosition: IconPosition.Before,
                    iconSize: Size.Medium,
                    label: 'Label',
                    maxDropDownHeight: '200px',
                    orientation: Orientation.Horizontal,
                    size: Size.Medium,
                    type: ButtonType.Button,
                    variant: Variant.Primary,
                    verticalContentAlignment: VerticalAlignment.Center
                }), [
                    {
                        key: 'iconSize',
                        template: iconSizeTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    },
                    {
                        key: 'maxDropDownHeight',
                        template: cssLengtTemplate
                    }
                ] as Array<IPlaygroundProperty<DropDownButtonElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                dropdownButtonElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-dropdown-button-sample': DropDownButtonSampleElement;
    }
}
