// #region Imports

import { Animate, Appearance, Component, Inset, Orientation, TextFormatters, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant, animate, interpolate, repeat, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { css, html, type CSSResultGroup, type TemplateResult } from 'lit';
import { CodeEditorLanguages } from '../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../Components/CodeEditor/CodeEditorTheme';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { typographyCssCode, typographyHtmlCode } from './TypographyView.Snippets';

// #endregion

/**
 * The `{@link TypographyView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-typography-view'
})
export class TypographyView extends ViewBase {

    // #region Fields

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly TYPOGRAPIES: Array<string> = ['headline1', 'headline2', 'headline3', 'headline4', 'headline5', 'headline6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline'];

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-typography-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.typography')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/typography'}" .label="${translate('loc.nav.typography')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.typography')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-banner .variant="${Variant.Info}"
                                    .appearance="${Appearance.Soft}"
                                    .icon="${'M12 1.999c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999Zm-.004 8.25a1 1 0 0 0-.992.885l-.007.116.003 5.502.007.117a1 1 0 0 0 1.987-.002L13 16.75l-.003-5.501-.007-.117a1 1 0 0 0-.994-.882ZM12 6.5a1.251 1.251 0 1 0 0 2.503A1.251 1.251 0 0 0 12 6.5Z'}"
                                    .header="${'Pro tip'}"
                                    .subHeader="${'The text on the right side is clickable 🎉. Clicking on a text automatically copies its font for pasting elsewhere.'}"></mosaik-banner>
                    <mosaik-spacer thickness="top"></mosaik-spacer>

                    <mosaik-stack orientation="${Orientation.Vertical}">
                        ${repeat(this.TYPOGRAPIES, (x) => x, (x) => html`
                        <mosaik-stack orientation="${this.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                    gap="16px">
                            <mosaik-stack orientation="${Orientation.Vertical}">
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-theme-{0}-{1}```', x, 'typography-font-size')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography-line-height')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography-font-weight')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography-text-decoration')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography-text-transform')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                                <mosaik-text .text="${interpolate('```--theme-{0}-{1}```', x, 'typography-letter-spacing')}"
                                            .formatter="${TextFormatters.RICHTEXT}"
                                            style="font: var(--theme-body2-typography); user-select: text;"></mosaik-text>
                            </mosaik-stack>
                            <mosaik-stack orientation="${Orientation.Vertical}">
                                <mosaik-text ${typography(x)} text="The quick brown fox jumps over the lazy dog"
                                            ?wrap="${true}"
                                            style="cursor: pointer; user-select: text;"
                                            @click="${() => this.onCopy(x)}"></mosaik-text>
                            </mosaik-stack>
                        </mosaik-stack>
                        <mosaik-spacer thickness="top"></mosaik-spacer>
                        `)}
                    </mosaik-stack>
                    <mosaik-spacer thickness="top"></mosaik-spacer>
                    <mosaik-tab .appearance="${Appearance.Solid}"
                                    .variant="${Variant.Primary}">
                        <mosaik-tab-item .label="${'CSS'}"
                                            .isSelected="${true}">
                            <app-code-editor .language="${CodeEditorLanguages.CSS}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                     .text="${typographyCssCode}"
                                     .readonly="${true}"></app-code-editor>
                        </mosaik-tab-item>
                        <mosaik-tab-item .label="${'HTML'}">
                            <app-code-editor .language="${CodeEditorLanguages.HTML}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"
                                     .text="${typographyHtmlCode}"
                                     .readonly="${true}"></app-code-editor>
                        </mosaik-tab-item>
                    </mosaik-tab>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    /**
     * @private
     */
    private onCopy(typo: string): void {
        const text = `font: var(--${typo}-typography);`
            .concat(`font-size: var(--theme-${typo}-typography-font-size);`)
            .concat(`line-height: var(--theme-${typo}-typography-line-height);`)
            .concat(`font-weight: var(--theme-${typo}-typography-font-weight);`)
            .concat(`text-decoration: var(--theme-${typo}-typography-text-decoration);`)
            .concat(`text-transform: var(--theme-${typo}-typography-text-transform);`)
            .concat(`letter-spacing: var(--theme-${typo}-typography-letter-spacing);`);

        void navigator.clipboard.writeText(text).then(() => {
            void ToastServiceLocator.current.open({
                variant: Variant.Success,
                content: TranslatorServiceLocator.current.translate('Typography copied to clipboard 🎉.'),
                timeout: Toast2.Timeout.SHORT
            });
        });
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-typography-view': TypographyView;
    }
}
