// #region Imports

import { Appearance, CSSResultGroup, Component, FlowDirection, Orientation, SegmentElement, Variant, html } from '@breadstone/mosaik-elements-foundation';
import { segmentElementDocs } from '../../../.Generated/mosaik-segment';
import { segmentItemElementDocs } from '../../../.Generated/mosaik-segment-item';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link SegmentSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-segment-sample'
})
export class SegmentSampleElement extends SampleBaseElement<SegmentElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<SegmentElement> {

        return {
            header: 'Segment',
            description: META.description(SegmentElement.is),
            graph: META.graph(SegmentElement.is),
            playground: {
                template: [['default', html`
                    <mosaik-segment>
                        <mosaik-segment-item .label="${'Segment'}"
                                             .icon="${'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'}"
                                             .isChecked="${true}"
                                             .orientation="${Orientation.Vertical}"></mosaik-segment-item>
                        <mosaik-segment-item label="Segment"
                                             .icon="${'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'}"
                                             .orientation="${Orientation.Vertical}"></mosaik-segment-item>
                        <mosaik-segment-item label="Segment"
                                             .icon="${'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'}"
                                             .orientation="${Orientation.Vertical}"></mosaik-segment-item>
                        <mosaik-segment-item label="Segment"
                                             .icon="${'M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z'}"
                                             .disabled="${true}"
                                             .orientation="${Orientation.Vertical}"></mosaik-segment-item>
                    </mosaik-segment>
                `]],
                properties: [
                    {
                        key: 'disabled',
                        value: false,
                        type: Boolean
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto,
                        type: FlowDirection
                    }
                ]
            },
            propertyGrid: {
                properties: META.properties<SegmentElement>(SegmentElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    }
                ])

            },
            docs: [
                segmentElementDocs(),
                segmentItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-segment-sample': SegmentSampleElement;
    }
}
