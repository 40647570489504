// #region Imports

import { Attribute, Component, CustomElement, Property, Watch, type IConnectedCallback } from '@breadstone/mosaik-elements-foundation';
import { marked } from 'marked';
import { IMarkdownElementProps } from './IMarkdownElementProps';
import { markdownElementStyle } from './MarkdownElementStyle';
import { markdownElementTemplate } from './MarkdownElementTemplate';
import { MarkdownRenderer } from './MarkdownRenderer';

// #endregion

/**
 * The `{@link MarkdownElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-markdown',
    template: markdownElementTemplate,
    styles: markdownElementStyle
})
export class MarkdownElement extends CustomElement implements IConnectedCallback, IMarkdownElementProps {

    // #region Fields

    private _text: string;
    private _src: string;
    private _renderer: MarkdownRenderer | null;
    private _breaks: boolean;
    private _pedantic: boolean;
    private _silent: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._text = '';
        this._src = '';
        this._renderer = null;
        this._breaks = false;
        this._pedantic = false;
        this._silent = false;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-markdown';
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Property({ type: String })
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        this._text = value;
    }

    /**
     * Gets or sets the `src` property.
     *
     * @public
     */
    @Property({ type: String })
    public get src(): string {
        return this._src;
    }

    public set src(value: string) {
        this._src = value;
    }

    /**
     * Gets or sets the `renderer` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get renderer(): MarkdownRenderer | null {
        return this._renderer;
    }

    public set renderer(value: MarkdownRenderer | null) {
        if (this._renderer !== value) {
            this._renderer = value;
            this.requestUpdate('renderer');
        }
    }

    /**
     * Gets or sets the `breaks` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get breaks(): boolean {
        return this._breaks;
    }

    public set breaks(value: boolean) {
        this._breaks = value;
    }

    /**
     * Gets or sets the `pedantic` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get pedantic(): boolean {
        return this._pedantic;
    }

    public set pedantic(value: boolean) {
        this._pedantic = value;
    }

    /**
     * Gets or sets the `silent` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get silent(): boolean {
        return this._silent;
    }

    public set silent(value: boolean) {
        this._silent = value;
    }

    // #endregion

    // #region Methods

    /**
     * @private
     * @template
    */
    public renderMarkdown(): string {
        return marked(this._text, {
            breaks: this._breaks,
            gfm: this._breaks,
            pedantic: this._pedantic,
            silent: this._silent
        }) as string;
    }

    /**
     * @private
     */
    private async fetchMarkdown(): Promise<string> {
        if (!this._src.includes('.md')) {
            return '`src` attribute does not specify a Markdown file.';
        }
        return fetch(this._src)
            .then(async (response) => response.text())
            .catch((e) => 'Failed to read Markdown source.');
    }

    /**
     * @protected
     */
    @Watch('text')
    protected onTextPropertyChanged(): void {
        this.requestUpdate();
    }

    /**
     * @protected
     */
    @Watch('src')
    protected async onSrcPropertyChanged(): Promise<void> {
        void this.fetchMarkdown()
            .then((r) => {
                this.text = r;
                this.requestUpdate();
            });
    }

    /**
     * @protected
     */
    @Watch('renderer')
    protected onRendererPropertyChanged(): void {
        marked.use({ renderer: this._renderer });
        this.requestUpdate();
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-markdown': MarkdownElement;
    }
}
