// #region Imports

import { Component, DialogServiceLocator, Inject, Property, Toast, Toast2, TranslatorServiceLocator, Variant, type FormElement, type IWizardActiveStepChangedEventDetail, type WizardElement } from '@breadstone/mosaik-elements-foundation';
import { IssuesService } from '../../Backend/Api/Services/IssuesService';
import type { CaptchaElement } from '../../Components/Captcha/CaptchaElement';
import { BrowserInfo } from '../../Services/BrowserInfo';
import { ViewBase } from '../Abstracts/ViewBase';
import { appIssueStyle } from './AppIssueStyle';
import { appIssueElementTemplate } from './AppIssueTemplate2';

// #endregion

interface IBrowserInfo {

    // #region Properties

    name: string;
    version: string;
    osName: string;
    osVersion: string;

    // #endregion

}

/**
 * The `{@link IssueElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-issue',
    template: appIssueElementTemplate,
    styles: appIssueStyle,
    imports: [],
    providers: [
        {
            provide: IssuesService,
            useClass: IssuesService
        }
    ]
})
export class AppIssueElement
    extends ViewBase {

    // #region Fields

    @Inject(IssuesService)
    private readonly _issuesService!: IssuesService;
    private _browser: IBrowserInfo;
    private _types: Array<string>;
    private _subject: string;
    private _description: string;
    private _type: string;
    private _isDone: boolean;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._types = ['bug', 'feature'];
        this._subject = '';
        this._description = '';
        this._type = '';
        this._browser = BrowserInfo.get();
        this._isDone = false;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-issue';
    }

    /**
     * Gets or sets the `types` property.
     *
     * @public
     */
    @Property({ type: Array<string> })
    public get types(): Array<string> {
        return this._types;
    }

    public set types(value: Array<string>) {
        if (this._types !== value) {
            this._types = value;
            this.requestUpdate('types');
        }
    }

    /**
     * Gets or sets the `type` property.
     *
     * @public
     */
    @Property({ type: String })
    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        if (this._type !== value) {
            this._type = value;
            this.requestUpdate('type');
        }
    }

    /**
     * Gets or sets the `subject` property.
     *
     * @public
     */
    @Property({ type: String })
    public get subject(): string {
        return this._subject;
    }

    public set subject(value: string) {
        if (this._subject !== value) {
            this._subject = value;
            this.requestUpdate('subject');
        }
    }

    /**
     * Gets or sets the `description` property.
     *
     * @public
     */
    @Property({ type: String })
    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        if (this._description !== value) {
            this._description = value;
            this.requestUpdate('description');
        }
    }

    /**
     * Gets or sets the `browser` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get browser(): IBrowserInfo {
        return this._browser;
    }

    private set browser(value: IBrowserInfo) {
        if (this._browser !== value) {
            this._browser = value;
            this.requestUpdate('browser');
        }
    }

    /**
     * Gets or sets the `isDone` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isDone(): boolean {
        return this._isDone;
    }

    public set isDone(value: boolean) {
        if (this._isDone !== value) {
            this._isDone = value;
            this.requestUpdate('isDone');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @private
     * @template
     */
    public onWizardStepChanged(event: IWizardActiveStepChangedEventDetail): void {
        if (event.direction === 'forward') {
            if (event.oldStepIndex === 0) {
                const isValid = this.getTemplatePart<FormElement>('form').reportValidity();
                event.cancel = !isValid;
                return;
            }

            if (event.oldStepIndex === 1) {
                event.cancel = !this.getTemplatePart<CaptchaElement>('captcha').valid;
                return;
            }

            if (event.newStepIndex === 2) {
                this.isBusy = true;

                void this._issuesService.createIssue({
                    body: {
                        title: this._subject,
                        body: this._description,
                        labels: [this._type, 'external']
                    }
                })
                    .then(() => {
                        void Toast.configure((x) => x
                            .withTheme({ isInline: true })
                            .withPortal({ destination: 'body' }))
                            .show({
                                hasBackdrop: true,
                                clickOutsideToClose: true,
                                header: TranslatorServiceLocator.current.translate('loc.global.success'),
                                variant: Variant.Success,
                                timeout: Toast2.Timeout.MEDIUM
                            });
                    })
                    .catch(() => {
                        void Toast.configure((x) => x
                            .withTheme({ isInline: true })
                            .withPortal({ destination: 'body' }))
                            .show({
                                hasBackdrop: true,
                                clickOutsideToClose: true,
                                header: TranslatorServiceLocator.current.translate('loc.global.error'),
                                variant: Variant.Danger,
                                timeout: Toast2.Timeout.MEDIUM
                            });
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.isDone = true;
                    });
            }
        }
    }

    /**
     * @private
     * @template
     */
    public onPrevious(): void {
        this.getTemplatePart<WizardElement>('wizard').selectPrevious();
    }

    /**
     * @private
     * @template
     */
    public onNext(): void {
        this.getTemplatePart<WizardElement>('wizard').selectNext();
    }

    /**
     * @private
     * @template
     */
    public onClose(): void {
        if (this.isDone) {
            this.reset();
        }

        void DialogServiceLocator.current.close('issueDialog');
    }

    /**
     * @private
     */
    private reset(): void {
        this.subject = '';
        this.description = '';
        this.type = '';
        this.getTemplatePart<FormElement>('form').reset();
        this.getTemplatePart<WizardElement>('wizard').selectFirst();
    }

    // #endregion

}

declare global {
    interface HTMLElementTagNameMap {
        'app-issue': AppIssueElement;
    }
}
