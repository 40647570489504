// #region Imports

import { Attribute, Component } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../Abstracts/ViewBase';
import type { GridGeneratorViewModel } from './GridGeneratorViewModel';
import { gridGeneratorViewStyle } from './GridGeneratorViewStyle';
import { gridGeneratorViewElementTemplate } from './GridGeneratorViewTemplate';

// #endregion

/**
 * The `{@link GridGeneratorView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-grid-generator-view',
    template: gridGeneratorViewElementTemplate,
    styles: gridGeneratorViewStyle
})
// @ViewModel(GridGeneratorViewModel)
export class GridGeneratorView extends ViewBase<GridGeneratorViewModel> {

    // #region Fields

    private _columns: number = 12;
    private _rows: number = 10;
    private _gap: number = 8;
    private _radius: number = 0;
    private _shadow: number = 0;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {

        return 'app-grid-generator-view';

    }

    /**
     * Gets or sets the `columns` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get columns(): number {

        return this._columns;

    }

    public set columns(value: number) {

        if (this._columns !== value) {

            this._columns = value;
            this.requestUpdate('columns');

        }

    }

    /**
     * Gets or sets the `rows` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get rows(): number {

        return this._rows;

    }

    public set rows(value: number) {

        if (this._rows !== value) {

            this._rows = value;
            this.requestUpdate('rows');

        }

    }

    /**
     * Gets or sets the `gap` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get gap(): number {

        return this._gap;

    }

    public set gap(value: number) {

        if (this._gap !== value) {

            this._gap = value;
            this.requestUpdate('gap');

        }

    }

    /**
     * Gets or sets the `radius` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get radius(): number {

        return this._radius;

    }

    public set radius(value: number) {

        if (this._radius !== value) {

            this._radius = value;
            this.requestUpdate('radius');

        }

    }

    /**
     * Gets or sets the `shadow` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get shadow(): number {

        return this._shadow;

    }

    public set shadow(value: number) {

        if (this._shadow !== value) {

            this._shadow = value;
            this.requestUpdate('shadow');

        }

    }

    // #endregion

    // #region Methods

    public onGapChanged(e: InputEvent): void {

        const value = (e.target as | HTMLInputElement | HTMLTextAreaElement).value;
        const gap = value !== null
            ? !Number.isNaN(Number.parseInt(value))
                ? Number.parseInt(value)
                : 0
            : 0;

        this.gap = gap;

    }

    public onColumnsChanged(e: InputEvent): void {

        const value = (e.target as | HTMLInputElement | HTMLTextAreaElement).value;
        const columns = value !== ''
            ? !Number.isNaN(Number.parseInt(value))
                ? Number.parseInt(value)
                : 0
            : 0;

        this.columns = columns;

    }

    public onRowsChanged(e: InputEvent): void {

        const value = (e.target as | HTMLInputElement | HTMLTextAreaElement).value;
        const rows = value !== ''
            ? !Number.isNaN(Number.parseInt(value))
                ? Number.parseInt(value)
                : 0
            : 0;

        this.rows = rows;

    }

    public onRadiusChanged(e: InputEvent): void {

        const { value } = e.target as | HTMLInputElement | HTMLTextAreaElement;
        const radius = value !== ''
            ? !Number.isNaN(Number.parseInt(value))
                ? Number.parseInt(value)
                : 0
            : 0;

        this.radius = radius;

    }

    public onShadowChanged(e: InputEvent): void {

        const { value } = e.target as | HTMLInputElement | HTMLTextAreaElement;
        const shadow = value !== null
            ? !Number.isNaN(Number.parseInt(value))
                ? Number.parseInt(value)
                : 0
            : 0;

        this.shadow = shadow;

    }

    public repeat(str: string, count: number): Array<string> {

        const res = [];
        for (let i = 0; i < count; i++) {

            res.push(str);

        }

        return res;

    }

    // #endregion

}
