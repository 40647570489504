// #region Imports

import { Command, Component, DomServiceLocator, Emit, HideOnScrollController, ICommand, IEventEmitter, IRouterNavigatedEventDetail, Inject, Property, RouterElement, on, type HTMLElementEventEmitter } from '@breadstone/mosaik-elements-foundation';
import { IUserResponse } from '../../Backend/Api/Models/IUserResponse';
import { SessionManager } from '../../Services/SessionManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { appBarStyle } from './AppBarStyle';
import { appBarTemplate } from './AppBarTemplate';
import { AppLogoElement } from './AppLogo';

// #endregion

@Component({
    selector: 'app-bar',
    styles: appBarStyle,
    template: appBarTemplate,
    imports: [
        AppLogoElement
    ]
})
export class AppBar
    extends ViewBase {

    // #region Fields

    @Inject(SessionManager)
    private readonly _sessionManager!: SessionManager;
    @Emit()
    private readonly _drawerOpened!: HTMLElementEventEmitter<string>;
    private readonly _scrollController: HideOnScrollController;
    private readonly _openLinkCommand: Command<string>;
    private readonly _logoutCommand: Command;
    private _scrollTargetFn: (() => Element | null) | null;
    private _text: string;
    private _hasMenu: boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `AppBar` class.
     *
     * @public
     */
    public constructor() {
        super();
        this._text = '';
        this._scrollTargetFn = null;
        this._hasMenu = false;
        this._openLinkCommand = new Command<string>((x) => this.onExecuteOpenLinkCommand(x));
        this._logoutCommand = new Command(() => this.onExecuteLogoutCommand());
        this._scrollController = new HideOnScrollController(this, {
            hideOnScroll: true,
            scrollUpThreshold: 1,
            scrollDownThreshold: screen.height,
            behavior: 'auto'
        });
    }

    // #endregion

    // #region Properties

    public static get is(): string {
        return 'app-bar';
    }

    @Property({ type: String })
    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        if (this._text !== value) {
            this._text = value;
            this.requestUpdate('text');
        }
    }

    @Property({ type: Boolean })
    public get hasMenu(): boolean {
        return this._hasMenu;
    }
    public set hasMenu(value: boolean) {
        if (this._hasMenu !== value) {
            this._hasMenu = value;
            this.requestUpdate('hasMenu');
        }
    }

    @Property({ type: Function })
    public get scrollTargetFn(): (() => Element | null) | null {
        return this._scrollTargetFn;
    }
    public set scrollTargetFn(value: (() => Element | null) | null) {
        if (this._scrollTargetFn !== value) {
            this._scrollTargetFn = value;
            this.requestUpdate('scrollTargetFn');

            this._scrollController.scrollTargetFn = this._scrollTargetFn;
        }
    }

    public get drawerOpened(): IEventEmitter<string> {
        return this._drawerOpened;
    }

    public get user(): IUserResponse | null {
        return this._sessionManager.me;
    }

    /**
     * Returns the `openLinkCommand` command property.
     *
     * @public
     * @readonly
     */
    public get openLinkCommand(): ICommand<string> {
        return this._openLinkCommand;
    }

    /**
     * Returns the `logoutCommand` command property.
     *
     * @public
     * @readonly
     */
    public get logoutCommand(): ICommand {
        return this._logoutCommand;
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override onApplyTemplate(): void {
        const router = DomServiceLocator.current.findAncestor(this, RouterElement, { strict: true });

        on(router, 'navigated', (x: CustomEvent<IRouterNavigatedEventDetail>) => {
            // TODO: restore header here...
            this._scrollController.restore();
        });
    }

    /**
     * Executes the `openLinkCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteOpenLinkCommand(parameter: string): void {
        window.open(parameter, '_blank', 'noopener,noreferrer');
    }

    /**
     * Executes the `logoutCommand` command.
     *
     * @private
     */
    private onExecuteLogoutCommand(): void {
        this._sessionManager.clear();
        window.location.href = '/';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-bar': AppBar;
    }
}
