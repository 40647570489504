// #region Imports

import { Component, TableElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { tableElementDocs } from '../../.Generated/mosaik-table';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link TableSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-table-sample'
})
export class TableSampleElement extends SampleBaseElement<TableElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<TableElement> {

        return {
            header: 'Table',
            description: META.description(TableElement.is),
            graph: META.graph(TableElement.is),
            playground: {
                template: html`
                    <mosaik-table columns="1fr 1fr" style="height: 300px;">
                        <mosaik-table-header>
                            <mosaik-table-row>
                                <mosaik-table-cell text="Breakpoint key"></mosaik-table-cell>
                                <mosaik-table-cell text="Media query (pixel range)"></mosaik-table-cell>
                            </mosaik-table-row>
                        </mosaik-table-header>
                        <mosaik-table-body>
                            <mosaik-table-row>
                                <mosaik-table-cell text="xs"></mosaik-table-cell>
                                <mosaik-table-cell text="(max-width: <b>599</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="gt-xs"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>600</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="sm"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>600</b>px) and (max-width: <b>959</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="gt-sm"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>960</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="md"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>960</b>px) and (max-width: <b>1279</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                              <mosaik-table-row>
                                <mosaik-table-cell text="gt-md"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>1280</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                            <mosaik-table-row>
                                <mosaik-table-cell text="lg"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>1280</b>px) and (max-width: <b>1919</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="gt-lg"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>1920</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                             <mosaik-table-row>
                                <mosaik-table-cell text="xl"></mosaik-table-cell>
                                <mosaik-table-cell text="(min-width: <b>1920</b>px)"></mosaik-table-cell>
                            </mosaik-table-row>
                        </mosaik-table-body>
                    </mosaik-table>
                `,
                properties: META.properties<TableElement>(TableElement.is, [

                ])
            },
            propertyGrid: {
                properties: META.properties<TableElement>(TableElement.is, [

                ])
            },
            docs: [
                tableElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-table-sample': TableSampleElement;
    }
}
