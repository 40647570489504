// #region Imports

import { Attribute, Component, CustomElement, Interval, IntlController, Localeable, Watch, type ILocaleableProps } from '@breadstone/mosaik-elements-foundation';
import { countdownElementStyle } from './CountdownElementStyle';
import { countdownElementTemplate } from './CountdownElementTemplate';
import { CountdownElementIntl } from './Intl/CountdownElementIntl';

// #endregion

/**
 * Represents the `{@link ICountdownElementProps}` interface.
 *
 * @public
 */
export interface ICountdownElementProps extends ILocaleableProps {
    date: Date;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

/**
 * The `{@link CountdownElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-count-down',
    template: countdownElementTemplate,
    styles: countdownElementStyle,
    imports: []
})
export class CountdownElement extends Localeable(CustomElement) implements ICountdownElementProps {

    // #region Fields

    private readonly _interval: Interval;
    private readonly _intl: IntlController<CountdownElementIntl>;
    private _date: Date;
    private _days: number;
    private _hours: number;
    private _minutes: number;
    private _seconds: number;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._intl = new IntlController<CountdownElementIntl>(this, {
            factory: () => new CountdownElementIntl()
        });
        this._date = new Date();
        this._days = 0;
        this._hours = 0;
        this._minutes = 0;
        this._seconds = 0;
        this._interval = new Interval(1000, () => this.onIntervalTick());
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-count-down';
    }

    /**
     * Gets or sets the `date` property.
     *
     * @public
     */
    @Attribute({ type: Date })
    public get date(): Date {
        return this._date;
    }

    public set date(value: Date) {
        if (this._date !== value) {
            this._date = value;
            this.requestUpdate('date');
        }
    }

    /**
     * Gets or sets the `days` property.
     *
     * @public
     * @readonly
     * @attr
     */
    @Attribute({ type: Number })
    public get days(): number {
        return this._days;
    }

    private set days(value: number) {
        if (this._days !== value) {
            this._days = value;
            this.requestUpdate('days');
        }
    }

    /**
     * Gets or sets the `hours` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get hours(): number {
        return this._hours;
    }

    public set hours(value: number) {
        if (this._hours !== value) {
            this._hours = value;
            this.requestUpdate('hours');
        }
    }

    /**
     * Gets or sets the `minutes` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get minutes(): number {
        return this._minutes;
    }

    public set minutes(value: number) {
        if (this._minutes !== value) {
            this._minutes = value;
            this.requestUpdate('minutes');
        }
    }

    /**
     * Gets or sets the `seconds` property.
     *
     * @public
     */
    @Attribute({ type: Number })
    public get seconds(): number {
        return this._seconds;
    }

    public set seconds(value: number) {
        if (this._seconds !== value) {
            this._seconds = value;
            this.requestUpdate('seconds');
        }
    }

    /**
     * Returns the `intl` property.
     *
     * @public
     * @readonly
     */
    public get intl(): CountdownElementIntl {
        return this._intl.intl;
    }
    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override disconnectedCallback(): void {
        super.disconnectedCallback();
        this._interval.stop();
        this._interval.destroy();
    }

    /**
     * Formats a single-digit number (0-9) to always have two digits with a leading zero.
     * @param {number} value - The number to format.
     * @returns {string} The formatted number as a string.
     */
    public format(value: number): string {
        return value < 10 ? `0${value}` : value.toString();
    }

    /**
     * @protected
     */
    @Watch('date')
    protected onDatePropertyChanged(oldValue?: Date, newValue?: Date): void {
        this._interval.stop();

        if (newValue) {
            if (newValue.getTime() < new Date().getTime()) {
                this.reset();
                console.log('dateString value can not be earlier than the system date');
            } else {
                this.countdown(newValue);
                this._interval.start();
            }
        } else {
            this.reset();
            console.log('invalid dateString format (valid format is "DD-MM-YYYY HH:mm")');
        }
    }

    /**
     * @protected
     */
    @Watch('locale')
    protected onLocalePropertyChanged(oldValue?: string, newValue?: string): void {
        // if (newValue == 'es') {
        //     this.words = WORDS_ES;
        // } else {
        //     this.words = WORDS_EN;
        // }
    }

    /**
     * @private
     */
    private onIntervalTick(): void {
        this.countdown(this.date);
    }

    /**
     * Handles the countdown by comparing the given date to the current date and updating time components.
     * @param {Date} value - The target date for the countdown.
     */
    private countdown(value: Date): void {
        const now = new Date();
        const durationMs = value.getTime() - now.getTime();

        if (durationMs <= 0) {
            this.reset();
            this._interval.stop();
            console.log('countdown finished');
        } else {
            const secondsTotal = Math.floor(durationMs / 1000);
            const minutesTotal = Math.floor(secondsTotal / 60);
            const hoursTotal = Math.floor(minutesTotal / 60);
            const days = Math.floor(hoursTotal / 24);

            const seconds = secondsTotal % 60;
            const minutes = minutesTotal % 60;
            const hours = hoursTotal % 24;

            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        }
    }

    /**
     * @private
     */
    private reset(): void {
        this.days = this.hours = this.minutes = this.seconds = 0;
    }

    /**
     * Formats a number to ensure it has a minimum number of digits, padding with zeroes if necessary.
     *
     * @param num The number to format.
     * @param width The desired minimum width of the number, negative for left padding.
     * @returns {string} The padded number as a string.
     */
    private lpad(num: number, width: number): string {
        const numStr = Math.abs(num).toString();
        const padding = Math.max(width + 1 - numStr.length, 0);
        return width < 0 ? numStr + Array(padding + 1).join('0') : Array(padding + 1).join('0') + numStr;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-count-down': CountdownElement;
    }
}
