// #region Imports

import { HtmlCodeGenerator } from './Abstracts/HtmlCodeGenerator';
import type { ICodeModel } from './Interfaces/ICodeGenerator';

// #endregion

/**
 * Generates React HTML.
 */
export class ReactHtmlGenerator extends HtmlCodeGenerator {

    // #region Imports

    public process(model: ICodeModel): string {
        const attributes = Object.entries(model.properties)
            .map(([key, value]) => {
                if (value === undefined || value === null || value === '') {
                    return '';
                }

                return `${key}={${JSON.stringify(value)}}`;
            })
            .filter((x) => x !== '')
            .join(' ');

        return `<${model.componentName} ${attributes} />`;
    }

    // #endregion

}
