// #region Imports

import { Appearance, html, repeat, Size, Spacing, TextFormatters, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import type { PlaygroundLogElement } from './PlaygroundLogElement';

// #endregion

/**
 * The template of the {@link PlaygroundLogElement}.
 *
 * @public
 */
export function playgroundLogElementTemplate<T extends PlaygroundLogElement>(e: T): TemplateResult {
    return html`
        <mosaik-list>
            ${repeat(e.getGroupedEntries(), (group) => group.key, (group) => html`
            <mosaik-list-item-group .header="${group.key ?? ''}">
                ${repeat(group.items, (log) => log.title, (log) => html`
                <mosaik-list-item>
                    ${when(log.detail, () => html`
                    <mosaik-expander part="entry"
                                    .header="${log.title}">
                        <app-json-renderer .data="${log.detail}"></app-json-renderer>
                    </mosaik-expander>
                    `, () => html`
                    <mosaik-box part="entry">
                        <mosaik-text .text="${log.title}"
                                    .wrap="${true}"
                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-text>
                    </mosaik-box>
                    `)}
                </mosaik-list-item>
                `)}
            </mosaik-list-item-group>
            `)}
        </mosaik-list>

        <mosaik-spacer .thickness="${Spacing.All}" .size="${Size.Tiny}">
            <mosaik-button .label="${'Clear'}" @click="${() => e.clear()}" .appearance="${Appearance.Soft}"></mosaik-button>
        </mosaik-spacer>

    `;
}
