//#region Imports

import { Component, CustomElement, Disableable, Elevatable, ElevationElement, Property, Variantable } from '@breadstone/mosaik-elements-foundation';
import { type IPricingTableElementProps } from './IPricingTableElementProps';
import { pricingTableElementStyle } from './PricingTableElementStyle';
import { pricingTableElementTemplate } from './PricingTableElementTemplate';

//#endregion

/**
 * The `{@link PricingTableElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-pricing-table',
    template: pricingTableElementTemplate,
    styles: pricingTableElementStyle,
    host: {
        role: 'list'
    },
    imports: [
        ElevationElement
    ]
})
export class PricingTableElement extends Variantable(Disableable(Elevatable(CustomElement))) implements IPricingTableElementProps {

    //#region Fields

    private _header: string;
    private _subHeader: string;
    private _price: string;
    private _priceInterval: string;
    private _ribbon: string;

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._header = '';
        this._subHeader = '';
        this._price = '';
        this._priceInterval = '';
        this._ribbon = '';
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-pricing-table';
    }

    /**
     * Gets or sets the `header` property.
     *
     * @public
     */
    @Property({ type: String })
    public get header(): string {
        return this._header;
    }
    public set header(value: string) {
        if (this._header !== value) {
            this._header = value;
            this.requestUpdate('header');
        }
    }

    /**
     * Gets or sets the `subHeader` property.
     *
     * @public
     */
    @Property({ type: String })
    public get subHeader(): string {
        return this._subHeader;
    }
    public set subHeader(value: string) {
        if (this._subHeader !== value) {
            this._subHeader = value;
            this.requestUpdate('subHeader');
        }
    }

    /**
     * Gets or sets the `price` property.
     *
     * @public
     */
    @Property({ type: String })
    public get price(): string {
        return this._price;
    }
    public set price(value: string) {
        if (this._price !== value) {
            this._price = value;
            this.requestUpdate('price');
        }
    }

    /**
     * Gets or sets the `priceInterval` property.
     *
     * @public
     */
    @Property({ type: String })
    public get priceInterval(): string {
        return this._priceInterval;
    }
    public set priceInterval(value: string) {
        if (this._priceInterval !== value) {
            this._priceInterval = value;
            this.requestUpdate('priceInterval');
        }
    }

    /**
     * Gets or sets the `ribbon` property.
     *
     * @public
     */
    @Property({ type: String })
    public get ribbon(): string {
        return this._ribbon;
    }
    public set ribbon(value: string) {
        if (this._ribbon !== value) {
            this._ribbon = value;
            this.requestUpdate('ribbon');
        }
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-pricing-table': PricingTableElement;
    }
}

