// #region Imports

import { Animate, Appearance, Component, Inset, Orientation, TextFormatters, Variant, animate, css, html, translate, typography, type CSSResultGroup, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { CodeEditorLanguages } from '../../Components/CodeEditor/CodeEditorLanguages';
import { CodeEditorTheme } from '../../Components/CodeEditor/CodeEditorTheme';
import { globals } from '../../Globals';
import { Icons } from '../../Resources/Icons/Icons';
import { ViewBase } from '../Abstracts/ViewBase';
import { usageAngularHtmlCode, usageAngularTsCode, usageNativeHtmlCode, usageReactTsxCode, usageVueHtmlCode, usageVueTsCode } from './UsageView.Sippets';

// #endregion

/**
 * The `{@link UsageView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-usage-view'
})
export class UsageView extends ViewBase {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-usage-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css``
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-helmet>
                <title .text="${globals.name} - ${translate('loc.nav.usage')}"></title>
            </mosaik-helmet>
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}"
                                        .label="${translate('loc.nav.home')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/usage'}"
                                        .label="${translate('loc.nav.usage')}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${translate('loc.nav.usage')}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-stack orientation="${Orientation.Vertical}"
                            gap="32px">
                    <mosaik-router-anchor ${typography('headline5')}
                                          .label="${translate('loc.usage.native.title')}"
                                          .ref="${'native'}"></mosaik-router-anchor>
                    <mosaik-banner .variant="${Variant.Warning}"
                                   .appearance="${Appearance.Soft}"
                                   .formatter="${TextFormatters.RICHTEXT}"
                                   .header="${translate('loc.global.important')}"
                                   .subHeader="${translate('loc.usage.important.message', '@breadstone/mosaik-elements-foundation')}"
                                   .icon="${Icons.WARNING}"></mosaik-banner>
                    <mosaik-text .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate('loc.usage.native.description', globals.name)}"
                                 ?wrap="${true}"></mosaik-text>
                    <app-code-editor .text="${usageNativeHtmlCode}"
                                     .language="${CodeEditorLanguages.HTML}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    </mosaik-stack>
                </mosaik-page-content>

                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-stack orientation="${Orientation.Vertical}"
                            gap="32px">
                    <mosaik-router-anchor ${typography('headline5')}
                                          .label="${translate('loc.usage.angular.title')}"
                                          .ref="${'angular'}"></mosaik-router-anchor>
                    <mosaik-banner .variant="${Variant.Warning}"
                                   .appearance="${Appearance.Soft}"
                                   .formatter="${TextFormatters.RICHTEXT}"
                                   .header="${translate('loc.global.important')}"
                                   .subHeader="${translate('loc.usage.important.message', '@breadstone/mosaik-elements-angular')}"
                                   .icon="${Icons.WARNING}"></mosaik-banner>
                    <mosaik-text .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate('loc.usage.angular.description', globals.name)}"
                                 ?wrap="${true}"></mosaik-text>
                    <app-code-editor .text="${usageAngularTsCode}"
                                     .language="${CodeEditorLanguages.TYPESCRIPT}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    <app-code-editor .text="${usageAngularHtmlCode}"
                                     .language="${CodeEditorLanguages.HTML}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    </mosaik-stack>
                </mosaik-page-content>

                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-stack orientation="${Orientation.Vertical}"
                            gap="32px">
                    <mosaik-router-anchor ${typography('headline5')}
                                          .label="${translate('loc.usage.react.title')}"
                                          .ref="${'react'}"></mosaik-router-anchor>
                    <mosaik-banner .variant="${Variant.Warning}"
                                   .appearance="${Appearance.Soft}"
                                   .formatter="${TextFormatters.RICHTEXT}"
                                   .header="${translate('loc.global.important')}"
                                   .subHeader="${translate('loc.usage.important.message', '@breadstone/mosaik-elements-react')}"
                                   .icon="${Icons.WARNING}"></mosaik-banner>
                    <mosaik-text .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate('loc.usage.react.description', globals.name)}"
                                 ?wrap="${true}"></mosaik-text>
                    <app-code-editor .text="${usageReactTsxCode}"
                                     .language="${CodeEditorLanguages.TYPESCRIPT}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    </mosaik-stack>
                </mosaik-page-content>

                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-stack orientation="${Orientation.Vertical}"
                            gap="32px">
                    <mosaik-router-anchor ${typography('headline5')}
                                          .label="${translate('loc.usage.vue.title')}"
                                          .ref="${'vue'}"></mosaik-router-anchor>
                    <mosaik-banner .variant="${Variant.Warning}"
                                   .appearance="${Appearance.Soft}"
                                   .formatter="${TextFormatters.RICHTEXT}"
                                   .header="${translate('loc.global.important')}"
                                   .subHeader="${translate('loc.usage.important.message', '@breadstone/mosaik-elements-vue')}"
                                   .icon="${Icons.WARNING}"></mosaik-banner>
                    <mosaik-text .formatter="${TextFormatters.RICHTEXT}"
                                 .text="${translate('loc.usage.vue.description', globals.name)}"
                                 ?wrap="${true}"></mosaik-text>
                    <app-code-editor .text="${usageVueTsCode}"
                                     .language="${CodeEditorLanguages.TYPESCRIPT}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    <app-code-editor .text="${usageVueHtmlCode}"
                                     .language="${CodeEditorLanguages.HTML}"
                                     .theme="${CodeEditorTheme.BOYSANDGIRLS}"></app-code-editor>
                    </mosaik-stack>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-usage-view': UsageView;
    }
}
