// #region Imports

import { Component, IconElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { iconElementDocs } from '../../../.Generated/mosaik-icon';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link IconSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-icon-sample'
})
export class IconSampleElement extends SampleBaseElement<IconElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<IconElement> {

        return {
            header: 'Icon',
            description: META.description(IconElement.is),
            graph: META.graph(IconElement.is),
            playground: {
                template: html`
                    <mosaik-icon></mosaik-icon>
                `,
                properties: META.properties<IconElement>(IconElement.is, [
                    {
                        key: 'data',
                        value: 'M12 22zm0-20a10 10 0 00-1.727 19.841v-7.6h-2.61v-3.018h2.61V8.995A3.641 3.641 0 0114.16 5a21.367 21.367 0 012.332.119v2.7h-1.6c-1.255 0-1.5.6-1.5 1.471v1.929h2.993L16 14.245h-2.6v7.647A9.994 9.994 0 0012 2z'
                    }
                ]),
                events: META.events<IconElement>(IconElement.is)
            },
            propertyGrid: {
                properties: META.properties<IconElement>(IconElement.is, [
                    {
                        key: 'data',
                        value: 'M12 22zm0-20a10 10 0 00-1.727 19.841v-7.6h-2.61v-3.018h2.61V8.995A3.641 3.641 0 0114.16 5a21.367 21.367 0 012.332.119v2.7h-1.6c-1.255 0-1.5.6-1.5 1.471v1.929h2.993L16 14.245h-2.6v7.647A9.994 9.994 0 0012 2z'
                    }
                ])
            },
            docs: [
                iconElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-icon-sample': IconSampleElement;
    }
}
