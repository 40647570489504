// #region Imports

import { Appearance, AvatarElement, BadgePosition, Component, FlowDirection, Size, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { avatarElementDocs } from '../../../.Generated/mosaik-avatar';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { exclude, merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link AvatarSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-avatar-sample'
})
export class AvatarSampleElement extends SampleBaseElement<AvatarElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<AvatarElement> {

        return {
            header: 'Avatar',
            description: META.description(AvatarElement.is),
            graph: META.graph(AvatarElement.is),
            playground: {
                template: [
                    ['default', html`
                        <mosaik-avatar></mosaik-avatar>
                    `], ['with badge', html`
                        <mosaik-avatar>
                            <mosaik-badge slot="badge"
                                          .size="${Size.Medium}"
                                          .label="${'1'}"
                                          .appearance="${Appearance.Soft}"
                                          .position="${BadgePosition.TopRight}"
                                          .variant="${Variant.Danger}"></mosaik-badge>
                        </mosaik-avatar>
                    `]
                ],
                properties: META.properties<AvatarElement>(AvatarElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'icon',
                        value: 'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
                    },
                    {
                        key: 'text',
                        value: 'John Doe'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]),
                events: META.events(AvatarElement.is)
            },
            propertyGrid: {
                properties: exclude(merge(META.properties<AvatarElement>(AvatarElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'icon',
                        value: 'M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.599C17.945 20.929 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501ZM12 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z'
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    },
                    {
                        key: 'src',
                        value: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
                    },
                    {
                        key: 'text',
                        value: 'John Doe'
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    }
                ]), [
                    {
                        key: 'textAccessor',
                        template: functionTemplate
                    }
                ] as Array<IPlaygroundProperty<AvatarElement>>, (a, b) => a.key === b.key), [
                    { key: 'hasBadge' },
                    { key: 'displayMode' }

                ] as Array<IPlaygroundProperty<AvatarElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                avatarElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-avatar-sample': AvatarSampleElement;
    }
}
