import { Appearance, BadgePosition, type TemplateResult, Variant, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace DropDownButtonSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-dropdown-button>
                <mosaik-menu>
                    <mosaik-menu-item .label="${'Item 1'}"></mosaik-menu-item>
                    <mosaik-menu-item .label="${'Item 2'}"></mosaik-menu-item>
                    <mosaik-menu-item .label="${'Item 3'}"></mosaik-menu-item>
                </mosaik-menu>
            </mosaik-dropdown-button>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withBadgeTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-dropdown-button>
                <mosaik-badge slot="overlay"
                                .appearance="${Appearance.Soft}"
                                .position="${BadgePosition.TopRight}"
                                .variant="${Variant.Danger}"
                                .label="${'1'}"></mosaik-badge>
                <mosaik-menu>
                    <mosaik-menu-item .label="${'Item 1'}"
                                        force-close="true"></mosaik-menu-item>
                    <mosaik-menu-item .label="${'Item 2'}"
                                        force-close="true"></mosaik-menu-item>
                    <mosaik-menu-item .label="${'Item 3'}"
                                        force-close="true"></mosaik-menu-item>
                </mosaik-menu>
            </mosaik-dropdown-button>
        `;

        return [
            {
                name: 'With Badge',
                description: 'The template with a badge'
            }, template
        ];
    }

    export function withCustomMenuTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-dropdown-button>
                <mosaik-menu>
                    <mosaik-menu-item .label="${'Style'}"
                                        force-close="true">
                        <mosaik-button-group slot="end">
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M6 5.75C6 4.784 6.784 4 7.75 4h4.749c2.823 0 4.751 2.34 4.751 4.75 0 .931-.288 1.852-.803 2.632C17.369 12.239 18 13.47 18 15c0 3.133-2.677 5-5 5H7.75A1.75 1.75 0 0 1 6 18.25V5.75Zm3.5 7.75v3H13c.312 0 .71-.138 1.024-.421.288-.26.476-.615.476-1.079 0-.888-.745-1.5-1.5-1.5H9.5Zm0-3.5h3c.715 0 1.25-.592 1.25-1.25 0-.657-.536-1.25-1.251-1.25H9.5V10Z'}"></mosaik-button>
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M10.649 18.5h3.847a1 1 0 0 1 0 2h-9.5a.997.997 0 0 1-.996-1c0-.552.445-1 .996-1h3.51L13.332 6H9.997a1 1 0 0 1 0-2H18.5a1 1 0 0 1 0 2h-3.025L10.65 18.5Z'}"></mosaik-button>
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M6 4.5a1 1 0 0 1 2 0v6.001c-.003 3.463 1.32 4.999 4.247 4.999 2.928 0 4.253-1.537 4.253-5v-6a1 1 0 1 1 2 0v6c0 4.54-2.18 7-6.253 7S5.996 15.039 6 10.5v-6ZM7 21a1 1 0 1 1 0-2h10.5a1 1 0 1 1 0 2H7Z'}"></mosaik-button>
                        </mosaik-button-group>
                    </mosaik-menu-item>
                    <mosaik-menu-item .label="${'Alignment'}"
                                        force-close="true">
                        <mosaik-button-group slot="end">
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M2 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM2 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM6 18a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
                            <mosaik-button .appearance="${Appearance.Plain}"
                                            .icon="${'M5 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM9 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
                        </mosaik-button-group>
                    </mosaik-menu-item>
                    <mosaik-menu-item .label="${'Font'}"
                                        force-close="true">
                        <mosaik-select style="width: 128px;" slot="end">
                            <mosaik-select-item .label="${'Option 1'}"></mosaik-select-item>
                            <mosaik-select-item .label="${'Option 2'}"></mosaik-select-item>
                        </mosaik-select>
                    </mosaik-menu-item>

                    <mosaik-menu-item .label="${'Item 3'}"
                                        force-close="true">
                    </mosaik-menu-item>
                </mosaik-menu>
            </mosaik-dropdown-button>
        `;

        return [
            {
                name: 'With Custom Menu',
                description: 'The template with a custom menu'
            }, template
        ];
    }

}
