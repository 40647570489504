// #region Imports

import { Appearance, ChipBoxElement, Component, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { Key } from '@breadstone/mosaik-elements-foundation/dist/Controls/Types/Key';
import { html, type CSSResultGroup } from 'lit';
import { chipboxElementDocs } from '../../../.Generated/mosaik-chipbox';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate, functionTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ChipBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-chipbox-sample'
})
export class ChipBoxSampleElement extends SampleBaseElement<ChipBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChipBoxElement> {

        return {
            header: 'Chip Box',
            description: META.description(ChipBoxElement.is),
            graph: META.graph(ChipBoxElement.is),
            playground: {
                template: html`
                    <mosaik-chipbox style="width: 280px"></mosaik-chipbox>
                `,
                properties: META.properties<ChipBoxElement>(ChipBoxElement.is, [
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'value',
                        value: ''
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'label',
                        value: 'Fruits'
                    },
                    {
                        key: 'placeholder',
                        value: 'New fruit...'
                    },
                    {
                        key: 'readonly',
                        value: false
                    },
                    {
                        key: 'isEditable',
                        value: true
                    },
                    {
                        key: 'chips',
                        value: ['🍌 Bannana', '🍇 Grape']
                    },
                    {
                        key: 'separatorKeys',
                        value: [Key.Enter, Key.Comma]
                    }
                ]),
                events: META.events<ChipBoxElement>(ChipBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ChipBoxElement>(ChipBoxElement.is, [
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'value',
                        value: ''
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'label',
                        value: 'Fruits'
                    },
                    {
                        key: 'placeholder',
                        value: 'New fruit...'
                    },
                    {
                        key: 'readonly',
                        value: false
                    },
                    {
                        key: 'isEditable',
                        value: true
                    },
                    {
                        key: 'chips',
                        value: ['🍌 Bannana', '🍇 Grape']
                    },
                    {
                        key: 'separatorKeys',
                        value: [Key.Enter, Key.Comma]
                    }
                ]), [
                    {
                        key: 'formatter',
                        template: functionTemplate
                    },
                    {
                        key: 'parser',
                        template: functionTemplate
                    },
                    {
                        key: 'transform',
                        template: functionTemplate
                    },
                    {
                        key: 'chips',
                        template: arrayTemplate
                    },
                    {
                        key: 'separatorKeys',
                        template: arrayTemplate
                    }
                ] as Array<IPlaygroundProperty<ChipBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                chipboxElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-chipbox-sample': ChipBoxSampleElement;
    }
}
