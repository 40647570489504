import { type TemplateResult, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace AnchorSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-anchor></mosaik-anchor>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

}
