// #region Imports

import { Component, FormController, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { InquireService } from '../../Backend/Api/Services/InquireService';
import { ViewBase } from '../Abstracts/ViewBase';
import { contactViewTemplate } from './ContactViewTemplate';

// #endregion

/**
 * The `{@link ContactView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-contact-view',
    template: contactViewTemplate,
    providers: [
        {
            provide: InquireService,
            useClass: InquireService
        }
    ]
})
export class ContactView extends ViewBase {

    // #region Fields

    @Inject(InquireService)
    private readonly _inquireService!: InquireService;
    private readonly _formController: FormController;
    private _firstName: string;
    private _lastName: string;
    private _email: string;
    private _message: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._firstName = '';
        this._lastName = '';
        this._email = '';
        this._message = '';
        this._formController = new FormController(this, {
            queries: {
                contact: '[name="contact"]'
            }
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-contact-view';
    }

    /**
     * Gets or sets the `firstName` property.
     *
     * @public
     */
    @Property({ type: String })
    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(value: string) {
        if (this._firstName !== value) {
            this._firstName = value;
            this.requestUpdate('firstName');
        }
    }

    /**
     * Gets or sets the `lastName` property.
     *
     * @public
     */
    @Property({ type: String })
    public get lastName(): string {
        return this._lastName;
    }

    public set lastName(value: string) {
        if (this._lastName !== value) {
            this._lastName = value;
            this.requestUpdate('lastName');
        }
    }

    /**
     * Gets or sets the `email` property.
     *
     * @public
     */
    @Property({ type: String })
    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        if (this._email !== value) {
            this._email = value;
            this.requestUpdate('email');
        }
    }

    /**
     * Gets or sets the `message` property.
     *
     * @public
     */
    @Property({ type: String })
    public get message(): string {
        return this._message;
    }

    public set message(value: string) {
        if (this._message !== value) {
            this._message = value;
            this.requestUpdate('message');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @template
     */
    public onSubmit(): void {
        const formFacade = this._formController.get('contact');

        if (formFacade?.reportValidity()) {
            this.isBusy = true;

            void this._inquireService
                .create({
                    email: this._email,
                    firstName: this._firstName,
                    lastName: this._lastName,
                    message: this._message
                })
                .then(() => {
                    // TODO: Handle success
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-contact-view': ContactView;
    }
}
