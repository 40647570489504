// #region Imports

import { ApiError } from '@backend/ApiError';
import { Command, Component, FormController, ICommand, Inject, IPropertyChangedEventDetail, Property, ToastServiceLocator, TranslatorServiceLocator, Variant, Watch } from '@breadstone/mosaik-elements-foundation';
import { AuthService } from '../../Backend/Api/Services/AuthService';
import { SessionManager } from '../../Services/SessionManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { authViewStyle } from './AuthViewStyle';
import { authViewTemplate } from './AuthViewTemplate';

// #endregion

/**
 * The `{@link AuthView}` view.
 *
 * @public
 */
@Component({
    selector: 'app-auth-view',
    template: authViewTemplate,
    styles: authViewStyle,
    imports: []
})
export class AuthView
    extends ViewBase {

    // #region Fields

    @Inject(AuthService)
    private readonly _authService!: AuthService;
    @Inject(SessionManager)
    private readonly _sessionManager!: SessionManager;
    private readonly _formController: FormController;
    private _isUnverified: boolean;
    private _currentLogin: string;
    private _hasAvatar: boolean;
    private _isPasswordShown: boolean;
    private _countdown: number;
    private readonly _submitCommand: Command<'login' | 'register'>;
    private readonly _passwordForgotCommand: Command;
    private readonly _pinChangCommand: Command<IPropertyChangedEventDetail>;
    private readonly _pinResetCommand: Command;
    private readonly _passwordRevealCommand: Command;
    private readonly _passwordConcealCommand: Command;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._isUnverified = false;
        this._currentLogin = '';
        this._hasAvatar = false;
        this._isPasswordShown = false;
        this._countdown = 0;
        this._submitCommand = new Command<'login' | 'register'>((x) => this.onExecuteSubmitCommand(x));
        this._pinChangCommand = new Command((x) => this.onExecutePinChangedCommand(x));
        this._pinResetCommand = new Command(() => this.onExecutePinResetCommand());
        this._passwordRevealCommand = new Command(() => this.onExecutePasswordRevealCommand());
        this._passwordConcealCommand = new Command(() => this.onExecutePasswordConcealCommand());
        this._passwordForgotCommand = new Command(() => this.onExecutePasswordForgotCommand());
        this._formController = new FormController(this, {
            queries: {
                login: '[name="login"]',
                register: '[name="register"]'
            }
        });
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `isUnverified` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isUnverified(): boolean {
        return this._isUnverified;
    }
    public set isUnverified(value: boolean) {
        if (this._isUnverified !== value) {
            this._isUnverified = value;
            this.requestUpdate('isUnverified');
        }
    }

    /**
     * Gets or sets the `hasAvatar` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Boolean })
    public get hasAvatar(): boolean {
        return this._hasAvatar;
    }
    private set hasAvatar(value: boolean) {
        if (this._hasAvatar !== value) {
            this._hasAvatar = value;
            this.requestUpdate('hasAvatar');
        }
    }

    /**
     * Gets or sets the `isPasswordShown` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isPasswordShown(): boolean {
        return this._isPasswordShown;
    }
    public set isPasswordShown(value: boolean) {
        if (this._isPasswordShown !== value) {
            this._isPasswordShown = value;
            this.requestUpdate('isPasswordShown');
        }
    }

    /**
     * Gets or sets the `countdown` property.
     *
     * @public
     */
    @Property({ type: Number })
    public get countdown(): number {
        return this._countdown;
    }
    public set countdown(value: number) {
        if (this._countdown !== value) {
            this._countdown = value;
            this.requestUpdate('countdown');
        }
    }

    @Property({ type: String })
    public get avatarText(): string {
        return this._formController.get('register')?.get<string>('firstName') ?? '';
    }

    /**
     * Returns the `submitCommand` command property.
     *
     * @public
     * @readonly
     */
    public get submitCommand(): ICommand<'login' | 'register'> {
        return this._submitCommand;
    }

    /**
     * Returns the `passwordForgotCommand` command property.
     *
     * @public
     * @readonly
     */
    public get passwordForgotCommand(): ICommand {
        return this._passwordForgotCommand;
    }

    /**
     * Returns the `pinChangedCommand` command property.
     *
     * @public
     * @readonly
     */
    public get pinChangedCommand(): ICommand<IPropertyChangedEventDetail> {
        return this._pinChangCommand;
    }

    /**
     * Returns the `pinResetCommand` command property.
     *
     * @public
     * @readonly
     */
    public get pinResetCommand(): ICommand {
        return this._pinResetCommand;
    }

    /**
     * Returns the `passwordRevealCommand` command property.
     *
     * @public
     * @readonly
     */
    public get passwordRevealCommand(): ICommand {
        return this._passwordRevealCommand;
    }

    /**
     * Returns the `passwordConcealCommand` command property.
     *
     * @public
     * @readonly
     */
    public get passwordConcealCommand(): ICommand {
        return this._passwordConcealCommand;
    }

    // #endregion

    // #region Methods

    /**
     * Executes the `submitCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteSubmitCommand(parameter: 'login' | 'register'): void {
        const formFacade = this._formController.get(parameter);

        if (formFacade?.reportValidity()) {
            this.isBusy = true;

            if (formFacade.name === 'login') {
                void this._authService
                    .login({
                        body: {
                            login: this._currentLogin = formFacade.get<string>('login'),
                            password: formFacade.get<string>('password')
                        }
                    })
                    .then((x) => {
                        void this._authService.me({
                            authorization: x.accessToken
                        }).then((y) => {
                            this._sessionManager.handshake(x.accessToken, y);
                            window.location.href = '/';
                        });
                    })
                    .catch((error: ApiError) => {
                        if (error.statusCode === 401) {
                            this.isUnverified = true;
                        } else {
                            void ToastServiceLocator.current.open({
                                header: TranslatorServiceLocator.current.translate('loc.global.error'),
                                variant: Variant.Danger,
                                content: error.message
                            });
                        }
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            } else if (formFacade.name === 'register') {
                void this._authService
                    .register({
                        body: {
                            email: this._currentLogin = formFacade.get<string>('email'),
                            password: formFacade.get<string>('password'),
                            profile: {
                                avatar: formFacade.get<string>('avatar'),
                                firstName: formFacade.get<string>('firstName'),
                                lastName: formFacade.get<string>('lastName'),
                                userName: formFacade.get<string>('userName')
                            }
                        }
                    })
                    .then((x) => {
                        this.isUnverified = true;
                    })
                    .catch(() => {
                        void ToastServiceLocator.current.open({
                            header: TranslatorServiceLocator.current.translate('loc.global.error'),
                            variant: Variant.Danger
                        });

                        this.isUnverified = false;
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        }
    }

    /**
     * Executes the `pinChangedCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecutePinChangedCommand(parameter: IPropertyChangedEventDetail): void {
        if (parameter.propertyName === 'value') {
            if (String(parameter.value).length === 6) {
                this.isBusy = true;
                void this._authService
                    .verifyPin({
                        body: {
                            pin: String(parameter.value),
                            login: this._currentLogin
                        }
                    })
                    .then(() => {
                        this.isUnverified = false;
                    })
                    .catch(() => {
                        void ToastServiceLocator.current.open({
                            header: TranslatorServiceLocator.current.translate('loc.global.error'),
                            variant: Variant.Danger
                        });
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        }
    }

    /**
     * Executes the `pinResetCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecutePinResetCommand(): void {
        void this._authService
            .resendVerification({
                body: {
                    login: this._currentLogin
                }
            })
            .then(() => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.success'),
                    content: TranslatorServiceLocator.current.translate('loc.auth.pin.resend'),
                    variant: Variant.Success
                });
            })
            .catch(() => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.error'),
                    variant: Variant.Danger
                });
            });
    }

    /**
     * Executes the `passwordForgotCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecutePasswordForgotCommand(): void {
        this._authService
            .forgotPassword({
                body: {
                    login: this._currentLogin
                }
            })
            .then(() => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.success'),
                    content: TranslatorServiceLocator.current.translate('loc.auth.password.forgot'),
                    variant: Variant.Success
                });
            })
            .catch(() => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.error'),
                    variant: Variant.Danger
                });
            });
    }

    /**
     * Executes the `passwordRevealCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecutePasswordRevealCommand(): void {
        this.isPasswordShown = true;
    }

    /**
     * Executes the `passwordConcealCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecutePasswordConcealCommand(): void {
        this.isPasswordShown = false;
    }

    /**
     * @private
     */
    @Watch('isUnverified')
    private onIsUnverifiedChanged(): void {
        if (this.isUnverified) {
            this.countdown = 60;

            const interval = setInterval(() => {
                this.countdown--;

                if (this.countdown === 0) {
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    // #endregion

}
