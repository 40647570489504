
export namespace BrowserInfo {

    export function get(): {
        name: string;
        version: string;
        osName: string;
        osVersion: string;
    } {
        const ua = navigator.userAgent;
        let tem: RegExpExecArray | null = null;
        let match = (/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i).exec(ua) ?? [];

        if ((/trident/i).test(match[1])) {
            tem = (/\brv[ :]+(\d+)/g).exec(ua) ?? null;
            return {
                name: 'IE',
                version: tem ? tem[1] || '' : '',
                osName: 'Unknown OS',
                osVersion: 'Unknown OS Version'
            };
        }

        if (match[1] === 'Chrome') {
            tem = (/\b(OPR|Edge)\/(\d+)/).exec(ua);
            if (tem !== null) {
                return {
                    name: tem[1].replace('OPR', 'Opera'),
                    version: tem[2],
                    osName: 'Unknown OS',
                    osVersion: 'Unknown OS Version'
                };
            }
        }

        match = match[2]
            ? [match[1], match[2]]
            : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = (/version\/(\d+)/i).exec(ua)) != null) {
            match.splice(1, 1, tem[1]);
        }

        const osMatch = (/(windows|mac|linux|ubuntu|android|ios)/i).exec(ua);
        const os = osMatch ? osMatch[0] : 'Unknown OS';
        // Improved regex to capture the full version string
        const osVersionMatch = (/(?:Mac OS X|windows|mac|linux|ubuntu|android|ios)[\s_]?([0-9_\.]+)/i).exec(ua);
        const osVersion = osVersionMatch ? osVersionMatch[1].replace(/_/g, '.') : 'Unknown OS Version';

        return {
            name: match[0],
            version: match[1],
            osName: os,
            osVersion: osVersion
        };
    }

    export function toString(): string {
        const info = get();
        return `\n\n==============================\n${info.name} ${info.version} on ${info.osName} ${info.osVersion}`;
    }

}
