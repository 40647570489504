// #region Imports

import { Component, DialogServiceLocator, FormElement, Inject, Property, Query } from '@breadstone/mosaik-elements-foundation';
import { IFeedbackTypeResponse } from '../../../Backend/Api/Models/IFeedbackTypeResponse';
import { IUserResponse } from '../../../Backend/Api/Models/IUserResponse';
import { FeedbackService } from '../../../Backend/Api/Services/FeedbackService';
import { SessionManager } from '../../../Services/SessionManager';
import { ViewBase } from '../../Abstracts/ViewBase';
import { createFeedbackViewStyle } from './CreateFeedbackViewStyle';
import { createFeedbackViewTemplate } from './CreateFeedbackViewTemplate';

// #endregion

/**
 * The `{@link CreateFeedbackView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-create-feedback-view',
    template: createFeedbackViewTemplate,
    styles: createFeedbackViewStyle,
    imports: []
})
export class CreateFeedbackView
    extends ViewBase {

    // #region Fields

    @Inject(FeedbackService) private readonly _feedbackService!: FeedbackService;
    @Inject(SessionManager) private readonly _sessionManager!: SessionManager;
    @Query('[part="form"]') private readonly _formElement!: FormElement;
    private _feedbackTypes: Array<IFeedbackTypeResponse>;
    private _subject: string;
    private _description: string;
    private _feedbackType: IFeedbackTypeResponse | null;
    private _user: IUserResponse | null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._subject = '';
        this._description = '';
        this._feedbackType = null;
        this._feedbackTypes = [];
        this._user = null;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-create-feedback-view';
    }

    /**
     * Gets or sets the `subject` property.
     *
     * @public
     */
    @Property({ type: String })
    public get subject(): string {
        return this._subject;
    }

    public set subject(value: string) {
        if (this._subject !== value) {
            this._subject = value;
            this.requestUpdate('subject');
        }
    }

    /**
     * Gets or sets the `description` property.
     *
     * @public
     */
    @Property({ type: String })
    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        if (this._description !== value) {
            this._description = value;
            this.requestUpdate('description');
        }
    }

    /**
     * Gets or sets the `feedbackType` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get feedbackType(): IFeedbackTypeResponse | null {
        return this._feedbackType;
    }

    public set feedbackType(value: IFeedbackTypeResponse | null) {
        if (this._feedbackType !== value) {
            this._feedbackType = value;
            this.requestUpdate('feedbackType');
        }
    }

    /**
     * Gets the `feedbackTypes` property.
     *
     * @public
     */
    @Property({ type: Array })
    public get feedbackTypes(): Array<IFeedbackTypeResponse> {
        return this._feedbackTypes;
    }

    public set feedbackTypes(value: Array<IFeedbackTypeResponse>) {
        if (this._feedbackTypes !== value) {
            this._feedbackTypes = value;
            this.requestUpdate('feedbackTypes');
        }
    }

    /**
     * Gets or sets the `user` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get user(): IUserResponse | null {
        return this._user;
    }

    public set user(value: IUserResponse | null) {
        if (this._user !== value) {
            this._user = value;
            this.requestUpdate('user');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        void this.initailize();
    }

    /**
     * @internal
     * @template
     */
    public onCreateFeedback(): void {
        const isValid = this._formElement.reportValidity();

        if (isValid) {
            this.isBusy = true;

            void this._feedbackService.create({
                authorization: this._sessionManager.session,
                body: {
                    title: this._subject,
                    body: this._description,
                    typeId: this._feedbackType?.id
                }
            }).finally(() => {
                this.isBusy = false;
                void DialogServiceLocator.current.close(CreateFeedbackView.is);
            });
        }
    }

    /**
     * @private
     */
    private async initailize(): Promise<void> {
        try {
            this.user = this._sessionManager.me;
            this.feedbackTypes = await this._feedbackService.types();
        } catch (error) {
            // console.error(error);
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-create-feedback-view': CreateFeedbackView;
    }
}
