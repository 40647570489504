import { Appearance, BadgePosition, type TemplateResult, Variant, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace FloatingActionButtonSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-fab></mosaik-fab>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withBadgeTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-fab>
                <mosaik-badge slot="overlay"
                                .appearance="${Appearance.Soft}"
                                .position="${BadgePosition.TopRight}"
                                .variant="${Variant.Danger}"
                                .label="${'1'}"></mosaik-badge>
            </mosaik-fab>
        `;

        return [
            {
                name: 'With Badge',
                description: 'The template with a badge'
            }, template
        ];
    }

}
