// #region Imports

import { Command, Component, DialogServiceLocator, DomServiceLocator, ICommand, Inject, Persisted, PlatformController, Property, RouterElement, State, ThemeLayout, ThemeServiceLocator, ThemeTypography, TranslatorServiceLocator, on, type CookiesConsentElement, type IPortalAttachedEventDetail, type IRouterNavigatedEventDetail, type IThemeLayout, type IThemeTypography, type PortalProjectionElement, type ThemeElement } from '@breadstone/mosaik-elements-foundation';
import { IDeploymentInfoResponse } from '../../Backend/Api/Models/IDeploymentInfoResponse';
import { DeploymentsService } from '../../Backend/Api/Services/DeploymentsService';
import { BackTopElement } from '../../Components/BackTop/BackTopElement';
import { MadeWithLoveElement } from '../../Components/MadeWithLove/MadeWithLoveElement';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { AppBar } from './AppBar';
import { appStyle } from './AppStyle';
import { appElementTemplate } from './AppTemplate';
import { AppSettingsView } from './Dialogs/AppSettingsView';

// #endregion

/**
 * The `AppElement` element.
 *
 * @public
 */
@Component({
    selector: 'app-root',
    styles: appStyle,
    template: appElementTemplate,
    imports: [
        AppBar,
        BackTopElement,
        MadeWithLoveElement
    ],
    providers: [
        {
            provide: DeploymentsService,
            useClass: DeploymentsService
        }
    ]
})
export class AppElement
    extends ViewBase {

    // #region Fields

    @Inject(DeploymentsService)
    private readonly _deploymentsService!: DeploymentsService;
    private readonly _platformController: PlatformController;
    private readonly _acceptCookiesCommand: Command;
    private readonly _settingsCommand: Command;
    private readonly _attachPortalCommand: Command<IPortalAttachedEventDetail>;
    private readonly _name: string;
    private readonly _year: number;
    private _scheme: string;
    private _preset: {
        key: string;
        default: boolean;
        primary: string;
        secondary: string;
        tertiary: string;
    };
    private _layout: IThemeLayout;
    private _typography: IThemeTypography;
    private _nextRouteName: string;
    private _nextRoutePath: string;
    private _previousRouteName: string;
    private _previousRoutePath: string;
    private _hasNavigation: boolean;
    private _hasMenu: boolean;
    private _isDrawerOpen: boolean;
    private _hasFooter: boolean;
    private _hasHeader: boolean;
    private _hasFeedback: boolean;
    private _hasCookies: boolean;
    private _language: string;
    private _deploymentDate: Date;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._scheme = globals.theme.schemes.find((x) => x.default)!.key;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._preset = globals.theme.presets.find((x) => x.default)!;
        this._layout = {
            radius: globals.theme.layout.radius,
            space: ThemeLayout.DEFAULT.space,
            thickness: ThemeLayout.DEFAULT.thickness
        };
        this._typography = {
            fontFamily: globals.theme.typographies.find((x) => x.default)?.fontFamily,
            types: ThemeTypography.DEFAULT.types
        };
        this._name = globals.name;
        this._year = new Date(Date.now()).getFullYear();
        this._nextRouteName = '';
        this._nextRoutePath = '';
        this._previousRouteName = '';
        this._previousRoutePath = '';
        this._hasNavigation = false;
        this._isDrawerOpen = false;
        this._hasFooter = false;
        this._hasHeader = true;
        this._hasFeedback = false;
        this._hasCookies = false;
        this._hasMenu = false;
        this._language = '';
        this._deploymentDate = new Date();

        this._platformController = new PlatformController(this);
        this._acceptCookiesCommand = new Command(() => this.onExecuteAcceptCookiesCommand());
        this._settingsCommand = new Command(() => this.onExecuteSettingsCommand());
        this._attachPortalCommand = new Command((x) => this.onExecuteAttachPortalCommand(x));
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-root';
    }

    @State()
    public cookiesConsentProjection: PortalProjectionElement | null = null;

    /**
     * Gets or sets the `language` property.
     *
     * @public
     */
    @Property({ type: String })
    @Persisted({
        storageKey: 'language',
        defaultValue: 'en'
    })
    public get language(): string {
        return this._language;
    }
    public set language(value: string) {
        if (this._language !== value) {
            this._language = value;
            this.requestUpdate('language');
        }
    }

    /**
     * Gets or sets the `scheme` property.
     *
     * @public
     */
    @Property({ type: String })
    @Persisted({
        storageKey: 'scheme',
        defaultValue: 'light'
    })
    public get scheme(): string {
        return this._scheme;
    }
    private set scheme(value: string) {
        if (this._scheme !== value) {
            this._scheme = value;
            this.requestUpdate('scheme');
        }
    }

    /**
     * Gets or sets the `preset` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    @Persisted({ storageKey: 'preset' })
    public get preset(): {
        key: string;
        default: boolean;
        primary: string;
        secondary: string;
        tertiary: string;
    } {
        return this._preset;
    }
    private set preset(value: {
        key: string;
        default: boolean;
        primary: string;
        secondary: string;
        tertiary: string;
    }) {
        if (this._preset.key !== value.key) {
            this._preset = value;
            this.requestUpdate('preset');

            void this.updateComplete.then(() => {
                ThemeServiceLocator.current.applyPalette(value.primary, 'primary', '500');
                ThemeServiceLocator.current.applyPalette(value.secondary, 'secondary', '500');
                ThemeServiceLocator.current.applyPalette(value.tertiary, 'tertiary', '500');
            });
        }
    }

    /**
     * Gets or sets the `layout` property.
     *
     * @public
     */
    @Property({ type: Object })
    @Persisted({
        storageKey: 'layout',
        defaultValue: ThemeLayout.DEFAULT
    })
    public get layout(): IThemeLayout {
        return this._layout;
    }
    private set layout(value: IThemeLayout) {
        if (this._layout.radius !== value.radius) {
            this._layout = value;
            this.requestUpdate('layout');
        }
    }

    /**
     * Gets or sets the `deploymentDate` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Date })
    public get deploymentDate(): Date {
        return this._deploymentDate;
    }
    private set deploymentDate(value: Date) {
        if (this._deploymentDate !== value) {
            this._deploymentDate = value;
            this.requestUpdate('deploymentDate');
        }
    }

    /**
     * Gets or sets the `typography` property.
     *
     * @public
     */
    @Property({ type: Object })
    @Persisted({
        storageKey: 'typography',
        defaultValue: ThemeTypography.DEFAULT
    })
    public get typography(): IThemeTypography {
        return this._typography;
    }
    private set typography(value: IThemeTypography) {
        if (this._typography.fontFamily !== value.fontFamily) {
            this._typography = value;
            this.requestUpdate('typography');
        }
    }

    /**
     * Gets or sets the `nextRouteName` property.
     *
     * @public
     */
    @Property({ type: String })
    public get nextRouteName(): string {
        return this._nextRouteName;
    }
    public set nextRouteName(value: string) {
        if (this._nextRouteName !== value) {
            this._nextRouteName = value;
            this.requestUpdate('nextRouteName');
        }
    }

    /**
     * Gets or sets the `nextRoutePath` property.
     *
     * @public
     */
    @Property({ type: String })
    public get nextRoutePath(): string {
        return this._nextRoutePath;
    }
    public set nextRoutePath(value: string) {
        if (this._nextRoutePath !== value) {
            this._nextRoutePath = value;
            this.requestUpdate('nextRoutePath');
        }
    }

    /**
     * Gets or sets the `previousRouteName` property.
     *
     * @public
     */
    @Property({ type: String })
    public get previousRouteName(): string {
        return this._previousRouteName;
    }
    public set previousRouteName(value: string) {
        if (this._previousRouteName !== value) {
            this._previousRouteName = value;
            this.requestUpdate('previousRouteName');
        }
    }

    /**
     * Gets or sets the `previousRoutePath` property.
     *
     * @public
     */
    @Property({ type: String })
    public get previousRoutePath(): string {
        return this._previousRoutePath;
    }
    public set previousRoutePath(value: string) {
        if (this._previousRoutePath !== value) {
            this._previousRoutePath = value;
            this.requestUpdate('previousRoutePath');
        }
    }

    /**
     * Gets or sets the `hasNavigation` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get hasNavigation(): boolean {
        return this._hasNavigation;
    }
    public set hasNavigation(value: boolean) {
        if (this._hasNavigation !== value) {
            this._hasNavigation = value;
            this.requestUpdate('hasNavigation');
        }
    }

    /**
     * Gets or sets the `hasFooter` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get hasFooter(): boolean {
        return this._hasFooter;
    }
    public set hasFooter(value: boolean) {
        if (this._hasFooter !== value) {
            this._hasFooter = value;
            this.requestUpdate('hasFooter');
        }
    }

    /**
     * Gets or sets the `hasHeader` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get hasHeader(): boolean {
        return this._hasHeader;
    }
    public set hasHeader(value: boolean) {
        if (this._hasHeader !== value) {
            this._hasHeader = value;
            this.requestUpdate('hasHeader');
        }
    }

    /**
     * Gets or sets the `hasFeedback` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get hasFeedback(): boolean {
        return this._hasFeedback;
    }
    public set hasFeedback(value: boolean) {
        if (this._hasFeedback !== value) {
            this._hasFeedback = value;
            this.requestUpdate('hasFeedback');
        }
    }

    /**
     * Gets or sets the `isDrawerOpen` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get isDrawerOpen(): boolean {
        return this._isDrawerOpen && !this.isMobile;
    }
    public set isDrawerOpen(value: boolean) {
        if (this._isDrawerOpen !== value) {
            this._isDrawerOpen = value;
            this.requestUpdate('isDrawerOpen');
        }
    }

    /**
     * Gets or sets the `hasCookies` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    @Persisted({ storageKey: 'cookies' })
    public get hasCookies(): boolean {
        return this._hasCookies;
    }
    public set hasCookies(value: boolean) {
        if (this._hasCookies !== value) {
            this._hasCookies = value;
            this.requestUpdate('hasCookies');
        }
    }

    /**
     * Gets or sets the `hasMenu` property.
     *
     * @public
     */
    @Property({ type: Boolean })
    public get hasMenu(): boolean {
        return this._hasMenu;
    }
    public set hasMenu(value: boolean) {
        if (this._hasMenu !== value) {
            this._hasMenu = value;
            this.requestUpdate('hasMenu');
        }
    }

    /**
     * Returns the `name` property.
     *
     * @public
     * @readonly
     */
    public get name(): string {
        return this._name;
    }

    /**
     * Returns the `name` property.
     *
     * @public
     * @readonly
     */
    public get year(): number {
        return this._year;
    }

    /**
     * Returns the `acceptCookiesCommand` command property.
     *
     * @public
     * @readonly
     */
    public get acceptCookiesCommand(): ICommand {
        return this._acceptCookiesCommand;
    }

    /**
     * Returns the `settingsCommand` command property.
     *
     * @public
     * @readonly
     */
    public get settingsCommand(): ICommand {
        return this._settingsCommand;
    }

    /**
     * Returns the `attachPortalCommand` command property.
     *
     * @public
     * @readonly
     */
    public get attachPortalCommand(): ICommand<IPortalAttachedEventDetail> {
        return this._attachPortalCommand;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();

        // updates the root element when the bootstrap event is emitted
        on(document, 'bootstrap' as any, () => {
            this.requestUpdate();
        });
    }

    /**
     * @protected
     * @override
     */
    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        void this.updateComplete.then(() => {
            ThemeServiceLocator.current.applyPalette(this._preset.primary, 'primary', '500');
            ThemeServiceLocator.current.applyPalette(this._preset.secondary, 'secondary', '500');
            ThemeServiceLocator.current.applyPalette(this._preset.tertiary, 'tertiary', '500');
        });

        const router = DomServiceLocator.current.findDescendant(this.renderRoot, RouterElement, { strict: true });

        try {
            const consent = this.getTemplatePart<CookiesConsentElement>('consent');

            consent.intl.messageLabel = TranslatorServiceLocator.current.translate('loc.cookiesConsent.message');
            consent.intl.acceptLabel = TranslatorServiceLocator.current.translate('loc.cookiesConsent.accept');
        } catch (_error: unknown) {
            // nothing to do
        }

        on(router, 'navigated', (x: CustomEvent<IRouterNavigatedEventDetail>) => {
            this.hasNavigation = x.detail.route.data?.hasNavigation !== false;
            this.isDrawerOpen = x.detail.route.data?.isDrawerOpen !== false;
            this.hasFooter = x.detail.route.data?.hasFooter !== false;
            this.hasHeader = x.detail.route.data?.hasHeader !== false;
            this.hasFeedback = x.detail.route.data?.hasFeedback !== false;
            this.hasMenu = x.detail.route.data?.hasMenu !== false;

            this.previousRouteName = router.previousRoute?.name ?? '';
            this.previousRoutePath = router.previousRoute?.path ?? '';
            this.nextRouteName = router.nextRoute?.name ?? '';
            this.nextRoutePath = router.nextRoute?.path ?? '';
        });

        on(this, 'requestNextSample' as any, () => {
            void router.navigate(this.nextRoutePath);
        });

        on(this, 'requestPreviousSample' as any, () => {
            void router.navigate(this.previousRoutePath);
        });
    }

    /**
     * @private
     */
    private initialize(): void {
        if (!import.meta.env.DEV) {
            void this._deploymentsService.getDeployment({ id: '' })
                .then((deployment: IDeploymentInfoResponse) => {
                    this.deploymentDate = new Date(deployment.readyAt);
                });
        }
    }

    /**
     * Executes the `acceptCookiesCommand` command.
     *
     * @private
     */
    private onExecuteAcceptCookiesCommand(): void {
        this.hasCookies = true;
        this.cookiesConsentProjection?.remove();
    }

    /**
     * Executes the `settingsCommand` command.
     *
     * @private
     */
    private onExecuteSettingsCommand(): void {
        void DialogServiceLocator.current.open(AppSettingsView.is, AppSettingsView, undefined, {
            scheme: this.scheme,
            preset: this.preset,
            layout: this.layout,
            font: this.typography,
            language: this.language
        }).then((result: any) => {
            if (result) {
                const theme = this.getTemplatePart<ThemeElement>('theme');
                //
                theme.scheme = result.scheme;
                this.scheme = result.scheme;

                //
                // TODO: this is completely wrong, the layout should be updated in the theme element
                theme.layout = {
                    ...theme.layout,
                    radius: result.layout.radius
                };
                this.layout = theme.layout;

                //
                this.preset = result.preset;

                //
                // TODO: this is completely wrong, the typography should be updated in the theme element
                theme.typography = {
                    ...theme.typography,
                    fontFamily: result.font
                };
                this.typography = theme.typography;

                //
                this.language = result.language;
                TranslatorServiceLocator.current.currentLanguage = result.language;
            }
        });
    }

    /**
     * Executes the `attachPortalCommand` command.
     *
     * @private
     * @param parameter - The command parameter.
     */
    private onExecuteAttachPortalCommand(parameter: IPortalAttachedEventDetail): void {
        this.cookiesConsentProjection = parameter.projection;
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-root': AppElement;
    }
}
