export namespace ObjectExtensions {

    export function get<T extends object>(object: T, property: keyof T): T[keyof T] {
        return object[property];
    }

    export function set<T extends object>(object: T, property: keyof T, value: T[keyof T]): boolean {
        if (isWiriteable(object, property) && has(object, property)) {
            object[property] = value;
            return true;
        }

        return false;
    }

    function has<T extends object>(object: T, property: keyof T): boolean {
        if (property in object) {
            return true;
        }

        return false;
    }

    function isWiriteable<T extends object>(object: T, property: keyof T): boolean {
        let currentPrototype = Object.getPrototypeOf(object);

        while (currentPrototype !== null) {
            const descriptor = Object.getOwnPropertyDescriptor(currentPrototype, property);

            if (descriptor) {
                if (descriptor.get && !descriptor.set) {
                    // If there's only a getter, it's not writable
                    return false;
                }

                if (!descriptor.set) {
                    // If there's no setter, it's not writable
                    return false;
                }

                return true;
            }

            currentPrototype = Object.getPrototypeOf(currentPrototype);
        }

        return false;
    }

}
