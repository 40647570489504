//#region Imports

import { ElementIntlBase } from '@breadstone/mosaik-elements-foundation';

//#endregion

/**
 * The `ApiDocsLayoutElementIntl` class.
 *
 * @public
 */
export class ApiDocsLayoutElementIntl extends ElementIntlBase {

    //#region Fields

    private _propertiesCaption: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ApiDocsLayoutElementIntl` class.
     *
     * @public
     */
    public constructor(locale?: string) {
        super(locale);

        this._propertiesCaption = 'Properties';
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `propertiesCaption` property.
     *
     * @public
     */
    public get propertiesCaption(): string {
        return this._propertiesCaption;
    }
    public set propertiesCaption(value: string) {
        this._propertiesCaption = value;
    }



    //#endregion

}
