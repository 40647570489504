
// #region Imports

import { Appearance, DrawerMode, DrawerPosition, Fit, Inset, Orientation, type TemplateResult, html, repeat } from '@breadstone/mosaik-elements-foundation';
import { VIEWPORTS } from '../../Components/ViewPort/ViewPorts';
import type { GridGeneratorView } from './GridGeneratorView';

// #endregion

/**
 * The template of the {@link GridGeneratorView}.
 *
 * @public
 */
export function gridGeneratorViewElementTemplate<T extends GridGeneratorView>(e: T): TemplateResult {
    return html`
        <mosaik-page .breakpoint="${'lg'}">
            <mosaik-page-header slot="header"
                                .inset="${Inset.All}"
                                .text="${'Grid Generator'}"></mosaik-page-header>
            <mosaik-page-content slot="content"
                                 .inset="${Inset.All}">
                <mosaik-drawer-container .fit="${Fit.Both}">
                    <mosaik-drawer slot="drawer"
                                    .isOpen="${true}"
                                    .mode="${DrawerMode.Side}"
                                    .position="${DrawerPosition.Right}">
                        <mosaik-stack .orientation="${Orientation.Vertical}">
                            <mosaik-card .header="${'Screen'}" .fit="${Fit.Width}" .appearance="${Appearance.Plain}">
                                <mosaik-stack .gap="${'16px'}" .orientation="${Orientation.Vertical}">
                                    <mosaik-form-field .label="${'Zoom'}">
                                        <mosaik-numberbox .value="${e.rows}"
                                                          .min="${0}"
                                                          .max="${10}"
                                                          @input="${(x: InputEvent) => e.onRowsChanged(x)}">
                                            <mosaik-button slot="prefix"
                                                           .text="${'Zoom out'}"
                                                           .icon="${''}"
                                                           @click="${() => e.viewModel.toggleZoom(e.viewModel.zoom, 'decrease')}"></mosaik-button>
                                            <mosaik-button slot="suffix"
                                                           .text="${'Zoom in'}"
                                                           .icon="${''}"
                                                           @click="${() => e.viewModel.toggleZoom(e.viewModel.zoom, 'increase')}"></mosaik-button>
                                        </mosaik-numberbox>
                                    </mosaik-form-field>
                                    <mosaik-form-field>
                                        <mosaik-select>
                                            ${repeat(VIEWPORTS, (x) => x.name, (x) => html`
                                                <mosaik-select-item .value="${x}" .label="${x.name}"></mosaik-select-item>
                                            `)}
                                        </mosaik-select>
                                    </mosaik-form-field>
                                </mosaik-stack>
                            </mosaik-card>
                            <mosaik-card .header="${'Grid'}" .fit="${Fit.Width}" .appearance="${Appearance.Plain}">
                                <mosaik-stack .gap="${'16px'}" .orientation="${Orientation.Vertical}">
                                    <mosaik-form-field .label="${'Rows'}">
                                        <mosaik-numberbox
                                                    .value="${e.rows}"
                                                    .min="${0}"
                                                    .max="${10}"
                                                    @input="${(x: InputEvent) => e.onRowsChanged(x)}"></mosaik-numberbox>
                                    </mosaik-form-field>
                                    <mosaik-form-field .label="${'Columns'}">
                                        <mosaik-numberbox
                                                    .value="${e.columns}"
                                                    .min="${0}"
                                                    .max="${12}"
                                                    @input="${(x: InputEvent) => e.onColumnsChanged(x)}"></mosaik-numberbox>
                                    </mosaik-form-field>
                                    <mosaik-form-field .label="${'Gap'}">
                                        <mosaik-numberbox
                                                    .value="${e.gap}"
                                                    .min="${0}"
                                                    @input="${(x: InputEvent) => e.onGapChanged(x)}"></mosaik-numberbox>
                                    </mosaik-form-field>
                                </mosaik-stack>
                            </mosaik-card>
                            <mosaik-card .header="${'Grid Item'}" .fit="${Fit.Width}" .appearance="${Appearance.Plain}">
                                <mosaik-stack .gap="${'16px'}" .orientation="${Orientation.Vertical}">
                                    <mosaik-form-field .label="${'Radius'}">
                                        <mosaik-numberbox
                                                    .value="${e.radius}"
                                                    .min="${0}"
                                                    @input="${(x: InputEvent) => e.onRadiusChanged(x)}"></mosaik-numberbox>
                                    </mosaik-form-field>
                                </mosaik-stack>
                                <mosaik-stack .gap="${'16px'}" .orientation="${Orientation.Vertical}">
                                    <mosaik-form-field .label="${'Shadow'}">
                                        <mosaik-numberbox
                                                    .value="${e.shadow}"
                                                    .min="${0}"
                                                    .max="${24}"
                                                    @input="${(x: InputEvent) => e.onShadowChanged(x)}"></mosaik-numberbox>
                                    </mosaik-form-field>
                                </mosaik-stack>
                            </mosaik-card>

                        </mosaik-stack>
                    </mosaik-drawer>
                    <mosaik-drawer-content slot="content">
                        <div part="container">
                            <div>
                                <app-view-port .viewport="${VIEWPORTS[0]}" .zoom="${0.5}">
                                    <div style="position: absolute; inset: 0;">
                                        <mosaik-grid .columnDefinitions="${e.repeat('auto', e.columns)}"
                                                     .rowDefinitions="${e.repeat('auto', e.rows)}"
                                                     .gap="${e.gap}">
                                            ${repeat([].constructor(e.columns), (_, index) => index, (_, columnIndex) => html`
                                                ${repeat([].constructor(e.rows), (_, index) => index, (_, rowIndex) => html`
                                                    <mosaik-grid-item .column="${columnIndex}" .row="${rowIndex}">
                                                        <mosaik-box .fit="${Fit.Both}" style="border: 1px dotted red; box-shadow: var(--theme-elevation-${e.shadow}dp); border-radius: ${e.radius}px">
                                                            <span>c:${columnIndex}/r:${rowIndex}</span>
                                                        </mosaik-box>
                                                    </mosaik-grid-item>
                                                `)}
                                            `)}
                                        </mosaik-grid>
                                    </div>
                                </app-view-port>
                                <div style="position: absolute; inset: 0;">
                                    <mosaik-grid .columnDefinitions="${e.repeat('auto', e.columns)}"
                                                .rowDefinitions="${e.repeat('auto', e.rows)}"
                                                .gap="${e.gap}">
                                    </mosaik-grid>
                                </div>
                            </div>
                        </div>
                        <mosaik-stack>
                        </mosaik-stack>
                    </mosaik-drawer-content>
                </mosaik-drawer-container>
            </mosaik-page-content>
        </mosaik-page>
    `;
}
