export namespace FileExtensions {

    // #region Methods

    /**
     * Reads a file.
     * @param blob - The given blob data.
     * @param as - The given fil read as {@link FileAs}.
     * @returns the file content.
     */
    export function read(blob: Blob, as: 'dataUrl' | 'text' | 'arrayBuffer'): Promise<string | ArrayBuffer | null> {
        return new Promise<string | ArrayBuffer | null>((resolve, reject): any => {
            const reader = new FileReader();

            reader.onload = (e): void => resolve(e.target?.result ?? null);
            reader.onerror = (e): void => reject(() => new Error(e.target?.error?.toString() ?? 'Unknown error'));

            switch (as) {
                case 'arrayBuffer':
                    reader.readAsArrayBuffer(blob);
                    break;
                case 'text':
                    reader.readAsText(blob);
                    break;
                case 'dataUrl':
                    reader.readAsDataURL(blob);
                    break;
                default:
                    throw new Error('Invalid file read type');
            }
        });
    }

    // #endregion

}
