// #region Imports

import { CSSResultGroup, Carousel2Element, Component, FlowDirection, ImageLegendPosition, html } from '@breadstone/mosaik-elements-foundation';
import { carousel2ElementDocs } from '../../../.Generated/mosaik-carousel-2';
import { carouselItem2ElementDocs } from '../../../.Generated/mosaik-carousel-item-2';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link Carousel2SampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-carousel-2-sample'
})
export class Carousel2SampleElement extends SampleBaseElement<Carousel2Element> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<Carousel2Element> {

        return {
            header: 'Carousel 2',
            description: META.description(Carousel2Element.is),
            graph: META.graph(Carousel2Element.is),
            playground: {
                template: [
                    ['default', () => html`
                    <mosaik-carousel-2 style="width: 200px">
                        <mosaik-carousel-item-2>
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'dark lights'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item-2>
                        <mosaik-carousel-item-2>
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1562177257-977b3bd4d7ec?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'stoney rainbow'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item-2>
                        <mosaik-carousel-item-2>
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1541723568817-3bb3d2dd5505?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxwcmlkZXxlbnwwfHwwfHx8MA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'colorful liquids'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item-2>
                    </mosaik-carousel-2>
                    `]
                ],
                properties: META.properties<Carousel2Element>(Carousel2Element.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'numVisible',
                        value: 1
                    },
                    {
                        key: 'numScroll',
                        value: 1
                    },
                    {
                        key: 'circular',
                        value: true
                    },
                    {
                        key: 'showIndicators',
                        value: true
                    },
                    {
                        key: 'showNavigators',
                        value: true
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<Carousel2Element>(Carousel2Element.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'numVisible',
                        value: 1
                    },
                    {
                        key: 'numScroll',
                        value: 1
                    },
                    {
                        key: 'circular',
                        value: true
                    },
                    {
                        key: 'showIndicators',
                        value: true
                    },
                    {
                        key: 'showNavigators',
                        value: true
                    }
                ])
            },
            docs: [
                carousel2ElementDocs(),
                carouselItem2ElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-carousel-2-sample': Carousel2SampleElement;
    }
}
