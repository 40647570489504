// #region Imports

import { Appearance, CardElement, Component, ElevatioWeight, Fit, FlowDirection, HorizontalAlignment, Inset, Orientation, Size, Spacing, TextAlignment, VerticalAlignment, flex, typography } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { cardElementDocs } from '../../.Generated/mosaik-card';
import { cardActionsElementDocs } from '../../.Generated/mosaik-card-actions';
import { cardFooterElementDocs } from '../../.Generated/mosaik-card-footer';
import { cardHeaderElementDocs } from '../../.Generated/mosaik-card-header';
import { cardSubTitleElementDocs } from '../../.Generated/mosaik-card-sub-title';
import { cardTitleElementDocs } from '../../.Generated/mosaik-card-title';
import type { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { formatterTemplate, insetTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';
import { CardSampleTemplates } from './CardSample.Template';

// #endregion

/**
 * The `{@link CardSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-card-sample'
})
export class CardSampleElement extends SampleBaseElement<CardElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CardElement> {

        return {
            header: 'Card',
            description: META.description(CardElement.is),
            graph: META.graph(CardElement.is),
            playground: {
                template: [
                    ['default', (p) => CardSampleTemplates.defaultTemplate(p)],
                    ['xxx', () => html`
                    <mosaik-card .appearance="${Appearance.Soft}"
                                    .elevation="${ElevatioWeight.Regular}">
                        <mosaik-card-header>

                        <mosaik-card-title>
                            <mosaik-number-counter slot="title"
                                                    ${typography('headline2')}
                                                    .from="${0}"
                                                    .to="${Number(100)}"
                                                    .duration="${'4s'}"
                                                    .alignment="${TextAlignment.Center}"></mosaik-number-counter>
                        </mosaik-card-title>
                        <mosaik-card-sub-title>
                            <mosaik-text slot="subTitle"
                                        text="lalalalalla"
                                        .alignment="${TextAlignment.Center}"></mosaik-text>
                        </mosaik-card-sub-title>
                        </mosaik-card-header>
                    </mosaik-card>
                    `],
                    ['with header', () => html`
                        <mosaik-card style="width: 316px;">
                            <mosaik-card-header></mosaik-card-header>
                        </mosaik-card>
                    `, [{
                            key: 'header',
                            value: 'Header'
                        },
                        {
                            key: 'subHeader',
                            value: 'and sub header only'
                        },
                        {
                            key: 'content',
                            value: ''
                        }]],
                    ['with header and content', () => html`
                        <mosaik-card style="width: 316px;">
                            <mosaik-card-header></mosaik-card-header>
                            <mosaik-card-content .text="${'Content'}"></mosaik-card-content>
                        </mosaik-card>
                    `],
                    ['with header, content and actions', () => html`
                        <mosaik-card style="width: 316px;">
                            <mosaik-card-header></mosaik-card-header>
                            <mosaik-card-content .text="${'Content'}"></mosaik-card-content>
                            <mosaik-card-actions>
                                <mosaik-button .label="${'Action'}"></mosaik-button>
                            </mosaik-card-actions>
                        </mosaik-card>
                    `],
                    ['with header, content, actions and footer', () => html`
                        <mosaik-card style="width: 316px;">
                            <mosaik-card-header></mosaik-card-header>
                            <mosaik-card-content .text="${'Content'}"></mosaik-card-content>
                            <mosaik-card-actions>
                                <mosaik-button .label="${'Action'}"></mosaik-button>
                            </mosaik-card-actions>
                            <mosaik-card-footer>
                                <mosaik-progress-bar .isIndeterminate="${true}"></mosaik-progress-bar>
                            </mosaik-card-footer>
                        </mosaik-card>
                    `],
                    ['styled like a instagram card', (props) => CardSampleTemplates.instagramTemplate(props), [
                        {
                            key: 'header',
                            value: 'John Doe'
                        },
                        {
                            key: 'subHeader',
                            value: 'Cologne'
                        },
                        {
                            key: 'inlineMedia',
                            value: true
                        },
                        {
                            key: 'appearance',
                            value: Appearance.Soft
                        },
                        {
                            key: 'orientation',
                            value: Orientation.Vertical
                        }]],
                    ['styled like a booking card', (p) => CardSampleTemplates.bookingTemplate(p), [
                        {
                            key: 'inlineMedia',
                            value: false
                        },
                        {
                            key: 'appearance',
                            value: Appearance.Soft
                        },
                        {
                            key: 'header',
                            value: 'Mountain Cabin'
                        },
                        {
                            key: 'subHeader',
                            value: 'NSW, Austrailia'
                        },
                        {
                            key: 'orientation',
                            value: Orientation.Vertical
                        },
                        {
                            key: 'inset',
                            value: Inset.None
                        }]],
                    ['styled like a weather card', html`
                        <mosaik-card style="width: 316px; height: 420px;"
                                     .inlineMedia="${true}">
                            <mosaik-image slot="cover"
                                          .src="${'https://images.unsplash.com/photo-1570698473651-b2de99bae12f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFkcmlkfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80'}"></mosaik-image>
                            <mosaik-spacer slot="media" .thickness="${Spacing.All}" .size="${Size.Large}">
                                <mosaik-stack .orientation="${Orientation.Vertical}"
                                            .verticalAlignment="${VerticalAlignment.Center}"
                                            .horizontalAlignment="${HorizontalAlignment.Center}">
                                    <mosaik-text class="shadow" .text="${'Sunday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                    .horizontalAlignment="${HorizontalAlignment.Right}"
                                                    .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Large}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'28°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/18'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                            </mosaik-spacer>
                            <mosaik-card-content>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                    <mosaik-text class="shadow" .text="${'Monday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'28°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/18'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                <mosaik-text class="shadow" .text="${'Tuesday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'31°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/22'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                    <mosaik-text class="shadow" .text="${'Wednesday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'32°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/23'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                    <mosaik-text class="shadow" .text="${'Thursday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'30°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/20'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                    <mosaik-text class="shadow" .text="${'Friday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M13.002 6.01c3.169 0 4.966 2.098 5.228 4.63h.08a3.687 3.687 0 0 1 3.692 3.683 3.687 3.687 0 0 1-3.692 3.683h-.516l-1.645 2.626a.75.75 0 0 1-1.344-.658l.045-.092 1.212-1.877h-2.136l-1.645 2.627a.75.75 0 0 1-1.344-.658l.045-.092 1.212-1.877h-2.136l-1.645 2.627a.75.75 0 0 1-1.344-.658l.045-.092 1.212-1.877h-.631a3.687 3.687 0 0 1-3.692-3.682 3.687 3.687 0 0 1 3.692-3.683h.08c.263-2.549 2.059-4.63 5.227-4.63Zm-8.634 4.156a.75.75 0 0 1-.315.934l-.091.046-.926.383a.75.75 0 0 1-.666-1.34l.092-.045.926-.384a.75.75 0 0 1 .98.406Zm6.487-4.833-.172.057C8.9 6.02 7.621 7.396 7.068 9.213l-.07.25-.058.239-.206.038a4.638 4.638 0 0 0-1.67.715 3.942 3.942 0 0 1 5.791-5.122Zm-7.914.03.106.036.926.383a.75.75 0 0 1-.469 1.421L3.4 7.168l-.926-.384a.75.75 0 0 1 .468-1.42Zm3.815-2.986.046.092.383.926a.75.75 0 0 1-1.34.665l-.046-.091-.383-.926a.75.75 0 0 1 1.34-.666Zm4.354-.318a.75.75 0 0 1 .44.874l-.035.106-.383.926a.75.75 0 0 1-1.42-.469l.034-.105.384-.926a.75.75 0 0 1 .98-.406Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'28°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/18'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <mosaik-stack .orientation="${Orientation.Horizontal}">
                                    <mosaik-text class="shadow" .text="${'Saturday'}"></mosaik-text>
                                    <mosaik-stack .orientation="${Orientation.Horizontal}"
                                                  .horizontalAlignment="${HorizontalAlignment.Right}"
                                                  .gap="${'16px'}">
                                        <mosaik-icon class="shadow"
                                                    .size="${Size.Small}"
                                                    .data="${'M11.996 18.532a1 1 0 0 1 .993.883l.007.117v1.456a1 1 0 0 1-1.993.116l-.007-.116v-1.456a1 1 0 0 1 1-1Zm6.037-1.932 1.03 1.03a1 1 0 0 1-1.415 1.413l-1.03-1.029a1 1 0 0 1 1.415-1.414Zm-10.66 0a1 1 0 0 1 0 1.414l-1.029 1.03a1 1 0 0 1-1.414-1.415l1.03-1.03a1 1 0 0 1 1.413 0ZM12.01 6.472a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm8.968 4.546a1 1 0 0 1 .117 1.993l-.117.007h-1.456a1 1 0 0 1-.116-1.993l.116-.007h1.456ZM4.479 10.99a1 1 0 0 1 .116 1.993l-.116.007H3.023a1 1 0 0 1-.117-1.993l.117-.007h1.456Zm1.77-6.116.095.083 1.03 1.03a1 1 0 0 1-1.32 1.497L5.958 7.4 4.93 6.371a1 1 0 0 1 1.32-1.497Zm12.813.083a1 1 0 0 1 .083 1.32l-.083.094-1.03 1.03a1 1 0 0 1-1.497-1.32l.084-.095 1.029-1.03a1 1 0 0 1 1.414 0ZM12 2.013a1 1 0 0 1 .993.883l.007.117v1.455a1 1 0 0 1-1.993.117L11 4.468V3.013a1 1 0 0 1 1-1Z'}"></mosaik-icon>
                                        <div ${flex({ direction: 'row' })}>
                                            <mosaik-text ${typography('body1')} .text="${'28°'}"></mosaik-text>
                                            <mosaik-text ${typography('body2')} .text="${'/18'}" .readonly="${true}"></mosaik-text>
                                        </div>
                                    </mosaik-stack>
                                </mosaik-stack>
                                <!-- <easy-box fxLayout="row"
                                      fxLayoutAlign="center center">
                                    <easy-text-block class="shadow"
                                                    [text]="('loc.weather.day.' + forecast.day.name) | localize"></easy-text-block>
                                    <easy-spacer fxFlex></easy-spacer>
                                    <easy-icon class="shadow"
                                            [size]="'small'"
                                            [name]="'weather_' + forecast.kind"></easy-icon>
                                    <easy-spacer [thickness]="'left'"></easy-spacer>
                                    <easy-text-format fxLayout="row"
                                                    [text]="'{0}°/{1}°'">
                                        <ng-template slot="template">
                                            <easy-text-block class="shadow"
                                                            [style.font-weight]="900"
                                                            [text]="forecast.temperature.max | toString"></easy-text-block>
                                        </ng-template>
                                        <ng-template slot="template">
                                            <easy-text-block class="shadow"
                                                            [text]="forecast.temperature.min | toString"></easy-text-block>
                                        </ng-template>
                                    </easy-text-format>
                                </easy-box> -->
                            </mosaik-card-content>

                        </mosaik-card>
                    `, [{
                            key: 'inlineMedia',
                            value: false
                        },
                        {
                            key: 'appearance',
                            value: Appearance.Soft
                        },
                        {
                            key: 'header',
                            value: 'Madrid'
                        },
                        {
                            key: 'subHeader',
                            value: 'Spain'
                        },
                        {
                            key: 'orientation',
                            value: Orientation.Vertical
                        },
                        {
                            key: 'inset',
                            value: Inset.None
                        }]],
                    ['styled like a music card', html`
                        <mosaik-card style="width: 420px;">
                            <mosaik-image slot="media"
                                            .height="${'180px'}"
                                            .src="${'https://lastfm.freetls.fastly.net/i/u/770x0/dd00ab972a1df72102668cc199555ac0.jpg#dd00ab972a1df72102668cc199555ac0'}"></mosaik-image>
                            <mosaik-card-actions>
                                <mosaik-stack .gap="${'16px'}"
                                                .orientation="${Orientation.Vertical}">
                                    <mosaik-button .icon="${'M3 20.25a.75.75 0 0 0 1.5 0V3.75a.75.75 0 1 0-1.5 0v16.5ZM18.23 20.475c1.158.83 2.77.002 2.77-1.422V4.83c0-1.384-1.53-2.22-2.695-1.473l-10.47 6.716a1.75 1.75 0 0 0-.074 2.895l10.47 7.507Zm1.27-1.422a.25.25 0 0 1-.396.203l-10.47-7.507a.25.25 0 0 1 .011-.413l10.47-6.717a.25.25 0 0 1 .385.21v14.224Z'}"></mosaik-button>
                                    <mosaik-button .icon="${'M7.608 4.615a.75.75 0 0 0-1.108.659v13.452a.75.75 0 0 0 1.108.659l12.362-6.726a.75.75 0 0 0 0-1.318L7.608 4.615ZM5 5.274c0-1.707 1.826-2.792 3.325-1.977l12.362 6.726c1.566.853 1.566 3.101 0 3.953L8.325 20.702C6.826 21.518 5 20.432 5 18.726V5.274Z'}"></mosaik-button>
                                    <mosaik-button .icon="${'M21 3.75a.75.75 0 0 0-1.5 0v16.5a.75.75 0 0 0 1.5 0V3.75ZM5.77 3.525C4.612 2.695 3 3.523 3 4.947V19.17c0 1.384 1.53 2.22 2.695 1.473l10.47-6.716a1.75 1.75 0 0 0 .074-2.895L5.77 3.525ZM4.5 4.947a.25.25 0 0 1 .396-.203l10.47 7.507a.25.25 0 0 1-.011.413l-10.47 6.717a.25.25 0 0 1-.385-.21V4.946Z'}"></mosaik-button>
                                </mosaik-stack>
                            </mosaik-card-actions>
                        </mosaik-card>
                    `, [{
                            key: 'inlineMedia',
                            value: false
                        },
                        {
                            key: 'appearance',
                            value: Appearance.Soft
                        },
                        {
                            key: 'header',
                            value: 'Still in Love'
                        },
                        {
                            key: 'subHeader',
                            value: 'At 1980'
                        },
                        {
                            key: 'inset',
                            value: Inset.All
                        },
                        {
                            key: 'orientation',
                            value: Orientation.Horizontal
                        }]]
                ],
                properties: META.properties<CardElement>(CardElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'header',
                        value: 'Title'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub Title'
                    },
                    {
                        key: 'content',
                        value: 'Content'
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'inlineMedia',
                        value: false
                    },
                    {
                        key: 'elevation',
                        value: ElevatioWeight.Light
                    }
                ])
            },
            propertyGrid: {
                properties: merge(META.properties<CardElement>(CardElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'header',
                        value: 'Title'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub Title'
                    },
                    {
                        key: 'content',
                        value: 'Content'
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'inlineMedia',
                        value: false
                    },
                    {
                        key: 'elevation',
                        value: ElevatioWeight.Light
                    },
                    {
                        key: 'formatter',
                        value: 'default'
                    }
                ]), [
                    {
                        key: 'inset',
                        template: insetTemplate
                    },
                    {
                        key: 'formatter',
                        template: formatterTemplate
                    }
                ] as Array<IPlaygroundProperty<CardElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                cardElementDocs(),
                cardHeaderElementDocs(),
                cardTitleElementDocs(),
                cardSubTitleElementDocs(),
                cardActionsElementDocs(),
                cardFooterElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-card-sample': CardSampleElement;
    }
}
