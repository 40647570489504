// #region Imports

import { Component, FlowDirection, VideoElement, VideoFit, VideoLegendPosition } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { videoElementDocs } from '../../../.Generated/mosaik-video';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link VideoSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-video-sample'
})
export class VideoSampleElement extends SampleBaseElement<VideoElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<VideoElement> {
        return {
            header: 'Video',
            description: META.description(VideoElement.is),
            graph: META.graph(VideoElement.is),
            playground: {
                template: html`
                    <mosaik-video></mosaik-video>
                `,
                properties: META.properties2<VideoElement>(VideoElement.is, {
                    dir: FlowDirection.Auto,
                    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    width: '200px',
                    height: '300px',
                    fit: VideoFit.Cover,
                    autoPlay: true,
                    poster: '',
                    autoFullScreen: false,
                    showControls: true,
                    legend: 'Big Buck Bunny',
                    legendPosition: VideoLegendPosition.Bottom
                }),
                events: META.events<VideoElement>(VideoElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<VideoElement>(VideoElement.is, {
                    dir: FlowDirection.Auto,
                    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    width: '200px',
                    height: '300px',
                    fit: VideoFit.Cover,
                    autoPlay: true,
                    poster: '',
                    autoFullScreen: false,
                    showControls: true,
                    legend: 'Big Buck Bunny',
                    legendPosition: VideoLegendPosition.Bottom
                }), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'src',
                        template: valueTemplate
                    },
                    {
                        key: 'ratio',
                        template: cssLengtTemplate
                    }
                ] as Array<IPlaygroundProperty<VideoElement>>, (a, b) => a.key === b.key),
                exludedProperties: ['hasSrc']
            },
            docs: [
                videoElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-video-sample': VideoSampleElement;
    }
}
