// #region Imports

import { Appearance, Attribute, BreakpointAlias, BreakpointController, Component, IntersectionController, Toast2, ToastServiceLocator, Variant } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../../Abstracts/ViewBase';
import { impressionViewStyle } from './ImpressionViewStyle';
import { impressionViewTemplate } from './ImpressionViewTemplate';

// #endregion

/**
 * The `{@link ImpressionView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-impression-view',
    template: impressionViewTemplate,
    styles: impressionViewStyle
})
export class ImpressionView
    extends ViewBase {

    // #region Fields

    private readonly _intersectionController: IntersectionController;
    private readonly _breakpointController: BreakpointController;
    private _isExtraSmall: boolean;
    private _appearance: Appearance;
    private _variant: Variant;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._isExtraSmall = false;
        this._appearance = Appearance.Solid;
        this._variant = Variant.Primary;

        this._intersectionController = new IntersectionController(this, {
            config: {
                // threshold: 1,
            },
            callback: (entry) => {
                entry.forEach((e) => {
                    if (e.isIntersecting) {
                        e.target.classList.add('visible');
                    } else {
                        e.target.classList.remove('visible');
                    }
                });
            }
        });
        this._breakpointController = new BreakpointController(this, {
            query: BreakpointAlias.XSmall,
            callback: (match) => {
                this.isExtraSmall = match;
            }
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-impression-view';
    }

    /**
     * Gets or sets the `isExtraSmall` property.
     *
     * @public
     */
    @Attribute({ type: Boolean })
    public get isExtraSmall(): boolean {
        return this._isExtraSmall;
    }

    private set isExtraSmall(value: boolean) {
        if (this._isExtraSmall !== value) {
            this._isExtraSmall = value;
            this.requestUpdate('isExtraSmall');
        }
    }

    /**
     * Gets or sets the `appearance` property.
     *
     * @public
     */
    @Attribute({ type: Appearance })
    public get appearance(): Appearance {
        return this._appearance;
    }

    private set appearance(value: Appearance) {
        if (this._appearance !== value) {
            this._appearance = value;
            this.requestUpdate('appearance');
        }
    }

    /**
     * Gets or sets the `variant` property.
     *
     * @public
     */
    @Attribute({ type: Variant })
    public get variant(): Variant {
        return this._variant;
    }

    private set variant(value: Variant) {
        if (this._variant !== value) {
            this._variant = value;
            this.requestUpdate('variant');
        }
    }

    // #endregion

    // #region Methods

    protected override onApplyTemplate(): void {
        super.onApplyTemplate();

        this.getTemplateParts('control-host').forEach((e) => {
            this._intersectionController.observe(e);
        });
    }

    /**
     * @private
     * @template
     */
    public onAppearanceChanged(appearance: Appearance): void {
        this.appearance = appearance;
    }

    /**
     * @private
     * @template
     */
    public onVariantChanged(variant: Variant): void {
        this.variant = variant;
    }

    /**
     * @private
     * @template
     */
    public onTabSave(): void {
        // this.isBusy = true;

        void ToastServiceLocator.current.open({
            hasBackdrop: true,
            content: 'Saving data...',
            isBusy: true,
            timeout: Toast2.Timeout.SHORT
        }).finally(() => {
            void ToastServiceLocator.current.open({
                hasBackdrop: true,
                content: 'Data saved successfully 🎉.',
                variant: Variant.Success,
                timeout: Toast2.Timeout.SHORT
            }).finally(() => {
                this.isBusy = false;
            });
        });
    }

    /**
     * @private
     * @template
     */
    public onDialogConfirm(isConfirmed: boolean): void {
        if (isConfirmed) {
            void ToastServiceLocator.current.open({
                content: 'Trying to activate the network connection...',
                isBusy: true,
                timeout: Toast2.Timeout.MEDIUM
            });
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-landing-impression-view': ImpressionView;
    }
}
