// #region Imports

import { Animate, Appearance, CSSResultGroup, Component, Fit, IconPosition, Inset, Size, TemplateResult, Variant, animate, css, flex, html, perspective, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { Icons } from '../../Resources/Icons/Icons';
import { ViewBase } from '../Abstracts/ViewBase';

// #endregion

/**
 * The `{@link ToolsView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-tools-view'
})
export class ToolsView extends ViewBase {

    // #region Fields
    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-tools-view';
    }

    /**
     * Returns the `{@link styles}` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {
        return [
            super.styles,
            css`
                :host > * {
                    flex: 1;
                }
            `
        ];
    }

    // #endregion

    // #region Methods

    /**
     * @protected
     * @override
     */
    protected override render(): TemplateResult {
        return html`
            <mosaik-page breakpoint="md" ${animate({ ...Animate.slideInBottom })}>
                <mosaik-page-pre-header .inset="${Inset.All}">
                    <mosaik-breadcrumb ?wrap="${true}">
                        <mosaik-breadcrumb-item>
                            <mosaik-button .href="${'/'}" .label="${'Home'}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                        <mosaik-breadcrumb-item .isActive="${true}">
                            <mosaik-button .href="${'/tools'}" .label="${'Tools'}"></mosaik-button>
                        </mosaik-breadcrumb-item>
                    </mosaik-breadcrumb>
                </mosaik-page-pre-header>
                <mosaik-page-header .text="${'Tools'}"
                                    .inset="${Inset.Horizontal}"></mosaik-page-header>
                <mosaik-page-content .inset="${[Inset.Horizontal, Inset.Top]}">
                    <mosaik-wrap .columns="${2}" .gap="${32}">
                        <mosaik-card ${perspective({ duration: 200 })}>
                            <mosaik-box slot="media"
                                        .fit="${Fit.Both}"
                                        .height="${'140px'}"
                                        .cornerd="${true}"
                                        ${flex({
                                            justify: 'center',
                                            alignItems: 'center'
                                        })}>
                                <mosaik-icon .size="${Size.Giant}"
                                             .data="${Icons.SYMBOLS}"
                                             style="--icon-size: 96px"></mosaik-icon>
                            </mosaik-box>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Icon Gallery'}"
                                             ${typography('headline6')}></mosaik-text>
                                <mosaik-text .text="${'The icon gallery is a collection of icons that can be used in your projects. You can search for icons, view details, and copy them.'}"
                                             .wrap="${true}"></mosaik-text>
                            </mosaik-card-content>
                            <mosaik-card-footer>
                                <mosaik-router-link .href="${'/tools/icons'}">
                                    <mosaik-button .appearance="${Appearance.Plain}"
                                                    .variant="${Variant.Primary}"
                                                    .iconSize="${Size.Medium}"
                                                    .iconPosition="${IconPosition.After}"
                                                    .label="${translate('loc.landing.actions.components.label')}"
                                                    .fit="${Fit.Width}"
                                                    .icon="${Icons.ARROW_RIGHT}"></mosaik-button>
                                </mosaik-router-link>
                            </mosaik-card-footer>
                        </mosaik-card>
                        <mosaik-card ${perspective({ duration: 200 })}>
                            <mosaik-box slot="media"
                                        .fit="${Fit.Both}"
                                        .height="${'140px'}"
                                        .cornerd="${true}"
                                        ${flex({
                                            justify: 'center',
                                            alignItems: 'center'
                                        })}>
                                <mosaik-icon .size="${Size.Giant}"
                                             .data="${Icons.COLOR}"
                                             style="--icon-size: 96px"></mosaik-icon>
                            </mosaik-box>
                            <mosaik-card-content>
                                <mosaik-text .text="${'Theme Generator'}"
                                             ${typography('headline6')}></mosaik-text>
                                <mosaik-text .text="${'The theme generator allows you to create a custom theme for your projects. You can customize colors, typography, and other properties.'}"
                                             .wrap="${true}"></mosaik-text>
                            </mosaik-card-content>
                            <mosaik-card-footer>
                                <mosaik-router-link .href="${'/tools/themeGenerator'}">
                                    <mosaik-button .appearance="${Appearance.Plain}"
                                                    .variant="${Variant.Primary}"
                                                    .iconSize="${Size.Medium}"
                                                    .iconPosition="${IconPosition.After}"
                                                    .label="${translate('loc.landing.actions.components.label')}"
                                                    .fit="${Fit.Width}"
                                                    .icon="${Icons.ARROW_RIGHT}"></mosaik-button>
                                </mosaik-router-link>
                            </mosaik-card-footer>
                        </mosaik-card>
                    </mosaik-wrap>
                </mosaik-page-content>
            </mosaik-page>
        `;
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-tools-view': ToolsView;
    }
}
