// #region Imports

import { IEventEmitter, Injectable, PureEventEmitter } from '@breadstone/mosaik-elements-foundation';

// #endregion

/**
 * The `IFeatureFlagInfo` interface.
 *
 * @public
 */
export interface IFeatureFlagInfo {

    // #region Properties

    key: string;

    description: string;

    active: boolean;

    options: Record<string, string>;

    // #endregion

}

/**
 * Represents the `IHelmetChangeEventDetail` interface.
 *
 * @public
 */
export interface IFeatureChangeEventDetail {

    // #region Properties

    feature: {
        key: string;
        active: boolean;
    };

    // #endregion

}

/**
 * The `FeatureManager` class.
 *
 * @public
 */
@Injectable()
export class FeatureManager {

    // #region Fields

    private static readonly _features: Array<IFeatureFlagInfo> = [];
    private readonly _changes: IEventEmitter<IFeatureChangeEventDetail>;

    // #endregion

    // #region Ctor

    static {
        this.syncFeaturesWithLocalStorage();
    }

    /**
     * Constructs a new instance of the `FeatureManager` class.
     *
     * @public
     */
    public constructor() {
        this._changes = new PureEventEmitter();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `features` property.
     *
     * @public
     * @readonly
     */
    public get features(): Array<IFeatureFlagInfo> {
        return FeatureManager._features;
    }

    /**
     * Returns the `changes` property.
     *
     * @public
     * @readonly
     */
    public get changes(): IEventEmitter<IFeatureChangeEventDetail> {
        return this._changes;
    }

    // #endregion

    // #region Methods

    /**
     * Composes the features.
     *
     * @remarks
     * If a feature with the same key already exists, and the active property is different, the feature will not be updated.
     *
     * @public
     * @param features - The features to compose.
     */
    public static compose(features: Array<IFeatureFlagInfo>): void {
        features.forEach((feature) => {
            const existingFeature = FeatureManager._features.find((f) => f.key === feature.key);
            if (existingFeature && existingFeature.active !== feature.active) {
                console.warn(`Feature with key "${feature.key}" already exists, and the active status is different.`);
                return;
            }

            if (existingFeature) {
                console.warn(`Feature with key "${feature.key}" already exists.`);

                return;
            }

            this._features.push(feature);
        });

        this.updateLocalStorage();
    }

    /**
     * Determines if the feature is active.
     *
     * @public
     * @param key - The key of the feature.
     */
    public static isActive(key: string): boolean | null {
        const feature = FeatureManager._features.find((f) => f.key === key);
        return feature?.active ?? null;
    }

    /**
     * Determines if the feature exists.
     *
     * @public
     * @param key - The key of the feature.
     */
    public hasFeature(key: string): boolean {
        return FeatureManager._features.some((feature) => feature.key === key);
    }

    /**
     * Gets the feature options.
     *
     * @public
     * @param key - The key of the feature.
     */
    public getFeatureOptions(key: string): Record<string, string> | undefined {
        const feature = FeatureManager._features.find((f) => f.key === key);
        return feature?.options;
    }

    /**
     * Changes the `active` status of a feature and updates local storage.
     *
     * @public
     * @param key - The key of the feature.
     * @param isActive - The new active status.
     */
    public changeFeature(key: string, isActive: boolean): void {
        const feature = FeatureManager._features.find((f) => f.key === key);

        if (!feature) {
            console.warn(`Feature with key "${key}" not found.`);
            return;
        }

        feature.active = isActive;
        FeatureManager.updateLocalStorage();

        this._changes.emit({
            feature: {
                active: isActive,
                key: key
            }
        });
    }

    /**
    * Updates local storage with the current features.
    *
    * @private
    */
    private static updateLocalStorage(): void {
        localStorage.setItem('features', JSON.stringify(this._features));
    }

    /**
     * Syncs the in-memory features with the data from local storage.
     *
     * @private
     * @static
     */
    private static syncFeaturesWithLocalStorage(): void {
        const storedFeatures = localStorage.getItem('features');
        if (storedFeatures) {
            const parsedFeatures = JSON.parse(storedFeatures) as Array<IFeatureFlagInfo>;
            parsedFeatures.forEach((feature) => {
                if (!FeatureManager._features.some((f) => f.key === feature.key)) {
                    FeatureManager._features.push(feature);
                }
            });
        }
    }

    // #endregion

}

FeatureManager.compose([
    {
        active: false,
        description: 'Allows to switch the language',
        key: 'multiLanguage',
        options: {}
    },
    {
        active: false,
        description: 'Enables or disables the ai chat feature',
        key: 'aiChat',
        options: {
            name: 'Mo',
            description: 'Mo is a friendly AI chatbot that can help you with your questions.'
        }
    },
    {
        active: false,
        description: 'Allows to track bugs or feature requests directly on the website',
        key: 'issueTracking',
        options: {}
    },
    {
        active: false,
        description: 'Set the website in the mood for Christmas',
        key: 'itsXmasTime',
        options: {}
    },
    {
        active: false,
        description: 'Enables or disables an example of a pricing table',
        key: 'pricingTable',
        options: {}
    },
    {
        active: false,
        description: 'Track component events in the playground',
        key: 'playgroundEvents',
        options: {}
    },
    {
        active: false,
        description: 'Enables or disables more tools in the playground',
        key: 'playgroundTools',
        options: {}
    }
]);
