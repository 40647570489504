// @see https://github.com/TonySpegel/toc-observer

//#region Imports

import { Attribute, Component, CustomElement, QueryAssignedElements, type IConnectedCallback, type IDisconnectedCallback } from '@breadstone/mosaik-elements-foundation';
import { tocElementStyle } from './TocElementStyle';
import { tocElementTemplate } from './TocElementTemplate';

//#endregion

/**
 * Represents the `{@link ITocElementProps}` interface.
 *
 * @public
 */
export interface ITocElementProps {

    rootElement?: string;
    rootMargin: string;
    tocActiveClass: string;
    observeParent: boolean;
    parentSelector: string;

}

/**
 * The `{@link TocElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-toc',
    template: tocElementTemplate,
    styles: tocElementStyle
})
export class TocElement extends CustomElement implements IConnectedCallback, IDisconnectedCallback, ITocElementProps {

    //#region Fields

    // Converts '_tocList' into a getter that returns the assignedElements of the given slot
    @QueryAssignedElements({ slot: 'toc' })
    private readonly _tocList?: Array<HTMLUListElement>;

    // anchor-IDs and their corresponding IntersectionObservers
    private _anchorHashObserverMap!: Map<HTMLAnchorElement['hash'], IntersectionObserver>;

    //#endregion

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-toc';
    }

    // Identifies the element or document as a reference for interecting items
    @Attribute({ type: String })
    public rootElement?: string;

    // Bounding box which determines when an observation is triggered
    @Attribute({ type: String })
    public rootMargin: string = '0px';

    // CSS class which is set when observer items are visible
    @Attribute({ type: String })
    public tocActiveClass: string = 'toc-active';

    /**
     * Useful for observing nested markup like this:
     * <section>
     *   <!-- ^observe -->
     *   <h2 id="possum">Possum</h2>
     * </section>
     *
     * Observing wrapper elements instead of just headings
     * has the advantage that those have more area to intersect with.
     *
     *     ┌─────────┐
     *     │ #possum │
     *   ┌─┼─────────┼─┐
     *   │ │         │ │
     *   │ └─────────┘ │< Viewport
     *   │ ^section    │
     *   └─────────────┘
     */
    @Attribute({ type: Boolean })
    public observeParent: boolean = false;

    // Should be used together with observeParent
    @Attribute({ type: String })
    public parentSelector: string = 'section';

    //#endregion

    //#region Methods

    /**
     * Stop observing when the component is removed from the DOM
     *
     * @public
     * @override
     */
    public override disconnectedCallback(): void {
        // As there are no toc-items left to highlight observing
        // elements should be stopped
        this._anchorHashObserverMap.forEach(obs => obs.disconnect());
    }

    /**
     * The 'firstUpdated' lifecycle is called after the component's DOM
     * has been updated the first time, immediately before updated() is called.
     * Only then an element's slot content (our toc items) is available and can be observed.
     *
     * @public
     * @override
     */
    public override firstUpdated(): void {
        // Observe items when at least one is available
        if (this.receiveTocListItems?.length) {
            this._anchorHashObserverMap = this.createIdObserverMap(this.receiveTocListItems() ?? []);

            this._anchorHashObserverMap.forEach((observer, anchorHash) => {
                const item = this.ownerDocument.querySelector(anchorHash);

                if (!this.observeParent && item) {
                    observer.observe(item);
                }

                if (this.observeParent && item?.closest(this.parentSelector) !== null) {
                    const parent = item?.closest(this.parentSelector) ?? null;

                    if (parent) {
                        observer.observe(parent);
                    }
                }
            });
        }
    }

    /**
     * Selects a TOC link within the components slot / ul element.
     * Its id param is obtained by reading the oberver item's target-id.
     */
    private selectTocLink(id: string): HTMLAnchorElement | null {
        return this._tocList?.length
            ? this._tocList[0].querySelector<HTMLAnchorElement>(`[href="${id}"]`)
            : null;
    }

    /**
     * Creates a map of anchor-IDs and their corresponding
     * IntersectionObservers. Anchor-IDs are used to select
     * toc-links within '_tocList' and also to create observer
     * items later.
     *
     * {"#beschreibung" => IntersectionObserver}
     * {"#lebensweise" => IntersectionObserver}
     */
    private createIdObserverMap(anchors: Array<HTMLAnchorElement>): Map<HTMLAnchorElement['hash'], IntersectionObserver> {
        return new Map(
            anchors.map((anchor: HTMLAnchorElement) => [
                anchor.hash,
                new IntersectionObserver((entries: Array<IntersectionObserverEntry>) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            this.selectTocLink(anchor.hash)?.classList.add(this.tocActiveClass);
                        } else {
                            this.selectTocLink(anchor.hash)?.classList.remove(
                                this.tocActiveClass,
                            );
                        }
                    });
                }, {
                    root: this.rootElement
                        ? (this.ownerDocument.querySelector(this.rootElement))
                        : null ?? null,
                    rootMargin: this.rootMargin,
                }),
            ]),
        );
    }

    /**
     * Receive any items within '_tocList' if present or null
     *
     * @private
     */
    private receiveTocListItems(): Array<HTMLAnchorElement> | null {
        if (this._tocList) {
            const list = Array.from(this._tocList[0].querySelectorAll<HTMLAnchorElement>('[href^="#"]')) ?? [];
            return this._tocList?.length
                ? this._tocList ? [...list ?? []] : []
                : null;

        }

        return null;
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-toc': TocElement;
    }

}

