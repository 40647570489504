// #region Imports

import { ChartDisplayType, ChartElement, ChartLegendPosition, Component, Fit, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { chartElementDocs } from '../../../.Generated/mosaik-chart';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link DonutChartSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-donut-chart-sample'
})
export class DonutChartSampleElement extends SampleBaseElement<ChartElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChartElement> {
        return {
            header: 'Donut Chart',
            description: META.description(ChartElement.is),
            graph: META.graph(ChartElement.is),
            playground: {
                template: html`
                    <mosaik-chart></mosaik-chart>
                `,
                properties: META.properties<ChartElement>(ChartElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    },
                    {
                        key: 'display',
                        value: ChartDisplayType.Donut
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    {
                        key: 'width',
                        value: '400px'
                    },
                    {
                        key: 'height',
                        value: '300px'
                    },
                    {
                        key: 'isLegendVisible',
                        value: true
                    },
                    {
                        key: 'legendPosition',
                        value: ChartLegendPosition.Bottom
                    },
                    {
                        key: 'isXAxisVisible',
                        value: true
                    },
                    {
                        key: 'isYAxisVisible',
                        value: true
                    },
                    {
                        key: 'annotations',
                        value: null
                    },
                    {
                        key: 'colors',
                        value: null
                    },
                    {
                        key: 'chart',
                        value: null
                    },
                    {
                        key: 'dataLabels',
                        value: null
                    },
                    {
                        key: 'series',
                        value: [44, 55, 13, 43, 22]
                    },
                    {
                        key: 'stroke',
                        value: null
                    },
                    {
                        key: 'labels',
                        value: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E']
                    },
                    {
                        key: 'legend',
                        value: null
                    },
                    {
                        key: 'markers',
                        value: null
                    },
                    {
                        key: 'noData',
                        value: null
                    },
                    {
                        key: 'fill',
                        value: null
                    },
                    {
                        key: 'tooltip',
                        value: null
                    },
                    {
                        key: 'plotOptions',
                        value: null
                    },
                    {
                        key: 'responsive',
                        value: null
                    },
                    {
                        key: 'xaxis',
                        value: null
                    },
                    {
                        key: 'yaxis',
                        value: null
                    },
                    {
                        key: 'grid',
                        value: null
                    },
                    {
                        key: 'states',
                        value: null
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'subHeader',
                        value: 'Subheader'
                    },
                    {
                        key: 'theme',
                        value: null
                    }
                ]),
                events: META.events(ChartElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ChartElement>(ChartElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    },
                    {
                        key: 'display',
                        value: ChartDisplayType.Donut
                    },
                    {
                        key: 'fit',
                        value: Fit.None
                    },
                    {
                        key: 'header',
                        value: 'Header'
                    },
                    {
                        key: 'subHeader',
                        value: 'Subheader'
                    },
                    {
                        key: 'width',
                        value: '400px'
                    },
                    {
                        key: 'height',
                        value: '300px'
                    },
                    {
                        key: 'isLegendVisible',
                        value: true
                    },
                    {
                        key: 'legendPosition',
                        value: ChartLegendPosition.Bottom
                    },
                    {
                        key: 'isXAxisVisible',
                        value: true
                    },
                    {
                        key: 'isYAxisVisible',
                        value: true
                    }
                ]), [
                    {
                        key: 'width',
                        template: cssLengtTemplate
                    },
                    {
                        key: 'height',
                        template: cssLengtTemplate
                    }
                ] as Array<IPlaygroundProperty<ChartElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                chartElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-donut-chart-sample': DonutChartSampleElement;
    }
}
