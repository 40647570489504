// #region Imports

import { CSSResultGroup, Component, FlowDirection, ScaleElement, ScaleMode, html } from '@breadstone/mosaik-elements-foundation';
import { scaleElementDocs } from '../../../.Generated/mosaik-scale';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { timeTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ScaleSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-scale-sample'
})
export class ScaleSampleElement extends SampleBaseElement<ScaleElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ScaleElement> {
        return {
            header: 'Scale',
            description: META.description(ScaleElement.is),
            graph: META.graph(ScaleElement.is),
            playground: {
                template: (props, set, _get) => html`
                    <div id="my-id"
                         style="background: black; width: 320px; height: 120px; position: relative">
                        <mosaik-scale .for="${'my-id'}"
                                      .disabled="${props.get('disabled').value}"
                                      .duration="${props.get('duration').value}"
                                      .factor="${props.get('factor').value}"
                                      .mode="${props.get('mode').value}"
                                      ></mosaik-scale>
                        <mosaik-text .text="${'Click me'}"></mosaik-text>
                    </div>
                `,
                properties: META.properties<ScaleElement>(ScaleElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'duration',
                        value: 100
                    }, {
                        key: 'mode',
                        value: ScaleMode.Grow
                    }, {
                        key: 'factor',
                        value: 0.08
                    }

                ]),
                events: META.events(ScaleElement.is)
            },
            propertyGrid: {
                exludedProperties: ['control', 'for'],
                properties: merge(META.properties<ScaleElement>(ScaleElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    }, {
                        key: 'duration',
                        value: 100
                    }, {
                        key: 'mode',
                        value: ScaleMode.Grow
                    }, {
                        key: 'factor',
                        value: 0.08
                    }
                ]), [{
                    key: 'duration',
                    template: timeTemplate
                }] as Array<IPlaygroundProperty<ScaleElement>>, (a, b) => a.key === b.key)
            },
            docs: [scaleElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-scale-sample': ScaleSampleElement;
    }
}
