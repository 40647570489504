// #region Imports

import { Component, EmptyUploadStrategy, FileUploadElement, FlowDirection } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { fileUploadElementDocs } from '../../../.Generated/mosaik-file-upload';
import { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { arrayTemplate, functionTemplate, valueTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link FileUploadSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-file-upload-sample'
})
export class FileUploadSampleElement extends SampleBaseElement<FileUploadElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
    */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<FileUploadElement> {

        return {
            header: 'File Upload',
            description: META.description(FileUploadElement.is),
            graph: META.graph(FileUploadElement.is),
            playground: {
                template: html`
                    <mosaik-file-upload></mosaik-file-upload>
                `,
                properties: META.properties<FileUploadElement>(FileUploadElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'placeholder',
                        value: 'Drop files here or click to upload.'
                    },
                    {
                        key: 'value',
                        value: []
                    },
                    {
                        key: 'accept',
                        value: []
                    },
                    {
                        key: 'view',
                        value: 'list'
                    },
                    {
                        key: 'strategy',
                        value: new EmptyUploadStrategy()
                    }
                ]),
                events: META.events(FileUploadElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<FileUploadElement>(FileUploadElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'disabled',
                        value: false
                    },
                    {
                        key: 'placeholder',
                        value: 'Drop files here or click to upload.'
                    },
                    {
                        key: 'value',
                        value: []
                    },
                    {
                        key: 'accept',
                        value: []
                    },
                    {
                        key: 'view',
                        value: 'list'
                    },
                    {
                        key: 'strategy',
                        value: new EmptyUploadStrategy()
                    }
                ]), [
                    {
                        key: 'accept',
                        template: arrayTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    },
                    {
                        key: 'strategy',
                        template: functionTemplate
                    },
                    {
                        key: 'value',
                        template: valueTemplate
                    }
                ] as Array<IPlaygroundProperty<FileUploadElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                fileUploadElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-file-upload-sample': FileUploadSampleElement;
    }
}
