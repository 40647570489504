// #region Imports

import { Appearance, Component, FlowDirection, HorizontalAlignment, Orientation, Size, SpinDirection, UpDownSpinPosition, UpDownSpinnerElement, Variant, VerticalAlignment, query, type ISpinEventDetail, type TextElement } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { updownSpinnerElementDocs } from '../../.Generated/mosaik-updown-spinner';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link UpDownSpinnerSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-updown-spinner-sample'
})
export class UpDownSpinnerSampleElement extends SampleBaseElement<UpDownSpinnerElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<UpDownSpinnerElement> {

        const onSpin = (e: CustomEvent<ISpinEventDetail>) => {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            query<TextElement>(this, '#value').text = e.detail.direction === SpinDirection.Increase
                ? (parseInt(query<TextElement>(this, '#value').text ?? '') + 1).toString()
                : (parseInt(query<TextElement>(this, '#value').text ?? '') - 1).toString();

            this.requestUpdate();

        };

        return {
            header: 'UpDownSpinner',
            description: META.description(UpDownSpinnerElement.is),
            graph: META.graph(UpDownSpinnerElement.is),
            playground: {
                template: html`
                <mosaik-updown-spinner @spined="${onSpin}">
                    <span slot="prefix">prefix</span>
                    <span slot="suffix">suffix</span>
                    <mosaik-stack .verticalAlignment="${VerticalAlignment.Center}"
                                  .horizontalAlignment="${HorizontalAlignment.Center}">
                        <mosaik-text id="value"
                                     .text="${'0'}"></mosaik-text>
                    </mosaik-stack>
                </mosaik-updown-spinner>
            `,
                properties: META.properties<UpDownSpinnerElement>(UpDownSpinnerElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Default
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'position',
                        value: UpDownSpinPosition.Around
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<UpDownSpinnerElement>(UpDownSpinnerElement.is, [
                    {
                        key: 'appearance',
                        value: Appearance.Default
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'orientation',
                        value: Orientation.Horizontal
                    },
                    {
                        key: 'position',
                        value: UpDownSpinPosition.Around
                    },
                    {
                        key: 'size',
                        value: Size.Medium
                    }
                ])
            },
            docs: [
                updownSpinnerElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-updown-spinner-sample': UpDownSpinnerSampleElement;
    }
}
