//#region Imports

import { html, when, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type CouponElement } from './CouponElement';

//#endregion

/**
 * The template of the {@link CouponElement}.
 *
 * @public
 */
export function couponElementTemplate<T extends CouponElement>(e: T): TemplateResult {
    return html`
        <mosaik-text .text="${e.text}"
                     .formatter="${e.formatter}"
                     .wrap="${true}"></mosaik-text>
        <mosaik-text part="code"
                     .text="${e.code}"
                     .formatter="${e.formatter}"></mosaik-text>
        ${when(e.discount > 0, () => html`
        <mosaik-text .text="${e.discount.toString()}"
                     .formatter="${e.formatter}"></mosaik-text>
        `)}
    `;
}
