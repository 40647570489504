//#region Imports

import { ChartDisplayType, ChartElement, ChartLegendPosition, Component, Fit, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { chartElementDocs } from '../../../.Generated/mosaik-chart';
import { type IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { cssLengtTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

//#endregion

/**
 * The `{@link HeatMapSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-heatmap-sample'
})
export class HeatMapSampleElement extends SampleBaseElement<ChartElement> {

    //#region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ChartElement> {
        return {
            header: 'HeatMap Chart',
            description: META.description(ChartElement.is),
            graph: META.graph(ChartElement.is),
            playground: {
                template: html`
                    <mosaik-chart></mosaik-chart>
                `,
                properties: META.properties<ChartElement>(ChartElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'variant', value: Variant.Default },
                    { key: 'display', value: ChartDisplayType.HeatMap },
                    { key: 'fit', value: Fit.None },
                    { key: 'width', value: '400px' },
                    { key: 'height', value: '300px' },
                    { key: 'isLegendVisible', value: true },
                    { key: 'legendPosition', value: ChartLegendPosition.Bottom },
                    { key: 'isXAxisVisible', value: true },
                    { key: 'isYAxisVisible', value: true },
                    { key: 'annotations', value: null },
                    {
                        key: 'colors', value: ['#F3B415', '#F27036', '#663F59', '#6A6E94', '#4E88B4', '#00A7C6', '#18D8D8', '#A9D794',
                            '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29'
                        ]
                    },
                    { key: 'chart', value: null },
                    { key: 'dataLabels', value: null },
                    {
                        key: 'series', value: [{
                            name: '10:00',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '10:30',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '11:00',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '11:30',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '12:00',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '12:30',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '13:00',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        },
                        {
                            name: '13:30',
                            data: this.generateData(15, {
                                min: 0,
                                max: 90
                            })
                        }
                        ]
                    },
                    { key: 'stroke', value: null },
                    { key: 'labels', value: null },
                    { key: 'legend', value: null },
                    { key: 'markers', value: null },
                    { key: 'noData', value: null },
                    { key: 'fill', value: null },
                    { key: 'tooltip', value: null },
                    {
                        key: 'plotOptions', value: {
                            heatmap: {
                                colorScale: {
                                    inverse: true
                                }
                            }
                        }
                    },
                    { key: 'responsive', value: null },
                    {
                        key: 'xaxis', value: {
                            type: 'category',
                            categories: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10', 'W11', 'W12', 'W13', 'W14', 'W15']
                        }
                    },
                    { key: 'yaxis', value: { tickAmount: 7 } },
                    { key: 'grid', value: null },
                    { key: 'states', value: null },
                    { key: 'header', value: 'Header' },
                    { key: 'subHeader', value: 'Subheader' },
                    { key: 'theme', value: null },
                ]),
                events: META.events(ChartElement.is),
            },
            propertyGrid: {
                properties: merge(META.properties<ChartElement>(ChartElement.is, [
                    { key: 'dir', value: FlowDirection.Auto },
                    { key: 'variant', value: Variant.Default },
                    { key: 'display', value: ChartDisplayType.HeatMap },
                    { key: 'fit', value: Fit.None },
                    { key: 'header', value: 'Header' },
                    { key: 'subHeader', value: 'Subheader' },
                    { key: 'width', value: '400px' },
                    { key: 'height', value: '300px' },
                    { key: 'isLegendVisible', value: true },
                    { key: 'legendPosition', value: ChartLegendPosition.Bottom },
                    { key: 'isXAxisVisible', value: true },
                    { key: 'isYAxisVisible', value: true },
                ]), [
                    { key: 'width', template: cssLengtTemplate },
                    { key: 'height', template: cssLengtTemplate },
                ] as Array<IPlaygroundProperty<ChartElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                chartElementDocs()
            ]
        };
    }

    private generateData(count: number, yrange: { min: number; max: number }): Array<number> {
        let i = 0;
        const series = [];
        while (i < count) {
            const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

            series.push(y);
            i++;
        }
        return series;
    }

    //#endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-heatmap-sample': HeatMapSampleElement;
    }
}

