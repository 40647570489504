// #region Imports

import { emit, HelmetServiceLocator, Translator, TranslatorService, TranslatorServiceLocator } from '@breadstone/mosaik-elements-foundation';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { registerSW } from 'virtual:pwa-register';
import { globals } from './Globals';
import { Translations_de as DE } from './Resources/Translations/Translations.de';
import { Translations_en as EN } from './Resources/Translations/Translations.en';
import { Translations_es as ES } from './Resources/Translations/Translations.es';
import { Translations_fr as FR } from './Resources/Translations/Translations.fr';
import { FeatureManager, type IFeatureFlagInfo } from './Services/FeatureManager';
import { SessionManager } from './Services/SessionManager';

// #endregion

/**
 * @public
 */
export function run(): void {
    usePwa();
    useTransition();
    void Promise.all([
        useConfig(),
        useFeatures(),
        useSession()
    ]).then(() => {
        useTitle();
        useHelmet();
        useAnalytics();
    })
        .then(() => {
            emit(document, 'bootstrap', {
                bubbles: true,
                cancelable: true
            });
        });
}

/**
 * @private
 */
function usePwa(): void {
    const isProduction = globals.mode === 'production';

    if (isProduction) {
        registerSW({
            immediate: true,
            onNeedRefresh: () => {
                console.info('### onNeedRefresh');
            },
            onOfflineReady: () => {
                console.info('### onOfflineReady');
            },
            onRegisterError: (error) => {
                console.error('### onRegisterError', error);
            },
            onRegisteredSW: (swScriptUrl, registration) => {
                console.info('### onRegisteredSW', swScriptUrl, registration);
            }

        });
    }
}

/**
 * @private
 */
async function useConfig(): Promise<void> {
    // window.location.hostname
    const isProduction = globals.mode === 'production';
    const isDev = import.meta.env.DEV;

    if (isProduction && !isDev) {
        try {
            const x = await fetch('/api/config');
            const y = await x.json();

            globals.assign(y.data);
            console.info('### override globals');
        } catch (error) {
            console.error('Error while fetching server config.', error);
        }
    }
    // else {

    //     try {

    //         const x = await fetch('@@EDGE_CONFIG');
    //         const y = await x.json();

    //         globals.assign(y.items.config);
    //         console.info('### override globals');

    //     } catch (error) {

    //         console.error('Error while fetching edge config.', error);

    //     }

    // }
}

/**
 * @private
 */
async function useFeatures(): Promise<void> {
    const isProduction = globals.mode === 'production';
    const isDev = import.meta.env.DEV;

    if (isProduction && !isDev) {
        const data = await fetch('/api/features');
        const features = await data.json();

        FeatureManager.compose(features as Array<IFeatureFlagInfo>);
    }
}

/**
 * @private
 */
function useTitle(): void {
    document.title = globals.name;
}

/**
 * @private
 */
function useHelmet(): void {
    HelmetServiceLocator.current.defaults('title', {

        '': globals.name
    });
    HelmetServiceLocator.current.defaults('meta', {
        'description': globals.description,
        'keywords': globals.keywords.join(', '),
        'author': globals.author,
        'name': globals.name,
        'robots': 'index, follow',
        // Open graph

        'og:site_name': globals.name,

        'og:title': globals.name,

        'og:description': globals.description,

        'og:type': 'website',
        // 'og:image': globals.image,
        // 'og:url': globals.url
        // Twitter

        'twitter:title': globals.name,

        'twitter:description': globals.description,
        // 'twitter:image': globals.image,
        // 'twitter:url': globals.url,

        'twitter:card': 'summary'
    });
}

/**
 * @private
 */
function useTransition(): void {
    const translator = new TranslatorService(Translator.DEFAULT_LANGUAGE_RESOLVER);
    translator.languageChanged.subscribe(() => {
        // Set the document root language
        document.documentElement.lang = translator.currentLanguage;
        // Handle it and trigger a global event
        emit(document, 'languageChanged', {
            bubbles: true,
            cancelable: true
        });
    });

    translator.addTranslations('en', EN.toObject());
    translator.addTranslations('de', DE.toObject());
    translator.addTranslations('fr', FR.toObject());
    translator.addTranslations('es', ES.toObject());

    TranslatorServiceLocator.set(translator);
}

/**
 * @private
 */
function useAnalytics(): void {
    const isProduction = globals.mode === 'production';

    // Vercel analytics
    inject({
        mode: isProduction ? 'production' : 'development',
        debug: !isProduction
    });

    if (isProduction) {
        injectSpeedInsights({
            debug: !isProduction
        });
    }

    // Clarity
    if (isProduction) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line func-names
        (function (c: any, l: any, a: any, r: any, i: any, t: any, y: any) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line logical-assignment-operators, func-names
            c[a] = c[a] || function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line logical-assignment-operators, prefer-rest-params
                (c[a].q = c[a].q || []).push(arguments);
            };
            t = l.createElement(r);
            t.async = 1;
            t.src = `https://www.clarity.ms/tag/${i}`;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', 'jqxxo84tjh');
    }

    // Google Analytics
    // If (isActive && isProduction) {
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/tslint/config
    //     (function (i: any, s: any, o: any, g: any, r: any, a: any, m: any) {
    //         I.GoogleAnalyticsObject = r;
    //         (i[r] =
    //             I[r] ||
    //             Function () {
    //                 (i[r].q = i[r].q || []).push(arguments);
    //             }),
    //             (i[r].l = 1 * new Date());
    //         (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    //         A.async = 1;
    //         A.src = g;
    //         M.parentNode.insertBefore(a, m);
    //     })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    //     Ga('create', 'UA-206795-1', 'auto');
    //     Ga('send', 'pageview');
    // }

    // Google Tag Manager
    if (isProduction) {
        // eslint-disable-next-line func-names
        (function (w: any, d: any, s: any, l: any, i: any) {
            // eslint-disable-next-line logical-assignment-operators
            w[l] = w[l] || [];
            w[l].push({

                'gtm.start': new Date().getTime(),
                'event': 'gtm.js'
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l !== 'dataLayer' ? `&l=${l}` : '';
            j.async = true;
            j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'G-C4QGDJ4MQ4');
    }
}

/**
 * @private
 */
async function useSession(): Promise<void> {
    try {
        await SessionManager.handshake();
    } catch (error) {
        SessionManager.clear();
        console.error('Error while fetching server handshake.', error);
    }
}
