import type { Attribute, ClassLike, ClassMember, ClassMethod, CssCustomProperty, CssPart, CustomElement, CustomElementExport, Event, Export, ClassField as ManifestClassField, Package, Slot } from 'custom-elements-manifest/schema';

// FIXME: remove once new custom-elements-manifest version is released
// https://github.com/webcomponents/custom-elements-manifest/pull/118
type ClassField = ManifestClassField & {
    /**
     * Whether the property is read-only.
     */
    readonly?: boolean;
};

export type {
    Attribute,
    ClassField,
    ClassMember,
    ClassMethod,
    CssCustomProperty,
    CssPart,
    CustomElement,
    Event,
    Package,
    Slot
};

export function hasCustomElements(manifest?: Package | null): manifest is Package {
    return (!!manifest && Array.isArray(manifest.modules) && manifest.modules.some(x =>
        x.exports?.some(y => y.kind as any === 'definition') ??
        x.declarations?.some(z => (z as CustomElement).customElement)
    ));
}

const isCustomElementExport = (y: Export): y is CustomElementExport =>
    y.kind as any === 'definition';

const isCustomElementDeclaration = (y: ClassLike): y is CustomElement =>
    (y as CustomElement).customElement;

const isPublic = (x: ClassMember): boolean =>
    !(x.privacy === 'private' || x.privacy === 'protected');

export function getCustomElements(manifest: Package, only?: Array<string>): Array<CustomElementExport> {
    const exports = (manifest.modules ?? []).flatMap(
        x => x.exports?.filter(isCustomElementExport) ?? []
    );
    return only ? exports.filter(e => only.includes(e.name)) : exports;
}

export const getElementData = (manifest: Package, elements: Array<CustomElementExport>, selected?: string): CustomElement | null => {
    const index = selected
        ? elements.findIndex(el => el?.name === selected)
        : 0;

    const element = elements[index];

    if (!element) {
        return null;
    }

    // const decl = !element.declaration.module
    //     ? manifest.modules
    //         .flatMap(x => x.declarations)
    //         .find((y): y is CustomElementDeclaration => y?.name === element.declaration.name)
    //     : manifest.modules
    //         .find(m => m.path === element.declaration.module?.replace(/^\//, ''))
    //         ?.declarations?.find(d => d.name === element.declaration.name);

    const decl = manifest.modules.map(x => x.exports?.find(x => x.kind as any === 'class')).at(0) as any as CustomElement;

    // if (!decl || !isCustomElementDeclaration(decl)) {
    //     throw new Error(`Could not find declaration for ${selected}`);
    // }

    return {
        customElement: true,
        name: element.name,
        description: decl?.description,
        slots: decl.slots ?? [],
        attributes: decl.attributes ?? [],
        members: decl.members ?? [],
        events: decl.events ?? [],
        cssParts: decl.cssParts ?? [],
        // TODO: analyzer should sort CSS custom properties
        cssProperties: [...(decl.cssProperties ?? [])].sort((a, b) =>
            a.name > b.name ? 1 : -1
        )
    };
};

export const getPublicFields = (members: Array<ClassMember> = []): Array<ClassField> =>
    members.filter(
        (x: ClassMember): x is ClassField => x.kind === 'field' && isPublic(x)
    );

export const getPublicMethods = (members: Array<ClassMember> = []): Array<ClassMethod> =>
    members.filter(
        (x: ClassMember): x is ClassMethod => x.kind === 'method' && isPublic(x)
    );
