// #region Imports

import { Animate, Appearance, ElevatioWeight, type IChatSubmitEvent, Placement, Size, type TemplateResult, TextFormatters, type TooltipElement, Variant, animate, html, interpolate, repeat, tooltip } from '@breadstone/mosaik-elements-foundation';
import { Icons } from '../../Resources/Icons/Icons';
import type { AppChatElement } from './AppChat';

// #endregion

/**
 * The template of the {@link AppChatElement}.
 *
 * @public
 */
export function appChatElementTemplate<T extends AppChatElement>(e: T): TemplateResult {
    return html`
        <!-- <mosaik-tooltip .content="${interpolate('Chat with {0}', e.name)}"
                        .placement="${Placement.Left}"
                        @connected="${(x: Event) => (x.target as TooltipElement).attach(e)}"></mosaik-tooltip> -->

        <mosaik-floating-trigger id="chat_trigger">
            <mosaik-fab id="chat"
                        .icon="${Icons.CHAT}"
                        .appearance="${Appearance.Solid}"
                        .variant="${Variant.Primary}"
                        .size="${Size.Large}"
                        ${tooltip({ content: 'Hello :)', placement: Placement.Left, staysOpen: true })}></mosaik-fab>
            <mosaik-floating slot="floating"
                             part="floating"
                             .anchor="${'chat'}"
                             .placement="${Placement.TopEnd}"
                             .distance="${8}"
                             .skidding="${8}"
                             .strategy="${'fixed'}"
                             .sync="${'none'}"
                             .elevation="${ElevatioWeight.Bold}"
                             .flipFallbackPlacements="${[Placement.Top, Placement.Bottom, Placement.Left, Placement.Right]}"
                             ?flip="${true}"
                             ?shift="${true}">
                <mosaik-chat part="chat"
                             style="--chat-border-style: none"
                             .width="${e.isMobile ? '100%' : '400px'}"
                             .height="${e.isMobile ? '100%' : '600px'}"
                             .begin="${'end'}"
                             .variant="${Variant.Primary}"
                             .appearance="${Appearance.Solid}"
                             .scrollToEnd="${true}"
                             @submited="${(x: IChatSubmitEvent) => e.submitCommand.execute(x.detail)}">
                    <mosaik-chat-header .title="${'Conversation'}">
                        <mosaik-button slot="after"
                                       .icon="${Icons.CLOSE}"
                                       .appearance="${Appearance.Plain}"
                                       @click="${() => e.closeCommand.execute()}"></mosaik-button>
                    </mosaik-chat-header>
                    ${repeat(e.messages, (x) => x.date, (x) => html`
                    <mosaik-chat-message .text="${x.message}"
                                         .attachments="${x.attachments}"
                                         .at="${x.date.toTimeString()}"
                                         .formatter="${TextFormatters.RICHTEXT}"
                                         ?reply="${x.reply}"
                                         ?is-busy="${x.isBusy}"
                                         ${animate({ ...Animate.slideInBottom })}>
                        <mosaik-avatar slot="avatar"
                                       .size="${Size.Tiny}"
                                       .text="${x.from}"></mosaik-avatar>
                    </mosaik-chat-message>
                    `)}
                </mosaik-chat>
            </mosaik-floating>
        </mosaik-floating-trigger>
    `;
}
