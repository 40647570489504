// #region Imports

import { Component } from '@breadstone/mosaik-elements-foundation';
import { ViewBase } from '../../Abstracts/ViewBase';
import { contactViewStyle } from './ContactViewStyle';
import { contactViewTemplate } from './ContactViewTemplate';

// #endregion

/**
 * The `{@link ContactViewElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-landing-contact-view',
    template: contactViewTemplate,
    styles: contactViewStyle
})
export class ContactView
    extends ViewBase {

    // #region Fields
    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-landing-contact-view';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-landing-contact-view': ContactView;
    }
}
