// #region Imports

import { Appearance, ElevatioWeight, ExpansionMode, HorizontalAlignment, IconPosition, ImageLegendPosition, Orientation, Spacing, type TemplateResult, TextFormatters, Variant, blankElementValidator, emailElementValidator, html, passwordBoxElementValueAccessor, repeat, requiredElementValidator, textBoxElementValueAccessor, translate, typography } from '@breadstone/mosaik-elements-foundation';
import { mouseMovementChild } from '../../../Components/MouseMovement/MouseMovementChildDirective';
import { globals } from '../../../Globals';
import type { ImpressionView } from './ImpressionView';

// #endregion

/**
 * The template of the {@link ArgumentView}.
 *
 * @public
 */
export function impressionViewTemplate<T extends ImpressionView>(e: T): TemplateResult {
    return html`
        <mosaik-router-anchor ${mouseMovementChild(0.001, 0.001)}
                              ${typography('headline4')}
                              .label="${translate('loc.landing.sample.title')}"
                              .ref="${'impression'}"></mosaik-router-anchor>
        <mosaik-spacer .thickness="${Spacing.Top}"></mosaik-spacer>
        <div class="bg" style="position: relative;">
            <mosaik-absolute>
                <mosaik-absolute-item .top="${'16px'}"
                                       .right="${'16px'}">
                    <mosaik-stack .orientation="${e.isMobile ? Orientation.Vertical : Orientation.Horizontal}"
                                    .horizontalAlignment="${HorizontalAlignment.Right}"
                                    .gap="${'16px'}">
                    <mosaik-segment>
                        ${repeat([Appearance.Outline, Appearance.Plain, Appearance.Solid, Appearance.Soft], (x) => x, (x) => html`
                        <mosaik-segment-item .label="${x}"
                                                .value="${x}"
                                                .isChecked="${e.appearance === x}"
                                                @click="${() => e.onAppearanceChanged(x)}"></mosaik-segment-item>
                        `)}
                    </mosaik-segment>
                    <mosaik-segment>
                        ${repeat([Variant.Primary, Variant.Secondary, Variant.Tertiary], (x) => x, (x) => html`
                        <mosaik-segment-item .value="${x}"
                                                .isChecked="${e.variant === x}"
                                                @click="${() => e.onVariantChanged(x)}">
                            <mosaik-dot slot="label" .variant="${x}"></mosaik-dot>
                        </mosaik-segment-item>
                        `)}
                    </mosaik-segment>
                    </mosaik-stack>
                </mosaik-absolute-item>
            </mosaik-absolute>

            <div part="control-host" class="a ${e.isExtraSmall ? 'xs' : ''}">
                ${expanderTemplate(e)}
            </div>
            <div part="control-host" class="b ${e.isExtraSmall ? 'xs' : ''}">
                ${sliderTemplate(e)}
            </div>
            <div part="control-host" class="c ${e.isExtraSmall ? 'xs' : ''}">
                ${toggleSwitchTemplate(e)}
            </div>
            <div part="control-host" class="d ${e.isExtraSmall ? 'xs' : ''}">
                ${chipsTemplate(e)}
            </div>
            <div part="control-host" class="e">
                ${pinboxTemplate(e)}
            </div>
            <div part="control-host" class="f ${e.isExtraSmall ? 'xs' : ''}">
                ${tooltipTemplate(e)}
            </div>
            <div part="control-host" class="g">
                ${tabTemplate(e)}
            </div>
            <div part="control-host" class="h">
                ${buttonGroupTemplate(e)}
            </div>
            <div part="control-host" class="i">
                ${toolbarTemplate(e)}
            </div>
            <div part="control-host" class="k">
                ${carouselTemplate(e)}
            </div>
            <div part="control-host" class="l">
                ${dialogTemplate(e)}
            </div>
        </div>
    `;
}

function expanderTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-expander-group part="control"
                               .mode="${ExpansionMode.Single}"
                               .appearance="${e.appearance}"
                               .variant="${e.variant}">
            <mosaik-expander class="control"
                             .header="${translate('loc.landing.sample.expander.1.header')}">
                <mosaik-text .text="${translate('loc.landing.sample.expander.1.content', globals.name)}"
                             .formatter="${TextFormatters.RICHTEXT}"
                             .wrap="${true}"></mosaik-text>
            </mosaik-expander>
            <mosaik-expander class="control"
                             .header="${translate('loc.landing.sample.expander.2.header')}"
                             .isExpanded="${true}">
                <mosaik-text .text="${translate('loc.landing.sample.expander.2.content')}"
                             .wrap="${true}"></mosaik-text>
            </mosaik-expander>
            <mosaik-expander class="control"
                             .header="${translate('loc.landing.sample.expander.3.header')}">
                <mosaik-text .text="${translate('loc.landing.sample.expander.3.content')}"
                             .wrap="${true}"></mosaik-text>
            </mosaik-expander>
        </mosaik-expander-group>
    `;
}

function sliderTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-slider part="control"
                       .min="${0}"
                       .max="${10}"
                       .step="${1}"
                       .value="${3}"
                       .ticks="${1}"
                       .appearance="${e.appearance}"
                       .variant="${e.variant}"></mosaik-slider>
    `;
}

function toggleSwitchTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-toggle-switch part="control"
                              .isChecked="${true}"
                              .appearance="${e.appearance}"
                              .variant="${e.variant}"></mosaik-toggle-switch>
    `;
}

function chipsTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-stack part="control"
                      .orientation="${Orientation.Horizontal}"
                      .gap="${'16px'}">
            <mosaik-chip part="control" class="control"
                         .label="${translate('loc.landing.sample.chip.1.content')}"
                         .closeable="${true}"
                         .appearance="${e.appearance}"
                         .variant="${e.variant}"></mosaik-chip>
            <mosaik-chip part="control" class="control"
                         .label="${translate('loc.landing.sample.chip.2.content')}"
                         .closeable="${true}"
                         .appearance="${e.appearance}"
                         .variant="${e.variant}"></mosaik-chip>
        </mosaik-stack>
    `;
}

function pinboxTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-pinbox part="control"
                       .placeholder="${'○'}"
                       .variant="${e.variant}"
                       .appearance="${e.appearance}"></mosaik-pinbox>
    `;
}

function tooltipTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-tooltip part="control"
                        .isOpen="${true}"
                        .staysOpen="${true}"
                        .content="${translate('loc.landing.sample.tooltip')}"
                        .formatter="${TextFormatters.RICHTEXT}">
            <mosaik-button class="control"
                           .variant="${e.variant}"
                           .appearance="${e.appearance}"
                           .icon="${'M11.883 3.007 12 3a1 1 0 0 1 .993.883L13 4v7h7a1 1 0 0 1 .993.883L21 12a1 1 0 0 1-.883.993L20 13h-7v7a1 1 0 0 1-.883.993L12 21a1 1 0 0 1-.993-.883L11 20v-7H4a1 1 0 0 1-.993-.883L3 12a1 1 0 0 1 .883-.993L4 11h7V4a1 1 0 0 1 .883-.993L12 3l-.117.007Z'}"></mosaik-button>
        </mosaik-tooltip>
    `;
}

function tabTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-tab part="control"
                    .appearance="${e.appearance}"
                    .variant="${e.variant}">
                <mosaik-tab-item .label="${translate('loc.landing.sample.tab.1.header')}">
                    <mosaik-spacer .thickness="${Spacing.All}">
                    <form>
                        <mosaik-stack .gap="${'24px'}"
                                      .orientation="${Orientation.Vertical}">
                            <mosaik-text .text="${translate('loc.landing.sample.tab.1.content.description')}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-form-field .validators="${[requiredElementValidator() as any]}"
                                               .accessor="${textBoxElementValueAccessor}">
                                <mosaik-textbox .label="${translate('loc.landing.sample.tab.1.content.label')}" .value="${'John Doe'}"
                                                .variant="${e.variant}"
                                                .required="${true}"
                                                .appearance="${e.appearance}"></mosaik-textbox>
                            </mosaik-form-field>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.landing.sample.tab.1.content.action')}"
                                            .variant="${e.variant}"
                                            .appearance="${e.appearance}"
                                            ?is-busy="${e.isBusy}"
                                            @click="${() => e.onTabSave()}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-stack>
                    </form>
                    </mosaik-spacer>
                </mosaik-tab-item>
                <mosaik-tab-item .label="${translate('loc.landing.sample.tab.2.header')}">
                    <mosaik-spacer .thickness="${Spacing.All}">
                    <form>
                        <mosaik-stack .gap="${'24px'}"
                                        .orientation="${Orientation.Vertical}">
                            <mosaik-text .text="${translate('loc.landing.sample.tab.2.content.description')}"
                                            .wrap="${true}"></mosaik-text>
                            <mosaik-form-field .validators="${[blankElementValidator(), requiredElementValidator()] as any}"
                                                .accessor="${passwordBoxElementValueAccessor}">
                                <mosaik-passwordbox .label="${translate('loc.landing.sample.tab.2.content.label')}"
                                                    .variant="${e.variant}"
                                                    .showable="${true}"
                                                    .required="${true}"
                                                    .appearance="${e.appearance}"></mosaik-passwordbox>
                            </mosaik-form-field>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                                <mosaik-button .label="${translate('loc.landing.sample.tab.2.content.action')}"
                                        .variant="${e.variant}"
                                        .appearance="${e.appearance}"
                                        ?is-busy="${e.isBusy}"
                                        @click="${() => e.onTabSave()}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-stack>
                    </form>
                    </mosaik-spacer>
                </mosaik-tab-item>
                <mosaik-tab-item .label="${translate('loc.landing.sample.tab.3.header')}">
                    <mosaik-spacer .thickness="${Spacing.All}">
                    <form>
                        <mosaik-stack .gap="${'24px'}"
                                      .orientation="${Orientation.Vertical}">
                            <mosaik-text .text="${translate('loc.landing.sample.tab.3.content.description')}"
                                         .wrap="${true}"></mosaik-text>
                            <mosaik-form-field .validators="${[requiredElementValidator(), emailElementValidator()] as any}"
                                               .accessor="${textBoxElementValueAccessor}">
                                <mosaik-textbox .label="${translate('loc.landing.sample.tab.3.content.label')}"
                                                .variant="${e.variant}"
                                                .pattern="${'[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'}"
                                                .title="${translate('Please enter a valid email address')}"
                                                .required="${true}"
                                                .appearance="${e.appearance}"></mosaik-textbox>
                            </mosaik-form-field>
                            <mosaik-stack .horizontalAlignment="${HorizontalAlignment.Right}">
                            <mosaik-button .label="${translate('loc.landing.sample.tab.3.content.action')}"
                                           .variant="${e.variant}"
                                           .appearance="${e.appearance}"
                                           ?is-busy="${e.isBusy}"
                                           @click="${() => e.onTabSave()}"></mosaik-button>
                            </mosaik-stack>
                        </mosaik-stack>
                    </form>
                    </mosaik-spacer>
                </mosaik-tab-item>
            </mosaik-tab>
    `;
}

function buttonGroupTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-button-group part="control"
                             .variant="${e.variant}"
                             .appearance="${e.appearance}">
                <mosaik-button .label="${translate('loc.landing.sample.buttonGroup.1.label')}"
                               .orientation="${Orientation.Vertical}"
                               .icon="${'M2 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM2 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
                <mosaik-button .label="${translate('loc.landing.sample.buttonGroup.2.label')}"
                               .orientation="${Orientation.Vertical}"
                               .icon="${'M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM6 18a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
                <mosaik-button .label="${translate('loc.landing.sample.buttonGroup.3.label')}"
                               .orientation="${Orientation.Vertical}"
                               .icon="${'M5 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM9 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}"></mosaik-button>
            </mosaik-button-group>
    `;
}

function toolbarTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-toolbar part="control">
            <mosaik-toggle-button slot="start" .isChecked="${true}" .icon="${'M6 5.75C6 4.784 6.784 4 7.75 4h4.749c2.823 0 4.751 2.34 4.751 4.75 0 .931-.288 1.852-.803 2.632C17.369 12.239 18 13.47 18 15c0 3.133-2.677 5-5 5H7.75A1.75 1.75 0 0 1 6 18.25V5.75Zm3.5 7.75v3H13c.312 0 .71-.138 1.024-.421.288-.26.476-.615.476-1.079 0-.888-.745-1.5-1.5-1.5H9.5Zm0-3.5h3c.715 0 1.25-.592 1.25-1.25 0-.657-.536-1.25-1.251-1.25H9.5V10Z'}" .appearance="${Appearance.Plain}"></mosaik-toggle-button>
            <mosaik-toggle-button slot="start" .isChecked="${true}" .icon="${'M10.649 18.5h3.847a1 1 0 0 1 0 2h-9.5a.997.997 0 0 1-.996-1c0-.552.445-1 .996-1h3.51L13.332 6H9.997a1 1 0 0 1 0-2H18.5a1 1 0 0 1 0 2h-3.025L10.65 18.5Z'}" .appearance="${Appearance.Plain}"></mosaik-toggle-button>
            <mosaik-toggle-button slot="start" .isChecked="${true}" .icon="${'M6 4.5a1 1 0 0 1 2 0v6.001c-.003 3.463 1.32 4.999 4.247 4.999 2.928 0 4.253-1.537 4.253-5v-6a1 1 0 1 1 2 0v6c0 4.54-2.18 7-6.253 7S5.996 15.039 6 10.5v-6ZM7 21a1 1 0 1 1 0-2h10.5a1 1 0 1 1 0 2H7Z'}" .appearance="${Appearance.Plain}"></mosaik-toggle-button>
            <mosaik-divider slot="start" .orientation="${Orientation.Vertical}"></mosaik-divider>
            <mosaik-button slot="start" .icon="${'M2 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM2 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}" .appearance="${Appearance.Plain}"></mosaik-button>
            <mosaik-button slot="start" .icon="${'M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM6 18a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}" .appearance="${Appearance.Plain}"></mosaik-button>
            <mosaik-button slot="start" .icon="${'M5 6a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM9 18a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1ZM3 11a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z'}" .appearance="${Appearance.Plain}"></mosaik-button>
            <mosaik-divider slot="start" .orientation="${Orientation.Vertical}"></mosaik-divider>
            <mosaik-select slot="start" .appearance="${Appearance.Plain}">
                <mosaik-select-item .value="${2}" .label="${'2px'}"></mosaik-select-item>
                <mosaik-select-item .value="${4}" .label="${'4px'}"></mosaik-select-item>
                <mosaik-select-item .value="${6}" .label="${'6px'}"></mosaik-select-item>
                <mosaik-select-item .value="${8}" .label="${'8px'}"></mosaik-select-item>
                <mosaik-select-item .value="${10}" .label="${'10px'}"></mosaik-select-item>
                <mosaik-select-item .value="${12}" .label="${'12px'}"></mosaik-select-item>
                <mosaik-select-item .value="${14}" .label="${'14px'}" .isSelected="${true}" .isActive="${true}"></mosaik-select-item>
                <mosaik-select-item .value="${16}" .label="${'16px'}"></mosaik-select-item>
            </mosaik-select>
        </mosaik-toolbar>
    `;
}

function carouselTemplate(e: ImpressionView): TemplateResult {
    return html`
    <mosaik-carousel part="control"
                     .showNavigation="${true}"
                     .showIndicator="${true}"
                     .appearance="${e.appearance}"
                     .loop="${true}"
                     .variant="${e.variant}">
        <mosaik-carousel-item>
            <mosaik-image
                    .src="${'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                    .width="${'200px'}"
                    .height="${'300px'}"
                    .legend="${'#1'}"
                    .legendPosition="${ImageLegendPosition.Top}"></mosaik-image>
        </mosaik-carousel-item>
        <mosaik-carousel-item>
            <mosaik-image
                    .src="${'https://images.unsplash.com/photo-1562177257-977b3bd4d7ec?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                    .width="${'200px'}"
                    .height="${'300px'}"
                    .legend="${'#2'}"
                    .legendPosition="${ImageLegendPosition.Top}"></mosaik-image>
        </mosaik-carousel-item>
        <mosaik-carousel-item>
            <mosaik-image
                    .src="${'https://images.unsplash.com/photo-1541723568817-3bb3d2dd5505?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxwcmlkZXxlbnwwfHwwfHx8MA%3D%3D'}"
                    .width="${'200px'}"
                    .height="${'300px'}"
                    .legend="${'#3'}"
                    .legendPosition="${ImageLegendPosition.Top}"></mosaik-image>
        </mosaik-carousel-item>
    </mosaik-carousel>
    `;
}

function dialogTemplate(e: ImpressionView): TemplateResult {
    return html`
        <mosaik-dialog part="control"
                       class="control"
                       style="top: 30rem; left: 28rem; display: flex; position: absolute;"
                       .isOpen="${true}"
                       .width="${'380px'}"
                       .height="${'220px'}"
                       .elevation="${ElevatioWeight.None}"
                       .appearance="${e.appearance}">
            <mosaik-dialog-header .text="${translate('loc.landing.sample.dialog.title')}"
                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-dialog-header>
            <mosaik-dialog-content .text="${translate('loc.landing.sample.dialog.content')}"
                                    .formatter="${TextFormatters.RICHTEXT}"></mosaik-dialog-content>
            <mosaik-dialog-actions>
                <mosaik-button slot="prefix"
                                .label="${translate('loc.landing.sample.dialog.help')}"
                                .icon="${'M12 4C9.238 4 7 6.238 7 9a1 1 0 0 0 2 0c0-1.658 1.342-3 3-3s3 1.342 3 3c0 .816-.199 1.294-.438 1.629-.262.365-.625.638-1.128.985l-.116.078c-.447.306-1.023.699-1.469 1.247-.527.648-.849 1.467-.849 2.561v.5a1 1 0 1 0 2 0v-.5c0-.656.178-1.024.4-1.299.257-.314.603-.552 1.114-.903l.053-.037c.496-.34 1.133-.786 1.62-1.468C16.7 11.081 17 10.183 17 9c0-2.762-2.238-5-5-5ZM12 21.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z'}"
                                .variant="${e.variant}"
                                .appearance="${Appearance.Plain}"></mosaik-button>
                <mosaik-button .label="${translate('loc.landing.sample.dialog.no')}"
                                .variant="${e.variant}"
                                .appearance="${Appearance.Plain}"
                                @click="${() => e.onDialogConfirm(false)}"></mosaik-button>
                <mosaik-button .label="${translate('loc.landing.sample.dialog.yes')}"
                                .variant="${e.variant}"
                                .appearance="${e.appearance}"
                                .iconPosition="${IconPosition.After}"
                                @click="${() => e.onDialogConfirm(true)}"></mosaik-button>
            </mosaik-dialog-actions>
        </mosaik-dialog>
    `;
}
