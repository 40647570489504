// #region Imports

import { Appearance, ButtonGroupElement, CSSResultGroup, Component, FlowDirection, Orientation, Variant } from '@breadstone/mosaik-elements-foundation';
import { buttonGroupElementDocs } from '../../../.Generated/mosaik-button-group';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';
import { ButtonGroupSampleTemplates } from './ButtonGroupSample.Templates';

// #endregion

/**
 * The `{@link ButtonGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-button-group-sample'
})
export class ButtonGroupSampleElement extends SampleBaseElement<ButtonGroupElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ButtonGroupElement> {
        return {
            header: 'Button Group',
            description: META.description(ButtonGroupElement.is),
            graph: META.graph(ButtonGroupElement.is),
            playground: {
                template: [
                    ButtonGroupSampleTemplates.defaultTemplate(),
                    ButtonGroupSampleTemplates.withTogglesTemplate()
                ],
                properties: META.properties2<ButtonGroupElement>(ButtonGroupElement.is, {
                    appearance: Appearance.Soft,
                    dir: FlowDirection.Auto,
                    orientation: Orientation.Horizontal,
                    variant: Variant.Primary
                }),
                events: META.events(ButtonGroupElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties2<ButtonGroupElement>(ButtonGroupElement.is, {
                    appearance: Appearance.Soft,
                    dir: FlowDirection.Auto,
                    orientation: Orientation.Horizontal,
                    variant: Variant.Primary
                }), [] as Array<IPlaygroundProperty<ButtonGroupElement>>, (a, b) => a.key === b.key)
            },
            docs: [buttonGroupElementDocs()]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-button-group-sample': ButtonGroupSampleElement;
    }
}
