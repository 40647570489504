
// #region Imports

import { Component, FlowDirection, MessageBoxElement, ref } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { dialogElementDocs } from '../../../.Generated/mosaik-dialog';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link MessageBoxSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-message-box-sample'
})
export class MessageBoxSampleElement extends SampleBaseElement<MessageBoxElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<MessageBoxElement> {
        return {
            header: 'MessageBox',
            description: META.description(MessageBoxElement.is),
            graph: META.graph(MessageBoxElement.is),
            playground: {
                template: (_props, set) => html`
                    <mosaik-message-box ${ref((el) => set(el as MessageBoxElement))}></mosaik-message-box>
                `,
                properties: META.properties<MessageBoxElement>(MessageBoxElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'header',
                        value: 'Network Connection'
                    },
                    {
                        key: 'subHeader',
                        value: 'We detected that you are currently *offline*. Do you want to turn the Wi-Fi connection on?'
                    },
                    {
                        key: 'message',
                        value: 'Enabling Wi-Fi offers numerous advantages: Access a wide range of online resources, stay connected with messaging and video calls, enjoy streaming and social media, facilitate remote work and learning, receive real-time updates and notifications, improve navigation with maps and location services, control smart home devices, and save on mobile data usage. Connect now to enjoy these benefits.'
                    },
                    {
                        key: 'pressEscapeToClose',
                        value: true
                    },
                    {
                        key: 'clickOutsideToClose',
                        value: true
                    },
                    {
                        key: 'buttons',
                        value: 'ok'
                    },
                    {
                        key: 'labels',
                        value: { ok: 'OK' }
                    }
                    // { key: 'formatter', value: 'default' }
                ]),
                events: META.events(MessageBoxElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<MessageBoxElement>(MessageBoxElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'isOpen',
                        value: true
                    },
                    {
                        key: 'hasBackdrop',
                        value: true
                    },
                    {
                        key: 'header',
                        value: 'Network Connection'
                    },
                    {
                        key: 'subHeader',
                        value: 'We detected that you are currently *offline*. Do you want to turn the Wi-Fi connection on?'
                    },
                    {
                        key: 'message',
                        value: 'Enabling Wi-Fi offers numerous advantages: Access a wide range of online resources, stay connected with messaging and video calls, enjoy streaming and social media, facilitate remote work and learning, receive real-time updates and notifications, improve navigation with maps and location services, control smart home devices, and save on mobile data usage. Connect now to enjoy these benefits.'
                    },
                    {
                        key: 'pressEscapeToClose',
                        value: true
                    },
                    {
                        key: 'clickOutsideToClose',
                        value: true
                    },
                    {
                        key: 'buttons',
                        value: 'ok'
                    },
                    {
                        key: 'labels',
                        value: { ok: 'OK' }
                    }
                ]), [
                ] as Array<IPlaygroundProperty<MessageBoxElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                dialogElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {

    interface HTMLElementTagNameMap {

        'app-message-box-sample': MessageBoxSampleElement;
    }
}
