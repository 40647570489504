// #region Imports

import { Component, FlowDirection, QRCodeElement, QRErrorCorrection } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { qrcodeElementDocs } from '../../../.Generated/mosaik-qrcode';
import { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';
import { colorTemplate, percentTemplate } from '../../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { merge } from '../../../Extensions/ArrayExtensions';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link QRCodeSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-qrcode-sample'
})
export class QRCodeSampleElement extends SampleBaseElement<QRCodeElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<QRCodeElement> {
        return {
            header: 'QRCode',
            description: META.description(QRCodeElement.is),
            graph: META.graph(QRCodeElement.is),
            playground: {
                template: html`
                    <mosaik-qrcode></mosaik-qrcode>
                `,
                properties: META.properties<QRCodeElement>(QRCodeElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'text',
                        value: 'https://mosaik.breadstone.de'
                    },
                    {
                        key: 'dimension',
                        value: 256
                    },
                    {
                        key: 'errorCorrection',
                        value: QRErrorCorrection.H
                    },
                    {
                        key: 'radius',
                        value: 0
                    }
                ]),
                events: META.events<QRCodeElement>(QRCodeElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<QRCodeElement>(QRCodeElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'text',
                        value: 'https://mosaik.breadstone.de'
                    },
                    {
                        key: 'dimension',
                        value: 256
                    },
                    {
                        key: 'errorCorrection',
                        value: QRErrorCorrection.H
                    },
                    {
                        key: 'radius',
                        value: 0
                    }
                ]), [
                    {
                        key: 'radius',
                        template: percentTemplate
                    },
                    {
                        key: 'backgroundColor',
                        template: colorTemplate
                    },
                    {
                        key: 'foregroundColor',
                        template: colorTemplate
                    }
                ] as Array<IPlaygroundProperty<QRCodeElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                qrcodeElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-qrcode-sample': QRCodeSampleElement;
    }
}
