// #region Imports

import { Appearance, Component, ExpanderGroupElement, ExpansionMode, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { expanderGroupElementDocs } from '../../.Generated/mosaik-expander-group';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ExpanderGroupSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-expander-group-sample'
})
export class ExpanderGroupSampleElement extends SampleBaseElement<ExpanderGroupElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
      * Returns the `styles` property.
      *
      * @public
      * @static
      * @readonly
      */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ExpanderGroupElement> {

        return {
            header: 'Expander Group',
            description: META.description(ExpanderGroupElement.is),
            graph: META.graph(ExpanderGroupElement.is),
            playground: {
                template: html`
                    <mosaik-expander-group style="width: 316px;">
                        <mosaik-expander .header="${'Expander 1'}"
                                         .isExpanded="${true}">
                            <mosaik-text .wrap="${true}" .text="${'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}"></mosaik-text>
                        </mosaik-expander>
                        <mosaik-expander .header="${'Expander 2'}">
                            <mosaik-text .wrap="${true}" .text="${'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}"></mosaik-text>
                        </mosaik-expander>
                        <mosaik-expander .header="${'Expander 3'}">
                            <mosaik-text .wrap="${true}" .text="${'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}"></mosaik-text>
                        </mosaik-expander>
                    </mosaik-expander-group>
                `,
                properties: META.properties<ExpanderGroupElement>(ExpanderGroupElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'mode',
                        value: ExpansionMode.Single
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    }
                ]),
                events: META.events(ExpanderGroupElement.is)
            },
            propertyGrid: {
                properties: META.properties<ExpanderGroupElement>(ExpanderGroupElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'mode',
                        value: ExpansionMode.Single
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    }
                ])
            },
            docs: [
                expanderGroupElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-expander-group-sample': ExpanderGroupSampleElement;
    }
}
