// #region Imports

import { Appearance, Component, ExpandDirection, ExpanderElement, FlowDirection, Variant } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { expanderElementDocs } from '../../.Generated/mosaik-expander';
import { expanderHeaderElementDocs } from '../../.Generated/mosaik-expander-header';
import { expanderSubHeaderElementDocs } from '../../.Generated/mosaik-expander-sub-header';
import { IPlaygroundProperty } from '../../Components/Playground/IPlaygroundProperty';
import { animationTemplate } from '../../Components/Playground/PlaygroundPropertyGridElementTemplates';
import { Memoize } from '../../Decorators/MemoizeDecorator';
import { merge } from '../../Extensions/ArrayExtensions';
import { META } from '../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from './Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ExpanderSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-expander-sample'
})
export class ExpanderSampleElement extends SampleBaseElement<ExpanderElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ExpanderElement> {

        return {
            header: 'Expander',
            description: META.description(ExpanderElement.is),
            graph: META.graph(ExpanderElement.is),
            playground: {
                template: [['default', html`
                    <mosaik-expander style="width: 316px;">
                        <mosaik-text .wrap="${true}" .text="${'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}"></mosaik-text>
                    </mosaik-expander>
                `], ['native', html`
                    <details>
                        <p>Some content</p>
                        <!-- <summary>Expander</summary> -->
                        <caption>Sub Header</caption>
                        <legend>Sub Header</legend>
                        <p>Some content</p>
                    </details>
                `]],
                properties: META.properties<ExpanderElement>(ExpanderElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    },
                    {
                        key: 'direction',
                        value: ExpandDirection.Down
                    },
                    {
                        key: 'isExpanded',
                        value: true
                    },
                    {
                        key: 'icon',
                        value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z'
                    },
                    {
                        key: 'header',
                        value: 'Expander'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub Header'
                    }
                ]),
                events: META.events(ExpanderElement.is)
            },
            propertyGrid: {
                properties: merge(META.properties<ExpanderElement>(ExpanderElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Default
                    },
                    {
                        key: 'direction',
                        value: ExpandDirection.Down
                    },
                    {
                        key: 'isExpanded',
                        value: true
                    },
                    {
                        key: 'icon',
                        value: 'M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z'
                    },
                    {
                        key: 'header',
                        value: 'Expander'
                    },
                    {
                        key: 'subHeader',
                        value: 'Sub Header'
                    }
                ]), [
                    {
                        key: 'enter',
                        template: animationTemplate
                    },
                    {
                        key: 'exit',
                        template: animationTemplate
                    }
                ] as Array<IPlaygroundProperty<ExpanderElement>>, (a, b) => a.key === b.key)
            },
            docs: [
                expanderElementDocs(),
                expanderHeaderElementDocs(),
                expanderSubHeaderElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-expander-sample': ExpanderSampleElement;
    }
}
