// #region Imports

import { ApiError } from '@backend/ApiError';
import { IUserResponse } from '@backend/Index';
import { Component, FormElement, IFormSubmitEvent, Inject, Property, Query, Toast2, ToastServiceLocator, TranslatorServiceLocator, Variant } from '@breadstone/mosaik-elements-foundation';
import { IFeedbackResponse } from '../../Backend/Api/Models/IFeedbackResponse';
import { FeedbackService } from '../../Backend/Api/Services/FeedbackService';
import { globals } from '../../Globals';
import { SessionManager } from '../../Services/SessionManager';
import { ViewBase } from '../Abstracts/ViewBase';
import { feedbackDetailViewStyle } from './FeedbackDetailViewStyle';
import { feedbackDetailViewTemplate } from './FeedbackDetailViewTemplate';

// #endregion

/**
 * The `{@link FeedbackDetailView}` view.
 *
 * @public
 */
@Component({
    selector: 'app-feedback-detail-view',
    template: feedbackDetailViewTemplate,
    styles: feedbackDetailViewStyle,
    imports: []
})
export class FeedbackDetailView
    extends ViewBase {

    // #region Fields

    @Inject(FeedbackService) private readonly _feedbackService!: FeedbackService;
    @Inject(SessionManager) private readonly _sessionManager!: SessionManager;
    @Query('[part="form"]') private readonly _formElement!: FormElement;
    private _feedback: IFeedbackResponse | null = null;
    private _user: IUserResponse | null = null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._feedback = null;
        this._user = this._sessionManager.me;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-feedback-detail-view';
    }

    /**
     * Gets or sets the `feedback` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get feedback(): IFeedbackResponse | null {
        return this._feedback;
    }

    public set feedback(value: IFeedbackResponse | null) {
        if (this._feedback !== value) {
            this._feedback = value;
            this.requestUpdate('feedback');
        }
    }

    /**
     * Gets or sets the `user` property.
     *
     * @public
     */
    @Property({ type: Object })
    public get user(): IUserResponse | null {
        return this._user;
    }

    public set user(value: IUserResponse | null) {
        if (this._user !== value) {
            this._user = value;
            this.requestUpdate('user');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        void this.initialize();
        this.initalizeFeedbackTracking();
    }

    public addComment(): void {
        const isValid = this._formElement.reportValidity();

        if (isValid) {
            this._formElement.submit();
        }
    }

    public deleteComment(feedbackId: string, commentId: string): void {
        this.isBusy = true;

        void this._feedbackService
            .uncomment({
                authorization: this._sessionManager.session,
                feedbackId: feedbackId,
                commentId: commentId
            })
            .then((x) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this._feedback!.comments = this._feedback!.comments.filter((y) => y.id !== x.id);
                this.requestUpdate('feedback');

                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.success'),
                    variant: Variant.Success,
                    timeout: Toast2.Timeout.MEDIUM
                });
            })
            .catch((x: ApiError) => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.error'),
                    variant: Variant.Danger,
                    timeout: Toast2.Timeout.MEDIUM
                });
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    public onSubmit(x: IFormSubmitEvent): void {
        if (!this._feedback) {
            return;
        }

        this.isBusy = true;

        void this._feedbackService
            .comment({
                authorization: this._sessionManager.session,
                feedbackId: this._feedback.id,
                body: {
                    body: x.detail.data.raw<{ comment: string }>().comment
                }
            })
            .then((y) => {
                this._feedback?.comments.push(y);
                this.requestUpdate('feedback');

                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.success'),
                    variant: Variant.Success,
                    timeout: Toast2.Timeout.MEDIUM
                });
            })
            .catch((y: ApiError) => {
                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.error'),
                    variant: Variant.Danger,
                    timeout: Toast2.Timeout.MEDIUM
                });
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    public onVote(): void {
        if (!this._feedback) {
            return;
        }

        this.isBusy = true;

        void this._feedbackService
            .vote({
                authorization: this._sessionManager.session,
                feedbackId: this._feedback.id
            })
            .then((x) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this._feedback!.votes = [...this._feedback!.votes, x];
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this._feedback!.votesCount = x.count;
                this.requestUpdate('feedback');

                void ToastServiceLocator.current.open({
                    header: TranslatorServiceLocator.current.translate('loc.global.success'),
                    variant: Variant.Success,
                    timeout: Toast2.Timeout.MEDIUM
                });
            })
            .catch((x: ApiError) => {
                if (x.statusCode === 400) {
                    void this._feedbackService.unvote({
                        authorization: this._sessionManager.session,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        feedbackId: this._feedback!.id
                    }).then((y) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this._feedback!.votes = this._feedback!.votes.filter((z) => z.id !== y.id);
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this._feedback!.votesCount = y.count;
                        this.requestUpdate('feedback');

                        void ToastServiceLocator.current.open({
                            header: TranslatorServiceLocator.current.translate('loc.global.success'),
                            variant: Variant.Success,
                            timeout: Toast2.Timeout.MEDIUM
                        });
                    });
                } else {
                    void ToastServiceLocator.current.open({
                        header: TranslatorServiceLocator.current.translate('loc.global.error'),
                        variant: Variant.Danger,
                        timeout: Toast2.Timeout.MEDIUM
                    });
                }
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    /**
     * @private
     */
    private async initialize(): Promise<void> {
        this.isBusy = true;

        const id = this.extractId();

        await this._feedbackService
            .details({ id: id })
            .then((x) => this.feedback = x)
            .finally(() => this.isBusy = false);
    }

    /**
     * @private
     */
    private initalizeFeedbackTracking(): void {
        // this.addSubscription(this._feedbackService.track().subscribe((x) => {
        //     console.log('TRAKING FROM VIEW', x);
        // }));
    }

    /**
     * @private
     */
    private extractId(): string {
        let id: string | null = null;

        // get router query
        if (!globals.myWebHostingIsAStupidIdiot) {
            // IMPORTANT: this only works if the router is configured without hash strategy.
            const params = new URLSearchParams(window.location.search);
            id = params.get('id');
        } else {
            // IMPORTANT: this only works if the router is configured to use the hash strategy.
            const search = window.location.hash.split('?')[1];
            const params = new URLSearchParams(search);
            id = params.get('id');
        }

        return id ?? '';
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-feedback-detail-view': FeedbackDetailView;
    }
}
