// #region Imports

import { Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { IMemberResponse } from '../../Backend/Api/Models/IMemberResponse';
import { MembersService } from '../../Backend/Api/Services/MembersService';
import { SocialLinkElement } from '../../Components/SocialLink/SocialLinkElement';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { aboutMemberViewStyle } from './AboutMemberViewStyle';
import { aboutMemberViewTemplate } from './AboutMemberViewTemplate';

// #endregion

/**
 * The `{@link AboutMemberView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-about-member-view',
    template: aboutMemberViewTemplate,
    styles: aboutMemberViewStyle,
    imports: [
        SocialLinkElement
    ],
    providers: [
        {
            provide: MembersService,
            useClass: MembersService
        }
    ]
})
export class AboutMemberView extends ViewBase {

    // #region Fields

    @Inject(MembersService)
    private readonly _membersService!: MembersService;
    private _member: IMemberResponse | null;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._member = null;
        // this.lang = 'en';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-about-member-view';
    }

    /**
     * Gets or sets the `member` property.
     *
     * @public
     * @readonly
     */
    @Property({ type: Object })
    public get member(): IMemberResponse | null {
        return this._member;
    }
    private set member(value: IMemberResponse | null) {
        if (this._member !== value) {
            this._member = value;
            this.requestUpdate('member');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();

        let id: string | null = null;

        // get router query
        if (!globals.myWebHostingIsAStupidIdiot) {
            // IMPORTANT: this only works if the router is configured without hash strategy.
            const params = new URLSearchParams(window.location.search);
            id = params.get('id');
        } else {
            // IMPORTANT: this only works if the router is configured to use the hash strategy.
            const search = window.location.hash.split('?')[1];
            const params = new URLSearchParams(search);
            id = params.get('id');
        }

        if (id) {
            this.isBusy = true;

            void this._membersService.getMember({ id: id })
                .then((x) => {
                    this.member = x;
                })
                .then(() => {
                    this.isBusy = false;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-about-member-view': AboutMemberView;
    }
}
