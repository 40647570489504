// #region Imports

import { ColorAreaElement, Component } from '@breadstone/mosaik-elements-foundation';
import { html, type CSSResultGroup } from 'lit';
import { colorAreaElementDocs } from '../../../.Generated/mosaik-color-area';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link ColorAreaSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-color-area-sample'
})
export class ColorAreaSampleElement extends SampleBaseElement<ColorAreaElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {
        return SampleBaseElement.styles;
    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<ColorAreaElement> {
        return {
            header: 'Color Area',
            description: META.description(ColorAreaElement.is),
            graph: META.graph(ColorAreaElement.is),
            playground: {
                template: html`
                    <mosaik-color-area></mosaik-color-area>
                `,
                properties: [

                ],
                events: META.events<ColorAreaElement>(ColorAreaElement.is)
            },
            propertyGrid: {
                properties: META.properties<ColorAreaElement>(ColorAreaElement.is, [

                ])
            },
            docs: [
                colorAreaElementDocs()
            ]
        };
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-color-area-sample': ColorAreaSampleElement;
    }
}
