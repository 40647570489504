// #region Imports

import { Appearance, CSSResultGroup, CarouselElement, Component, FlowDirection, ImageLegendPosition, Variant, html } from '@breadstone/mosaik-elements-foundation';
import { carouselElementDocs } from '../../../.Generated/mosaik-carousel';
import { carouselItemElementDocs } from '../../../.Generated/mosaik-carousel-item';
import { Memoize } from '../../../Decorators/MemoizeDecorator';
import { META } from '../../../Services/MetaService';
import { SampleBaseElement, type ISampleDescription } from '../Abstracts/SampleBase';

// #endregion

/**
 * The `{@link CarouselSampleElement}` element.
 *
 * @public
 */
@Component({
    selector: 'app-carousel-sample'
})
export class CarouselSampleElement extends SampleBaseElement<CarouselElement> {

    // #region Ctor

    /**
     * @public
     */
    public constructor() {

        super();

    }

    // #endregion

    // #region Properties

    /**
     * Returns the `styles` property.
     *
     * @public
     * @static
     * @readonly
     */
    public static override get styles(): CSSResultGroup {

        return SampleBaseElement.styles;

    }

    /**
     * @protected
     * @readonly
     */
    @Memoize()
    protected get description(): ISampleDescription<CarouselElement> {

        return {
            header: 'Carousel',
            description: META.description(CarouselElement.is),
            graph: META.graph(CarouselElement.is),
            playground: {
                template: [
                    ['default', () => html`
                    <mosaik-carousel style="width: 200px">
                        <mosaik-carousel-item >
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'dark lights'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item>
                        <mosaik-carousel-item>
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1562177257-977b3bd4d7ec?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'stoney rainbow'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item>
                        <mosaik-carousel-item>
                            <mosaik-image .src="${'https://images.unsplash.com/photo-1541723568817-3bb3d2dd5505?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxwcmlkZXxlbnwwfHwwfHx8MA%3D%3D'}"
                                          .width="${'200px'}"
                                          .height="${'300px'}"
                                          .legend="${'colorful liquids'}"
                                          .legendPosition="${ImageLegendPosition.Bottom}"></mosaik-image>
                        </mosaik-carousel-item>
                    </mosaik-carousel>
                    `], ['with navigation', () => html`
                    <mosaik-carousel style="width: 300px; --carousel-gap: 28px;" .showNavigation="${true}">
                        <mosaik-carousel-item>
                            <mosaik-card>
                                <mosaik-card-content>
                                    <mosaik-image .src="${'https://images.unsplash.com/photo-1529912626516-e58b23f44f1d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                                  .width="${'100px'}"
                                                  .height="${'100px'}"></mosaik-image>
                                    <mosaik-text .text="${'lalala'}"></mosaik-text>
                                </mosaik-card-content>
                            </mosaik-card>
                        </mosaik-carousel-item>
                        <mosaik-carousel-item>
                            <mosaik-card>
                                <mosaik-card-content>
                                    <mosaik-image .src="${'https://images.unsplash.com/photo-1562177257-977b3bd4d7ec?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}"
                                                  .width="${'100px'}"
                                                  .height="${'100px'}"></mosaik-image>
                                    <mosaik-text .text="${'lalala'}"></mosaik-text>
                                </mosaik-card-content>
                            </mosaik-card>
                        </mosaik-carousel-item>
                        <mosaik-carousel-item>
                            <mosaik-card>
                                <mosaik-card-content>
                                    <mosaik-image .src="${'https://images.unsplash.com/photo-1541723568817-3bb3d2dd5505?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxwcmlkZXxlbnwwfHwwfHx8MA%3D%3D'}"
                                                  .width="${'100px'}"
                                                  .height="${'100px'}"></mosaik-image>
                                    <mosaik-text .text="${'lalala'}"></mosaik-text>
                                </mosaik-card-content>
                            </mosaik-card>
                        </mosaik-carousel-item>
                    </mosaik-carousel>
                    `]
                ],
                properties: META.properties<CarouselElement>(CarouselElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'showIndicator',
                        value: true
                    },
                    {
                        key: 'showNavigation',
                        value: true
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'loop',
                        value: true
                    }
                ])
            },
            propertyGrid: {
                properties: META.properties<CarouselElement>(CarouselElement.is, [
                    {
                        key: 'dir',
                        value: FlowDirection.Auto
                    },
                    {
                        key: 'showIndicator',
                        value: true
                    },
                    {
                        key: 'showNavigation',
                        value: true
                    },
                    {
                        key: 'appearance',
                        value: Appearance.Soft
                    },
                    {
                        key: 'variant',
                        value: Variant.Primary
                    },
                    {
                        key: 'loop',
                        value: true
                    }
                ]),
                exludedProperties: ['selectedItem']
            },
            docs: [
                carouselElementDocs(),
                carouselItemElementDocs()
            ]
        };

    }

    // #endregion

}

/**
 * @public
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-carousel-sample': CarouselSampleElement;
    }
}
