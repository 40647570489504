import { Appearance, BadgePosition, type ButtonElement, type TemplateResult, Variant, adoptStyle, css, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';
import type { IPlaygroundProperty } from '../../../Components/Playground/IPlaygroundProperty';

export namespace ButtonSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-button></mosaik-button>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withBadgeTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-button>
                <mosaik-badge slot="overlay"
                              .appearance=${Appearance.Soft}
                              .position=${BadgePosition.TopRight}
                              .variant=${Variant.Danger}
                              .label=${'1'}></mosaik-badge>
            </mosaik-button>
        `;

        return [
            {
                name: 'With Badge',
                description: 'The template with a badge'
            }, template
        ];
    }

    export function styledTemplate(): [TemplateName, TemplateResult, Array<IPlaygroundProperty<ButtonElement>>] {
        const style = css`
            :host {
                --button-background-color: #92CD41 !important;
                --button-foreground-color: #fff !important;
                /* --button-border-radius: 0; */
                --button-border-color: #fff;
                --button-border-width: 4px;
                --button-shadow: inset -6px -6px 0px 0px #4AA52E;
                --button-border-style: none;
                --button-border-width: 0;
                --button-transition-duration: 0s;
            }

            :host(:hover),
            :host(:focus) {
                --button-background-color: #76c442;
                --button-shadow: inset -6px -6px 0px 0px #4AA52E;
            }

            :host(:active) {
                --button-background-color: #76c442;
                --button-shadow: inset 4px 4px 0px 0px #4AA52E;
            }
            :host(::part(button)::part(ripple)){
                display: none;
            }
            :host(::part(button)){
                overflow: unset;
            }

            :host(::part(button)::before),
            :host(::part(button)::after) {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                box-sizing: content-box;
            }

            :host(::part(button)::before) {
                top: -6px;
                left: 0;
                border-top: 6px black solid;
                border-bottom: 6px black solid;
            }

            :host(::part(button)::after) {
                left: -6px;
                top: 0;
                border-left: 6px black solid;
                border-right: 6px black solid;
            }
        `;
        const template = html`
            <mosaik-button ${adoptStyle(style)}></mosaik-button>
        `;

        return [
            {
                name: 'Styled',
                description: 'The styled template'
            }, template, [
                {
                    key: 'appearance',
                    value: Appearance.Default
                },
                {
                    key: 'variant',
                    value: Variant.Default
                },
                {
                    key: 'label',
                    value: 'Press Start'
                },
                {
                    key: 'icon',
                    value: ''
                }
            ]
        ];
    }

}
