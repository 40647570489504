//#region Imports

import { html, type TemplateResult } from '@breadstone/mosaik-elements-foundation';
import { type TypewriterElement } from './TypewriterElement';

//#endregion

/**
 * The template of the {@link TypewriterElement}.
 *
 * @public
 */
export function typewriterElementTemplate<T extends TypewriterElement>(e: T): TemplateResult {
    return html`
        <mosaik-text part="text"
                     .text="${'&#8203'.concat(e.text)}"
                     .formatter="${e.formatter}"
                     .variant="${e.variant}"
                     .alignment="${e.alignment}"></mosaik-text>
    `;
}
