import { Appearance, BadgePosition, type TemplateResult, Variant, html } from '@breadstone/mosaik-elements-foundation';
import type { TemplateName } from '../../../Components/Playground/IPlaygroundElementProps';

export namespace CompoundButtonSampleTemplates {

    export function defaultTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-compound-button></mosaik-compound-button>
        `;

        return [
            {
                name: 'Default',
                description: 'The default template'
            }, template
        ];
    }

    export function withBadgeTemplate(): [TemplateName, TemplateResult] {
        const template = html`
            <mosaik-compound-button>
                <mosaik-badge slot="overlay"
                              .appearance=${Appearance.Soft}
                              .position=${BadgePosition.TopRight}
                              .variant=${Variant.Danger}
                              .label=${'1'}></mosaik-badge>
            </mosaik-compound-button>
        `;

        return [
            {
                name: 'With Badge',
                description: 'The template with a badge'
            }, template
        ];
    }

}
