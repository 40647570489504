// #region Imports

import { Attribute, Component, Inject, Property } from '@breadstone/mosaik-elements-foundation';
import { ContentsService } from '../../Backend/Api/Services/ContentsService';
import { MarkdownElement } from '../../Components/Markdown/MarkdownElement';
import { globals } from '../../Globals';
import { ViewBase } from '../Abstracts/ViewBase';
import { viewBaseStyle } from '../Abstracts/ViewBaseStyle';
import { privacyPolicyViewStyle } from './PrivacyPolicyViewStyle';
import { privacyPolicyViewTemplate } from './PrivacyPolicyViewTemplate';

// #endregion

/**
 * The `{@link PrivacyPolicyView}` element.
 *
 * @public
 */
@Component({
    selector: 'app-privacypolicy-view',
    template: privacyPolicyViewTemplate,
    styles: [
        viewBaseStyle(),
        privacyPolicyViewStyle()
    ],
    providers: [
        {
            provide: ContentsService,
            useClass: ContentsService
        }
    ],
    imports: [
        MarkdownElement
    ]
})
export class PrivacyPolicyView extends ViewBase {

    // #region Fields

    @Inject(ContentsService)
    private readonly _contentsService!: ContentsService;

    private _companyName: string;
    private _contactEmail: string;
    private _content: string;

    // #endregion

    // #region Ctor

    /**
     * @public
     */
    public constructor() {
        super();

        this._companyName = globals.name;
        this._contactEmail = globals.authorEmail;
        this._content = '';
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `{@link is}` property.
     * The `{@link is}` property represents natural name of this element.
     *
     * @public
     * @static
     * @readonly
     */
    public static get is(): string {
        return 'app-privacypolicy-view';
    }

    /**
     * Gets or sets the `companyName` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get companyName(): string {
        return this._companyName;
    }

    public set companyName(value: string) {
        if (this._companyName !== value) {
            this._companyName = value;
            this.requestUpdate('companyName');
        }
    }

    /**
     * Gets or sets the `contactEmail` property.
     *
     * @public
     */
    @Attribute({ type: String })
    public get contactEmail(): string {
        return this._contactEmail;
    }

    public set contactEmail(value: string) {
        if (this._contactEmail !== value) {
            this._contactEmail = value;
            this.requestUpdate('contactEmail');
        }
    }

    /**
     * Gets or sets the `content` property.
     *
     * @public
     */
    @Property({ type: String })
    public get content(): string {
        return this._content;
    }

    public set content(value: string) {
        if (this._content !== value) {
            this._content = value;
            this.requestUpdate('content');
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     * @override
     */
    public override connectedCallback(): void {
        super.connectedCallback();
        this.initialize();
    }

    /**
     * @private
     */
    private initialize(): void {
        this.isBusy = true;

        void this._contentsService
            .getContents({
                kind: 'privacyPolicy',
                lang: this.lang as Parameters<ContentsService['getContents']>[0]['lang']
            })
            .then((x) => {
                this.content = x.content;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    // #endregion

}

/**
 * @public
 */
declare global {
    interface HTMLElementTagNameMap {
        'app-privacypolicy-view': PrivacyPolicyView;
    }

}
